import React, {useState} from 'react'
import {Button} from "primereact/button";
import BestleistungenDialog from "../tournament/BestleistungenDialog";
import WerbungenDialog from "./WerbungenDialog";
import EventOperatorZusammenlegenSpielerIdDialog from "../tournament/EventOperatorZusammenlegenSpielerIdDialog";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";
import NuConnectComponent from "../../nu/NuConnectComponent";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {LigaSpieltagPaarkreuzsystemVerwaltenDialog} from "../../general/LigaSpieltagPaarkreuzsystemVerwaltenDialog";

export default function EventOperatorSettingsDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [showBestleistungenDialog, setShowBestleistungenDialog] = useState(false);
    let [showWerbungenDialog, setShowWerbungenDialog] = useState(false);
    let [showBDVVerbindung, setShowBDVVerbindung] = useState(false);
    let [showSpielerZusammenlegenDialog, setShowSpielerZusammenlegenDialog] = useState(false);
    let [showPaarkreuzsystemeDialog, setShowPaarkreuzsystemeDialog] = useState(false);

    return <DHDefaultDrawer show={true} onClose={onClose} title="Einstellungen zum Veranstalter">
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding: 20}}>
            <div>
                Hier kannst du deine Standardeinstellung für Bestleistungen zu deinem Veranstalter ändern.<br/>
                Alle Turniere erhalten diese Einstellung als Standard, welche du natürlich im Turnier jederzeit ändern kannst.
            </div>
            <div style={{marginTop: 10}}>
                <Button label="Bestleistungen bearbeiten" onClick={() => setShowBestleistungenDialog(true)}/>
            </div>
            <hr/>
            <div>
                Werbepartner für Live-View verwalten. Dies ist ausschließlich für Pro-Accounts nützlich und sinnvoll. Nur da sind Live-Views möglich.
            </div>
            <div style={{marginTop: 10}}>
                <Button label="Werbepartner für Live-View verwalten" onClick={() => setShowWerbungenDialog(true)}/>
            </div>
            <hr/>
            <div>
                Spieler können mit unterschiedlichen Ids vorhanden sind, hier kannst du diese Zusammenlegen.
            </div>
            <div style={{marginTop: 10}}>
                <Button label="Spieler zusammenlegen" onClick={() => setShowSpielerZusammenlegenDialog(true)}/>
            </div>
            <hr/>
            <div style={{marginTop: 10}}>
                <Button label="Paarkreuzsystem verwalten" onClick={() => setShowPaarkreuzsystemeDialog(true)}/>
            </div>
            <hr/>
            <div style={{display: "flex"}}>
                <div style={{width: "50%", padding: 5}}>
                    Hier kannst du deinen Veranstalter mit einem BDV Verein verbinden. Danach kannst du die Mannschaften und Spiele über einen Menüpunkt auf dem Dashboard synchronisieren.
                </div>
                <div style={{width: "50%", padding: 5}}>
                    <img src="/images/bdv.png" style={{width: 150}}/>
                    {showBDVVerbindung && <NuConnectComponent eventOperatorId={eventOperatorId}
                                                              onError={(message) => {
                                                                  setErrorMessage(message);
                                                                  setShowBDVVerbindung(false);
                                                              }}
                                                              onResponseNotOK={(message) => {
                                                                  setErrorMessage(message);
                                                                  setShowBDVVerbindung(false);
                                                              }}/>}
                    <Button label="BDV Verbindung herstellen" onClick={() => {
                        setShowBDVVerbindung(true);
                        setErrorMessage(undefined);
                    }}/>
                </div>
            </div>
        </div>
        {showBestleistungenDialog && <BestleistungenDialog onClose={() => setShowBestleistungenDialog(false)} eventOperatorId={eventOperatorId}/>}
        {showWerbungenDialog && <WerbungenDialog onClose={() => setShowWerbungenDialog(false)} eventOperatorId={eventOperatorId}/>}
        {showSpielerZusammenlegenDialog &&
            <EventOperatorZusammenlegenSpielerIdDialog eventOperatorId={eventOperatorId}
                                                       callBackOnClose={() => setShowSpielerZusammenlegenDialog(false)}/>
        }
        {showPaarkreuzsystemeDialog &&
            <LigaSpieltagPaarkreuzsystemVerwaltenDialog onClose={() => setShowPaarkreuzsystemeDialog(false)} eventOperatorId={eventOperatorId} />
        }

    </DHDefaultDrawer>
}
