import React, {useState} from "react";
import {Rating} from "primereact/rating";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

export function ScoringRatingArea(props) {

    let [bildqualitaet, setBildqualitaet] = useState(null);
    let [tonqualitaet, setTonqualitaet] = useState(null);
    let [darthelfer, setDarthelfer] = useState(null);

    const fetch = (typ, rating) => {
        let data = {gameId: props.gameId, playerId: props.playerId, typ: typ, rating: rating};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.rating,
            data,
            json => {
                console.log("rating war erfolgreich")
            },
            responseNotOk => {
                console.log("Fehler beim Rating " + typ + " | " + rating );
            },
            error => {
                console.log("Fehler beim Rating " + typ + " | " + rating );
            }
        );
    }

    const sendBildqualitaet = (rating) => {
        setBildqualitaet(rating);
        fetch("bildqualitaet", rating);
    }

    const sendTonqualitaet = (rating) => {
        setTonqualitaet(rating);
        fetch("tonqualitaet", rating);
    }

    const sendDarthelfer = (rating) => {
        setDarthelfer(rating);
        fetch("darthelfer", rating);
    }

    return <div style={{color: "black", fontSize: 14, marginTop: 20}}>
        <span style={{fontWeight: "bold"}}>Nimm dir bitte kurz Zeit um einige Sachen zu bewerten</span>
        <div style={{marginTop:20}}>
            Wie zufrieden warst du mit der Bildqualität deines Gegners?
            <Rating value={bildqualitaet} cancel={true} onChange={(e) => sendBildqualitaet(e.target.value)}/>
        </div>
        <div style={{marginTop:10}}>
            Wie zufrieden warst du mit der Tonqualität deines Gegners?
            <Rating value={tonqualitaet} cancel={true} onChange={(e) => sendTonqualitaet(e.target.value)}/>
        </div>
        <div style={{marginTop:10}}>
            Wie zufrieden warst du mit dem Spiel, bezogen auf darthelfer.de?
            <Rating value={darthelfer} cancel={true} onChange={(e) => sendDarthelfer(e.target.value)}/>
        </div>
    </div>
}