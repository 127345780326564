import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Redirect} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function StartseiteGameScoringInvitation() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [gameId, setGameId] = useState(undefined);
    let [key, setKey] = useState("");

    const detectGame = () => {
        setErrorMessage(undefined);
        if (key !== undefined && key !== null && key.length > 0) {
            let url = ConfigUtil.getConfig().resourceUrls.fungame + "/detectGame?scorekey=" + key;
            FetchUtil.fetchGet(url,
                gameid => {
                    setGameId(gameid);
                },
                responseNotOk => {
                    setErrorMessage("Fehler: " + responseNotOk.message);
                },
                error => {
                    setErrorMessage("Fehler: " + error.message);
                });
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            detectGame();
        }
    }

    if (gameId) {
        return <Redirect
            to={NAVIGATION_ITEM.PUBLIC_TRAINING_GAME_SCORE.route + "?gameid=" + gameId + "&playerid=GAST"}/>;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <h3>Du hast einen Key zu einem Spiel erhalten, und möchtest das Spielscoring anschauen?</h3>
        <div style={{display: "flex"}}>
            <InputText name="key" style={{width: 300, marginRight:10}} value={key}
                       placeholder="Key hier eintragen"
                       onKeyDown={(e) => handleKeyDown(e)}
                       onChange={(e) => setKey(e.target.value.toUpperCase())}/>
            <Button label="Meeting ermitteln" style={{width: 150}} type="button"
                    onClick={() => detectGame()} disabled={key === ""}/>
        </div>
    </div>
}
