import React, {useState} from 'react'
import {Button} from 'primereact/button';
import TurnierGruppenphaseAbschliessenDialog from "./TurnierGruppenphaseAbschliessenDialog";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmProgressDialog from "../../general/ConfirmProgressDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import SchweizerSystemRunde from "./SchweizerSystemRunde";
import SchweizerSystemRundeTabelle from "./SchweizerSystemRundeTabelle";
import TournamentSchweizerSystemPlatzierungTable from "../public/tournaments/TournamentSchweizerSystemPlatzierungTable";

export default function SchweizerSystemRunden({tournament, gruppenTabellen, boardGames, liveGames, userId, isSetModus, isReadOnly, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [gruppe, setGruppe] = useState(1);

    const [showAbschliessenDialog, setShowAbschliessenDialog] = useState(false);
    const [showStarteNaechsteRunde, setShowStarteNaechsteRunde] = useState(false);
    const [showPlatzierungsspieleDialog, setShowPlatzierungsspieleDialog] = useState(false);

    const [selectedPlayerId, setSelectedPlayerId] = useState(undefined);
    const [platzierungenAktiv, setPlatzierungenAktiv] = useState(false);

    const [working, setWorking] = useState(false);

    const startePlatzierungsspiele = () => {
        setErrorMessage(undefined);
        setWorking(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/schweizersystem/starteplatzierungsgames/" + tournament.id,
            {},
            () => {
                setInfoMessage("Nächste Runde wurde gestartet.");
                setWorking(false);
                setShowPlatzierungsspieleDialog(false);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
                setShowPlatzierungsspieleDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setWorking(false);
                setShowPlatzierungsspieleDialog(false);
            }
        );
    }

    const starteNaechsteRundeSchweizerSystem = () => {
        setErrorMessage(undefined);
        setWorking(true);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/schweizersystem/startenaechsterunde/" + tournament.id + "/" + gruppe,
            {},
            () => {
                setInfoMessage("Nächste Runde wurde gestartet.");
                setWorking(false);
                setShowStarteNaechsteRunde(false);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
                setShowStarteNaechsteRunde(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setWorking(false);
                setShowStarteNaechsteRunde(false);
            }
        );
    }

    const isRundenphaseAbgeschlossen = () => {
        return tournament !== undefined && tournament.folgeTurnierId !== null && tournament.folgeTurnierId !== undefined
    }

    const isAnzahlGruppenEins = () => {
        return tournament.anzahlGruppen === 1;
    }

    const getGruppenAuswahlArea = () => {
        if( isAnzahlGruppenEins() ) {
            return "";
        } else {
            let gruppen = []
            for(let i = 1; i <= tournament.anzahlGruppen; i++) {
                gruppen.push(<Button label={i} style={{width:50}} onClick={() => {
                    setGruppe(i);
                    setPlatzierungenAktiv(false);
                }}
                                     className={gruppe === i ? "p-button-primary" : "p-button-secondary"}/>);
            }
            if( tournament.platzierungsphase) {
                gruppen.push(<Button label="Platzierungen" style={{width:120}} onClick={() => {
                    setPlatzierungenAktiv(true);
                    setGruppe(undefined);
                }}
                                     className={platzierungenAktiv ? "p-button-primary" : "p-button-secondary"}/>);
            }
            return <div style={{display:"flex", marginTop:10, verticalAlign:"middle", alignItems:"center"}}><span style={ {marginRight:10}}>Gruppen:</span>{gruppen}</div>
        }
    }

    const getPlatzierungen = () => {
        return <div style={{padding:5, width:"100%"}}>
            <TournamentSchweizerSystemPlatzierungTable onRefresh={() => onRefresh()}
                                                       boardGames={boardGames}
                                                       isReadOnly={isReadOnly}
                                                       eventOperatorId={tournament.eventOperatorId}
                                                       tournamentId={tournament.id}
                                                       liveGames={liveGames}
                                                       players={tournament.tournamentPlayerEntities}
                                                       games={tournament.tournamentSchweizerPlatzierungGameEntities}
                                                       />
        </div>
    }

    const getRunden = () => {
        if (tournament === undefined) {
            return "";
        }

        let anzahlRunden = 1;
        let groupGames = tournament.tournamentGroupGameEntities.filter(groupGame => groupGame.gruppe === gruppe);
        for (const groupGame of groupGames) {
            if( groupGame.runde > anzahlRunden) {
                anzahlRunden = groupGame.runde;
            }
        }
        let returnRunden = [];
        for (let runde = anzahlRunden; runde > 0; runde--) {
            let rundenGames = groupGames.filter(groupGame => groupGame.runde === runde);
            let tabelle = gruppenTabellen.filter(t => t.gruppe === gruppe && t.runde === runde)[0];
            returnRunden.push(<div style={{paddingLeft:2}} key={"key_grouptable_" + gruppe + "_runde_" + runde}>
                <SchweizerSystemRundeTabelle runde={runde}
                                      gruppe={gruppe}
                                      tabellen={tabelle}
                                      selectedPlayerId={selectedPlayerId}
                                      onPlayerSelect={(playerId) => setSelectedPlayerId(playerId)}
                                      tournament={tournament} />
            </div>);
            returnRunden.push(<div style={{paddingRight:2}} key={"key_groupgames_" + gruppe + "_runde_" + runde}>
                <SchweizerSystemRunde runde={runde}
                                      gruppe={gruppe}
                                      groupGames={rundenGames}
                                      tabellen={tabelle}
                                      userId={userId}
                                      isReadOnly={isReadOnly || tournament.platzierungsphase}
                                      selectedPlayerId={selectedPlayerId}
                                      onPlayerSelect={(playerId) => setSelectedPlayerId(playerId)}
                                      onRefresh={onRefresh} tournament={tournament} liveGames={liveGames} boardGames={boardGames} />
            </div>);
        }
        return returnRunden;
    }

    if (!tournament) {
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            Laden Daten...
        </div>
    }

    return <div style={{textAlign: "center", align: "center", color: "gray"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        {isRundenphaseAbgeschlossen() &&
            <div className="grid"
                 style={{marginTop: 20, padding: 20, marginLeft: 0, marginRight: 0}}>
                        <span style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            color: "green"
                        }}>Die Rundenphase ist beendet!!!</span>
            </div>
        }
        <div className="grid" style={{padding: 5, paddingTop: 10, marginLeft: 0, marginRight: 0}}>
            {!isReadOnly && !isRundenphaseAbgeschlossen() && !tournament.platzierungsphase &&
                <Button label={isAnzahlGruppenEins() ? "Nächste Runde starten" : "Nächste Runde starten für 'Gruppe " + gruppe + "'"}
                        onClick={(e) => setShowStarteNaechsteRunde(true)} style={{marginRight: 5}}/>
            }
            {!isReadOnly && !isRundenphaseAbgeschlossen() && isAnzahlGruppenEins() &&
                <Button label="Folgeturnier starten"
                        onClick={(e) => setShowAbschliessenDialog(true)} style={{marginRight: 5}}/>
            }
            {!isReadOnly && !isRundenphaseAbgeschlossen() && !isAnzahlGruppenEins() &&
                <Button label="Platzierungsspiele starten"
                        disabled={tournament.platzierungsphase}
                        onClick={(e) => setShowPlatzierungsspieleDialog(true)} style={{marginRight: 5}}/>
            }
        </div>
        {getGruppenAuswahlArea()}
        <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
            {gruppe !== undefined && getRunden()}
            {platzierungenAktiv  && getPlatzierungen()}
        </div>
        {showAbschliessenDialog &&
            <TurnierGruppenphaseAbschliessenDialog
                tournamentid={tournament.id}
                oldTournamentSystem={tournament.turniersystem}
                propAnzahlSpielerDieWeiterkommen={tournament.anzahlSpielerProGruppeFuerKOPhase}
                gameType={tournament.gameType}
                callBackCloseDialog={() => setShowAbschliessenDialog(false)}
                callBackRefresh={() => onRefresh()}/>
        }
        {showStarteNaechsteRunde && <ConfirmProgressDialog header="Frage"
                                                           textOben={isAnzahlGruppenEins() ? "Soll die nächste Runde gestartet werden?" : "Soll die nächste Runde für Gruppe '" + gruppe+ "' gestartet werden?"}
                                                           showJaButton={true}
                                                           working={working}
                                                           callBackOnNein={() => {
                                                               setShowStarteNaechsteRunde(false);
                                                               setWorking(false);
                                                           }}
                                                           visible={true}
                                                           callBackOnJa={() => starteNaechsteRundeSchweizerSystem()}
        />
        }
        {showPlatzierungsspieleDialog &&
            <ConfirmProgressDialog header="Frage"
                                   textOben={"Soll die Platzierungsspiele gestartet werden, danach sind keine weiteren Runden im Schweizer System möglich?"}
                                   showJaButton={true}
                                   working={working}
                                   callBackOnNein={() => {
                                       setShowPlatzierungsspieleDialog(false);
                                       setWorking(false);
                                   }}
                                   visible={true}
                                   callBackOnJa={() => startePlatzierungsspiele()}
            />
        }
    </div>
}