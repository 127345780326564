import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import RanglisteProfilesComponent from "./RanglisteProfilesComponent";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function RanglisteProfileGruppenZuordnungDialog({eventOperatorId, anzahlGruppen, tournamentId, ranglisteProfileId, onError, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [profileList, setProfileList] = useState([]);
    let [profileZuordnungen, setProfileZuordnungen] = useState([]);

    useEffect(() => {
        ladeProfile();
        ladeTournamentGroupProfiles();
    }, []);

    const save = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save/tournament/group/ranglisteprofiles/" + tournamentId,
            profileZuordnungen,
            message => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                ladeTournamentGroupProfiles();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error);
            }
        );
    }

    const setGruppeProfileId = (gruppe, profileId) => {
        let dto;
        let dtos = profileZuordnungen.filter(p => p.gruppe === gruppe);
        if( dtos.length === 1) {
            dto = dtos[0];
            dto = {...dto, ranglisteProfileId: profileId};
        } else {
            dto = {gruppe: gruppe, ranglisteProfileId: profileId };
        }
        let newArray = profileZuordnungen.filter(p => p.gruppe !== gruppe);
        newArray.push(dto);
        setProfileZuordnungen(newArray);
    }

    const getProfileId = (gruppe) => {
        let dtos = profileZuordnungen.filter(p => p.gruppe === gruppe);
        if( dtos.length === 1) {
            let dto = dtos[0];
            return dto.ranglisteProfileId;
        } else {
            return undefined;
        }
    }

    const getGruppenRows = () => {
        let gruppenRows = [];
        for (let gruppennummer = 1; gruppennummer <= anzahlGruppen; gruppennummer++) {
            let profileId = getProfileId(gruppennummer);
            gruppenRows.push(<div key={"key_gruppe_" + gruppennummer} style={{marginBottom:10}}>
                <span style={{marginRight:10}}>Gruppe {gruppennummer}</span>
                <RanglisteProfilesComponent eventOperatorId={eventOperatorId}
                                            profileList={profileList}
                                            onError={(message) => setErrorMessage(message)} onChange={(id) => setGruppeProfileId(gruppennummer, id)}
                                            ranglisteProfileId={profileId}/>
            </div>);
        }
        return gruppenRows;
    }

    const ladeProfile = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglistenprofiles/" + eventOperatorId,
            json => {
                let newProfiles = [{id: undefined, bezeichnung: "- - - - - -"},...json]
                setProfileList(newProfiles);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeTournamentGroupProfiles = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/tournament/group/ranglisteprofiles/" + tournamentId,
            json => {
                setProfileZuordnungen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const footer = (
        <div>
            <Button label="Speichern" icon={PrimeIcons.SAVE} style={{marginRight:5}} onClick={() => save()} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} />
        </div>
    );

    return <DHDialog onClose={onClose} title="Ranglistenprofile" buttons={footer} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {ranglisteProfileId !== null && ranglisteProfileId !== undefined && <>
            <div style={{marginBottom:5}}>Dem Turnier ist bereits ein Ranglistenprofil zugewiesen, dieses wird für alle Gruppen genutzt.</div>
            <div style={{marginBottom:10}}>Sofern du für einzelne Gruppen ein anderes Ranglistenprofil und damit evtl. andere Punkte benutzen möchtest, kannst du den Gruppen hier ein eigenes Ranglistenprofil zuweisen.</div>
        </>
        }
        {profileList.length === 0 && <div>Keine Ranglistenprofile zur Auswahl vorhanden.</div>}
        {profileList.length > 0 && getGruppenRows()}
    </>}/>
}
