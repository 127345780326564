import React from 'react'
import {FunGameShortComponent} from "./ligaComponents";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

export function VergangeneSpieleArea(props) {

    const getRows = () => {
        if(props.games === null || props.games === undefined) {
            return "";
        }
        let rows = [];
        for (const game of props.games) {
            rows.push(<FunGameShortComponent funGame={game} userId={props.userId} key={"key_vergangenge_spiele" + game.gameId}/>);
        }
        return rows;
    }

    return <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid red"}}>
        <h3>Vergangene Spiele</h3>
        <div style={{marginBottom:20, fontSize:14}}>* Anzeige der letzten 5 Spiele</div>
        {getRows()}
        <NavLink to={NAVIGATION_ITEM.GAMESTATISTIK.route + "?userId=" + props.currentProfilUserId} style={{textDecoration: "none"}}>
         <div style={{color:"black", textDecoration: "none", marginTop:20}}>Vergangene Spiele anzeigen ...</div>
        </NavLink>

    </div>
}