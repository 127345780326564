import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PlayerUtil from "../../../util/PlayerUtil";
import DateUtil from "../../../util/DateUtil";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 400,
    },
});


function getRows(props) {
    let tableRows = [];
    if( props.ergebnisse !== undefined && props.ergebnisse !== null) {
        let style = {borderRight:"2px solid #f4f4f4", textAlign:"left"};

        for (let i = 0; i < props.ergebnisse.length; i++) {
            let modolo = i % 2;
            let currentStyle = modolo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let ergebnis = props.ergebnisse[i];
            let trainingSessionDto = ergebnis.trainingSessionDto;
            let trainingsergebnisseDto = ergebnis.trainingsergebnisseDto;
            let player1Legs = props.spieler1.id === trainingsergebnisseDto.spieler1Id ? trainingsergebnisseDto.spieler1Legs : trainingsergebnisseDto.spieler2Legs;
            let player2Legs = props.spieler2.id === trainingsergebnisseDto.spieler1Id ? trainingsergebnisseDto.spieler1Legs : trainingsergebnisseDto.spieler2Legs;

            let datumAsString = DateUtil.localDateTimeToString(trainingSessionDto.timestampAsString);
            let ergebnisAsString = player1Legs + ":" +  player2Legs;
            tableRows.push(<TableRow key={datumAsString + "_row"} style={currentStyle}>
                <TableCell key={datumAsString + "_1"} style={{borderRight:"2px solid #f4f4f4"}}>{datumAsString}</TableCell>
                <TableCell key={datumAsString + "_2"} style={style}>{ergebnisAsString}</TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function aSimpleTable(props) {
    const classes = useStyles();

    let tableRows = getRows(props);

    let spielername1 = PlayerUtil.anzeigeNameZumSpieler(props.spieler1);
    let spielername2 = PlayerUtil.anzeigeNameZumSpieler(props.spieler2);

    let headerTableCellStyle = {fontSize: "14px", width:"150px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"};
    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={headerTableCellStyle}>Datum</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>{spielername1 + " : " + spielername2}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}