import React, {useEffect, useState} from "react";
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {InputText} from "primereact/inputtext";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import RanglistenPunkteDialog from "./RanglistenPunkteDialog";
import ConfirmDialog from "../../general/ConfirmDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function RanglisteProfilDetailArea({eventOperatorId, profile, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [id, setId] = useState(profile.id);
    let [bezeichnung, setBezeichnung] = useState(profile.bezeichnung !== undefined ? profile.bezeichnung : "");
    let [profileDetails, setProfileDetails] = useState([]);
    let [showPunkteDialog, setShowPunkteDialog] = useState(false);
    let [selectedProfileDetailId, setSelectedProfileDetailId] = useState(undefined);
    let [selectedProfileDetail, setSelectedProfilDetail] = useState(undefined);

    useEffect(() => {
        if( id !== undefined && id !== null) {
            ladeRanglistenProfileDetails();
        }
    }, [id]);

    const ladeRanglistenProfileDetails = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglistenprofiledetails/" + id,
            json => {
                setProfileDetails(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const saveRanglistenProfil = () => {
        let data = {
            id: id,
            eventOperatorId: eventOperatorId,
            bezeichnung: bezeichnung.trim(),
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglisteprofile/save",
            data,
            id => {
                setId(id);
                onRefresh();
                setInfoMessage("Ranglisteprofil wurde erfolgreich gespeichert.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const loescheProfilDetail = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/profiledetail/" + selectedProfileDetailId,
            {},
            id => {
                setSelectedProfileDetailId(undefined);
                ladeRanglistenProfileDetails()
                setInfoMessage("Es wurde erfolgreich gelöscht.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            })
    }

    const onTableClick = (detail) => {
        setSelectedProfilDetail(detail);
        setShowPunkteDialog(true);
    }

    const Tabelle = () => {
        let rows = [];
        for (const detail of profileDetails) {
            rows.push(<TableRow key={"key_profil" + detail.id} style={{cursor:"pointer"}}>
                <TableCell style={{fontSize: 16, textAlign:"center"}} onClick={() => onTableClick(detail)}>{detail.teilnehmerBis}</TableCell>
                <TableCell style={{fontSize: 16, textAlign:"center"}} onClick={() => onTableClick(detail)}>{detail.platz}</TableCell>
                <TableCell style={{fontSize: 16, textAlign:"center"}} onClick={() => onTableClick(detail)}>{detail.faktor}</TableCell>
                <TableCell style={{fontSize: 16, textAlign:"center"}}>
                    <Button icon="pi pi-trash" tooltip="Löschen" style={{marginRight: 5}}
                            onClick={() => setSelectedProfileDetailId(detail.id)}/>
                </TableCell>
            </TableRow>);
        }
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table style={{color: "white"}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 16, width:70, textAlign:"center"}}>Teilnehmer bis</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 16, width:50, textAlign:"center"}}>Platz</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 16, textAlign:"center"}}>Punkte/Faktor</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 16, width:50}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const isSaveActive = () => {
        return bezeichnung.trim() !== "";
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>
            <Button label="Zurück" icon={PrimeIcons.ARROW_LEFT} onClick={() => onClose()} />
            <Button label="Speichern" icon={PrimeIcons.SAVE} onClick={() => saveRanglistenProfil()} style={{marginLeft:10}} disabled={!isSaveActive()}/>
        </div>
        <div>
            <div style={{marginTop:20}}>
                Bezeichnung
            </div>
            <div>
                <InputText value={bezeichnung} style={{width: 300}}
                           onChange={(e) => setBezeichnung(e.target.value)}/>
            </div>
            {id === undefined && <div style={{marginTop:20, fontSize:16, fontWeight:"bold"}}>Bitte den Profilnamen füllen und diesen zuerst speichern, danach kannst du dafür die Punkte vergeben.</div>}
            {id !== undefined &&
                <div style={{marginTop: 20}}>
                    <div style={{marginBottom:10}}>
                        <Button label="Hinzufügen" icon={PrimeIcons.PLUS} onClick={() => setShowPunkteDialog(true)}/>
                    </div>
                    <Tabelle />
                </div>
            }
        </div>
        {showPunkteDialog && <RanglistenPunkteDialog profileId={id} profileDetail={selectedProfileDetail !== undefined ? selectedProfileDetail : {}} details={profileDetails} onRefresh={() => ladeRanglistenProfileDetails()}
                                                     onClose={() => {
                                                         setShowPunkteDialog(false);
                                                         setSelectedProfilDetail(undefined);
                                                     }} />}
        {selectedProfileDetailId !== undefined &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => setSelectedProfileDetailId(undefined)}
                           callBackOnJa={() => loescheProfilDetail()}
                           header="Punkte löschen"
                           textOben=""
                           textUnten="Möchtest du die Punkte wirklich löschen?"/>
        }
    </div>
}
