import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHDefaultDrawer from "../general/DHDefaultDrawer";
import {ToggleButton} from "primereact/togglebutton";

export default function NuSettingsComponent({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [datenWurdenGeladen, setDatenWurdenGeladen] = useState(false);

    const [setting171To180, setSetting171To180] = useState(false);

    useEffect(() => {
        ladeDaten();
    }, []);

    useEffect(() => {
        if( datenWurdenGeladen ) {
            setErrorMessage(undefined);
            FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/setting/171to180/save/" + eventOperatorId + "/" + setting171To180,
                {},
                json => {
                    setInfoMessage("Speichern erfolgreich.");
                },
                responseNotOk => {
                    setErrorMessage("Fehler:" + responseNotOk.message)
                },
                error => {
                    setErrorMessage("Fehler:" + error.message)
                })
        }
    }, [setting171To180]);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/settings/eventoperator/"+ eventOperatorId,
            json => {
                setSetting171To180(json.setting171To180);
                setDatenWurdenGeladen(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title={"BDV/nu Einstellungen"} children={
        <div style={{minWidth: 350, padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>

            <div>
                171er werden an BDV/nu als 180er übertragen.
            </div>
            <div>
                (Bitte vorab mit dem Verband klären)
            </div>
            <div style={{marginTop:5}}>
                <ToggleButton onLabel="Ja" offLabel="Nein"
                              checked={setting171To180}
                              onChange={() => setSetting171To180(!setting171To180)}/>
            </div>
        </div>
    }/>
}