import {
    NEW_GAMES_OVER_WEBSOCKET,
    NEW_HERAUSFORDERUNGEN_OVER_WEBSOCKET,
} from "../actions/games";

function games(state = {
    activeGames: [],
    herausforderungen: [],
}, action) {
    switch (action.type) {
        case NEW_GAMES_OVER_WEBSOCKET:
            return {...state, activeGames: action.value};
        case NEW_HERAUSFORDERUNGEN_OVER_WEBSOCKET:
            return {...state, herausforderungen: action.value};
        default:
            return state
    }
}

export default games;