import React from 'react'
import VeranstalterVerwalten from "./VeranstalterVerwalten";
import DHDialog from "../../general/DHDialog";

export default function VeranstalterUebersichtDialog({onRefresh, onClose}) {

    return <DHDialog onClose={() => onClose()} title="Werbung" show={true} buttons={[]} showCloseButton={true}
                     comp={<div style={{padding: 20}}>
                         <VeranstalterVerwalten onSave={() => onRefresh()}/>
                     </div>}/>
}
