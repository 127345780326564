import React from 'react'
import UserSearch from "./UserSearch";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import ConfirmDialog from "../../general/ConfirmDialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {OverlayPanel} from "primereact/overlaypanel";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

class DoppelpartnerArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {user: undefined, doppelpartner: [], showDeleteDialog:false,
            selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined, showEditDialog: false};

        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.save = this.save.bind(this);
        this.changeDoppelpaarung = this.changeDoppelpaarung.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.doppelpartner + "/all",
            json => {
                this.setState({doppelpartner: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    changeDoppelpaarung() {
        this.messages.clear();
        let data = {id: this.state.selectedDoppelpartner, name: this.state.selectedDoppelpartnerName};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.doppelpartner,
            data,
            json => {
                this.setState({showEditDialog: false, selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined});
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({showEditDialog: false, selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined});
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({showEditDialog: false, selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined});
                this.showError("Fehler: " + error.message);
            }
        );
    }

    save() {
        this.messages.clear();
        let data = {userId2: this.state.user.id, name: this.state.name};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.doppelpartner,
            data,
            json => {
            this.setState({user:undefined, name: ""})
                this.ladeDaten()
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    delete() {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.doppelpartner + "/" + this.state.selectedDoppelpartner,
            {},
            json => {
                this.setState({showDeleteDialog: false, selectedDoppelpartner: undefined})
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({showDeleteDialog: false, selectedDoppelpartner: undefined})
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({showDeleteDialog: false, selectedDoppelpartner: undefined})
                this.showError("Fehler: " + error.message);
            }
        );
    }


    getDescription(description) {
        return <div className="grid" style={{textAlign:"left"}}>
            <div className="col" style={{fontSize:12}}>
                {description}
            </div>
        </div>
    }

    getRows() {
        if( this.state.doppelpartner === null || this.state.doppelpartner === undefined) {
            return [];
        }
        let rows = [];
        for (const dPartner of this.state.doppelpartner) {
            rows.push(<div style={{marginBottom:20}} key={"key_doppelpartner_" + dPartner.id}>
                <div className="grid">
                    <div className="col-fixed" style={{width:50}}>
                        <Button icon="pi pi-trash"
                             onClick={() => this.setState({selectedDoppelpartner: dPartner.id, showDeleteDialog:true})}
                             style={{marginRight:"5px", marginTop:"5px"}}
                             tooltip={"Doppelpartner löschen"}/>
                        <Button icon="pi pi-pencil"
                                tooltip="Doppelpartner bearbeiten"
                                style={{marginRight:"5px", marginTop:"5px"}}
                                onClick={() => this.setState({showEditDialog: true, selectedDoppelpartner: dPartner.id, selectedDoppelpartnerName: dPartner.name})}/>
                    </div>
                    <div className="col" style={{fontStyle:"bold"}}>
                        {dPartner.name}<br/>
                        <span style={{fontSize:12}}>Partner: {dPartner.userName1} u. {dPartner.userName2}</span><br/>
                        <div style={{width: "100%", marginBottom:5}}>
                            <OverlayPanel ref={(el) => this.opIdShort = el} showCloseIcon>
                                <div style={{marginBottom: 5}}>
                                    SpielerID kannst du für Offlineturniere, z.B. in der Kneipe oder ähnliches, für die schnelle Anmeldung nutzen.
                                </div>
                            </OverlayPanel>
                            <span style={{width: 200, marginRight: 10, fontSize:12}}>
                                SpielerID <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}} onClick={(e) => this.opIdShort.show(e)}/> :
                            </span>
                            <span>{dPartner.idShort}</span>
                        </div>
                        <span style={{fontSize:12}}>30 Tage Avg.: {dPartner.average}</span><br/>
                        <NavLink to={NAVIGATION_ITEM.DOPPELSTATISTICSAREA.route + "?doppelpartnerid=" + dPartner.id } style={{textDecoration: "none"}}>
                            <div style={{textAlign:"left", fontSize:12, marginTop:5}}>Weitere Statistik anzeigen...</div>
                        </NavLink>
                        <NavLink to={NAVIGATION_ITEM.GAMESTATISTIK.route + "?doppelpartnerId=" + dPartner.id} style={{textDecoration: "none"}}>
                            <div style={{textAlign:"left", fontSize:12, marginTop:5}}>Vergangene Spiele anzeigen...</div>
                        </NavLink>
                    </div>
                </div>
                </div>
                );
        }
        return rows;
    }

    editDialog() {
        return <DHDefaultDrawer show={this.state.showEditDialog}
                                title="Doppelpaarung bearbeiten"
                                onClose={() => this.setState({showEditDialog: false, selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined})}
                                children={<div style={{padding:10}}>
                                    <div>
                                        <InputText value={this.state.selectedDoppelpartnerName} style={{width: "100%"}}
                                                   onChange={(e) => this.setState({selectedDoppelpartnerName: e.target.value})}/>
                                    </div>
                                    <div style={{marginTop:10}}>
                                        <Button label="Speichern" icon="pi pi-check" onClick={() => this.changeDoppelpaarung()}/>
                                        <Button label="Abbrechen" icon="pi pi-times"
                                                onClick={() => this.setState({showEditDialog: false, selectedDoppelpartnerName: undefined, selectedDoppelpartner: undefined})}
                                                className="p-button-secondary"/>
                                    </div>
                                </div>}/>
    }

    render() {
        let rows = this.getRows();
        return <div className="ligashortoverview"
                    style={{height: "100%", borderLeft: "10px solid blue", textAlign: "left"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <h3>Doppelpartner</h3>
            {this.getDescription("Über folgenden Bereich kannst du Doppelpartner inkl. der Funname (Name der Doppelpaarung - einmalig in Darthelfer) verwalten. Du benötigst diese Doppelpartner nur für ONLINE-Games, nicht im Veranstalterbereich.")}

            <div className="grid" style={{marginTop:20}}>
                <div className="col-fixed" style={{width:120}}>Funname:</div>
                <div className="col">
                    <InputText value={this.state.name} style={{width: "100%"}}
                               onChange={(e) => this.setState({name: e.target.value})}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width:120}}>Doppelpartner:</div>
                <div className="col">
                    <UserSearch
                        callBackError={(message) => {
                            this.showError(message);
                            this.setState({user: undefined})
                        }}
                        callBackClearMessages={() => this.messages.clear()}
                        callBackInfo={(message) => this.showWarn(message)}
                        callBackUserFound={(user) => this.setState({user: user})}
                        shortSearchButton={true}/>
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <Button icon="pi pi-check"
                            label="Speichern"
                            disabled={this.state.user === null || this.state.user === undefined || this.state.name === ""}
                            tooltip="Speichern"
                            onClick={() => this.save()}/>
                </div>
            </div>
            <hr/>
            <div>
                {rows}
            </div>
            {this.editDialog()}
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({showDeleteDialog: false})}
                           callBackOnJa={this.delete}
                           header="Doppelpartner löschen"
                           textOben="Sobald für diese Doppelpaarung bereits Spiele vorhanden sind, wird die Doppelpaarung auf inaktiv gestellt. Der Name ist damit verbraucht und kann nicht mehr vergeben werden. "
                           textUnten="Möchtest du die Doppelpaarung wirklich löschen?"/>
        </div>
    }
}

DoppelpartnerArea.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default DoppelpartnerArea;
