import React from 'react'
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {USER_ROLES} from "../../../constants/userRoles";
import {Panel} from "primereact/panel";
import DatePicker from "react-datepicker";
import {InputText} from "primereact/inputtext";
import DateUtil from "../../../util/DateUtil";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import RanglisteMarkerComponent from "./RanglisteMarkerComponent";

class RanglisteEditView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {ranglisten: [], selectedMarkers:[]};

        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.loadRanglisten = this.loadRanglisten.bind(this);
    }

    componentDidMount() {
        this.loadRanglisten();
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    loadRanglisten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.rangliste + "/all",
            json => {
                this.setState({ranglisten: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    loescheRangliste(id) {
        this.messages.clear();
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.rangliste + "/" + id,
            {},
            json => {
                this.showInfo("Rangsliste wurde gelöscht.");
                this.loadRanglisten();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    setRanglisteAktiv(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.rangliste + "/changeactive/" + id,
            {},
            json => {
                this.showInfo("Rangliste wurde auf aktiv gesetzt.");
                this.loadRanglisten();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    save(zeitraumTyp) {
        this.messages.clear();
        let data = {vondatum: DateUtil.toLocalDateTime(this.state.vondatum), bisdatum: DateUtil.toLocalDateTime(this.state.bisdatum), bezeichnung: this.state.bezeichnung, zeitraumTyp: zeitraumTyp, marker: this.state.selectedMarkers};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.rangliste + "/save",
            data,
            json => {
                this.showInfo("Speichern war erfolgreich.");
                this.loadRanglisten();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }



    getRows(zeitraumTyp) {
        let rows = [];

        if( this.state.ranglisten === undefined || this.state.ranglisten.length === 0 ) {
            return rows;
        }
        for (let i = 0; i < this.state.ranglisten.length; i++) {
            let rangliste = this.state.ranglisten[i];
            if( rangliste.zeitraumTyp !== zeitraumTyp) {
                continue;
            }
            let von = DateUtil.localDateTimeToString(rangliste.vondatum);
            let bis = DateUtil.localDateTimeToString(rangliste.bisdatum);

            let markers = "";
            if( rangliste.marker !== undefined && rangliste.marker !== null) {
                for (const markerElement of rangliste.marker) {
                    markers += markerElement + "; ";
                }
            }
            rows.push(<div key={"row_" + rangliste.id} className="grid" style={{marginLeft:"5px"}}>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {von}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {bis}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {rangliste.bezeichnung}
                </div>
                <div className="col-fixed" style={{width:'150px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {markers}
                </div>
                <div className="col-fixed" style={{width:'35px', textAlign:"center", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {rangliste.active ? <i className="pi pi-star"/> : ""}
                </div>
                <div className="col-fixed" style={{width:'95px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    <Button icon="pi pi-star"
                            onClick={() => this.setRanglisteAktiv(rangliste.id)}
                            style={{marginRight:"5px"}}
                            rendered={rangliste.active}
                            tooltip={"Rangliste aktive setzen"}
                            className="p-button-success"/>
                    <Button icon="pi pi-times"
                            tooltip="Rangliste löschen"
                            onClick={() => this.loescheRangliste(rangliste.id)}
                            style={{marginRight:"5px"}} />
                </div>
            </div>);
        }
        return rows;
    }

    render() {
        let isAdmin = false;
        if (this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }

        if (!isAdmin) {
            return <div>Keine Berechtiung zum Anzeigen dieser Seite</div>
        }

        let rowsTurniere = this.getRows("rangliste_turniere");
        let rowsWeekendLeague = this.getRows("rangliste_weekendleague");

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader"
                 style={{padding: 0, margin: 0, backgroundColor: "#B50339", color: "white"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Ranglisten anlegen u. bearbeiten
                </div>
            </div>
            <Panel header="Rangliste anlegen" style={{textAlign: "left", padding: 20}}>
                <div className="grid" style={{marginTop: "20px"}}>
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Von Datum:</div>
                    <div className="col">
                        <DatePicker
                            selected={this.state.vondatum}
                            onChange={date => this.setState({vondatum: date})}
                            dateFormat="dd.MM.yyyy"
                            todayButton="Heute"/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bis Datum:</div>
                    <div className="col">
                        <DatePicker
                            selected={this.state.bisdatum}
                            onChange={date => this.setState({bisdatum: date})}
                            dateFormat="dd.MM.yyyy"
                            todayButton="Heute"/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bezeichnung:</div>
                    <div className="col">
                        <InputText name="bezeichnung" className={!this.state.bezeichnung ? "p-error" : ""}
                                   style={{width: 300}}
                                   value={this.state.bezeichnung}
                                   onChange={(e) => this.setState({bezeichnung: e.target.value})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>
                        Rangliste-Marker (optional):
                    </div>
                    <div className="col">
                        <RanglisteMarkerComponent onValueChange={(markers) => this.setState({selectedMarkers:markers})} selectedMarkers={this.state.selectedMarkers}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}/>
                    <div className="col"><Button label="Speichern" onClick={() => this.save("rangliste_turniere")}/></div>
                </div>
                <div style={{marginTop: "20px"}}>
                    {rowsTurniere}
                </div>
            </Panel>
            <Panel header="Weekend League anlegen" style={{textAlign: "left", padding: 20}}>
                <div className="grid" style={{marginTop: "20px"}}>
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Von Datum:</div>
                    <div className="col">
                        <DatePicker
                            selected={this.state.vondatum}
                            onChange={date => this.setState({vondatum: date})}
                            dateFormat="dd.MM.yyyy"
                            todayButton="Heute"/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bis Datum:</div>
                    <div className="col">
                        <DatePicker
                            selected={this.state.bisdatum}
                            onChange={date => this.setState({bisdatum: date})}
                            dateFormat="dd.MM.yyyy"
                            todayButton="Heute"/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Bezeichnung:</div>
                    <div className="col">
                        <InputText name="bezeichnung" className={!this.state.bezeichnung ? "p-error" : ""}
                                   style={{width: 300}}
                                   value={this.state.bezeichnung}
                                   onChange={(e) => this.setState({bezeichnung: e.target.value})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>
                        Rangliste-Marker (optional):
                    </div>
                    <div className="col">
                        <RanglisteMarkerComponent onValueChange={(markers) => this.setState({selectedMarkers:markers})} selectedMarkers={this.state.selectedMarkers}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                    <div className="col"><Button label="Speichern" onClick={() => this.save("rangliste_weekendleague")}/></div>
                </div>
                <div style={{marginTop: "20px"}}>
                    {rowsWeekendLeague}
                </div>
            </Panel>
        </div>
    }
}

RanglisteEditView.propTypes = {
    userId: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default RanglisteEditView;
