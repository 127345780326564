import React, {useEffect, useState} from 'react'
import StatistikGameComponent from "./StatistikGameComponent";
import {Sidebar} from "primereact/sidebar";

export default function StatistikGameDialog({gameId, tournamentId, tournamentGameId, onClose}) {

    let [dimensions, setDimensions] = useState({
        width: window.screen.width}
    );

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.screen.width
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return <Sidebar visible={true} style={{minWidth: 380, width:1200, maxWidth: dimensions.width}}
                    onHide={(e) => onClose()}
                    position="left" showCloseIcon={true}>
        <div style={{
            padding: 10,
            marginBottom: 20,
            width: "100%",
            backgroundColor: "#ac1c27",
            textAlign: "center",
            color: "white",
            fontSize: 25
        }}>Game/Details
        </div>
        <div style={{width: "100%"}}>
            <StatistikGameComponent gameId={gameId} tournamentId={tournamentId} tournamentGameId={tournamentGameId}/>
        </div>
    </Sidebar>
}
