import React from 'react'
import FunGamesArea from "./FunGamesArea";
import PropTypes from "prop-types";

class MeineSpieleOverview extends React.Component {

    render() {
        return <div className="col-12 md:col-12 lg:col-3" style={{padding:10}}>
            <FunGamesArea funGames={this.props.games} userId={this.props.userId} />
        </div>
    }
}

MeineSpieleOverview.propTypes = {
    games: PropTypes.array,
    userId: PropTypes.string,
}

export default MeineSpieleOverview;
