import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export function AdministrationLigaProAccountVergeben(props) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [ligaId, setLigaId] = useState("");

    const generate = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/liga/proaccount/vergeben/" + ligaId,
            {},
            () => {
                setInfoMessage("Gespeichert");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Generieren: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Generieren: " + error.message);
            })
    }

    return <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid red"}}>
        <h3>Liga Proaccount vergeben - Adminoption</h3>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{width:"100%"}}>
            <InputText type="text" placeholder="ligaId" style={{width:600}} value={ligaId}
                       onChange={(e) => setLigaId(e.target.value)}/>
        </div>
        <div style={{marginTop:20}}>
            <Button onClick={() => generate()} label="Generieren..."
                    disabled={ligaId === ""}/>
        </div>
    </div>
}
