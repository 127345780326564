import React, {useState} from 'react'
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

const zIndexAusbullen = 600;

export default function AusbullenComponent({gameid, player, gegner, bullAbgeschickt, erneutBullen, callBackSendBullen, callBackOnError, callBackClearErrorMessages}) {

    let [showBullenArea, setShowBullenArea] = useState(true);
    let [showBullenNotfallBereich, setShowBullenNotfallBereich] = useState(false);
    let [showBullenErklaerung, setShowBullenErklaerung] = useState(false);
    let [bull1, setBull1] = useState(undefined);
    let [bull2, setBull2] = useState(undefined);
    let [bull3, setBull3] = useState(undefined);

    const bullOptions = [
        {name: 'No', wert: 0},
        {name: 'single bull', wert: 25},
        {name: 'bulls eye', wert: 50}
    ];

    const sendBullen = () => {
        callBackSendBullen(bull1.wert, bull2.wert, bull3.wert);
    }

    const sendBullenGewonnen = (gewonnen) => {
        callBackClearErrorMessages();

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.game + "/bullengewonnen/" + gameid + "/" + player.id + "/" + gewonnen,
            {},
            () => {},
            responseNotOk => {
                callBackOnError("Fehler beim Bullen: " + responseNotOk.message);
            },
            error => {
                callBackOnError("Fehler beim Bullen: " + error.message);
            })
    }

    let playername = player.username !== null ? player.username : player.playername;
    let gegnername = gegner.username !== null ? gegner.username : gegner.playername;

    if( !showBullenArea ) {
        return <div style={{position:"fixed", top:60, left:35,
            zIndex: zIndexAusbullen, padding:20, border:"1px solid black", backgroundColor:"white"}}>
            <div onClick={() => setShowBullenArea(true)} style={{cursor:"pointer"}}><i class="pi pi-window-maximize"  style={{marginRight:10}}/>Bullbereich maximieren</div>
            <h3>{playername} vs. {gegnername}</h3>
        </div>
    }
    let alleDreiGefuellt = bull1 && bull2 && bull3;
    return <div style={{position:"fixed", top:60, left:35,
        zIndex: zIndexAusbullen, padding:20, border:"1px solid black", backgroundColor:"white"}}>1
        <div onClick={() => setShowBullenArea(false)} style={{cursor:"pointer"}}><i className="pi pi-window-minimize" style={{marginRight:10}}/>Bullbereich minimieren</div>
        <h3>{playername} vs. {gegnername}</h3>
        <h3>Du musst ausbullen...</h3>


        {(!bullAbgeschickt && erneutBullen) &&  <><br/><span style={{color:"green"}}>Das Ausbullen hat kein Ergebnis gebracht, du musst erneut ausbullen.</span><br/><br/></>}
        {bullAbgeschickt && <><br/><span style={{color:"red"}}>Dein Bullen wurde abgeschickt, wir warten auf deinen Gegner</span><br/><br/></>}


        Wurf 1:
        <SelectButton value={bull1} options={bullOptions}
                      optionLabel="name"
                      onChange={(e) => setBull1(e.value)} />
        Wurf 2:
        <SelectButton value={bull2} options={bullOptions}
                      optionLabel="name"
                      onChange={(e) => setBull2(e.value)} />
        Wurf 3:
        <SelectButton value={bull3} options={bullOptions}
                      optionLabel="name"
                      onChange={(e) => setBull3(e.value)} />


        <Button label="Abschicken" style={{marginTop:10}} disabled={!alleDreiGefuellt || bullAbgeschickt} onClick={() => sendBullen()}/>


        <Fieldset legend="Erklärung" style={{marginTop:10}} toggleable collapsed={!showBullenErklaerung} onToggle={(e) => setShowBullenErklaerung(!e.value)}>
            <div style={{color:"red", marginBottom:10}}>Du wirfst 3 mal und gibst dein Ergebnis genau in dieser Reihenfolge ein.</div>
            <div style={{color:"red", marginBottom:10}}>Wurf 1 wird mit Wurf 1 verglichen, 2 mit 2 und 3 mit 3.</div>
            <div style={{color:"red", marginBottom:10}}>Gewonnen hat, wer den ersten Wurf mit einem höheren Ergebnis hat.</div>
            <div style={{color:"red", marginBottom:10}}>Sofern es kein Ergebnis gibt, wird der Vorgang wiederholt. Dies wird dir angezeigt.</div>
        </Fieldset>
        {player.id !== "GAST" && <Fieldset legend="Notfall / Probleme" style={{marginTop:10}} toggleable collapsed={!showBullenNotfallBereich} onToggle={(e) => setShowBullenNotfallBereich(!e.value)}>
            ;-( ;-( ;-( ;-( Die Notfallknöpfe gibt es, da wir Probleme mit dem normalen Abschicken des Bullen haben.
            <br/>Bitte nutze weiterhin das normale Bullen und melde uns, wenn es Probleme gibt.
            <br/>
            <Button label="Bullen gewonnen." style={{marginTop:30}} onClick={() => sendBullenGewonnen(true)}/>
            <Button label="Bullen verloren." style={{marginLeft:10, marginTop:30}} onClick={() => sendBullenGewonnen(false)}/>
        </Fieldset>
        }
    </div>

}
