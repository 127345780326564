import React, {useState} from 'react'
import StatistikGameDialog from "./StatistikGameDialog";


export default function PublicLigaspieltagGamesTab({tournament, boardGames}) {

    let [selectedGameId, setSelectedGameId] = useState(undefined);

    const getBereichArea = (bereich) => {
        return <div style={{fontSize:20, marginBottom:20, height:50, display:"flex",
            alignItems:"center", alignContent:"center", textAlign:"right", justifyContent:"center", fontWeight:"bold",
            backgroundColor:"#ac1c27", color:"white"}}>
                Phase {bereich}
        </div>
    }

    const getGameBoardAnzeigeArea = (gameId) => {
        if( tournament.tournamentLeagueGameEntities.length === 0) {
            return;
        }
        let game = tournament.tournamentLeagueGameEntities.filter(tmpGame => tmpGame.id === gameId)[0];
        if( game.boardId === null || game.boardId === undefined || boardGames.length === 0) {
            return "";
        }
        let board = boardGames.filter(boardGame => boardGame.tournamentGameId === game.id)[0];
        if( board === null || board === undefined) {
            // vermutlich wurde das vergebene board gelöscht oder noch nicht aufgerufen
            return "";
        }
        return <div style={{display:"flex", textAlign:"center", justifyContent:"center", verticalAlign:"middle", alignItems:"center",
            backgroundColor:"red", color:"white", width:"100%", paddingTop:5, paddingBottom:5,
            borderTopLeftRadius: 10,borderTopRightRadius: 10,}}>
            {board.boardBezeichnung}
        </div>
    }

    const getPlayerName = (playerId) => {
        let players = tournament.tournamentPlayerEntities.filter(player => player.id === playerId);
        if( players.length === 1) {
            return players[0].name;
        } else {
            return "Spielername nicht gefunden."
        }
    }

    const getGameSingleArea = (game) => {
        if( game.gesperrt) {
            let ergebnis = game.anzahlPlayer1 !== null;
            let scoringWithDarthelfer = tournament.scoringWithDarthelfer;
            let player1Gewonnen = game.anzahlPlayer1 !== null && game.anzahlPlayer1 > game.anzahlPlayer2;
            let player2Gewonnen = game.anzahlPlayer2 !== null && game.anzahlPlayer2 > game.anzahlPlayer1;
            return <div style={{marginTop:5, marginBottom:20, textAlign:"center", cursor: ergebnis && scoringWithDarthelfer ? "pointer" : ""}} onClick={() => {
                    if( ergebnis && scoringWithDarthelfer ) {
                        setSelectedGameId(game.id);
                    }
            }}>
                <div style={{fontWeight:"bold", marginBottom:10}}>Spiel {game.counter}</div>
                {!ergebnis && getGameBoardAnzeigeArea(game.id)}
                {game.type === "EZ" &&
                    <div>
                        <span style={{color: player1Gewonnen ? "darkgreen": "", fontWeight: player1Gewonnen ? "bold": ""}}>{getPlayerName(game.player1Id)}</span>
                        <span style={{marginLeft:5, marginRight:5}}>vs.</span>
                        <span style={{color: player2Gewonnen ? "darkgreen": "", fontWeight: player2Gewonnen ? "bold": ""}}>{getPlayerName(game.player2Id)}</span>
                    </div>
                }
                {game.type === "DP" &&
                    <div>
                        <span style={{color: player1Gewonnen ? "darkgreen": "", fontWeight: player1Gewonnen ? "bold": ""}}>{getPlayerName(game.player1Id)}</span>
                        <br/>
                        <span style={{marginLeft:5, marginRight:5}}>vs.</span>
                        <br/>
                        <span style={{color: player2Gewonnen ? "darkgreen": "", fontWeight: player2Gewonnen ? "bold": ""}}>{getPlayerName(game.player2Id)}</span>
                    </div>
                }
                {game.anzahlPlayer1 !== null &&
                    <div>
                        Ergebnis: {game.anzahlPlayer1} : {game.anzahlPlayer2}
                    </div>
                }
            </div>
        } else {
            return undefined;
        }
    }

    const ermittleHoechstenBereichAusGames = () => {
        let bereich = 1;
        for (const game of tournament.tournamentLeagueGameEntities) {
            if( game.bereich > bereich) {
                bereich = game.bereich;
            }
        }
        return bereich;
    }

    const getGameArea = () => {
        let cols = [];
        let games = tournament.tournamentLeagueGameEntities;
        if( games === null || games === undefined || games.length === 0) {
            return <div style={{marginTop:30}}>Aktuell keine Spiele vorhanden...</div>
        }
        let hoechsterBereich = ermittleHoechstenBereichAusGames();
        for( let bereich = 1; bereich <= hoechsterBereich ; bereich++) {
            let tmpGames = games.filter(game => game.bereich === bereich);
            let rows = [];
            tmpGames.forEach(game => {
                if( game.type === "EZ"  || game.type === "DP") {
                    let row = getGameSingleArea(game);
                    if( row !== undefined) {
                        rows.push(row);
                    }
                } else {
                    rows.push(<div>Fehlerhafter Gametype, bitte Admin verständigen. LeagueGameId: {game.id}</div>);
                }
            })
            if( rows.length > 0 ) {
                let newRows = [getBereichArea(bereich)].concat(rows);
                cols.push(<div className="col-12 md:col-6 lg:col-3" style={{color:"black", backgroundColor:"white", border:"1px solid #ddd"}}>
                    {newRows}
                </div>)
            }
        }


        return <div className="grid" style={{padding:20, margin:0, color:"white"}}>
            {cols}
        </div>;
    }

    const getGesamtErgebnis = () => {
        let heim = 0;
        let gast = 0;
        for (const tmpGame of tournament.tournamentLeagueGameEntities) {
            if( tmpGame.anzahlPlayer1 !== null && tmpGame.anzahlPlayer1 !== undefined ) {
                if( tmpGame.anzahlPlayer1 > tmpGame.anzahlPlayer2) {
                    heim = heim + 1;
                } else {
                    gast = gast + 1;
                }
            }
        }
        return heim + ":" + gast;
    }

    return <div style={{backgroundColor:"#022836"}}>
        <div style={{width:"100%", padding:20, textAlign:"left", color:"white", fontSize:30}}>
            Ergebnis: {getGesamtErgebnis()}
        </div>
        {getGameArea()}
        {selectedGameId !== undefined &&
            <StatistikGameDialog tournamentId={tournament.id} tournamentGameId={selectedGameId} onClose={() => {
                setSelectedGameId(undefined);
            }}/>
        }
    </div>
}

