import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Button} from "primereact/button";

function TurnierSponsorenTabPanel({onError, sponsoren, changeSponsoren}) {

    let [sponsorenZurAuswahl, setSponsorenZurAuswahl] = useState([]);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.sponsoren + "/all",
            json => {
                setSponsorenZurAuswahl(json)
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
            },
            error => {
                onError("Fehler: " + error.message);
            }
        );
    }, []);

    function getRows() {
        let rows = [];
        for (const tmpSponsor of sponsorenZurAuswahl) {
            let image;
            let sponsorIsGewaehlt = sponsoren.includes(tmpSponsor.id);
            if (sponsorIsGewaehlt) {
                image = <img src="/images/check2.png"
                             style={{width: 30, marginRight: 10, marginTop: 7}}
                             alt="Turnieradmin"/>;
            } else {
                image = ""
            }
            rows.push(<div className="grid" key={"row_sponsor" + tmpSponsor.id} style={{marginBottom: 20}}>
                <div className="col-fixed" style={{width: 100}}>
                    {image}
                    {!sponsorIsGewaehlt && <Button icon="pi pi-plus-circle"
                                              onClick={() => {
                                                  let newSponsoren = [...sponsoren];
                                                  newSponsoren.push(tmpSponsor.id);
                                                  changeSponsoren(newSponsoren);
                                              }}/>}
                    {sponsorIsGewaehlt && <Button icon="pi pi-trash"
                                             onClick={() => {
                                                 let newSponsoren = [];
                                                 for (const tmp of sponsoren) {
                                                     if( tmp !== tmpSponsor.id) {
                                                         newSponsoren.push(tmp);
                                                     }
                                                 }
                                                 changeSponsoren(newSponsoren);
                                             }}/>}
                </div>
                <div className="col">
                    {tmpSponsor.anzeigename}<br/>
                    {tmpSponsor.url}<br/>
                    {tmpSponsor.image}
                </div>
            </div>);
        }
        return rows;
    }

    return <div>
        {getRows()}
    </div>

}

TurnierSponsorenTabPanel.propTypes = {
    sponsoren: PropTypes.array.isRequired,
    changeSponsoren: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default TurnierSponsorenTabPanel;
