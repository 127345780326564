import React, {useEffect, useState} from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";

import Table from "./trainingsergebnisTable";
import ErrorInfoComponent from "./ErrorInfoComponent";

export default function Trainingsergebnisse({trainingssessionid, counter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [spieler, setSpieler] = useState(undefined);
    let [trainingsergebnisse, setTrainingsergebnisse] = useState([]);
    let [playerIdsBezahlt, setPlayerIdsBezahlt] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [trainingssessionid, counter]);

    const ladeDaten = () => {
        if(trainingssessionid === undefined) {
            return;
        }
        let data = {id: trainingssessionid}
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.training + "/trainingsergebnisse",
            data,
            json => {
                setSpieler(json.playerDtos);
                setTrainingsergebnisse(json.trainingsergebnisseDtos);
                setPlayerIdsBezahlt(json.playerIdsBezahlt);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }

    if( spieler === undefined) {
        return <div style={{textAlign:"left"}}>
                <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
                <div>Aktuell sind keine Daten zur gewählten Trainingssession vorhanden...</div>
            </div>
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom:"20px", fontWeight:"bold", textAlign:"left"}}>Ergebnisse zur Trainingssession</div>
        <Table spieler={spieler} trainingsergebnisse={trainingsergebnisse} payments={playerIdsBezahlt}/>
    </div>
}
