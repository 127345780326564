import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import PlatzierungTable from "../public/tournaments/PlatzierungTable";
import ExportPlatzierungenButton from "../tournament/ExportPlatzierungenButton";


export default function TournamentPlatzierungTab({tournamentId}) {
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [platzierungen, setPlatzierungen] = useState([]);

    useEffect(() => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/platzierung/" + tournamentId,
            {},
            json => {
                setPlatzierungen(json);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }, [tournamentId]);

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {platzierungen.length > 0 &&
            <div style={{padding:20, textAlign:"left"}}>
                <ExportPlatzierungenButton platzierungen={platzierungen}/>
            </div>
        }
        <PlatzierungTable platzierungen={platzierungen}/>
    </div>
}

