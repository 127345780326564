import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import {getFromLocalStorageWithDefault, saveToLocalStorage} from "../../util/localStorageZugriff";
import {NEWS_ARRAY} from "./news";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";

export default function NewsArea({proVisible, onClose}) {

    let [news, setNews] = useState([]);
    let [bereitsAngeschaut, setBereitAngeschaut] = useState([]);
    let [visible, setVisible] = useState(proVisible);

    let [selectedNewsId, setSelectedNewsId] = useState(undefined);

    useEffect(() => {
        setBereitAngeschaut(getFromLocalStorageWithDefault("dh_news_viewed", []));
        let newsDate = getFromLocalStorageWithDefault("dh_next_news_time", undefined);
        if( newsDate === undefined ) {
            setVisible(true);
            setNewNewsDate();
        } else {
            if( newsDate < new Date().getTime()) {
                setVisible(true);
                setNewNewsDate();
            }
        }
    }, []);

    useEffect(() => {
        if( visible ) {
            loadNews();
        }
    }, [visible]);

    const setNewNewsDate = () => {
        var nextNewsDate = new Date();
        var duration = 1; // in 1 Tagen sollen die News erneut angezeigt werden.
        nextNewsDate.setTime(nextNewsDate.getTime() +  (duration * 24 * 60 * 60 * 1000));
        saveToLocalStorage("dh_next_news_time", nextNewsDate.getTime());
    }

    const loadNews = () => {
        let newNews = [];
        for (const newsarrayElement of NEWS_ARRAY) {
            newNews.push(newsarrayElement);
        }
        setNews(newNews);
    }

    const setAngeschaut = (id) => {
        let tmp = [...bereitsAngeschaut];
        tmp.push(id);
        setBereitAngeschaut(tmp);
        saveToLocalStorage("dh_news_viewed", tmp);
        sendWatchedToServer(id);
    }

    const newsOhneBereitsAngeschaut = () => {
        return news.filter(n => !bereitsAngeschaut.includes(n.id))
    }

    const newsbereitsAngeschaut = () => {
        return news.filter(n => bereitsAngeschaut.includes(n.id))
    }

    const sendWatchedToServer = (id) => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.news + "/watched/" + id,
            {},
            () => {
            },
            responseNotOk => {
            },
            error => {
            }
        );

    }

    const getRows = () => {
        const rows = [];
        for (const tmpNews of newsOhneBereitsAngeschaut()) {
            rows.push(<div key={"key_news_" + tmpNews.id} style={{marginTop: 20}}>
                <hr style={{borderTop: "1px dotted #000"}}/>
                <div style={{marginBottom: 5}}>Datum: {tmpNews.datum}</div>
                <div style={{fontWeight: "bold", marginBottom: 10}}>{tmpNews.title}</div>
                {tmpNews.text}
                <div style={{marginTop: 10}}>
                    <Button label="Diese News nicht mehr anzeigen" onClick={() => setAngeschaut(tmpNews.id)}/>
                </div>
            </div>)
        }
        return rows;
    }

    const getRowsBereitsAngeschaut = () => {
        const rows = [];
        for (const tmpNews of newsbereitsAngeschaut()) {
            rows.push(<div key={"key_news_" + tmpNews.id} style={{marginTop:20, cursor:"pointer"}} onClick={() => {
                if( selectedNewsId === tmpNews.id) {
                    setSelectedNewsId(undefined);
                } else {
                    setSelectedNewsId(tmpNews.id);
                }
            }
            }>
                <div style={{marginBottom:5}}>Datum: {tmpNews.datum}</div>
                <div style={{fontWeight:"bold", marginBottom:10}}>{tmpNews.title}</div>
                {selectedNewsId === tmpNews.id && <>
                    {tmpNews.text}
                </>}
                <hr style={{borderTop: "1px dotted #000"}}/>
            </div>)
        }
        return rows;
    }

    if( !proVisible && (news.length === 0 || getRows().length === 0)) {
        return "";
    }

    return <Sidebar visible={visible} style={{maxWidth:600, minWidth: 350}} fullScreen onHide={() => onClose(false)}
                    position="center" showCloseIcon={false}>
        <Button label="Bereich schliessen" onClick={() => onClose(false)} style={{margin: 10}}/>
        {getRows().length === 0 && <div>Keine News vorhanden...</div>}
        {getRows()}
        {newsbereitsAngeschaut().length > 0 && <div style={{paddingTop:30}}>
            <hr/>
            <div style={{fontWeight: "bold"}}>News die bereits angeschaut hast</div>
            {getRowsBereitsAngeschaut()}
        </div>
        }
    </Sidebar>
}