import {connect} from 'react-redux'
import Impressum from "../components/Impressum";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Impressum)