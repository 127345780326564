import React from 'react'
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import ConfigUtil from "../../../util/ConfigUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import KalenderComponent from "./KalenderComponent";
import {Card} from "primereact/card";

let stompClient = undefined;

class Kalender extends React.Component {

    constructor(props) {
        super(props);

        this.state = {events:[], orgEvents:[], flatEvents: []};

        this.websocketConnect = this.websocketConnect.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.props.userId);
    }

    componentWillUnmount() {
        if( stompClient !== undefined) {
            console.log("disconnect");
            stompClient.disconnect();
        }
    }

    websocketConnect(userId) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                const destination = "/topic/public/user/events/" + this.props.userId;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const body = JSON.parse(payload.body);
        let events = [];
        for (const event of body.events) {
            let title = event.userEigentuemerName;
            if( event.gegnerName !== null && event.gegnerName !== undefined ) {
                title += " vs. " + event.gegnerName;
            }
            events.push({
                id: event.id,
                title: title,
                start: event.isoTime,
            });
        }
        this.setState({events: events, orgEvents:body.events, flatEvents: body.flatEvents});
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
        let readonly = false;
        return (<div
                style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "gray"}}>
                    <div className="col" style={{marginTop: 10, padding: 0}}>
                        Kalender
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div style={{padding:10}}>
                        <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                            Hier kannst du deine Termine eintragen die anderen als 'ich habe Zeit und möchte spielen' angezeigt werden.
                            <br/>
                            Die Termine werden in Ligen u. evtl. in Turnieren mit deiner Beteiligung angezeigt.
                            <br/>
                            Sofern ein Mitspieler einen Termin annimmt oder wieder storniert, wirst du von darthelfer.de hier unter Posteingang benachrichtigt.
                        </Card>
                    <KalenderComponent userId={this.props.userId} readOnly={readonly} events={this.state.events} orgEvents={this.state.orgEvents} flatEvents={this.state.flatEvents}/>
                </div>
            </div>
        )
    }

}


Kalender.propTypes = {
    userId: PropTypes.string.isRequired,
};



export default Kalender
