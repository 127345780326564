import React from 'react'
import {Messages} from 'primereact/messages';
import {TabPanel, TabView} from 'primereact/tabview';

import {Toast} from "primereact/toast";
import StatisticsTrainingsessions from "./StatisticsTrainingsessions";
import StatisticsHeadToHead from "./StatisticsHeadToHead";
import StatisticsGamedays from "./StatisticsGamedays";
import Trainingtabelle from "../../general/Trainingtabelle";
import StatisticsHeadToAll from "./StatisticsHeadToAll";
import PropTypes from "prop-types";

class Statistics extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#ffffcc"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Auswertungen
                </div>
            </div>
            <div className="grid" style={{padding: 20, marginLeft: 0, marginRight: 0}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <TabView style={{width:"100%", paddingTop:10, paddingLeft:10, paddingRight:10}}>
                    <TabPanel header="Gesamttabelle">
                        <Trainingtabelle loadGesamt={true} ueberschrift="Gesamttabelle"/>
                    </TabPanel>
                    <TabPanel header="Einzelne Trainingssession">
                        <StatisticsTrainingsessions/>
                    </TabPanel>
                    <TabPanel header="Head to Head">
                        <StatisticsHeadToHead/>
                    </TabPanel>
                    <TabPanel header="Head to All">
                        <StatisticsHeadToAll/>
                    </TabPanel>
                    <TabPanel header="Spieltage">
                        <StatisticsGamedays userId={this.props.userId}/>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    }

}

Statistics.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default Statistics
