import React, {useEffect, useState} from 'react'
import {PrimeIcons} from "primereact/api";
import {getFromLocalStorageWithDefault, saveToLocalStorage} from "../../../../util/localStorageZugriff";

export default function LiveViewGameDetailAuswahl({onClose}) {

    let [showAverage, setShowAverage] = useState(false);
    let [showAverageLeg, setShowAverageLeg] = useState(false);
    let [showAverageFirst9, setShowAverageFirst9] = useState(false);
    let [show100Plus, setShow100Plus] = useState(false);
    let [show140Plus, setShow140Plus] = useState(false);
    let [show171er, setShow171er] = useState(false);
    let [show180er, setShow180er] = useState(false);
    let [showBestLeg, setShowBestLeg] = useState(false);
    let [showWorstLeg, setShowWorstLeg] = useState(false);
    let [showHighOut, setShowHighOut] = useState(false);

    useEffect(() => {
        loadFromLocalStorage()
    },[]);

    useEffect(() => {
        saveToLocalStorage("dh_frontend_x01_live_view_show_average", showAverage);
        saveToLocalStorage("dh_frontend_x01_live_view_show_averageleg", showAverageLeg);
        saveToLocalStorage("dh_frontend_x01_live_view_show_averagefirst9", showAverageFirst9);
        saveToLocalStorage("dh_frontend_x01_live_view_show_100plus", show100Plus);
        saveToLocalStorage("dh_frontend_x01_live_view_show_140plus", show140Plus);
        saveToLocalStorage("dh_frontend_x01_live_view_show_171er", show171er);
        saveToLocalStorage("dh_frontend_x01_live_view_show_180er", show180er);
        saveToLocalStorage("dh_frontend_x01_live_view_show_bestleg", showBestLeg);
        saveToLocalStorage("dh_frontend_x01_live_view_show_worstleg", showWorstLeg);
        saveToLocalStorage("dh_frontend_x01_live_view_show_highout", showHighOut);
    },[showAverage, showAverageLeg, showAverageFirst9, show100Plus, show140Plus, show171er, show180er, showBestLeg, showWorstLeg, showHighOut]);


    const loadFromLocalStorage = () => {
        setShowAverage(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_average", true));
        setShowAverageLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_averageleg", true));
        setShowAverageFirst9(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_averagefirst9", false));
        setShow100Plus(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_100plus", true));
        setShow140Plus(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_140plus", true));
        setShow171er(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_171er", false));
        setShow180er(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_180er", true));
        setShowBestLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_bestleg", true));
        setShowWorstLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_worstleg", false));
        setShowHighOut(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_highout", false));
    }

    const getAuswahlArea = (label, field, setField) => {
        return <div style={{marginBottom:20, cursor:"pointer", color: field ? "green" : "red"}} onClick={() => setField(!field)}>{label}</div>
    }

    return <div key={"key_live_game_detail_auswahl"} style={{
        backgroundColor: "#01405b",
        border: "1px solid gray",
        padding: 20,
        width: "100%",
        fontSize: 30,
        color: "white",
    }}>
        <div onClick={() => onClose(false)} style={{marginBottom: 30, cursor: "pointer"}}>
            <i className={PrimeIcons.TIMES_CIRCLE}
               style={{fontSize: 30, marginRight: 20, color: "#ac1c27", cursor: "pointer"}}
               onClick={() => onClose(false)}/>
            Detailbereich schliessen!
        </div>
        {getAuswahlArea("Zeige Average", showAverage, setShowAverage)}
        {getAuswahlArea("Zeige Leg-Average", showAverageLeg, setShowAverageLeg)}
        {getAuswahlArea("Zeige First 9 -Average", showAverageFirst9, setShowAverageFirst9)}
        {getAuswahlArea("Zeige 100+", show100Plus, setShow100Plus)}
        {getAuswahlArea("Zeige 140+", show140Plus, setShow140Plus)}
        {getAuswahlArea("Zeige 171er", show171er, setShow171er)}
        {getAuswahlArea("Zeige 180er", show180er, setShow180er)}
        {getAuswahlArea("Zeige Best Leg", showBestLeg, setShowBestLeg)}
        {getAuswahlArea("Zeige Worst Leg", showWorstLeg, setShowWorstLeg)}
        {getAuswahlArea("Zeige High Out", showHighOut, setShowHighOut)}
    </div>
}
