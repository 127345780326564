import React from 'react'
import PlayerUtil from "../../../util/PlayerUtil";

export default function SchweizerSystemRundeTabelle({tournament, tabellen, isSetModus, selectedPlayerId, gruppe, runde, onPlayerSelect}) {

    const getTableArea = () => {
        if( !tabellen ) {
            return "";
        }
        let rows = [];
        let position = 1;
        rows.push(<div key={"table_header_1_group_" + gruppe + "_runde_" + runde} className="grid" style={{paddingLeft:10,paddingRight:10, paddingTop:10}}>
            <div className="col"></div>
            <div className="col-fixed" style={{width:50, textAlign:"center", backgroundColor:"#E6946C", fontSize:12}}>Punkte</div>
            <div className="col-fixed" style={{width:75, textAlign:"center", backgroundColor:"#E0AF97", fontSize:12}}>Spiele</div>
            <div className="col-fixed" style={{width:50, textAlign:"center", backgroundColor:"#E6946C", fontSize:12}}>{isSetModus ? "Sets" : "Legs"}</div>
        </div>);
        rows.push(<div key={"table_header_2_group_" + gruppe + "_runde_" + runde} className="grid" style={{paddingLeft:10,paddingRight:10}}>
            <div className="col-fixed" style={{width:20}}></div>
            <div className="col"></div>
            <div className="col-fixed" style={{width:50, backgroundColor:"#E6946C"}}></div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>S</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>U</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>V</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>G</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>V</div>
        </div>);
        let anzahlDieWeiterKommen = tournament.anzahlSpielerProGruppeFuerKOPhase;
        let rangliste = tabellen.rangliste;
        for(let i = 0; i < rangliste.length ;i++) {
            let ranglisteEinzel = rangliste[i];
            let styleRow = i < anzahlDieWeiterKommen ? {backgroundColor: "#82e3d9"} : {};
            if( selectedPlayerId !== undefined && selectedPlayerId === ranglisteEinzel.playerid ) {
                styleRow = {...styleRow, backgroundColor: "#FD8943"};
            }
            rows.push(<div  key={"table_header_group_" + gruppe + "_runde_" + runde + "_playerid_" + ranglisteEinzel.playerid}
                            className="grid" style={{paddingLeft:10,paddingRight:10, paddingTop:5}}
                            onMouseOver={() => onPlayerSelect(ranglisteEinzel.playerid)} onMouseOut={() => onPlayerSelect(undefined)}>
                <div className="col-fixed" style={{...styleRow,width:20}}>{position}.</div>
                <div className="col" style={{...styleRow, verticalAlign: "middle", display:"flex"}}>
                    {PlayerUtil.getSpielerName(tournament.tournamentPlayerEntities, ranglisteEinzel.playerid)}
                </div>
                <div className="col-fixed" style={{width:50, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.punkte}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.gewonnenespiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.unentschiedenspiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.verlorenespiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.anzahlLegsGewonnen}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.anzahlLegsVerloren}</div>
            </div>);
            position++;
        }
        return rows;
    }

    return <div style={{minWidth: 400, backgroundColor: "white", color: "black", textAlign: "left", marginTop: 10}}>
        <div style={{backgroundColor: "#26a69a", color: "#f2f2f2", fontSize: 16, fontWeight: "bold", textAlign: "center", padding:10}}>
            <div>Gruppe {gruppe} / Runde {runde} - Tabelle</div>
        </div>
        <div style={{minWidth: 400, marginTop:10}}>
            <div style={{backgroundColor: "#d6f5f3"}}>
                {getTableArea()}
            </div>
        </div>
    </div>
}

