import React, {useState} from 'react'
import {LigaShortComponent} from "./ligaComponents";
import PropTypes from "prop-types";
import {Paginator} from "primereact/paginator";

function ProfilLigenArea({ligenAktuell, ligenAbgeschlossen}) {

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);

    const abgeschlossenRows = () => {
        let returnRows = [];
        for(let i = first; i < (first + rows) ; i++) {
            let turnier = ligenAbgeschlossen[i];
            if( turnier !== undefined) {
                returnRows.push(turnier);
            }
        }
        return returnRows;
    }

    return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
        <div className="ligashortoverview"
             style={{height: "100%", borderLeft: "10px solid #b3ccff", textAlign: "left"}}>
            <h3>Ligeninformationen</h3>
            <h4>Aktive Ligen</h4>
            <div className="grid" style={{marginTop: "20px"}}>
                <LigaShortComponent ligen={ligenAktuell}/>
            </div>
            <h4>Abgeschlossene Ligen</h4>
            <Paginator first={first} rows={rows} totalRecords={ligenAbgeschlossen.length} rowsPerPageOptions={[5, 10, 20, 30]} onPageChange={(e) => {
                setFirst(e.first);
                setRows(e.rows);
            }}>></Paginator>
            <div className="grid" style={{marginTop: "20px"}}>
                <LigaShortComponent ligen={abgeschlossenRows()}/>
            </div>
        </div>
    </div>
}

ProfilLigenArea.propTypes = {
    ligenAktuell: PropTypes.array.isRequired,
    ligenAbgeschlossen: PropTypes.array.isRequired,
};

export default ProfilLigenArea;
