import {connect} from 'react-redux'
import withRouter from "../../../../components/RouterComponentWrapper";
import PublicEventoperator from "../../../../components/views/public/eventoperator/PublicEventoperator";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";

const mapStateToProps = function (state, ownProps) {
    let eventOperatorId = ownProps.router.location.pathname.substring(NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route.length + 1);
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const tab = urlParams.get('tab');
    const ranking = urlParams.get('ranking');
    return {userId: state.user.id, isLoggedIn: state.user.isLoggedIn, eventOperatorId: eventOperatorId, tab:tab, ranking: ranking};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicEventoperator))
