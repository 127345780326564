import React, {useEffect, useRef, useState} from 'react'
import {PrimeIcons} from "primereact/api";
import {OverlayPanel} from "primereact/overlaypanel";
import {Checkbox} from "primereact/checkbox";

export default function LiveTVGame({game, proBoardName, favorits, playerIdsChecked, boardId, showAverage, showLastThrow, onFavorit, onShowAverage, onLastThrow}) {

    const filterTournamentsOP = useRef(null);

    const COL_AVERAGE_WIDTH = 70;
    const COL_SETS_WIDTH = 30;
    const COL_LEGS_WIDTH = 40;
    const COL_TOGO_WIDTH = 40;
    const COL_LAST_TRHOW_WIDTH = 40;

    const [boardName, setBoardName] = useState(proBoardName);

    useEffect( () => {
        if( game !== undefined) {
            setBoardName(game.boardName);
        }
    } ,[game]);

    const isEDart = () => {
        if( game === undefined) {
            return false;
        }
        return game.dartType === "EDART";
    }

    const isSetModus = () =>  {
        if( game === undefined) {
            return false;
        }
        return game.sets > 1;
    }

    const isFavorit = () => {
        return favorits.filter(fav => fav.boardId === boardId).length > 0;
    }

    const isGameStartsSoon = () => {
        return game !== undefined && game.startssoon !== undefined && game.startssoon;
    }

    const isInGame = () => {
        return game !== undefined && game.inGame !== undefined && game.inGame;
    }

    const isGameAbgeschlossen = () => {
        return game !== undefined && game.abgeschlossen
    }

    const getModus = () => {
        if( game === undefined || isGameStartsSoon() || isInGame() || isGameAbgeschlossen()) {
            return "";
        }
        if( !isSetModus()) {
            if( game.fistToLegs !== null && game.fistToLegs !== undefined ) {
                return "first to " + game.fistToLegs + " legs";
            } else {
                return "best of " + game.bestOfLegs + " legs ";
            }
        } else {
            if( game.fistToLegs !== null && game.fistToLegs !== undefined ) {
                return "sets: " + game.sets + " ftl: " + game.fistToLegs;
            } else {
                return "sets: " + game.sets + " bol: " + game.bestOfLegs;
            }
        }
    }

    const getPlayerValueCol = (value, width, border, styleBackgroundImage, fontSize) => {
        let style = {
            margin: 0, color: "white", padding: 5, paddingRight: 5, width: width, textAlign: "center",
            borderBottom: border ? "1px solid #DDD" : "none", ...styleBackgroundImage
        };
        if( fontSize !== undefined) {
            style = {...style, fontSize: fontSize};
        }
        return <div className="col-fixed"
                    style={style}>
            {value}
        </div>
    }

    const getPlayerArea = (playerId, name, sets, legs, toGo, average, lastThrow, anwurf, active, mehrSetsLegsAlsGegner, border) => {

        let styleBackgroundImage = {backgroundImage: border ? "linear-gradient(to top, #10ab08, #096b04)" : "linear-gradient(to top, #096b04, #10ab08)"};
        let isGewinner = isGameAbgeschlossen() && mehrSetsLegsAlsGegner;

        let checked = playerIdsChecked.includes(playerId);

        return <div style={{backgroundColor: "white", color: "black", padding: 0, fontWeight:"500"}}>
            <div className="grid" style={{margin: 0, padding: 0, fontSize: 16, height: 32}}>
                <div className="col-fixed"
                     style={{
                         margin: 0, color: "red", padding: 5, paddingRight: 5, width: 15, textAlign: "center",
                         borderBottom: border ? "1px solid #DDD" : "none"
                     }}>
                    {anwurf && !isGameAbgeschlossen() && <img src="/images/punkt_rot.png" style={{height: 10}}/>}
                </div>
                <div className="col" style={{
                    margin: 0, padding: 5,
                    whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                    borderBottom: border ? "1px solid #DDD" : "none",
                    opacity: isGameAbgeschlossen() && !isGewinner ? 0.2 : 1.0,
                    color: isGewinner ? "#10ab08" : "black",
                    fontWeight: isGewinner ? "700" : ""
                }}>
                    {name}
                </div>
                <div className="col-fixed"
                     style={{
                         margin: 0, color: "red", padding: 5, paddingRight: 5, width: 20, textAlign: "center",
                         borderBottom: border ? "1px solid #DDD" : "none"
                     }}>
                    {active && !isGameAbgeschlossen() && <img src="/images/pfeil_rechts_rot_transparent.png" style={{height: 12}}/>}
                </div>

                {showAverage && getPlayerValueCol(average !== "" ? average : "-", COL_AVERAGE_WIDTH, border, styleBackgroundImage)}
                {isSetModus() && getPlayerValueCol(sets, COL_SETS_WIDTH, border, styleBackgroundImage)}
                {getPlayerValueCol(legs, COL_LEGS_WIDTH, border, styleBackgroundImage)}
                {showLastThrow && getPlayerValueCol(lastThrow !== null && lastThrow !== undefined && lastThrow !== "" ? lastThrow : "-", COL_LAST_TRHOW_WIDTH, border, {color: 'rgba(255,255,255,0.6)', ...styleBackgroundImage})}
                {!isEDart() && !checked && getPlayerValueCol(toGo, COL_TOGO_WIDTH, border, styleBackgroundImage)}
                {!isEDart() && checked && getPlayerValueCol("Check", COL_TOGO_WIDTH, border, styleBackgroundImage, 10)}
            </div>
        </div>
    }

    const getPlayerEmtyArea = (text, border) => {
        return <div style={{backgroundColor: "white", color: "black", padding: 0, fontWeight: "500"}}>
            <div className="grid" style={{margin: 0, padding: 0, fontSize: 16, height: 32}}>
                <div className="col" style={{margin: 0, padding: 5, borderBottom: border ? "1px solid #DDD" : "none"}}>
                    {text}
                </div>
            </div>
        </div>
    }

    const getHeaderCol = (bez, width) => {
        return <div className="col-fixed darthelfer-background-tvview-green" style={{
            margin: 0, color: "white", padding: 5,
            backgroundImage: "linear-gradient(to top, #096b04, #10ab08)",
            borderBottom: "1px solid #DDD",
            paddingRight: 5, width: width, textAlign: "center"
        }}>
            {bez}
        </div>
    }

    const getHeader = () => {
        return <div className="darthelfer-background-tvview-header" style={{color: "white", padding: 0, fontWeight: "500"}}>
            <div className="grid" style={{margin: 0, padding: 0, fontSize: 14}}>
                <div className="col" style={{margin: 0, padding: 5, borderBottom: "1px solid #DDD",}}>
                    {getModus()}
                    {isGameStartsSoon() && <span style={{color: "red"}}>Spiel startet...</span>}
                    {isInGame() && <span style={{color:"red"}}>Spieler im Spiel...</span>}
                    {isGameAbgeschlossen() && <span style={{color:"red"}}>Spiel beendet...</span>}
                </div>
                {showAverage && getHeaderCol("Avg.", COL_AVERAGE_WIDTH)}
                {isSetModus() && getHeaderCol("Sets", COL_SETS_WIDTH)}
                {getHeaderCol("Legs", COL_LEGS_WIDTH)}
                {showLastThrow && getHeaderCol("", COL_LAST_TRHOW_WIDTH)}
                {!isEDart() && getHeaderCol("", COL_TOGO_WIDTH)}
            </div>
        </div>
    }

    const getTVViewWidth = () => {
        let width = 320;
        if( showAverage ) {
            width += COL_AVERAGE_WIDTH;
        }
        if( showLastThrow) {
            width += COL_LAST_TRHOW_WIDTH
        }
        return width;
    }

    return <>
        <div style={{color: "white", fontSize: 18, margin: 10, marginBottom: 20, textAlign: "left"}}>
            {boardName}
            <i className={isFavorit() ? PrimeIcons.STAR_FILL : PrimeIcons.STAR}
               style={{fontSize: 15, marginLeft: 20, marginRight:20, color: "white", cursor: "pointer"}}
               onClick={() => onFavorit(boardId, boardName)}/>
            <i className={PrimeIcons.FILTER} style={{color: "white", fontSize: 15, cursor:"pointer"}}
               onClick={(e) => filterTournamentsOP.current.toggle(e)}/>
            <OverlayPanel ref={filterTournamentsOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 150, width: 200}}>
                <div style={{marginTop: 10}}>
                    <Checkbox checked={showAverage}
                              onChange={() => onShowAverage(!showAverage)}
                              style={{marginLeft: 10, marginRight:10}}/>
                    Zeige Average
                </div>
                <div style={{marginTop:10}}>
                    <Checkbox checked={showLastThrow}
                              onChange={() => onLastThrow(!showLastThrow)}
                              style={{marginLeft: 10, marginRight:10}}/>
                    Zeige letzten Wurf
                </div>
            </OverlayPanel>
        </div>
        <div key={"key_live_tv_board_" + boardId} style={{
            width: getTVViewWidth(),
            backgroundColor: "#01405b",
            border: "1px solid gray",
            margin: 10,
            padding: 0,
            textAlign: "left",
    }}>
        {getHeader()}
        {game === undefined && <>
            {getPlayerEmtyArea("Am Board wird aktuell nicht gespielt.", true)}
            {getPlayerEmtyArea("", false)}
            </>
        }
        {game !== undefined && <>
            {getPlayerArea(game.spieler1Id, game.spieler1Name, game.spieler1Sets, game.spieler1Legs,
                game.spieler1Togo, game.spieler1Average, game.spieler1LastThrow,
                game.spieler1Id === game.spielerIdLegAnwurf, game.spieler1Id === game.spielerIdActive,
                game.sets === 1 ? game.spieler1Legs > game.spieler2Legs : game.spieler1Sets > game.spieler2Sets, true)}
            {getPlayerArea(game.spieler2Id, game.spieler2Name, game.spieler2Sets, game.spieler2Legs,
                game.spieler2Togo, game.spieler2Average, game.spieler2LastThrow,
                game.spieler2Id === game.spielerIdLegAnwurf, game.spieler2Id === game.spielerIdActive,
                game.sets === 1 ? game.spieler2Legs > game.spieler1Legs : game.spieler2Sets > game.spieler1Sets, false)}
        </>}

        <div className="darthelfer-background-tvview-header" style={{borderTop:"1px solid #DDD", padding:5}}>
            <div className="grid" style={{margin:0, padding:0}}>
                <div className="col" style={{margin:0, padding:0, textAlign:"right", color:"white"}}>
                    <img alt="Logo" src={"images/darthelfer_quer_weiss.png"} style={{height: 20}}/>
                </div>
            </div>
        </div>
    </div>
    </>
}
