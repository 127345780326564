import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import DoppelStatisticArea from "../../../components/views/liga/DoppelStatistikArea";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const doppelpartnerId = urlParams.get('doppelpartnerid');
    return {doppelpartnerid: doppelpartnerId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DoppelStatisticArea))