export const NEW_LEAGUEOPERATORS = 'NEW_LEAGUEOPERATORS';

export const newLeagueOperators = (leagueOperators) => ({
    type: NEW_LEAGUEOPERATORS,
    value: leagueOperators,
});

export function leagueOperators(leagueOperators) {
    return function (dispatch) {
        dispatch(newLeagueOperators(leagueOperators));
    };
}