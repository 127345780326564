import React, {useState} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function RanglisteDetailArea({eventOperatorId, rangliste, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [id, setId] = useState(rangliste.id);
    let [bezeichnung, setBezeichnung] = useState(rangliste.bezeichnung !== undefined ? rangliste.bezeichnung : "");
    let [bestleistung, setBestleistung] = useState(rangliste.punkteBestleistung !== undefined ? rangliste.punkteBestleistung : 0);
    let [anwesenheit, setAnwesenheit] = useState(rangliste.punkteAnwesenheit !== undefined ? rangliste.punkteAnwesenheit : 0);
    let [anzahlBesteErgebnisse, setAnzahlBesteErgebnissee] = useState(rangliste.anzahlBesteErgebnisse !== undefined ? rangliste.anzahlBesteErgebnisse : 999);
    let [einmalpunkte, setEinmalpunkte] = useState(rangliste.einmalpunkte !== undefined ? rangliste.einmalpunkte : 0);
    let [einmalpunkteFaktor, setEinmalpunkteFaktor] = useState(rangliste.einmalpunkteFaktor !== undefined ? rangliste.einmalpunkteFaktor : 0);

    const saveRangliste = () => {
        let data = {
            id: id,
            eventOperatorId: eventOperatorId,
            bezeichnung: bezeichnung,
            punkteBestleistung: bestleistung,
            punkteAnwesenheit : anwesenheit,
            einmalpunkte: einmalpunkte,
            einmalpunkteFaktor: einmalpunkteFaktor,
            anzahlBesteErgebnisse: anzahlBesteErgebnisse,
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/rangliste/save",
            data,
            id => {
                setId(id);
                onRefresh();
                setInfoMessage("Rangliste wurde erfolgreich gespeichert.");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Spieler: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Laden der Spieler: " + error.message)
            })
    }

    const isSaveActive = () => {
        return bezeichnung.trim() !== "" && bestleistung !== null && anwesenheit !== null && einmalpunkte !== null && einmalpunkteFaktor !== null;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>
            <Button label="Zurück" icon={PrimeIcons.ARROW_LEFT} onClick={() => onClose()}/>
            <Button label="Speichern" icon={PrimeIcons.SAVE} onClick={() => saveRangliste()} style={{marginLeft: 10}}
                    disabled={!isSaveActive()}/>
        </div>
        <div>
            <div style={{marginTop: 20}}>
                Bezeichnung
            </div>
            <div>
                <InputText value={bezeichnung} style={{width: 300}}
                           onChange={(e) => setBezeichnung(e.target.value)}/>
            </div>
            <div style={{marginTop: 20}}>
                Punkte für Bestleistung
            </div>
            <div>
                <InputNumber value={bestleistung} style={{width: 50}} onChange={(e) => setBestleistung(e.value)}/>
            </div>
            <div style={{marginTop: 20}}>
                Punkte für Anwesenheit
            </div>
            <div>
                <InputNumber value={anwesenheit} style={{width: 50}} onChange={(e) => setAnwesenheit(e.value)}/>
            </div>
            <hr/>
            <div style={{marginTop: 20}}>
                Anzahl beste Ergebnisse die gewertet werden sollen
            </div>
            <div>
                <InputNumber value={anzahlBesteErgebnisse} style={{width: 50}} onChange={(e) => setAnzahlBesteErgebnissee(e.value)}/>
            </div>
            <hr/>
            <div style={{marginTop: 20}}>
                Hinweis Einmalpunkte: Sobald ein Spieler zum ersten Mal an einem Ranglistenturnier teilnimmt, erhält er
                die Einmalpunkte mit dem angegebenen Faktor.<br/>
                Beispiel: Einmalpunkte 8 + Einmalpunkte 8 + Turnierteilnahme 6 = Schnitt in der Tabelle wäre dann
                (8+8+6)/3=7.33
            </div>
            <div style={{marginTop: 20}}>
                Einmalpunkte
            </div>
            <div>
                <InputNumber value={einmalpunkte} style={{width: 50}} onChange={(e) => setEinmalpunkte(e.value)}/>
            </div>
            <div style={{marginTop: 20}}>
                Einmalpunkte Faktor
            </div>
            <div>
                <InputNumber value={einmalpunkteFaktor} style={{width: 50}}
                             onChange={(e) => setEinmalpunkteFaktor(e.value)}/>
            </div>
        </div>
    </div>
}
