import React from "react";
import {Chart} from "primereact/chart";

export function HeadToHeadStatistik(props) {

    const dataLegs = {
        labels: ["G - " + props.gewonneneLegsGesamt, "V - " + props.verloreneLegsGesamt],
        datasets: [
            {
                data: [props.gewonneneLegsGesamt, props.verloreneLegsGesamt],
                backgroundColor: [
                    "#008000",
                    "#ff0000",
                ],
                hoverBackgroundColor: [
                    "#008000",
                    "#ff0000",
                ]
            }]
    };
    const dataSpiele = {
        labels: ["G - " + props.gewonneneSpieleGesamt, "V - " + props.verloreneSpieleGesamt],
        datasets: [
            {
                data: [props.gewonneneSpieleGesamt, props.verloreneSpieleGesamt],
                backgroundColor: [
                    "#008000",
                    "#ff0000",
                ],
                hoverBackgroundColor: [
                    "#008000",
                    "#ff0000",
                ]
            }]
    };

    return (
        <div style={{marginTop:30}}>
            <div className="grid">
                <div className="col" style={{fontWeight:"bold"}}>Gesamtstatistik</div>
            </div>
            <div className="grid">
                <div className="col-fixed">Spiele<br/><Chart type="doughnut" data={dataSpiele} width={150} height={150}/></div>
                <div className="col-fixed">Legs<br/><Chart type="doughnut" data={dataLegs} width={150} height={150}/></div>
            </div>
        </div>
        );
}