import React, {useEffect, useState} from 'react'
import {PrimeIcons} from "primereact/api";
import LiveViewGameDetailAuswahl from "./LiveViewGameDetailAuswahl";
import {getFromLocalStorageWithDefault} from "../../../../util/localStorageZugriff";

export default function LiveViewGameDetail({game, onClose}) {

    let [showAverage, setShowAverage] = useState(false);
    let [showAverageLeg, setShowAverageLeg] = useState(false);
    let [showAverageFirst9, setShowAverageFirst9] = useState(false);
    let [show100Plus, setShow100Plus] = useState(false);
    let [show140Plus, setShow140Plus] = useState(false);
    let [show171er, setShow171er] = useState(false);
    let [show180er, setShow180er] = useState(false);
    let [showBestLeg, setShowBestLeg] = useState(false);
    let [showWorstLeg, setShowWorstLeg] = useState(false);
    let [showHighOut, setShowHighOut] = useState(false);

    let [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth}
    );
    let [showAuswahl, setShowAuswahl] = useState(false);

    useEffect(() => {
        loadFromLocalStorage()
    },[]);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const loadFromLocalStorage = () => {
        setShowAverage(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_average", true));
        setShowAverageLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_averageleg", true));
        setShowAverageFirst9(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_averagefirst9", false));
        setShow100Plus(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_100plus", true));
        setShow140Plus(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_140plus", true));
        setShow171er(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_171er", false));
        setShow180er(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_180er", true));
        setShowBestLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_bestleg", true));
        setShowWorstLeg(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_worstleg", false));
        setShowHighOut(getFromLocalStorageWithDefault("dh_frontend_x01_live_view_show_highout", false));
    }

    const detailHighlightArea = (header, wert) => {
        return <div style={{textAlign: "center", padding: 0, borderBottom:"1px solid white", marginTop:10, marginBottom:10}}>
            <div>{header}</div>
            <div style={{marginTop: 5, marginBottom:10}}>{wert}</div>
        </div>
    }

    const detailHighlightButtonArea = (header, wert) => {
        return <div style={{textAlign: "center", padding: 0, marginLeft:10, marginRight:10}}>
            <div>{header}:{wert}</div>
        </div>
    }

    const player = (name, togo, lastThrow, orientation, abgeschlossen, hatMehrSetsOderLegs, isLegBeginer, isActive, canCheck) => {
        let fontColorScore = "white";
        let backgroundColor = "";
        if( canCheck && isActive) {
            backgroundColor = "green";
            fontColorScore = "white";
        } else if( isActive) {
            backgroundColor = "yellow";
            fontColorScore = "black"
        } else if( canCheck) {
            fontColorScore = "green";
        }
        return <>
            <div style={{
                fontSize: isSmartphone() ? "6.0vw" : "4.0vw",
                padding: 10,
                borderRadius: 20,
                border: abgeschlossen ? "" : isActive ? "10px solid #7a1c23" : "10px solid #01405b",
                backgroundColor: abgeschlossen && hatMehrSetsOderLegs ? "green" : "#ac1c27",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}>
                {name}
            </div>
            {(abgeschlossen === undefined || !abgeschlossen) &&
                <div style={{marginTop: 10}}>
                    <div className="grid" style={{
                        display: "flex",
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        justifyContent: "left",
                        alignItems: "center",
                    }}>
                        {orientation === "left" &&
                            <div className="col-fixed" style={{
                                fontSize: isSmartphone() ? "4.0vw" : "3.0vw",
                                borderLeft: orientation === "left" && isLegBeginer ? "10px solid #ac1c27" : ""
                            }}>
                                {lastThrow !== null && lastThrow !== undefined ? lastThrow : "-"}
                            </div>
                        }
                        <div className="col" style={{fontSize: isSmartphone() ? "8.0vw" : "6.0vw", textAlign: "center", color: fontColorScore, backgroundColor: backgroundColor}}>
                            {togo}
                        </div>
                        {orientation === "right" &&
                            <div className="col-fixed" style={{
                                fontSize: isSmartphone() ? "4.0vw" : "3.0vw",
                                borderRight: orientation === "right" && isLegBeginer ? "10px solid #ac1c27" : ""
                            }}>
                                {lastThrow !== null && lastThrow !== undefined ? lastThrow : "-"}
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    }

    const getHeader = () => {
        return <div className="grid" style={{
            backgroundColor: "#ac1c27",
            color: "white",
            display: "flex",
            justifyContent: "center",
            verticalAlign: "middle",
            alignItems: "center",
            margin: 0,
            padding: 0,
            fontWeight: "bold",
            fontSize: isSmartphone() ? "3.5vw" : "2.5vw",
            marginBottom:5,
        }}>
            <div className="col" style={{textAlign: "center"}}>
                {game.boardName}
            </div>
            <div className="col-fixed" style={{textAlign: "center", width:50}}>
                <i className={PrimeIcons.PENCIL} style={{marginRight:10, fontSize: isSmartphone() ? "3.5vw" : "2.5vw", color:"white", cursor:"pointer"}} onClick={() => setShowAuswahl(!showAuswahl)}/>
            </div>
            {game.sets === 1 &&
                <div className="col" style={{textAlign: "center"}}>
                    {game.fistToLegs !== null && game.fistToLegs !== undefined && "first to " + game.fistToLegs + " legs"}
                    {game.bestOfLegs !== null && game.bestOfLegs !== undefined && "best of " + game.bestOfLegs + " legs"}
                </div>
            }
            {game.sets > 1 &&
                <div className="col" style={{textAlign: "center"}}>
                    {game.fistToLegs !== null && game.fistToLegs !== undefined && "sets: " + game.sets + " ftl: " + game.fistToLegs}
                    {game.bestOfLegs !== null && game.bestOfLegs !== undefined && "sets: " + game.sets + " bol: " + game.bestOfLegs}
                </div>
            }
        </div>
    }

    const getSmartphoneShortInfoArea = (sets, legs, average) => {
        return <div className="grid" style={{color: "white", margin: 0, padding: 0}}>
            {game.sets > 1 && <div className="col">{detailHighlightArea("S/L", sets !== null && sets !== undefined ? sets + "/" + legs: "-")}</div>}
            {game.sets === 1 && <div className="col">{detailHighlightArea("Legs", legs)}</div>}
            {<div className="col">{detailHighlightArea("Avg.", average !== null && average !== undefined && average !== "" ? average : "-")}</div>}
        </div>
    }

    const getHighlightArea = (sets, legs, average, averageFirst9, averageLeg, best, worstLeg, highOut, count100Plus, count140Plus, count171er, count180er) => {
        return <div style={{fontSize: isSmartphone() ? "4.0vw" : "2.0vw", color: "white", margin: 0, padding: 0}}>
            {game.sets > 1 && detailHighlightArea("S/L", sets !== null && sets !== undefined ? sets + "/" + legs: "-")}
            {game.sets === 1 && detailHighlightArea("Legs", legs)}
            {showAverage && detailHighlightArea("Avg.", average !== null && average !== undefined && average !== "" ? average : "-")}
            {showAverageFirst9 && detailHighlightArea("Avg.First 9", averageFirst9 !== null && averageFirst9 !== undefined ? averageFirst9 : "-")}
            {showAverageLeg && detailHighlightArea("Avg.Leg", averageLeg !== null && averageLeg !== undefined ? averageLeg : "-")}
            {isSmartphone() && show100Plus && detailHighlightArea("100+", count100Plus !== null && count100Plus !== undefined ? count100Plus : "-")}
            {isSmartphone() && show140Plus && detailHighlightArea("140+", count140Plus !== null && count140Plus !== undefined ? count140Plus : "-")}
            {isSmartphone() && show171er && detailHighlightArea("171", count171er !== null && count171er !== undefined ? count171er : "-")}
            {isSmartphone() && show180er && detailHighlightArea("180", count180er !== null && count180er !== undefined ? count180er : "-")}
            {isSmartphone() && showBestLeg && detailHighlightArea("Best", best !== null && best !== undefined ? best : "-")}
            {isSmartphone() && showWorstLeg && detailHighlightArea("Worst", worstLeg !== null && worstLeg !== undefined ? worstLeg : "-")}
            {isSmartphone() && showHighOut && detailHighlightArea("High Out", highOut !== null && highOut !== undefined ? highOut : "-")}
        </div>
    }

    const getHighlightBottomArea = (best, worstLeg, highOut, count100Plus, count140Plus, count171er, count180er) => {
        return <div style={{fontSize: "1.5vw", color: "white", margin: 0, padding: 0, display:"flex"}}>
            {show100Plus && detailHighlightButtonArea("100+", count100Plus !== null && count100Plus !== undefined ? count100Plus : "-")}
            {show140Plus && detailHighlightButtonArea("140+", count140Plus !== null && count140Plus !== undefined ? count140Plus : "-")}
            {show171er && detailHighlightButtonArea("171", count171er !== null && count171er !== undefined ? count171er : "-")}
            {show180er && detailHighlightButtonArea("180", count180er !== null && count180er !== undefined ? count180er : "-")}
            {showBestLeg && detailHighlightButtonArea("Best", best !== null && best !== undefined ? best : "-")}
            {showWorstLeg && detailHighlightButtonArea("Worst", worstLeg !== null && worstLeg !== undefined ? worstLeg : "-")}
            {showHighOut && detailHighlightButtonArea("High Out", highOut !== null && highOut !== undefined ? highOut : "-")}
        </div>
    }

    const isSmartphone = () =>  {
        if (dimensions.width < 800) {
            return true;
        }
        return false;
    }

    const getScoreArea = () => {
        let lineStyle = {
            verticalAlign: "middle",
            textAlign: "center",
            borderBottom: "1px solid black",
            margin: 0,
            padding: 0,
            backgroundColor: "#064d6b",
            color: "white",
            fontSize: isSmartphone() ? "4.0vw" : "2.0vw",
        };

        let rows = [];
        let anzScores = 0;
        if( game.legSpieler1 === null || game.legSpieler1 === undefined || game.legSpieler2 === null || game.legSpieler2 === undefined) {
            anzScores = 0;
        } else if( game.legSpieler1.scores.length >= game.legSpieler2.scores.length ) {
            anzScores = game.legSpieler1.scores.length;
        } else {
            anzScores = game.legSpieler2.scores.length;
        }
        rows.push(<div className="grid" key="key_score_header" style={lineStyle}>
            <div className="col">
                <div className="grid" style={{padding:0}}>
                    <div className="col">
                    </div>
                    <div className="col">
                        {game.startpunkte}
                    </div>
                </div>
            </div>
            <div className="col-fixed" style={{width: isSmartphone() ? 50 : 100, borderLeft:"2px solid gray", borderRight:"2px solid gray"}}></div>
            <div className="col">
                <div className="grid" style={{padding:0}}>
                    <div className="col">
                    </div>
                    <div className="col">
                        {game.startpunkte}
                    </div>
                </div>
            </div>
        </div>);
        for(let i = 0 ; i < anzScores; i++) {
            let modulo = i % 2;
            let currentStyle = modulo === 1 ? {...lineStyle, backgroundColor: "#064d6b"} : {
                ...lineStyle,
                backgroundColor: "#022836"
            };

            let scorePlayer1 = game.legSpieler1.scores[i];
            let scorePlayer2 = game.legSpieler2.scores[i];
            if( (scorePlayer1 === undefined || scorePlayer1.value === "") && (scorePlayer2 === undefined || scorePlayer2.value === "")) {
                // score ist leer, nichts ausgeben
                continue;
            }
            rows.push(<div className="grid" key={"key_score_" + i} style={currentStyle}>
                <div className="col">
                    <div className="grid" style={{padding:0}}>
                        <div className="col" style={{textDecoration: scorePlayer1 !== undefined && scorePlayer1.invalid !== null && scorePlayer1.invalid !== undefined && scorePlayer1.invalid ? "line-through" : ""}}>
                            {scorePlayer1 !== undefined && scorePlayer1.value !== "" && scorePlayer1.value}
                        </div>
                        <div className="col">
                            {scorePlayer1 !== undefined && scorePlayer1.value !== "" && scorePlayer1.togo}
                        </div>
                    </div>
                </div>
                <div className="col-fixed" style={{width: isSmartphone() ? 50 : 100, borderLeft:"2px solid gray", borderRight:"2px solid gray"}}>{ (i+1) * 3}</div>
                <div className="col">
                    <div className="grid" style={{padding:0}}>
                        <div className="col" style={{textDecoration: scorePlayer2 !== undefined && scorePlayer2.invalid !== null && scorePlayer2.invalid !== undefined && scorePlayer2.invalid ? "line-through" : ""}}>
                            {scorePlayer2 !== undefined && scorePlayer2.value}
                        </div>
                        <div className="col">
                            {scorePlayer2 !== undefined && scorePlayer2.togo}
                        </div>
                    </div>
                </div>
            </div>)
        }
        rows.push(<div className="grid" key="key_header" style={{...lineStyle, backgroundColor:"white", color:"black",
            padding: 0,
            margin: 0,
            textAlign: "center",
            alignContent:"center",
            fontWeight: "bold",
        }}>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
            <div className="col-fixed" style={{width: isSmartphone() ? 50 : 100, borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}/>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>scored</div>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white", textAlign:"center"}}>to go</div>
        </div>);

        let rowsGedreht = rows.reverse();
        return rowsGedreht;
    }

    const getPlayerArea = () => {
        return <div className="grid"
             style={{color: "white", margin: 0, padding: 0, textAlign: "center"}}>
            <div className="col-fixed" style={{margin: 0, padding: 0, width: "50%"}}>
                {player(game.spieler1Name, game.spieler1Togo, game.spieler1LastThrow, "left", game.abgeschlossen,
                    game.sets === 1 ? game.spieler1Legs > game.spieler2Legs : game.spieler1Sets > game.spieler2Sets,
                    game.spieler1Id === game.spielerIdLegAnwurf,
                    game.spieler1Id === game.spielerIdActive, game.canCheckSpieler1)}
            </div>
            <div className="col-fixed" style={{margin: 0, padding: 0, width: "50%"}}>
                {player(game.spieler2Name, game.spieler2Togo, game.spieler2LastThrow, "right", game.abgeschlossen,
                    game.sets === 1 ? game.spieler2Legs > game.spieler1Legs : game.spieler2Sets > game.spieler1Sets,
                    game.spieler2Id === game.spielerIdLegAnwurf,
                    game.spieler2Id === game.spielerIdActive, game.canCheckSpieler2)}
            </div>
        </div>
    }

    if( showAuswahl) {
        return <LiveViewGameDetailAuswahl onClose={() =>
        {
            setShowAuswahl(false);
            loadFromLocalStorage();
        }}/>
    }

    if( game === null ||game === undefined) {
        return <div key={"key_live_game_detail"} style={{
            backgroundColor: "#01405b",
            border: "1px solid gray",
            margin: 10,
            padding: 0,
        }}>asdf
        </div>
    }

    return <div key={"key_live_game_detail_" + game.gameId} style={{
        backgroundColor: "#01405b",
        border: "1px solid gray",
        margin: 10,
        padding: 0,
        width:"100%",
        maxWidth:1250,
    }}>
        {getHeader()}
        {getPlayerArea()}
        <hr/>
        {!isSmartphone() &&
            <div style={{width:"100%", backgroundColor:"#064d6b", borderTop:"2px solid gray"}}>
                <div className="grid" style={{padding:0, margin:0}}>
                    <div className="col" style={{width:"100%", display:"flex", textAlign:"left", justifyContent:"left", borderRight:"1px solid gray"}}>
                        {getHighlightBottomArea(
                            game.spieler1Best,
                            game.spieler1WorstLeg,
                            game.spieler1HighOut,
                            game.spieler1Count100Plus,
                            game.spieler1Count140Plus,
                            game.spieler1Count171er,
                            game.spieler1Count180er)
                        }
                    </div>
                    <div className="col" style={{width:"100%", display:"flex", textAlign:"right", justifyContent:"right", borderLeft:"1px solid gray"}}>
                        {getHighlightBottomArea(
                            game.spieler2Best,
                            game.spieler2WorstLeg,
                            game.spieler2HighOut,
                            game.spieler2Count100Plus,
                            game.spieler2Count140Plus,
                            game.spieler2Count171er,
                            game.spieler2Count180er)
                        }
                    </div>
                </div>
            </div>
        }
        {isSmartphone() && <div className="grid">
            <div className="col">
                {getSmartphoneShortInfoArea(
                    game.spieler1Sets,
                    game.spieler1Legs,
                    game.spieler1Average,
                )}
            </div>
            <div className="col">
                {getSmartphoneShortInfoArea(
                    game.spieler2Sets,
                    game.spieler2Legs,
                    game.spieler2Average,
                )}
            </div>
        </div>
        }
        <div className="grid" style={{padding:0, margin:0}}>
            {!isSmartphone() &&
                <div className="col-fixed" style={{margin: 0, padding: 0, width: 200}}>
                    {getHighlightArea(
                        game.spieler1Sets,
                        game.spieler1Legs,
                        game.spieler1Average,
                        game.spieler1AverageFirst9,
                        game.spieler1AverageLeg,
                        game.spieler1Best,
                        game.spieler1WorstLeg,
                        game.spieler1HighOut,
                        game.spieler1Count100Plus,
                        game.spieler1Count140Plus,
                        game.spieler1Count180er,
                        game.spieler1Count171er)}
                </div>
            }
            <div className="col" style={{border:"1px solid white", padding:0, margin:0}}>
                {game.abgeschlossen !== null && game.abgeschlossen !== undefined && game.abgeschlossen &&
                    <div style={{
                        fontSize: isSmartphone() ? "6.0vw" : "2.0vw",
                        color: "green",
                        margin: 0,
                        marginBottom: 40,
                        padding: 0,
                        verticalAlign: "middle",
                        textAlign: "center"
                    }}>
                        Spiel ist beendet
                    </div>
                }
                {game.startssoon !== undefined && game.startssoon &&
                    <div style={{
                        fontSize: isSmartphone() ? "5.0vw" : "3.0vw",
                        color: "green",
                        margin: 0,
                        marginBottom: 40,
                        padding: 0,
                        verticalAlign: "middle",
                        textAlign: "center"
                    }}>
                        ... Startet ...
                    </div>
                }
                {getScoreArea()}
            </div>
            {!isSmartphone() &&
                <div className="col-fixed" style={{margin: 0, padding: 0, width: 200}}>
                    {getHighlightArea(
                        game.spieler2Sets,
                        game.spieler2Legs,
                        game.spieler2Average,
                        game.spieler2AverageFirst9,
                        game.spieler2AverageLeg,
                        game.spieler2Best,
                        game.spieler2WorstLeg,
                        game.spieler2HighOut,
                        game.spieler2Count100Plus,
                        game.spieler2Count140Plus,
                        game.spieler2Count180er,
                        game.spieler2Count171er)}
                </div>
            }
        </div>
        {isSmartphone() &&
            <div className="grid" style={{padding:0, margin:0}}>
                <div className="col" style={{margin: 0, padding: 0}}>
                    {getHighlightArea(
                        game.spieler1Sets,
                        game.spieler1Legs,
                        game.spieler1Average,
                        game.spieler1AverageFirst9,
                        game.spieler1AverageLeg,
                        game.spieler1Best,
                        game.spieler1WorstLeg,
                        game.spieler1HighOut,
                        game.spieler1Count100Plus,
                        game.spieler1Count140Plus,
                        game.spieler1Count180er,
                        game.spieler1Count171er)}
                </div>
                <div className="col" style={{margin: 0, padding: 0}}>
                    {getHighlightArea(
                        game.spieler2Sets,
                        game.spieler2Legs,
                        game.spieler2Average,
                        game.spieler2AverageFirst9,
                        game.spieler2AverageLeg,
                        game.spieler2Best,
                        game.spieler2WorstLeg,
                        game.spieler2HighOut,
                        game.spieler2Count100Plus,
                        game.spieler2Count140Plus,
                        game.spieler2Count180er,
                        game.spieler2Count171er)}
                </div>
            </div>
        }
    </div>
}
