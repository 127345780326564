import React from 'react'
import LigaTable from "../../liga/ligaTable";


export default function PublicLigaTabelleTab({tournament, gruppenTabellen}) {


    const isSetModus = () => {
        let modusGruppenphase = tournament.tournamentModusEntities.find(element => element.modusTyp === "liga");
        return modusGruppenphase.sets > 1;
    }

    const getSpielerName = (id) => {
        if (id === undefined || tournament === null || tournament === undefined) {
            return "";
        }

        for (let i = 0; i < tournament.tournamentPlayerEntities.length; i++) {
            let spieler = tournament.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    if( gruppenTabellen === undefined || gruppenTabellen.rangliste === null) {
        return "Noch keine Daten vorhanden...";
    }

    return <div style={{backgroundColor:"#022836", padding:5, paddingTop:20}}>
        <LigaTable rows={gruppenTabellen[0].rangliste} callbackGetSpielername={(id) => getSpielerName(id)}
                   isSetModus={isSetModus()}/>
    </div>
}

