import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import DateUtil from "../../../../util/DateUtil";
import ConfirmDialog from "../../../general/ConfirmDialog";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";

export default function BoardTeilenArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [sharedBoardDeleteId, setSharedBoardDeleteId] = useState(undefined);

    const [selectedBoardId, setSelectedBoardId] = useState(undefined);
    const [sharedBoards, setSharedBoards] = useState([]);
    const [boards, setBoards] = useState([]);
    const [idShort, setIdShort] = useState("");
    const [hours, setHours] = useState(48);

    useEffect(() => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        ladeBoards();
        ladeSharedBoards();

    }, [eventOperatorId]);

    const ladeSharedBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/board/shared/load/"+ eventOperatorId,
            json => {
                setSharedBoards(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der geteilten Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der geteilten  Boards: " + error.message);
            }
        );
    }

    const ladeBoards = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/"+ eventOperatorId,
            dto => {
                setBoards(dto.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Boards: " + error.message);
            }
        );
    }

    const loeschen = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/board/share/delete/" + eventOperatorId + "/" + sharedBoardDeleteId,
            {},
            json => {
                setInfoMessage("Erfolgreich gelöscht.");
                ladeSharedBoards();
                ladeBoards();
                setSelectedBoardId(undefined);
                setSharedBoardDeleteId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }
    const save = () => {
        setErrorMessage(undefined);
        let data = {boardId: selectedBoardId, idShort: idShort, hours: hours};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/board/share/save/" + eventOperatorId,
            data,
            json => {
                setInfoMessage("Speichern erfolgreich");
                ladeSharedBoards();
                ladeBoards();
                setSelectedBoardId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getSharedRows = () => {
        let rows = []
        for (const board of sharedBoards) {
            rows.push(<div key={"key_shared_row_" + board.id}>
                <Button icon={PrimeIcons.TRASH} onClick={() => setSharedBoardDeleteId(board.id)} style={{marginRight:5}}/><br/>
                {board.boardBezeichnung}<br/>
                {board.eventOperatorNameShared}<br/>
                Id: {board.idShort}<br/>
                Zeitpunkt geteilt: {DateUtil.localDateTimeToDateTimeString(board.timestampShared)}<br/>
                Zeitpunkt automatisches Löschen: {DateUtil.localDateTimeToDateTimeString(board.timestampDelete)}<br/>
                <hr style={{borderTop: "1px dotted #000"}}/>
            </div>)
        }
        return rows;
    }

    const canSave = () => {
        let tmp = selectedBoardId !== undefined && idShort.length > 0 &&
            (hours !== undefined && hours !== null && hours > 0);
        return tmp;
    }

    const getBoardComponent = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const b of boards) {
            if( b.eventOperatorIdShared )  {
                tmp.push({name: <div>
                        <div>{b.bezeichnung}</div>
                        <div style={{fontSize:12}}>
                            {b.beschreibungShared}
                        </div>
                    </div>, code: b.id});
            } else {
                tmp.push({name: b.bezeichnung, code: b.id});
            }
        }
        return <div key={"key_dh_component"}>
            <DHSelectionComponent title="Auswahl" disabled={false} onChange={(id) => setSelectedBoardId(id)} value={selectedBoardId} values={tmp}/>
        </div>
    }

    return <div style={{padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div>
            <div style={{marginBottom: 5}}>
                <span style={{fontWeight: "bold"}}>Hintergrund:</span><br/>
                Es gibt Ligaspieltage, da spielen verschiedene Vereine an einem Veranstaltungsort.
                Damit nun alle Vereine auf der gleichen Anlage spielen können, muss der Verein dem die Anlage gehört sein Board mit den
                anderen Vereinen teilen. Der Verein hat dann das Board zur Auswahl und kann seine Spiele auf diesem Board aufrufen.<br/>
                Eventuell möchte auch ein Verband oder anderer Verein auf deiner Anlage in seinem Namen bei dir am Spielort ein Turnier abhalten,
                auch dafür kannst du ihm hier deine Boards zur Verfügung stellen.
            </div>
            <div style={{marginBottom: 5}}>
                <span style={{fontWeight: "bold"}}>Hinweis:</span><br/>
                Hier kannst du ein Board mit einem anderen Veranstalter teilen.<br/>
                Du verlierst in diesem Fall die Kontrolle über das Board und kannst dieses in deinem Veranstalterbereich nicht mehr verwalten.<br/>
                Der Veranstalter mit dem du das Board teilst, sieht das Board wie sein eigenes.<br/>
                Nachdem Darthelfer das Teilen automatisch beendet oder du die Teilung hier löscht, wird dir das Board wieder in vollem Umfang zur Verfügung gestellt.<br/>
                Jedes Board kann nur mit einem Veranstalter geteilt werden. Handelt es sich um einen Doppelligaspieltag, musst du das Board nach Beendigung des ersten Spieltages hier Löschen und mit
                dem neuen Veranstalter teilen.
            </div>
            <div style={{marginBottom: 20}}>
                <span style={{fontWeight: "bold"}}>Achtung:</span><br/>
                Solltes du das Board bereits einem Event zugewiesen haben, wird diese Zuordnung beim Teilen öder Löschen der Teilung automatisch gelöscht.
            </div>
        </div>

        <div>
            Boardauswahl:<br/>
            {getBoardComponent()}
        </div>
        <div style={{marginTop: 10}}>
            Id des Vereins / des Veranstalters welcher das Board nutzen darf:<br/>
            <InputText value={idShort}
                       onChange={(e) => setIdShort(e.target.value)} style={{width: 100}}/>
        </div>
        <div style={{marginTop: 10}}>
            Stunden nachdem die Teilung des Boards automatisch gelöscht wird:<br/>
            (ca. Std. wo der Veranstalter das Board nicht mehr benötigt)<br/>
            <InputNumber inputStyle={{width: 100}} minFractionDigits={0} min={1} style={{width: 50}}
                         value={hours}
                         onChange={(e) => setHours(e.value)}/>
        </div>
        <div style={{marginTop: 10}}>
            <Button icon={PrimeIcons.SAVE} label={"Speichern"} onClick={() => save()} disabled={!canSave()}/>
        </div>
        <hr/>
        {getSharedRows()}
        {sharedBoardDeleteId && <ConfirmDialog header={"Teilen löschen..."} visible={true}
                                                textUnten={"Möchtest du wirklich löschen?"}
                                               callBackOnJa={() => loeschen()}
                                               callBackOnNein={() => setSharedBoardDeleteId(undefined)}/> }
    </div>
}
