import React from 'react'
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import ReactStars from "react-rating-stars-component";
import {OverlayPanel} from "primereact/overlaypanel";

class DeineDatenAendern extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...props.profil};

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({...this.props.profil});
        }
    }


    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    save() {
        this.messages.clear();
        if( this.state.password !== this.state.password2) {
            this.showError("Die beiden neuen Passwörter stimmen nicht überein.");
            return;
        }
        let data = {...this.state};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/changeStammdaten",
            data,
            json => {
                this.showInfo("Speichern der Daten war erfolgreich!");
                this.props.callbackRefresh();

            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        return <div className="ligashortoverview"
                    style={{height: "100%", borderLeft: "10px solid #00CC00", textAlign: "left"}}>
            <h3>Deine Daten</h3>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <OverlayPanel ref={(el) => this.opIdShort = el} showCloseIcon>
                <div style={{marginBottom: 5}}>
                    SpielerID kannst du für Offlineturniere, z.B. in der Kneipe oder ähnliches, für die schnelle Anmeldung nutzen.
                </div>
            </OverlayPanel>
            <div style={{width: "100%", marginBottom: 20}}>
                    <span style={{width: 200, marginRight: 10}}>
                        SpielerID <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}} onClick={(e) => this.opIdShort.show(e)}/> :
                    </span>
                <span>{this.props.profil.userIdShort}</span>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>Name:</div>
                <div className="col">
                    <InputText value={this.state.name} style={{width: "100%"}}
                               name="name"
                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>Vorname:</div>
                <div className="col">
                    <InputText value={this.state.vorname} style={{width: "100%"}}
                               name="vorname"
                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>Email:</div>
                <div className="col">
                    <InputText value={this.state.email} style={{width: "100%"}}
                               name="email"
                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>Mobil:</div>
                <div className="col">
                    <InputText value={this.state.mobil} style={{width: "100%"}}
                               name="mobil"
                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col" style={{textAlign: "left", marginLeft: "5px", fontSize: 12}}>
                    Wir benötigen deine Strasse, PLZ u. deinen Wohnort ausschließlich, sobald du über
                    darthelfer.de einen Pro-Account abschließen willst.<br/>
                    Du kannst Pro-User nur werden, wenn du einen deutschen Wohnsitz besitzt bzw. hier eine deutsche Adresse einträgst.
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>Strasse:</div>
                <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="strasse"
                                                                                               style={{width: "250px"}}
                                                                                               value={this.state.strasse}
                                                                                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: 80, textAlign: "right"}}>PLZ/Ort:</div>
                <div className="col" style={{textAlign: "left", marginLeft: "5px"}}>
                    <InputText name="plz"
                               style={{width: "60px"}}
                               value={this.state.plz}
                               onChange={(e) => this.handleChange(e)}/>
                    <InputText name="ort"
                               style={{width: "190px"}}
                               value={this.state.ort}
                               onChange={(e) => this.handleChange(e)}/>
                </div>
            </div>

            <div className="grid">
                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                <div className="col"><Button label="Speichern" onClick={() => this.save()}/></div>
            </div>
            <hr/>
            <span style={{fontSize: 12}}>Es werden die letzten 20 Bewertungen deiner Onlinespiele berücksichtigt.</span>
            <div style={{width: "100%", marginTop: 20}}>
                <span>Bewertung deiner Bildqualität</span>
                <br/>
                <span style={{fontSize: 12}}>Gesamtanzahl deiner erhaltenen Bildbewertungen:{this.props.profil.bewertung.anzahlRatingsBildqualitaet}</span>
                <ReactStars
                    value={this.props.profil.bewertung.bildqualitaet}
                    count={5}
                    size={25}
                    isHalf={true}
                    edit={false}
                    activeColor="#ffd700"/>
            </div>
            <div style={{width: "100%", marginTop: 20}}>
                <span>Bewertung deiner Tonqualität</span>
                <br/>
                <span style={{fontSize: 12}}>Gesamtanzahl deiner erhaltenen Tonbewertungen: {this.props.profil.bewertung.anzahlRatingTonqualitaet}</span>
                <ReactStars
                    value={this.props.profil.bewertung.tonqualitaet}
                    count={5}
                    size={25}
                    isHalf={true}
                    edit={false}
                    activeColor="#ffd700"/>
            </div>
        </div>
    }
}

DeineDatenAendern.propTypes = {
    profil: PropTypes.object.isRequired,
    callbackRefresh: PropTypes.func.isRequired,
};


export default DeineDatenAendern;
