import {connect} from 'react-redux'
import withRouter from "../../../../components/RouterComponentWrapper";
import PublicLigaspielttagQRCodeCheck from "../../../../components/views/public/ligaspieltag/PublicLigaspielttagQRCodeCheck";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const tournamentid = urlParams.get('tournamentid');
    const qrcode = urlParams.get('qrcode');
    return {tournamentid: tournamentid, qrcode: qrcode};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicLigaspielttagQRCodeCheck))
