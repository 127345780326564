import {connect} from 'react-redux'
import Statistics from "../../../components/views/statistics/Statistics";

const mapStateToProps = (state, ownProps) => ({
    userId: state.user.id,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Statistics)