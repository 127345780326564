import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";

export default function RefreshCounterComp({text, onRefresh}) {

    const INITAL_COUNTER = 120;

    let [refreshCounter, setRefreshcounter] = useState(0);
    let [autorefresh, setAutorefresh] = useState(false);
    let [counter, setCounter] = useState(INITAL_COUNTER);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if( refreshCounter > 60) {
                // 120 mal wird automatisch aktualisert, dann wird es abgeschalten
                setAutorefresh(false);
                setRefreshcounter(0);
                return;
            }
            if( counter === 0) {
                counter = INITAL_COUNTER;
                if( autorefresh ) {
                    setRefreshcounter(refreshCounter+1);
                    onRefresh();
                }
            }
            setCounter(counter-1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [counter]);

    return <>
        <Button icon="pi pi-refresh"
                className="p-button-primary"
                onClick={() => onRefresh()}
                style={{height: 40, marginTop:5}}/>
        <Button label={autorefresh ? text + " (" + counter + ")" : text}
                        icon="pi pi-refresh"
                        className={autorefresh ? "p-button-primary" : "p-button-secondary"}
                        onClick={() => setAutorefresh(!autorefresh)}
                        style={{height: 40, marginTop:5, marginRight: 5}}/>
        </>
}
