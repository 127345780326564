import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';

import {Button} from "primereact/button";
import SpieltagGameComponent from "./SpieltagGameComponent";
import SpieltagGameEditDialog from "./SpieltagGameEditDialog";
import GegenspielerVerwaltenDialog from "./GegenspielerVerwaltenDialog";
import PropTypes from "prop-types";

class SpieltagDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spieltag: undefined,
            showEditDialog: false,
            showGegenspielerDialog: false,
        };

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.setGameActive = this.setGameActive.bind(this);
        this.getAbgeschlossenGames = this.getAbgeschlossenGames.bind(this);
        this.setGameNichtAbgeschlossen = this.setGameNichtAbgeschlossen.bind(this);
        this.existierenInaktiveSpiele = this.existierenInaktiveSpiele.bind(this);
        this.existierenAbgeschlosseneSpiele = this.existierenAbgeschlosseneSpiele.bind(this);
    }

    componentDidMount() {
        this.ladeDaten(false, false);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'warn',
            sticky: 'true',
            summary: 'Achtung!!!',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten(nurAktive, keineAbgeschlossenen) {
        let id = this.props.spieltagid;
        if (id !== undefined && id !== null) {
            let urlSpieltag = ConfigUtil.getConfig().resourceUrls.gameday + "/" + id;
            FetchUtil.fetchGet(urlSpieltag,
                json => {
                    this.setState({spieltag:json})
                },
                responseNotOk => {
                    this.showError("Fehler beim Laden des Spieltages: " + responseNotOk.message);
                },
                error => {
                    this.showError("Fehler beim Laden des Spieltages: " + error.message);
            });

            let urlGames = ConfigUtil.getConfig().resourceUrls.gameday + "/" + id + "/games/" + nurAktive + "/" +keineAbgeschlossenen;
            FetchUtil.fetchGet(urlGames,
                json => {
                    this.setState({games:json})
                },
                responseNotOk => {
                    this.showError("Fehler beim Laden der Spiele: " + responseNotOk.message);
                },
                error => {
                    this.showError("Fehler beim Laden der Spiele: " + error.message);
                });
        }
    }

    setGameNichtAbgeschlossen(gameid) {
        this.messages.clear();
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game/" +  gameid+ "/nichtabgeschlossen",
            {},
            () => {
                this.ladeDaten(false, false);
            },
            responseNotOk => {
                this.showError("Fehler beim Nichtabschliessen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Nichtabschliessen: " + error.message);
            }
        );
    }

    setGameActive(gameid) {
        this.messages.clear();
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game/" +  gameid+ "/active",
            {},
            () => {
                this.showInfo("Spiel aktiv geschaltet.");
                this.ladeDaten(false, false);
            },
            responseNotOk => {
                this.showError("Fehler beim Aktiv schalten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Aktiv schalten: " + error.message);
            }
        );
    }

    render() {
        if( this.state.spieltag === undefined) {
            return <div>Laden...</div>
        }

        let activeGames = this.getAktiveGames();
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding:0, margin:0, backgroundColor:"#ecb3ff", marginBottom:20}}>
                <div className="col" style={{marginTop:10, padding:0}}>
                    Detail zum Spieltag
                </div>
            </div>
            <div style={{textAlign:"left", marginLeft:20, fontSize:24}}>Spielstand {this.state.spieltag.gewonnenSpieleHeim} : {this.state.spieltag.gewonnenSpieleGegner} - Punkte {this.state.spieltag.punkteHeim} : {this.state.spieltag.punkteGegner}</div>
            <div className="grid align-center vertical-container"
                 style={{margin: 0, marginBottom:10, textAlign: "left", verticalAlign: "middle"}}>
                {!this.state.spieltag.abgeschlossen &&
                    <div className="col-fixed" style={{width: 200, marginRight: 30}}>
                        <Button label="Spiel anlegen" onClick={() => this.setState({showEditDialog: true})}
                                style={{width: 200,height: 60}}/>
                    </div>
                }
                {!this.state.spieltag.abgeschlossen &&
                    <div className="col-fixed" style={{width: 200, marginRight: 30}}>
                        <Button label="Gegenspieler verwalten" onClick={() => this.setState({showGegenspielerDialog: true})}
                                style={{width: 200,height: 60}}/>
                    </div>
                }
                {this.existierenInaktiveSpiele() &&
                        <div className="col-fixed" style={{width: 120, fontWeight: "bold"}}>Inaktive Spiele:</div>
                }
                {this.existierenInaktiveSpiele() &&
                        <div className="col">{this.getInAktiveGames()}</div>
                }
            </div>
            {activeGames}
            <hr/>
            {this.existierenAbgeschlosseneSpiele() &&
                <div className="grid align-center vertical-container"
                     style={{margin: 0, marginBottom: 10, textAlign: "left", verticalAlign: "middle"}}>
                    <div className="col-fixed" style={{width: 200, marginRight: 20}}>Abgeschlossene Spiele:</div>
                    <div className="col">{this.getAbgeschlossenGames()}</div>
                </div>
            }
            {this.state.showEditDialog &&
                <SpieltagGameEditDialog visible={true}
                                    spieltagid={this.props.spieltagid}
                                    gegenspieler={this.state.spieltag.gegenspieler}
                                    callBackRefresh={() => this.ladeDaten(false, false)}
                                    callBackEditDialogClose={() =>this.setState({showEditDialog: false})}/>
            }
            {this.state.showGegenspielerDialog &&
                <GegenspielerVerwaltenDialog visible={this.state.showGegenspielerDialog}
                                             spieltagid={this.props.spieltagid}
                                             gegenspieler={this.state.spieltag.gegenspieler}
                                             callBackRefresh={() => this.ladeDaten(false, false)}
                                             callBackEditDialogClose={() => this.setState({showGegenspielerDialog: false})}/>
            }
        </div>
    }

    getInAktiveGames() {
        let rows = [];
        if( this.state.games === undefined || this.state.games === null) {
            return rows;
        }
        const games = this.state.games;
        for (const game of games) {
            const spieler2Name = game.spieler2Name !== undefined && game.spieler2Name !== null && game.spieler2Name !== "" ? game.spieler2Name : "'gegner'";
            if( !game.active) {
                rows.push(<Button key={game.id} label={game.spieler1Name + " : " + spieler2Name}
                                  onClick={(e) => this.setGameActive(game.id)}
                                  style={{height: 60, marginRight: 10, marginBottom:5}}/>)
            }
        }
        return rows;
    }

    getAktiveGames() {
        let rows = [];
        if( this.state.games === undefined || this.state.games === null) {
            return rows;
        }
        const games = this.state.games;
        for (const game of games) {
            if( game.active && !game.abgeschlossen) {
                rows.push(<SpieltagGameComponent key={game.id} readonly={this.state.spieltag.abgeschlossen} callBackRefresh={() => this.ladeDaten(false,false)} game={game} spieltagid={this.props.spieltagid} />)
            }
        }
        return rows;
    }

    getAbgeschlossenGames() {
        let rows = [];
        if( this.state.games === undefined || this.state.games === null) {
            return rows;
        }
        const games = this.state.games;
        for (const game of games) {
            const spieler2Name = game.spieler2Name !== undefined && game.spieler2Name !== null && game.spieler2Name !== "" ? game.spieler2Name : "'gegner'";
            if( game.abgeschlossen) {
                const label = <div style={{marginLeft:5, marginRight:5, marginBottom:5}}>{game.spieler1Name + " : " + spieler2Name}<br/>{game.anzahlSpieler1 + " : " + game.anzahlSpieler2}</div>
                rows.push(<Button key={game.id} label=" "
                                  onClick={(e) => this.setGameNichtAbgeschlossen(game.id)}
                                  style={{ marginRight: 10, marginBottom:5}}>{label}</Button>)
            }
        }
        return rows;
    }

    existierenInaktiveSpiele() {
        if( this.state.games === undefined ) {
            return false;
        }
        const games = this.state.games;
        for (const game of games) {
            if( !game.active) {
                return true;
            }
        }
        return false;
    }

    existierenAbgeschlosseneSpiele() {
        if( this.state.games === undefined ) {
            return false;
        }
        const games = this.state.games;
        for (const game of games) {
            if( game.abgeschlossen) {
                return true;
            }
        }
        return false;
    }

}

SpieltagDetail.propTypes = {
    spieltagid: PropTypes.string,
};

export default SpieltagDetail