import QRGenerator from "../pdf/qrcode/QRGenerator";
import React from "react";
import {Button} from "primereact/button";
import DHDefaultDrawer from "./DHDefaultDrawer";


export default function QRCodeSidebar({beschreibung, kritisch, link, onClose}) {

    return <DHDefaultDrawer onClose={onClose} show={true} title={"QR-Code"} children={<div style={{padding:20}}>
        <div style={{fontSize: 18, backgroundColor: kritisch ? "red" : "", color: kritisch ? "white" : ""}}>{beschreibung}</div>
        <QRGenerator data={{id: "1", value: link}}/>
        </div>}
    />
}
