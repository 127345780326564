import React from "react";
import {Document, Image, Page, StyleSheet, View, Text} from "@react-pdf/renderer";
import logo from "../darthelfer_quer.png";

const styles = StyleSheet.create({
    page: {
        display: "block"
    },
    view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%"
    },
    logo: {
        padding: "30px",
        width: "100%",
    },
    bezeichnung: {
        padding: "30px",
    },
    beschreibung: {
        fontWeight: "bold"
    },
    qrImage: {
        width: "200px",
        height: "200px",
        marginBottom:"30px",
    }
});

function QRCodePage({ data }) {
    const getDataURL = (id) => {
        return document.getElementById(id).toDataURL();
    }
    return (
        <Page key={`page_1`} size="A4" style={styles.page}>
            <View style={styles.view}>
                <Image src={logo} style={styles.logo}/>
            </View>
            {data.qrcodes.map(qrcode => {
                return <div key={"qrarea_" + qrcode.id}>
                    <View style={styles.view}>
                        <Text style={styles.beschreibung}>{qrcode.beschreibung}</Text>
                    </View>
                    <View style={styles.view}>
                        <Text style={styles.bezeichnung}>{qrcode.bezeichnung}</Text>
                    </View>
                    <View key={"viewimage_" + qrcode.id} style={styles.view}>
                        <Image allowDangerousPaths src={getDataURL(qrcode.id)} style={styles.qrImage} />
                    </View>
                </div>
            })}
        </Page>
    );
}



function QRCodeDocument({ data }) {
    return (
        <Document>
            <QRCodePage data={data} />
        </Document>
    );
}

export default QRCodeDocument;
