import {connect} from 'react-redux'
import TournamentOverview from "../../../components/views/tournament/TournamentOverview";

const mapStateToProps = (state, ownProps) => ({
    mandantType: state.user.mandantType,
    userRoles: state.user.userRoles
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TournamentOverview)