

export const NEWS_ARRAY = [
    // {id: 1, visible:false, datum:"28.03.2024",
    //     title: "Bleib immer auf dem neuen Stand rund um Darthelfer!!!!",
    //     text:
    //         <div>
    //             Folge unserer Facebookseite<br/>
    //             <a href="https://www.facebook.com/darthelfer" target="_blank" style={{textDecoration: "none", color:"black"}}>https://www.facebook.com/darthelfer</a><br/><br/>
    //             Fragen und Details werden in unserer Facebookgruppe behandelt<br/>
    //             <a href="https://www.facebook.com/groups/darthelfer" target="_blank" style={{textDecoration: "none", color:"black"}}>https://www.facebook.com/groups/darthelfer</a>
    //         </div>
    // },
    // {id: 2, visible:false, datum:"27.03.2024",
    //     title: "Kennst du schon unseren Veranstalterbereich?",
    //     text:
    //         <div>
    //             Für Turnierveranstalter, Vereine, Kneipen oder deinem Hobbyteam!!!<br/><br/>
    //             Du kannst deine Turniere/Ligen kostenlos über Darthelfer verwalten.<br/>
    //             In der kostenlosen Version musst du deine Ergebnisse von Hand eintragen. Deine Mitspieler können aber jederzeit auf den aktuellen Spielstand schauen.<br/>
    //             <br/>
    //             Sofern du den Scorer unter
    //             <a href="https://scorer.darthelfer.de" target="_blank" style={{textDecoration: "none", color: "black"}}> https://scorer.darthelfer.de </a>
    //             einsetzt, kannst du die Ergebnisse inkl. Statistiken automatisch nach Darthelfer übertragen und hast somit die perfekte Eventübersicht inkl. Statistiken usw.<br/>
    //             Dieser Service ist kostenpflichtig und kostet aktuell 132,- Euro im Jahr. Keine weiteren Gebühren für Scorer und keine Beschränkung auf die Anzahl der Boards.<br/>
    //             <br/>
    //             Nachdem du in DH angemeldet bist, kannst du im oberen Bereich zwischen Onlinebereich und Veranstalterbereich wechseln.<br/>
    //             Im Veranstalterbereich kannst du kostenlos einen Veranstalter anlegen und testen ob Darthelfer dir dabei helfen kann dein Event zu veranstalten.<br/>
    //         </div>
    // },
];