export const NEW_GAMES_OVER_WEBSOCKET = 'NEW_GAMES_OVER_WEBSOCKERT';
export const NEW_HERAUSFORDERUNGEN_OVER_WEBSOCKET = 'NEW_HERFORDERUNGEN_OVER_WEBSOCKERT';

export const newGamesOverWebsocket = (games) => ({
    type: NEW_GAMES_OVER_WEBSOCKET,
    value: games,
});

export const newHerausforderungenOverWebsocket = (games) => ({
    type: NEW_HERAUSFORDERUNGEN_OVER_WEBSOCKET,
    value: games,
});

export function gamesOverWebsocket(games) {
    return function (dispatch) {
        dispatch(newGamesOverWebsocket(games));
    };
}

export function herausforderungenOverWebsocket(games) {
    return function (dispatch) {
        dispatch(newHerausforderungenOverWebsocket(games));
    };
}