import React from 'react'
import TournamentAnyGameTable from "./TournamentAnyGameTable";


export default function PublicAnyGamesTab({tournament, games, boardGames}) {

    const getGameArea = () => {
        if( !games || games.length === 0) {
            return <div style={{marginTop:30}}>Aktuell keine Spiele vorhanden...</div>
        }

        return <div className="grid" style={{padding:20, margin:0, color:"white"}}>
            <TournamentAnyGameTable tournamentId={tournament.id} eventOperatorId={tournament.eventOperatorId} games={games} players={tournament.tournamentPlayerEntities}
                                    boardGames={boardGames} isAdmin={false} onRefresh={undefined} onDeleteGame={undefined}/>
        </div>;
    }

    return <div style={{backgroundColor:"#022836"}}>
        {getGameArea()}
    </div>
}

