import {connect} from 'react-redux'
import {withCookies} from "react-cookie";
import withRouter from "../../../components/RouterComponentWrapper";
import ScoringView from "../../../components/views/scoring/ScoringView";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const gameId = urlParams.get('gameid');
    const playerId = urlParams.get('playerid');
    return {gameid: gameId, playerid: playerId, autodartsBoardId: state.user.autodartsBoardId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(ScoringView)))
