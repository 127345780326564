


export function isVerliererseiteByModel(model) {
    return model.feld.startsWith("-") || model.verliererseite;
}

export function isVerliererseiteByFeld(feld) {
    // startet mit - ist altes Model in v1
    if( feld.startsWith("-") ) {
        return true;
    }
    let indexOf = feld.indexOf("-");
    if( indexOf > 0) {
        // - wurde im feld gefunden, neues Model. Wenn Feld begin größer 100, dann v2
        let s = feld.substr(0, indexOf);
        let vSeite =  Number(s) >= 100;
        return vSeite;
    }
    return false;
}
