import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import GameDetail from "../../../components/views/liga/GameDetail";
import {withCookies} from "react-cookie";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const username = urlParams.get('username');
    const gegnerId = urlParams.get('gegnerId');
    return {userId: state.user.id, gegnerUsername: username, gegnerId: gegnerId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(GameDetail)))