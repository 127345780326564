import {connect} from 'react-redux'
import Games from "../../../components/views/liga/Games";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id, herausforderungen: state.games.herausforderungen};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Games)