import React, {useEffect, useRef, useState} from 'react'
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import DHDialog from "../../general/DHDialog";

function ConfirmAbfrageTeilnahmebedingungenDialog({tournamentId, visible, onClose, onConfirm}) {

    let [teilnahmebedingungen, setTeilnahmebedingungen] = useState([]);
    const messages = useRef(null);

    useEffect(() => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmebedingungen/" + tournamentId,
            {},
            json => {
                setTeilnahmebedingungen(json);
            },
            responseNotOk => {
                showError(responseNotOk.message);
            },
            error => {
                showError(error.message);
            })
    }, []);

    const showError = (message) => {
        messages.current.show({ severity: 'error', sticky: true, summary: 'Fehler...', detail: message });
    }

    const footer = (
        <div>
            <Button label="Bestätigen" icon="pi pi-check" onClick={() => onConfirm()} disabled={teilnahmebedingungen.length === 0} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    const rows = () => {
        let rowArray = [];
        for (const tmp of teilnahmebedingungen) {
            rowArray.push(<div key={"key_teilnahmebedingungen_" + tmp.id}  style={{marginBottom:20}}>
                <div>{tmp.beschreibung}</div>
                {tmp.link !== null && tmp.link !== undefined && tmp.link !== "" &&
                    <div><a href={tmp.link} target="_blank" rel="noopener noreferrer">Link zum Dokument...</a></div>
                }
            </div>)
        }
        return rowArray;
    }

    return <DHDialog onClose={() => onClose()} title="Teilnahmebedingungen" show={visible} buttons={footer}
                     comp={<>
        <Messages ref={messages}></Messages>
        {rows()}
        <div>
            Ich habe alle Bedingungen gelesen und erkläre mich damit einverstanden.
        </div>
    </>}/>
}

ConfirmAbfrageTeilnahmebedingungenDialog.propTypes = {
    tournamentId: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmAbfrageTeilnahmebedingungenDialog;