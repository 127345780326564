import React from 'react'
import PropTypes from "prop-types";
import LiveView from "../public/liveview/LiveView";

function VeranstalterViewView({currentEventOperator}) {

    if( currentEventOperator === undefined) {
        return <div>Kein Veranstalter ausgewählt...</div>
    }

    return <div>
        <div style={{padding: 10}}>
            <LiveView eventOperatorId={currentEventOperator.id} showBoardSelection={true} showEmbedded={true}/>
        </div>
    </div>
}

VeranstalterViewView.propTypes = {
    currentEventOperator: PropTypes.object
};

export default VeranstalterViewView
