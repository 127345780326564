import {NEW_CHAT_INFOS_OVER_WEBSOCKET} from "../actions/chat";

function chat(state = {
    headtoheadsUngelesen: false,
}, action) {
    switch (action.type) {
        case NEW_CHAT_INFOS_OVER_WEBSOCKET:
            return {...state, headtoheadsUngelesen: action.value};
        default:
            return state;
    }
}

export default chat;