import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import LigaDetail from "../../../components/views/liga/LigaDetail";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const ligaId = urlParams.get('ligaid');
    return {ligaid: ligaId, userId: state.user.id, leagueoperators: state.leagueoperator.leagueoperators};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LigaDetail))