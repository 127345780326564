import React, {useEffect, useState} from 'react'
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import ConfirmDialog from "../../../general/ConfirmDialog";
import {ProgressSpinner} from "primereact/progressspinner";
import NuPlayerAuswahlComponent from "../../../nu/NuPlayerAuswahlComponent";

export default function PublicLigaspielttagMannschaftTab({tournamentid, qrcode}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [players, setPlayers] = useState([]);
    const [heimVerwaltetGastdaten, setHeimVerwaltetGastdaten] = useState(undefined);
    const [externMeetingId, setExternMeetingId] = useState(undefined);
    const [deletePlayerId, setDeletePlayerId] = useState(undefined);
    const [playerId, setPlayerId] = useState(undefined);
    const [playerName, setPlayerName] = useState("");
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showBDVNuGuestPlayers, setShowBDVNuGuestPlayers] = useState(false);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/gastspieler/" + tournamentid + "/" + qrcode,
            (json) => {
                setPlayers(json.players);
                setHeimVerwaltetGastdaten(json.heimVerwaltetGastdaten);
                setExternMeetingId(json.externMeetingId);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            });
    }

    const savePlayer = () => {
        setErrorMessage(undefined);
        if( playerName.trim().length === 0) {
            setErrorMessage("Spielername muss gesetzt sein.");
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/saveplayer/" + tournamentid + "/" + qrcode;
        let data = {playerId: playerId, name: playerName.trim()};
        FetchUtil.fetchPost(url,
            data,
            (json) => {
                setPlayers(json.players);
                resetPlayer();

            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            });
    }

    const deletePlayer = () => {
        setErrorMessage(undefined);
        let data = {playerId: deletePlayerId};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/deleteplayer/" + tournamentid + "/" + qrcode,
            data,
            (json) => {
                setPlayers(json.players);
                setDeletePlayerId(undefined);
                setShowDeleteDialog(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setDeletePlayerId(undefined);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setDeletePlayerId(undefined);
                setShowDeleteDialog(false);
            });
    }

    const resetPlayer = () => {
        setPlayerName("");
        setPlayerId(undefined);
    }

    const changePlayer = (player) => {
        setPlayerName(player.name);
        setPlayerId(player.id);
    }

    const getRows = () => {
        if( players.length === 0) {
            return <div style={{padding:20}}>Keine Spielerdaten vorhanden</div>
        }
        let rows = [];
        for (const player of players) {
            if( player.idShortSinglePlayer1 !== null && player.idShortSinglePlayer1 !== undefined) {
                continue;
            }
            rows.push(<div key={"key_player_" + player.id} style={{fontSize:20, marginBottom:10}}>
                <Button icon={PrimeIcons.TRASH} onClick={() => {
                    setDeletePlayerId(player.id);
                    setShowDeleteDialog(true);
                }} style={{marginRight:5}}/>
                {!externMeetingId && <>
                    <Button icon={PrimeIcons.PENCIL} style={{marginRight:5}} onClick={() => changePlayer(player)}/>
                </>}

                {player.name}
            </div>);
        }
        return rows;
    }

    if( heimVerwaltetGastdaten === undefined) {
        return <div>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                                onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <ProgressSpinner style={{width: 100, height: 100}} />
        </div>
    }
    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        {externMeetingId && <>
            <div style={{marginBottom: 20}}>
                <Button label="Spieler auswählen" onClick={() => setShowBDVNuGuestPlayers(true)}/>
            </div>
        </>}

        {!externMeetingId && <>
            <div style={{marginBottom: 20}}>
                <Button label="Neuen Spieler anlegen" onClick={() => resetPlayer()}/>
            </div>
            <div style={{marginBottom: 20}}>
                <InputText style={{width: 350}} value={playerName} onChange={(e) => setPlayerName(e.target.value)}/>
            </div>
            <div style={{marginBottom: 20}}>
                <Button label="Speichern" disabled={playerName.trim().length === 0} onClick={() => savePlayer()}/>
            </div>
            <hr style={{color:"white", marginBottom:20}}/>
        </>}
        {getRows()}
        {showDeleteDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() => {
                               setShowDeleteDialog(false);
                               setDeletePlayerId(undefined);
                           }}
                           callBackOnJa={() => deletePlayer()}
                           header="Spieler löschen"
                           textOben="Sobald der Spieler noch keinem Spiel zugewiesen ist, kann dieser gelöscht werden."
                           textUnten="Möchtest du den Spieler wirklich löschen?"/>
        }
        {showBDVNuGuestPlayers && <NuPlayerAuswahlComponent externMeetingId={externMeetingId}
                                                            tournamentId={tournamentid}
                                                            qrCode={qrcode}
                                                            showGuestPlayers={true}
                                                            tournamentPlayers={players}
                                                            onClose={() => setShowBDVNuGuestPlayers(false)}
                                                            onRefresh={() => ladeDaten()} />}
    </div>;
}
