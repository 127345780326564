import React from 'react'

export function StartZertifikat() {

    return <div className="col-12 md:col-12 lg:col-3" style={{padding: 10}}>
        <div className="kachelStartseite" style={{height: "100%"}}>
            <img src="/images/bdv_tournament_software.png" style={{width:"100%"}} alt="Chrome"/>
        </div>
    </div>
}
