import React, {useEffect, useState} from "react";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PlayerUtil from "../../../util/PlayerUtil";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";


export default function LigaspieltagEdartDetailStatisikArea({tournamentId, games, players, publicView}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [statistiks, setStatisiks] = useState([]);

    useEffect(() => {
        if( !tournamentId) {
            return;
        }
        ladeDaten();

    }, [tournamentId]);

    const ladeDaten = () => {
        let url;
        if( publicView ) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/edart/statistik/tournament/"+ tournamentId;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/statistik/tournament/"+ tournamentId;
        }
        FetchUtil.fetchGet(url,
            json => {
                setStatisiks(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    const getHighlightString = (values) => {
        if( values.length === 0 ) {
            return "-";
        }
        let sortedValues = values.sort();
        let returnString = "";
        for (const value of sortedValues) {
            returnString += value + ", ";
        }
        return returnString;
    }

    const getRows = () => {
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 14, padding:5};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 14, padding:5};

        let background = false;
        let rows = [];
        for (const game of games) {
            let ergebnis = game.anzahlPlayer1 !== undefined && game.anzahlPlayer1 !== null ? game.anzahlPlayer1 + ":" + game.anzahlPlayer2 : "-";
            let statistik = statistiks.filter(t => t.gameId === game.id)[0];
            let style = background ? styleWithBackground : styleWithoutBackground;
            let spiel = (game.player1Id ? PlayerUtil.getSpielerName(players, game.player1Id) : "-") + " vs. "  + (game.player2Id ? PlayerUtil.getSpielerName(players, game.player2Id) : "-");
            rows.push(<TableRow key={"key_row_" + game.id}>
                    <TableCell style={style} >{game.counter}</TableCell>
                    <TableCell style={style} >{spiel}</TableCell>
                    <TableCell style={style} >{ergebnis}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple20 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple19 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple18 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple17 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple16 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.bull : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.schwarzer : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik._171er : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik._180er : 0}</TableCell>
                    <TableCell style={style} >{statistik ? getHighlightString(statistik.highscores) : "-"}</TableCell>
                    <TableCell style={style} >{statistik ? getHighlightString(statistik.highfinishes) : "-"}</TableCell>
                </TableRow>);
            background = !background;
        }
        return rows;
    }

    const getTable = () => {
        return <TableContainer style={{ maxHeight: "100%", padding:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:14}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:5, width:20}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:5}}>Spiel</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Erg.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T20</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T19</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T18</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T17</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T16</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Bull</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Schw.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>171</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>180</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highscores</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highfinishes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows()}
                </TableBody>
            </Table>
        </TableContainer>
    }
    return <div style={{backgroundColor:"#022836", padding:10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {getTable()}
    </div>
}