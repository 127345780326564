import React, {useState} from "react";
import {Button} from "primereact/button";
import DHDialog from "./DHDialog";

export default function ScoreWertComponent({beschreibung, values, readonly, onRemove, onOk}) {

    const buttonStyle = {width: "80px", height: "80px"};

    const [currentValue, setCurrentValue] = useState("");

    const [show, setShow] = useState(false);

    const addValue = (value) => {
        setCurrentValue(currentValue + value);
    }

    const removeLastValue = () => {
        let tmpCurrentValue = currentValue;
        tmpCurrentValue = tmpCurrentValue.substring(0, tmpCurrentValue.length - 1);
        setCurrentValue(tmpCurrentValue);
    }

    const uebernehmeValue = () => {
        onOk(currentValue);
        setCurrentValue("");
        setShow(false);
    }

    const abbrechen = () => {
        setCurrentValue("");
        setShow(false);
    }

    const isDisableButtons = () => {
        return currentValue.length > 2;
    }

    const isDisableBackButton = () => {
        return currentValue.length === 0;
    }

    const isDisableUebernehmenButton = () => {
        let currentIntValue = parseInt(currentValue, 10);
        return currentValue === "" || currentIntValue < 100 || currentIntValue > 180;
        ;
    }

    const getButtons = () => {
        let buttons = [];
        for (const value of values) {
            buttons.push(<Button label={value} disabled={readonly} icon="pi pi-trash" iconPos="right" style={{marginRight: 1, marginTop: 5, height: 40}}
                                 onClick={() => onRemove(value)}/>)
        }
        return buttons;

    }

    return <div style={{float: "left", width: "100%", marginBottom: 10}}>
        {beschreibung &&
            <div style={{marginRight: 10, width: 300}}>{beschreibung}</div>
        }

        <Button icon="pi pi-plus" disabled={readonly} style={{marginRight: 10, marginTop: 5, width: 40, height: 40}} onClick={(e) => setShow(true)}/>
        {getButtons()}
        {show &&
            <DHDialog onClose={() => setShow(false)} buttons={[]} title={"Auswahl"} comp={<>
                <div className="grid" style={{
                    textAlign: "center",
                    align: "center",
                    margin: "20px",
                    marginLeft: "70px",
                    fontWeight: "bold",
                    fontSize: "30px",
                    height: "50px"
                }}>
                    {currentValue}
                </div>
                <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                    <Button label="1" style={buttonStyle} onClick={(e) => addValue(1)}
                            disabled={isDisableButtons()}/>
                    <Button label="2" style={buttonStyle} onClick={(e) => addValue(2)}
                            disabled={isDisableButtons()}/>
                    <Button label="3" style={buttonStyle} onClick={(e) => addValue(3)}
                            disabled={isDisableButtons()}/>
                </div>
                <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                    <Button label="4" style={buttonStyle} onClick={(e) => addValue(4)}
                            disabled={isDisableButtons()}/>
                    <Button label="5" style={buttonStyle} onClick={(e) => addValue(5)}
                            disabled={isDisableButtons()}/>
                    <Button label="6" style={buttonStyle} onClick={(e) => addValue(6)}
                            disabled={isDisableButtons()}/>
                </div>
                <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                    <Button label="7" style={buttonStyle} onClick={(e) => addValue(7)}
                            disabled={isDisableButtons()}/>
                    <Button label="8" style={buttonStyle} onClick={(e) => addValue(8)}
                            disabled={isDisableButtons()}/>
                    <Button label="9" style={buttonStyle} onClick={(e) => addValue(9)}
                            disabled={isDisableButtons()}/>
                </div>
                <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                    <Button icon="pi pi-times" style={buttonStyle}
                            onClick={(e) => setCurrentValue("")}/>
                    <Button label="0" style={buttonStyle} onClick={(e) => addValue(0)}
                            disabled={isDisableButtons()}/>
                    <Button icon="pi pi-angle-left" style={buttonStyle} onClick={(e) => removeLastValue()}
                            disabled={isDisableBackButton()}/>
                </div>
                <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                    <Button label="Übernehmen" className="p-button-success"
                            style={{marginLeft: "5px", height: "50px", width: "240px"}}
                            onClick={() => uebernehmeValue()} disabled={isDisableUebernehmenButton()}/>
                </div>
                <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                    <Button label="Abbrechen / Schliessen" className="p-button-danger"
                            style={{marginLeft: "5px", height: "50px", width: "240px"}}
                            onClick={() => abbrechen()}/>
                </div>
            </>}/>
        }
    </div>

}
