import {connect} from 'react-redux'
import {withCookies} from "react-cookie";
import VeranstalterLiveView from "../../../components/views/veranstalter/VeranstalterLiveView";

const mapStateToProps = (state, ownProps) => ({
    currentEventOperator: state.eventoperator.currentEventOperator,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(VeranstalterLiveView))
