import React, {useEffect, useRef, useState} from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import {Button} from "primereact/button";
import AutodartsManuelScoreDialog from "./AutodartsManuelScoreDialog";
import BoardComponent from "../board/BoardComponent";

export default function AutodartsConnection({gameId, playerId, boardId, stompClient, toGo, modusId, active, onError, onErrorClear, onValueChange}) {

    const ref = useRef();
    ref.current = {active: active, toGo: toGo, modusId: modusId};

    const [editScore, setEditScore] = useState(undefined);

    const [message, setMessage] = useState(undefined);
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [valueGesamt, setValueGesamt] = useState(undefined);
    const [wuerfe, setWuerfe] = useState([]);
    const [wuerfeGegner, setWuerfeGegner] = useState([]);
    const [dto, setDto] = useState(undefined);

    const [serverReady, setServerReady] = useState(false);

    const [connected, setConnected] = useState(false);

    const [showBoard, setShowBoard] = useState(false);

    useEffect(() => {
        if( valueGesamt === null || valueGesamt === undefined) {
            onValueChange(0);
        } else {
            onValueChange(valueGesamt);
        }
    }, [valueGesamt]);

    useEffect(() => {
        let ref = undefined;
        if( connected ) {
            let destinationAutodarts = "/topic/autodarts/" + boardId;
            console.log("subscribe to " + destinationAutodarts);
            ref = stompClient.subscribe(destinationAutodarts, onMessageReceived);
            let destinationGegner = "/topic/autodarts/game/" + boardId + "/" + gameId + "/" + playerId;
            console.log("subscribe to " + destinationGegner);
            ref = stompClient.subscribe(destinationGegner, onMessageGegnerReceived);
        }


        return function cleanup() {
            if( ref !== undefined) {
                console.log("unsubscribe")
                ref.unsubscribe();
            }
        }
    }, [connected]);


    useEffect(() => {
        checkIfServerReady();
        return () => {
            if(connected) {
                unsubscribeFromAutodarts();
            }
        };
    }, []);

    const onMessageGegnerReceived = (payload) => {
        const json = JSON.parse(payload.body);
        if( json.action === "THROW_DETECTED") {
            setWuerfeGegner(json.dtos);
        } else if( json.action === "TAKOUT_FINISHED" || json.action === "MANUAL_RESET") {
            setWuerfeGegner([]);
        }
    }

    const onMessageReceived = (payload) => {
        console.log("onMessageReceived");
        setErrorMessage(undefined);
        setInfoMessage(undefined);
        setMessage(undefined);

        console.log("-----------------------");
        const json = JSON.parse(payload.body);
        setDto(json);
        if( json.action === "THROW_DETECTED") {
            setWuerfe(json.dtos);
            setValueGesamt(json.valueGesamt);
            checkModusAndValueGesamt(json.dtos, json.valueGesamt);
        } else if( json.action === "TAKOUT_FINISHED") {
            let validThrows = checkModusAndValueGesamt(json.dtos, json.valueGesamt);
            sendAufnahme(validThrows ? json.valueGesamt : 0, json.dtos);
            setWuerfe([]);
            setValueGesamt(undefined);
            setMessage(undefined);
        } else if( json.action === "MANUAL_RESET") {
            setWuerfe([]);
            setValueGesamt(undefined);
        }
        console.log("ENDE -----------------------");
    };

    const sendAufnahme = (value, tmpWuerfe) => {
        console.log("sendAufnahme", value);
        let anzWuerfe = 3;
        if ( value === ref.current.toGo) {
            anzWuerfe = tmpWuerfe.length;
        }
        let url = "/app/score/x01/" + gameId + "/" + playerId;
        let wurf1 = tmpWuerfe[0] !== undefined ? tmpWuerfe[0].name : undefined;
        let wurf2 = tmpWuerfe[1] !== undefined ? tmpWuerfe[1].name : undefined;
        let wurf3 = tmpWuerfe[2] !== undefined ? tmpWuerfe[2].name : undefined;
        let wurf1Bearbeitet = tmpWuerfe[0] !== undefined && tmpWuerfe[0].changed ? tmpWuerfe[0].changed : false;
        let wurf2Bearbeitet = tmpWuerfe[1] !== undefined && tmpWuerfe[1].changed ? tmpWuerfe[1].changed : false;
        let wurf3Bearbeitet = tmpWuerfe[2] !== undefined && tmpWuerfe[2].changed ? tmpWuerfe[2].changed : false;
        let json = {aufnahme: value, anzahlWuerfe: anzWuerfe, anzahlDoppelwuerfe: undefined,
            wurf1: wurf1, wurf2: wurf2, wurf3: wurf3,
            wurf1Bearbeitet: wurf1Bearbeitet, wurf2Bearbeitet: wurf2Bearbeitet, wurf3Bearbeitet: wurf3Bearbeitet};
        stompClient.send(url, {}, JSON.stringify(json));
    }

    const sendChange = (tmpValue, tmpWuerfe) => {
        let url = "/app/autodarts/change/score/" + boardId;
        let json = {valueGesamt: tmpValue, dtos: tmpWuerfe, changed: true};
        stompClient.send(url, {}, JSON.stringify(json));
    }

    const sendReset = () => {
        let url = "/app/autodarts/reset/" + boardId;
        let json = {};
        console.log("sendReset", json);
        stompClient.send(url, {}, JSON.stringify(json));
    }

    const checkIfServerReady = async () => {
        setErrorMessage(undefined);

        await FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.autodarts,
            json => {
                setServerReady(json.online);
                console.log("is server ready", json.online);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Ermitteln des Status:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Ermitteln des Status:" + error.message)
            })
    }

    const subscribeToAutodarts = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.autodarts + "/subscribe",
            {},
            json => {
                setInfoMessage("Verbindung erfolgreich hergestellt.")
                setConnected(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Verbinden mit Board:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Verbinden mit Board:" + error.message)
        });
    }

    const unsubscribeFromAutodarts = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.autodarts + "/unsubscribe",
            {},
            json => {
                setInfoMessage("Verbindung erfolgreich getrennt.")
                setConnected(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Lösen von Board:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Lösen von Board:" + error.message)
            })
    }

    const isGesamtValueBiggerThanToGo = () => {
        return valueGesamt > ref.current.toGo
    }

    const checkModusAndValueGesamt = (dtos, gesamt) => {
        if( dtos.length === 0) {
            return true;
        }
        if( gesamt === ref.current.toGo) {
            let letztesDto = dtos[dtos.length - 1];
            if( ref.current.modusId === "double_out" || ref.current.modusId === "double_in_double_out") {
                let letztesDto = dtos[dtos.length - 1];
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier !== 2) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "triple_out") {
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier !== 3) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "master_out") {
                if( letztesDto.multiplier === 1 && letztesDto.value === 50) {
                    return true;
                }
                if( letztesDto.multiplier === 1 && letztesDto.value !== 50) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
        } else {
            if( ref.current.modusId === "double_out" || ref.current.modusId === "double_in_double_out" || ref.current.modusId === "master_out") {
                if( ref.current.toGo - gesamt < 2) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
            else if( ref.current.modusId === "triple_out") {
                if( ref.current.toGo - gesamt < 3) {
                    setValueGesamt(0);
                    setMessage("Überworfen!");
                    return false;
                }
            }
        }
        return true;
    }

    const getThrowArea = () => {
        let cols = [];
        for(let i = 0 ; i < 3; i++) {
            let wuerfeElement = wuerfe[i];
            cols.push(<div key={"key_wurf" + i} style={{padding:10, width:"25%",cursor:"pointer"}} onClick={() => setEditScore(i)}>
                {wuerfeElement !== undefined && <>
                    {wuerfeElement.name}<br/>
                    <span style={{fontSize:30}}>{wuerfeElement.value}</span>
                </>
                }
                {wuerfeElement === undefined &&
                    <img src="/images/dart_single.png" style={{width:50}}/>
                }
            </div>);
        }
        cols.push(<div key={"key_wuerfe_gesamt"} style={{padding:10, width:"25%", color: "whitesmoke"}}>
            Gesamt<br/>
            {valueGesamt}
        </div>);
        return <div style={{display: "flex", color:"whitesmoke", textAlign:"center", fontSize:35}}>
            {cols}
        </div>
    }

    const getBoardStatusArea = () => {
        return <div style={{width: "100%", display: "flex", alignItems:"center", borderTop: "1px solid white", padding: 5, color: "white"}}>
            <div style={{width:400, textAlign:"center"}}>{dto !== undefined && dto.event !== undefined && dto.event}</div>
            <div style={{width:200}}>
                <Button label="Reset" onClick={() => sendReset()} style={{marginRight: 1}}/>
                <Button label="Send" onClick={() => {
                    sendAufnahme(valueGesamt, wuerfe);
                    setWuerfe([]);
                    setValueGesamt(undefined);
                    setMessage(undefined);
                    sendReset();
                }} style={{marginRight: 1}} disabled={!active}/>
            </div>
        </div>
    }
    const getBottomArea = () => {
        return <div style={{display: "flex", fontSize: 16, color: "whitesmoke", textAlign: "center", alignContent:"center"}}>
            <img src="/images/autodarts_powered_by.png" style={{height:50, marginLeft: 10, marginTop: 5, marginBottom: 5}}/>
            <img src="/images/darthelfer_quer_weiss.png" style={{height:50, marginLeft:10, marginRight:10, marginBottom: 5, marginTop: 5}}/>
        </div>
    }

    const changeScore = (changeDto) => {
        onErrorClear();
        setMessage(undefined);
        let newWuerfe = undefined;
        let wurf = wuerfe[editScore];
        if( wurf === undefined) {
            // wenn der selektierte score nicht 0 ist, muss der score davor vorhanden sein.
            if( editScore > 0 ) {
                if( wuerfe[editScore -1] === undefined) {
                    onError("Bitte Wurf davor zuerst bearbeiten...");
                    setEditScore(undefined);
                    return;
                }
            }
            newWuerfe = [...wuerfe];
            newWuerfe.push(changeDto);
        } else {
            newWuerfe = [];
            for(let i = 0; i < wuerfe.length; i++) {
                if( i !== editScore) {
                    newWuerfe.push(wuerfe[i]);
                } else {
                    newWuerfe.push(changeDto);
                }
            }
        }
        setWuerfe(newWuerfe)
        let tmp = 0;
        for (const wuerfeElement of newWuerfe) {
            tmp = tmp + wuerfeElement.value;
        }
        setValueGesamt(tmp);
        setEditScore(undefined);
        let isChangeValid = checkModusAndValueGesamt(newWuerfe, tmp);
        sendChange(isChangeValid ? tmp : 0, newWuerfe);
    }

    const getThrowData = (tmpWuerfe) => {
        let throwData = [];
        for (const wuerfeElement of tmpWuerfe) {
            throwData.push(wuerfeElement.dataNode);
        }
        return throwData;
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <img src={serverReady ? "/images/autodarts_green.png" : "/images/autodarts_red.png"}
             style={{position: "fixed", left: 640, bottom: 5, width: 40, height: 40, cursor: "pointer"}} onClick={() => checkIfServerReady()}/>
        {serverReady &&
            <img src={connected ? "/images/autodarts_connect.png" : "/images/autodarts_disconnect.png"}
                 style={{position: "fixed", left: 685, bottom: 5, widht: 40, height: 40, cursor: "pointer"}} onClick={() => {
                if (connected) {
                    unsubscribeFromAutodarts();
                } else {
                    subscribeToAutodarts();
                }
            }}/>
        }
        {connected && <img src="/images/dartboard.png"
                           style={{position: "fixed", left: 730, bottom: 5, width: 40, height: 40, cursor: "pointer"}}
                           onClick={() => setShowBoard(true)}/>
        }

        <div style={{position: "fixed", left: 40, bottom: 5, width: 600}}>
            {serverReady && connected && <>
                {!active && wuerfe.length > 0 &&
                    <div style={{height: 55, fontSize: 22, backgroundColor: "red", color: "white", textAlign: "center"}}>
                        Du bist nicht dran, bitte werfe erst wenn du dran bist.<br/>Du kannst deinen Wurf nicht absenden.
                    </div>
                }
                {(isGesamtValueBiggerThanToGo() || (message !== undefined && message !== "")) &&
                    <div style={{backgroundColor: "#94038c", color: "white", textAlign: "center", fontSize: 30, padding: 10}}>
                        {isGesamtValueBiggerThanToGo() ? "Überworfen" : message}
                    </div>
                }
                <div style={{backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", height: 100, borderTop: !active ? "10px solid red" : "none"}}>
                    {getThrowArea()}
                </div>
                <div style={{backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)"}}>
                    {getBoardStatusArea()}
                </div>
            </>
            }
            <div style={{height: 60, backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", borderTop: "1px solid white"}}>
                {getBottomArea()}
            </div>
        </div>

        {editScore !== undefined && <AutodartsManuelScoreDialog score={editScore} onClose={() => setEditScore(undefined)}
                                                                onScore={(changeDto) => changeScore(changeDto)}/>}
        {showBoard && <BoardComponent throwDataPlayer={getThrowData(wuerfe)}
                                      throwDataGegner={getThrowData(wuerfeGegner)}
                                      onClose={() => setShowBoard(false)}/>}
    </>
}
