import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ConfirmKennwortDialog from "../../../general/ConfirmKennwortDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";


export default function PublicTournamentRegistrationOptionAnonym({tournamentId, isParticipantsMailConfirmation, isDoppelturnier, isDYP, passwordRequired, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [playername, setPlayername] = useState("");
    const [name, setName] = useState("");
    const [vorname, setVorname] = useState("");
    const [strasse, setStrasse] = useState("");
    const [plz, setPLZ] = useState("");
    const [ort, setOrt] = useState("");
    const [email, setEmail] = useState("");
    const [mobil, setMobil] = useState("");

    const [message, setMessage] = useState("");
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [showTeilnehmenButton, setShowTeilnehmenButton] = useState(true);

    const [showKennwortDialog, setShowKennwortDialog] = useState(false);
    const [kennwort, setKennwort] = useState("");

    const teilnehmen = () => {
        setErrorMessage(undefined);
        setShowInfoMessage(false);
        let message = validationMessage();
        if (message !== "") {
            return;
        }
        if (passwordRequired && kennwort === "") {
            setShowKennwortDialog(true);
            return
        }

        let data = {
            playername: playername,
            name: name,
            vorname: vorname,
            strasse: strasse,
            plz: plz,
            ort: ort,
            email: email,
            mobil: mobil,
            kennwort: kennwort,
            tournamentId: tournamentId
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/tournamentteilnehmen", data, message => {
            if (message === "") {
                setInfoMessage("Teilnahme war erfolgreich!");
                setShowInfoMessage(true);
                setShowKennwortDialog(false);
                setKennwort("");
                setShowTeilnehmenButton(false);
                onRefresh();
            } else {
                setErrorMessage(message);
                setShowKennwortDialog(false);
                setShowInfoMessage("");
                setKennwort("");
            }
        }, responseNotOk => {
            setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            setShowKennwortDialog(false);
            setKennwort("");
        }, error => {
            setErrorMessage("Fehler beim Speichern: " + error.message);
            setShowKennwortDialog(false);
            setKennwort("");
        });
    }

    const validationMessage = () => {
        let message = "";
        if( isDoppelturnier && !isDYP && playername === "") {
            message = "Doppelname";
        }
        if (name === "") {
            message = message === "" ?"Name" : message + ", Name";
        }
        if (vorname === "") {
            message = message === "" ? "Vorname" : message + ", Vorname";
        }
        if (mobil === "") {
            message = message === "" ? "Telefon" : message + ", Telefon";
        }
        if (email === "") {
            message = message === "" ? "Email" : message + ", Email";
        }
        if (message !== "") {
            message = "Pflichtfelder füllen: " + message;
        }
        setMessage(message);
        return message;
    }

    return <div style={{marginBottom: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <h3>Daten eingeben und anmelden</h3>
        <div style={{marginTop:10}}>Pflichtfelder sind mit * gekennzeichnet.</div>
        <div className="grid" style={{textAlign: "center"}}>
            <div className="col-12 md:col-12 lg:col-12">
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="playernameText" value={playername}
                                   onChange={(e) => setPlayername(e.target.value)} style={{width:300}}/>
                        <label htmlFor="playernameText" style={{ fontWeight: "bold"}}>{isDoppelturnier && !isDYP ? "Doppelname / Spitzname *" : "Spielername / Spitzname"} </label>
                    </span>
                </div>
            </div>
        </div>
        <div className="grid" style={{textAlign: "center"}}>
            <div className="col-12 md:col-12 lg:col-4">
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="nameText" value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                        <label htmlFor="nameText" style={{ fontWeight: "bold"}}>Name *</label>
                    </span>
                </div>
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label">
                        <InputText id="vornameText" value={vorname}
                                   onChange={(e) => setVorname(e.target.value)}/>
                        <label htmlFor="vornameText" style={{ fontWeight: "bold"}}>Vorname *</label>
                    </span>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-4">
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="strasseText" value={strasse}
                                   onChange={(e) => setStrasse(e.target.value)}/>
                        <label htmlFor="strasseText" style={{ fontWeight: "bold"}}>Straße</label>
                    </span>
                </div>
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 5}}>
                        <InputText id="plzText" value={plz}
                                   onChange={(e) => setPLZ(e.target.value)} style={{width: 70}}/>
                        <label htmlFor="plzText" style={{ fontWeight: "bold"}}>PLZ</label>
                    </span>
                    <span className="p-float-label">
                        <InputText id="ortText" value={ort}
                                   onChange={(e) => setOrt(e.target.value)}/>
                        <label htmlFor="ortText" style={{ fontWeight: "bold"}}>Ort</label>
                    </span>
                </div>
            </div>
            <div className="col-12 md:col-12 lg:col-4">
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="telefonText" value={mobil}
                                   onChange={(e) => setMobil(e.target.value)}/>
                        <label htmlFor="telefonText" style={{ fontWeight: "bold"}}>Telefon/Mobil *</label>
                    </span>
                        </div>
                        <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="emailText" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        <label htmlFor="emailText" style={{ fontWeight: "bold"}}>Email *</label>
                    </span>
                </div>
            </div>
        </div>
        {message !== "" && <div style={{marginTop: 30, display: "flex", color: "red"}}>
            {message}
        </div>}
        {showInfoMessage && isParticipantsMailConfirmation !== null && isParticipantsMailConfirmation && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
            Du erhälst umgehend eine Email. Bitte bestätige den Bestätigungslink in der Email.
            <br/><br/>
            ACHTUNG! Prüfe bitte deinen SPAM-Ordner.
            <br/><br/>
            Die Mail ist 24 Stunden gültig. Sofern du die Teilnahme innerhalb dieser Zeit nicht bestätigst, wirst du
            vom System automatisch wieder abgemeldet.
        </div>}
        {showInfoMessage && (isParticipantsMailConfirmation === null || !isParticipantsMailConfirmation) && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
            Deine Anmeldung war erfolgreich.
        </div>}
        {showTeilnehmenButton && <div style={{marginTop: 30, display: "flex"}}>
            <Button label="Anmelden" icon="pi pi-plus" onClick={() => teilnehmen()}
                    style={{height: 40, width: 350}}/>
        </div>}
        {showKennwortDialog && <ConfirmKennwortDialog visible={true}
                                                      header="Turnierkennwort eingeben."
                                                      textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                                      changeKennwort={(kennwort) => setKennwort(kennwort)}
                                                      callBackOnAbbrechen={() => setShowKennwortDialog(false)}
                                                      callBackOnAbsenden={() => teilnehmen()}/>}

    </div>
}

