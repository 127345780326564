import React from 'react';
import DateUtil from "../../../util/DateUtil";
import {getTournamentsystem, TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {NavLink} from "react-router-dom";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

function getTournamentName(id) {
    return getTournamentsystem(id).text;
}

export default function TournamentTable({tournaments, showButtons, onTournamentCopy, onShowAktionen, onTournamentEdit}) {

    const getRows = () =>  {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 16, padding:5};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 16, padding:5};
        tournaments.forEach(tournament => {
            let isTurnierMitGruppenphase = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO ||
                getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO ||
                getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.ROUND_ROBIN;
            let isLiga = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.LIGA;
            let isExterneLiga = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.EXTERNALLEAUGUE;
            let isLigaspieltag = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.LIGASPIELTAG;
            let isWeekendLeague = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.WEEKENDLEAGUE;
            let isKratzer = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.KRATZER;
            let isSchweizerSystem = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM;
            let isAnyGames = getTournamentsystem(tournament.turniersystem) === TOURNAMENTSYSTEMS.ANY_GAMES;
            let link = "";
            if( isLiga) {
                link = NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + tournament.id;
            } else if( isTurnierMitGruppenphase || isSchweizerSystem) {
                link = NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route + "?turnierid=" + tournament.id;
            } else if( isWeekendLeague) {
                link = NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route + "?turnierid=" + tournament.id;
            } else if( isKratzer) {
                link = NAVIGATION_ITEM.KRATZERDETAIL.route + "?turnierid=" + tournament.id;
            } else if( isExterneLiga) {
                link = NAVIGATION_ITEM.EXTERNERLIGASPIELTAG.route + "?id=" + tournament.id;
            } else if( isLigaspieltag) {
                link = NAVIGATION_ITEM.LIGASPIELTAGDETAIL.route + "?turnierid=" + tournament.id;
            } else if( isAnyGames) {
                link = NAVIGATION_ITEM.ANYGAMESDETAIL.route + "?turnierid=" + tournament.id;
            } else {
                link = NAVIGATION_ITEM.TURNIERDETAIL.route + "?turnierid=" + tournament.id;
            }
            let formattedDateTime;
            if( tournament.beginn !== null && tournament.beginn !== undefined) {
                formattedDateTime = DateUtil.localDateTimeToString(tournament.beginn);
            } else {
                formattedDateTime = DateUtil.localDateTimeToString(tournament.timestampAsString);
            }
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={"key_tournament_table_" + tournament.id}>
                {showButtons &&
                    <TableCell style={style}>
                        <Button icon="pi pi-copy"
                                style={{marginRight: "5px", marginTop: "5px"}}
                                tooltip="Event kopieren"
                                onClick={() => onTournamentCopy(tournament)}/>
                        <Button icon={PrimeIcons.BOLT}
                                onClick={() => onShowAktionen(tournament)}
                                tooltip="Zeige Aktionen zum Event"
                                style={{marginRight: "5px", marginTop: "5px"}}/>
                        <Button icon="pi pi-pencil" tooltip="Event bearbeiten"
                                style={{marginRight: "20px", marginTop: "5px"}}
                                onClick={() => onTournamentEdit(tournament)}/>
                        <NavLink to={link} style={{textDecoration: "none"}}>
                            <Button icon="pi pi-table"
                                    style={{marginTop: "5px"}}
                                    tooltip="Zeige Details zum Event"/>
                        </NavLink>
                    </TableCell>
                }
                <TableCell style={style}>{formattedDateTime}</TableCell>
                <TableCell style={style}>{tournament.bezeichnung}
                    {tournament.bezeichnungShort &&
                        <><br/><span style={{fontSize:12}}>{tournament.bezeichnungShort}</span></>}
                </TableCell>
                <TableCell style={style}>{getTournamentName(tournament.turniersystem)}</TableCell>
                <TableCell style={style}>{tournament.gameType}</TableCell>
                <TableCell style={style}>{tournament.tournamentstatus}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    if( tournaments === undefined || tournaments.length === 0) {
        return <div style={{color:"white", fontSize:20, padding:20}}>Aktuell keine Turniere/Ligaspiele/Ligen vorhanden.</div>
    }

    return <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
        <Table style={{backgroundColor:"#022836", color:"white"}}>
            <TableHead>
                <TableRow>
                    {showButtons &&
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, width:180, padding:5}}>#</TableCell>
                    }
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, width:170, padding:5}}>Beginn</TableCell>
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, padding:5}}>Bezeichnung</TableCell>
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, padding:5}}>Turniersystem</TableCell>
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, padding:5}}>Type</TableCell>
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, width:80, padding:5}}>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {getRows()}
            </TableBody>
        </Table>
    </TableContainer>
}
