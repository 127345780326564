import {connect} from 'react-redux'
import KachelOverview from "../../../components/views/start/KachelOverview";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KachelOverview)