import React from 'react'


export default function SoundComponent({loop, file, onEnded}) {

    if( loop) {
        return <audio id="audio" loop autoPlay onEnded={onEnded}>
            <source src={"/sounds/" + file} type="audio/mpeg"/>
        </audio>
    }

    return <audio id="audio" autoPlay onEnded={onEnded}>
        <source src={"/sounds/" + file} type="audio/mpeg"/>
    </audio>

}
