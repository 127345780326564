export const NEW_NOTIFICATIONS_OVER_WEBSOCKET = 'NEW_NOTIFICATIONS_OVER_WEBSOCKERT';
export const NEW_ADMIN_NOTIFICATIONS_OVER_WEBSOCKET = 'NEW_ADMIN_NOTIFICATIONS_OVER_WEBSOCKET';

export const newNotificationsOverWebsocket = (notifications) => ({
    type: NEW_NOTIFICATIONS_OVER_WEBSOCKET,
    value: notifications,
});

export const newAdminNotificationsOverWebsocket = (notifications) => ({
    type: NEW_ADMIN_NOTIFICATIONS_OVER_WEBSOCKET,
    value: notifications,
});

export function notificationsOverWebsocket(notifications) {
    return function (dispatch) {
        dispatch(newNotificationsOverWebsocket(notifications));
    };
}

export function adminNotificationsOverWebsocket(notifications) {
    return function (dispatch) {
        dispatch(newAdminNotificationsOverWebsocket(notifications));
    };
}