import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material/";
import {Button} from "primereact/button";
import Description from "./Description";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function VeranstalterVerwaltenDialog({eventOperatorID, onClose, onSave}) {

    const [tabValue, setTabValue] = React.useState("veranstalterdaten");

    let [id, setId] = useState(eventOperatorID);
    let [proVeranstalter, setProVeranstalter] = useState(false);
    let [name, setName] = useState("");
    let [homepage, setHomepage] = useState("");
    let [socialmedia, setSocialmedia] = useState("");
    let [austragungsortBezeichnung, setAustragungsortBezeichnung] = useState("");
    let [austragungsortStrasse, setAustragungsortStrasse] = useState("");
    let [austragungsortPLZ, setAustragungsortPLZ] = useState("");
    let [austragungsortOrt, setAustragungsortOrt] = useState("");
    let [email, setEmail] = useState("");
    let [telefon, setTelefon] = useState("");

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if (eventOperatorID === undefined) {
            return;
        }
        ladeVeranstalter();
    }, [eventOperatorID]);

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Veranstalterdaten" ,"veranstalterdaten", false));
        returnValue.push(erzeugeTab("Austragungsort" ,"austragungsort", false));
        return returnValue;
    }

    const erzeugeTab = (value, key, icon) => {
        return <div style={{fontSize: 16, paddingLeft:10, paddingRight:10, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabAustragungsort = () => {
        return <div>
            <h3 style={{marginBottom:20}}>Austragungsort</h3>
            <Description
                message="Hier können Daten zum Austragungsort eingetragen werden. Diese werden in den Turnieren und Ligen als Standard vorbelegt. Natürlich können die Daten bei der Turnier-/Ligaanlage noch überschrieben werden."/>
            <div>
                <TextField id="standard-name" label="Bezeichnung Austragungsort" variant="standard"
                           value={austragungsortBezeichnung}
                           onChange={(e) => setAustragungsortBezeichnung(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">
                    <TextField id="standard-name" label="Straße" variant="standard" value={austragungsortStrasse}
                               onChange={(e) => setAustragungsortStrasse(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-fixed" style={{width: 200}}>
                    <TextField id="standard-name" label="PLZ" variant="standard" value={austragungsortPLZ}
                               onChange={(e) => setAustragungsortPLZ(e.target.value)} style={{width: "100%"}}/>
                </div>
                <div className="col">
                    <TextField id="standard-name" label="Ort" variant="standard" value={austragungsortOrt}
                               onChange={(e) => setAustragungsortOrt(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
        </div>
    }

    const getTabVeranstalterdaten = () => {
        return <div>
            <h3 style={{marginBottom:20}}>Veranstalterdaten</h3>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Name des Veranstalters" variant="standard" value={name}
                           onChange={(e) => setName(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-homepage" label="Homepagelink des Veranstalters" variant="standard"
                           value={homepage}
                           onChange={(e) => setHomepage(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-socialmedia" label="Socialmedialink des Veranstalters" variant="standard"
                           value={socialmedia}
                           onChange={(e) => setSocialmedia(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Telefon" variant="standard" value={telefon}
                           onChange={(e) => setTelefon(e.target.value)} style={{width: "100%"}}/>
            </div>
            <div style={{marginTop: 10}}>
                <TextField id="standard-name" label="Email" variant="standard" value={email}
                           onChange={(e) => setEmail(e.target.value)} style={{width: "100%"}}/>
            </div>
        </div>
    }
    const getTabpanel = () => {
        if (tabValue === "veranstalterdaten") {
            return getTabVeranstalterdaten();
        } else if (tabValue === "austragungsort") {
            return getTabAustragungsort();
        } else if (tabValue === "admins") {
            return getTabAdmins();
        }
        return "";
    }

    const ladeVeranstalter = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + id,
            json => {
                setId(json.id);
                setProVeranstalter(json.proVeranstalter);
                setName(json.name);
                setHomepage(json.homepage);
                setSocialmedia(json.socialmedia);
                setAustragungsortBezeichnung(json.austragungsortBezeichnung);
                setAustragungsortStrasse(json.austragungsortStrasse);
                setAustragungsortPLZ(json.austragungsortPLZ);
                setAustragungsortOrt(json.austragungsortOrt);
                setEmail(json.email);
                setTelefon(json.telefon);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);

        let data = {
            id: id,
            name: name,
            socialmedia: socialmedia,
            homepage: homepage,
            austragungsortBezeichnung: austragungsortBezeichnung,
            austragungsortStrasse: austragungsortStrasse,
            austragungsortPLZ: austragungsortPLZ,
            austragungsortOrt: austragungsortOrt,
            email: email,
            telefon: telefon
        };
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/save";
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Es wurde erfolgreich gespeichert");
                onSave();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const footerContent = (
        <div>
            <Button label="Speichern" icon="pi pi-times" onClick={() => save()} className="p-button-primary"/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
        </div>
    )

    return <DHDialog onClose={() => onClose()} title="Veranstalter verwalten" show={true} buttons={footerContent}
                     comp={<>
                         <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                             onClearErrorMessage={() => setErrorMessage(undefined)}/>
                         <div style={{marginTop: 10, marginBottom: 20}}>
                             <div style={{display: "flex"}}>
                                 {getTabs()}
                             </div>
                         </div>
                         {getTabpanel()}
                     </>}/>
}
