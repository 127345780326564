import {connect} from 'react-redux'
import Dashboard from "../../../components/views/start/Dashboard";
import {withCookies} from "react-cookie";

const mapStateToProps = (state, ownProps) => ({
    mandantType: state.user.mandantType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard))
