class TournamentSystems {
    constructor(id, text, icon, icon2) {
        this.id = id;
        this.text = text;
        this.icon = icon;
        this.icon2 = icon2;
    }
}

export const TOURNAMENTSYSTEMS = {
    EINFACHERKO: new TournamentSystems("EINFACHERKO", "Einfacher KO","/images/singleko.png"),
    DOPPELTERKO : new TournamentSystems("DOPPELTERKO", "Doppelter KO", "/images/doppelko.png"),
    GRUPPE_DANN_KO : new TournamentSystems("GRUPPEDANNKO", "Gruppenphase dann einfaches KO Turnier", "/images/group.png", "/images/singleko.png"),
    GRUPPE_DANN_DKO : new TournamentSystems("GRUPPEDANNDKO", "Gruppenphase dann doppeltes KO Turnier", "/images/group.png", "/images/doppelko.png"),
    LIGA: new TournamentSystems("LIGA", "Liga","/images/liga.png"),
    ROUND_ROBIN: new TournamentSystems("ROUNDROBIN", "Round Robin","/images/group.png"),
    KRATZER: new TournamentSystems("KRATZER", "Kratzer","/images/liga.png"),
    SCHWEIZERSYSTEM: new TournamentSystems("SCHWEIZERSYSTEM", "Schweizer System","/images/liga.png"),
    ANY_GAMES: new TournamentSystems("ANYGAMES", "Any Games","/images/liga.png"),
    LIGASPIELTAG: new TournamentSystems("LIGASPIELTAG", "Ligaspieltag","/images/liga.png"),
    EXTERNALLEAUGUE: new TournamentSystems("EXTERNALLEAGUE", "Externe Liga","/images/liga.png"),
    WEEKENDLEAGUE: new TournamentSystems("WEEKENDLEAGUE", "Weekend League","/images/liga.png"),
};


export function getTournamentsystem(id) {
    if (TOURNAMENTSYSTEMS.EINFACHERKO.id === id) {
        return TOURNAMENTSYSTEMS.EINFACHERKO;
    } else if (TOURNAMENTSYSTEMS.DOPPELTERKO.id === id) {
        return TOURNAMENTSYSTEMS.DOPPELTERKO;
    } else if (TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id === id) {
        return TOURNAMENTSYSTEMS.GRUPPE_DANN_KO;
    } else if (TOURNAMENTSYSTEMS.ROUND_ROBIN.id === id) {
        return TOURNAMENTSYSTEMS.ROUND_ROBIN;
    } else if (TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id === id) {
        return TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO;
    } else if (TOURNAMENTSYSTEMS.LIGA.id === id) {
        return TOURNAMENTSYSTEMS.LIGA;
    } else if (TOURNAMENTSYSTEMS.KRATZER.id === id) {
        return TOURNAMENTSYSTEMS.KRATZER;
    } else if (TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id === id) {
        return TOURNAMENTSYSTEMS.WEEKENDLEAGUE;
    } else if (TOURNAMENTSYSTEMS.EXTERNALLEAUGUE.id === id) {
        return TOURNAMENTSYSTEMS.EXTERNALLEAUGUE;
    } else if (TOURNAMENTSYSTEMS.LIGASPIELTAG.id === id) {
        return TOURNAMENTSYSTEMS.LIGASPIELTAG;
    } else if (TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id === id) {
        return TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM;
    } else if (TOURNAMENTSYSTEMS.ANY_GAMES.id === id) {
        return TOURNAMENTSYSTEMS.ANY_GAMES;
    }
    return undefined;
}
