import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';
import PropTypes from "prop-types";
import DateUtil from "../../../util/DateUtil";
import {GameModusComponent} from "../../general/GameModusComponent";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import {GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {ToggleButton} from "primereact/togglebutton";
import DHDialog from "../../general/DHDialog";
import DHDateTimePicker from "../../general/DHDateTimePicker";

const INITIAL_STATE = {
    id: "",
    date: "",
    beschreibung: "",
    firstToLegs: "",
    modusid: undefined,
    startpunkte: undefined,
    vonStatistikAusschliessen: false
};

class TrainingSessionEditDialog extends React.Component {

    constructor(props) {
        super(props);

        let id = this.props.trainingssession !== undefined ? this.props.trainingssession.id : undefined;
        this.state = {...INITIAL_STATE, id: id};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.ladeDaten();
        }
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        let id = this.state.id;
        if (id !== undefined && id !== null) {
            let url = ConfigUtil.getConfig().resourceUrls.training + "/" + id;
            FetchUtil.fetchGet(url,
                json => {
                    let mydate = Date.parse(json.timestampAsString);
                    let newDate = new Date(mydate);
                    let newState = {
                        id: json.id,
                        date: newDate,
                        beschreibung: json.beschreibung,
                        firstToLegs: json.firstToLegs,
                        modusid: json.modusid,
                        startpunkte: json.startpunkte,
                        vonStatistikAusschliessen: json.vonStatistikAusschliessen
                    };
                    this.setState(newState);
                },
                responseNotOk => {
                    this.showError("Fehler beim Laden der Trainingssession: " + responseNotOk.message);
                },
                error => {
                    this.showError("Fehler beim Laden der Trainingssession: " + error.message);
                })
        }
    }

    save() {
        this.messages.clear();
        let id = this.state.id;
        let date = this.state.date;
        let beschreibung = this.state.beschreibung;
        let firstToLegs = this.state.firstToLegs;
        let startpunkte = this.state.startpunkte;
        let modusid = this.state.modusid;
        let vonStatistikAusschliessen = this.state.vonStatistikAusschliessen;

        let data ={id: id, date: date, beschreibung: beschreibung, firstToLegs: firstToLegs, startpunkte: startpunkte, modusid: modusid, vonStatistikAusschliessen: vonStatistikAusschliessen };
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.training,
            data,
            json => {
                this.setState({id: json.id});
                this.showInfo("Trainingssession wurde erfolgreich gespeichert.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    hideDialog() {
        this.setState(INITIAL_STATE);
        this.props.callBackEditDialogClose();
    }

    render() {
        let saveButtonDisabled = !this.state.date || !this.state.firstToLegs;
        const dialogFooter = (
            <div>
                <Button label="Speichern" icon="pi pi-save" disabled={saveButtonDisabled} onClick={() => this.save()} />
                <Button label="Neuen Trainingstag anlegen" disabled={!this.state.id} onClick={() => this.setState(INITIAL_STATE)} style={{width: "300px", marginTop:"5px"}}/>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.hideDialog()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog title="Trainingssession anlegen / bearbeiten" onClose={() => this.hideDialog()} buttons={dialogFooter} comp={<>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div style={{height:300}}>
                <div className="grid" style={{marginTop:10}}>
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Datum:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <DHDateTimePicker disabled={false} value={this.state.date} changeValue={(date) => this.setState({date: date})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Beschreibung:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <InputText name="beschreibung" style={{width: "200px"}}
                                   value={this.state.beschreibung}
                                   onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Legs zum Sieg:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameFirstToLegsComponent firstToLegs={this.state.firstToLegs} onChange={(firstToLegs) => this.setState({firstToLegs: firstToLegs})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Modus:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameModusComponent modusId={this.state.modusid} onChange={(modusid) => this.setState({modusid: modusid})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Startpunkte:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameStartpunkteComponent startpunkte={this.state.startpunkte} onChange={(startpunkte) => this.setState({startpunkte: startpunkte})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Nicht in Statistik anzeigen:</div>
                    <div className="col">
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.state.vonStatistikAusschliessen}
                                      onChange={(e) => this.setState({vonStatistikAusschliessen: e.value})} />
                    </div>
                </div>
            </div>
        </>}/>
    }

}

TrainingSessionEditDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    callBackEditDialogClose: PropTypes.func.isRequired,
};

export default TrainingSessionEditDialog;
