import React from 'react'
import Links from "../../Links";
import WasKannDarthelfer from "../../WasKannDarthelfer";
import InteresseAnDarthelfer from "../../InteresseAnDarthelfer";
import {Systemvoraussetzungen} from "../Systemvoraussetzungen";
import StartseiteLinks from "./StartseiteLinks";
import DHMobileDiv from "../../general/DHMobileDiv";
import StartMobilTournament from "../../StartMobilTournaments";
import {StartZertifikat} from "../StartZertifikat";

class Start extends React.Component {

    render() {
        return <div>
            <DHMobileDiv comp={<StartMobilTournament/>}/>
            <div className="grid" style={{marginLeft: 10, marginRight: 10, marginTop: 10}}>
                <WasKannDarthelfer/>
                <StartZertifikat/>
                <StartseiteLinks cols={3} isOnlineDashboard={true}/>
                <InteresseAnDarthelfer/>
                <Systemvoraussetzungen/>
            </div>
            <div className="grid" style={{marginLeft: 10, marginRight: 10, marginTop: 10}}>
                <Links/>
            </div>
        </div>
    }
}

Start.propTypes = {};

export default Start
