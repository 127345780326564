import React from "react";


export default function LigaTeilnehmerLegendeArea() {

    const getRow = (picture, text) => {
        return <div style={{display: "flex", paddingBottom: 10, margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
            <img src={picture} style={{width: 30, height: 30, marginRight: 20}}
                 alt=""/>
            {text}
        </div>
    }

    return <div>
        <h3>Legende</h3>
        {getRow("/images/delete_color.png", "Löschen oder Deaktivieren des Teilnehmers")}
        {getRow("/images/euro_green.png", "Teilnehmer hat bezahlt, klick wechselt zu nicht bezahlt")}
        {getRow("/images/euro_black.png", "Teilnehmer hat NICHT bezahlt, klick wechselt zu bezahlt")}
        {getRow("/images/change.png", "Teilnehmer in andere Liga/Turnier verschieben. (nur für Onlineligen und -turniere)")}
        {getRow("/images/card_yellow_red.png", "Teilnehmer verwarnen (wird bei Onlineligen/-turnieren dazu genutzt wenn Gegner nicht antreten oder im Onlinegame Auffälligkeiten vorkommen.")}
        {getRow("/images/player_border.png", "Teilnehmer hat eine Mail erhalten aber seine Teilnahme nicht bestäigt.")}
        {getRow("/images/player_green.png", "Teilnehmer hat per klick in der Mail seine Teilnahme bestätigt oder ein Admin hat den Teilnehmer erfasst.")}
        {getRow("/images/anwesend_black.png", "Teilnehmer ist nicht als anwesend markiert")}
        {getRow("/images/anwesend_color.png", "Teilnehmer ist als anwesend markiert")}
        {getRow("/images/info.png", "Zeigt erweiterte Infos zum Teilnehmer. Wie Telefon usw.")}
        {getRow("/images/player_merge.png", "SpielerId ändern. Wichtig für Ranglisten und automatische Boardvergabe, dass ein Spieler immer die gleiche SpielerId hat.")}
        {getRow("/images/warteliste.png", "Spieler befindet sich auf der Warteliste und würde nicht im Turnier spielen")}
        {getRow("/images/nu_darthelfer_player_match.png", "Verknüpfe externe Person mit Darthelfer-User.")}
    </div>
}