import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import GameSchreiberDialog from "../liga/GameSchreiberDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function BoardSelectionDialog({eventOperatorId, tournamentId, tournamentGameId, spieler1Name, spieler2Name, showSchreiberArea, schreiberName, onClose, callBackLoadData, players}) {

    const [boards, setBoards] = useState([]);
    const [boardsMitGame, setBoardsMitGame] = useState([]);
    const [selectedBoardId, setSelectedBoardId] = useState(undefined);
    const [showDeleteBoardGameDialog, setShowDeleteBoardGameDialog] = useState(false);
    const [showSaveBoardGameDialog, setShowSaveBoardGameDialog] = useState(false);

    const [showSchreiberDialog, setShowSchreiberDialog] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        loadBoards();
    }, []);


    const getBoardMitGame = (boardId) => {
        for (const boardGame of boardsMitGame) {
            if (boardId === boardGame.boardId) {
                return boardGame;
            }
        }
        return undefined;
    }

    const saveBoardGame = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/saveBoardGame/" + eventOperatorId + "/" + selectedBoardId + "/" + tournamentId + "/" + tournamentGameId,
            undefined,
            returnValue => {
                if (!returnValue) {
                    setErrorMessage("An diesem Board wird bereits gespielt. Ansicht wird aktualisiert.");
                    loadBoards();
                } else {
                    setInfoMessage("Game wurde zum Board gespeichert.");
                    loadBoards();
                    callBackLoadData();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteBoardGame = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/deleteBoardGame/" + eventOperatorId + "/" + selectedBoardId,
            undefined,
            json => {
                setInfoMessage("Game am Board wurde gelöscht.");
                loadBoards();
                callBackLoadData();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const loadBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/" + eventOperatorId,
            json => {
                setBoards(json.boards);
                setBoardsMitGame(json.boardsMitGame);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            });
    }

    const getBoardCols = () => {
        if (boards.length === 0) {
            return <div style={{marginTop: 30}}>Keine Boards vorhanden.</div>;
        }
        let rows = [];
        for (const board of boards) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            rows.push(<div key={"key_board_" + board.id} style={{width: 320, maxWidth: 360, padding: 5, border: "1px solid #DDD", margin: 5}}>
                Bezeichnung: '{board.bezeichnung}'
                <div>
                    <Button icon="pi pi-trash" tooltip="Game am Board löschen"
                            style={{marginRight: "5px", marginTop: "5px"}}
                            disabled={existierentesBoardGameZurBoardId === undefined}
                            onClick={() => {
                                setSelectedBoardId(board.id);
                                setShowDeleteBoardGameDialog(true);
                            }}/>
                    <Button icon="pi pi-box" tooltip="Game am Board spielen"
                            style={{marginRight: "5px", marginTop: "5px"}}
                            disabled={existierentesBoardGameZurBoardId !== undefined}
                            onClick={() => {
                                setSelectedBoardId(board.id);
                                setShowSaveBoardGameDialog(true);
                            }}/>
                </div>
                { existierentesBoardGameZurBoardId !== undefined && <>
                    <div>Spiel: {existierentesBoardGameZurBoardId.gameDescription}</div>
                    <div>Turnier: {existierentesBoardGameZurBoardId.tournamentBezeichnung}</div>
                    {existierentesBoardGameZurBoardId.schreiberName !== null && existierentesBoardGameZurBoardId.schreiberName !== undefined &&
                    <div>Schreiber: {existierentesBoardGameZurBoardId.schreiberName}</div>
                    }
                </>
                }
        </div>);
        }
        return rows;
    }

    const footer = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    return <DHDialog onClose={() => onClose()} title={"Boardauswahl"} buttons={footer} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{fontSize: 18, marginBottom: 20}}>Board für Spiel<br/><span style={{fontWeight: "bold"}}>
            {spieler1Name} vs. {spieler2Name}</span><br/>
        </div>
        <div style={{fontSize: 18, marginBottom: 20}}>
            Board für Spiel<br/>
        </div>
        {showSchreiberArea && <>
            <span style={{fontWeight: "bold"}}>
                {schreiberName !== undefined && schreiberName !== null && schreiberName !== "" &&
                    <div>Schreiber: {schreiberName}</div>
                }
            </span>
            <div style={{marginBottom: 20}}>
                <Button label="Schreiber auswählen" type="button" onClick={() => setShowSchreiberDialog(true)}
                        style={{marginRight: 5}}/>
            </div>
        </>
        }
        <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
            {getBoardCols()}
        </div>
        {showDeleteBoardGameDialog && <ConfirmDialog header="Frage..."
                                                     textOben="Das Board ist für ein Game reserviert. Sofern du das Game an diesem Board löschen möchtest, kannst du dies hier tun. Allerdings kann das evtl. noch anstehende Game an dem Board nicht mehr gespielt werden."
                                                         textUnten="Möchtest du das Game am Board wirklich löschen?"
                                                         visible={true}
                                                         callBackOnNein={() => {
                                                             setShowDeleteBoardGameDialog(false);
                                                             setSelectedBoardId(undefined);
                                                         }}
                                                         callBackOnJa={() => {
                                                             setShowDeleteBoardGameDialog(false);
                                                             deleteBoardGame();
                                                         }}/>
            }
            {showSaveBoardGameDialog && <ConfirmDialog header="Board benutzen..."
                                                       textUnten="Möchtest du das Game wirklich an diesem Board spielen?"
                                                       visible={true}
                                                       callBackOnNein={() => {
                                                           setShowSaveBoardGameDialog(false);
                                                           setSelectedBoardId(undefined);
                                                       }}
                                                       callBackOnJa={() => {
                                                           setShowSaveBoardGameDialog(false);
                                                           saveBoardGame();
                                                       }}/>
            }
            {showSchreiberDialog &&
                <GameSchreiberDialog gameId={tournamentGameId} tournamentId={tournamentId} players={players}
                                     onRefresh={() => {
                                         loadBoards();
                                         callBackLoadData();
                                     }}
                                     onClose={() => setShowSchreiberDialog(false)}/>}
    </>}/>
}
