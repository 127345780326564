import React, {useRef} from 'react'
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import RanglisteTable from "./RanglisteTable";
import {Button} from "primereact/button";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {NavLink} from "react-router-dom";


export default function PublicTournamentRanglisteTab({participants, eventOperatorId, rankingId}) {
    const filterParticipantsOP = useRef(null);

    const [filterText, setFilterText] = React.useState("");

    const filterParticipants = () => {
        if( filterText === "") {
            return participants;
        }
        let returnList = [];
        for (const participant of participants) {
            if( participant.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(participant);
            }
        }
        return returnList;
    }

    return <div>
        <div style={{display: "flex", marginTop:20, marginLeft: 15, marginBottom: 10}}>
            <OverlayPanel ref={filterParticipantsOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 100, width: 200}}>
                <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <InputText id="filterText" value={filterText}
                                       onChange={(e) => setFilterText(e.target.value)}/>
                            <label htmlFor="filterText">Filter</label>
                        </span>
                </div>
            </OverlayPanel>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {participants.length}</div>
            <i className="pi pi-filter" style={{color: "white", width: 40, height: 30, fontSize: 20}}
               onClick={(e) => filterParticipantsOP.current.toggle(e)}/>
            {filterText !== "" &&
                <div style={{color: "white", marginRight: 10, fontSize: 20}}>Gefiltert: {filterParticipants().length}</div>
            }
            {rankingId !== null && rankingId !== undefined &&
                <div>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + eventOperatorId +"?tab=ranking&ranking=" + rankingId } style={{textDecoration: "none"}}>
                        <Button label="Zur Gesamtrangliste"/>
                    </NavLink>
                </div>
            }
        </div>
        <RanglisteTable participants={filterParticipants()}/>
    </div>
}

