import {connect} from 'react-redux'
import TrainingOverview from "../../../components/views/training/TrainingOverview";

const mapStateToProps = state => ({
    userRoles: state.user.userRoles
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingOverview)