import {connect} from 'react-redux'
import LigaShortOverview from "../../../components/views/liga/LigaShortOverview";

const mapStateToProps = function (state, ownProps) {
    return {ligaInfos: state.tournament.ligaInfos};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LigaShortOverview)