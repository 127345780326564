import * as React from 'react';
import {useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PlayerUtil from "../../../../util/PlayerUtil";
import StatistikGameDialog from "./StatistikGameDialog";
import BoardSelectionDialog from "../../veranstalter/BoardSelectionDialog";
import DHDefaultDrawer from "../../../general/DHDefaultDrawer";
import {Button} from "primereact/button";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import {InputNumber} from "primereact/inputnumber";

const CELL_PADDING_VALUE = 8;

export default function TournamentSchweizerSystemPlatzierungTable({tournamentId, eventOperatorId, games, players, boardGames, liveGames, isReadOnly,
                                                                      onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    const [selectedGameId, setSelectedGameId] = useState(undefined);
    const [selectedGameBoardVergabe, setSelectedGameBoardVergabe] = useState(undefined);
    const [editGame, setEditGame] = useState(undefined);
    const [anzahlSpieler1, setAnzahlSpieler1] = useState(0);
    const [anzahlSpieler2, setAnzahlSpieler2] = useState(0);

    const save = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeschweizerplatzierungsgame";
        let data = {
            tournamentid: tournamentId,
            tournamentGameId: editGame.id,
            player1Id: editGame.player1Id,
            player2Id: editGame.player2Id,
            anzahlPlayer1: anzahlSpieler1,
            anzahlPlayer2: anzahlSpieler2,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                setInfoMessage("Ergebnis wurde erfolgreich gespeichert.");
                setEditGame(undefined);
                setAnzahlSpieler1(0);
                setAnzahlSpieler2(0);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern des Ergebnisses: " + responseNotOk.message);
                setEditGame(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Speichern des Ergebnisses: " + error.message);
                setEditGame(undefined);
            });
    }
    const getBoardGame = (gameId) => {
        if( !boardGames) {
            return undefined;
        }
        for (const boardGame of boardGames) {
            if( boardGame.tournamentGameId === gameId) {
                return boardGame;
            }
        }
        return undefined;
    }

    const getLiveGame = (boardGame) => {
        if( liveGames === null || liveGames === undefined || boardGame === null || boardGame === undefined) {
            return undefined;
        }
        let gameId = boardGame.gameId;
        let tmpGames = liveGames.find(game => game.gameId === gameId);
        return tmpGames
    }

    const getLiveGameErgebnis = (liveGame) => {
        if( liveGame.startssoon) {
            return <img src="/images/dot_red.png" style={{width:20, marginTop:5}}/>
        }
        if( liveGame.inGame) {
            return <img src="/images/dot_green.png" style={{width:20, marginTop: 5}}/>
        }
        if( liveGame.sets > 1 ) {
            return <span style={{opacity:0.5, marginTop: 5}}>{liveGame.spieler1Sets}:{liveGame.spieler2Sets}</span>
        } else {
            return <span style={{opacity:0.5, marginTop: 5}}>{liveGame.spieler1Legs}:{liveGame.spieler2Legs}</span>
        }
    }

    const getTableRows = (games) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        games.forEach(game => {

            let boardGame = getBoardGame(game.id);
            let liveGame = getLiveGame(boardGame);

            let ergebnisVorhanden = game.anzahlPlayer1 !== null;
            let ergebnis =  ergebnisVorhanden ? game.anzahlPlayer1 + ":" + game.anzahlPlayer2 : "-";
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={game.id}>
                <TableCell style={{...style, textAlign:"center"}}>{game.spielUmPlatz}</TableCell>
                <TableCell style={{...style, textAlign:"right"}}>{PlayerUtil.getSpielerName(players, game.player1Id)}</TableCell>
                {ergebnisVorhanden && <TableCell style={{...style, textAlign:"center"}}><span style={{cursor: "pointer"}} onClick={() => {
                    setSelectedGameId(game.id);
                    setShowGameDetailDialog(true);
                }}>{ergebnis}</span></TableCell>}
                {!ergebnisVorhanden && <TableCell style={{...style,textAlign:"center"}}>
                    {boardGame !== undefined ?
                        <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20, marginTop:5}}>{boardGame.boardBezeichnung}</div>
                            :
                        <div style={{textAlign:"center", fontSize:20}}>-</div>
                    }
                    {!ergebnisVorhanden && liveGame !== undefined && getLiveGameErgebnis(liveGame)}
                </TableCell>}


                <TableCell style={style}>{game.player2Id !== null ? PlayerUtil.getSpielerName(players, game.player2Id) : "Freilos"}</TableCell>
                {!isReadOnly &&
                    <TableCell style={style}>
                        {!ergebnisVorhanden &&
                            <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben"
                                 style={{cursor: "pointer", width: 30, marginRight: 10}}
                                 onClick={() => {
                                     setSelectedGameBoardVergabe(game);
                                 }}/>
                        }
                        <Button icon="pi pi-pencil" onClick={() => setEditGame(game)}
                                style={{height: 40, width: 40}}/>
                    </TableCell>
                }
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    if (games.length === 0) {
        return <div style={{color: "white", fontSize: 20, padding: 20}}>Aktuell keine Games vorhanden.</div>
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20, padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:12, width: 100, textAlign:"center", padding:CELL_PADDING_VALUE}}>Spiel um Platz</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, textAlign:"right", padding:CELL_PADDING_VALUE}}>Spieler 1</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, textAlign:"center", width: 50, padding:CELL_PADDING_VALUE}}>Ergebnis</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Spieler 2</TableCell>
                        {!isReadOnly && <TableCell style={{backgroundColor: "#ac1c27", color:"white", width: 100, fontSize:20, padding:CELL_PADDING_VALUE}}>#</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(games)}
                </TableBody>
            </Table>
        </TableContainer>
        {showGameDetailDialog &&
            <StatistikGameDialog tournamentId={tournamentId} tournamentGameId={selectedGameId} onClose={() => {
                setShowGameDetailDialog(false);
                setSelectedGameId(undefined);
            }}/>
        }
        {selectedGameBoardVergabe !== undefined &&
            <BoardSelectionDialog onClose={() => {
                setSelectedGameBoardVergabe(undefined);
            }}
                                  eventOperatorId={eventOperatorId}
                                  spieler1Name={PlayerUtil.getSpielerName(players, selectedGameBoardVergabe.player1Id)}
                                  spieler2Name={PlayerUtil.getSpielerName(players, selectedGameBoardVergabe.player2Id)}
                                  showSchreiberArea={false}
                                  schreiberName={() => ""}
                                  players={players}
                                  callBackLoadData={() => onRefresh()}
                                  tournamentId={tournamentId}
                                  tournamentGameId={selectedGameBoardVergabe.id}/>
        }
        {editGame && <DHDefaultDrawer title="Änderung" onClose={() => setEditGame(undefined)} show={true} children={
            <div className="grid" style={{padding: 20, width:300}}>
                <div className="col-fixed" style={{width:150, marginLeft: 50}}>
                    <InputNumber inputStyle={{width: 50}} value={anzahlSpieler1}
                               onChange={(e) => setAnzahlSpieler1(e.value)} min={0}/>  :
                    <InputNumber inputStyle={{width: 50}} value={anzahlSpieler2}
                               onChange={(e) => setAnzahlSpieler2(e.value)} min={0}/>
                </div>
                <div className="col-fixed" style={{width: 40}}>
                    <Button icon="pi pi-check"  tooltip="Spiel speichern" style={{marginLeft:5, marginRight:5}} onClick={()=> save()}/>
                </div>
            </div>
        }/>}
    </>
}
