import React from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";

const MODUS_ZUR_AUSWAHL = [
    {name: 'Auswahl löschen', code: undefined},
    {name: 'Straight Out', code: 'straight_out'},
    {name: 'Double Out', code: 'double_out'},
    {name: 'Do.In Do.Out', code: 'double_in_double_out'},
    {name: 'Triple Out', code: 'triple_out'},
    {name: 'Master Out', code: 'master_out'},
];

export function GameModusComponent({modusId, onChange}){
    return <DHSelectionComponent value={modusId} onChange={onChange} values={MODUS_ZUR_AUSWAHL} title="Modus"/>
}

function getModusById(modusId) {
    if( modusId === undefined || modusId === null) {
        return {name: '', code: ''};
    }
    for (const moduszurauswahlElement of MODUS_ZUR_AUSWAHL) {
        if( moduszurauswahlElement.code === modusId) {
            return moduszurauswahlElement;
        }
    }
    return {name: '', code: ''};
}

export function getModusNameById(modusId) {
    return getModusById(modusId).name;
}