import React, {useEffect, useState} from 'react'
import {OrderList} from 'primereact/orderlist';
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function TurniergruppeProblemDialog({tournament, group, ranglisteDtos, ranglisteManuellDtos, onHide, onRefresh, onGetSpielername}) {

    let [players, setPlayers] = useState([]);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        let tmpPlayers = [];
        if( ranglisteManuellDtos !== null && ranglisteManuellDtos !== undefined && ranglisteManuellDtos.length > 0 ) {
            for (const ranglisteDto of ranglisteManuellDtos) {
                tmpPlayers.push({name: onGetSpielername(ranglisteDto.playerid), id: ranglisteDto.playerid});
            }
        } else {
            for (const ranglisteDto of ranglisteDtos) {
                tmpPlayers.push({name: onGetSpielername(ranglisteDto.playerid), id: ranglisteDto.playerid});
            }
        }
        setPlayers(tmpPlayers);
    }, []);

    const rowTemplate = (player) => {
        return (
            <div className="grid">
                <div className="col"
                     style={{fontSize: '14px', float: 'right', margin: '15px 5px 0 0'}}>{player.name}</div>
            </div>
        );
    }

    const getOrgRanglisteRows = () => {
        let rows = [];
        if (ranglisteDtos === undefined || ranglisteDtos === null) {
            return rows;
        }
        for (let i = 0; i < ranglisteDtos.length; i++) {
            let ranglisteDto = ranglisteDtos[i];
            rows.push(<div className="grid" style={{marginBottom: 10}}>
                <div className="col-fixed" style={{width: 30}}>{i + 1}.</div>
                <div className="col">{onGetSpielername(ranglisteDto.playerid)}</div>
            </div>)
        }
        return rows;
    }

    const save = () => {
        setErrorMessage(undefined);

        let playerids = [];
        for (const player of players) {
            playerids.push(player.id);
        }
        let data = {tournamentid: tournament.id, group: group, playerIds: playerids};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/speichereGruppenergebnisManuell",
            data,
            json => {
                setInfoMessage("Das Ergebnis wurde gespeichert!");
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const isAenderungsmodus = () => {
        return tournament.folgeTurnierId === null || tournament.folgeTurnierId === undefined;
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" disabled={!isAenderungsmodus()} onClick={() => save()}
                    className="p-button-primary"/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onHide()}
                    className="p-button-secondary"/>
        </div>
    );

    return <DHDialog onClose={() => onHide()} title="Tabelle von Hand festlegen" show={true} buttons={dialogFooter}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom:10}}>
            Es besteht ein Problem in der Tabelle, das System konnte nicht automatisch bestimmen, wer weiter kommt.
        </div>
        <div style={{marginBottom:10}}>
            Lass die entsprechenden Personen z.B. Ausbullen, First 9 oder ähnliches und lege die Tabelle manuell fest.
        </div>
        <div className="grid" style={{height: 500}}>
            <div className="col" style={{height: 500}}>
                <OrderList value={players} itemTemplate={rowTemplate} header="Neue Rangliste"
                           onChange={(e) => setPlayers(e.value)} listStyle={{height: "100%"}}></OrderList>
            </div>
            <div className="col">
                <div className="grid" style={{marginBottom: 20}}>
                    <div className="col" style={{fontWeight: "bold"}}>
                        Orginal Tabelle
                    </div>
                </div>
                {getOrgRanglisteRows()}
            </div>
        </div>
    </>}/>
}
