import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {PickList} from "primereact/picklist";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function TournamentPrioArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [sourcePrio, setSourcePrio] = useState([]);
    const [targetPrio, setTargetPrio] = useState([]);
    const [sourceOhneBoardvergabe, setSourceOhneBoardvergabe] = useState([]);
    const [targetOhneBoardvergabe, setTargetOhneBoardvergabe] = useState([]);

    const [showHinweis, setShowHinweis] = useState(false);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/board/tournamentprio/" + eventOperatorId,
            json => {
                // Prioliste
                let mySourcePrio = [];
                let myTargetPrio = [];
                for (const prioTournamentId of json.prioTournamentIds) {
                    let tournament = json.tournaments.find(tournament => tournament.id === prioTournamentId.id);
                    // könnte undefined sein, da Turnier vielleicht schon abgeschlossen ist.
                    if( tournament !== undefined) {
                        myTargetPrio.push({id:tournament.id, bezeichnung:tournament.bezeichnung});
                    }
                }
                for (const tournament of json.tournaments) {
                    let gefunden = myTargetPrio.find(tmp => tmp.id === tournament.id);
                    if( gefunden === undefined) {
                        mySourcePrio.push(tournament);
                    }
                }
                setTargetPrio(myTargetPrio);
                setSourcePrio(mySourcePrio);
                // Prioliste ENDE ******
                // Ohne Boardvergabe
                let mySourceOhneBoardvergabe = [];
                let myTargetOhneBoardvergabe = [];
                for (const ohneBoardvergabeTournamentId of json.ohneBoardvergabeTournamentIds) {
                    let tournament = json.tournaments.find(tournament => tournament.id === ohneBoardvergabeTournamentId.id);
                    // könnte undefined sein, da Turnier vielleicht schon abgeschlossen ist.
                    if( tournament !== undefined) {
                        myTargetOhneBoardvergabe.push({id:tournament.id, bezeichnung:tournament.bezeichnung});
                    }
                }
                for (const tournament of json.tournaments) {
                    let gefunden = myTargetOhneBoardvergabe.find(tmp => tmp.id === tournament.id);
                    if( gefunden === undefined) {
                        mySourceOhneBoardvergabe.push(tournament);
                    }
                }
                setTargetOhneBoardvergabe(myTargetOhneBoardvergabe);
                setSourceOhneBoardvergabe(mySourceOhneBoardvergabe);
                // Ohne Boardvergabe ENDE ******
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const save = () => {
        setErrorMessage(undefined);
        let proIds = [];
        let ohneBoardvergabeIds = [];
        for (const tmp of targetPrio) {
            proIds.push({id:tmp.id});
        }
        for (const tmp of targetOhneBoardvergabe) {
            ohneBoardvergabeIds.push({id:tmp.id});
        }
        let data = {prioTournamentIds: proIds, ohneBoardvergabeTournamentIds: ohneBoardvergabeIds};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save/board/tournamentprio/" + eventOperatorId,
            data,
            () => {
                setInfoMessage("Speichern war erfolgreich");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const itemTemplate = (item) => {
        if( item === null ) {
            return "";
        }
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <span className="product-category">{item.bezeichnung}</span>
                </div>
            </div>
        );
    }

    const onChangePrio = (event) => {
        setSourcePrio(event.source);
        setTargetPrio(event.target);
    }

    const onChangeOhneBoardvergabe = (event) => {
        setSourceOhneBoardvergabe(event.source);
        setTargetOhneBoardvergabe(event.target);
    }

    const getTournamentPrioArea = () => {
        return <>
            <h3>Turniere mit Prio:</h3>
            <PickList source={sourcePrio} target={targetPrio} itemTemplate={itemTemplate}
                      sourceHeader="Turniere" targetHeader="Prioliste"
                      sourceStyle={{ height: '150px' }} targetStyle={{ height: '150px' }}
                      onChange={(event) => onChangePrio(event)}
                      showSourceControls={false}></PickList>
            <h3>Turniere für die aktuell kein Board automatisch vergeben werden soll:</h3>
            <PickList source={sourceOhneBoardvergabe} target={targetOhneBoardvergabe} itemTemplate={itemTemplate}
                      sourceHeader="Turniere" targetHeader="Turniere ohne Boardvergabe"
                      sourceStyle={{ height: '150px' }} targetStyle={{ height: '150px' }}
                      onChange={(event) => onChangeOhneBoardvergabe(event)}
                      showSourceControls={false}></PickList>
        </>
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis" toggleable collapsed={!showHinweis} onToggle={(e) => setShowHinweis(!showHinweis)} style={{marginBottom:10}}>
            <div style={{fontSize:16}}>Hier siehst du deine 'gestarteten' Turniere. Sofern du bei der automatischen Boardvergabe eine Prioriät bei
                der Reihenfolge vergeben willst, schiebe das Turnier in die Prioliste und entsprechend nach oben oder unten.
                Sobald die Boardvergabe angestoßen wird, z.B. wenn ein Game eintragen wird, wird in der Prioreihenfolge versucht ein neues Game
                zu vergeben. Danach kommen die Turniere ohne Prio.<br/><br/>
                Hintergrund ist, dass du evtl. z.B. ein Turnier zuende spielen willst und andere Turniere einfach mehr Zeit haben.
            </div>
        </Fieldset>
        {getTournamentPrioArea()}
        <div style={{width:"100%", padding:20, textAlign:"right"}}>
            <Button label="Speichern" icon="pi pi-check" onClick={() => save()}/>
        </div>
    </>
}
