import React from "react";
import PropTypes from "prop-types";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import {Button} from "primereact/button";
import TournamentCricketArea from "../TournamentCricketArea";

function KODKOCricketVerwaltenArea({
                                       tournamentSystem,
                                       gewinnerseiteSets,
                                       gewinnerseiteFirstToLegs,
                                       gewinnerseiteZufallszahlen,
                                       gewinnerseiteZufallszahlenJedesLeg,
                                       gewinnerseiteCutthroat,
                                       verliererseiteSets,
                                       verliererseiteFirstToLegs,
                                       verliererseiteZufallszahlen,
                                       verliererseiteZufallszahlenJedesLeg,
                                       verliererseiteCutthroat,
                                       setGewinnerseiteFirstToLegs,
                                       setGewinnerseiteZufallszahlen,
                                       setGewinnerseiteZufallszahlenJedesLeg,
                                       setGewinnerseiteCutthroat,
                                       setGewinnerseiteSets,
                                       setVerliererseiteSets,
                                       setVerliererseiteFirstToLegs,
                                       setVerliererseiteZufallszahlen,
                                       setVerliererseiteZufallszahlenJedesLeg,
                                       setVerliererseiteCutthroat,
                                   }) {

    function uebernehmeNachVerliererseite() {
        setVerliererseiteSets(gewinnerseiteSets);
        setVerliererseiteFirstToLegs(gewinnerseiteFirstToLegs);
        setVerliererseiteZufallszahlen(gewinnerseiteZufallszahlen);
        setVerliererseiteZufallszahlenJedesLeg(gewinnerseiteZufallszahlenJedesLeg);
        setVerliererseiteCutthroat(gewinnerseiteCutthroat);
    }

    return <>
        {tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id &&
            <div className="grid" style={{marginTop: 20}}>
                <div className="col-fixed" style={{width: 200, fontWeight: "bold"}}>Gewinnerseite:
                    (pflicht)
                </div>
            </div>
        }
        <TournamentCricketArea showSets={true} showBestOfLegs={false} showPflichtfelder={true}
                               sets={gewinnerseiteSets} firstToLegs={gewinnerseiteFirstToLegs}
                               zufallszahlen={gewinnerseiteZufallszahlen}
                               zufallszahlenjedesleg={gewinnerseiteZufallszahlenJedesLeg}
                               cutthroat={gewinnerseiteCutthroat}
                               onSetsChanged={(sets) => setGewinnerseiteSets(sets)}
                               onFirstToLegsChanged={(firstToLegs) => setGewinnerseiteFirstToLegs(firstToLegs)}
                               onZufallszahlenChanged={(value) => setGewinnerseiteZufallszahlen(value)}
                               onZufallszahlenjedeslegChanged={(value) => setGewinnerseiteZufallszahlenJedesLeg(value)}
                               onCutthroatChanged={(value) => setGewinnerseiteCutthroat(value)}
        />
        {tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id && <>
            <div className="grid" style={{marginTop: 20}}>
                <div className="col" style={{width: 200, fontWeight: "bold"}}>Verliererseite:
                    (pflicht)
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <Button label="Daten von Gewinnerseite übernehmen"
                            onClick={uebernehmeNachVerliererseite}/>
                </div>
            </div>
            <TournamentCricketArea showSets={true} showBestOfLegs={false} showPflichtfelder={true}
                                   sets={verliererseiteSets} firstToLegs={verliererseiteFirstToLegs}
                                   zufallszahlen={verliererseiteZufallszahlen}
                                   zufallszahlenjedesleg={verliererseiteZufallszahlenJedesLeg}
                                   cutthroat={verliererseiteCutthroat}
                                   onSetsChanged={(sets) => setVerliererseiteSets(sets)}
                                   onFirstToLegsChanged={(firstToLegs) => setVerliererseiteFirstToLegs(firstToLegs)}
                                   onZufallszahlenChanged={(value) => setVerliererseiteZufallszahlen(value)}
                                   onZufallszahlenjedeslegChanged={(value) => setVerliererseiteZufallszahlenJedesLeg(value)}
                                   onCutthroatChanged={(value) => setVerliererseiteCutthroat(value)}
            />
        </>
        }
    </>
}

KODKOCricketVerwaltenArea.propTypes = {
    tournamentSystem: PropTypes.string.isRequired,
    gewinnerseiteSets: PropTypes.string.isRequired,
    gewinnerseiteFirstToLegs: PropTypes.string.isRequired,
    gewinnerseiteZufallszahlen: PropTypes.string.isRequired,
    gewinnerseiteZufallszahlenJedesLeg: PropTypes.string.isRequired,
    gewinnerseiteCutthroat: PropTypes.string.isRequired,
    verliererseiteSets: PropTypes.string.isRequired,
    verliererseiteFirstToLegs: PropTypes.string.isRequired,
    verliererseiteZufallszahlen: PropTypes.string.isRequired,
    verliererseiteZufallszahlenJedesLeg: PropTypes.string.isRequired,
    verliererseiteCutthroat: PropTypes.string.isRequired,
    setGewinnerseiteSets: PropTypes.func.isRequired,
    setGewinnerseiteFirstToLegs: PropTypes.func.isRequired,
    setGewinnerseiteZufallszahlen: PropTypes.func.isRequired,
    setGewinnerseiteZufallszahlenJedesLeg: PropTypes.func.isRequired,
    setGewinnerseiteCutthroat: PropTypes.func.isRequired,
    setVerliererseiteSets: PropTypes.func.isRequired,
    setVerliererseiteFirstToLegs: PropTypes.func.isRequired,
    setVerliererseiteZufallszahlen: PropTypes.func.isRequired,
    setVerliererseiteZufallszahlenJedesLeg: PropTypes.func.isRequired,
    setVerliererseiteCutthroat: PropTypes.func.isRequired,
};

export default KODKOCricketVerwaltenArea
