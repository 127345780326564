import {connect} from 'react-redux'

import TrainingGameEdit from "../../../components/views/training/TrainingGameEdit";

const mapStateToProps = state => ({
    userid: state.user.id
});
const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingGameEdit)