import * as React from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {PrimeIcons} from "primereact/api";
import {useState} from "react";

export default function StatisticTable({participants, tournamentId, playerStatistiks, onShowStatistik, onShowGames}) {
    const CELL_PADDING_VALUE = 8;

    let [selectedParticipant, setSelectedParticipant] = useState(undefined);

    const getTableRows = (participants, playerStatistiks, onShowStatistik, onShowGames) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        participants.forEach(participant => {
            let statistik = findStatistik(participant.id, participant.idShort, playerStatistiks);
            let style;
            if( selectedParticipant !== undefined && selectedParticipant.id === participant.id) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }
            returnRows.push( <TableRow key={participant.id} onMouseOver={() => setSelectedParticipant(participant)}
            onClick={() => setSelectedParticipant(participant)}>
                <TableCell style={style}>{participant.name}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik.average : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik.first9Average : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik._100Plus : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik._140Plus : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik._171er : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{statistik !== undefined ? statistik._180er : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>
                    {statistik !== undefined ? <>
                        <i className={PrimeIcons.CHART_LINE} style={{fontSize:20, paddingRight:2, cursor:"pointer"}} onClick={() => onShowStatistik(statistik, participant.name)}/>
                        {tournamentId !== undefined && <i className={PrimeIcons.DATABASE} style={{fontSize:20, padding:2, cursor:"pointer"}} onClick={() => onShowGames(participant.id)}/>}
                    </>
                        :
                        "-"
                    }
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const findStatistik = (playerId, idShort, playerStatistiks) => {
        for (const statistik of playerStatistiks) {
            if( statistik.playerid === playerId || statistik.idShort === idShort) {
                return statistik;
            }
        }
        return undefined;
    }

    if( participants.length === 0) {
        return ""
    }
    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>Avg.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>F-9 Avg.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>100+</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>140+</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>171</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:30, padding:CELL_PADDING_VALUE}}>180</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:100, padding:CELL_PADDING_VALUE}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(participants, playerStatistiks, onShowStatistik, onShowGames)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
