import {connect} from 'react-redux'
import MeineSpieleOverview from "../../../components/views/liga/MeineSpieleOverview";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id, games: state.games.activeGames};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MeineSpieleOverview)