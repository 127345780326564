import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {InputText} from "primereact/inputtext";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function GameSchreiberDialog({tournamentId, gameId, players, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [tabValue, setTabValue] = useState("spieler");

    const [schreiberListe, setSchreiberListe] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/schreiber/" + tournamentId,
            json => {
                setSchreiberListe(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message)
        });

    }

    const getFilteredSchreiber = () => {
        if( filter.trim() === "") {
            return schreiberListe;
        }
        return schreiberListe.filter(schreiber => schreiber.name.includes(filter.trim()));
    }

    const getFilteredPlayers = () => {
        if( filter.trim() === "") {
            return players;
        }
        return players.filter(player => player.name.includes(filter.trim()));
    }

    const saveSchreiber = (id, name) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/game/schreiber/change/" + tournamentId + "/" + gameId,
            {id: id, name: name},
            () => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            })
    }

    const getPlayer = (player) => {
        return <div style={{marginBottom: 10, fontSize:18}}>
            <Button icon="pi pi-save" label="Speichern" style={{marginRight: 5}} onClick={() => saveSchreiber(player.id, player.name)}/>
            {player.name}</div>
    }

    const getZusaetzlicherSchreiber = (schreiber) => {
        return <div style={{marginBottom: 10, fontSize:18}}>
            <Button icon="pi pi-save" label="Speichern" style={{marginRight: 5}} onClick={() => saveSchreiber(schreiber.id, schreiber.name)}/>
            {schreiber.name}</div>
    }

    const erzeugeTab = (value, key, width) => {
        return <div style={{fontSize: 20, width:width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Spieler" ,"spieler", 180));
        returnValue.push(erzeugeTab("zusätzliche Schreiber", "schreiber", 250));
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "schreiber" ) {
            return <div style={{marginTop:20}}>
                {getFilteredSchreiber().length === 0 ?
                    <div>Keine Schreiber vorhanden</div>
                    :
                    getFilteredSchreiber().map(schreiber => <div key={"key_schreiber_" + schreiber.id}>
                        {getZusaetzlicherSchreiber(schreiber)}
                    </div>)
                }
            </div>
        } else if( tabValue === "spieler" ) {
            return <div style={{marginTop:20}}>
                {getFilteredPlayers().length === 0 ?
                    <div>Keine Spieler vorhanden</div>
                    :
                    getFilteredPlayers().map(player => <div key={"key_player_" + player.id}>
                        {getPlayer(player)}
                    </div>)
                }
            </div>
        }
    }

    const footer = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary" />
        </div>
    );

    return <DHDialog onClose={() => onClose()} title="Schreiber für Game wählen" show={true} buttons={footer}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{textAlign:"left"}}>
            <div style={{marginTop:20}}>
                Filter:
                <InputText value={filter} style={{width: 100, marginLeft:5}}
                           onChange={(e) => setFilter(e.target.value)}/>
            </div>
            <div style={{marginTop:20}}>
                <div style={{display:"flex"}}>
                    {getTabs()}
                </div>
            </div>
            {getTabpanel()}
        </div>
    </>}/>
}
