import {connect} from 'react-redux'
import withRouter from "../../../../components/RouterComponentWrapper";
import LiveView from "../../../../components/views/public/liveview/LiveView";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const tournamentId = urlParams.get('tournamentid');
    const eventOperatorId = urlParams.get('eventoperatorid');
    const boardId = urlParams.get('boardid');
    return {tournamentid: tournamentId, eventOperatorId: eventOperatorId, boardId: boardId, showEmbedded: false};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveView))
