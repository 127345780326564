import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import {Button} from "primereact/button";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import {PrimeIcons} from "primereact/api";
import DHDefaultDrawer from "../general/DHDefaultDrawer";
import {InputText} from "primereact/inputtext";
import ConfirmDialog from "../general/ConfirmDialog";

export default function NuEmailadressenComponent({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [email, setEmail] = useState("");
    const [emailAdressen, setEmailAdressen] = useState([]);

    const [deleteId, setDeleteId] = useState(undefined);


    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/email/laden/"+ eventOperatorId,
            json => {
                setEmailAdressen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const deleteEmailadresse = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.nu + "/email/delete/" + eventOperatorId + "/" + deleteId,
            {},
            () => {
                setInfoMessage("Email wurde gelöscht.");
                ladeDaten();
                setDeleteId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setDeleteId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setDeleteId(undefined);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/email/save/" + eventOperatorId,
            {email: email},
            json => {
                setInfoMessage("Emailadresse wurde gespeichert.");
                setEmail("");
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler:" + error.message)
            })
    }


    const getHinweisArea = () => {
        return <div style={{marginTop: 20, fontSize: 11}}>
            <strong>Hinweis:</strong><br/>
            Hier kannst du Mailadresse verwalten, die beim Übertragen des Ergebnisses an nu automatisch eine Ligaspieltagzusammenfassung per Mail erhalten.<br/><br/>
            * z.B. Allgemeine Emailadresse deines Verbandes bei komplett digitalem Spielbetrieb.<br/><br/>
            Du kannst direkt am Ligaspieltag noch eine weitere Adresse eingeben, z.B. für den  Kapitän der Gastmannschaft<br/><br/>
            Sofern du eine fehlerhafte Emailadresse gespeichert hast, lösche diese einfach weg und gebe eventuell eine neue Emailadresse ein.
        </div>
    }

    const getNewEmailadressArea = () => {
        return <div>
            <div style={{marginBottom: 5, fontWeight:"bold"}}>
                Emailadresse:
            </div>
            <div style={{marginBottom: 10}}>
                <InputText value={email} style={{width: "100%"}}
                           onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div style={{marginBottom: 5}}>
                <Button disabled={email === ""} label="Speichern" icon={PrimeIcons.SAVE} onClick={() => save()}/>
            </div>
        </div>
    }

    const getRows = () => {
        let rows = [];
        for (const email of emailAdressen) {
            rows.push(<div className="grid" style={{marginBottom: 5}} key={"key_email_" + email.id}>
                <div className="col-fixed" style={{width: 50}}>
                    <Button icon={PrimeIcons.TRASH} onClick={() => setDeleteId(email.id)} style={{marginRight: 5}}/>
                </div>
                <div className="col">
                    {email.email}
                </div>
            </div>)
        }
        return rows;
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title={"Emailadressen verwalten"} children={
        <div style={{minWidth: 350, padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            {getNewEmailadressArea()}
            <hr/>
            <div>
                <div style={{marginBottom:10}}>Bestehende Emailadressen:</div>
                <div>
                    {emailAdressen.length === 0 && <div style={{marginTop:20}}>Aktuell keine Emailadressen vorhanden.</div>}
                    {emailAdressen.length > 0 && getRows()}
                </div>
            </div>
            <hr/>
            {getHinweisArea()}
            {deleteId !== undefined &&
                <ConfirmDialog visible={true}
                               callBackOnNein={() => setDeleteId(undefined)}
                               callBackOnJa={() => deleteEmailadresse()}
                               header="Emailadresse löschen"
                               textOben=""
                               textUnten="Möchtest du die Emailadresse wirklich löschen?"/>
            }
        </div>
    }/>
}