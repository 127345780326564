import {connect} from 'react-redux'
import RanglisteView from "../../../components/views/liga/RanglisteView";

const mapStateToProps = (state, ownProps) => ({
    userRoles: state.user.userRoles,
    userId: state.user.id,
    leagueoperators: state.leagueoperator.leagueoperators,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RanglisteView)