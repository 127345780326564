import React, {useState} from 'react'
import PublicTournamentRegistrationOptionLogin from "./PublicTournamentRegistrationOptionLogin";
import PublicTournamentRegistrationOptionVorOrt from "./PublicTournamentRegistrationOptionVorOrt";
import PublicTournamentRegistrationOptionAnonym from "./PublicTournamentRegistrationOptionAnonym";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import PublicTournamentRegistrationOptionIdShort from "./PublicTournamentRegistrationOptionIdShort";
import PublicTournamentDeregistrationComp from "./PublicTournamentDeregistrationComp";

export default function PublicTournamentRegistrationTab({isLoggedIn, eventOperatorId, anzahlTeilnehmer, maxAnzahlTeilnehmer, tournamentId, registrationAllowed, isParticipantsMailConfirmation,
                                                            isDoppelturnier,isDYP, passwordRequired, isUserTeilnehmer, onRefresh}) {

    let [showDeregistration, setShowDeregistration] = useState(false);
    let [selectedRegistrationType, setSelectedRegistrationType] = useState(isDoppelturnier && !isDYP ? "idshort" : "login");

    if( !registrationAllowed) {
        return <div style={{color:"white", padding:20, fontSize:16, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
            <div style={{backgroundColor:"white", color:"black", height:"100%", marginTop:20, marginBottom:20, padding:20}}>
                <Card style={{marginBottom:20}}>
                    <div>Die Registrierung wurde vom Veranstalter für die Teilnehmer  deaktiviert. Vermutlich kannst du dich direkt am Turnier-/Ligatag beim Veranstalter anmelden. Bei Fragen nimm gerne Kontakt zum Veranstalter auf.</div>
                </Card>
            </div>
        </div>
    }

    const getButton = (type) => {
        return <Button icon={selectedRegistrationType === type && "pi pi-check"} style={{marginRight:10, backgroundColor: selectedRegistrationType === type ? "#009f5a" : ""}} onClick={() => setSelectedRegistrationType(type)}/>
    }

    return <div style={{color:"white", padding:20, fontSize:16, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <div style={{backgroundColor:"white", color:"black", height:"100%", marginTop:20, marginBottom:20, padding:20}}>
            <Card style={{marginBottom: 20, fontSize: 16}}>
                <div style={{marginRight: 10, marginBottom:20, fontWeight:"bold"}}>
                    Anzahl der Teilnehmer: {anzahlTeilnehmer}
                    {maxAnzahlTeilnehmer !== null && maxAnzahlTeilnehmer !== undefined && " - max. Anzahl: " + maxAnzahlTeilnehmer}
                </div>
                <div>Es gibt verschiedene Möglichkeiten dich am Turnier anzumelden, bitte entscheide dich für eine dieser Möglichkeiten:</div>
                <ul>
                    {(!isDoppelturnier || isDYP) &&
                        <>
                            <li style={{marginBottom: 10}}>{getButton("login")}
                                <span style={{color: "#009f5a", fontWeight: "bold"}}>* EMPFOHLEN *</span> Du meldest dich mit
                                deinem kostenlosen Darthelfer-Zugang an. Damit werden dir deine Spielstatistiken usw. direkt
                                zugewiesen.
                            </li>
                            <br/>
                        </>
                    }
                    <li style={{marginBottom: 10}}>{getButton("idshort")}<span style={{color: "#009f5a", fontWeight: "bold"}}>* EMPFOHLEN *</span> Du hast schon einmal mit Darthelfer gespielt, dann
                        hast du eine <span style={{fontWeight: "bold"}}>SpielerID</span>. Diese findest du in einer Anmeldeemail oder in deinem Profil.
                    </li>
                    <br/>
                    <li style={{marginBottom: 10}}>{getButton("anonym")}Du meldest dich hier mit deinen Daten an und bestätigst deine Anmeldung. Hierfür erhälst du eine Email und musst darüber die
                        Anmeldung bestätigen.
                    </li>
                    <br/>
                    <li>{getButton("vorort")}Du bist direkt auf der Veranstaltung und meldest dich beim Veranstalter an. Hier reichen deine Daten, besser ist jedoch auch hier die <span
                        style={{fontWeight: "bold"}}>SpielerID</span> aus einer Anmeldeemail oder deinem Profil.
                    </li>
                </ul>
            </Card>
            {isUserTeilnehmer &&
                <PublicTournamentRegistrationOptionLogin isLoggedIn={isLoggedIn} isUserTeilnehmer={isUserTeilnehmer} tournamentId={tournamentId} passwordRequired={passwordRequired}
                                                         onRefresh={onRefresh}/>
            }
            {selectedRegistrationType === "vorort" && <PublicTournamentRegistrationOptionVorOrt/>}
            {selectedRegistrationType === "login" && !isUserTeilnehmer && (!isDoppelturnier || isDYP) && <PublicTournamentRegistrationOptionLogin isLoggedIn={isLoggedIn} tournamentId={tournamentId} passwordRequired={passwordRequired} onRefresh={onRefresh} />}
            {selectedRegistrationType === "anonym" && <PublicTournamentRegistrationOptionAnonym tournamentId={tournamentId} isParticipantsMailConfirmation={isParticipantsMailConfirmation} isDoppelturnier={isDoppelturnier} isDYP={isDYP} passwordRequired={passwordRequired} onRefresh={onRefresh}/>}
            {selectedRegistrationType === "idshort" && <PublicTournamentRegistrationOptionIdShort tournamentId={tournamentId} isParticipantsMailConfirmation={isParticipantsMailConfirmation} passwordRequired={passwordRequired} onRefresh={onRefresh}/>}
            <div style={{marginTop:30}}>
                <Button className="p-button-danger" onClick={() => setShowDeregistration(true)} label={"Abmelden"} style={{width:300, height:40}}/>
            </div>
        </div>
        {showDeregistration && <PublicTournamentDeregistrationComp onClose={() => setShowDeregistration(false)} tournmanetId={tournamentId}/>}
    </div>
}

