import {NEW_LIGA_INFOS_OVER_WEBSOCKET, NEW_TURNIER_INFOS_OVER_WEBSOCKET,} from "../actions/tournament";

function tournament(state = {
    turnierInfos: [],
    ligaInfos: [],
}, action) {
    switch (action.type) {
        case NEW_LIGA_INFOS_OVER_WEBSOCKET:
            return {...state, ligaInfos: action.value};
        case NEW_TURNIER_INFOS_OVER_WEBSOCKET:
            return {...state, turnierInfos: action.value};
        default:
            return state
    }
}

export default tournament;