import React, {useEffect, useState} from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DateUtil from "../../util/DateUtil";
import TrainingAuswahlSaison from "./TrainingAuswahlSaison";

import Table from './trainingsTable';
import HigscoreAndHighfinishArea from './highscoreAndHighfinishArea'
import ErrorInfoComponent from "./ErrorInfoComponent";

export default function Trainingtabelle({trainingssessionid, loadGesamt, ueberschrift, counter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [saisonAuswahlVisible, setSaisonAuswahlVisible] = useState(false);
    let [selectedSaison, setSelectedSaison] = useState(undefined);

    let [rangliste, setRangliste] = useState([]);
    let [sort, setSort] = useState("default");

    useEffect(() => {
        ladeDaten(undefined, undefined, true, sort);
    }, [trainingssessionid, counter]);

    const callbackSort = (sort) => {
        setSort(sort);
        if( selectedSaison !== undefined) {
            ladeDaten(selectedSaison.vondatum, selectedSaison.bisdatum, false, sort);
        }
    }

    const saisonUebernehmen = (saison) => {
        setSaisonAuswahlVisible(false);
        setSelectedSaison(saison);
        ladeDaten(saison.vondatum, saison.bisdatum, false, sort);
    }

    const saisonLoeschen = () =>{
        setSaisonAuswahlVisible(false);
        setSelectedSaison(undefined);
        ladeDaten(undefined, undefined, true, sort);
    }

    const ladeDaten = (von, bis, wandleDatum, sort) => {
        setErrorMessage(undefined);
        let sortIntern = sort !== undefined && sort !== "" ? sort : "default";
        if (loadGesamt) {
            if ((von === undefined || von === "") || (bis === undefined || bis === "")) {
                setRangliste([]);
                return;
            }
            let vonFuerBackend;
            let bisFuerBackend;
            if (wandleDatum) {
                vonFuerBackend = DateUtil.toLocalDateTime(von);
                bisFuerBackend = DateUtil.toLocalDateTime(bis);
            } else {
                vonFuerBackend = von;
                bisFuerBackend = bis;
            }
            let data = {vondatum: vonFuerBackend, bisdatum: bisFuerBackend};
            FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.statistics + "/ranglistevonbis/" + sortIntern,
                data,
                json => {
                    setRangliste(json);
                },
                responseNotOk => {
                    setErrorMessage(responseNotOk.message);
                },
                error => {
                    setErrorMessage(error.message);
                })
        } else {
            if (trainingssessionid === undefined) {
                return;
            }
            let data = {id: trainingssessionid};
            FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.statistics + "/ranglistetotrainingsession/" + sortIntern,
                data,
                json => {
                    setRangliste(json);
                },
                responseNotOk => {
                    setErrorMessage(responseNotOk.message);
                },
                error => {
                    setErrorMessage(error.message);
                })
        }
    }

    const getDatumArea = () => {
        if (loadGesamt) {
            return <div>
                <TrainingAuswahlSaison visible={saisonAuswahlVisible}
                                       callbackOnSelect={saisonUebernehmen}
                                       callbackOnDelete={saisonLoeschen}/>
            </div>;
        } else {
            return <div/>;
        }
    }

    if ( rangliste === undefined || rangliste.length === 0) {
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        return <div style={{marginTop: "20px", textAlign: "left"}}>
            <div style={{marginBottom: "20px", fontWeight: "bold", textAlign: "left"}}>{ueberschrift}</div>
            {getDatumArea()}
            <div>Aktuell sind keine Tabellendaten vorhanden...</div>
        </div>
    }

    return <div style={{marginTop: "20px"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: "20px", fontWeight: "bold", textAlign: "left"}}>{ueberschrift}</div>
        {getDatumArea()}
        <Table rangliste={rangliste} sort={sort} callbackSort={() => callbackSort()}/>
        <HigscoreAndHighfinishArea rangliste={rangliste} />
    </div>
}
