class TournamentModus {
    constructor(id) {
        this.id = id;
    }
}

export const TOURNAMENTMODUS = {
    LIGA: new TournamentModus("liga"),
    WEEKENDLEAGUE: new TournamentModus("weekendleague"),
    KRATZER: new TournamentModus("kratzer"),
    ANY_GAMES: new TournamentModus("anygames"),
    GRUPPENTURNIER: new TournamentModus("gruppenturnier"),
    VERLIERERSEITE: new TournamentModus("verliererseite"),
    GEWINNERSEITE: new TournamentModus("gewinnerseite"),
    FINALE: new TournamentModus("finale"),
    SPIELUMPLATZDREI: new TournamentModus("spielumplatzdrei"),
    HALBFINALE: new TournamentModus("halbfinale"),
    VIERTELFINALE: new TournamentModus("viertelfinale"),
    ACHTELFINALE: new TournamentModus("achtelfinale"),
};


export function getTournamentmodus(id) {
    if (TOURNAMENTMODUS.LIGA.id === id) {
        return TOURNAMENTMODUS.LIGA;
    }
    return undefined;
}
