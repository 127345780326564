import DHDialog from "../general/DHDialog";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import {ProgressSpinner} from "primereact/progressspinner";
import ConfirmProgressDialog from "../general/ConfirmProgressDialog";


export default function NuPlayerAuswahlComponent({tournamentId, tournamentPlayers, externMeetingId, qrCode, showGuestPlayers, onClose, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [nuHomePlayers, setNuHomePlayers] = useState([]);
    let [nuGuestPlayers, setNuGuestPlayers] = useState([]);

    let [showSaveDialog, setShowSaveDialog] = useState(false);
    let [selectedPlayer, setSelectedPlayer] = useState(undefined);

    let [filter, setFilter] = useState("");

    let [working, setWorking] = useState(false);


    useEffect(() => {
        if( qrCode ) {
            ermittleGastplayersOeffentlicherBereich();
        } else {
            if( showGuestPlayers ) {
                ermittleGastplayer();
            } else {
                ermittleHeimplayer();
            }
        }
    }, []);

    const nuPlayerVorhanden = (externPlayerId) => {
        return tournamentPlayers.filter(player => player.externPlayerId === externPlayerId).length > 0;
    }

    const ermittleGastplayersOeffentlicherBereich = () =>  {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/meeting/guestplayers/" + tournamentId + "/" + qrCode,
            json => {
                setNuGuestPlayers(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Spieler zur Gastmannschaft: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Spieler zur Gastmannschaft: " + error.message);
            })
    }

    const ermittleHeimplayer = () =>  {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/homeplayers/" + externMeetingId,
            json => {
                setNuHomePlayers(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Spieler zur Heimmannschaft: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Spieler zur Heimmannschaft: " + error.message);
            })
    }

    const ermittleGastplayer = () =>  {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/guestplayers/" + externMeetingId,
            json => {
                setNuGuestPlayers(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Spieler zur Gastmannschaft: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Spieler zur Gastmannschaft: " + error.message);
            })
    }

    const savePlayer = () => {
        setWorking(true);
        setErrorMessage(undefined);
        let data = {externMeetingId: externMeetingId, tournamentId: tournamentId, heim: !showGuestPlayers, gast: showGuestPlayers,
            externPlayerId: selectedPlayer.nuLigaPlayerId,
            externPersonId: selectedPlayer.nuLigaPersonId,
            lastname: selectedPlayer.lastname,
            firstname: selectedPlayer.firstname,
        };
        let url;
        if( qrCode ) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/nu/saveplayer/" + qrCode;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.nu + "/saveplayer";
        }
        FetchUtil.fetchPost(url,
            data,
            () => {
                setInfoMessage("BDV/nu Spieler wurde nach Darthelfer übernommen.")
                onRefresh();
                setSelectedPlayer(undefined);
                setShowSaveDialog(false);
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedPlayer(undefined);
                setShowSaveDialog(false);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setSelectedPlayer(undefined);
                setShowSaveDialog(false);
                setWorking(false);
            }
        );
    }

    const bdvNuGuestPlayerArea = () => {
        return nuPlayerArea("Gastmannschaft", nuGuestPlayers)
    }
    const bdvNuHomePlayerArea = () => {
        return nuPlayerArea("Heimmannschaft", nuHomePlayers)
    }

    const getPlayerLabel = (player) => {
        if( player === undefined) {
            return "";
        }
        return player.lastname + ", " + player.firstname + " / " + player.nuLigaPersonId;
    }
    const nuPlayerArea = (label, players) => {
        let rows = [];
        let color = true;
        players.sort((a, b) => a.lastname.localeCompare(b.lastname));
        for (const tmp of players) {
            if( tmp.nuLigaPersonId < 0) {
                continue;
            }
            let value = getPlayerLabel(tmp);
            if( filter !== "" && !value.toUpperCase().includes(filter.toUpperCase())) {
                continue;
            }
            rows.push(<div key={"key_" + tmp.nuLigaPlayerId}
                           style={{textAlign:"center", border:"1px solid #ddd",
                               fontSize:20, padding:10,
                               backgroundColor: color ? "#f2f2f2" : "white"}}>
                <span style={{cursor:"pointer"}} onClick={() => {
                    setShowSaveDialog(true);
                    setSelectedPlayer(tmp);
                }}>{nuPlayerVorhanden(tmp.nuLigaPlayerId) && <img src="/images/dot_green.png" style={{height:20, marginRight:10}}/>}
                    {value}</span>
            </div>);
            color = !color;
        }
        rows.push(<hr style={{borderTop:"2px solid black"}}/>);
        for (const tmp of players) {
            if( tmp.nuLigaPersonId >= 0) {
                continue;
            }
            let value = tmp.lastname + ", " + tmp.firstname + " (" + tmp.nuLigaPersonId + ")";
            rows.push(<div key={"key_" + tmp.nuLigaPlayerId}
                           style={{textAlign:"center", border:"1px solid #ddd",
                               fontSize:20, padding:10, cursor:"pointer",
                               backgroundColor: color ? "#f2f2f2" : "white"}}>
                <span style={{cursor:"pointer"}} onClick={() => {
                    setShowSaveDialog(true);
                    setSelectedPlayer(tmp);
                }}>{nuPlayerVorhanden(tmp.nuLigaPlayerId) && <img src="/images/dot_green.png" style={{height:20, marginRight:10}}/>}
                    {value}</span>
            </div>);
            color = !color;
        }
        return <DHDialog title={label} show={true} onClose={onClose} showCloseButton={true} comp={<>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <div style={{marginBottom:10}}>Filter: <InputText value={filter} onChange={(e) => setFilter(e.target.value)}/></div>
            {players.length === 0 && <>
                    <div style={{marginTop:30}}>Keine Spielerdaten vorhanden. Wir laden gerade von nu...</div>
                    <ProgressSpinner style={{width: 50, height: 50}} />
                </>
            }
            {rows}
            {showSaveDialog &&
                <ConfirmProgressDialog visible={true}
                                       working={working}
                               callBackOnNein={() => setShowSaveDialog(false)}
                               callBackOnJa={() => savePlayer()}
                               header="Spieler übernehmen"
                               textOben={"Der gewählte Spieler " + getPlayerLabel(selectedPlayer) + " wird als Teilnehmer übernommen. Sofern der Teilnehmer bereits vorhanden ist, werden die Daten in Darthelfer aktualisiert."}
                               textUnten="Möchtest du den Teilnehmer wirklich übernehmen?"/>
            }
        </>}/>
    }

    if( showGuestPlayers ) {
        return bdvNuGuestPlayerArea();
    } else {
        return bdvNuHomePlayerArea();
    }

}