import React, {useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function ProfilAutodartsArea({propBoardId, onSave}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [boardId, setBoardId] = useState(propBoardId !== null && propBoardId !== undefined ? propBoardId : "");

    const save = () => {
        setErrorMessage(undefined);
        let tmp = boardId.trim();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/save/autodarts/boardid",
            {boardId: tmp},
            json => {
                setInfoMessage("BoardId wurde gespeichert!");
                onSave(tmp);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    return <div className="ligashortoverview"
                style={{height: "100%", borderLeft: "10px solid gray", textAlign: "left"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>

        <div style={{width: "100%", padding:5,  backgroundImage: "linear-gradient(to left, #1aa3ff, #0059B3)", borderTop: "1px solid white"}}>
            <img src="/images/autodarts_powered_by.png" style={{height: 40, marginRight:10}}/>
            <img src="/images/darthelfer_quer_weiss.png" style={{height: 30, marginBottom:5}}/>
        </div>
        <div className="grid" style={{marginTop: "20px"}}>
        <div className="col" style={{width: '180px', textAlign: "left"}}>BoardId:</div>
        </div>
        <div className="grid">
            <div className="col"><InputText style={{width: "100%"}} value={boardId}
                                            onChange={(e) => setBoardId(e.target.value)}/>
            </div>
        </div>
        <div>
            <Button label="Speichern" onClick={() => save()}/>
        </div>
    </div>
}
