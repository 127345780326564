import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {LigaSpieltagComponent, Sponsoren, Tabelle171er, Tabelle180er, TabelleBestDarts, TabelleHighfinishes, TabelleHighscores, TabelleRangliste, TabelleWorstDarts} from "./ligaComponents";
import {Button} from "primereact/button";
import LigaTable from "./ligaTable";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import PropTypes from "prop-types";
import LigaKalenderArea from "./LigaKalenderArea";
import ConfirmKennwortDialog from "../../general/ConfirmKennwortDialog";
import LigaZuschauenArea from "./LigaZuschauenArea";
import Chat from "../../general/Chat";
import {ToggleButton} from "primereact/togglebutton";
import {Dropdown} from "primereact/dropdown";
import LigaSpielbareSpieltageDialog from "./LigaSpielbareSpieltageDialog";
import ConfirmAbfrageTeilnahmebedingungenDialog from "./ConfirmAbfrageTeilnahmebedingungenDialog";
import TournamentPayPalDialog from "./TournamentPayPalDialog";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import LigaUrkunden from "./LigaUrkunden";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import LigaVerwaltenDialog from "../tournament/LigaVerwaltenDialog";
import PublicTournamentStatistikTab from "../public/tournaments/PublicTournamentStatistikTab";
import TurnierQRCode from "./TurnierQRCode";
import RefreshCounterComp from "../../RefreshCounterComp";
import {PrimeIcons} from "primereact/api";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";
import {ProgressSpinner} from "primereact/progressspinner";

class LigaDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {readonly:false, showEdit:false, showAktionen: false, zeigeBeschreibungVoll:false,
            nurMeineSpieleAnzeigen: false, nurSpieleOhneErgebnis: false, showDialogSpielbareSpieltage:false, showKennwortDialog:false,
            kennwort:"", showAbfrageTeilnahmebedingungenDialog: true, abfrageTeilnahmebedingungenBeantwortet: false, showBezahlenDialog:false,
            boardGames: []};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);
        this.hasPlayersMeetingkeyAllowed = this.hasPlayersMeetingkeyAllowed.bind(this);
        this.isSpielerInaktiv = this.isSpielerInaktiv.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }


    ladeDaten() {
        this.messages.clear();
        this.ladeLigaDaten();
    }

    teilnahmeDoppelAendern() {
        this.messages.clear();
        let mussTeilnahmebedingungAbgefragtWerden =
            this.state.liga.teilnahmebedingungen !== null
            && this.state.liga.teilnahmebedingungen !== undefined
            && this.state.liga.teilnahmebedingungen
        if( mussTeilnahmebedingungAbgefragtWerden && !this.state.abfrageTeilnahmebedingungenBeantwortet) {
            this.setState({showAbfrageTeilnahmebedingungenDialog: true});
            return
        }
        let mussKennwortAbgefragtWerden = this.state.liga.kennwort !== null && this.state.liga.kennwort !== undefined && this.state.liga.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeDoppelAendern?tournamentid=" + this.state.liga.id +
            "&doppelpaarungid=" + this.state.selectedDoppelpaarung.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeAendern() {
        this.messages.clear();
        let mussTeilnahmebedingungAbgefragtWerden =
            this.state.liga.teilnahmebedingungen !== null
            && this.state.liga.teilnahmebedingungen !== undefined
            && this.state.liga.teilnahmebedingungen
        if( mussTeilnahmebedingungAbgefragtWerden && !this.state.abfrageTeilnahmebedingungenBeantwortet) {
            this.setState({showAbfrageTeilnahmebedingungenDialog: true});
            return
        }
        let mussKennwortAbgefragtWerden = this.state.liga.kennwort !== null && this.state.liga.kennwort !== undefined && this.state.liga.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.liga.id +"&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeBeenden(teilnahmeAufJedenFallBeenden) {
        this.messages.clear();
        let userHatBezahlt = this.isAktuellerUserAlsBezahltMarkiert() ;
        if( userHatBezahlt && !teilnahmeAufJedenFallBeenden) {
            this.setState({showTeilnahmeBeendenTrotzBezahltMeldung: true});
            return;
        }
        this.setState({showTeilnahmeBeendenTrotzBezahltMeldung:false});
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.liga.id,
            {},
            json => {
                this.showInfo("Speichern war erfolgreich!")
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    ladeLigaDaten() {
        if (this.props.ligaid === null || this.props.ligaid === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/ladeliga/" + this.props.ligaid;
        FetchUtil.fetchGet(url,
            json => {
                let readonly = json.tournamentDto.tournamentstatus === "abgeschlossen";

                console.log(json);
                this.setState({liga: json.tournamentDto,
                    ligaGebuehrZuBezahlen: json.ligaGebuehrZuBezahlen,
                    spielbareSpieltage: json.spielbareSpieltage,
                    usernames: json.usernameDtos,
                    boardGames: json.boardGames,
                    tabelle:json.tabelleDto !== null ? json.tabelleDto.rangliste : undefined,
                    ranglisteManuell: json.ranglisteWrapper.ranglisteManuellDtos,
                    ranglisteHighfinishes: json.ranglisteWrapper.ranglisteHighfinishes,
                    ranglisteHighscores: json.ranglisteWrapper.ranglisteHighscores,
                    rangliste180er: json.ranglisteWrapper.rangliste180er,
                    rangliste171er: json.ranglisteWrapper.rangliste171er,
                    statistik: json.ranglisteWrapper.statistikDto,
                    playerStatistiks: json.ranglisteWrapper.playerStatistiks,
                    bestDarts: json.ranglisteWrapper.bestDarts,
                    worstDarts: json.ranglisteWrapper.worstDarts,
                    readonly:readonly,
                    doppelpaarungen: json.doppelpartnerDtos,
                    showKennwortDialog: false,
                    kennwort:"",
                    showAbfrageTeilnahmebedingungenDialog: false,
                    abfrageTeilnahmebedingungenBeantwortet: false});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der aktiven Ligen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der aktiven Ligen: " + error.message);
            });
    }

    getUsernamesByUserId(userId) {
        for (const username of this.state.usernames) {
            if( username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    isAktuellerUserAdministrator() {
        if( this.state.liga.erstellerUserId === this.props.userId) {
            return true;
        }
        for (const admin of this.state.liga.administratoren) {
            if( admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    isAktuellerUserAlsBezahltMarkiert() {
        if (this.state.liga.tournamentPlayerEntities === null || this.state.liga.tournamentPlayerEntities === undefined) {
            return false;
        }

        if( this.state.liga.doppelturnier) {
            console.log("Methode ist nicht für Doppel ausgelegt.")
            return false;
        } else {
            return this.state.liga.tournamentPlayerEntities.filter(player => player.userId === this.props.userId && player.bezahlt).length > 0
        }
    }

    isAktuellerUserInLigaVorhanden() {
        if (this.state.liga.tournamentPlayerEntities === null || this.state.liga.tournamentPlayerEntities === undefined) {
            return false;
        }

        if( this.state.liga.doppelturnier) {
            for (const doppelDto of this.state.liga.doppelpartnerDtos) {
                if( doppelDto.userId1 === this.props.userId || doppelDto.userId2 === this.props.userId) {
                    return true;
                }
            }
        } else {
            for (const player of this.state.liga.tournamentPlayerEntities) {
                if (player.userId === this.props.userId && !player.inaktiv) {
                    return true;
                }
            }
        }
        return false;
    }

    isSpieltagAktiv(spieltag) {
        let returnValue = this.state.spielbareSpieltage === null ||
            this.state.spielbareSpieltage === undefined ||
            this.state.spielbareSpieltage.length === 0 ||
            this.state.spielbareSpieltage.find(tmp => tmp.spieltag === spieltag) !== undefined;
        return returnValue;
    }

    getAktiveSpieltageArea() {
        let tmp = "";
        for (const spieltag of this.state.spielbareSpieltage) {
            tmp += spieltag.spieltag + ", ";
        }
        return tmp;
    }

    getLigaDetailArea() {
        if (this.state.liga === null || this.state.liga === undefined) {
            return <div>Es sind noch keine Daten geladen...</div>
        }

        let ligaBetreiber = this.getLigaBetreiber();
        let ligaBetreiberName = "";
        let ligaBetreiberHomepage = "";
        let ligaBetreiberSocialmedia = "";
        if( ligaBetreiber !== undefined) {
            ligaBetreiberName = ligaBetreiber.name + " / " + ligaBetreiber.shortname;
            ligaBetreiberHomepage = ligaBetreiber.homepage;
            ligaBetreiberSocialmedia = ligaBetreiber.socialmedia;
        }

        let tournamentModus = this.state.liga.tournamentModusEntities[0];
        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid ">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">{this.state.liga.bezeichnung}</div>
                    </div>
                    {this.state.liga.tournamentSponsorenEntities.length > 0 && <Sponsoren sponsoren={this.state.liga.tournamentSponsorenEntities} />}
                    {ligaBetreiberName !== "" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Ligabetreiber:</div>
                            <div className="col">{ligaBetreiberName}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}></div>
                            <div className="col">
                                {ligaBetreiberHomepage ? <a href={ligaBetreiberHomepage} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/homepage.png" alt="Homepage" style={{width:30}}/></a> :"" }
                                {ligaBetreiberSocialmedia ? <a href={ligaBetreiberSocialmedia} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/facebook.png" alt="Facebook" style={{marginLeft:10, width:30}}/></a> :"" }
                            </div>
                        </div>
                    </>
                    }
                    {this.state.liga.anmeldeende !== null && this.state.liga.anmeldeende !== undefined &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Anmeldeende:</div>
                            <div className="col">{this.state.liga.anmeldeendeMitUhrzeitString}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{this.state.liga.beginnString}</div>
                    </div>
                    {this.getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{this.state.liga.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ligaleitung:</div>
                        <div className="col">{this.getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ligastatus:</div>
                        <div className="col">{this.state.liga.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>GameType:</div>
                        <div className="col">{this.state.liga.gameType}</div>
                    </div>
                    {this.state.liga.gameType === "cricket" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Cutthroat:</div>
                            <div className="col">{tournamentModus.cutthroat ? "ja" : "nein"}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Zufallszahlen:</div>
                            <div className="col">{tournamentModus.zufallszahlen ? "ja" : "nein"}</div>
                        </div>
                        {tournamentModus.zufallszahlen && <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Zufallsz. j. Leg:</div>
                            <div className="col">{tournamentModus.zufallszahlenjedesleg ? "ja" : "nein"}</div>
                        </div>
                        }
                    </>
                    }
                    {this.state.liga.gameType === "x01" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Modus:</div>
                            <div className="col">{getModusNameById(tournamentModus.modusid)}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Startpunkte:</div>
                            <div className="col">{tournamentModus.startpunkte}</div>
                        </div>
                    </>}
                    {this.isSetModus() &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Sets:</div>
                            <div className="col">{tournamentModus.sets}</div>
                        </div>
                    }
                    {tournamentModus.firstToLegs &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>first to legs:</div>
                        <div className="col">{tournamentModus.firstToLegs}</div>
                    </div>
                    }
                    {tournamentModus.bestOfLegs &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>best of legs:</div>
                        <div className="col">{tournamentModus.bestOfLegs}</div>
                    </div>
                    }
                    { this.state.liga.rangliste &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Ranglistenliga:</div>
                            <div className="col">{this.state.liga.rangliste ? "Ja" : "Nein"}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Hin. u. Rückspiel:</div>
                        <div className="col">{this.state.liga.hinUndRueckspiel ? "Ja" : "Nein"}</div>
                    </div>
                    {(this.state.liga.averageObergrenze !== null && this.state.liga.averageObergrenze !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Avg. Obergrenze:</div>
                            <div
                                className="col">{this.state.liga.averageObergrenze}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Anzahl Spieler:</div>
                        <div className="col">{this.state.liga.tournamentPlayerEntities !== null && this.state.liga.tournamentPlayerEntities !== undefined ? this.state.liga.tournamentPlayerEntities.length :  "0"}</div>
                    </div>
                    {(this.state.liga.maxAnzahlTeilnehmer !== null && this.state.liga.maxAnzahlTeilnehmer !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Max. Anzahl Teilnehmer:</div>
                            <div
                                className="col">{this.state.liga.maxAnzahlTeilnehmer}</div>
                        </div>
                    }
                    {(this.state.spielbareSpieltage !== null && this.state.spielbareSpieltage !== undefined && this.state.spielbareSpieltage.length > 0) &&
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Aktive Spieltage:</div>
                        <div className="col">{this.getAktiveSpieltageArea()}</div>
                    </div>
                    }
                </div>
            </div>
            {this.state.liga.tournamentstatus === "neu" && this.state.liga.online && this.state.liga.doppelturnier && !this.isAktuellerUserInLigaVorhanden() &&
            <>
                <div className="grid" style={{fontSize:12, marginBottom:15}}>
                    Hier kannst du deinen Doppelnamen auswählen und damit am Turnier teilnehmen. Sofern du hier keinen Doppelnamen findest ist für dich noch keine Doppelpaarung angelegt, im Profil kannst du diese anlegen/verwalten oder Details sehen.
                </div>
                <div className="grid">
                    <Dropdown value={this.state.selectedDoppelpaarung} options={this.state.doppelpaarungen}
                              optionLabel="name"
                              onChange={(e) => {
                                  this.setState({selectedDoppelpaarung: e.value})
                              }}
                              placeholder="Eingabe..." style={{width: 300}}/>
                    <Button label="Am Doppeliga teilnehmen" icon="pi pi-plus"
                            onClick={() => this.teilnahmeDoppelAendern()}
                            disabled={this.state.selectedDoppelpaarung === undefined || this.state.selectedDoppelpaarung === null}
                            style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                </div>
            </>
            }
            <div className="grid" style={{marginTop:10}}>
                {this.state.ligaGebuehrZuBezahlen !== null && this.state.ligaGebuehrZuBezahlen !== undefined &&
                    <Button label="Bezahlung vornehmen" className="p-button-danger"
                            onClick={() => this.setState({showBezahlenDialog: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {this.state.liga.online && this.state.liga.tournamentstatus === "neu" && !this.state.liga.doppelturnier && !this.isAktuellerUserInLigaVorhanden() &&
                    <Button label="An der Liga teilnehmen" icon="pi pi-plus" onClick={() => this.teilnahmeAendern()} style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {this.state.liga.tournamentstatus === "neu" && this.isAktuellerUserInLigaVorhanden() &&
                    <Button label="Aus Liga austreten, nicht teilnehmen" icon="pi pi-minus" onClick={() => this.teilnahmeBeenden()} style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditDialog: true})}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Aktionen" icon={PrimeIcons.BOLT}
                            onClick={() => this.setState({showAktionen: true})}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                {(this.isAktuellerUserAdministrator() && !this.isEventOperator() && !this.state.readonly) && <>
                    <Button label="Spielbare Spieltage verwalten" icon="pi pi-pencil"
                            onClick={() => this.setState({showDialogSpielbareSpieltage: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                    { this.state.showDialogSpielbareSpieltage && <LigaSpielbareSpieltageDialog ligaId={this.state.liga.id} spielbareSpieltage={this.state.spielbareSpieltage} anzahlSpieltage={this.getHoechsterSieltag()}
                                                                                               onClose={() => {
                                                                                                   this.setState({showDialogSpielbareSpieltage: false});
                                                                                                   this.ladeLigaDaten();
                                                                                               }}/> }
                </>
                }
                <RefreshCounterComp text="Auto-Refresh" onRefresh={() => this.ladeDaten()}/>
            </div>
        </div>
    }

    getHightlightsArea() {
        return <div className="grid">
            <Tabelle180er rangliste180er={this.state.rangliste180er} getSpielerName={this.getSpielerName}/>
            <Tabelle171er rangliste171er={this.state.rangliste171er} getSpielerName={this.getSpielerName}/>
            <TabelleHighfinishes ranglisteHighfinishes={this.state.ranglisteHighfinishes} getSpielerName={this.getSpielerName}/>
            <TabelleHighscores ranglisteHighscores={this.state.ranglisteHighscores} getSpielerName={this.getSpielerName}/>
            <TabelleBestDarts bestDarts={this.state.bestDarts}  getSpielerName={this.getSpielerName} />
            <TabelleWorstDarts worstDarts={this.state.worstDarts}  getSpielerName={this.getSpielerName} />
        </div>;
    }

    getSpielerName(id) {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < this.state.liga.tournamentPlayerEntities.length; i++) {
            let spieler = this.state.liga.tournamentPlayerEntities[i];
            // es kann die userid oder playerid oder eine doppelpartnerId drinnen sein.
            if (spieler.userId === id || spieler.id === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    getHoechsterSieltag(spieltag) {
        let hoechsterSpieltag = 0
        let gruppenEintraege = this.state.liga.tournamentGroupGameEntities;
        if( gruppenEintraege === null || gruppenEintraege === undefined) {
            return hoechsterSpieltag;
        }
        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde > hoechsterSpieltag) {
                hoechsterSpieltag = eintrag.spielrunde;
            }
        }
        return hoechsterSpieltag;
    }

    getGamesBySpieltag(spieltag) {
        let liga = this.state.liga
        let gruppenEintraege = liga.tournamentGroupGameEntities;
        let games = []
        if( gruppenEintraege === null || gruppenEintraege === undefined) {
            return games;
        }
        let currentUserPlayerId = this.ermittlePlayerIdVomAktuellenUser();

        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde === spieltag) {
                let hinzufuegen = true;
                let ergebnisVorhanden = eintrag.anzahlSpieler1 !== null && eintrag.anzahlSpieler1 !== undefined;
                let isAktullerUserImGame = eintrag.spieler1 === currentUserPlayerId || eintrag.spieler2 === currentUserPlayerId;
                if( this.state.nurSpieleOhneErgebnis && (ergebnisVorhanden || eintrag.spielOhneErgebnis) ) {
                    hinzufuegen = false;
                }
                if( this.state.nurMeineSpieleAnzeigen && !isAktullerUserImGame ) {
                    hinzufuegen = false;
                }

                if( hinzufuegen) {
                    games.push(eintrag);
                }
            }
        }
        return games;

    }

    isSpielerInaktiv(spielerid) {
        for (const player of this.state.liga.tournamentPlayerEntities) {
            if( player.id === spielerid && player.inaktiv) {
                return true;
            }
        }
        return false;
    }

    ermittlePlayerIdVomAktuellenUser() {
        let liga = this.state.liga;
        if( liga.tournamentPlayerEntities === null || liga.tournamentPlayerEntities === undefined) {
            return undefined;
        }

        if( this.state.liga.doppelturnier) {
            let doppelpartnerId = undefined;
            for (const doppelDto of this.state.liga.doppelpartnerDtos) {
                if( doppelDto.userId1 === this.props.userId || doppelDto.userId2 === this.props.userId) {
                    doppelpartnerId = doppelDto.id;
                }
            }
            if( doppelpartnerId !== undefined) {
                for (const player of this.state.liga.tournamentPlayerEntities) {
                    if( player.doppelpartnerId === doppelpartnerId) {
                        return player.id;
                    }
                }
            }
        } else {
            for (const player of this.state.liga.tournamentPlayerEntities) {
                if( player.userId === this.props.userId ) {
                    return player.id;
                }
            }
        }
        return undefined;
    }

    getSpielplan() {
        let hoechsterSpieltag = this.getHoechsterSieltag();
        let currentUserPlayerId = this.ermittlePlayerIdVomAktuellenUser();
        let cols = []
        for(let i = 1 ; i <= hoechsterSpieltag ;i++) {
            let games = this.getGamesBySpieltag(i);
            if( games.length > 0 ) {
                let showGames =  this.isSpieltagAktiv(i);
                cols.push(<LigaSpieltagComponent
                    readonly={this.state.readonly}
                    showGames={showGames}
                    selectedPlayerId={this.state.selectedPlayerId}
                    onPlayerSelect={(playerId) => this.setState({selectedPlayerId: playerId})}
                    boardGames={this.state.boardGames}
                    spieltag={i} games={games}
                    liga={this.state.liga}
                    refresh={this.ladeDaten}
                    userId={this.props.userId}
                    currentUserPlayerId={currentUserPlayerId}
                    getSpielerName={this.getSpielerName}
                    callbackHasPlayersMeetingkeyAllowed={this.hasPlayersMeetingkeyAllowed}
                    callbackIsSpielerInaktiv={this.isSpielerInaktiv} key={"ligadetail_spieltag_" + i}/>);
            }
        }
        return <div>
            {!this.isEventOperator() && <>
                <div className="grid" style={{marginTop: 10, marginBottom: 10}}>
                    <ToggleButton onLabel="Nur meine Spiele werden angezeigt" offLabel="Spiele von allen Teilnehmern werden angezeigt"
                                  checked={this.state.nurMeineSpieleAnzeigen}
                                  onChange={(e) => this.setState({nurMeineSpieleAnzeigen: !this.state.nurMeineSpieleAnzeigen})}
                                  style={{marginTop: 5}}/>
                    <ToggleButton onLabel="Nur Spiele ohne Ergebnis werden angezeigt" offLabel="Spiele mit und ohne Ergebnis werden angezeigt"
                                  checked={this.state.nurSpieleOhneErgebnis}
                                  onChange={(e) => this.setState({nurSpieleOhneErgebnis: !this.state.nurSpieleOhneErgebnis})}
                                  style={{marginTop: 5, marginLeft: 10}}/>


                </div>
            </>
            }
            <div className="grid">
                {cols}
            </div>
        </div>;
    }


    getRanglisteArea() {
        return <TabelleRangliste players={this.state.liga.ranglistePlayerEntities}/>
    }

    render() {
        let liga = this.state.liga

        if (liga === null || liga === undefined) {
            return <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <ProgressSpinner style={{width:100, height:100}}/>
            </div>
        }

        let isLigaRunning = this.state.liga.tournamentstatus !== "neu";
        let isLigaAbgeschlossen = this.state.liga.tournamentstatus === "abgeschlossen";
        let isUserInLigaRelevant = this.isAktuellerUserInLigaVorhanden() || this.isAktuellerUserAdministrator();

        let tabs = [];
        if ( isLigaAbgeschlossen && liga.rangliste) {
           tabs.push(<TabPanel header="Rangliste" key="tab_rangliste">
                    {this.getRanglisteArea()}
                </TabPanel>
           );
        }
        if( isLigaAbgeschlossen && liga.urkunden) {
            tabs.push(<TabPanel header="Urkunden" key="tab_urkunden">
                    <LigaUrkunden tournamentId={this.state.liga.id} rangliste={this.state.rangliste}
                                  rangliste171er={this.state.rangliste171er}
                                  rangliste180er={this.state.rangliste180er}
                                  ranglisteHighscores={ this.state.ranglisteHighscores}
                                  ranglisteHighfinishes={ this.state.ranglisteHighfinishes}
                                  ranglisteBestDarts={this.state.bestDarts}
                                  callbackGetSpielername={this.getSpielerName}/>
                </TabPanel>
            );
        }

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Liga - Details
                </div>
            </div>
            <div style={{paddingRight: 20, paddingLeft: 20}}>
                {this.getLigaDetailArea()}
            </div>

            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10}}>
                <TabPanel header="Spielplan" disabled={!isLigaRunning}>
                    {this.getSpielplan()}
                </TabPanel>
                <TabPanel header="Tabelle" disabled={!isLigaRunning}>
                    {this.getTabelle()}
                </TabPanel>
                <TabPanel header="Highlights" disabled={!isLigaRunning || liga.gameType !== "x01"}>
                    {this.getHightlightsArea()}
                </TabPanel>
                <TabPanel header="Teilnehmer">
                    {this.getTeilnehmerArea()}
                </TabPanel>
                <TabPanel header="Statistik" disabled={!isLigaRunning || liga.gameType !== "x01"}>
                    {this.getStatistikArea()}
                </TabPanel>
                {!this.isEventOperator() &&
                    <TabPanel header="Terminabsprache" disabled={!isLigaRunning || isLigaAbgeschlossen || !isUserInLigaRelevant}>
                        {<LigaKalenderArea liga={this.state.liga} userId={this.props.userId}/>}
                    </TabPanel>
                }
                <TabPanel header="Zuschauen" disabled={!isLigaRunning || isLigaAbgeschlossen || !isUserInLigaRelevant}>
                    {this.getZuschauenArea()}
                </TabPanel>
                {!this.isEventOperator() &&
                    <TabPanel header="Chat" disabled={!isLigaRunning || isLigaAbgeschlossen || !isUserInLigaRelevant}>
                        {this.getChat()}
                    </TabPanel>
                }
                {this.isEventOperator() &&
                    <TabPanel header="Links">
                        <TurnierQRCode eventOperatorId={this.state.liga.eventOperatorId} tournamentId={this.state.liga.id} tournamentSystem={this.state.liga.tournamentstatus} onlineTournament={this.state.liga.online} bezeichnung={this.state.liga.bezeichnung}/>
                    </TabPanel>
                }
                {tabs}
            </TabView>
            <ConfirmKennwortDialog visible={this.state.showKennwortDialog}
                                   header="Ligakennwort eingeben."
                                   textOben="Der Ligabetreiber erwartet ein Kennwort zur Teilnahme."
                                   changeKennwort={(kennwort) => this.setState({kennwort: kennwort})}
                                   callBackOnAbbrechen={() => this.setState({showKennwortDialog:false, abfrageTeilnahmebedingungenBeantwortet: false})}
                                   callBackOnAbsenden={() => this.state.liga.doppelturnier ? this.teilnahmeDoppelAendern() : this.teilnahmeAendern()}/>
            {this.state.showAbfrageTeilnahmebedingungenDialog &&
                <ConfirmAbfrageTeilnahmebedingungenDialog tournamentId={this.state.liga.id} visible={this.state.showAbfrageTeilnahmebedingungenDialog}
                                                         onConfirm={() => {
                                                             this.setState({abfrageTeilnahmebedingungenBeantwortet: true, showAbfrageTeilnahmebedingungenDialog: false}, () => {
                                                                 this.state.liga.doppelturnier ? this.teilnahmeDoppelAendern() : this.teilnahmeAendern();
                                                             });
                                                         }}
                                                         onClose={() => this.setState({showAbfrageTeilnahmebedingungenDialog: false})}/>
            }
            {this.state.showBezahlenDialog &&
                <TournamentPayPalDialog onClose={() => this.setState({showBezahlenDialog:false})}
                                        price={this.state.ligaGebuehrZuBezahlen}
                                        userId={this.props.userId}
                                        playerId={this.ermittlePlayerIdVomAktuellenUser()}
                                        tournamentId={this.state.liga.id}
                                        onConfirm={() => {
                                            this.showInfo("Bezahlung war erfolgreich.");
                                            this.setState({showBezahlenDialog:false});
                                            this.ladeDaten();
                                        }}
                                        onError={() => {
                                            this.setState({showBezahlenDialog:false});
                                            this.showError("Fehler beim Bezahlen, bitte Admin mit Zeitpunkt und Details verständigen!!!");
                                        }}/>}
            {this.state.showTeilnahmeBeendenTrotzBezahltMeldung &&
                <ConfirmWithQuestionDialog header={"Teilnahme beenden"}
                                           textOben={"Du bist bereits als 'bezahlt markiert'. Der evtl. bezahlte Betrag wird dir nicht zurück erstattet!!!"}
                                           textUnten={"Möchtest du die Teilnahme wirklich beenden?"}
                                           visible={true}
                                           callBackOnNein={() => this.setState({showTeilnahmeBeendenTrotzBezahltMeldung:false})}
                                           callBackOnJa={() => {
                                               this.teilnahmeBeenden(true);
                                           }}/>
            }
            {this.state.showEditDialog && <LigaVerwaltenDialog ligaId={this.state.liga.id}
                                                                  callBackRefresh={this.ladeDaten}
                                                                  callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                  callBackOnClose={() => this.setState({showEditDialog:false})}/>
            }
            {this.state.showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => this.showError(message)}
                                                               onInfo={(message) => this.showInfo(message)}
                                                               onClearMessages={() => this.messages.clear()}
                                                               callBackRefresh={() => this.ladeLigaDaten()}
                                                               callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                               proTournament={this.state.liga}
                                                               onClose={() => this.setState({showAktionen: false})}
                                                               callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                               callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
            />}

        </div>
    }


    isSetModus() {
        let tournamentModus = this.state.liga.tournamentModusEntities[0];
        return tournamentModus.sets > 1;
    }

    getTabelle() {
        if( this.state.tabelle === null || this.state.tabelle === undefined) {
            return "Noch keine Daten vorhanden...";
        }
        return <LigaTable rows={this.state.tabelle} callbackGetSpielername={this.getSpielerName} isSetModus={this.isSetModus()}/>
    }

    getAdministratoren() {
        if( this.state.liga === null || this.state.liga === undefined || this.state.liga.administratoren === null || this.state.liga.administratoren === undefined) {
            return "";
        }

        let administratoren = "";
        for (const administrator of this.state.liga.administratoren) {
            if( administratoren !== "") {
                administratoren += " - "
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    isEventOperator() {
        if( this.state.liga === null || this.state.liga === undefined) {
            return false;
        }
        return this.state.liga.eventOperatorId !== null && this.state.liga.eventOperatorId !== undefined;
    }

    hasPlayersMeetingkeyAllowed(playerId1, playerId2) {
        if( this.state.liga.doppelturnier || this.isEventOperator()) {
            return false;
        }
        if( playerId1 === undefined || playerId1 === null || playerId2 === undefined || playerId2 === null) {
            return false;
        }
        let player1 = this.state.liga.tournamentPlayerEntities.find(player => player.id === playerId1);
        let player2 = this.state.liga.tournamentPlayerEntities.find(player => player.id === playerId2);
        let usernameObject1 = this.getUsernamesByUserId(player1.userId);
        let usernameObject2 = this.getUsernamesByUserId(player2.userId);
        return usernameObject1.showMeetingkey && usernameObject2.showMeetingkey;
    }

    getStatistikArea() {
        return <div style={{backgroundColor: "#022836", color:"white", textAlign:"left", padding:5}}>
            <PublicTournamentStatistikTab tournamentId={this.state.liga.id} participants={this.state.liga.tournamentPlayerEntities}
                                          gesamtStatistik={this.state.statistik}
                                          playerStatistiks={this.state.playerStatistiks}/>
        </div>
    }

    getTeilnehmerArea() {
        return <LigaTeilnehmerArea liga={this.state.liga}
                                    players={this.state.liga.tournamentPlayerEntities}
                                   showEditButton={true}
                                   readonly={this.state.readonly} getUsernamesByUserId={this.getUsernamesByUserId}
                                   callBackRefresh={this.ladeDaten}
                                   isVerschiebenErlaubt={true}
                                   showUsernames={!this.state.liga.doppelturnier}
                                   isAdmin={this.isAktuellerUserAdministrator()}/>
    }

    getZuschauenArea() {
        return <LigaZuschauenArea tournamentId={this.state.liga.id}/>
    }

    getBeschreibungArea(fixedColumWidth) {
        if( this.state.liga.beschreibung === null || this.state.liga.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if( this.state.zeigeBeschreibungVoll || this.state.liga.beschreibung.length <= 50 ) {
            beschreibung = this.state.liga.beschreibung;
        } else {
            beschreibung = this.state.liga.beschreibung.substring(0,50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor:"pointer"}} onClick={() => this.setState({zeigeBeschreibungVoll : !this.state.zeigeBeschreibungVoll})}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }

    getChat() {
        return <Chat type="turnier" loadData={true} id={this.state.liga.id} userId={this.props.userId}
                      callBackOnError={(message) => this.showError(message)}/>
    }

    getLigaBetreiber() {
        for (const leagueoperator of this.props.leagueoperators) {
            if( leagueoperator.id === this.state.liga.leagueOperatorId) {
                return leagueoperator;
            }
        }
        return undefined;
    }

}

LigaDetail.propTypes = {
    userId: PropTypes.string.isRequired,
    leagueoperators: PropTypes.array.isRequired,
};

export default LigaDetail
