import {connect} from 'react-redux'
import AGB from "../components/AGB";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AGB)