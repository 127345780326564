export const NEW_LIGA_INFOS_OVER_WEBSOCKET = 'NEW_LIGA_INFOS_OVER_WEBSOCKET';
export const NEW_TURNIER_INFOS_OVER_WEBSOCKET = 'NEW_TURNIER_INFOS_OVER_WEBSOCKET';

export const newLigaInfosOverWebsocket = (ligaInfos) => ({
    type: NEW_LIGA_INFOS_OVER_WEBSOCKET,
    value: ligaInfos,
});

export const newTurnierInfosOverWebsocket = (turnierInfos) => ({
    type: NEW_TURNIER_INFOS_OVER_WEBSOCKET,
    value: turnierInfos,
});

export function ligaInfosOverWebsocket(ligaInfos) {
    return function (dispatch) {
        dispatch(newLigaInfosOverWebsocket(ligaInfos));
    };
}
export function turnierInfosOverWebsocket(turnierInfos) {
    return function (dispatch) {
        dispatch(newTurnierInfosOverWebsocket(turnierInfos));
    };
}