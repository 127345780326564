import React from 'react'
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";

import {USER_ROLES} from "../../../constants/userRoles";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import PropTypes from "prop-types";
import {MANDANTTYPES} from "../../../constants/mandantTypes";
import {Checkbox} from "primereact/checkbox";
import DateUtil from "../../../util/DateUtil";
import {InputText} from "primereact/inputtext";
import TournamentSponsorDialog from "./TournamentSponsorDialog";
import TeilnahmebedingungenDialog from "../liga/TeilnahmebedingungenDialog";
import LigaVerwaltenDialog from "./LigaVerwaltenDialog";
import TurnierAdminsDialog from "./TurnierAdminsDialog";
import {Paginator} from "primereact/paginator";
import WeekendLeagueVerwaltenDialog from "./WeekendLeagueVerwaltenDialog";
import TurnierVerwaltenDialog from "./TurnierVerwaltenDialog";
import TournamentTable from "./TournamentTable";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import TournamentCopySidebar from "../public/tournaments/TournamentCopySidebar";
import LigaspieltagVerwaltenDialog from "./LigaspieltagVerwaltenDialog";
import DHDatePicker from "../../general/DHDatePicker";

class TournamentOverview extends React.Component {

    constructor(props) {
        super(props);

        var date = new Date();
        date.setDate(date.getDate() - 92);
        this.state = {
            first:0,
            rows: 50,
            showLigaDialog: false,
            showKODKODialog: false,
            showWeekendleagueDialog: false,
            showTurnierDialog: false,
            showTeilnahmebedingungenDialog: false,
            showLigaspieltagDialog: false,
            turniersystem: undefined,
            tournaments: [],
            showTurnierKopierenFrage: false,
            auslosungLaeuft: false,
            zeigeAbgeschlossene: false,
            vondatum: date,
            bisdatum: undefined,
            filter: "",
        };

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.callBackTurnierKopieren = this.callBackTurnierKopieren.bind(this);
        this.callBackTurnierBearbeiten = this.callBackTurnierBearbeiten.bind(this);
        this.callBackRefresh = this.callBackRefresh.bind(this);
    }

    callBackRefresh() {
        this.loadData();
    }

    callBackTurnierBearbeiten(tournament) {
        if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
            this.setState({showLigaDialog: true, selectedTournament: tournament});
        } else if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
            this.setState({showLigaspieltagDialog: true, selectedTournament: tournament});
        } else if( tournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id) {
            this.setState({showWeekendleagueDialog: true, selectedTournament: tournament});
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
            this.setState({showKODKODialog: true, selectedTournament: tournament});
        } else {
            this.setState({showTurnierDialog: true, selectedTournament: tournament});
        }
    }

    callBackTurnierKopieren(tournament) {
        this.setState({showTurnierKopierenFrage: true, selectedTournament: tournament});
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'warn',
            sticky: 'true',
            summary: 'Achtung!!!',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let data = {vondatum: this.state.vondatum, bisdatum: this.state.bisdatum, showAbgeschlossene: this.state.zeigeAbgeschlossene};

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/load",
            data,
            json => {
                this.setState({tournaments: json});
                let selectedGefunden = false;
                if( this.state.selectedTournament !== undefined) {
                    for(let i = 0; i < this.state.tournaments.length; i++) {
                        let tournament = this.state.tournaments[i];
                        // neu selektieren, da Änderungen vorhanden sein können.
                        if( tournament.id === this.state.selectedTournament.id) {
                            this.setState({selectedTournament: tournament});
                            selectedGefunden = true;
                            break;
                        }
                    }
                }
                if(selectedGefunden === false) {
                    // wurde evtl. gelöscht, daher selected auf undefined
                    this.setState({selectedTournament: undefined});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Turniere: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Turniere: " + error.message);
            })
    }

    neuesTurnierAnlegen() {
        this.setState({
            showTurnierDialog: true,
            turniersystem: undefined,
            selectedTournament: undefined
        });
    }

    render() {
        let isAdmin = false;
        if( this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }
        let isLigaLogin = this.props.mandantType === MANDANTTYPES.DEFAULT.bezeichnung;

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding:0, margin:0, backgroundColor:"#b3ccff"}}>
                <div className="col" style={{marginTop:10, padding:0}}>
                    Turniere / Ligen
                </div>
            </div>
            <div className="grid" style={{padding:10, marginLeft:0, marginRight:0}}>
                <div className="col" style={{textAlign:"left"}}>
                    <Button label="Neue Liga anlegen" icon="pi pi-pencil" tooltip="Neue Liga anlegen" onClick={() => this.setState({showLigaDialog: true})} style={{marginRight:5}}/>
                    <Button label="Neue Weekendleague anlegen" icon="pi pi-pencil" tooltip="Neue Weekendleague anlegen" onClick={() => this.setState({showWeekendleagueDialog: true})} style={{marginRight:5}}/>
                    <Button label="Neues Gruppe / DKO / KO Turnier anlegen" icon="pi pi-pencil" tooltip="Neues Turnier anlegen" onClick={() => this.setState({showKODKODialog: true})} style={{marginRight:5}}/>
                </div>
            </div>
            <div className="grid" style={{padding:10, paddingTop:0, marginLeft:0, marginRight:0}}>
                <div className="col" style={{textAlign:"left"}}>
                    <Button label="Teilnahmebedingungen verwalten" tooltip="Teilnahmebedingungen verwalten" onClick={() => this.setState({showTeilnahmebedingungenDialog: true})} style={{marginRight:5}}/>
                    <Button label="Turnieradmins verwalten" tooltip="Turnieradmins" onClick={() => this.setState({showTurnieradminsDialog: true})} style={{marginRight:5}}/>
                    <Button label="Sponsoren verwalten" tooltip="Sponsoren" onClick={() => this.setState({showSponsorenDialog: true})} style={{marginRight:5}}/>
                </div>
            </div>
            <div className="grid" style={{paddingLeft:20}}>
                <div className="col-fixed" style={{width: '300px', textAlign:"left"}}>
                    <span style={{marginRight:"5px"}}>Von Datum:</span>
                    <DHDatePicker disabled={false} value={this.state.vondatum} changeValue={(value) => this.setState({vondatum:value})}/>
                </div>
                <div className="col-fixed" style={{width: '300px'}}><span style={{marginRight:"5px"}}>Bis Datum:</span>
                    <DHDatePicker disabled={false} value={this.state.bisdatum} changeValue={(value) => this.setState({bisdatum:value})}/>
                </div>
            </div>
            <div className="grid" style={{paddingLeft:20, marginTop:5}}>
                <div className="col" style={{textAlign: "left"}}>
                    <Checkbox inputId="chb-abgeschlossen" checked={this.state.zeigeAbgeschlossene}
                              onChange={() => this.setState({zeigeAbgeschlossene: !this.state.zeigeAbgeschlossene})} style={{marginRight:5}}/>
                    <label htmlFor="chb-abgeschlossen" className="p-checkbox-label">zeige abgeschlossene</label>
                </div>
            </div>
            <div className="grid" style={{paddingLeft:20, marginTop:5}}>
                <div className="col" style={{textAlign: "left"}}>
                    <Button label="Aktualisieren" onClick={() => this.loadData()}/>
                </div>
            </div>
            <div className="grid" style={{paddingLeft:20, marginTop:5}}>
                <div className="col" style={{textAlign: "left"}}>
                    Filter Bezeichnung:
                    <InputText value={this.state.filter} style={{width: 100, marginLeft:5}}
                               onChange={(e) => this.setState({filter: e.target.value})}/>
                </div>
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <Paginator first={this.state.first} rows={this.state.rows} totalRecords={this.getTournamentsLength()} rowsPerPageOptions={[5, 10, 20, 30, 50, 100, 200]} onPageChange={(e) => {
                    this.setState({first: e.first, rows: e.rows});
                }} style={{width:"100%"}}/>
                <TournamentTable tournaments={this.getTournaments()}
                                 showOnline={true} showSteeldarts={true} showEDart={true}
                                 showButtons={true} onTournamentCopy={this.callBackTurnierKopieren} onTournamentEdit={this.callBackTurnierBearbeiten}/>
            </div>
            {this.state.showLigaspieltagDialog && <LigaspieltagVerwaltenDialog tournamentId={this.state.selectedTournament !== undefined ? this.state.selectedTournament.id : null}
                                                                   callBackRefresh={this.callBackRefresh}
                                                                   callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                   callBackOnClose={() => this.setState({showLigaspieltagDialog:false, selectedTournament: undefined})}/>
            }
            {this.state.showKODKODialog && <TurnierVerwaltenDialog tournamentId={this.state.selectedTournament !== undefined ? this.state.selectedTournament.id : null}
                                                                   callBackRefresh={this.callBackRefresh}
                                                                   callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                   callBackOnClose={() => this.setState({showKODKODialog:false, selectedTournament: undefined})}/>
            }
            {this.state.showLigaDialog && <LigaVerwaltenDialog ligaId={this.state.selectedTournament !== undefined ? this.state.selectedTournament.id : null}
                                                               callBackRefresh={this.callBackRefresh}
                                                               callBackOnClose={() => this.setState({showLigaDialog:false, selectedTournament: undefined})}/>
            }
            {this.state.showWeekendleagueDialog && <WeekendLeagueVerwaltenDialog tournamentId={this.state.selectedTournament !== undefined ? this.state.selectedTournament.id : null}
                                                               callBackRefresh={this.callBackRefresh}
                                                               callBackOnClose={() => this.setState({showWeekendleagueDialog:false, selectedTournament: undefined})}/>
            }
            {this.state.showTeilnahmebedingungenDialog &&  <TeilnahmebedingungenDialog visible={this.state.showTeilnahmebedingungenDialog} onClose={() => this.setState({showTeilnahmebedingungenDialog:false})}/>}
            {this.state.showTurnieradminsDialog && <TurnierAdminsDialog callBackOnClose={() => this.setState({showTurnieradminsDialog: false})}/>}
            {this.state.showSponsorenDialog && <TournamentSponsorDialog visible={true}
                                       callBackOnClose={() => this.setState({showSponsorenDialog:false})}
                                       tournament={this.state.selectedTournament} />}
            {this.state.showTurnierKopierenFrage &&
                <TournamentCopySidebar
                    tournamentId={this.state.selectedTournament.id}
                    propBezeichnung={this.state.selectedTournament.bezeichnung}
                    propBezeichnungKurz={this.state.selectedTournament.bezeichnungShort}
                    turniersystem={this.state.selectedTournament.turniersystem}
                    propMannschaftHeim={this.state.selectedTournament.mannschaftHeim}
                    onCopy={() => this.loadData()}
                    onClose={() => {
                        this.setState({selectedTournament: undefined, showTurnierKopierenFrage: false})
                    }}
                />
            }
        </div>
    }
    getTournamentsLength() {
        return this.getTournamentsFiltered().length;
    }

    getTournamentsFiltered() {
        let rows = [];
        if( this.state.filter === "") {
            rows = this.state.tournaments;
        } else {
            for (const tournament of this.state.tournaments) {
                if( tournament.bezeichnung.includes(this.state.filter)) {
                    rows.push(tournament);
                }
            }
        }
        return rows;
    }

    getTournaments() {
        let rows = this.getTournamentsFiltered();
        let returnRows = [];
        for(let i = this.state.first; i < (this.state.first + this.state.rows) ; i++) {
            let turnier = rows[i];
            if( turnier !== undefined) {
                returnRows.push(turnier);
            }
        }
        return returnRows;
    }
}

TournamentOverview.propTypes = {
    mandantType: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired,
};

export default TournamentOverview
