import React from 'react'
import PropTypes from 'prop-types';
import {addLocale, locale} from 'primereact/api';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import ImpressumContainer from "../../containers/ImpressumContainer";
import SimpleModalContainer from "../../containers/modals/SimpleModalContainer";
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import "primeflex/primeflex.min.css"
import {Route, Switch} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../constants/navigationItems";
import StartContainer from "../../containers/views/start/StartContainer";
import Datenschutz from "../Datenschutz";
import TrainingGameEditContainer from "../../containers/views/training/TrainingGameEditContainer";
import KachelOverviewContainer from "../../containers/views/start/KachelOverviewContainer";

import HeaderBig from "../general/HeaderBig";
import HeaderSmall from "../general/HeaderSmall";
import PlayerOverviewContainer from "../../containers/views/player/PlayerOverviewContainer";
import UnderConstruction from "../UnderConstruction";
import TrainingOverviewContainer from "../../containers/views/training/TrainingOverviewContainer";
import StatisticsContainer from "../../containers/views/statistics/StatisticsContainer";
import SettingsContainer from "../../containers/views/settings/SettingsContainer";
import TrainingPublicScore from "../views/public/training/TrainingPublicScore";
import PublicScoringViewContainer from "../../containers/views/public/PublicScoringViewContainer";
import TournamentOverviewContainer from "../../containers/views/tournament/TournamentOverviewContainer";
import TournamentbaumContainer from "../../containers/views/tournament/TournamentbaumContainer";
import TurniergruppenContainer from "../../containers/views/tournament/TurniergruppenContainer";
import ScoringViewContainer from "../../containers/views/scoring/ScoringViewContainer";

import SpieltagOverviewContainer from "../../containers/views/spieltag/SpieltagOverviewContainer";
import SpieltagDetailContainer from "../../containers/views/spieltag/SpieltagDetailContainer";
import TwoPlayerScoringViewContainer from "../../containers/score/TwoPlayerScoringViewContainer";
import DashboardContainer from "../../containers/views/start/DashboardContainer";
import LigaDetailContainer from "../../containers/views/liga/LigaDetailContainer";
import RegistrationCompleteContainer from "../../containers/RegistrationCompleteContainer";
import ProfilContainer from "../../containers/views/liga/ProfilContainer";
import GameDetailContainer from "../../containers/views/liga/GameDetailContainer";
import KalenderContainer from "../../containers/views/liga/KalenderContainer";
import BezahlenContainer from "../../containers/views/liga/BezahlenContainer";
import GameVideoContainer from "../../containers/views/training/GameVideoContainer";
import AdministrationContainer from "../../containers/views/liga/AdministrationContainer";
import TurnierDetailContainer from "../../containers/views/liga/TurnierDetailContainer";
import GameStatistikContainer from "../../containers/views/liga/GameStatistikContainer";
import AGBContainer from "../../containers/AGBContainer";

import ProfilFriendContainer from "../../containers/views/liga/ProfilFriendContainer";
import TurnierGruppenDetailContainer from "../../containers/views/liga/TurnierGruppenDetailContainer";
import HeadToHeadChatContainer from "../../containers/views/liga/HeadToHeadChatContainer";
import AutomatViewContainer from "../../containers/views/liga/AutomatViewContainer";
import DoppelStatisticsAreaContainer from "../../containers/views/liga/DoppelStatisticsAreaContainer";
import BenutzersucheViewContainer from "../../containers/views/liga/BenutzersucheViewContainer";
import RanglisteViewContainer from "../../containers/views/liga/RanglisteViewContainer";
import RanglisteEditViewContainer from "../../containers/views/liga/RanglisteEditViewContainer";
import WeekendLeagueDetailContainer from "../../containers/views/liga/WeekendLeagueDetailContainer";
import GameWatchContainer from "../../containers/views/training/GameWatchContainer";
import GamingAreaContainer from "../../containers/views/liga/GamingAreaContainer";
import ScoringShortViewContainer from "../../containers/views/scoring/ScoringShortViewContainer";
import LiveViewContainer from "../../containers/views/public/liveview/LiveViewContainer";
import PublicTournamentsContainer from "../../containers/views/public/tournaments/PublicTournamentsContainer";
import PublicTournamentContainer from "../../containers/views/public/tournaments/PublicTournamentContainer";
import ParticipantConfirmationContainer from "../../containers/views/public/ParticipantConfirmationContainer";
import TurnierKratzerDetailContainer from "../../containers/views/liga/TurnierKratzerDetailContainer";
import PublicEventoperatorContainer from "../../containers/views/public/eventoperator/PublicEventoperatorContainer";
import ScorerRedirectContainer from "../../containers/scorer/ScorerRedirectContainer";
import ExterneLigaSpieltagContainer from "../../containers/views/externeliga/ExterneLigaSpieltagContainer";
import NuCallbackContainer from "../../containers/extern/NuCallbackContainer";
import LigaspieltagDetailContainer from "../../containers/views/liga/LigaspieltagDetailContainer";
import 'dayjs/locale/de';
import VeranstalterLiveViewContainer from "../../containers/views/veranstalter/VeranstalterLiveViewContainer";
import ParticipantDeregistrationContainer from "../../containers/views/public/ParticipantDeregistrationContainer";
import AnyGamesContainer from "../../containers/views/liga/AnyGamesContainer";
import UserStatistikContainer from "../../containers/views/liga/UserStatistikContainer";
import PublicLigaspielttagQRCodeCheckContainer from "../../containers/views/public/ligaspieltag/PublicLigaspielttagQRCodeCheckContainer";

class App extends React.Component {

    componentDidMount() {
        registerLocale("de", de);
        setDefaultLocale("de");

        addLocale('de', {
            firstDayOfWeek: 1,
            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
            monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
            dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
            dayNamesMin: ['S', 'M', 'D', 'M ', 'D', 'F ', 'S'],
            today: 'Heute',
            clear: 'Löschen'
        });
        locale('de');

        this.props.loginByToken(false);
    }

    render() {
        if (NAVIGATION_ITEM.PUBLIC_SCORER_UPDATE.route === this.props.location.pathname) {
            return <ScorerRedirectContainer/>
        } else if (NAVIGATION_ITEM.PARTICIPANT_CONFIRMATION.route === this.props.location.pathname) {
            return <ParticipantConfirmationContainer/>
        } else if (NAVIGATION_ITEM.PARTICIPANT_DEREGISTRATION.route === this.props.location.pathname) {
            return <ParticipantDeregistrationContainer/>
        }else if (NAVIGATION_ITEM.COMPLETEREGISTRATION.route === this.props.location.pathname) {
            return <RegistrationCompleteContainer/>;
        } else if (NAVIGATION_ITEM.GAME_VIDEO.route === this.props.location.pathname) {
            return <GameVideoContainer/>
        } else if (NAVIGATION_ITEM.GAMEWATCH.route === this.props.location.pathname) {
            return <GameWatchContainer/>
        } else if (NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route === this.props.location.pathname) {
            return <PublicTournamentsContainer/>
        } else if (this.props.location.pathname.startsWith(NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route)) {
            return <LiveViewContainer/>
        } else if (this.props.location.pathname.startsWith(NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route)) {
            return <PublicTournamentContainer/>
        } else if (this.props.location.pathname.startsWith(NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR_LIVE_VIEW.route)) {
            return <LiveViewContainer/>
        } else if (this.props.location.pathname.startsWith(NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route)) {
            return <PublicEventoperatorContainer/>
        } else if (this.props.location.pathname.startsWith(NAVIGATION_ITEM.PUBLI_LIGASPIELTAGGASTEINGABE.route)) {
            return <PublicLigaspielttagQRCodeCheckContainer/>
        }

        return <div style={{height: "100%"}}>
            {!this.props.isLoggedIn && <div style={{padding: 0}}>
                {this.props.location.pathname !== NAVIGATION_ITEM.TRAININGSESSION_PUBLIC_RESULT_ENTER.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.PARTICIPANT_CONFIRMATION.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.PARTICIPANT_DEREGISTRATION.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.PUBLIC_TRAINING_GAME_SCORE.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.PUBLIC_SCORING_VIEW.route ?
                    <HeaderBig/> : <div/>
                }
                <Switch>
                    <Route path={NAVIGATION_ITEM.AGB.route}><AGBContainer /></Route>
                    <Route path={NAVIGATION_ITEM.IMPRESSUM.route}><ImpressumContainer /></Route>
                    <Route path={NAVIGATION_ITEM.DATENSCHUTZ.route}><Datenschutz /></Route>
                    <Route path={NAVIGATION_ITEM.TRAININGSESSION_PUBLIC_RESULT_ENTER.route}><TrainingPublicScore /></Route>
                    <Route path={NAVIGATION_ITEM.PUBLIC_SCORING_VIEW.route}><PublicScoringViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.PUBLIC_TRAINING_GAME_SCORE.route}><ScoringViewContainer /></Route>
                    {/*no-match-route*/}
                    <Route><StartContainer /></Route>
                </Switch>
            </div>
            }
            {this.props.isLoggedIn && <div style={{height: "100%", backgroundColor: "white"}}>
                {this.props.location.pathname !== NAVIGATION_ITEM.TURNIERBAUM.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.SCORING.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.SCORING_SHORT.route &&
                this.props.location.pathname !== NAVIGATION_ITEM.TWO_PLAYER_GAME_SCORE.route ?
                    <HeaderSmall/> : <div/>
                }

                <Switch>
                    <Route path={NAVIGATION_ITEM.ANYGAMESDETAIL.route} component={AnyGamesContainer}/>
                    <Route path={NAVIGATION_ITEM.LIGASPIELTAGDETAIL.route} component={LigaspieltagDetailContainer}/>
                    <Route path={NAVIGATION_ITEM.DASHBOARD.route}><DashboardContainer /></Route>
                    <Route path={NAVIGATION_ITEM.HEADTOHEADCHAT.route}><HeadToHeadChatContainer /></Route>
                    <Route path={NAVIGATION_ITEM.LIGADETAIL.route}><LigaDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.EXTERNERLIGASPIELTAG.route}><ExterneLigaSpieltagContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TURNIERDETAIL.route}><TurnierDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route}><TurnierGruppenDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.USERSTATSTIK.route}><UserStatistikContainer /></Route>
                    <Route path={NAVIGATION_ITEM.PROFIL.route}><ProfilContainer /></Route>
                    <Route path={NAVIGATION_ITEM.PROFILFRIEND.route}><ProfilFriendContainer /></Route>
                    <Route path={NAVIGATION_ITEM.BEZAHLEN.route}><BezahlenContainer /></Route>
                    <Route path={NAVIGATION_ITEM.KALENDER.route}><KalenderContainer /></Route>
                    <Route path={NAVIGATION_ITEM.GAMEDAY_OVERVIEW.route}><SpieltagOverviewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.GAMEDAY_DETAIL.route}><SpieltagDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.GAMINGAREA.route}><GamingAreaContainer /></Route>
                    <Route path={NAVIGATION_ITEM.GAMESTATISTIK.route}><GameStatistikContainer /></Route>
                    <Route path={NAVIGATION_ITEM.PLAYER_OVERVIEW.route}><PlayerOverviewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.KACHELOVERVIEW.route}><KachelOverviewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.AUTOMAT.route}><AutomatViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.BENUTZERSUCHE.route}><BenutzersucheViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.RANGLISTE.route}><RanglisteViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.RANGLISTEEDIT.route}><RanglisteEditViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.AGB.route}><AGBContainer /></Route>
                    <Route path={NAVIGATION_ITEM.IMPRESSUM.route}><ImpressumContainer /></Route>
                    <Route path={NAVIGATION_ITEM.DATENSCHUTZ.route}><Datenschutz /></Route>
                    <Route path={NAVIGATION_ITEM.TRAINING_GAME_EDIT.route}><TrainingGameEditContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TRAINING_OVERVIEW.route}><TrainingOverviewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.SETTINGS.route}><SettingsContainer /></Route>
                    <Route path={NAVIGATION_ITEM.STATISTICS.route}><StatisticsContainer /></Route>
                    <Route path={NAVIGATION_ITEM.DOPPELSTATISTICSAREA.route}><DoppelStatisticsAreaContainer /></Route>
                    <Route path={NAVIGATION_ITEM.ADMINISTRATION.route}><AdministrationContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TRAININGSESSION_PUBLIC_RESULT_ENTER.route}><TrainingPublicScore /></Route>
                    <Route path={NAVIGATION_ITEM.SCORING.route}><ScoringViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.SCORING_SHORT.route}><ScoringShortViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TWO_PLAYER_GAME_SCORE.route}><TwoPlayerScoringViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TURNIER.route}><TournamentOverviewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TURNIERBAUM.route}><TournamentbaumContainer /></Route>
                    <Route path={NAVIGATION_ITEM.TURNIERGRUPPE.route}><TurniergruppenContainer /></Route>
                    <Route path={NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route}><WeekendLeagueDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.KRATZERDETAIL.route}><TurnierKratzerDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.GAME_OVERVIEW.route}><GameDetailContainer /></Route>
                    <Route path={NAVIGATION_ITEM.VERANSTALTER_LIVE_VIEW.route}><VeranstalterLiveViewContainer /></Route>
                    <Route path={NAVIGATION_ITEM.EXTERN_NUCALLBACK.route}><NuCallbackContainer /></Route>
                    <Route path={NAVIGATION_ITEM.UNDERCONSTRUCTION.route}><UnderConstruction /></Route>
                    {/*no-match-route*/}
                    <Route><DashboardContainer /></Route>
                </Switch>
            </div>
            }
            <SimpleModalContainer/>
        </div>
    }
}

App.propTypes = {
    location: PropTypes.any,
    loginByToken: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    serverVersion: PropTypes.string,
    userName: PropTypes.string,
    userId: PropTypes.string,
};

export default App;
