import {connect} from 'react-redux'
import Profil from "../../../components/views/liga/Profil";
import {logout, saveAutodartsBoardId} from "../../../actions/user";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout()),
    saveAutodartsBoardId: (boardId) => dispatch(saveAutodartsBoardId(boardId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profil)