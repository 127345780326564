import {connect} from 'react-redux'
import NuCallbackView from "../../components/extern/NuCallbackView";

const mapStateToProps = function (state, ownProps) {
    return {};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NuCallbackView)
