import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Button} from "primereact/button";
import ConfirmDialog from "../../general/ConfirmDialog";

class AdministrationZuschauenGames extends React.Component {

    constructor(props) {
        super(props);
        this.state = {games : [],showDeleteDialog:false, selectedId:undefined};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    delete() {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.admin + "/gamezuschauen/" + this.state.selectedId,
            {},
    json=> {
                this.setState({games : [...json], showDeleteDialog:false, selectedId:undefined});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.admin + "/allegamezuschauen",
            json => {
                this.setState({games : [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    getRows() {
        let rows = [];
        for (const game of this.state.games) {
            rows.push(<div key={"key_game_zuschauen_" + game.id}>
                <Button icon="pi pi-trash" style={{marginTop:25}}
                        onClick={() => this.setState({showDeleteDialog: true, selectedId: game.id})}/>
                <br/>
                MongoId: {game.id}<br/>
                GameId: {game.gameId}<br/>
                Spieler1Name: {game.spieler1Name} Spieler1Username: {game.spieler1Username} | Spieler1Id: {game.spieler1Id}<br/>
                Spieler2Name: {game.spieler2Name} Spieler2Username: {game.spieler2Username} | Spieler2Id: {game.spieler2Id}<br/>
                TurnierId: {game.tournamentId}
            </div>)
            rows.push(<hr key={"key_game_zuschauen_line_" + game.id}/>);
        }
        return rows;
    }

    render() {
        return <div style={{textAlign:"left"}}>
            {this.getRows()}
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteDialog: false,
                               selectedId: undefined
                           })}
                           callBackOnJa={() => this.delete()}
                           header="Wirklich löschen..."
                           textOben=""
                           textUnten="Möchtest du wirklich löschen?"/>
        </div>
    }
}

export default AdministrationZuschauenGames
