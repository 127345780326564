import React, {useEffect} from 'react'
import {PrimeIcons} from "primereact/api";

export default function LiveViewGame({game, boardName, playerIdsChecked, favorits, onDetail, onFavorit}) {
    const detail = (header, wert) => {
        return <div className="col" style={{textAlign: "center", margin: 0, padding: 0}}>
            <div>{header}</div>
            <div style={{marginTop: 5}}>{wert}</div>
        </div>
    }

    const isFavorit = () => {
        if( favorits === undefined) {
            return false;
        }
        return favorits.filter(fav => fav.boardId === game.boardId).length > 0;
    }

    const getPlayerSteeldartArea = (playerId, name, togo, lastThrow, orientation, abgeschlossen, hatMehrSetsOderLegs, isLegBeginer, isActive, canCheck) => {
        if( game.dartType !== null && game.dartType !== undefined && game.dartType === "EDART") {
            return "";
        }

        let checked = playerIdsChecked.includes(playerId);

        return <>
            <div style={{
                fontSize: 20,
                padding: 10,
                backgroundColor: "#ac1c27",
                height: 40,
                maxHeight: 40,
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}>
                {name}
            </div>
            {(abgeschlossen === undefined || !abgeschlossen) ?
                <div style={{marginTop: 0}}>
                    <div className="grid" style={{
                        display: "flex",
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        justifyContent: "left",
                        alignItems: "center",
                        borderBottom: isActive ? "5px solid #ac1c27" : ""
                    }}>
                        {orientation === "left" &&
                            <div className="col-fixed" style={{
                                width: 50,
                                height: 50,
                                fontSize: 20,
                                borderLeft: orientation === "left" && isLegBeginer ? "5px solid #ac1c27" : ""
                            }}>
                                {lastThrow}
                            </div>
                        }
                        <div className="col" style={{fontSize: 50, textAlign: "center", color: canCheck ? "green" : ""}}>
                            {checked ? <span style={{color: "green", fontSize: 30, fontWeight: "bold"}}>Check</span> : togo}
                        </div>
                        {orientation === "right" &&
                            <div className="col-fixed" style={{
                                width: 50,
                                height: 50,
                                fontSize: 20,
                                borderRight: orientation === "right" && isLegBeginer ? "5px solid #ac1c27" : ""
                            }}>
                                {lastThrow}
                            </div>
                        }
                    </div>
                </div>
                :
                <div style={{marginTop: 10, color: "green", fontSize: 30, fontWeight: "bold"}}>
                    {hatMehrSetsOderLegs && "Sieger"}
                </div>
                }
        </>
    }

    const getPlayerEdartArea = (name, legs, abgeschlossen, hatMehrSetsOderLegs, isUnentschieden) => {
        if( game.dartType !== null && game.dartType !== undefined && game.dartType === "STEELDARTS") {
            return "";
        }

        let tmpName = name;

        return <>
            <div style={{
                fontSize: 20,
                padding: 10,
                backgroundColor: "#ac1c27",
                height: 40,
                maxHeight: 40,
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}>
                {tmpName}
            </div>
            {(abgeschlossen === undefined || !abgeschlossen) &&
                <div style={{marginTop: 0}}>
                    <div className="grid" style={{
                        display: "flex",
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        justifyContent: "left",
                        alignItems: "center",
                    }}>
                        <div className="col" style={{fontSize: 30, textAlign: "center"}}>
                            Legs<br/>{legs}
                        </div>
                    </div>
                </div>
            }
            {(abgeschlossen !== undefined && abgeschlossen && hatMehrSetsOderLegs) &&
                <div style={{marginTop: 10, color: "green", fontSize: hatMehrSetsOderLegs ? 30 : 20, fontWeight: "bold"}}>
                    Sieger
                </div>
            }
            {(abgeschlossen !== undefined && abgeschlossen && isUnentschieden) &&
                <div style={{marginTop: 10, color: "green", fontSize: hatMehrSetsOderLegs ? 30 : 20, fontWeight: "bold"}}>
                    Unentschieden
                </div>
            }
        </>
    }

    const showDetail = () => {
        onDetail(game.boardId);
    }


    const getScoringDetailArea = () => {
        if( game.startssoon !== undefined && game.startssoon ) {
            return "";
        }
        if( game.inGame !== undefined && game.inGame ) {
            return "";
        }
        if( game.dartType === null || game.dartType === undefined || game.dartType === "STEELDARTS") {
            return <div className="grid" style={{height: 50, fontSize: 18, color: "white", margin: 0, padding: 0}} onClick={() => showDetail()}>
                {detail("Avg.", game.spieler1Average)}
                {game.sets === 1 && detail("Best", game.spieler1Best !== null && game.spieler1Best !== undefined ? game.spieler1Best : "-")}
                {game.sets > 1 && detail("Sets", game.spieler1Sets !== null && game.spieler1Sets !== undefined ? game.spieler1Sets : "-")}
                {detail("Legs", game.spieler1Legs)}
                {detail("Legs", game.spieler2Legs)}
                {game.sets === 1 && detail("Best", game.spieler2Best !== null && game.spieler2Best !== undefined ? game.spieler2Best : "-")}
                {game.sets > 1 && detail("Sets", game.spieler2Sets !== null && game.spieler2Sets !== undefined ? game.spieler2Sets : "-")}
                {detail("Avg.", game.spieler2Average)}
            </div>
        }
        return "";
    }

    const getStartingArea = () => {
        if( game.inGame !== undefined && game.inGame ) {
            return "";
        }
        if( game.startssoon !== undefined && game.startssoon ) {
           return <div style={{
               height: 50,
               fontSize: 30,
               color: "green",
               margin: 0,
               padding: 0,
               verticalAlign: "middle",
               textAlign: "center",
               cursor: game.boardId !== null && game.boardId !== undefined ? "pointer" : "auto"
           }} onClick={() => showDetail()}>
               ... Startet ...
           </div>
        } else {
            return "";
        }
    }

    const getInGameArea = () => {
        if( game.inGame !== undefined && game.inGame ) {
            return <div style={{
                height: 50,
                fontSize: 30,
                color: "green",
                margin: 0,
                padding: 0,
                verticalAlign: "middle",
                textAlign: "center",
                cursor: game.boardId !== null && game.boardId !== undefined ? "pointer" : "auto"
            }} onClick={() => showDetail()}>
                ... im Spiel ...
            </div>
        } else {
            return "";
        }
    }

    if( game === undefined) {
        return <div style={{padding:20, width:250, color:"white"}}>Am Board '{boardName}' wird aktuell nicht gespielt.</div>
    }

    return <div key={"key_live_game_" + game.gameId} style={{
        width: 320,
        backgroundColor: "#01405b",
        border: "1px solid gray",
        margin: 10,
        padding: 0
    }}>
        {game.schreiberId !== undefined && game.schreiberId !== null &&
            <div className="grid"
                 style={{color: "white", margin: 0, padding: 5, textAlign: "center", fontSize:20, backgroundColor:"#ac1c27", borderBottom:"1px solid white"}}>
                <div className="col" style={{margin: 0, padding: 0, width: "100%", overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"}}>
                    Schreiber: {game.schreiberName}
                </div>
            </div>
        }
        <div className="grid"
             style={{height: 120, minHeight: 120, color: "white", margin: 0, padding: 0, textAlign: "center",
                 cursor: game.boardId !== null && game.boardId !== undefined ? "pointer" : "auto"}} onClick={() => showDetail()}>
            <div className="col-fixed" style={{margin: 0, padding: 0, width: "50%"}}>
                {getPlayerSteeldartArea(game.spieler1Id, game.spieler1Name, game.spieler1Togo, game.spieler1LastThrow, "left", game.abgeschlossen,
                    game.sets === 1 ? game.spieler1Legs > game.spieler2Legs : game.spieler1Sets > game.spieler2Sets,
                    game.spieler1Id === game.spielerIdLegAnwurf,
                    game.spieler1Id === game.spielerIdActive, game.canCheckSpieler1)}
                {getPlayerEdartArea(game.spieler1Name, game.spieler1Legs, game.abgeschlossen, game.spieler1Legs > game.spieler2Legs, game.spieler1Legs === game.spieler2Legs)}
            </div>
            <div className="col-fixed" style={{margin: 0, padding: 0, width: "50%"}}>
                {getPlayerSteeldartArea(game.spieler2Id, game.spieler2Name, game.spieler2Togo, game.spieler2LastThrow, "right", game.abgeschlossen,
                    game.sets === 1 ? game.spieler2Legs > game.spieler1Legs : game.spieler2Sets > game.spieler1Sets,
                    game.spieler2Id === game.spielerIdLegAnwurf,
                    game.spieler2Id === game.spielerIdActive, game.canCheckSpieler2)}
                {getPlayerEdartArea(game.spieler2Name, game.spieler2Legs, game.abgeschlossen, game.spieler2Legs > game.spieler1Legs, game.spieler2Legs === game.spieler1Legs)}
            </div>
        </div>
        <hr/>
        {getStartingArea()}
        {getInGameArea()}
        {getScoringDetailArea()}
        <div className="grid" style={{
            backgroundColor: "#ac1c27",
            height: 50,
            color: "white",
            display: "flex",
            justifyContent: "center",
            verticalAlign: "middle",
            alignItems: "center",
            margin: 0,
            padding: 0,
            fontWeight: "bold",
        }}>
            <div className="col" style={{fontSize: 20, cursor:"pointer"}} onClick={() => onFavorit(game.boardId, game.boardName)}>
                <i className={isFavorit() ? PrimeIcons.STAR_FILL : PrimeIcons.STAR} style={{fontSize: 20, marginRight: 5, color: "white"}}/>{game.boardName}
            </div>
            {game.sets === 1 &&
                <div className="col" style={{textAlign: "center", fontSize: 20}}>
                    {game.fistToLegs !== null && game.fistToLegs !== undefined && "first to " + game.fistToLegs + " legs"}
                    {game.bestOfLegs !== null && game.bestOfLegs !== undefined && "best of " + game.bestOfLegs + " legs "}
                </div>
            }
            {game.sets > 1 &&
                <div className="col" style={{textAlign: "center", fontSize: 20}}>
                    {game.fistToLegs !== null && game.fistToLegs !== undefined && "sets: " + game.sets + " ftl: " + game.fistToLegs}
                    {game.bestOfLegs !== null && game.bestOfLegs !== undefined && "sets: " + game.sets + " bol: " + game.bestOfLegs}
                </div>
            }
        </div>
        {game.werbeImageUrl !== null && game.werbeImageUrl !== undefined &&
            <div style={{
                backgroundColor:"white",
                display: "flex",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
                margin: 0,
                padding: 0,
            }}>
                {game.werbeUrl !== null && game.werbeUrl !== undefined && game.werbeUrl !== "" ?
                    <a href={game.werbeUrl} target="_blank"><img src={game.werbeImageUrl} alt="Werbung" style={{maxWidth:310, maxHeight:80, height:80}}/></a>
                    :
                    <img src={game.werbeImageUrl} alt="Werbung" style={{maxWidth:310, maxHeight:80, height:80}}/>
                }
            </div>
        }
    </div>
}
