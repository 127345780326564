import React from 'react'
import PropTypes from "prop-types";
import {InputNumber} from "primereact/inputnumber";

function RanglistePlatzLine({beschriftung, value, onChangeValue}) {

    return <div style={{textAlign:"left", marginBottom:5}}>
        <div className="flex flex-row flex-wrap card-container blue-container">
            <div className="flex" style={{width:30}}>{beschriftung}</div>
            <div className="flex">
                <InputNumber value={value} onValueChange={(e) => onChangeValue(e.value)} min={0}/>
            </div>
        </div>
    </div>
}

RanglistePlatzLine.propTypes = {
    beschriftung : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
    onChangeValue : PropTypes.func.isRequired,
}

export default RanglistePlatzLine;