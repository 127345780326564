import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {ScoreArea, ScoreHeader, WurfArea} from "../../../score/ScoreComponents";
import StatistikComponent from "./StatistikComponent";
import {Chart} from "primereact/chart";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function StatistikGameComponent({gameId, tournamentId, tournamentGameId}) {

    const [game, setGame] = useState(undefined);
    const [tabValue, setTabValue] = useState("legs");

    const [errorMessage, setErrorMessage] = useState(undefined);


    useEffect(() => {
        ladeGameDetailDaten();
    }, [gameId, tournamentId, tournamentGameId]);

    const showError = (message) => {
        messages.current.show({severity: 'error', sticky: true, summary: 'Fehler...', detail: message});
    }

    const ladeGameDetailDaten = () => {
        setErrorMessage(undefined);
        let url;
        if( gameId !== undefined) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/gameausarchiv/x01/" + gameId + "/gameid";
        } else {
            url = ConfigUtil.getConfig().resourceUrls.public + "/gameausarchiv/x01/" + tournamentId + "/" + tournamentGameId + "/tournamentgameid";
        }
        FetchUtil.fetchGet(url,
            json => {
                setGame(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Gamedaten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Allgemeiner Fehler beim Laden der Gamedaten");
            });
    }

    const getScoresZumGameDetail = (scores, gamedetailId) => {
        for (const scoreWrapper of scores) {
            if( scoreWrapper.gamedetailId === gamedetailId ) {
                return scoreWrapper.scores;
            }
        }
        return [];
    }

    const getAnzahlSets = () => {
        let legs = game.gameDetailsPlayer1;
        let groesstesSet = 1;
        for (const leg of legs) {
            if( groesstesSet < leg.setNumber) {
                groesstesSet = leg.setNumber;
            }
        }
        return groesstesSet;
    }

    const getAnzahlLegs = (setNumber) => {
        return game.gameDetailsPlayer1.filter(leg => leg.setNumber === setNumber).length;
    }

    const getScoreArea = (anzScores, setNumber, leg, scoresPlayer1, scoresPlayer2) => {
        return <div key={"set" + setNumber + "_leg_" + leg} className="grid" style={{backgroundColor: "#e6f2ff", padding:0, margin:0}}>
            <div className="col">
                <ScoreHeader fontSize={12}/>
                <div className="grid"
                     style={{backgroundColor: "#e6f2ff"}}>
                    <div className="col">
                        <ScoreArea key={"score_area_player1_set_" + setNumber + "_leg_" + leg} scores={scoresPlayer1} normaleReihenfolge={true}
                                   changeAufnahmeAllowed={false} changeScore={() => ""} anzScores={anzScores} mobileDevice={true}/>
                    </div>
                    <div className="col-fixed" style={{width: 70, margin: 0, padding: 0}}>
                        <WurfArea anzScores={anzScores}  mobileDevice={true} normaleReihenfolge={true}/>
                    </div>
                    <div className="col">
                        <ScoreArea key={"score_area_player2_set" + setNumber + "_leg_" + leg} scores={scoresPlayer2} normaleReihenfolge={true}
                                   changeAufnahmeAllowed={false} anzScores={anzScores} mobileDevice={true}/>
                    </div>
                </div>
            </div>
        </div>;
    }

    const getAverageArea = () => {
        let dataCol = [];
        dataCol.push(getPlayerColumn());
        for (let setNumber = 1; setNumber <= getAnzahlSets(); setNumber++) {
            for (let leg = 1; leg <= getAnzahlLegs(setNumber); leg++) {
                let label = getDetailLabel(setNumber, leg);
                const gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                dataCol.push(getDetailColumn(label, gameDetailsPlayer1.average, gameDetailsPlayer2.average));
            }
        }
        dataCol.push(getDetailColumn("Gesamt", game.player1Statistik.average, game.player2Statistik.average, 70));

        return <div>
            <div style={{display: "flex"}}>
                {dataCol}
            </div>
        </div>
    }

    const getAverageFirst9Area = () => {
        let dataCol = [];
        dataCol.push(getPlayerColumn());

        for (let setNumber = 1; setNumber <= getAnzahlSets() ;setNumber++) {
            for (let leg = 1; leg <= getAnzahlLegs(setNumber); leg++) {
                let label = getDetailLabel(setNumber, leg);
                const gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                dataCol.push(getDetailColumn(label, gameDetailsPlayer1.first9Average, gameDetailsPlayer2.first9Average));
            }
        }
        dataCol.push(getDetailColumn("Gesamt", game.player1Statistik.first9Average, game.player2Statistik.first9Average, 70));
        return <div>
            <div style={{display: "flex"}}>
                {dataCol}
            </div>
        </div>
    }

    const getDetailColumn = (header, value1, value2, width) => {
        return getDetailColumnWithToGoScore(header, value1, value2, width, false, false)
    }
    const getDetailColumnWithToGoScore = (header, value1, value2, width, value1ToGoScore, value2ToGoScore) => {
        return <div style={{width: width !== undefined ? width: 50, minWidth: 50, textAlign: "center"}}>
            <div style={{borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD", backgroundColor: "#ac1c27", color: "white", padding: 10}}>
                {header}
            </div>
            <div style={{borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD", opacity: value1ToGoScore ? "0.5" : "1.0"}}>
                {value1}
            </div>
            <div style={{borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD", opacity: value2ToGoScore ? "0.5" : "1.0"}}>
                {value2}
            </div>
        </div>
    }

    const getDetailLabel = (setNumber, leg) => {
        let isSetModus = getAnzahlSets() > 1;
        let label = "";
        if (isSetModus) {
            label = "S: " + setNumber +"/L: " + leg;
        } else {
            label = "" + leg;
        }
        return label;
    }

    const getPlayerColumn = () => {
        let isSetModus = getAnzahlSets() > 1;
        const player1Name = game.player1Name;
        const player2Name = game.player2Name ? game.player2Name : "Gast";
        return <div style={{
            width: 120, minWidth: 100, textAlign: "left"
        }}>
            <div style={{borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD", backgroundColor: "#ac1c27", color: "white", padding: 10}}>{isSetModus ? "Set/Leg" : "Leg"}</div>
            <div style={{
                whiteSpace: "nowrap", overflow: "hidden",
                textOverflow: "ellipsis",
                borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD", borderTop: "1px solid #DDD"
            }}>
                {player1Name}:
            </div>
            <div style={{
                whiteSpace: "nowrap", overflow: "hidden",
                textOverflow: "ellipsis",
                borderRight: "1px solid #DDD", borderBottom: "1px solid #DDD"
            }}>
                {player2Name}:
            </div>
        </div>
    }

    const getCheckdartsArea = () => {
        let dataCol = [];
        dataCol.push(getPlayerColumn());

        for (let setNumber = 1; setNumber <= getAnzahlSets(); setNumber++) {
            for (let leg = 1; leg <= getAnzahlLegs(setNumber); leg++) {
                let label = getDetailLabel(setNumber, leg);
                const gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const scoresPlayer1 = getScoresZumGameDetail(game.scores, gameDetailsPlayer1.id);
                const scoresPlayer2 = getScoresZumGameDetail(game.scores, gameDetailsPlayer2.id);
                const player1LetzterScore = scoresPlayer1[scoresPlayer1.length-1];
                const player1ToGo = player1LetzterScore !== undefined ? player1LetzterScore.togo : "-";
                const player2LetzterScore = scoresPlayer2[scoresPlayer2.length-1];
                const player2ToGo = player2LetzterScore !== undefined ? player2LetzterScore.togo : "-";
                let valuePlayer1 = gameDetailsPlayer1.gewonnen ? gameDetailsPlayer1.wuerfe : "(" + player1ToGo + ")";
                let valuePlayer2 = gameDetailsPlayer2.gewonnen ? gameDetailsPlayer2.wuerfe : "(" + player2ToGo + ")";
                dataCol.push(getDetailColumnWithToGoScore(label, valuePlayer1, valuePlayer2, 50, !gameDetailsPlayer1.gewonnen, !gameDetailsPlayer2.gewonnen));
            }
        }
        return <div>
            <div style={{display: "flex"}}>
                {dataCol}
            </div>
            <div style={{display: "flex", marginTop:5}}>
                * In Klammer steht der Restscore beim Check
            </div>
        </div>
    }

    const getErgebnisArea = () => {
        if( game === null || game === undefined) {
            return "";
        }
        let isSetModus = getAnzahlSets() > 1;
        let player1Counter;
        let player2Counter;
        if( isSetModus) {
            player1Counter = game.player1Statistik.gewonneneSets;
            player2Counter = game.player2Statistik.gewonneneSets;
        } else {
            player1Counter = game.player1Statistik.gewonneneLegs;
            player2Counter = game.player2Statistik.gewonneneLegs;
        }
        const player1Name = game.player1Name;
        const player2Name = game.player2Name ? game.player2Name : "Gast";
        return <div style={{fontSize:20}}>
            <div style={{marginTop:20,fontWeight:"bold"}}>Ergebnis: </div>
            <div style={{marginTop:10}}><span style={{marginRight:10}}>{player1Counter}</span>{player1Name}</div>
            <div><span style={{marginRight:10}}>{player2Counter}</span>{player2Name}</div>
            <hr/>
        </div>
    }

    const getChart = () => {
        let dataAvgPlayer1 = [];
        let dataAvgPlayer2 = [];
        let labels = [];
        let isSetModus = getAnzahlSets() > 1;
        for(let setNumber = 1; setNumber <= getAnzahlSets() ;setNumber++) {
            for (let leg = 1; leg <= getAnzahlLegs(setNumber); leg++) {
                if (isSetModus) {
                    labels.push("S:" + setNumber +"/L:" + leg);
                } else {
                    labels.push("Leg:" + leg);
                }
                const gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const legAveragePlayer1 = gameDetailsPlayer1.average;
                const legAveragePlayer2 = gameDetailsPlayer2.average;
                dataAvgPlayer1.push(legAveragePlayer1);
                dataAvgPlayer2.push(legAveragePlayer2);
            }
        }

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: labels,
            datasets: [
                {
                    type: 'bar',
                    label: game.player1Name,
                    backgroundColor: "green",
                    data: dataAvgPlayer1,
                    borderColor: 'white',
                    borderWidth: 2
                },
                {
                    type: 'bar',
                    label: game.player2Name ? game.player2Name : "Gast",
                    backgroundColor: "red",
                    data: dataAvgPlayer2,
                    borderColor: 'white',
                    borderWidth: 2
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
        return <Chart type="line" data={data} options={options}/>
    }

    const getLegs = () => {
        let legs =  [];

        legs.push(<div>
            <div style={{marginTop:20,fontWeight:"bold"}}>Averagevergleich</div>
            {getChart()}
        </div>)
        legs.push(<div>
            <div style={{marginTop:20,fontWeight:"bold"}}>Averages</div>
            {getAverageArea()}
        </div>)
        legs.push(<div>
            <div style={{marginTop:20,fontWeight:"bold"}}>First 9 Average</div>
            {getAverageFirst9Area()}
        </div>)
        legs.push(<div>
            <div style={{marginTop:20,fontWeight:"bold"}}>Checkdarts</div>
            {getCheckdartsArea()}
        </div>)
        let isSetModus = getAnzahlSets() > 1;

        for(let setNumber = 1; setNumber <= getAnzahlSets() ;setNumber++) {
            for(let leg = 1; leg <= getAnzahlLegs(setNumber) ;leg++) {
                let legSetBez = "";
                if( isSetModus ) {
                    legSetBez = "Set " + setNumber + " Leg Nummer " + leg;
                } else {
                    legSetBez = "Leg Nummer " + leg;
                }
                const gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
                const player1Name = game.player1Name;
                const player2Name = game.player2Name ? game.player2Name : "Gast";
                const scoresPlayer1 = getScoresZumGameDetail(game.scores, gameDetailsPlayer1.id);
                const scoresPlayer2 = getScoresZumGameDetail(game.scores, gameDetailsPlayer2.id);
                const anzScoresPlayer1 = scoresPlayer1.length;
                const legAveragePlayer1 = gameDetailsPlayer1.average;
                const player1Gewonnnen = gameDetailsPlayer1.gewonnen;
                const player2Gewonnnen = gameDetailsPlayer2.gewonnen;
                const legWuerfePlayer1 = gameDetailsPlayer1.wuerfe;
                const anzScoresPlayer2 = scoresPlayer2.length;
                const legAveragePlayer2 = gameDetailsPlayer2.average;
                const legWuerfePlayer2 = gameDetailsPlayer2.wuerfe;
                const anzScores = anzScoresPlayer1 > anzScoresPlayer2 ? anzScoresPlayer1 : anzScoresPlayer2;
                legs.push(<div key={"ueberschrift_set_" + setNumber +"_leg" + leg}>
                    <div style={{marginTop:20, marginBottom:10, fontSize:20, fontWeight:"bold", textAlign:"center"}}>
                        {legSetBez}
                    </div>
                    <div className="grid">
                        <div className="col" style={{textAlign:"center", color: player1Gewonnnen ? "green" : "red"}}>
                            {player1Name} (Avg. {legAveragePlayer1}/ Würfe {legWuerfePlayer1})
                        </div>
                        <div className="col" style={{textAlign:"center", color: player2Gewonnnen ? "green" : "red"}}>
                            {player2Name} (Avg. {legAveragePlayer2}/ Würfe {legWuerfePlayer2})
                        </div>
                    </div>
                </div>);
                legs.push(getScoreArea(anzScores, setNumber, leg, scoresPlayer1, scoresPlayer2));
            }
        }
        return legs
    }

    const erzeugeTab = (value, key) => {
        return <div key={"key_tab_" + key} style={{fontSize: 20, width:"33%", marginLeft:5, cursor:"pointer",
            borderBottom: tabValue === key ? "2px solid red" : "1px dashed  red", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value ? value.toUpperCase() : "Gast"}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Legs","legs"));
        returnValue.push(erzeugeTab(game.player1Name,"player1"));
        returnValue.push(erzeugeTab(game.player2Name,"player2"));
        return returnValue;
    }

    const getTabpanel = () => {
        if (tabValue === "legs") {
            return getLegs();
        } else if( tabValue === "player1") {
            return <StatistikComponent name={game.player1Name} showPlayerGesamtStatistik={false} statistik={game.player1Statistik}/>
        } else if( tabValue === "player2") {
            return <StatistikComponent name={game.player2Name ? game.player2Name : "Gast"} showPlayerGesamtStatistik={false} statistik={game.player2Statistik}/>
        }
    }

    return <div>
        <ErrorInfoComponent infoMessage={undefined} errorMessage={errorMessage} onClearInfoMessage={() => ""} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{width:"100%", marginTop:20}}>
            {getErgebnisArea()}
        </div>
        <div style={{width:"100%"}}>
            {game !== undefined &&
                <>
                    <div style={{display:"flex", marginTop:20}}>
                        {getTabs()}
                    </div>
                    {getTabpanel()}
                </>
            }
        </div>
    </div>
}
