import React from "react";

export default function LigaSpieltagEDartDetailStatistikGameMinimiert({gameNr, spielerName, gegnerName, anzahlSpieler1, anzahlSpieler2, onMaximize}) {


    return <div style={{width: 300, borderRight: "1px solid gray", padding: 10}}>
        <div style={{
            marginBottom: 1, backgroundColor: "#022836", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis",
            height: 30, display: "flex", alignItems:"center",
        }}>
            <div style={{width:50}}>
                <i className="pi pi-window-maximize" onClick={() => onMaximize()}/>
            </div>
            <div style={{fontSize:14}}>
                Spiel: {gameNr}
            </div>
        </div>
        <div style={{
            marginBottom: 1, backgroundColor: "#ac1c27", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis", display:"flex",
        }}>
            <div style={{width:30, minWidth:30, borderRight: "1px solid white", marginRight:5}}>{anzahlSpieler1 ? anzahlSpieler1 : 0}</div>
            {spielerName}
        </div>
        <div style={{
            marginBottom: 20, backgroundColor: "#ac1c27", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis", display: "flex",
        }}>
            <div style={{width: 30, minWidth:30, borderRight: "1px solid white", marginRight: 5}}>{anzahlSpieler2 ? anzahlSpieler2 : 0}</div>
            {gegnerName}
        </div>
    </div>
}