import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import ExterneLigaSpieltag from "../../../components/views/externeliga/ExterneLigaSpieltag";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const id = urlParams.get('id');
    return {id: id, userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExterneLigaSpieltag))
