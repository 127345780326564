import {connect} from 'react-redux'
import SpieltagOverview from "../../../components/views/spieltag/SpieltagOverview";

const mapStateToProps = state => ({
    userRoles: state.user.userRoles
});

const mapDispatchToProps = dispatch => ({
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpieltagOverview)