import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import TournamentX01Area from "./TournamentX01Area";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHDialog from "../../general/DHDialog";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";


export default function ChangeModusOrBoardKODialog({tournamentId, eventOperatorId, prefix, bezeichnung, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [sets, setSets] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);
    let [modusId, setModusId] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);

    const [boardsVergeben, setBoardsVergeben] = useState([]);
    const [boards, setBoards] = useState([]);

    const [tabValue, setTabValue] = useState("modus");

    useEffect(() => {
        if( prefix !== undefined && prefix !== null) {
            ladeDaten();
        }
    }, [prefix]);

    useEffect(() => {
        if( eventOperatorId !== undefined && eventOperatorId !== null) {
            ladeBoards();
            ladeTournamentBoards();
        }
    }, [eventOperatorId]);

    const ladeBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/"+ eventOperatorId,
            dto => {
                setBoards(dto.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Boards: " + error.message);
            }
        );
    }

    const ladeTournamentBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/boardsByTournament/"+ eventOperatorId +"/" + tournamentId,
            dto => {
                let tournamentBoards = dto.tournamentBoards;
                let tmpKO = [];
                for (const board of tournamentBoards) {
                    if( board.tournamentOrginalId === tournamentId && board.koArea === prefix) {
                        tmpKO.push({boardId: board.boardId});
                    }
                }
                setBoardsVergeben(tmpKO);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const ladeDaten = () => {
        setErrorMessage(undefined)
        let data = {prefix: prefix};
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/loadtournamentmodus/" + tournamentId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setSets(json.sets);
                setModusId(json.modusId);
                setFirstToLegs(json.firttolegs);
                setStartpunkte(json.startpunkte);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const saveBoards = (close) =>  {
        setErrorMessage(undefined)
        let data = {prefix: prefix, boards: boardsVergeben};
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/boards/kophase/" + tournamentId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                if( close ) {
                    onClose();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const saveModus = (close) =>  {
        setErrorMessage(undefined)
        let data = {prefix: prefix, sets: sets, firstToLegs: firstToLegs, modusid: modusId, startpunkte: startpunkte};
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/savetournamentmodus/" + tournamentId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                if( close ) {
                    onClose();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const erzeugeTab = (value, key) => {
        return <div key={"key_tab_" + key} style={{fontSize: 20, width:"50%", marginLeft:5, cursor:"pointer",
            borderBottom: tabValue === key ? "2px solid red" : "1px dashed  red", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Modus","modus"));
        if( eventOperatorId !== null && eventOperatorId !== undefined) {
            returnValue.push(erzeugeTab("Boards","boards"));
        }
        return returnValue;
    }

    const getTabpanel = () => {
        if (tabValue === "modus") {
            return getModusArea();
        } else if( tabValue === "boards") {
            return getBoardsArea();
        }
    }

    const enableSaveModusButton = () => {
        return sets &&  firstToLegs &&  modusId  && startpunkte;
    }

    const addBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsVergeben];
        tmpBoardsVergeben.push({boardId: boardId});
        setBoardsVergeben(tmpBoardsVergeben);
    }

    const removeBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsVergeben];
        tmpBoardsVergeben = tmpBoardsVergeben.filter(value => value.boardId !== boardId);
        setBoardsVergeben(tmpBoardsVergeben);
    }


    const getBoardsArea = () => {

        let tmpBoards = [];
        for (const board of boards) {
            if( boardsVergeben.filter(value => value.boardId === board.id).length === 0 ) {
                tmpBoards.push({name: board.bezeichnung, code: board.id});
            }
        }

        let rows = [];
        for (const board of boardsVergeben) {
            let tmpArray = boards.filter(value => value.id === board.boardId);
            if( tmpArray !== null && tmpArray !== undefined && tmpArray.length === 1) {
                let tmpBoard = tmpArray[0];
                rows.push(<div key={"key_ko_board_" + board.boardId} style={{marginTop:10, fontSize:16}}>
                    <Button icon="pi pi-minus-circle" style={{marginRight:10, height:30, width:30}} onClick={() => removeBoard(board.boardId)}/>
                    {tmpBoard.bezeichnung}
                </div>)
            }
        }

        return <>
            <div style={{marginBottom:10}}>Hier kannst du für die gewählte KO/DKO Phase (z.B. {bezeichnung}) die gewünschten Boards festlegen.<br/>
                Sobald du mindestens ein Board festgelegt hast, wird diese Phase ausschließlich auf den zugewiesenen Boads gespielt.<br/>
                Die Standardeinstellung über 'Rund ums Board' wird nicht mehr beachtet.
            </div>
            <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHSelectionComponent value={undefined} onChange={(boardId) => addBoard(boardId) } values={tmpBoards} title="Boardauswahl"/>
            </div>
            <div style={{marginTop: 30}}>
                {rows}
            </div>
            <div style={{marginTop: 20, textAlign: "right"}}>
                <Button label="Boardeinstellungen Speichern und Schließen"
                        style={{marginRight: 5}}
                        onClick={() => saveBoards(true)}/>
                <Button label="Boardeinstellungen Speichern"
                        style={{marginRight: 5}}
                        onClick={() => saveBoards(false)}/>
                <Button label="Schliessen"
                        onClick={() => onClose()}/>
            </div>
        </>
    }

    const getModusArea = () => {
        return <>
            <TournamentX01Area showSets={true} showBestOfLegs={false} sets={sets}
                               showPflichtfelder={true} firstToLegs={firstToLegs}
                               modusId={modusId} startpunkte={startpunkte}
                               onSetsChanged={(sets) => setSets(sets)}
                               onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                               onModusIdChanged={(modusId) => setModusId(modusId)}
                               onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
            <div style={{marginTop: 20, textAlign: "right"}}>
                <Button label="Modus Speichern und Schließen"
                        disabled={!enableSaveModusButton()}
                        style={{marginRight: 5}}
                        onClick={() => saveModus(true)}/>
                <Button label="Modus Speichern"
                        disabled={!enableSaveModusButton()}
                        style={{marginRight: 5}}
                        onClick={() => saveModus(false)}/>
                <Button label="Schliessen"
                        onClick={() => onClose()}/>
            </div>
        </>
    }

    return <DHDialog onClose={onClose} title="Modus / Boards verändern" comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: 20, fontSize:20, fontWeight:"bold"}}>Änderung für '{bezeichnung}'</div>
        <div style={{display: "flex", marginTop: 20}}>
            {getTabs()}
        </div>
        <div style={{padding: 20}}>
            {getTabpanel()}
        </div>
    </>
    }/>
}
