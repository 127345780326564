import React from 'react'
import PropTypes from "prop-types";

import FullCalendar, {formatDate} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import deLocale from '@fullcalendar/core/locales/de';
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import ConfirmDialog from "../../general/ConfirmDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Checkbox} from "primereact/checkbox";
import DatePicker from "react-datepicker";
import {Button} from "primereact/button";
import DateUtil from "../../../util/DateUtil";
import KalenderNewEventDialog from "./KalenderNewEventDialog";

class KalenderComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedEvent: undefined,
            showTerminAnnehmenDialog: false,
            showNewEventDialog: false,
            showDeleteDialog: false,
            vergangenheitAnzeigen: false,
            nurAktuellesTurnier: false,
        };

        this.saveSelectedEvent = this.saveSelectedEvent.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.terminAnnehmen = this.terminAnnehmen.bind(this);
        this.storniereEvent = this.storniereEvent.bind(this);
        this.renderEventContent = this.renderEventContent.bind(this);
        this.clickFlatRow = this.clickFlatRow.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    isMobileDevice() {
        let windowWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        if (windowWidth < 1150) {
            return true;
        }
        return false;
    }


    render() {
        return (
            <div className='demo-app'>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <div className='demo-app-main'>
                    {this.isMobileDevice() ? this.getFlatCalendar() : this.getFullCalendar()}
                </div>
                {this.state.showNewEventDialog &&
                    <KalenderNewEventDialog tournamentId={this.props.tournamentId}
                                            onClose={() => {
                                                this.setState({
                                                    showNewEventDialog: false, selectedEvent: undefined
                                                })}}
                                            onSave={(nurAktuellesTurnier) => this.saveSelectedEvent(nurAktuellesTurnier)}
                    />
                }
                <ConfirmDialog visible={this.state.showDeleteDialog}
                               callBackOnNein={() => this.setState({
                                   showDeleteDialog: undefined
                               })}
                               callBackOnJa={this.storniereEvent}
                               header="Zeitpunkt löschen..."
                               textOben="Wenn du Eigentümer des Termins bist, wird der Termin gelöscht. Sofern du den Termin nur von einem anderen Benutzer angenommen hast, wirst du ausgetragen. Dein Gegner wird darüber informiert."
                               textUnten={"Möchtest du den Eintrag löschen?"}
                />
                <ConfirmDialog visible={this.state.showTerminAnnehmenDialog}
                               callBackOnNein={() => this.setState({
                                   showTerminAnnehmenDialog: undefined
                               })}
                               callBackOnJa={this.terminAnnehmen}
                               header="Termin annehmen..."
                               textOben=""
                               textUnten={"Möchtest du den Termin wirklich annehmen?"}
                />

            </div>
        )
    }

    renderEventContent(eventInfo) {
        let event = this.props.orgEvents.find(event => event.id === eventInfo.event.id);
        let backgroundColor = "green";
        if (event !== undefined) {
            let gegnerId = event.gegnerId;
            if (gegnerId !== null && gegnerId !== undefined) {
                backgroundColor = "red";
            }
        }
        return (
            <div style={{backgroundColor: backgroundColor, width: "100%", height: "100%"}}>
                <b>{eventInfo.timeText} </b>
                <i>{eventInfo.event.title}</i>
            </div>
        )
    }

    storniereEvent() {
        if (this.props.readOnly) {
            return;
        }

        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.event + "/stornieren?eventId=" + this.state.selectedEvent.id,
            {},
            () => {
                this.showInfo("Dein Termin wurde storniert");
                this.setState({showDeleteDialog: false, selectedEvent: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showDeleteDialog: false, selectedEvent: undefined});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showDeleteDialog: false, selectedEvent: undefined});
            }
        );
    }

    saveFlatEvent() {
        this.messages.clear();
        if (this.state.date === null || this.state.date === undefined) {
            return;
        }

        let tmpDatum = DateUtil.toLocalDateTime(this.state.date);
        let data = {isoTime: tmpDatum, tournamentId: this.state.nurAktuellesTurnier ? this.props.tournamentId: undefined};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.event,
            data,
            () => {
                this.showInfo("Dein Termin wurde eingetragen");
                this.setState({date: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showNewEventDialog: false, selectedEvent: undefined});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showNewEventDialog: false, selectedEvent: undefined});
            }
        );
    }

    saveSelectedEvent(nurAktuellesTurnier) {
        if (this.props.readOnly) {
            return;
        }

        this.messages.clear();
        let data = {isoTime: this.state.selectedEvent.startStr, tournamentId: nurAktuellesTurnier ? this.props.tournamentId: undefined};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.event,
            data,
            () => {
                this.showInfo("Dein Termin wurde eingetragen");
                this.setState({showNewEventDialog: false, selectedEvent: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showNewEventDialog: false, selectedEvent: undefined});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showNewEventDialog: false, selectedEvent: undefined});
            }
        );
    }

    terminAnnehmen() {
        if (this.props.readOnly) {
            return;
        }
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.event + "/annehmen?eventId=" + this.state.selectedEvent.id,
            {},
            () => {
                this.showInfo("Dein Termin wurde eingetragen");
                this.setState({showTerminAnnehmenDialog: false, selectedEvent: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showTerminAnnehmenDialog: false, selectedEvent: undefined});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showTerminAnnehmenDialog: false, selectedEvent: undefined});
            }
        );
    }

    getZeitpunktBySelectedEvent = () => {
        if (this.state.selectedEvent === undefined) {
            return "";
        }
        let selectedEvent = this.state.selectedEvent;
        let tag = formatDate(selectedEvent.start, {day: '2-digit'});
        let monat = formatDate(selectedEvent.start, {month: '2-digit'});
        let jahr = formatDate(selectedEvent.start, {year: 'numeric'});
        let stundeminuten = formatDate(selectedEvent.start, {hour: '2-digit', minute: '2-digit', hour12: false});
        return tag + "." + monat + "." + jahr + " " + stundeminuten;
    }

    handleDateSelect = (selectInfo) => {
        if (selectInfo.allDay || this.props.readOnly) {
            return;
        }
        this.setState({showNewEventDialog: true, selectedEvent: selectInfo});
    }

    handleEventClick = (clickInfo) => {
        if (this.props.readOnly) {
            return;
        }
        let event = this.props.orgEvents.find(event => event.id === clickInfo.event.id);
        if (event.userEigentuemerId === this.props.userId || event.gegnerId === this.props.userId) {
            this.setState({showDeleteDialog: true, selectedEvent: event});
        } else if (event.gegnerId === null || event.gegnerId === undefined) {
            this.setState({showTerminAnnehmenDialog: true, selectedEvent: event});
        } else {
            this.setState({showNewEventDialog: true, selectedEvent: clickInfo.event});
        }
    }

    handleEvents = (events) => {
        // wird ausgelöst, wenn die Events initial in den Kalender geladen werden.
    }

    getFlatCalendar() {
        if (this.props.flatEvents === null || this.props.flatEvents === undefined) {
            return "Keine Daten vorhanden";
        }
        let rows = [];
        for (const event of this.props.flatEvents) {
            if (event.vergangeheit && !this.state.vergangenheitAnzeigen) {
                continue;
            }

            let eintraege = [];
            for (const einzelEvent of event.events) {
                let backgroundColor = "green";
                let gegnerId = einzelEvent.gegnerId;
                if (gegnerId !== null && gegnerId !== undefined) {
                    backgroundColor = "red";
                }
                let title = einzelEvent.userEigentuemerName;
                if (einzelEvent.gegnerName !== null && einzelEvent.gegnerName !== undefined) {
                    title += " vs. " + einzelEvent.gegnerName;
                }
                eintraege.push(<div style={{backgroundColor: backgroundColor, width: "100%", color: "white"}}
                                    onClick={() => this.clickFlatRow(einzelEvent)} key={"key_kalender_component_einzelevent" + einzelEvent.id}>
                    <div style={{marginTop: 10}}>{einzelEvent.timeFormatted}</div>
                    <div style={{marginBottom: 10}}>{title}</div>
                    <hr style={{borderTop: "1px dashed gray"}}/>
                </div>)
            }
            rows.push(<div key={"key_kalender_componente_ueberevent_" + event.timeFormatted}>
                <div style={{fontWeight: "bold", marginBottom: 5}}>{event.timeFormatted}</div>
                {eintraege}
                <hr/>
            </div>);
        }
        return <div>
            <div style={{marginBottom: 20, textAlign:"left"}}>
                <Checkbox inputId="vergangenheitAnzeigen"
                          onChange={e => this.setState({vergangenheitAnzeigen: !this.state.vergangenheitAnzeigen})}
                          checked={this.state.vergangenheitAnzeigen} style={{marginRight:5}}/>
                <label htmlFor="vergangenheitAnzeigen" className="p-checkbox-label">Vergangene Termine anzeigen</label>
            </div>
            <hr/>
            <div style={{marginBottom: 5, textAlign:"left"}}>
                Neuen freien Termin anlegen:
            </div>
            {this.props.tournamentId !== undefined &&
                <div style={{marginTop:10, marginBottom:10, textAlign:"left"}}>
                    <Checkbox checked={this.state.nurAktuellesTurnier} onChange={() => this.setState({nurAktuellesTurnier:!this.state.nurAktuellesTurnier})} style={{marginRight:5}}/>
                    <span>nur am aktuellen Turnier/Liga anzeigen</span>
                </div>
            }
            <div style={{marginBottom: 5, textAlign:"left"}}>
                <DatePicker
                    selected={this.state.date}
                    onChange={date => this.setState({date: date})}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Zeit"
                    dateFormat="dd.MM.yyyy HH:mm"
                    todayButton="Heute"
                />
            </div>
            <div style={{display:"flex", marginBottom: 20, textAlign:"left"}}>
                <Button icon="pi pi-check" style={{marginLeft:5}} disabled={!this.state.date} onClick={() => this.saveFlatEvent()}/>
            </div>

            {rows}
        </div>;
    }

    getFullCalendar() {
        return <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            locale={deLocale}
            firstDay={1}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='timeGridWeek'
            editable={!this.props.readOnly}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={this.props.events}
            select={this.handleDateSelect}
            eventContent={this.renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            eventStartEditable={false}
        />

    }

    clickFlatRow(event) {
        if (event.userEigentuemerId === this.props.userId || event.gegnerId === this.props.userId) {
            this.setState({showDeleteDialog: true, selectedEvent: event});
        } else if (event.gegnerId === null || event.gegnerId === undefined) {
            this.setState({showTerminAnnehmenDialog: true, selectedEvent: event});
        }
    }
}


KalenderComponent.propTypes = {
    userId: PropTypes.string.isRequired,
    tournamentId: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    events: PropTypes.array.isRequired,
    flatEvents: PropTypes.array.isRequired,
    orgEvents: PropTypes.array.isRequired,
};


export default KalenderComponent
