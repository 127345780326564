import React from 'react'
import {FunGameShortComponent} from "./ligaComponents";
import PropTypes from "prop-types";

class FunGamesArea extends React.Component {

    gibtEsHerausforderungen() {
        if(this.props.funGames === null || this.props.funGames === undefined) {
            return false;
        }
        for (const funGame of this.props.funGames) {
            if (funGame.herausforderung && funGame.spielerId === this.props.userId && funGame.gegnerId === null) {
                return true;
            }
        }
        return false;
    }

    getRowsHerausforderungen() {
        if(this.props.funGames === null || this.props.funGames === undefined) {
            return "";
        }
        let rows = [];
        for (const funGame of this.props.funGames) {
            if (funGame.herausforderung && funGame.spielerId === this.props.userId && funGame.gegnerId === null) {
                rows.push(<FunGameShortComponent funGame={funGame} userId={this.props.userId}
                                                 key={"key_fungame_herausforderung_" + funGame.id}
                                                 showDelete={this.props.showDelete}
                                                 callBackAfterDelete={this.props.callBackAfterDelete}/>);
            }
        }
        return rows;
    }

    getRows() {
        if(this.props.funGames === null || this.props.funGames === undefined) {
            return "";
        }
        let rows = [];
        for (const funGame of this.props.funGames) {
            if (funGame.herausforderung && funGame.spielerId === this.props.userId && funGame.gegnerId === null) {
                continue;
            }
            rows.push(<FunGameShortComponent funGame={funGame} userId={this.props.userId} key={"key_fungame_row_" + funGame.id}
                                             showDelete={this.props.showDelete}
                                                 callBackAfterDelete={this.props.callBackAfterDelete}/>);
        }
        return rows;
    }

    render() {
        return <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid red"}}>
            <h3>Aktive Spiele</h3>
            {this.getRows()}
            {this.gibtEsHerausforderungen() && <div>
                <h3>Deine öffentlichen Herausforderungen</h3>
                {this.getRowsHerausforderungen()}
            </div>}
        </div>
    }
}

FunGamesArea.propTypes = {
    funGames: PropTypes.array,
    userId: PropTypes.string,
}

export default FunGamesArea;
