import {connect} from 'react-redux'
import LigaDashboard from "../../../components/views/start/LigaDashboard";
import {withCookies} from "react-cookie";
import {gamesOverWebsocket, herausforderungenOverWebsocket} from "../../../actions/games";
import {newAdminNotificationsOverWebsocket, notificationsOverWebsocket} from "../../../actions/notifications";
import {newTurnierInfosOverWebsocket, newLigaInfosOverWebsocket} from "../../../actions/tournament";
import {onlinefriendsOverWebsocket} from "../../../actions/friends";
import {logout} from "../../../actions/user";
import {statistikOverWebsocket} from "../../../actions/statistik";
import {newLeagueOperators} from "../../../actions/leagueoperator";
import {newChatInfosOverWebsocket} from "../../../actions/chat";

const mapStateToProps = (state, ownProps) => ({
    userId: state.user.id,
    userEventOperatorAdmin: state.user.userEventOperatorAdmin,
    leagueoperators: state.leagueoperator.leagueoperators,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    gamesOverWebsocket: (games) => dispatch(gamesOverWebsocket(games)),
    herausforderungenOverWebsocket: (herausforderungen) => dispatch(herausforderungenOverWebsocket(herausforderungen)),
    notificationsOverWebsocket: (notifications) => dispatch(notificationsOverWebsocket(notifications)),
    onlinefriendsOverWebsocket: (friends) => dispatch(onlinefriendsOverWebsocket(friends)),
    statistikOverWebsocket: (statistik) => dispatch(statistikOverWebsocket(statistik)),
    newAdminNotificationsOverWebsocket: (news) => dispatch(newAdminNotificationsOverWebsocket(news)),
    newLigaInfosOverWebsocket: (news) => dispatch(newLigaInfosOverWebsocket(news)),
    newTurnierInfosOverWebsocket: (news) => dispatch(newTurnierInfosOverWebsocket(news)),
    newLeagueOperators: (leagueoperators) => dispatch(newLeagueOperators(leagueoperators)),
    newChatInfosOverWebsocket: (chatInfos) => dispatch(newChatInfosOverWebsocket(chatInfos)),
    logout: () => dispatch(logout())

});

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(LigaDashboard))
