import React from 'react'
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";


export default function ProgressButton({text, icon, disabled, working, onClick}) {

    return <Button label={text}
                   icon={working ? <ProgressSpinner style={{width:20, height:20}} strokeWidth={6} fill="var(--surface-ground)"/> : icon !== undefined ? icon : ""}
                   disabled={disabled || working}
                   className={working ? "p-button-secondary" : "p-button-primary"}
                   onClick={() => onClick()}>

    </Button>
}
