import {connect} from 'react-redux'
import KachelLogout from "../../components/general/KachelLogout";
import {logout} from "../../actions/user";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KachelLogout)