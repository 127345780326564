import React from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import PropTypes from "prop-types";

class PublicScoringView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            connectmessage: "Sie sind nicht mit dem Server verbunden.",
            connectmessagecolor: "red",
            showDarthelferLogo: false,
            scoreresult: undefined,
        };

        this.callbackNachrichtErhalten = this.callbackNachrichtErhalten.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo({
            top: 10000000000000000,
            left: 0,
            behavior: 'smooth'
        });
    }

    componentDidMount() {
        if (this.props.mandant !== undefined && this.props.mandant !== null && this.props.monitorkey !== undefined && this.props.monitorkey !== null) {
            this.validateAndConnect();
        }
    }

    validateAndConnect() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/validate/monitor/" + this.props.mandant + "/" + this.props.monitorkey,
            json => {
                if (json !== undefined && json !== null && json !== "") {
                    this.setState({monitorname: json});
                    this.websocketConnect();
                } else {
                    this.setState({
                        ...this.state, monitorname: "",
                        keyMessage: "Du bist nicht mit dem Server verbunden, evtl. ungültiger 'Public Key'...",
                        messageColor: "red"
                    });
                }
            },
            responseNotOk => {
                this.setState({
                    ...this.state,
                    keyMessage: "Du bist nicht mit dem Server verbunden...",
                    messageColor: "red"
                });
                this.props.showError(responseNotOk.message);
            },
            error => {
                this.setState({
                    ...this.state,
                    keyMessage: "Du bist nicht mit dem Server verbunden...",
                    messageColor: "red"
                });
                this.props.showError(error.message);
            });
    }

    websocketConnect() {
        let stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                stompClient.subscribe("/topic/public/monitor/" + this.props.mandant + "/" + this.props.monitorkey, this.callbackNachrichtErhalten);
                this.setState({connectmessage: "Verbunden...", connectmessagecolor: "green"});
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    callbackNachrichtErhalten(payload) {
        this.setState({scoreresult: JSON.parse(payload.body)});
    }

    getStatistikArea(orientation, player) {
        let style = {position: "fixed", top: 340};
        if (orientation === "left") {
            style = {...style, left: 0};
        } else {
            style = {...style, right: 0};
        }
        let fixedColStyle = {width: 160};
        return <div style={style}>
            <div className="grid" style={{
                position: "relative", top: 10, left: 0, bottom: 0, width: 300,
                backgroundImage: "url(/images/darthelfer.svg)",
                backgroundRepeat: "no-repeat", opacity: ".05",
            }}></div>

            <div className="grid" style={{
                position: "relative",
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
                margin: 0,
                padding: 0,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 30
            }}>
                <div className="col" style={fixedColStyle}>
                    <div className="grid">
                        <div className="col" style={{color: "#3333cc"}}>
                            Totals
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            Ton
                        </div>
                        <div className="col">
                            {player.ton}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            100+
                        </div>
                        <div className="col">
                            {player.moreThan100}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            140+
                        </div>
                        <div className="col">
                            {player.moreThan140}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            171er
                        </div>
                        <div className="col">
                            {player.score171er}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            180er
                        </div>
                        <div className="col">
                            {player.score180er}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col" style={{color: "#3333cc"}}>
                            Average
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={fixedColStyle}>
                            3-Darts &#8709;
                        </div>
                        <div className="col">
                            {player.average}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    getWurfArea(anzScores) {
        let lineStyle = {
            verticalAlign: "middle",
            height: 60,
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            borderBottom: "1px solid black",
            margin: 0,
            padding: 0
        };

        let counter = 3;
        let rows = [];
        for (let i = 0; i < anzScores; i++) {
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {...lineStyle, backgroundColor: "#e6eeff"} : {
                ...lineStyle,
                backgroundColor: "#b3ccff"
            };
            rows.push(<div className="grid" style={currentStyle}>
                <div className="col" style={{padding: 0, margin: "auto", verticalAlign: "middle"}}>{counter}</div>
            </div>);
            counter = counter + 3;
        }
        return <div className="grid" style={{margin: 0, padding: 0, paddingTop: 7.5}}>
            <div className="col">
                {rows}
            </div>
        </div>
    }

    getScoreArea(player) {
        let lineStyle = {
            verticalAlign: "middle",
            height: 60,
            lineHeight: 1,
            textAlign: "center",
            fontSize: 30,
            borderBottom: "1px solid black",
            margin: 0,
            padding: 0,
            backgroundColor: "#e6f2ff",
        };
        let rows = [];
        for (let i = 0; i < player.scores.length; i++) {
            let score = player.scores[i];
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {...lineStyle, backgroundColor: "#e6eeff"} : {
                ...lineStyle,
                backgroundColor: "#b3ccff"
            };
            rows.push(<div className="grid" style={currentStyle}>
                <div className="col">{score.scored !== undefined ? score.scored : "-"}</div>
                <div className="col">{score.togo}</div>
            </div>)
        }

        return <div className="grid"
                    style={{
                        margin: 0,
                        padding: 0,
                    }}>
            <div className="col">
                {rows}
            </div>
        </div>

    }

    getScoreHeader() {
        return <div className="grid" style={{
            position: "fixed", top: 340, left: 0, bottom: 0, width:"100%", height:50,
            padding: 0,
            margin: 0,
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            zIndex:1000000000000,
        }}>
            <div className="col-fixed" style={{width:310, backgroundColor:undefined}}></div>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor:"white"}}>scored</div>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor:"white"}}>to go</div>
            <div className="col-fixed" style={{width: 70, borderBottom: "2px solid black", backgroundColor:"white"}}/>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor:"white"}}>scored</div>
            <div className="col" style={{borderBottom: "2px solid black", backgroundColor:"white"}}>to go</div>
            <div className="col-fixed" style={{width:310}}></div>
        </div>
    }

    render() {
        if (this.state.scoreresult === undefined) {
            return <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "#e6f2ff",
                height: "100%",
                width: "100%",
                textAlign: "center"
            }}>
                {this.getTopArea()}
                <img alt="Logo" src={"images/darthelfer.svg"} style={{height: "80%", marginTop: 50}}/>
            </div>
        }

        let anzScores  = this.state.scoreresult.player1.scores.length > this.state.scoreresult.player2.scores.length ? this.state.scoreresult.player1.scores.length : this.state.scoreresult.player2.scores.length;

        return <div
            style={{position: "absolute", top: 0, left: 0, backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <div style={{zIndex: 1000000000000000000000000}}>
                {this.getTopArea()}
                {this.getPlayerArea()}
                {this.getStatistikArea("left", this.state.scoreresult.player1)}
                {this.getStatistikArea("right", this.state.scoreresult.player2)}
                {this.getScoreHeader()}
            </div>
            <div style={{backgroundColor: "#e6f2ff"}}>
                <div className="grid"
                     style={{marginLeft: 300, marginRight: 300, marginTop: 380, backgroundColor: "#e6f2ff"}}>
                    <div className="col" style={{borderLeft: "2px solid black"}}>
                        {this.getScoreArea(this.state.scoreresult.player1)}
                    </div>
                    <div className="col-fixed" style={{width: 70, margin: 0, padding: 0, borderLeft: "2px solid black", borderRight: "2px solid black"}}>
                        {this.getWurfArea(anzScores)}
                    </div>
                    <div className="col" style={{borderRight: "2px solid black"}}>
                        {this.getScoreArea(this.state.scoreresult.player2)}
                    </div>
                </div>
            </div>
        </div>;
    }


    getTopArea() {
        return <div className="grid" style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: 40,
            margin: 0,
            padding: 0,
            width: "100%",
            backgroundColor: "#e6f2ff"
        }}>
            <div className="col" style={{textAlign: "left", color: this.state.connectmessagecolor}}>
                <span style={{fontSize: 20}}>{this.state.monitorname + " " + this.state.connectmessage}</span><br/>
            </div>
            <div className="col" style={{textAlign: "center",}}>
                <span
                    style={{fontSize: 20}}>{this.state.scoreresult !== undefined ? "Modus: '" + this.state.scoreresult.modus + "'" : ""}</span><br/>
            </div>
            <div className="col" style={{textAlign: "right"}}>
                <span style={{fontSize: 20}}></span><br/>
            </div>
        </div>
    }

    getPlayerSingleArea(player) {
        let backgroundColorScore = player.playerThrow ? "#99e600" : "#0059b3";
        let backgroundColorKasten = player.playerThrow ? "#0059b3" : "#2077ce";
        let boxShadow = player.playerThrow ? {boxShadow: "0 2px 35px #FFF"}: {};
        let textShadow = player.playerThrow ? {textShadow: "0 2px 35px #FFF"}: {};

        return <div className="col" style={{
            border: "1px solid gray",
            textAlign: "center",
            marginRight: 3,
            backgroundColor: backgroundColorKasten,
            color: "white"
        }}>
            <span style={{fontSize: 50, ...textShadow}}>{player.name}</span><br/>
            <span style={{fontSize: 30, ...textShadow}}>Legs: {player.legs}</span><br/><br/>
            <div className="grid" style={{width: 400, height: 100, margin: "auto"}}>
                <div className="col" style={{
                    border: "2px solid white",
                    borderRadius: 25,
                    fontSize: 50,
                    backgroundColor: backgroundColorScore,
                    zIndex: 1000000,
                    ...boxShadow

                }}>
                    {player.score}
                </div>
            </div>
        </div>
    }

    getPlayerArea() {
        return <div className="grid" style={{
            position: "fixed",
            top: 40,
            left: 0,
            height: 300,
            width: "100%",
            margin: 0,
            padding: 0,
            backgroundColor: "#004080"
        }}>
            {this.getPlayerSingleArea(this.state.scoreresult.player1)}
            {this.getPlayerSingleArea(this.state.scoreresult.player2)}
        </div>

    }

}

PublicScoringView.propTypes = {
    mandant: PropTypes.string,
    monitorkey: PropTypes.string,
};

export default PublicScoringView;
