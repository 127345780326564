import React from 'react';
import PropTypes from "prop-types";

import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";

import ConfigUtil from "../../../util/ConfigUtil";
import {PlayerSingleArea, ScoreArea, ScoreHeader, StatistikArea, WurfArea} from "../../score/ScoreComponents";
import {getModusNameById} from "../../general/GameModusComponent";

let stompClient = undefined;

class GameWatchView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gameid: props.gameid,
            playerid: undefined,
        };

        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.websocketConnect = this.websocketConnect.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.state.gameid);
    }

    componentWillUnmount() {
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
    }

    websocketConnect(gameid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {
        }; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/game/x01/" + gameid;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const json = JSON.parse(payload.body);
        let daten = {...json, playerid: json.players[0].playerid};
        this.setState(daten);
    }

    renderPC(anzScores, spielBeendet) {
        return <div
            style={{backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <div className="grid" style={{
                height: 60, margin: 0, marginBottom: 0, padding: 0, width: "100%", backgroundColor: "#e6f2ff",
                position: "fixed", top: 0, left: 0,
                zIndex: 600,
            }}>
                <div className="col" style={{fontSize: 20, textAlign: "right"}}>{this.getModusBeschreibung()}</div>
            </div>
            <>
                <div>
                    <div className="grid" style={{
                        height: 300,
                        width: "100%",
                        margin: 0,
                        marginTop: 50,
                        padding: 0,
                    }}>
                        <PlayerSingleArea player={this.getPlayerScoreDto(this.state.playerid, false)}
                                          togo={this.getToGo(this.state.playerid, false)}
                                          showCheckout={true}
                                          modusId={this.state.modusId}
                                          isLegBeginner={this.state.beginnerLegPlayerid === this.state.playerid}/>
                        <PlayerSingleArea player={this.getPlayerScoreDto(this.state.playerid, true)}
                                          togo={this.getToGo(this.state.playerid, true)}
                                          showCheckout={false}
                                          modusId={this.state.modusId}
                                          isLegBeginner={this.state.beginnerLegPlayerid !== this.state.playerid}/>
                    </div>
                </div>
                {!spielBeendet ?
                    this.getScoreArea(anzScores)
                    :
                    this.gameOverArea()
                }
            </>
        </div>
    }

    render() {
        if (this.state.players === undefined) {
            return <div>Dein Spiel ist noch nicht geladen...</div>;
        }
        let existiertEinGewinner = this.existiertEinGewinner();
        let existiertUnentschieden = this.existiertUnentschieden();
        const anzScoresPlayer1 = existiertEinGewinner || existiertUnentschieden ? 0 : this.getGameDetails(this.state.playerid, false).scoreDtos.length;
        const anzScoresPlayer2 = existiertEinGewinner || existiertUnentschieden ? 0 : this.getGameDetails(this.state.playerid, true).scoreDtos.length;
        const anzScores = anzScoresPlayer1 > anzScoresPlayer2 ? anzScoresPlayer1 : anzScoresPlayer2;

        return this.renderPC(anzScores, existiertEinGewinner || existiertUnentschieden);
    }

    getScoreArea(anzScores) {
        let legAveragePlayer = this.getGameDetails(this.state.playerid, false).legAverage;
        let legAverageGegner = this.getGameDetails(this.state.playerid, true).legAverage;
        let style = {borderLeft: "2px solid black", borderRight: "2px solid black"};
        return <div className="grid"
                    style={{backgroundColor: "#e6f2ff", padding: 0, margin: 0, height: "100%", width: "100%"}}>
            <StatistikArea orientation="left" player={this.getPlayerScoreDto(this.state.playerid, false)}
                           legAverage={legAveragePlayer}/>
            <div className="col" style={style}>
                <ScoreHeader fontSize={20}/>
                <div className="grid"
                     style={{backgroundColor: "#e6f2ff"}}>
                    <div className="col">
                        <ScoreArea key={"score_area_" + this.state.playerid}
                                   scores={this.getGameDetails(this.state.playerid, false).scoreDtos}
                                   playerid={this.state.playerid}
                                   changeAufnahmeAllowed={true} changeScore={this.changeScore} anzScores={anzScores}
                                   mobileDevice={false}/>
                    </div>
                    <div className="col-fixed" style={{width: 70, margin: 0, padding: 0}}>
                        <WurfArea anzScores={anzScores} mobileDevice={false}/>
                    </div>
                    <div className="col">
                        <ScoreArea key={"score_area_gegner"}
                                   scores={this.getGameDetails(this.state.playerid, true).scoreDtos}
                                   changeAufnahmeAllowed={false} anzScores={anzScores} mobileDevice={false}/>
                    </div>
                </div>
            </div>
            <StatistikArea orientation="right" player={this.getPlayerScoreDto(this.state.playerid, true)}
                           legAverage={legAverageGegner}/>
        </div>;
    }

    getGameDetails(playerid, opposite) {
        let playerScoreDto = this.getPlayerScoreDto(playerid, opposite);
        for (const gameDetailResultDto of playerScoreDto.gameDetailResultDtos) {
            if (!gameDetailResultDto.abgeschlossen) {
                return gameDetailResultDto;
            }
        }
        return undefined;
    }

    getPlayerScoreDtoByPlayers(players, playerid, opposite) {
        for (const player of players) {
            if (opposite && player.playerid !== playerid) {
                return player;
            }
            if (!opposite && player.playerid === playerid) {
                return player;
            }
        }
        return undefined;
    }

    getPlayerScoreDto(playerid, opposite) {
        return this.getPlayerScoreDtoByPlayers(this.state.players, playerid, opposite);
    }

    existiertUnentschieden() {
        return this.state.unentschieden !== null && this.state.unentschieden !== undefined && this.state.unentschieden;
    }

    existiertEinGewinner() {
        return this.state.gewonnenPlayerid !== null && this.state.gewonnenPlayerid !== undefined;
    }

    getToGo(playerid, opposite) {
        if (this.existiertEinGewinner() || this.existiertUnentschieden()) {
            return 0;
        }
        const gameDetail = this.getGameDetails(playerid, opposite);
        return gameDetail.scoreDtos[gameDetail.scoreDtos.length - 1].togo;
    }

    gameOverArea() {
        let label = "";
        if (this.existiertEinGewinner()) {
            label = "Spieler " + this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername + " hat gewonnen!";
        } else if (this.existiertUnentschieden()) {
            label = "Das Spiel endet unentschieden!";
        }

        return <div className="grid" style={{height: "100%", width: "100%", padding: 0, margin: 0}}>
            <StatistikArea player={this.getPlayerScoreDto(this.state.playerid, false)}/>
            <div className="col" style={{color: "#77b300", fontSize: 25}}>
                {label}
            </div>
            <StatistikArea player={this.getPlayerScoreDto(this.state.playerid, true)}/>
        </div>;
    }

    getModusBeschreibung() {
        let meetingKey = "";
        if (this.state.meetingKey !== null && this.state.meetingKey !== undefined) {
            meetingKey = "Key: " + this.state.meetingKey + " - ";
        }

        let modus = getModusNameById(this.state.modusId);
        if (this.state.firstToLegs) {
            return meetingKey + "Modus:" + modus + "- 'first to legs': " + this.state.firstToLegs;
        }
        return meetingKey + "Modus:" + modus + "- 'best of legs': " + this.state.bestOfLegs;
    }
}

GameWatchView.propTypes = {
    gameid: PropTypes.string.isRequired,
};

export default GameWatchView;
