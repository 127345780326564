import React from 'react'
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';
import {Button} from "primereact/button";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ConfigUtil from "../../../util/ConfigUtil";
import {FunGameShortComponent} from "./ligaComponents";
import ConfirmDialog from "../../general/ConfirmDialog";
import FetchUtil from "../../../util/FetchUtil";
import PropTypes from "prop-types";

class Games extends React.Component {

    constructor(props) {
        super(props);

        this.state = {selectedHerausforderung: undefined};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.save = this.save.bind(this);
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    save() {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.fungame + "/" + this.state.selectedHerausforderung + "/confirminvitation",
            {},
            () => {
                this.showInfo("Du hast die Einladung angenommen.");
                this.setState({selectedHerausforderung: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({selectedHerausforderung:false});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({selectedHerausforderung:false});
            }
        );
    }

    getRows() {
        if(this.props.herausforderungen === null || this.props.herausforderungen === undefined) {
            return "Aktuell keine Herausforderungen vorhanden...";
        }
        let rows = [];
        for (const herausforderung of this.props.herausforderungen) {
            if (herausforderung.spielerId !== this.props.userId) {
                rows.push(
                    <div onClick={() => this.setState({selectedHerausforderung:herausforderung.id})} style={{cursor:"pointer", marginBottom:20}}
                    key={"key_games_herausforderung_" + herausforderung.id}>
                        <FunGameShortComponent funGame={herausforderung} userId={this.props.userId}/>
                    </div>);
            }
        }
        return rows;
    }

    render() {
        return <div className="col-12 md:col-12 lg:col-3" style={{padding:10}}>
            <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid #ff1a75"}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <h3>Spielen</h3>
                <div style={{marginLeft:10}}>
                    <NavLink to={NAVIGATION_ITEM.GAME_OVERVIEW.route} style={{textDecoration: "none"}}>
                        <Button label="Spiel erstellen" style={{width:"100%", height:50}}/>
                    </NavLink>
                </div>
                <div style={{marginLeft:10, marginTop:20}}>
                    <NavLink to={NAVIGATION_ITEM.GAMINGAREA.route} style={{textDecoration: "none"}}>
                        <Button label="Gaming-Area" style={{width:"100%", height:50}}/>
                    </NavLink>
                </div>
                <h4>Öffentliche Herausforderungen</h4>
                <div style={{marginLeft:10, marginBottom:10, fontSize:12}}>
                    Mit Klick auf das Spiel nimmst du die Herausforderung an.
                </div>
                {this.getRows()}
            </div>
            <ConfirmDialog visible={this.state.selectedHerausforderung !== undefined}
                                       callBackOnNein={() => this.setState({
                                           selectedHerausforderung: undefined
                                       })}
                                       callBackOnJa={() => this.save()}
                                       header="Herausforderung annehmen"
                                       textOben="Sobald du die Herausforderung annimmst, wird dir ein Spiel auf dem Dashboard oder in deinem Profil angezeigt."
                                       textUnten="Möchtest du die Herausforderung wirklich annehmen?"/>
        </div>
    }
}

Games.propTypes = {
    userId: PropTypes.string.isRequired,
    herausforderungen: PropTypes.array,
}

export default Games;
