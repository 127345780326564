import {connect} from 'react-redux'
import ScorerRedirect from "../../components/scorer/ScorerRedirect";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScorerRedirect)
