import React, {useState} from 'react'
import {TabPanel, TabView} from "primereact/tabview";
import RanglisteTypView from "./rangliste/RanglisteTypView";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function RanglistePunkteDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const dialogFooter = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()}
                    className="p-button-secondary"/>
        </div>
    );
    return <DHDialog onClose={() => onClose()} title="Rangliste Punkte Einstellungen" show={true} buttons={dialogFooter}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>
            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                <TabPanel header="KO 4">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko4" anzahlPersonen={4} plaetzeAuswahl={[1,2,3]} />
                </TabPanel>
                <TabPanel header="KO 8">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko8" anzahlPersonen={8} plaetzeAuswahl={[1,2,3,5]}/>
                </TabPanel>
                <TabPanel header="KO 16">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko16" anzahlPersonen={16} plaetzeAuswahl={[1,2,3,5,9]}/>
                </TabPanel>
                <TabPanel header="KO 32">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko32" anzahlPersonen={32} plaetzeAuswahl={[1,2,3,5,9,17]}/>
                </TabPanel>
                <TabPanel header="KO 64">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko64" anzahlPersonen={64} plaetzeAuswahl={[1,2,3,5,9,17,33]}/>
                </TabPanel>
                <TabPanel header="KO 128">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko128" anzahlPersonen={128} plaetzeAuswahl={[1,2,3,5,9,17,33,65]}/>
                </TabPanel>
                <TabPanel header="KO 256">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="ko256" anzahlPersonen={256} plaetzeAuswahl={[1,2,3,5,9,17,33, 65,129]}/>
                </TabPanel>
                <TabPanel header="DKO 4">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko4" anzahlPersonen={4} plaetzeAuswahl={[1,2,3,4]}/>
                </TabPanel>
                <TabPanel header="DKO 8">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko8" anzahlPersonen={8} plaetzeAuswahl={[1,2,3,4,5,7]}/>
                </TabPanel>
                <TabPanel header="DKO 16">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko16" anzahlPersonen={16} plaetzeAuswahl={[1,2,3,4,5,7,9,13]} />
                </TabPanel>
                <TabPanel header="DKO 32">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko32" anzahlPersonen={32} plaetzeAuswahl={[1,2,3,4,5,7,9,13,17,25]}/>
                </TabPanel>
                <TabPanel header="DKO 64">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko64" anzahlPersonen={64} plaetzeAuswahl={[1,2,3,4,5,7,9,13,17,25,33,49]}/>
                </TabPanel>
                <TabPanel header="DKO 128">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko128" anzahlPersonen={128} plaetzeAuswahl={[1,2,3,4,5,7,9,13,17,25,33,49,65,97]} />
                </TabPanel>
                <TabPanel header="DKO 256">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="dko256" anzahlPersonen={256} plaetzeAuswahl={[1,2,3,4,5,7,9,13,17,25,33,49,65,97,129,193]} />
                </TabPanel>
                <TabPanel header="Liga">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="liga" anzahlPersonen={350} plaetzeAuswahl={[1,2,3,4,5,6,7,8,9,10]}/>
                </TabPanel>
                <TabPanel header="Gruppenphase">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="group" anzahlPersonen={350} plaetzeAuswahl={[1,2,3,4]}/>
                </TabPanel>
                <TabPanel header="Round Robin">
                    <RanglisteTypView eventOperatorId={eventOperatorId} clearMessage={() => setErrorMessage(undefined)} showError={(message) => setErrorMessage(message)} showInfo={(message) => setInfoMessage(message)} typ="roundrobin" anzahlPersonen={350} plaetzeAuswahl={[1,2,3,4,5,6,7,8,9,10]}/>
                </TabPanel>
            </TabView>
        </div>
    </>}/>
}
