import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Chart} from "primereact/chart";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 400,
    },
});


function getRows(props) {
    let tableRows = [];
    if( props.headToAllPlayerDtos !== undefined && props.headToAllPlayerDtos !== null) {
        for (let i = 0; i < props.headToAllPlayerDtos.length; i++) {
            let modolo = i % 2;
            let currentStyle = modolo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let dto = props.headToAllPlayerDtos[i];

            let gewonneneLegs = 0;
            let verloreneLegs = 0;
            let gewonneneSpiele = 0;
            let verloreneSpiele = 0;
            let stringErgebnisse =  [];
            for (let j = 0; j < dto.ergebnisse.length; j++) {
                let ergebnis = dto.ergebnisse[j];
                gewonneneLegs = gewonneneLegs + ergebnis.anzahlGesuchterSpieler;
                verloreneLegs = verloreneLegs + ergebnis.anzahlGegenspieler;
                if( ergebnis.anzahlGesuchterSpieler > ergebnis.anzahlGegenspieler) {
                    gewonneneSpiele++;
                } else {
                    verloreneSpiele++;
                }
                if( j < 5) {
                    // Ergebnisse sollen nur 5 angezeigt werden.
                    stringErgebnisse.push(<div>{ergebnis.datum} - {ergebnis.anzahlGesuchterSpieler}:{ergebnis.anzahlGegenspieler}</div>);
                }
            }

            const dataLegs = {
                labels: ["G - " + gewonneneLegs, "V - " + verloreneLegs],
                datasets: [
                    {
                        data: [gewonneneLegs, verloreneLegs],
                        backgroundColor: [
                            "#008000",
                            "#ff0000",
                        ],
                        hoverBackgroundColor: [
                            "#008000",
                            "#ff0000",
                        ]
                    }]
            };
            const dataSpiele = {
                labels: ["G - " + gewonneneSpiele, "V - " + verloreneSpiele],
                datasets: [
                    {
                        data: [gewonneneSpiele, verloreneSpiele],
                        backgroundColor: [
                            "#008000",
                            "#ff0000",
                        ],
                        hoverBackgroundColor: [
                            "#008000",
                            "#ff0000",
                        ]
                    }]
            };
            tableRows.push(<TableRow key={dto.nameGegenspieler + "_row"} style={currentStyle}>
                <TableCell key={dto.nameGegenspieler + "_1"} style={{borderRight:"2px solid #f4f4f4", verticalAlign:"top"}}>{dto.nameGegenspieler}</TableCell>
                <TableCell key={dto.nameGegenspieler + "_2"} style={{borderRight:"2px solid #f4f4f4", verticalAlign:"top"}}>{stringErgebnisse}</TableCell>
                <TableCell key={dto.nameGegenspieler + "_2"} style={{borderRight:"2px solid #f4f4f4", verticalAlign:"top"}}><Chart type="doughnut" data={dataLegs} width={100} height={100} /></TableCell>
                <TableCell key={dto.nameGegenspieler + "_2"} style={{borderRight:"2px solid #f4f4f4", verticalAlign:"top"}}><Chart type="doughnut" data={dataSpiele} width={100} height={100} /></TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function aSimpleTable(props) {
    const classes = useStyles();
    let tableRows = getRows(props);
    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Gegenspieler</TableCell>
                        <TableCell style={{fontSize: "14px", minWidth:"150px", width:"150px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Ergebnisse</TableCell>
                        <TableCell style={{fontSize: "14px", minWidth:"200px", width:"200px",backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Legs</TableCell>
                        <TableCell style={{fontSize: "14px", minWidth:"200px", width:"200px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Spiele</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}