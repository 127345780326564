import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PlayerUtil from "../../util/PlayerUtil";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 400,
    },
});

function getRows(props) {
    let tableRows = [];
    if( props.rangliste !== undefined) {
        let style = {borderRight:"2px solid #f4f4f4", textAlign:"center"};
        for (let i = 0; i < props.rangliste.length; i++) {
            let tmpRow = props.rangliste[i];
            let modolo = i % 2;
            let currentStyle = modolo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let spielername = PlayerUtil.anzeigeNameZumSpieler(tmpRow.playerDto);

            tableRows.push(<TableRow key={tmpRow.playerid + "_row"} style={currentStyle}>
                <TableCell key={tmpRow.playerid + "_1"} style={{width:"50px", borderRight:"2px solid #f4f4f4"}}>
                    {"" + (i+1) + "."}
                </TableCell>
                <TableCell key={tmpRow.playerid + "_2"} style={{borderRight:"2px solid #f4f4f4"}}>{spielername}</TableCell>
                <TableCell key={tmpRow.playerid + "_3"} style={style}>{tmpRow.punkte}</TableCell>
                <TableCell key={tmpRow.playerid + "_4"} style={style}>{tmpRow.legDifferenz}</TableCell>
                <TableCell key={tmpRow.playerid + "_5"} style={style}>{tmpRow.anzahlLegsGewonnen + ":" + tmpRow.anzahlLegsVerloren}</TableCell>
                <TableCell key={tmpRow.playerid + "_6"} style={style}>{tmpRow.anzahlspiele}</TableCell>
                <TableCell key={tmpRow.playerid + "_7"} style={style}>{tmpRow.gewonnenespiele}</TableCell>
                <TableCell key={tmpRow.playerid + "_8"} style={style}>{tmpRow.verlorenespiele}</TableCell>
                <TableCell key={tmpRow.playerid + "_9"} style={style}>{tmpRow.gewonnenespieleInProzent}</TableCell>
                <TableCell key={tmpRow.playerid + "_10"} style={style}>{tmpRow.anwesend}</TableCell>
                <TableCell key={tmpRow.playerid + "_11"} style={style}>{tmpRow.anwesendInProzent}</TableCell>
                <TableCell key={tmpRow.playerid + "_12"} style={style}>{tmpRow.anzahl171}</TableCell>
                <TableCell key={tmpRow.playerid + "_13"} style={style}>{tmpRow.anzahl180}</TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function aSimpleTable(props) {
    const classes = useStyles();

    let tableRows = getRows(props);

    let headerTableCellStyle = {fontSize: "14px", width:"50px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4",cursor:"pointer"};
    let sortIcon = <i className="pi pi-sort-amount-down"/>;
    let sortFunc = props.callbackSort;
    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={headerTableCellStyle}>Platz</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Spieler</TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("default")}>
                            {props.sort === "default" ? sortIcon : ""}Punkte
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("legdifferenz")}>
                            {props.sort === "legdifferenz" ? sortIcon : ""}Diff.Legs
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("legs")}>
                            {props.sort === "legs" ? sortIcon : ""}Legs
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("spiele")}>
                            {props.sort === "spiele" ? sortIcon : ""}Spiele
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("gewonnene_spiele")}>
                            {props.sort === "gewonnene_spiele" ? sortIcon : ""}G.Spiele
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("verlorene_spiele")}>
                            {props.sort === "verlorene_spiele" ? sortIcon : ""}V.Spiele
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("gewonnen_spiele_in_prozent")}>
                            {props.sort === "gewonnen_spiele_in_prozent" ? sortIcon : ""}G.Spiele in %
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("anwesend")}>
                            {props.sort === "anwesend" ? sortIcon : ""}Anwesend
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("anwesend_in_prozent")}>
                            {props.sort === "anwesend_in_prozent" ? sortIcon : ""}Anwesend in %
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("171er")}>
                            {props.sort === "171er" ? sortIcon : ""}171er
                        </TableCell>
                        <TableCell style={headerTableCellStyle} onClick={() => sortFunc("180er")}>
                            {props.sort === "180er" ? sortIcon : ""}180er
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}