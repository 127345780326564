import React from 'react'
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {GameBestOfLegsComponent, GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {GameModusComponent} from "../../general/GameModusComponent";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import {GameSetsComponent} from "../../general/GameSetsComponent";

function TournamentX01Area({showSets, showBestOfLegs, showPflichtfelder, sets, firstToLegs, bestOfLegs, modusId, startpunkte, onSetsChanged, onFirstToLegsChanged, onBestOfLegsChanged, onModusIdChanged, onStartpunkteChanged}) {

    return <>
        <div className="grid" style={{marginBottom:20}}>
            <div className="col">
                <Button label="Standard setzen" onClick={() => {
                    if( showSets ) {
                        onSetsChanged(1);
                    }
                    if( showBestOfLegs ) {
                        onBestOfLegsChanged(5);
                        onFirstToLegsChanged(undefined);
                    } else {
                        if( onBestOfLegsChanged !== undefined) {
                            onBestOfLegsChanged(undefined);
                        }
                        onFirstToLegsChanged(3);
                    }
                    onStartpunkteChanged(501);
                    onModusIdChanged("double_out");
                }}
                />
            </div>
        </div>
        {showSets &&
            <div className="grid">
                <div className="col-fixed" style={{width: "120px",  color: showPflichtfelder && (sets === undefined || sets === null || sets === "") ? "red" : ""}}>first to sets: {showPflichtfelder && "*" }</div>
                <div className="col">
                    <GameSetsComponent sets={sets}
                                       onChange={(sets) => onSetsChanged(sets)}/>
                </div>
            </div>
        }
        <div className="grid">
            <div className="col-fixed" style={{width: "120px",  color: bestOfLegs === undefined && showPflichtfelder && (firstToLegs === undefined || firstToLegs === null || firstToLegs === "") ? "red" : ""}}>first to
                legs: {bestOfLegs === undefined && showPflichtfelder && "*"}</div>
            <div className="col">
                <GameFirstToLegsComponent firstToLegs={firstToLegs}
                                          onChange={(firstToLegs) => {
                                              onFirstToLegsChanged(firstToLegs);
                                              {firstToLegs !== undefined && onBestOfLegsChanged !== undefined && onBestOfLegsChanged(undefined)};
                                          }}/>
            </div>
        </div>
        {showBestOfLegs &&
            <div className="grid">
                <div className="col-fixed" style={{width: "120px",  color: firstToLegs === undefined && showPflichtfelder && (bestOfLegs === undefined || bestOfLegs === null || bestOfLegs === "") ? "red" : ""}}>best of
                    legs: {firstToLegs === undefined && showPflichtfelder && "*"}</div>
                <div className="col">
                    <GameBestOfLegsComponent bestOfLegs={bestOfLegs}
                                              onChange={(bestOfLegs) => {
                                                  onBestOfLegsChanged(bestOfLegs);
                                                  {bestOfLegs !== undefined && onFirstToLegsChanged(undefined)};
                                              }}/>
                </div>
            </div>
        }
        <div className="grid">
            <div className="col-fixed" style={{width: "120px",  color: showPflichtfelder && (modusId === undefined || modusId === null || modusId === "") ? "red" : ""}}>Modus: {showPflichtfelder && "*" }</div>
            <div className="col">
                <GameModusComponent modusId={modusId}
                                    onChange={(modusId) => onModusIdChanged(modusId)}/>
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: "120px",  color: showPflichtfelder && (startpunkte === undefined || startpunkte === null || startpunkte === "") ? "red" : ""}}>Startpunkte: {showPflichtfelder && "*" }</div>
            <div className="col">
                <GameStartpunkteComponent startpunkte={startpunkte}
                                          onChange={(startpunkte) => onStartpunkteChanged(startpunkte)}/>
            </div>
        </div>
    </>
}

TournamentX01Area.propTypes = {
    sets: PropTypes.number,
    firstToLegs: PropTypes.number,
    bestOfLegs: PropTypes.number,
    modusId: PropTypes.string,
    startpunkte: PropTypes.number,
    onSetsChanged: PropTypes.func,
    onFirstToLegsChanged: PropTypes.func.isRequired,
    onBestOfLegsChanged: PropTypes.func,
    onModusIdChanged: PropTypes.func.isRequired,
    onStartpunkteChanged: PropTypes.func.isRequired,
    showSets: PropTypes.bool.isRequired,
    showBestOfLegs: PropTypes.bool.isRequired,
    showPflichtfelder: PropTypes.bool.isRequired,
};

export default TournamentX01Area
