import React from 'react'
import LiveView from "../liveview/LiveView";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import ConfigUtil from "../../../../util/ConfigUtil";

export default function PublicEventOperatorLiveTab({eventOperatorId}) {
    const url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR_LIVE_VIEW.route + "?eventoperatorid=" + eventOperatorId;
    return <div>
        <div style={{fontSize:20, margin:20, color:"white"}}>
            Hinweis: Klick aufs Spiel für Detailansicht
        </div>
        <div style={{fontSize:16, margin:20, color:"white", cursor:"pointer"}}
            onClick={() => window.open(url, "_self")}>
            <i className="pi pi-desktop" style={{fontSize: 30, marginRight: 20, color: "white", cursor: "pointer"}}
               onClick={() => window.open(url, "_self")}/>
            Vollbild
        </div>
        <LiveView eventOperatorId={eventOperatorId} showEmbedded={true}/>
    </div>
}

