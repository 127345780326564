import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import GameVideo from "../../../components/views/training/GameVideo";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const username = urlParams.get('username');
    const gameId = urlParams.get('gameid');
    let testhinweis = urlParams.get('testhinweis');
    testhinweis = testhinweis === null || testhinweis === undefined || testhinweis === "false" || testhinweis === false ? false : true;
    return {username: username, gameid: gameId, testhinweis: testhinweis};
};

const mapDispatchToProps = dispatch => ({
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GameVideo))