import React from 'react';
import {isVerliererseiteByFeld} from "./TurnierbaumHelper";

export default function area(props) {
    if (props.feld.model.startknoten) {
        return [];
    }
    let parent1Feld = undefined;
    let parent2Feld = undefined;
    for (const tmpFeld of props.felder) {
        if (props.feld.model.parent1 !== undefined && tmpFeld.feld === props.feld.model.parent1) {
            parent1Feld = tmpFeld;
        }
        if (props.feld.model.parent2 !== undefined && tmpFeld.feld === props.feld.model.parent2) {
            parent2Feld = tmpFeld;
        }
    }
    let objekte = [];
    if (isVerliererseiteByFeld(props.feld.feld)) {
        // Verliererseite
        let beideLinienZeichnen = parent1Feld !== undefined && parent2Feld !== undefined;
        beideLinienZeichnen = beideLinienZeichnen && (props.feld.feld.length === 2 || props.feld.feld.startsWith("100-") || isVerliererseiteByFeld(parent2Feld.feld));
        if (beideLinienZeichnen) {
            objekte.push(<hr key={"key_linie_1_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: parent1Feld.y + 15, left: parent1Feld.x - 10, width: 10, color:"red"}}/>);
            objekte.push(<hr key={"key_linie_2_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: parent2Feld.y + 15, left: parent2Feld.x - 10, width: 10, color:"blue"}}/>);
            objekte.push(<hr key={"key_linie_3_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: props.feld.y + 15, left: props.feld.x + 200, width: 10, color:"yellow"}}/>);

            let rotate = "rotate(90deg)";
            let width = (parent2Feld.y + 15) - (parent1Feld.y + 15) + 1.5;
            objekte.push(<hr key={"key_linie_4_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                             style={{
                                 top: parent1Feld.y + 15,
                                 left: parent1Feld.x - 10,
                                 width: width,
                                 transform: rotate
                             }}/>);
        } else {
            objekte.push(<hr key={"key_linie_5_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: props.feld.y + 15, left: props.feld.x + 200, width: 20}}/>);

            if (parent2Feld.model.ueberschrift !== undefined) {
                let rotate = "rotate(50deg)";
                objekte.push(<hr key={"key_linie_6_verlierer_" + props.feld.feld} className="turnierbaumlinie"
                                 style={{top: props.feld.y + 40, left: props.feld.x + 200, width: 62, transform: rotate}}/>);
                objekte.push(<div key={"key_linie_7_verlierer_" + props.feld.feld} style={{
                    position: "absolute",
                    top: props.feld.y + 100,
                    left: props.feld.x + 240,
                    color: "gray",
                }}>{parent2Feld.model.ueberschrift}</div>);
            }

        }
    } else {
        // gewinnerseite
        let beideLinienZeichnen = parent1Feld !== undefined && parent2Feld !== undefined;
        beideLinienZeichnen = beideLinienZeichnen && !isVerliererseiteByFeld(parent2Feld.feld);

        if (beideLinienZeichnen) {
            objekte.push(<hr key={"key_linie_1_gewinner_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: parent1Feld.y + 15, left: parent1Feld.x + 200, width: 10, color:"red"}}/>);
            objekte.push(<hr key={"key_linie_2_gewinner_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: parent2Feld.y + 15, left: parent2Feld.x + 200, width: 10, color:"yellow"}}/>);
            objekte.push(<hr key={"key_linie_3_gewinner_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: props.feld.y + 15, left: props.feld.x - 10, width: 10, color:"greem"}}/>);

            let rotate = "rotate(90deg)";
            let width = (parent2Feld.y + 15) - (parent1Feld.y + 15) + 1.5;
            objekte.push(<hr key={"key_linie_4_gewinner_" + props.feld.feld}  className="turnierbaumlinie"
                             style={{
                                 top: parent1Feld.y + 15,
                                 left: props.feld.x - 10,
                                 width: width,
                                 transform: rotate
                             }}/>);

        } else {
            objekte.push(<hr key={"key_linie_5_gewinner_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: props.feld.y + 15, left: props.feld.x - 20, width: 20}}/>);
        }
        if (props.feld.model.beschreibungUnten !== undefined && props.feld.model.beschreibungUnten !== null) {
            let rotate = "rotate(-50deg)";
            objekte.push(<hr key={"key_linie_6_gewinner_" + props.feld.feld} className="turnierbaumlinie"
                             style={{top: props.feld.y + 94, left: props.feld.x + 20, width: 62, transform: rotate}}/>);
            // im dark modus keine color gesetzt light color: black
            objekte.push(<div key={"key_linie_7_gewinner_" + props.feld.feld} style={{
                position: "absolute",
                top: props.feld.y + 110,
                left: props.feld.x - 60,
                color: "gray",
            }}>{props.feld.model.beschreibungUnten}</div>);
        }
    }
    return objekte;
}
