import React from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";

const SETS_ZUR_AUSWAHL = [
    {name: 'Auswahl löschen', code: undefined},
    {name: '1', code: 1},
    {name: '2', code: 2},
    {name: '3', code: 3},
    {name: '4', code: 4},
    {name: '5', code: 5},
    {name: '6', code: 6},
    {name: '7', code: 7},
    {name: '8', code: 8},
    {name: '9', code: 9},
];


export function GameSetsComponent({sets, onChange}) {
    return <DHSelectionComponent value={sets} onChange={onChange} values={SETS_ZUR_AUSWAHL} title="Sets"/>
}