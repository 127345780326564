import React from 'react'
import PropTypes from "prop-types";

import {NavLink} from "react-router-dom";

class Kachel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            icon: "images/kachel/" + this.props.icon + ".png"
        };
    }

    render() {
        return <NavLink to={this.props.navlinkto} style={{textDecoration:"none"}}>
            <div className="grid kachel"
                 onMouseLeave={() => this.setState({icon: "images/kachel/" + this.props.icon + ".png"})}
                 onMouseOver={() => this.setState({icon: "images/kachel/" + this.props.icon + "_blue.png"})}
                 style={{
                padding: 0,
                marginTop: "5px",
                marginLeft: "10px",
                marginRight: 10,
            }}>
                <div className="col-fixed" style={{width:30, backgroundColor: this.props.color, padding:20}}></div>
                <div className="col">
                    <div style={{marginRight: "20px", marginLeft: "30px", textAlign: "center", align: "center"}}>
                        {this.props.fixicon !== undefined ?
                            <img alt="" style={{marginTop: "20px", marginRight: "20px"}}
                                 src={"images/kachel/" + this.props.fixicon + ".png"}/>
                            :
                            <div/>
                        }
                        <img alt="" style={{marginTop: "20px"}} src={this.state.icon}/>
                    </div>
                    <div style={{
                        marginTop: "10px",
                        height: 57,
                        textAlign: "center",
                        align:"bottom", verticalAlign:"bottom"
                    }}>{this.props.text}</div>
                </div>
            </div>
        </NavLink>
    }
}

Kachel.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    fixicon: PropTypes.string,
    navlinkto: PropTypes.string,
    color: PropTypes.string.isRequired,
};

export default Kachel;