import React, {useEffect, useState} from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";

export function LigaSpieltagPaarkreuzsystemComponent({eventOperatorId, counter, selectedPaarkreuzsystemId, disabled, onChange, showChangeDialog}) {

    const [paarkreuzsysteme, setPaarkreuzsysteme] = useState([]);

    useEffect(() => {
        if (!eventOperatorId) {
            return;
        }
        ladePaarkreuzsyteme();
    }, [eventOperatorId, counter]);


    const ladePaarkreuzsyteme = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/paarkreuzsysteme/" + eventOperatorId,
            dtos => {
                setPaarkreuzsysteme(dtos);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const getPaarkreuzsystemeValues = () => {
        let values = [];
        values.push({name: showChangeDialog ? 'Neu anlegen' : "Auswahl löschen", code: undefined});
        for (const element of paarkreuzsysteme) {
            values.push({name: element.bezeichnung + " (" + element.modus +")", code: element.id});
        }
        return values;
    }

    return <DHSelectionComponent value={selectedPaarkreuzsystemId} onChange={(id) => {
        let system = paarkreuzsysteme.filter(t => t.id === id)[0];
        onChange(system);
    }} disabled={disabled} values={getPaarkreuzsystemeValues()} title="Paarkreuzsystem"/>
}
