import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {getCheckout} from "../views/training/checkouts"

export function PlayerSingleAreaSmall(props) {
    const player = props.player;

    let backgroundColorScore = player.active ? "#77b300" : "#0059b3";
    let backgroundColorKasten = player.active ? "#0059b3" : "#2077ce";
    let boxShadow = player.active ? {boxShadow: "0 2px 35px #FFF"} : {};
    let textShadow = player.active ? {textShadow: "0 2px 35px #FFF"} : {};
    let checkout = props.showCheckout && props.modusId === "double_out" ? getCheckout(props.togo) : "";

    return <div className="col-12 md:col-6 lg:col-6" style={{
        border: "1px solid gray",
        textAlign: "left",
        marginRight: 0,
        marginLeft: 0,
        backgroundColor: backgroundColorKasten,
        color: "white",
        padding: 5,
        height: 160,
    }}>

        <span style={{fontSize: 20, ...textShadow}}>{player.username !== null ? player.username : player.playername}</span><br/>
        <span style={{fontSize: 18, ...textShadow}}>Legs: {player.gewonneneLegs}</span>
        <span style={{fontSize: 14, ...textShadow, marginLeft: 40}}>Average: {player.average} / Leg Avg.: {props.legAverage}</span>
        <br/>
        {checkout !== "" &&
        <div style={{marginTop: 0, marginBottom:10}}>
            <span style={{fontSize: 15, ...textShadow}}>Checkout: {checkout}</span>
        </div>
        }
        {checkout === "" &&
        <div className="grid" style={{padding: 0, margin: 0}}>
            <div className="col" style={{padding: 0, margin: 0, marginBottom: 20}}>
                <span style={{fontSize: 15, ...textShadow, marginRight: 10}}>Ton:{player.ton}</span>
                <span style={{fontSize: 15, ...textShadow, marginRight: 10}}>100+:{player._100Plus}</span>
                <span style={{fontSize: 15, ...textShadow, marginRight: 10}}>140+:{player._140Plus}</span>
                <span style={{fontSize: 15, ...textShadow, marginRight: 10}}>171:{player._171er}</span>
                <span style={{fontSize: 15, ...textShadow, marginRight: 10}}>180:{player._180er}</span>
            </div>
        </div>
        }
        <div className="grid" style={{width: 200, height: 30, margin: "auto", textAlign: "center", padding: 0}}>
            <div className="col" style={{
                border: "2px solid white",
                borderRadius: 25,
                fontSize: 25,
                backgroundColor: backgroundColorScore,
                ...boxShadow

            }}>
                {props.isLegBeginner &&
                <img src="/images/dart_single.png" alt="Legbeginner" style={{marginRight: 20, height: 25, width: 25}}/>}
                {props.togo}
                {props.isLegBeginner &&
                <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 25, width: 25}}/>}
            </div>
        </div>
    </div>
}

export function PlayerHeaderArea(props) {
    const player = props.player;

    let backgroundColorScore = player.active ? "#77b300" : "#0059b3";
    let textShadow = player.active ? {textShadow: "0 2px 35px #FFF", color: "black"} : {};
    const dartpngBackground = player.active ? {backgroundColor: "#99e600"} : {backgroundColor: "#1aa3ff"};

    let legSetBez = "";
    if( props.isSetModus) {
        legSetBez = "Sets: " + player.gewonneneSets + " Legs: " + player.gewonneneLegs;
    } else {
        legSetBez = "Legs: " + player.gewonneneLegs;
    }
    return <div className="grid align-center vertical-container" style={{
        border: "1px solid gray",
        textAlign: "center",
        backgroundColor: backgroundColorScore,
        color: "white", float: "left", padding: 0, margin: 0, height: "100%",
        marginRight: 10,
    }}>

        {props.isLegBeginner &&
        <div style={{float: "left", width: 40, height: "100%", padding: 0, margin: 0, ...dartpngBackground}}><img
            src="/images/dart_single.png" alt="Legbeginner" style={{margin: 2, height: 35, width: 35}}/></div>
        }
        <span style={{fontSize: 20, ...textShadow, marginLeft: 10}}>{props.label} togo: {props.togo}</span>
        <span style={{fontSize: 20, ...textShadow, marginLeft: 30, marginRight: 10}}>{legSetBez}</span>
    </div>
}

export function PlayerSingleAreaHalberBildschirm(props) {
    const player = props.player;

    let backgroundColorScore = player.active ? "#77b300" : "#0059b3";
    let backgroundColorKasten = player.active ? "#0059b3" : "#2077ce";
    let boxShadow = player.active ? {boxShadow: "0 2px 35px #FFF"} : {};
    let textShadow = player.active ? {textShadow: "0 2px 35px #FFF"} : {};
    let checkout = props.showCheckout && (props.modusId === "double_out" || props.modusId === "double_in_double_out" ) ? getCheckout(props.togo) : "";

    let legSetBez = "";
    if( props.isSetModus) {
        legSetBez = "Sets: " + player.gewonneneSets + " Legs: " + player.gewonneneLegs;
    } else {
        legSetBez = "Legs: " + player.gewonneneLegs;
    }

    return <div className="grid" style={{
        width: "100%",
        margin: 0,
        padding: 0,
        backgroundColor: backgroundColorKasten,
        border: "1px solid gray",
        color: "white"
    }}>
        <div className="col" style={{
            textAlign: "left",
            marginRight: 3,
        }}>
            <div style={{marginTop: 5}}>
                <span style={{fontSize: 30, ...textShadow}}>{player.username !== null ? player.username : player.playername}</span>
            </div>
            <div style={{marginTop: 5}}>
                <span style={{fontSize: 25, ...textShadow}}>{legSetBez}</span>
            </div>
            <div style={{marginTop: 5}}>
                <span
                    style={{fontSize: 15, ...textShadow}}>Average: {player.average} / Leg.Avg: {props.legAverage} / Ton: {player.ton} / 100+: {player._100Plus} / 140+: {player._140Plus} / 171: {player._171er} / 180: {player._180er}</span>
            </div>
            {(checkout !== "") &&
            <div style={{marginTop: 30}}>
                <span style={{fontSize: 20, ...textShadow}}>Checkout: {checkout}</span>
            </div>
            }
        </div>
        <div className="col-fixed" style={{
                textAlign: "center",
                marginRight: 3,
                marginTop:10,
                width: 220,
            }}>
            <div className="grid" style={{width: 200, height: 50, margin: "auto"}}>
                <div className="col" style={{
                    border: "2px solid white",
                    borderRadius: 25,
                    fontSize: 30,
                    backgroundColor: backgroundColorScore,
                    ...boxShadow

                }}>
                    {props.isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner"
                                                 style={{marginRight: 20, height: 20, width: 20}}/>}
                    <span style={player.active ? {color:"black"} : {}}>{props.togo}</span>
                    {props.isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner"
                                                 style={{marginLeft: 20, height: 20, width: 20}}/>}
                </div>
            </div>
        </div>
    </div>
}

export function PlayerSingleArea(props) {
    const player = props.player;

    let backgroundColorScore = player.active ? "#77b300" : "#0059b3";
    let backgroundColorKasten = player.active ? "#0059b3" : "#2077ce";
    let boxShadow = player.active ? {boxShadow: "0 2px 35px #FFF"} : {};
    let textShadow = player.active ? {textShadow: "0 2px 35px #FFF"} : {};
    let checkout = props.showCheckout && (props.modusId === "double_out" || props.modusId === "double_in_double_out" ) ? getCheckout(props.togo) : "";

    let legSetBez = "";
    if( props.isSetModus) {
        legSetBez = "Sets: " + player.gewonneneSets + " Legs: " + player.gewonneneLegs;
    } else {
        legSetBez = "Legs: " + player.gewonneneLegs;
    }

    return <div className="col" style={{
        border: "1px solid gray",
        textAlign: "center",
        marginRight: 3,
        backgroundColor: backgroundColorKasten,
        color: "white"
    }}>
        <span style={{fontSize: 50, ...textShadow}}>{player.username !== null ? player.username : player.playername}</span><br/>
        <span style={{fontSize: 30, ...textShadow}}>{legSetBez}</span><br/><br/>
        <div className="grid" style={{width: 400, height: 100, margin: "auto"}}>
            <div className="col" style={{
                border: "2px solid white",
                borderRadius: 25,
                fontSize: 50,
                backgroundColor: backgroundColorScore,
                ...boxShadow

            }}>
                {props.isLegBeginner &&
                <img src="/images/dart_single.png" alt="Legbeginner" style={{marginRight: 20, height: 40, width: 40}}/>}
                <span style={player.active ? {color:"black"} : {}}>{props.togo}</span>
                {props.isLegBeginner &&
                <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
            </div>
        </div>
        {checkout !== "" &&
            <div style={{marginTop: 30}}>
                <span style={{fontSize: 20, ...textShadow}}>Checkout: {checkout}</span>
            </div>
        }
    </div>
}

const wurfAreaRows = (props, lineStyle) => {
    let counter = 3;
    let rows = [];
    for (let i = 0; i < props.anzScores; i++) {
        let modulo = i % 2;
        let currentStyle = modulo === 0 ? {...lineStyle, backgroundColor: "#e6eeff"} : {
            ...lineStyle,
            backgroundColor: "#b3ccff"
        };
        rows.push(<div key={"wurf_area_" + i} className="grid" style={currentStyle}>
            <div className="col"
                 style={{padding: 0, margin: "auto", verticalAlign: "middle"}}>{i !== 0 ? counter : ""}</div>
        </div>);
        if (i !== 0) {
            counter = counter + 3;
        }
    }
    return rows;
}

export function WurfArea(props) {
    let lineStyle = {
        verticalAlign: "middle",
        height: props.mobileDevice ? 40 : 60,
        textAlign: "center",
        fontSize: props.mobileDevice ? 16 : 20,
        fontWeight: "bold",
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        borderLeft: "2px solid black", borderRight: "2px solid black"
    };

    let rows = wurfAreaRows(props, lineStyle);
    let rowsGedreht = rows;
    if( props.normaleReihenfolge === undefined || !props.normaleReihenfolge) {
        rowsGedreht = rows.reverse();
    }

    return <div className="grid" style={{margin: 0, padding: 0}}>
        <div className="col">
            {rowsGedreht}
        </div>
    </div>
}

export function InputAreaSmall(props) {

    const ergaenzeAufnahme = (wert) => {
        if (wert === "") {
            props.merkeAufnahme("");
        } else {
            props.merkeAufnahme(props.aufnahme + wert);
        }
    };

    const aufnahmeKorrekt = props.aufnahme !== "" && parseInt(props.aufnahme) <= 180;

    const buttonStyle = {width: "20%", height: 75, border: "1px solid #0059b3"};
    return <div className="grid" style={{marginBottom: 5, marginLeft: 0, marginRight: 0, marginTop: 0, padding: 0}}>
        <div className="col-fixed" style={{width: "75%", padding: 0, margin: 0}}>
            <div className="grid"
                 style={{
                     margin: 0,
                     padding: 0,
                 }}>
                <div className="col" style={{padding: 0}}>
                    <Button label="1" style={buttonStyle} onClick={() => ergaenzeAufnahme("1")}/>
                    <Button label="2" style={buttonStyle} onClick={() => ergaenzeAufnahme("2")}/>
                    <Button label="3" style={buttonStyle} onClick={() => ergaenzeAufnahme("3")}/>
                    <Button label="4" style={buttonStyle} onClick={() => ergaenzeAufnahme("4")}/>
                    <Button label="5" style={buttonStyle} onClick={() => ergaenzeAufnahme("5")}/>
                </div>
            </div>
            <div className="grid"
                 style={{
                     margin: 0,
                     padding: 0,
                 }}>
                <div className="col" style={{padding: 0}}>
                    <Button label="6" style={buttonStyle} onClick={() => ergaenzeAufnahme("6")}/>
                    <Button label="7" style={buttonStyle} onClick={() => ergaenzeAufnahme("7")}/>
                    <Button label="8" style={buttonStyle} onClick={() => ergaenzeAufnahme("8")}/>
                    <Button label="9" style={buttonStyle} onClick={() => ergaenzeAufnahme("9")}/>
                    <Button label="0" style={buttonStyle} onClick={() => ergaenzeAufnahme("0")}/>
                </div>
            </div>
        </div>
        <div className="col-fixed" style={{width: "25%", padding: 0, margin: 0}}>
            <Button label="Abschicken" style={{width: "100%", height: "100%", border: "1px solid #0059b3"}}
                    disabled={!aufnahmeKorrekt}
                    onClick={() => props.sendAufnahme()}/>
        </div>
    </div>

}

export function ScoreArea(props) {

    const [selectedId, setSelectedId] = useState("");
    let lineStyle = {
        verticalAlign: "middle",
        height: props.mobileDevice ? 40 : 60,
        textAlign: "center",
        fontSize: props.mobileDevice ? 18 : 30,
        borderBottom: "1px solid black",
        margin: 0,
        padding: 0,
        backgroundColor: "#e6f2ff",
    };
    let rows = [];
    for (let i = 0; i < props.scores.length; i++) {
        let score = props.scores[i];
        const scoreIstGueltig = score.scoregueltig;
        let modulo = i % 2;
        let currentStyle = modulo === 0 ? {...lineStyle, backgroundColor: "#e6eeff"} : {
            ...lineStyle,
            backgroundColor: "#b3ccff",
        };
        let styleScored = {};
        if (!scoreIstGueltig) {
            styleScored = {...styleScored, textDecoration: "line-through"};
        }

        const handelKeyDown = (e) => {
            if (e.keyCode === 13) {
                // enter
                if (e.target.value !== "" && !isNaN(e.target.value)) {
                    // wert muss befüllt sein u. muss eine nummer sein
                    props.changeScore(props.playerid, selectedId, e.target.value);
                    setSelectedId("");
                }
            }
        }

        rows.push(<div key={"score_" + score.id} className="grid" style={currentStyle}
                       onClick={() => {
                           if (props.changeAufnahmeAllowed && score.aufnahme !== undefined && score.aufnahme !== null) {
                               setSelectedId(score.id)
                           }
                       }}>
            {selectedId === score.id ?
                <div className="col" style={styleScored}>
                    <InputText style={{width: 60}} onKeyDown={(e) => handelKeyDown(e)}/>
                    <Button icon={"pi pi-times-circle"} style={{marginLeft: 5}} onClick={(e) => {

                        setSelectedId(undefined);
                        e.stopPropagation();

                    }}/>
                </div>
                :
                <div className="col" style={styleScored}>{score.aufnahme !== undefined ? score.aufnahme : "-"}
                    {(score.wurf1 || score.wurf2 || score.wurf3) &&
                    <span style={{fontSize:16}}> (
                        <span style={{marginLeft:2, marginRight:2, backgroundColor: score.wurf1Bearbeitet ? "red" : "", }}>{score.wurf1}</span>/
                        <span style={{marginLeft:2, marginRight:2, backgroundColor: score.wurf2Bearbeitet ? "red" : "", }}>{score.wurf2}</span>/
                        <span style={{marginLeft:2, marginRight:2, backgroundColor: score.wurf3Bearbeitet ? "red" : "", }}>{score.wurf3}</span>
                        )
                    </span>
                    }
                </div>
            }
            <div className="col">{score.togo}</div>
        </div>)
    }
    if (props.anzScores > props.scores.length) {
        // fügt leere zeile ein, da der gegner wohl bereits eine zeile mehr gescored hat.
        rows.push(<div key={"score_line"} style={lineStyle}></div>)
    }
    let rowsGedreht = rows;
    if( props.normaleReihenfolge === undefined || !props.normaleReihenfolge) {
        rowsGedreht = rows.reverse();
    }

    return <div className="grid"
                style={{
                    margin: 0,
                    padding: 0,
                }}>
        <div className="col" style={{margin: 0, padding: 0}}>
            {rowsGedreht}
        </div>
    </div>

}

export function StatistikArea(props) {
    let style = {width: 300};
    let fixedColStyle = {width: 140};
    return <div className="col-fixed" style={style}>
        <div className="grid" style={{
            margin: 0,
            padding: 0,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 26
        }}>
            <div className="col" style={fixedColStyle}>
                <div className="grid">
                    <div className="col" style={{color: "#3333cc"}}>
                        Totals
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        Average
                    </div>
                    <div className="col">
                        {props.player.average}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        Leg. Avg.
                    </div>
                    <div className="col">
                        {props.legAverage}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        Ton
                    </div>
                    <div className="col">
                        {props.player.ton}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        100+
                    </div>
                    <div className="col">
                        {props.player._100Plus}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        140+
                    </div>
                    <div className="col">
                        {props.player._140Plus}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        171er
                    </div>
                    <div className="col">
                        {props.player._171er}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={fixedColStyle}>
                        180er
                    </div>
                    <div className="col">
                        {props.player._180er}
                    </div>
                </div>
                {props.player.anzahlDoppelwuerfe > 0 && <>
                    <div className="grid" style={{marginTop:10}}>
                        <div className="col">
                            Doppelquote:
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {props.player.doppelquote}% - {props.player.anzahlDoppelgetroffen}/{props.player.anzahlDoppelwuerfe}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    </div>
}

export function ScoreHeader(props) {
    return <div className="grid" style={{
        width: "100%", height: 50,
        padding: 0,
        margin: 0,
        textAlign: "center",
        fontSize: props.fontSize,
        fontWeight: "bold",
    }}>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white"}}>to go</div>
        <div className="col-fixed" style={{width: 70, borderBottom: "2px solid black", backgroundColor: "white"}}/>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white"}}>scored</div>
        <div className="col" style={{borderBottom: "2px solid black", backgroundColor: "white"}}>to go</div>
    </div>;
}
