import React from 'react'
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Card} from "primereact/card";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

class ChoosLeagueoperatorDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {disabledleagueoperators: []};
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.changeVisible = this.changeVisible.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.visible === false && this.props.visible === true) {
            this.ladeDaten();
        }
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }


    allVisible() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.leagueoperator + "/allvisible";
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({disabledleagueoperators: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }


    changeVisible(betreiberid) {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.leagueoperator + "/changevisible/" + betreiberid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({disabledleagueoperators: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    ladeDaten() {
        let url = ConfigUtil.getConfig().resourceUrls.leagueoperator + "/disabledleagueoperators";
        FetchUtil.fetchGet(url,
            json => {
                this.setState({disabledleagueoperators: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    isBetreiberDisabled(betreiberid) {
        if( this.state.disabledleagueoperators === null || this.state.disabledleagueoperators === undefined || this.state.disabledleagueoperators.length === 0) {
            return false;
        }
        for (const disabledleagueoperator of this.state.disabledleagueoperators) {
            if( disabledleagueoperator === betreiberid) {
                return true;
            }
        }
        return false;
    }

    getBetreiberListe() {
        if( this.props.leagueoperators === null || this.props.leagueoperators === undefined || this.props.leagueoperators.length === 0) {
            return <div>Keine Ligabetreiber zur Auswahl. Bitte öffne den Dialog erneut.</div>
        }
        let rows = [];

        rows.push(<div style={{cursor:"pointer", marginBottom:30}} key={"key_ligabetreiber_alle"}
                       onClick={() => this.allVisible()}>
            Daten von allen Liga- und Turnierbetreiber anzeigen...
        </div>)
        for (const betreiber of this.props.leagueoperators) {
            let image;
            if( this.isBetreiberDisabled(betreiber.id)) {
                image = <img src="/images/cancel.png"
                     style={{width:30, marginRight:10, marginTop:7}}
                     alt="Auswahl Ligabetreiber"/>;
            } else {
                image = <img src="/images/check2.png"
                              style={{width:30, marginRight:10, marginTop:7}}
                              alt="Auswahl Ligabetreiber"/>;

            }
            rows.push(<div style={{cursor:"pointer"}} key={"key_ligabetreiber_id_" + betreiber.id}
            onClick={() => this.changeVisible(betreiber.id)}>
                {image}
                {betreiber.name}
                <hr/>
            </div>)
        }
        return <div>
            {rows}
        </div>
    }

    render() {
        return <DHDefaultDrawer onClose={() => this.props.closeDialog()} show={this.props.visible}
                                title="Auswahl der Ligabetreiber" children={<div style={{padding:20}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <Card>Hier kannst du einschränken zu welchen Ligabetreibern du Turniere und Ligen sehen willst.<br/>
                Auf dem Dashboard erscheinen dann nur noch die entsprechenden Turniere und Ligen.<br/><br/>
            Sofern du keine Auswahl triffst, werden dir die Daten von allen Ligabetreibern angezeigt.</Card>
            <div style={{marginTop:20}}>
                {this.getBetreiberListe()}
            </div>
        </div>}/>
    }
}

ChoosLeagueoperatorDialog.propTypes = {
    leagueoperators: PropTypes.array,
    visible: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
};

export default ChoosLeagueoperatorDialog