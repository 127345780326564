import React from 'react'

export function Systemvoraussetzungen() {

    return <div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
        <div className="kachelStartseite" style={{height: "100%"}}>
            <h3>System</h3>
            <img src="/images/chrome.png" style={{width:40, heigth:40, marginRight:10}} alt="Chrome"/>
            <div style={{fontSize:14, marginTop:5}}>Wir unterstützen im Prinzip alle modernen Browser, empfehlen aber sehr stark die Nutzung von Chrome. Der Chromebrowser bringt in der neuen Version viele Features mit welche alle anderen Browser nicht unterstützen. Da Darthelfer eine moderne Plattform ist, nutzen wir sehr oft diese neue Techniken.</div>
            <div style={{fontSize:14, marginTop:5}}>Dein Endgerät inkl. der Bildschirmauflösung muss aktuellen Standards entsprechen.</div>
            <div style={{fontSize:14, marginTop:5}}>Auf Endgeräten wie XBox, Playstation und Radioweckern wird darthelfer.de nicht funktionieren.</div>
            <div style={{fontSize:14, marginTop:5}}>Sofern du diese Voraussetzungen nicht erfüllst, bitten wir dich darthelfer.de nicht, oder ausschließlich ohne Support, zu nutzen.</div>
        </div>
    </div>
}
