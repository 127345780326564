import React from 'react'
import {Messages} from 'primereact/messages';

import {Toast} from "primereact/toast";
import {Panel} from 'primereact/panel';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import SelectPlayerComponent from "../../general/SelectPlayerComponent";

import PlayerUtil from "../../../util/PlayerUtil";

import Table from "./headToHeadTable";
import {Chart} from "primereact/chart";

class StatisticsHeadToHead extends React.Component {

    constructor() {
        super();
        this.state = {trainigssession: undefined, counter:0};

        this.showToast = this.showToast.bind(this);
        this.showError = this.showError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loescheSpieler1 = this.loescheSpieler1.bind(this);
        this.loescheSpieler2 = this.loescheSpieler2.bind(this);
        this.merkeSpieler1 = this.merkeSpieler1.bind(this);
        this.merkeSpieler2 = this.merkeSpieler2.bind(this);
        this.getAuswertung = this.getAuswertung.bind(this);
    }

    loadData(spieler1Id, spieler2Id) {
        if(spieler1Id === undefined || spieler2Id === undefined ) {
            return;
        }
        let data = {spieler1id: spieler1Id, spieler2id: spieler2Id};
        let url = ConfigUtil.getConfig().resourceUrls.statistics + "/headtohead"
        FetchUtil.fetchPost(url,
            data,
            json => {
                this.setState({...this.state,ergebnisse: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der head to head Statistik: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der head to head Statistik: " + error.message);
            });
    }

    loescheSpieler1(spieler) {
        this.setState({spieler1: undefined, ergebnisse: undefined});
    }

    loescheSpieler2(spieler) {
        this.setState({spieler2: undefined, ergebnisse: undefined});
    }

    merkeSpieler1(spieler) {
        this.setState({...this.state, spieler1: spieler});
        if( this.state.spieler2 !== undefined) {
            this.loadData(spieler.id, this.state.spieler2.id);
        }
    }

    merkeSpieler2(spieler) {
        this.setState({...this.state, spieler2: spieler});
        if( this.state.spieler1 !== undefined) {
            this.loadData(this.state.spieler1.id, spieler.id);
        }
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    getAuswertung() {
        if( this.state.ergebnisse === undefined) {
            return ;
        }

        let gesamtLegsSpieler1 = 0;
        let gesamtLegsSpieler2 = 0;
        let siegeSpieler1 = 0;
        let siegeSpieler2 = 0;
        for (let i = 0; i < this.state.ergebnisse.length; i++) {
            let ergebnis = this.state.ergebnisse[i];
            let trainingsergebnisseDto = ergebnis.trainingsergebnisseDto;
            let player1Legs = this.state.spieler1.id === trainingsergebnisseDto.spieler1Id ? trainingsergebnisseDto.spieler1Legs : trainingsergebnisseDto.spieler2Legs;
            let player2Legs = this.state.spieler2.id === trainingsergebnisseDto.spieler1Id ? trainingsergebnisseDto.spieler1Legs : trainingsergebnisseDto.spieler2Legs;

            gesamtLegsSpieler1 = player1Legs + gesamtLegsSpieler1;
            gesamtLegsSpieler2 = player2Legs + gesamtLegsSpieler2;
            if( player1Legs > player2Legs) {
                siegeSpieler1 = siegeSpieler1 + 1;
            } else {
                siegeSpieler2 = siegeSpieler2 + 1;
            }
        }

        let spieler1Name = PlayerUtil.anzeigeNameZumSpieler(this.state.spieler1);
        let spieler2Name = PlayerUtil.anzeigeNameZumSpieler(this.state.spieler2);

        const dataSpiele = {
            labels: [spieler1Name + " Spiele gewonnen (" + siegeSpieler1 + ")",spieler2Name + " Spiele gewonnen (" + siegeSpieler2 + ")"],
            datasets: [
                {
                    data: [siegeSpieler1, siegeSpieler2],
                    backgroundColor: [
                        "#008000",
                        "#ff0000",
                    ],
                    hoverBackgroundColor: [
                        "#008000",
                        "#ff0000",
                    ]
                }]
        };

        const dataLegs = {
            labels: [spieler1Name + " legs gewonnen (" + gesamtLegsSpieler1 + ")", spieler2Name + " legs gewonnen (" + gesamtLegsSpieler2 + ")"],
            datasets: [
                {
                    data: [gesamtLegsSpieler1, gesamtLegsSpieler2],
                    backgroundColor: [
                        "#008000",
                        "#ff0000",
                    ],
                    hoverBackgroundColor: [
                        "#008000",
                        "#ff0000",
                    ]
                }]
        };

        let windowWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

        let chartWidth = 10;
        if( windowWidth < 400 ) {
            chartWidth = 220;
        } else {
            chartWidth = 320;
        }

        return <div>
            <Chart type="doughnut" data={dataLegs} width={chartWidth} height={chartWidth} />
            <Chart type="doughnut" data={dataSpiele} width={chartWidth} height={chartWidth}/>
        </div>;
    }

    render() {
        let auswertung = this.getAuswertung();
       return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black", padding: "20px"}}>
           <Toast ref={(el) => this.toast = el}/>
           <Messages ref={(el) => this.messages = el}/>
           <Panel header="Head to Head Vergleich">
               <div className="grid">
                   <div className="col-fixed" style={{textAlign: "left"}}>
                       <SelectPlayerComponent
                           player={this.state.spieler1}
                           selectButtonLabel="Spieler 1 auswählen"
                           callBackSelectFunc={this.merkeSpieler1}
                           callBackDeleteFunc={this.loescheSpieler1}
                           callBackOnErrorFunc={this.showError}/>
                   </div>
               </div>
               <div className="grid">
                   <div className="col" style={{textAlign: "left"}}>
                       <SelectPlayerComponent
                           player={this.state.spieler2}
                           selectButtonLabel="Spieler 2 auswählen"
                           callBackSelectFunc={this.merkeSpieler2}
                           callBackDeleteFunc={this.loescheSpieler2}
                           callBackOnErrorFunc={this.showError}/>
                   </div>
               </div>
               <div style={{marginTop:"30px", textAlign: "left"}}>
                   {auswertung}
               </div>
               {this.state.spieler1 !== undefined && this.state.spieler2 !== undefined ?
                   <div style={{marginTop:"30px"}}>
                       <Table spieler1={this.state.spieler1}  spieler2={this.state.spieler2} ergebnisse={this.state.ergebnisse}/>
                   </div>
                   :
                   <div/>
               }
           </Panel>
        </div>
    }

}

StatisticsHeadToHead.propTypes = {};

export default StatisticsHeadToHead