import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 350,
    },
});

function getRows(props) {
    let tableRows = [];
    for (let i = 0; i < props.rows.length; i++) {
        let row = props.rows[i];
        let styleRow = i % 2 === 0 ? {backgroundColor: "#666666"} : {backgroundColor: "#404040"};
        tableRows.push(<TableRow key={props.keyPrefix +"_tabellenzeile_" + row.id} style={{...styleRow, color:"white"}}>
            <TableCell style={{width:"50px", color:"white"}}>
                {"" + row.rang + "."}
            </TableCell>
            <TableCell style={{color:"white"}}>{row.spieler}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:"#E0AF97"}}>
                {row.wert}
            </TableCell>
        </TableRow>);
    }

    return tableRows;
}

export default function SimpleTable(props) {
    const classes = useStyles();

    let tableRows = getRows(props);

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: "14px", width:"50px", backgroundColor:"black", color:"white"}}>Rang</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"black", color:"white"}}>Spieler</TableCell>
                        <TableCell style={{fontSize: "14px", width:props.col3Width, backgroundColor:"#E0AF97"}}>{props.col3}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key="bodykey">
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}
