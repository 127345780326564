import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

function TurnierKratzerTabPanel({tournamentId, tournamentKratzerPoints, onError}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [pointList, setPointList] = useState([]);
    let [points, setPoints] = useState(0);
    let [bezeichnung, setBezeichnung] = useState("");

    useEffect( () => {
        loadData();
    } ,tournamentId);

    const save = () => {
        setErrorMessage(undefined);
        let data = {tournamentId: tournamentId, bezeichnung: bezeichnung, punkte: points};
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/kratzerpoints/save";
        FetchUtil.fetchPost(url,
            data,
            json => {
                loadData();
                setPoints(0);
                setBezeichnung("");
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const loadData = () => {
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/kratzerpoints/load/" + tournamentId;
        FetchUtil.fetchGet(url,
            json => {
                setPointList([...json]);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const deleteData = (id) => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/kratzerpoints/delete/" + id;
        FetchUtil.fetchPost(url,
            [],
            json => {
                loadData();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen: " + error.message);
            }
        );
    }

    const getRows = () => {
        let rows = [];
        for (const kratzerPoints of pointList) {
            rows.push(<div key={"key_kratzer_points_" + kratzerPoints.bezeichnung} style={{marginBottom:10}}>
                <div><Button icon="pi pi-trash" onClick={() => deleteData(kratzerPoints.id)} style={{marginRight: 10}}/>Bezeichnung: {kratzerPoints.bezeichnung} - Punkte: {kratzerPoints.punkte}</div>
            </div>);
        }
        return rows;
    }

    if( tournamentId === null || tournamentId === undefined) {
        return <div>
            <div style={{color:"red"}}>
                Für die Bearbeitung musst du das Turnier mindestens einmal gespeichert haben.
            </div>
        </div>
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginTop: 20, marginBottom: 10}}>
            Hier kannst du die Punkte für das Kratzerturnier festlegen. Jedem Teilnehmer wird dann eine dieser Gruppierung zugewiesen.
        </div>
        <div className="grid" style={{marginTop:20, marginBottom:30}}>
            <div className="col">
                <span>Bezeichnung:</span>
                <InputText style={{width: 330, marginLeft:5, marginRight: 20}} value={bezeichnung}
                           onChange={(e) => setBezeichnung(e.target.value)}/>
                <span>Punkte:</span>
                <InputNumber inputStyle={{width:50, marginLeft:5, marginRight:5}} value={points} onValueChange={(e) => setPoints(e.value)} min={0}/>
                <Button label="Speichern" icon="pi pi-save" disabled={bezeichnung.length === 0}
                        onClick={() => save()}/>
            </div>
        </div>
        {getRows()}
    </div>
}

TurnierKratzerTabPanel.propTypes = {
    onError: PropTypes.func.isRequired,
};

export default TurnierKratzerTabPanel;
