import React, {useEffect, useState} from 'react'
import DateUtil from "../../../../util/DateUtil";

export default function BoardAufrufRow({label, timestamp, minutesToError}) {

    if( timestamp === null || timestamp === undefined) {
        return "";
    }

    const [showError, setShowError] = useState(false);

    const isError = () => {
        return DateUtil.isDateBeforeDateWithMinutes(new Date(), new Date(timestamp), minutesToError);
    }

    useEffect(() => {
        setShowError(isError(minutesToError));
    }, [minutesToError]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setShowError(isError(minutesToError));
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [minutesToError]);

    return <span
        style={{color: showError ? "red" : ""}}>
        <br/>
        {label}: {DateUtil.localDateTimeToString(timestamp)}
    </span>

}
