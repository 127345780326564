import {connect} from 'react-redux'
import AktionenChat from "../../../components/views/liga/AktionenChat";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id, headtoheadsUngelesen: state.chat.headtoheadsUngelesen};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AktionenChat)