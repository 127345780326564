export const KEY_LAST_EVENTS = "dh_last_events";

export function saveToLocalStorage(key, value)  {
    localStorage.setItem(key, JSON.stringify(value));
}

export function saveToSessionStorage(key, value)  {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export function getFromSessionStorageWithDefault(key, defaultValue)  {
    const savedItem = sessionStorage.getItem(key);
    const parsedItem = JSON.parse(savedItem);
    if( parsedItem === undefined || parsedItem === null || parsedItem === "") {
        return defaultValue;
    } else {
        return parsedItem;
    }
}

export function getFromLocalStorage(key)  {
    const savedItem = localStorage.getItem(key);
    if( savedItem === undefined || savedItem === null || savedItem === "undefined" || savedItem === "") {
        return undefined;
    } else {
        return JSON.parse(savedItem);
    }
}

export function getFromLocalStorageWithDefault(key, defaultValue)  {
    const savedItem = localStorage.getItem(key);
    const parsedItem = JSON.parse(savedItem);
    if( parsedItem === undefined || parsedItem === null || parsedItem === "") {
        return defaultValue;
    } else {
        return parsedItem;
    }
}

export function removeFromLocalStorage(key)  {
    localStorage.removeItem(key);
}

export function removeFromSessionStorage(key)  {
    sessionStorage.removeItem(key);
}

export function addLastWatchedEvent(id, name) {
    let event = {id: id, name: name, type: "event"};
    let lastEvents = getFromLocalStorageWithDefault(KEY_LAST_EVENTS, []);
    if( lastEvents.length === 0) {
        lastEvents.push(event);
    } else {
        lastEvents = lastEvents.filter(tmp => tmp.id !== id && tmp.type === "event");
        if( lastEvents.length >= 10) {
            lastEvents.splice(-1);
        }
        lastEvents.unshift(event)
    }
    saveToLocalStorage(KEY_LAST_EVENTS, lastEvents);
}
