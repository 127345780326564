import {connect} from 'react-redux'
import {logout} from "../../../actions/user";
import SchnellAktionen from "../../../components/views/liga/SchnellAktionen";
import {withCookies} from "react-cookie";

const mapStateToProps = (state, ownProps) => ({
    userRoles: state.user.userRoles,
    userId: state.user.id,
    userEventOperatorAdmin: state.user.userEventOperatorAdmin,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout())
});

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchnellAktionen))
