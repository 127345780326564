import React from 'react'

class KachelLogout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            icon: "images/kachel/logout.png"
        };
    }

    render() {
        return <div className="grid kachel"
             onMouseLeave={() => this.setState({icon: "images/kachel/logout.png"})}
             onMouseOver={() => this.setState({icon: "images/kachel/logout_blue.png"})}
             onClick={() => this.props.logout()}
             style={{
                 padding: 0,
                 marginTop: "5px",
                 marginLeft: "10px",
                 marginRight: 10,
             }}>
            <div className="col-fixed" style={{width:30, backgroundColor: this.props.color, padding:20}}>
            </div>
            <div className="col">
                <div style={{marginRight: "20px", marginLeft: "30px", textAlign: "center", align: "center"}}>
                    {this.props.fixicon !== undefined ?
                        <img alt="" style={{marginTop: "20px", marginRight: "20px"}}
                             src={"images/kachel/" + this.props.fixicon + ".png"}/>
                        :
                        <div/>
                    }
                    <img alt="" style={{marginTop: "20px"}} src={this.state.icon}/>
                </div>
                <div style={{
                    marginTop: "10px",
                    height: 57,
                    textAlign: "center",
                    align:"bottom", verticalAlign:"bottom"
                }}>Abmelden</div>
            </div>
        </div>
    }
}

export default KachelLogout;