import React from 'react'
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {Fieldset} from "primereact/fieldset";
import UserSearch from "../liga/UserSearch";
import DHDialog from "../../general/DHDialog";

class TurnierAddPlayerDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.save = this.save.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tournament === undefined && this.props.tournament !== undefined) {
            this.setState({tournament: this.props.tournament});
        }
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    showWarn(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'info',
            sticky: 'true',
            summary: '',
            detail: message
        });
    }

    save() {

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/addplayeronrunningtournament/" + this.props.tournament.id + "/" + this.state.user.id,
            {},
            json => {
                this.showInfo("Hinzufügen war erfolgreich!");
                this.setState({user:undefined});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="User zur Liga/Turnier hinzufügen" disabled={this.state.user === undefined} icon="pi pi-save" onClick={() => this.save()}/>
                <Button label="Schliessen" icon="pi pi-times" onClick={() => {
                    this.setState({user:undefined});
                    this.props.callBackDialogClose();
                }}
                        className="p-button-secondary"/>
            </div>
        );

        return <DHDialog onClose={() => this.props.callBackDialogClose()} title="Spieler zum Turnier/Liga hinzufügen" show={this.props.visible} buttons={dialogFooter}
                         comp={<>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <Fieldset legend="Hinweise..." style={{marginBottom:20}} toggleable={true}>
                    <div>- Du kannst nur in eine Liga einfügen, wenn da gerade eine ungerade Teilnehmeranzahl vorhanden ist. Also bei 7,9,11 Teilnehmern, nicht bei 8,10,12 usw.</div>
                </Fieldset>
                <div className="grid">
                    <div className="col-fixed" style={{width: 100}}>
                        Username:
                    </div>
                    <div className="col-fixed" style={{width: 400}}>
                        <UserSearch callBackError={(message) => {
                            this.showError(message);
                            this.setState({user: undefined})
                        }}
                                    callBackClearMessages={() => this.messages.clear()}
                                    callBackInfo={this.showWarn}
                                    callBackUserFound={(user) => this.setState({user: user})}/>
                    </div>
                </div>
            </>}/>
    }
}

TurnierAddPlayerDialog.propTypes = {
    callBackRefresh: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    tournament: PropTypes.object.isRequired,
};

export default TurnierAddPlayerDialog