import React from 'react'
import LiveView from "../liveview/LiveView";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import ConfigUtil from "../../../../util/ConfigUtil";


export default function PublicTournamentLiveTab({tournamentId, tournamentBezeichnung, eventOperatorId}) {
    const url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route + "?tournamentid=" + tournamentId + "&eventoperatorid=" + eventOperatorId;
    return <div className="darthelfer-background-blue">
        <div style={{fontSize:16, margin:20, color:"white", cursor:"pointer"}}
            onClick={() => window.open(url, "_self")}>
            <i className="pi pi-desktop" style={{fontSize: 30, marginRight: 20, color: "white", cursor: "pointer"}}
               onClick={() => window.open(url, "_self")}/>
            Vollbild
        </div>
        {tournamentBezeichnung !== null && tournamentBezeichnung !== undefined &&
            <div style={{padding:20, color:"white", fontSize:20}}>{tournamentBezeichnung}</div>
        }
        <LiveView tournamentid={tournamentId} tournamentBezeichnung={tournamentBezeichnung} eventOperatorId={eventOperatorId} showEmbedded={true}/>
    </div>
}

