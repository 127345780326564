import DHDialog from "../../general/DHDialog";
import PlayerUtil from "../../../util/PlayerUtil";
import LigaSpieltagEDartDetailStatistikGame from "./LigaSpieltagEDartDetailStatitikGame";
import React, {useState} from "react";
import LigaSpieltagEDartDetailStatistikGameMinimiert from "./LigaSpieltagEDartDetailStatitikGameMinimiert";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function LigaSpieltagEDartDetailStatistikDialog({tournamentId, games, players, bereich, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [minimizedGames, setMinimizedGames] = useState([]);

    const gameUncompleted = (gameId) => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/uncomplete/" +  gameId,
            {},
            () => {
                setInfoMessage("Spiel wurde wieder geöffnet.")
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Öffnen: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Öffnen: " + error.message);
            });
    }

    const getGamesComplete = () => {
        let t = [];
        for (const game of games) {
            if( game.complete) {
                t.push(game);
            }
        }
        return t;
    }

    const getGames = () => {
        let t = [];
        for (const game of games) {
            if( minimizedGames.includes(game.id)) {
                continue;
            }
            if( game.bereich < bereich && !game.complete) {
                t.push(game);
            }
        }
        return t;
    }

    const getGamesMinimiert = () => {
        let t = [];
        for (const game of games) {
            if( minimizedGames.includes(game.id)) {
                t.push(game);
            }
        }
        return t;
    }

    const addMinimizedGame = (gameId) => {
        let tmp = [...minimizedGames];
        tmp.push(gameId);
        setMinimizedGames(tmp);
    }

    const addMaximizedGame = (gameId) => {
        let tmp = [...minimizedGames];
        tmp = tmp.filter(t => t !== gameId);
        setMinimizedGames(tmp);
    }

    const getGamesCompleteArea = () => {
        let columns = [];
        let tmpGames = getGamesComplete();
        for (const tmpGame of tmpGames) {
            columns.push(<LigaSpieltagEDartDetailStatistikGameMinimiert
                gameNr={tmpGame.counter}
                spielerName={PlayerUtil.getSpielerName(players, tmpGame.player1Id)}
                gegnerName={PlayerUtil.getSpielerName(players, tmpGame.player2Id)}
                anzahlSpieler1={tmpGame.anzahlPlayer1}
                anzahlSpieler2={tmpGame.anzahlPlayer2}
                onMaximize={() => gameUncompleted(tmpGame.id)}
            />);
        }
        return columns;
    }

    const getGamesMinimiertArea = () => {
        let columns = [];
        let tmpGames = getGamesMinimiert();
        for (const tmpGame of tmpGames) {
            columns.push(<LigaSpieltagEDartDetailStatistikGameMinimiert
                                                                gameNr={tmpGame.counter}
                                                               spielerName={PlayerUtil.getSpielerName(players, tmpGame.player1Id)}
                                                               gegnerName={PlayerUtil.getSpielerName(players, tmpGame.player2Id)}
                                                                anzahlSpieler1={tmpGame.anzahlPlayer1}
                                                                anzahlSpieler2={tmpGame.anzahlPlayer2}
                                                               onMaximize={() => addMaximizedGame(tmpGame.id)}
            />);
        }
        return columns;
    }

    const getGamesArea = () => {
        let columns = [];
        let tmpGames = getGames();
        for (const tmpGame of tmpGames) {
            columns.push(<LigaSpieltagEDartDetailStatistikGame tournamentId={tournamentId} gameId={tmpGame.id}
                                                               playerId={tmpGame.player1Id}
                                                               gameNr={tmpGame.counter}
                                                               spielerName={PlayerUtil.getSpielerName(players, tmpGame.player1Id)}
                                                               gegnerName={PlayerUtil.getSpielerName(players, tmpGame.player2Id)}
                                                               onMinimize={() => addMinimizedGame(tmpGame.id)}
            />);
        }
        return columns;
    }
    const getArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <div style={{display: "flex"}}>
                {getGamesArea()}
            </div>
            {getGamesMinimiert().length > 0 && <>
                <hr/>
                <div style={{fontWeight: "bold"}}>
                    Minimierte Spiele:
                </div>
                <div style={{display: "flex"}}>
                    {getGamesMinimiertArea()}
                </div>
            </>
            }
            {getGamesComplete().length > 0 && <>
                <hr/>
                <div style={{fontWeight:"bold"}}>
                    Abgeschlossene Spiele:
                </div>
                <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
                    {getGamesCompleteArea()}
                </div>
            </>
            }
        </div>
    }

    return <DHDialog onClose={onClose} title={"E-Dart Statistik"} buttons={[]} comp={getArea()}/>
}