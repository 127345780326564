import React, {useState} from 'react'
import {Card} from "primereact/card";

export default function PublicTournamentDetailsTab({tournament}) {

    const veranstalterArea = () => {
        if ((tournament.homepage === null || tournament.homepage === "") &&
            (tournament.socialmedia === null || tournament.socialmedia === "") &&
            (tournament.email === null || tournament.email === "") &&
            (tournament.telefon === null || tournament.telefon === "")) {
            return "";
        }

        return <div className={"col-12 md:col-12 lg:col-6"}>
            <Card style={{fontSize:18}}>
                <div style={{fontWeight:"bold", marginBottom:10}}>Daten zum Veranstalter</div>
                {tournament.homepage !== null && tournament.homepage &&
                    <div style={{marginBottom:5}}>Homepage: <a href={tournament.homepage} target="_blank" rel="noopener noreferrer">{tournament.homepage}</a></div>}
                {tournament.socialmedia !== null && tournament.socialmedia !== "" && <div style={{marginBottom:5}}>Socialmedia: <a href={tournament.socialmedia} target="_blank" rel="noopener noreferrer">{tournament.socialmedia}</a></div>}
                {tournament.email !== null && tournament.email !== "" && <div style={{marginBottom:5}}>Email: {tournament.email}</div>}
                {tournament.telefon !== null && tournament.telefon !== "" && <div style={{marginBottom:5}}>Telefon: {tournament.telefon}</div>}
            </Card>
        </div>
    }

    const ausrichterArea = () => {
        if ((tournament.austragungsortBezeichnung === null || tournament.austragungsortBezeichnung === "") &&
            (tournament.austragungsortStrasse === null || tournament.austragungsortStrasse === "") &&
            (tournament.austragungsortPLZ === null || tournament.austragungsortPLZ === "") &&
            (tournament.austragungsortOrt === null || tournament.austragungsortOrt === "")) {
            return "";
        }

        return <div className={"col-12 md:col-12 lg:col-6"}>
            <Card style={{fontSize:18}}>
                <div style={{fontWeight:"bold", marginBottom:10}}>Daten zum Ausrichtungsort</div>
                {tournament.austragungsortBezeichnung !== null && tournament.austragungsortBezeichnung !== "" &&
                    <div style={{marginBottom:5}}>Bezeichnung: {tournament.austragungsortBezeichnung}</div>}
                {tournament.austragungsortStrasse !== null && tournament.austragungsortStrasse !== "" && <div style={{marginBottom:5}}>Straße: {tournament.austragungsortStrasse}</div>}
                {tournament.austragungsortPLZ !== null && tournament.austragungsortPLZ !== "" && <div style={{marginBottom:5}}>PLZ: {tournament.austragungsortPLZ}</div>}
                {tournament.austragungsortOrt !== null && tournament.austragungsortOrt !== "" && <div style={{marginBottom:5}}>Ort: {tournament.austragungsortOrt}</div>}
            </Card>
        </div>
    }

    const veranstaltungArea = () => {

        let [gekuerzt, setGekuerzt] = useState(true);

        let beschreibung = "";
        if( tournament.beschreibung ) {
            if (gekuerzt || tournament.beschreibung.length <= 100) {
                beschreibung = tournament.beschreibung.substring(0, 100);
                if( beschreibung.length >= 50) {
                    beschreibung = beschreibung + "\n...mehr Daten vorhanden (Klick)..."
                }
            } else {
                beschreibung = tournament.beschreibung;
            }
        }

        return <div className={"col-12 md:col-12 lg:col-6"}>
            <Card style={{fontSize:18}}>
                <div style={{fontWeight:"bold", marginBottom:10}}>Details zur Veranstaltung</div>
                <div style={{marginBottom:5}}>Bezeichnung: {tournament.bezeichnung}</div>
                {tournament.beschreibung !== null && tournament.beschreibung !== "" &&
                    <div style={{whiteSpace: "pre-line", marginBottom:5}} onClick={() => setGekuerzt(!gekuerzt)}>Beschreibung:<br/>{beschreibung}</div>}
                <div style={{marginBottom:5}}>Zeitpunkt: {tournament.beginnMitUhrzeitString}</div>
                <div style={{marginBottom:5}}>Turnierstatus: {tournament.tournamentstatus}</div>
                {tournament.doppelturnier &&
                    <div style={{marginBottom:5}}>Doppelturnier</div>
                }
                {tournament.dyp &&
                    <div style={{marginBottom:5}}>DYP Turnier, Anmeldung als Einzelperson. Automatische Zuweisung des Doppelpartners.</div>
                }
                {!tournament.doppelturnier &&
                    <div style={{marginBottom:5}}>Einzelturnier</div>
                }
            </Card>
        </div>
    }

    return <div style={{padding: 20, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        <div className="grid">
            {veranstaltungArea()}
            {veranstalterArea()}
            {ausrichterArea()}
        </div>
    </div>
}
