import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import FunGamesArea from "./FunGamesArea";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {VergangeneSpieleArea} from "./VergangeneSpieleArea";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import ProfilFriendStatistikArea from "./ProfilFriendStatistikArea";
import ProfilLigenArea from "./ProfilLigenArea";
import ProfilTurnierArea from "./ProfilTurnierArea";

class ProfilFriend extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.sendeFreundschaftsanfrage = this.sendeFreundschaftsanfrage.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.profil + "/" + this.props.profilId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({profil: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    sendeFreundschaftsanfrage() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.freundschaft + "/anfrageeinstellen?freundUserId=" + this.props.profilId;
        FetchUtil.fetchPost(url,
            {},
            message => {
                if( message !== null && message !== undefined && message !== "") {
                    this.showError(message);
                } else {
                    this.showInfo("Freundschaftsanfrage wurde abgeschickt.");
                    this.ladeDaten();
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Senden der Freundschaftsanfrage: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Senden der Freundschaftsanfrage: " + error.message);
            }
        );
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#ff99c2"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Profil
                </div>
            </div>
            {this.state.profil === null || this.state.profil === undefined ?
            "Daten werden geladen":
            this.showProfil()
            }
        </div>
    }

    showProfil() {
        return  <><div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
            <div className="ligashortoverview">
                <span style={{marginRight: 30, fontWeight: "bold", fontSize: 25}}>Username: {this.state.profil.username}</span>
                {!this.state.profil.aktuellerUserIstEinFreund && <Button label="Freundschaftsanfrage senden" icon="pi pi-user-plus"
                        tooltip="Freundschaftsanfrage senden" onClick={() => this.sendeFreundschaftsanfrage()} style={{margin: 5}}/>}
                {this.state.profil.aktuellerUserIstEinFreund && <span style={{color:"green", fontSize:20, fontWeight:"bold", marginLeft:20, marginRight:20}}>Ihr seid Freunde</span>}
                <NavLink to={NAVIGATION_ITEM.GAME_OVERVIEW.route + "?gegnerId=" + this.state.profil.userId + "&username=" + this.state.profil.username} style={{textDecoration: "none"}}>
                    <Button label="Spiel starten" icon="pi pi-globe" tooltip="Freundschaftsanfrage senden"
                            onClick={() => ""} style={{margin: 5}}/>
                </NavLink>
                {this.state.profil.onlineStatus ?
                    <span style={{color:"green", fontSize:20, fontWeight:"bold", marginLeft:20, marginRight:20}}>Benutzer ist online</span> :
                    <span style={{color:"red", fontSize:20, fontWeight:"bold", marginLeft:20, marginRight:20}}>Benutzer ist offline</span>}
                <NavLink to={NAVIGATION_ITEM.HEADTOHEADCHAT.route + "?username=" + this.state.profil.username} style={{textDecoration: "none"}}>
                    <Button label="Chat starten" icon="pi pi-comments"
                            tooltip="Chat starten"/>
                </NavLink>
            </div>
        </div>
        <div style={{padding:10}}>
            <div className="grid">
                {this.getDatenArea()}
                {this.getAnstehendeSpieleArea()}
                {this.getVergangeneSpieleArea()}
                <ProfilLigenArea ligenAktuell={this.state.profil.ligenAktuell} ligenAbgeschlossen={this.state.profil.ligenAbgeschlossen}/>
                <ProfilTurnierArea turniereAktuell={this.state.profil.turniereAktuell} turniereAbgeschlossen={this.state.profil.turniereAbgeschlossen}/>
                <ProfilFriendStatistikArea showDetails={false} userId={this.props.profilId}/>
            </div>

        </div>
        </>;
    }

    getDatenArea() {
        return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
            <div className="ligashortoverview" style={{height: "100%", textAlign: "left"}}>
                <h3>Daten</h3>
                <div style={{width: "100%", marginBottom:5}}>
                    <span style={{width: 200, marginRight: 10}}>Name:</span>
                    <span>{this.state.profil.name}</span>
                </div>
                <div style={{width: "100%", marginBottom:5}}>
                    <span style={{width: 200, marginRight: 10}}>Vorname:</span>
                    <span>{this.state.profil.vorname}</span>
                </div>
                <div style={{width: "100%", marginBottom:5}}>
                    <span style={{width: 200, marginRight: 10}}>Darthelferusername:</span>
                    <span>{this.state.profil.username}</span>
                </div>
                <hr/>
                <span style={{fontSize:12}}>Es werden die letzten 20 Bewertungen berücksichtigt.</span>
                <div style={{width: "100%", marginTop:20}}>
                    <span>Bewertung deiner Bildqualität</span>
                    <br/>
                    <span style={{fontSize:12}}>Gesamtanzahl deiner erhaltenen Bildbewertungen:{this.state.profil.bewertung.anzahlRatingsBildqualitaet}</span>
                    <ReactStars
                        value={this.state.profil.bewertung.bildqualitaet}
                        count={5}
                        size={25}
                        isHalf={true}
                        edit={false}
                        activeColor="#ffd700"/>
                </div>
                <div style={{width: "100%", marginTop:20}}>
                    <span>Bewertung deiner Tonqualität</span>
                    <br/>
                    <span style={{fontSize:12}}>Gesamtanzahl deiner erhaltenen Tonbewertungen: {this.state.profil.bewertung.anzahlRatingTonqualitaet}</span>
                    <ReactStars
                        value={this.state.profil.bewertung.tonqualitaet}
                        count={5}
                        size={25}
                        isHalf={true}
                        edit={false}
                        activeColor="#ffd700"/>
                </div>
            </div>
        </div>
    }

    getAnstehendeSpieleArea() {
        return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10, textAlign: "left"}}>
            <FunGamesArea funGames={this.state.profil.funGameDtos} userId={this.props.userId}
                          showDelete={true} callBackAfterDelete={() => this.ladeDaten()} />
        </div>
    }

    getVergangeneSpieleArea() {
        return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10, textAlign: "left"}}>
            <VergangeneSpieleArea games={this.state.profil.letzteGames} userId={this.props.userId} currentProfilUserId={this.props.profilId} />
        </div>
    }
}

ProfilFriend.propTypes = {
    userId: PropTypes.string.isRequired,
    profilId: PropTypes.string.isRequired,
};

export default ProfilFriend
