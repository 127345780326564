class NavigationItem {
    constructor(text, route) {
        this.text = text;
        this.route = route;
    }
}

export const NAVIGATION_ITEM = {
    HOME: new NavigationItem("Home",  ""),
    START: new NavigationItem("Startseite",  "/startseite"),
    TEAM_OVERVIEW: new NavigationItem("Mannschaftsübersicht",  "/teamoverview"),
    PLAYER_OVERVIEW: new NavigationItem("Spielerübersicht", "/playeroverview"),
    AGB: new NavigationItem("AGB",  "/agb"),
    IMPRESSUM: new NavigationItem("Impressum", "/impressum"),
    DATENSCHUTZ: new NavigationItem("Datenschutz",  "/datenschutz"),
    DASHBOARD: new NavigationItem("Dashboard", "/dashboard"),
    KACHELOVERVIEW: new NavigationItem("Startseite", "/startside"),
    UNDERCONSTRUCTION: new NavigationItem("UNDERCONSTRUCTION",  "/underconstruction"),
    TRAINING_OVERVIEW: new NavigationItem("Trainingsüberischt","/trainingoverview"),
    GAMEDAY_OVERVIEW: new NavigationItem("Spieltageübersicht",  "/gamedayoverview"),
    GAMEDAY_DETAIL: new NavigationItem("Details Spieltag",  "/gamedaydetail"),
    GAMESTATISTIK: new NavigationItem("Gamestatistik",  "/gamestatistik"),
    TRAINING_GAME_EDIT: new NavigationItem("Trainingsspiel bearbeiten",  "/trainginggameedit"),
    SCORING_SHORT: new NavigationItem("Scoring", "/scoringshort"),
    SCORING: new NavigationItem("Scoring", "/scoring"),
    GAME_VIDEO: new NavigationItem("Gamevideo",  "/gamevideo"),
    GAMEWATCH: new NavigationItem("Gamewatch",  "/gamewatch"),
    TWO_PLAYER_GAME_SCORE: new NavigationItem("Scoring an einem Rechner",  "/twoplayergamescoring"),
    PUBLIC_TRAINING_GAME_SCORE: new NavigationItem("Trainingsspiel scoren",  "/publictraingingscoring"),
    TRAINING_EDIT: new NavigationItem("Training bearbeiten",  "/traingingedit"),
    TRAININGSESSION_PUBLIC_RESULT_ENTER: new NavigationItem("Trainingssession im öffentlichen Bereich scoren",  "/sessionpublicscore"),
    PUBLIC_TOURNAMENT_LIVE_VIEW: new NavigationItem("",  "/public/tournament/liveview"),
    PUBLIC_EVENTOPERATOR_LIVE_VIEW: new NavigationItem("",  "/public/eventoperator/liveview"),
    PUBLIC_TOURNAMENTS: new NavigationItem("",  "/public/tournaments"),
    PUBLIC_TOURNAMENT: new NavigationItem("",  "/public/tournament"),
    PUBLIC_EVENTOPERATOR: new NavigationItem("",  "/public/eventoperator"),
    EXTERN_NUCALLBACK: new NavigationItem("",  "/extern/nucallback"),
    PARTICIPANT_CONFIRMATION: new NavigationItem("",  "/public/participantconfirmation"),
    PARTICIPANT_DEREGISTRATION: new NavigationItem("",  "/public/participantderegistration"),
    PUBLIC_SCORER_UPDATE: new NavigationItem("",  "/public/scorer/update"),
    PUBLIC_SCORING_VIEW: new NavigationItem("Trainingssession im öffentlichen Bereich scoren",  "/publicscoringview"),
    STATISTICS: new NavigationItem("Statistik",  "/statistics"),
    DOPPELSTATISTICSAREA: new NavigationItem("Doppelstatistik",  "/doppelstatisticsarea"),
    SETTINGS: new NavigationItem("Einstellungen",  "/settings"),
    TURNIER: new NavigationItem("Turnier",  "/tournament"),
    TURNIERBAUM: new NavigationItem("Turnierbaum",  "/tournamenttree"),
    TURNIERGRUPPE: new NavigationItem("Turniergrupppenphase",  "/tournamentgroup"),
    GAMINGAREA: new NavigationItem("Gaming Area",  "/gamingarea"),
    GAME_OVERVIEW: new NavigationItem("Game",  "/game"),
    LIGADETAIL: new NavigationItem("Ligadetail",  "/ligadetail"),
    HEADTOHEADCHAT: new NavigationItem("Head to Head Chat",  "/headtoheadchat"),
    EXTERNERLIGASPIELTAG: new NavigationItem("Ligaspieltag",  "/externligaspieltag"),
    ANYGAMESDETAIL: new NavigationItem("Any Games Detail", "/anygamesdetail"),
    LIGASPIELTAGDETAIL: new NavigationItem("Ligaspieltag", "/ligaspieltagdetail"),
    PUBLI_LIGASPIELTAGGASTEINGABE: new NavigationItem("Ligaspieltag Eingabemaske für Gastmannschaft", "/public/leaguematchdayinput"),
    TURNIERDETAIL: new NavigationItem("Turnierdetail",  "/turnierdetail"),
    TURNIERGRUPPENDETAIL: new NavigationItem("Turniergruppendetail",  "/turniergruppendetail"),
    KRATZERDETAIL: new NavigationItem("Kratzerdetail",  "/kratzerdetail"),
    WEEKENDLEAGUEDETAIL: new NavigationItem("Weekendleaguedetail",  "/weekendleaguedetail"),
    COMPLETEREGISTRATION: new NavigationItem("Registrierung abschliessen",  "/completeregistration"),
    ADMINISTRATION: new NavigationItem("Administration",  "/administration"),
    PROFIL: new NavigationItem("Profil",  "/profil"),
    USERSTATSTIK: new NavigationItem("Benutzerstatistik",  "/userstatistics"),
    PROFILFRIEND: new NavigationItem("Profil",  "/profilfriend"),
    KALENDER: new NavigationItem("Kalender",  "/kalender"),
    VERANSTALTER_UEBERSICHT: new NavigationItem("Veranstalter",  "/veranstalteruebersicht"),
    VERANSTALTER_LIVE_VIEW: new NavigationItem("Veranstalter",  "/veranstalterliveview"),
    VERANSTALTER_DETAIl: new NavigationItem("Veranstalter",  "/veranstalterdetail"),
    BEZAHLEN: new NavigationItem("Bezahlen",  "/bezahlen"),
    AUTOMAT: new NavigationItem("Automat",  "/automat"),
    BENUTZERSUCHE: new NavigationItem("Benutzersuche",  "/benutzersuche"),
    RANGLISTE: new NavigationItem("rangliste",  "/rangliste"),
    RANGLISTEEDIT: new NavigationItem("ranglisteedit",  "/ranglisteedit"),
};
