import React, {useRef} from 'react'
import ParticipantTable from "./ParticipantTable";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";


export default function PublicTournamentParticipantTab({tournamentId, participants, maxAnzahlTeilnehmer, playersSortedByTime, isBezahlung, isParticipantsMailConfirmation, supportsWebPush, pushSpielerIds, updateSubscriptions}) {
    const filterParticipantsOP = useRef(null);

    const [filterText, setFilterText] = React.useState("");

    const filterParticipants = (warteliste) => {
        let players = participants.filter(player => player.warteliste === warteliste);
        if( filterText === "") {
            return players;
        }
        let returnList = [];
        for (const player of players) {
            if( player.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(player);
            }
        }
        return returnList;
    }

    const getWarteliste = () => {
        return playersSortedByTime.filter(player => player.warteliste);
    }

    return <div>
        <div style={{display: "flex", marginTop:20, marginLeft: 15, marginBottom: 10}}>
            <OverlayPanel ref={filterParticipantsOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 100, width: 200}}>
                <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <InputText id="filterText" value={filterText}
                                       onChange={(e) => setFilterText(e.target.value)}/>
                            <label htmlFor="filterText">Filter</label>
                        </span>
                </div>
            </OverlayPanel>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {participants.length} {getWarteliste().length > 0 && " - Warteliste: " + getWarteliste().length}
                {maxAnzahlTeilnehmer !== null && maxAnzahlTeilnehmer !== undefined && " - max. Anzahl: " + maxAnzahlTeilnehmer}
            </div>
            <i className="pi pi-filter" style={{color: "white", width: 40, height: 30, fontSize: 30}}
               onClick={(e) => filterParticipantsOP.current.toggle(e)}/>
            {filterText !== "" &&
                <div style={{color: "white", marginRight: 10, fontSize: 20}}>Gefiltert: {filterParticipants(false).length}</div>
            }
        </div>
        <ParticipantTable tournamentId={tournamentId} participants={filterParticipants(false)}
                          isBezahlung={isBezahlung} isParticipantsMailConfirmation={isParticipantsMailConfirmation}
                          supportsWebPush={supportsWebPush} pushSpielerIds={pushSpielerIds}  updateSubscriptions={updateSubscriptions}/>
        {getWarteliste().length > 0 &&
            <>
                <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
                    <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl auf
                        Warteliste: {getWarteliste().length}</div>
                </div>
                <ParticipantTable tournamentId={tournamentId} participants={getWarteliste()}
                                  isBezahlung={isBezahlung} isParticipantsMailConfirmation={isParticipantsMailConfirmation}
                                  supportsWebPush={supportsWebPush} pushSpielerIds={pushSpielerIds} updateSubscriptions={updateSubscriptions}/>
            </>
        }
    </div>
}

