import React, {useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PlayerUtil from "../../../util/PlayerUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import UserSearch from "./UserSearch";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

export default function EventleiterVerwaltenDialog({tournamentId, admins, visible, onRefresh, onClose}) {

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [user, setUser] = useState(undefined);
    const [selectedAdmin, setSelectedAdmin] = useState(undefined);
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const saveAsAdmin = () => {
        setErrorMessage(undefined);
        let userid = user.id;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/saveadmin?tournamentid=" + tournamentId + "&userid=" + userid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde hinzugefügt");
                setUser(undefined);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Hinzufügen eines Admins: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Hinzufügen eines Admins: " + error.message);
            }
        );
    }

    const removeAdmin = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/removeadmin?tournamentid=" + tournamentId + "&userid=" + selectedAdmin;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde entfernt");
                setSelectedAdmin(undefined);
                setShowConfirmDelete(false);
                onRefresh();
            },
            responseNotOk => {

                setErrorMessage("Fehler beim Entfernen eines Admins: " + responseNotOk.message);
                setSelectedAdmin(undefined);
                setShowConfirmDelete(false);
            },
            error => {
                setErrorMessage("Fehler beim Entfernen eines Admins: " + error.message);
                setSelectedAdmin(undefined);
                setShowConfirmDelete(false);
            }
        );
    }

    const getAdminListe = () => {
        let rows = [];
        for (const admin of admins) {
            rows.push(<div key={"adminliste_key_" + admin.userId}>
                <img src="/images/delete_color.png"
                     style={{width:30, marginRight:10, marginTop:5}}
                     alt="Admin löschen"
                    onClick={() => {
                        setSelectedAdmin(admin.userId);
                        setShowConfirmDelete(true);
                    }}/>
                {admin.name}
            </div>)
        }
        return <div>
            {rows}
        </div>
    }

    return <DHDefaultDrawer  title="Eventleiter verwalten" show={visible} onClose={() => onClose()} children={<div style={{padding:20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div className="grid">
            <div className="col-fixed" style={{width:"120px"}}>Username:</div>
            <div className="col">
                <UserSearch
                    callBackError={(message) => {
                            setErrorMessage(message);
                            setUser(undefined)
                        }}
                    callBackClearMessages={() => setErrorMessage(undefined)}
                                               callBackInfo={(message) => setInfoMessage(message)}
                                                callBackUserFound={(user) => setUser(user)}/></div>
        </div>
        {user !== undefined &&
            <div className="grid" style={{marginTop:5}}>
                <Button label={"Den Benutzer " + PlayerUtil.anzeigeNameZumSpieler(user) + " als Eventleiter speichern"}
                        icon="pi pi-save" onClick={() => saveAsAdmin()} />
            </div>
        }
        <h3>Turnierleiterliste</h3>
        {getAdminListe()}
        {showConfirmDelete &&
        <ConfirmDialog visible={true}
                       callBackOnNein={() => {
                           setShowConfirmDelete(false);
                           setSelectedAdmin(undefined);
                       }}
                       callBackOnJa={() => removeAdmin()}
                       header="Eventleiter entfernen"
                       textOben=""
                       textUnten="Möchtest du den Eventleiter wirklich entfernen?"/>
        }
    </div>}/>
}