import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import ProfilFriend from "../../../components/views/liga/ProfilFriend";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const profilId = urlParams.get('profilId');
    return {profilId: profilId, userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilFriend))
