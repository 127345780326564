import React, {useEffect, useRef, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {ToggleButton} from "primereact/togglebutton";
import {InputTextarea} from "primereact/inputtextarea";
import {OverlayPanel} from "primereact/overlaypanel";
import {TabPanel, TabView} from "primereact/tabview";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TurnierSponsorenTabPanel from "./tabs/TurnierSponsorenTabPanel";
import TurnierAdminTabPanel from "./tabs/TurnierAdminTabPanel";
import LigaAktionenComponent from "./tabs/LigaAktionenComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import TournamentX01Area from "./TournamentX01Area";
import TournamentCricketArea from "./TournamentCricketArea";
import TurnierQRCode from "../liga/TurnierQRCode";
import RanglisteMarkerComponent from "../liga/RanglisteMarkerComponent";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import DHDateTimePicker from "../../general/DHDateTimePicker";

function WeekendLeagueVerwaltenDialog({tournamentId, callBackOnClose, callBackRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [tournament, setTournament] = useState(undefined);

    let [admins, setAdmins] = useState([]);
    let [sponsoren, setSponsoren] = useState([]);

    let [gameType, setGameType] = useState(undefined);

    let [id, setId] = useState(undefined);
    let [turnierstatus, setTurnierstatus] = useState(undefined);
    let [beginn, setBeginn] = useState(undefined);
    let [ende, setEnde] = useState(undefined);
    let [bezeichnung, setBezeichnung] = useState("");
    let [kennwort, setKennwort] = useState("");
    let [anzeigenAb, setAnzeigenAb] = useState(undefined);
    let [rangliste, setRangliste] = useState(false);
    let [ranglistemarkers, setRanglistemarkers] = useState([]);
    let [sollVomSystemGestartetWerden, setSollVomSystemGestartetWerden] = useState(false);
    let [beschreibung, setBeschreibung] = useState("");

    let [bestOfLegs, setBestOfLegs] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);
    let [modusId, setModusId] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);
    let [zufallszahlen, setZufallszahlen] = useState(null);
    let [zufallszahlenjedesleg, setZufallszahlenjedesleg] = useState(null);
    let [cutthroat, setCutthroat] = useState(null);

    const ranglisteOP = useRef(null);
    const kennwortOP = useRef(null);

    function istTurnierGestartet() {
        return turnierstatus === "gestartet";
    }

    function istTurnierAbgeschlossen() {
        return turnierstatus === "abgeschlossen";
    }

    function ladeTournament(id) {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.weekendleague + "/verwalten/" + id,
            json => {
                let tournament = json;
                setTournament(tournament);
                setId(tournament.id);
                setGameType(tournament.gameType);
                setTurnierstatus(tournament.tournamentstatus);
                setBezeichnung(tournament.bezeichnung);
                setRangliste(tournament.rangliste);
                setRanglistemarkers(tournament.ranglistemarkers);
                setSollVomSystemGestartetWerden(tournament.sollVomSystemGestartetWerden);
                if (tournament.beginn !== undefined && tournament.beginn !== null) {
                    setBeginn(tournament.beginn);
                }
                if (tournament.ende !== undefined && tournament.ende !== null) {
                    setEnde(tournament.ende);
                }
                let modusEntity = tournament.tournamentModusEntities[0];
                setFirstToLegs(modusEntity.firstToLegs);
                setBestOfLegs(modusEntity.bestOfLegs);
                if (tournament.gameType === "x01") {
                    setModusId(modusEntity.modusid);
                    setStartpunkte(modusEntity.startpunkte);
                } else if (tournament.gameType === "cricket") {
                    setZufallszahlen(modusEntity.zufallszahlen);
                    setZufallszahlenjedesleg(modusEntity.zufallszahlenjedesleg);
                    setCutthroat(modusEntity.cutthroat);
                }
                setBeschreibung(tournament.beschreibung);
                setKennwort(tournament.kennwort);
                if (tournament.anzeigenAb !== undefined && tournament.anzeigenAb !== null) {
                    setAnzeigenAb(tournament.anzeigenAb);
                }
                let adminsNachDemLaden = [];
                for (const tmpAdmin of tournament.administratoren) {
                    adminsNachDemLaden.push(tmpAdmin.userId);
                }
                setAdmins(adminsNachDemLaden);
                let sponsorenNachDemLaden = [];
                for (const tmpSponsor of tournament.tournamentSponsorenEntities) {
                    sponsorenNachDemLaden.push(tmpSponsor.sponsorEntity.id)
                }
                setSponsoren(sponsorenNachDemLaden);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    useEffect(() => {
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        ladeTournament(tournamentId);
    }, []);

    function save() {
        setErrorMessage(undefined);
        let modusArray = [];
        if (gameType === "x01") {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.WEEKENDLEAGUE.id,
                gameType: gameType,
                modusid: modusId,
                sets: 1,
                firstToLegs: firstToLegs,
                bestOfLegs: bestOfLegs,
                startpunkte: startpunkte
            });
        } else if (gameType === "cricket") {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.WEEKENDLEAGUE.id,
                gameType: gameType,
                sets: 1,
                firstToLegs: firstToLegs,
                bestOfLegs: bestOfLegs,
                zufallszahlen: zufallszahlen,
                zufallszahlenjedesleg: zufallszahlenjedesleg,
                cutthroat: cutthroat
            });
        }

        let adminsZumSpeichern = [];
        for (const admin of admins) {
            adminsZumSpeichern.push({userId: admin});
        }

        let data = {
            id: id, gameType: gameType,
            online: true,
            bezeichnung: bezeichnung, beschreibung: beschreibung,
            beginn: beginn, ende: ende, anzeigenAb: anzeigenAb,
            kennwort: kennwort,
            sollVomSystemGestartetWerden: sollVomSystemGestartetWerden,
            rangliste: rangliste, ranglistemarkers: ranglistemarkers,
            turniersystem: TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id,
            administratoren: adminsZumSpeichern,
            sponsorenZumSpeichern: sponsoren,
            tournamentModusEntities: modusArray,
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save",
            data,
            json => {
                ladeTournament(json.id);
                setInfoMessage("Turnier wurde erfolgreich gespeichert.");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    function showSaveButton() {
        if (!bezeichnung || !beginn || !ende) {
            return false;
        }
        if (gameType === "x01") {
            return (firstToLegs || bestOfLegs) && modusId && startpunkte;
        } else if (gameType === "cricket") {
            return (firstToLegs || bestOfLegs) && zufallszahlen !== null && cutthroat !== null;
        } else {
            return false;
        }
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" style={{marginRight: 5}} disabled={!showSaveButton()} onClick={() => save()}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackOnClose()}
                    className="p-button-secondary"/>
        </div>
    )

    return <DHDialog onClose={callBackOnClose} title="WeekendLeague anlegen/bearbeiten" buttons={dialogFooter} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="grid">
            <div className="col-fixed" style={{width: 120}}>Bezeichnung: *</div>
            <div className="col"><InputText disabled={istTurnierAbgeschlossen()} style={{width: "350px"}} value={bezeichnung}
                                            onChange={(e) => setBezeichnung(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 120}}>Startzeitpunkt: *</div>
            <div className="col">
                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={beginn} changeValue={setBeginn}/>
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 120}}>Endezeitpunkt: *</div>
            <div className="col">
                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={ende} changeValue={setEnde}/>
            </div>
        </div>
        <TabView style={{width: "100%", paddingTop: 10, paddingLeft: 10, paddingRight: 10, border: "none"}}>
            <TabPanel header="Modus">
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="flex align-items-center justify-content-center h-4rem"
                         style={{marginLeft: 5, marginRight: 5}}>
                        <ToggleButton style={{width: 200}} checked={gameType === 'x01'} offLabel="X01" onLabel="X01"
                                      onChange={(e) => setGameType('x01')} className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                        <ToggleButton style={{width: 200}} checked={gameType === 'cricket'} offLabel="Cricket"
                                      onLabel="Cricket" onChange={(e) => setGameType('cricket')} className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                    </div>
                </div>
                {gameType === "x01" &&
                    <TournamentX01Area showBestOfLegs={true} showPflichtfelder={true} showSets={false}
                                       firstToLegs={firstToLegs}
                                       bestOfLegs={bestOfLegs} modusId={modusId} startpunkte={startpunkte}
                                       onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                                       onBestOfLegsChanged={(bestOfLegs) => setBestOfLegs(bestOfLegs)}
                                       onModusIdChanged={(modusId) => setModusId(modusId)}
                                       onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
                }
                {gameType === "cricket" &&
                    <TournamentCricketArea bestOfLegs={bestOfLegs} showSets={false} showBestOfLegs={true} showPflichtfelder={true}
                                           firstToLegs={firstToLegs}
                                           zufallszahlen={zufallszahlen} zufallszahlenjedesleg={zufallszahlenjedesleg} cutthroat={cutthroat}
                                           onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                                           onBestOfLegsChanged={(bestOfLegs) => setBestOfLegs(bestOfLegs)}
                                           onZufallszahlenChanged={(value) => setZufallszahlen(value)}
                                           onZufallszahlenjedeslegChanged={(value) => setZufallszahlenjedesleg(value)}
                                           onCutthroatChanged={(value) => setCutthroat(value)}/>
                }
            </TabPanel>
            <TabPanel header="Optionale Eingaben">
                <div className="grid">
                    <div className="col-fixed" style={{width: 200}}>Beschreibung:</div>
                </div>
                <div className="grid">
                    <div className="col" style={{width: "100%"}}>
                        <InputTextarea rows={5} cols={50} maxLength={10000} value={beschreibung}
                                       onChange={(e) => setBeschreibung(e.target.value)}/>
                    </div>
                </div>
                <hr/>
                <div className="grid">
                    <div className={"col-12 md:col-12 lg:col-12"} style={{padding: 10}}>
                        <div className="grid">
                            <OverlayPanel ref={kennwortOP} showCloseIcon>
                                <div style={{marginBottom: 5}}>Die Eingabe des Kennworts ist optional. Sobald ein
                                    Kennwort angegeben wird, muss der Benutzer bei
                                    der Teilnahme ein Kennwort eingeben.
                                </div>
                            </OverlayPanel>
                            <div className="col-fixed" style={{width: 200}}>Kennwort für Turnierteilnahme:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => kennwortOP.current.toggle(e)}/>
                            </div>
                            <div className="col">
                                <InputText disabled={istTurnierAbgeschlossen()} value={kennwort} style={{width: 200}}
                                           onChange={(e) => setKennwort(e.target.value)}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Anzeigen ab:</div>
                            <div className="col">
                                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={anzeigenAb} changeValue={setAnzeigenAb}/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 200}}>Liga automatisch starten:</div>
                            <div className="col">
                                <ToggleButton className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onLabel="Ja" offLabel="Nein"
                                              checked={sollVomSystemGestartetWerden}
                                              onChange={(e) => setSollVomSystemGestartetWerden(!sollVomSystemGestartetWerden)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <OverlayPanel ref={ranglisteOP} showCloseIcon>
                                <div style={{marginBottom: 5}}>Sofern du das Turnier als Rangliste markierst, wird
                                    diese
                                    bei der Ranglistenberechnung berücktsichtigt.
                                </div>
                                <div style={{marginBottom: 5}}>Achtung!!! Du musst Ranglistenpunkte im System
                                    definieren. Bei Fragen wende dich an den Admin.
                                </div>
                            </OverlayPanel>
                            <div className="col-fixed" style={{width: 210}}>Rangliste:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => ranglisteOP.current.toggle(e)}/></div>
                            <div className="col-fixed" style={{width: "200px"}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={rangliste}
                                              onChange={(e) => setRangliste(!rangliste)}/>
                            </div>
                        </div>
                        {rangliste &&
                            <div className="grid">
                                <div className="col-fixed" style={{width: 210}}>Markers:</div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <RanglisteMarkerComponent
                                        onValueChange={(markers) => setRanglistemarkers(markers)}
                                        selectedMarkers={ranglistemarkers}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Admins">
                <TurnierAdminTabPanel changeAdmins={(admins) => setAdmins(admins)}
                                      onError={(message) => setErrorMessage(message)}
                                      admins={admins}/>
            </TabPanel>
            <TabPanel header="Sponsoren">
                <TurnierSponsorenTabPanel onError={(message) => setErrorMessage(message)} sponsoren={sponsoren}
                                          changeSponsoren={(sponsoren) => setSponsoren(sponsoren)}/>
            </TabPanel>
            <TabPanel header="Aktionen" disabled={id === undefined}>
                <LigaAktionenComponent showAsSidebar={false} proTournament={tournament} onInfo={(message) => setInfoMessage(message)}
                                                               onClose={() => ""}
                                                               callBackLoadNewTournament={(newId) => {
                                                               ladeTournament(newId);
                                                               callBackRefresh();
                                                           }}
                                                               callBackRefresh={() => {
                                                               ladeTournament(id);
                                                               callBackRefresh();
                                                           }}
                                                               callBackCloseDialog={() => {
                                                               callBackRefresh();
                                                               callBackOnClose();
                                                           }}
                                                               onClearMessages={() => setErrorMessage(undefined)}
                                                               onError={(message) => setErrorMessage(message)}/>
            </TabPanel>
            {id !== undefined &&
                <TabPanel header="QRCodes">
                    <TurnierQRCode tournamentId={id} onlineTournament={true} tournamentSystem={TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id} bezeichnung={bezeichnung}/>
                </TabPanel>
            }
        </TabView>
        <div style={{marginTop: 20, fontSize: 10}}>TurnierId: {id}</div>
    </>}/>
}

WeekendLeagueVerwaltenDialog.propTypes = {
    callBackOnClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    tournamentId: PropTypes.string,
};

export default WeekendLeagueVerwaltenDialog
