export const NEW_ONLINEFRIENDS_OVER_WEBSOCKET = 'NEW_ONLINEFRIENDS_OVER_WEBSOCKERT';

export const newOnlinefriendsOverWebsocket = (friends) => ({
    type: NEW_ONLINEFRIENDS_OVER_WEBSOCKET,
    value: friends,
});

export function onlinefriendsOverWebsocket(friends) {
    return function (dispatch) {
        dispatch(newOnlinefriendsOverWebsocket(friends));
    };
}