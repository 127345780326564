import React, {useEffect, useState} from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";
import DHDialog from "./DHDialog";
import {LigaSpieltagModusComponent} from "./LigaSpieltagModusComponent";
import {InputText} from "primereact/inputtext";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import ErrorInfoComponent from "./ErrorInfoComponent";
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {LigaSpieltagPaarkreuzsystemComponent} from "./LigaSpieltagPaarkreuzsystemComponent";
import ConfirmDialog from "./ConfirmDialog";

const VALUES = [
    {name: 'Auswahl löschen', code: undefined},
    {name: 'H1', code: 'H1'},
    {name: 'H2', code: 'H2'},
    {name: 'H3', code: 'H3'},
    {name: 'H4', code: 'H4'},
    {name: 'H5', code: 'H5'},
    {name: 'H6', code: 'H6'},
    {name: 'H7', code: 'H7'},
    {name: 'H8', code: 'H8'},
    {name: 'H9', code: 'H9'},
    {name: 'H10', code: 'H10'},
    {name: 'H11', code: 'H11'},
    {name: 'H12', code: 'H12'},
    {name: 'G1', code: 'G1'},
    {name: 'G2', code: 'G2'},
    {name: 'G3', code: 'G3'},
    {name: 'G4', code: 'G4'},
    {name: 'G5', code: 'G5'},
    {name: 'G6', code: 'G6'},
    {name: 'G7', code: 'G7'},
    {name: 'G8', code: 'G8'},
    {name: 'G9', code: 'G9'},
    {name: 'G10', code: 'G10'},
    {name: 'G11', code: 'G11'},
    {name: 'G12', code: 'G12'},
];


export function LigaSpieltagPaarkreuzsystemVerwaltenDialog({ eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedPaarkreuzSystemId, setSelectedPaarkreuzSystemId] = useState(undefined);

    const [working, setWorking] = useState(false);
    const [saveCounter, setSaveCounter] = useState(1);


    const [modus, setModus] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");
    const [players, setPlayers] = useState([]);


    useEffect(() => {
        if( selectedPaarkreuzSystemId === undefined) {
            setPlayers([]);
        } else {
            ladeDetailDaten();
        }
    }, [selectedPaarkreuzSystemId]);

    const ladeDetailDaten = () => {
        setWorking(true);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/paarkreuzsystemdetails/" + selectedPaarkreuzSystemId,
            dtos => {
                let tmp = [];
                for (const dto of dtos) {
                    tmp.push({spielNr: dto.spiel, playerNr: dto.spieler, type: dto.type, value: dto.wert});
                }
                setPlayers(tmp);
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
                setWorking(false);
            });
    }

    const deletePaarkreuzsystem = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.eventoperator + "/paarkreuzsystem/delete/" + selectedPaarkreuzSystemId,
            {},
            () => {
                setInfoMessage("Löschen erfolgreich.");
                setSelectedPaarkreuzSystemId(undefined);
                setPlayers([]);
                setShowDeleteDialog(false);
                setSaveCounter(saveCounter + 1);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen! " + responseNotOk.message);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler beim Löschen! " + error.message);
                setShowDeleteDialog(false);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        let dtos = [];
        for (const player of players) {
            dtos.push({spiel: player.spielNr, type: player.type, spieler: player.playerNr, wert: player.value});
        }
        let data = {id: selectedPaarkreuzSystemId, bezeichnung: bezeichnung, modus: modus, paarkreuzsystemDetailDtos:dtos};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/paarkreuzsystem/save/" + eventOperatorId,
            data,
            (id) => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                setSelectedPaarkreuzSystemId(id);
                setSaveCounter(saveCounter + 1);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern! " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Speichern! " + error.message)
            }
        );
    }

    const getPlayerValue = (spielNr, playerNr, type) => {
        let tmp = players.filter(t => t.spielNr === spielNr && t.playerNr === playerNr && t.type === type);
        if( tmp.length === 1) {
            return tmp[0].value;
        }
        return undefined;
    }

    const changePlayer = (spielNr, playerNr, type, value) => {
        let tmp = [];
        for (const element of players) {
            if( element.spielNr !== spielNr || element.playerNr !== playerNr || element.type !== type) {
                tmp.push(element);
            }
        }
        tmp.push({spielNr: spielNr, playerNr: playerNr, type: type, value: value});
        setPlayers(tmp);
    }

    const getEZRow = (spielNr) => {
        return <div style={{marginTop:10}}>
            <div style={{fontWeight:"bold", marginBottom:5}}>Spiel: {spielNr} - Einzel</div>
            <div style={{display:"flex", alignContent:"center", alignItems:"center"}}>
                <div style={{minWidth: 100, width:100}}>
                    <DHSelectionComponent value={getPlayerValue(spielNr, 1, "HEIM")} onChange={(value) => changePlayer(spielNr, 1, "HEIM", value)} disabled={false} values={VALUES} title="Auswahl"/>
                </div>
                <div style={{minWidth: 50, width:50}}>vs.</div>
                <div style={{minWidth: 100, width:100}}>
                    <DHSelectionComponent value={getPlayerValue(spielNr, 1, "GAST")} onChange={(value) => changePlayer(spielNr, 1, "GAST", value)} disabled={false} values={VALUES} title="Auswahl"/>
                </div>
            </div>
        </div>
    }

    const getDPRow = (spielNr) => {
        return <div style={{marginTop: 10}}>
            <div style={{fontWeight: "bold", marginBottom: 5}}>Spiel: {spielNr} - Doppel</div>
            <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
                <div style={{minWidth: 100, width: 100}}>
                    <div>
                        <DHSelectionComponent value={getPlayerValue(spielNr, 1, "HEIM")} onChange={(value) => changePlayer(spielNr, 1, "HEIM", value)} disabled={false} values={VALUES} title="Auswahl"/>
                    </div>
                    <div style={{marginTop:5}}>
                        <DHSelectionComponent value={getPlayerValue(spielNr, 2, "HEIM")} onChange={(value) => changePlayer(spielNr, 2, "HEIM", value)} disabled={false} values={VALUES} title="Auswahl"/>
                    </div>
                </div>
                <div style={{minWidth: 50, width: 50}}>vs.</div>
                <div style={{minWidth: 100, width: 100}}>
                    <div>
                        <DHSelectionComponent value={getPlayerValue(spielNr, 1, "GAST")} onChange={(value) => changePlayer(spielNr, 1, "GAST", value)} disabled={false} values={VALUES} title="Auswahl"/>
                    </div>
                    <div style={{marginTop:5}}>
                        <DHSelectionComponent value={getPlayerValue(spielNr, 2, "GAST")} onChange={(value) => changePlayer(spielNr, 2, "GAST", value)} disabled={false} values={VALUES} title="Auswahl"/>
                    </div>
                </div>
            </div>
        </div>
    }


    const getGamesArea = () => {
        if (modus === undefined) {
            return <div style={{marginTop: 20}}>Bitte Modus auswählen...</div>
        }
        let rows = [];
        let spielNr = 1;
        let array = modus.split("|");
        for (const element of array) {
            let row = element.split("x");
            let anzahl = parseInt(row[0]);
            let type = row[1];
            for (let i = 0; i < anzahl; i++) {
                if (type === "EZ") {
                    rows.push(getEZRow(spielNr));
                } else if (type === "DP") {
                    rows.push(getDPRow(spielNr));
                }
                spielNr++;
            }
            rows.push(<hr/>);
        }
        return <div style={{marginTop: 20}}>
            {rows}
        </div>


    }

    const getAuswahlArea = () => {
        return <div style={{padding: 10}}>
            <div style={{display: "flex", alignContent: "center", alignItems: "center", marginBottom: 10}}>
                <span style={{marginRight: 10}}>Auswahl Paarkreuzsystem:</span>
                <LigaSpieltagPaarkreuzsystemComponent onChange={(paarkreuzsystem) => {
                    setSelectedPaarkreuzSystemId(paarkreuzsystem ? paarkreuzsystem.id : undefined);
                    if (paarkreuzsystem === undefined) {
                        setModus(undefined);
                        setBezeichnung("");
                    } else {
                        setModus(paarkreuzsystem.modus);
                        setBezeichnung(paarkreuzsystem.bezeichnung);
                    }
                }} showChangeDialog={true} counter={saveCounter}
                                                      eventOperatorId={eventOperatorId} disabled={false} selectedPaarkreuzsystemId={selectedPaarkreuzSystemId}/>
                <Button icon={PrimeIcons.TRASH} style={{marginLeft:20}} disabled={!selectedPaarkreuzSystemId}
                              onClick={() => setShowDeleteDialog(true)}/>
            </div>
        </div>
    }

    const getArea = () => {
        return <div style={{padding: 10}}>
            <div>
                <div style={{marginBottom: 5}}>
                    Gib dem Paarkreuzsystem eine sprechende Bezeichnung, so dass du dieses dann im entsprechenden Ligaspieltag auswählen kannst.<br/>
                    z.B. Mannschaft III oder 4EZ-4EZ-4EZ-4EZ<br/><br/>
                    Sobald du im Ligaspieltag das Paarkreuzsystem auswählst, wird die Aufstellung für dich nach dem Setzen der Spieler automatisch vervollständigt.
                </div>
                <div style={{marginTop: 10, marginBottom:10}}>
                    Wichtiger Hinweis: Du musst im Spielplan nicht alle Werte füllen. Sofern z.B. ein Doppel nicht fest gesetzt wird, lass hier den Wert einfach leer.
                </div>
                <div>
                    <InputText value={bezeichnung} style={{width: 200}}
                               placeholder="Bezeichnung"
                               onChange={(e) => setBezeichnung(e.target.value)}/>
                </div>
            </div>
            <div style={{display: "flex", alignContent: "center", alignItems: "center", marginTop:10}}>
                <div style={{width: 100}}>Modus:</div>
                <LigaSpieltagModusComponent id={modus} disabled={false} onChange={(id) => {
                    setModus(id);
                    setPlayers([]);
                }}/>
            </div>
            {getGamesArea()}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Paarkreuzsystem" buttons={[]} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {getAuswahlArea()}
        {working && <ProgressSpinner style={{width:100, height:100}}/>}
        {!working &&  <>
            {getArea()}
            <div>
                <Button label="Speichern" icon={PrimeIcons.SAVE} onClick={() => save()} style={{marginLeft: 10}} disabled={bezeichnung === "" || modus === undefined}/>
            </div>
        </>}
        {showDeleteDialog && <ConfirmDialog header="Löschen" textOben={"Möchtest du das Paarkreuzsytem wirklich löschen?"}
                                            visible={true}
                                            callBackOnJa={() => deletePaarkreuzsystem()}
                                            callBackOnNein={() => setShowDeleteDialog(false)}/>}
    </>}/>
}
