import React from "react";
import LoginContainer from "../../../../containers/LoginContainer";
import {Button} from "primereact/button";
import DHDefaultDrawer from "../../../general/DHDefaultDrawer";


export default function LoginDialog({onClose}) {
    return <DHDefaultDrawer onClose={() => onClose()} show={true} title="Login" children={<div style={{padding: 10}}>
        <LoginContainer/>
        <div style={{marginTop: 10}}>Um Onlineligen oder Onlineturniere anschauen zu können oder sofern du dich bei einer Liga bzw. Turnier anmelden möchtest, musst du angemeldet sein.</div>
        <div style={{marginTop: 10}}>Sofern du noch keinen Zugang zu darthelfer hast, kannst du dir gerne einen kostenlosen Zugang anlegen.</div>
        <div style={{marginTop: 10}}>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
        </div>
    </div>}/>
}
