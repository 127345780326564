import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import TournamentPlayerEventOperatorSelectDialog from "../../tournament/TournamentPlayerEventOperatorSelectDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";

export default function TeilnehmerPrioArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showSearchDialogIgnore, setShowSearchDialogIgnore] = useState(false);
    const [showSearchDialogBoard, setShowSearchDialogBoard] = useState(false);
    const [showHinweis, setShowHinweis] = useState(false);
    const [selectedSpielerIdIgnore, setSelectedSpielerIdIgnore] = useState(undefined);
    const [selectedSpielerNameIgnore, setSelectedSpielerNameIgnore] = useState(undefined);
    const [selectedSpielerIdBoard, setSelectedSpielerIdBoard] = useState(undefined);
    const [selectedSpielerNameBoard, setSelectedSpielerNameBoard] = useState(undefined);
    const [selectedBoardId, setSelectedBoardId] = useState(undefined);

    const [boardsVergeben, setBoardsVergeben] = useState([]);
    const [boards, setBoards] = useState([]);
    const [playerIgnoreListe, setPlayerIgnoreListe] = useState([]);

    const [playerFixedListe, setPlayerFixedListe] = useState([]);

    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(undefined);

    useEffect(() => {
        ladeBoards();
        ladeTournaments();
        ladeTeilnehmerIgnore();
        ladeTeilnehmerBoards();
    }, []);

    const ladeBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/"+ eventOperatorId,
            dto => {
                setBoards(dto.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Boards: " + error.message);
            }
        );
    }

    const ladeTournaments = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/tournaments/started/"+ eventOperatorId,
            json => {
                setTournaments(json);
                if( json.length === 1) {
                    setSelectedTournament(json[0]);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Turniere: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Turniere: " + error.message);
            }
        );
    }

    const ladeTeilnehmerBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/board/player/fixed/" + eventOperatorId,
            json => {
                setPlayerFixedListe(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const ladeTeilnehmerIgnore = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/board/player/ignore/" + eventOperatorId,
            json => {
                setPlayerIgnoreListe(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const saveTeilnehmerBoard = () => {
        setErrorMessage(undefined);
        let boards = [];
        for (const boardElement of boardsVergeben) {
            boards.push({boardId: boardElement.boardId});
        }
        let name = getSpielerNameString(selectedSpielerNameBoard);
        let data = {idShort: selectedSpielerIdBoard, name: name, boards: boards};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save/board/player/fixed/" + eventOperatorId,
            data,
            () => {
                setSelectedSpielerIdBoard(undefined);
                setSelectedSpielerNameBoard(undefined);
                setBoardsVergeben([]);
                ladeTeilnehmerBoards();
                setInfoMessage("Speichern war erfolgreich");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const saveTeilnehmerIgnore = () => {
        setErrorMessage(undefined);
        let name = getSpielerNameString(selectedSpielerNameIgnore);
        let tournamentId = selectedTournament !== undefined ? selectedTournament.id : undefined;
        let tournamentBezeichnung = selectedTournament !== undefined ? selectedTournament.bezeichnung : undefined;
        let data = {idShort: selectedSpielerIdIgnore, name: name,  tournamentId: tournamentId, tournamentBezeichnung: tournamentBezeichnung};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save/board/player/ignore/" + eventOperatorId,
            data,
            () => {
                setSelectedSpielerIdIgnore(undefined);
                setSelectedSpielerNameIgnore(undefined);
                setSelectedTournament(undefined);
                ladeTeilnehmerIgnore();
                setInfoMessage("Speichern war erfolgreich");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteTeilnehmerIgnore = (ignoreId) => {
        setErrorMessage(undefined);
        let data = {idShort: selectedSpielerIdIgnore, name: selectedSpielerNameIgnore};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/delete/board/player/ignore/" + eventOperatorId + "/" + ignoreId,
            data,
            () => {
                setInfoMessage("Löschen war erfolgreich");
                ladeTeilnehmerIgnore();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteTeilnehmerFixed = (idShort) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/delete/board/player/fixed/" + eventOperatorId + "/" + idShort,
            {},
            () => {
                setInfoMessage("Löschen war erfolgreich");
                ladeTeilnehmerBoards();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getSpielerIgnoreTableArea = () => {
        let returnListe = [];
        returnListe.push(<div key={"key_ignore_id_header"} style={{marginTop:20}}><h3>Liste Spieler</h3></div>)
        if( playerIgnoreListe.length === 0 ) {
            returnListe.push(<div key={"key_ignore_id_empty"} style={{marginTop:20}}>Keine Spieler gespeichert!</div>)
        }
        for (const element of playerIgnoreListe) {
            returnListe.push(<div key={"key_ignore_id_" + element.id} style={{marginBottom:5}}>
                <Button icon="pi pi-trash" tooltip="Löschen"
                        style={{marginRight: "5px"}}
                        onClick={() => deleteTeilnehmerIgnore(element.id)}/>
                {element.name} - SpielerId: {element.idShort} {element.tournamentBezeichnung !== null && element.tournamentBezeichnung !== undefined && "/" + element.tournamentBezeichnung}</div>)
        }
        return returnListe;
    }

    const getSpielerFixedTableArea = () => {
        let returnListe = [];
        returnListe.push(<div key={"key_fixed_id_header"} style={{marginTop:20}}><h3>Liste Spieler</h3></div>)
        if( playerFixedListe.length === 0 ) {
            returnListe.push(<div key={"key_fixed_id_empty"} style={{marginTop:20}}>Keine Spieler gespeichert!</div>)
        }

        for (const element of playerFixedListe) {
            let boardString = "";
            for (const boardId of element.boards) {
                let tmpArray = boards.filter(value => value.id === boardId);
                if( tmpArray.length === 1) {
                    boardString += tmpArray[0].bezeichnung + ", ";
                }
            }

            returnListe.push(<div key={"key_fixed_id_" + element.id} style={{marginBottom:5}}>
                <Button icon="pi pi-trash" tooltip="Löschen"
                        style={{marginRight: "5px"}}
                        onClick={() => deleteTeilnehmerFixed(element.idShort)}/>
                {element.name} - SpielerId: {element.idShort} / Boards: {boardString}</div>)
        }
        return returnListe;
    }

    const getSpielerNameString = (nameArray) => {
        let name = "";
        if( nameArray !== undefined) {
            for (const tmp of nameArray) {
                name += tmp + ", ";
            }
        }
        return name;
    }

    const getBoardComponent = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const b of boards) {
            if( b.eventOperatorIdShared )  {
                tmp.push({name: <div>
                        <div>{b.bezeichnung}</div>
                        <div style={{fontSize:12}}>
                            {b.beschreibungShared}
                        </div>
                    </div>, code: b.id});
            } else {
                tmp.push({name: b.bezeichnung, code: b.id});
            }
        }
        return <div key={"key_dh_component"}>
            <DHSelectionComponent title="Auswahl" disabled={false} onChange={(id) => setSelectedBoardId(id)} value={selectedBoardId} values={tmp}/>
        </div>
    }

    const getTournamentSelection = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const t of tournaments) {
            tmp.push({name: t.bezeichnung, code: t.id});
        }
        return <div>
            <span style={{marginRight: 10}}>Turnierauswahl:</span>
            <DHSelectionComponent title="Auswahl" disabled={false} value={selectedTournament ? selectedTournament.id : undefined} values={tmp}
                                  onChange={(id) => {
                                      if( id === undefined) {
                                          setSelectedTournament(undefined);
                                      } else {
                                          let tournament = tournaments.filter(t => t.id === id)[0];
                                          setSelectedTournament(tournament);
                                      }
                                  }}/>
        </div>
    }

    const getTeilnehmerIgnorierenArea = () => {
        let name = getSpielerNameString(selectedSpielerNameIgnore);
        return <>
            <h3>Teilnehmer bei der Boardvergabe ignorieren:</h3>
            <div>
                <Button label="Vorhandenen Spieler suchen und auswählen" icon="pi pi-search-plus"
                        onClick={() => {
                            setShowSearchDialogIgnore(true);
                        }}/>
            </div>
            {selectedSpielerIdIgnore !== undefined &&
                <div style={{marginTop:10, fontSize:20}}>SpielerId: {selectedSpielerIdIgnore}</div>
            }
            {name !== "" &&
                <div style={{marginTop:5, fontSize:20}}>Name: {name}</div>
            }
            {selectedSpielerIdIgnore !== undefined && <>
                <div style={{marginTop: 10}}>Hinweis: Sobald du ein Turnier wählst, wird der Spieler bei der Boardvergabe nur bei diesem Turnier ignoriert. Sobald kein Turnier gewählt wird, wird der Spieler in allen Turnieren bei der Boardvergabe ignoriert.</div>
                <div style={{marginTop: 10, marginBottom:10}}>
                    {getTournamentSelection()}
                </div>
                <div style={{marginTop:10}}>
                    <Button label="Teilnehmer zum Ignorieren speichern" icon="pi pi-save" onClick={() => saveTeilnehmerIgnore()}/>
                </div>
            </>
            }
            {getSpielerIgnoreTableArea()}
        </>
    }

    const removeBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsVergeben];
        tmpBoardsVergeben = tmpBoardsVergeben.filter(value => value.boardId !== boardId);
        setBoardsVergeben(tmpBoardsVergeben);
    }

    const addBoard = () => {
        let tmpBoardsVergeben = [...boardsVergeben];
        tmpBoardsVergeben.push({boardId: selectedBoardId});
        setSelectedBoardId(undefined);
        setBoardsVergeben(tmpBoardsVergeben);
    }

    const getTeilnehmerBoardvergabeArea = () => {
        let name = getSpielerNameString(selectedSpielerNameBoard);
        return <>
            <h3>Bestimmte Boards für Teilnehmer vergeben:</h3>
            <div style={{fontSize:14, marginBottom:20}}>Hinweis: Sobald ein Spieler geändert werden soll, kannst du diesen einfach erneut suchen und die Boards für ihn erneut vergeben. Wir überschreiben in diesem Fall die Einstellung für den Spieler.</div>
            <div>
                <Button label="Vorhandenen Spieler suchen und auswählen" icon="pi pi-search-plus"
                        onClick={() => {
                            setShowSearchDialogBoard(true);
                        }}/>
            </div>
            {selectedSpielerIdBoard !== undefined && <>
                <div style={{marginTop:10, fontSize:20}}>SpielerId: {selectedSpielerIdBoard}</div>
                <div style={{marginTop:5, fontSize:20}}>Name: {name}</div>
                <div style={{display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center", marginTop:20}}>
                    {getBoardComponent()}
                    <Button icon="pi pi-plus-circle" style={{marginLeft:10, height:30, width:30}} disabled={selectedBoardId === undefined || isBoardVergeben()} onClick={() => addBoard()}/>
                </div>
            </>
            }
            {getBoardVergebeneArea()}
            {selectedSpielerIdBoard !== undefined &&
                <div style={{marginTop:10}}>
                    <Button label="Board(s) zum Teilnehmer speichern" icon="pi pi-save" disabled={boardsVergeben.length === 0} onClick={() => saveTeilnehmerBoard()}/>
                </div>
            }
            {getSpielerFixedTableArea()}
        </>
    }

    const isBoardVergeben = () => {
        return boardsVergeben.filter(tmpBoard => tmpBoard.boardId === selectedBoardId).length === 1;
    }

    const getBoardVergebeneArea = () => {
        let rows = [];
        for (const board of boardsVergeben) {
            let tmpArray = boards.filter(value => value.id === board.boardId);
            if( tmpArray !== null && tmpArray !== undefined && tmpArray.length === 1) {
                let tmpBoard = tmpArray[0];
                rows.push(<div key={"key_board_" + board.boardId} style={{marginTop:10, fontSize:16}}><Button icon="pi pi-minus-circle" style={{marginRight:10, height:30, width:30}} onClick={() => removeBoard(board.boardId)}/>{tmpBoard.bezeichnung}</div>)
            }
        }
        return <div>
            {rows}
        </div>
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis" toggleable collapsed={!showHinweis} onToggle={(e) => setShowHinweis(!showHinweis)} style={{marginBottom:10}}>
            <div style={{fontSize:16}}>Auf diesem Dialog kannst du Einstellungen zur Boardvergabe zum Teilnehmer durchführen.<br/><br/>
                1.) Ein Teilnehmer kann aktuell nicht spielen und wird aus diesem Grund bei der Boardvergabe ignoriert.<br/><br/>
                2.) Ein Teilnehmer kann, z.B. aufgrund von Handicaps (Rollstuhlfahrer oder ähnlich), nur an einem bestimmten Board spielen.<br/>
                Sobald ein Spiel vom Spieler aufgerufen werden soll und das entsprechend gewählte Board ist nicht frei, wird das Spiel bei der nächsten Gelegenheit auf dem gewählten Board aufgerufen.
            </div>
        </Fieldset>
        {getTeilnehmerIgnorierenArea()}
        <hr style={{marginTop:40, marginBottom:20}}/>
        {getTeilnehmerBoardvergabeArea()}
        {(showSearchDialogIgnore || showSearchDialogBoard) && <TournamentPlayerEventOperatorSelectDialog
            eventOperatorId={eventOperatorId}
            callBackOnClose={() => {
                setShowSearchDialogIgnore(false);
                setShowSearchDialogBoard(false);
            }}
            callBackOnSelect={(spielerId, nameArray) => {
                if( showSearchDialogIgnore ) {
                    setSelectedSpielerIdIgnore(spielerId);
                    setSelectedSpielerNameIgnore(nameArray);
                } else {
                    setSelectedSpielerIdBoard(spielerId);
                    setSelectedSpielerNameBoard(nameArray);
                }
                setShowSearchDialogIgnore(false);
                setShowSearchDialogBoard(false);
            }}/>}
    </>
}
