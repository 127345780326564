import React from 'react'
import {Toast} from 'primereact/toast';
import {Messages} from 'primereact/messages';
import {Button} from "primereact/button";
import {GameBestOfLegsComponent, GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {GameSetsComponent} from "../../general/GameSetsComponent";
import {GameModusComponent} from "../../general/GameModusComponent";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import UserSearch from "./UserSearch";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {Dropdown} from "primereact/dropdown";
import DoppelpaarungSearch from "./DoppelpaarungSearch";
import {NavLink} from "react-router-dom";
import {ToggleButton} from 'primereact/togglebutton';
import {SelectButton} from 'primereact/selectbutton';

class GameDetail extends React.Component {

    constructor(props) {
        super(props);

        this.cricketzufallszahlen = [
            {name: 'Ja', value: true},
            {name: 'Nein', value: false},
        ];
        this.cricketcutthroat = [
            {name: 'Ja', value: true},
            {name: 'Nein', value: false},
        ];

        let { cookies } = props;
        let modusid = this.getCookieValue(cookies,"darthelfer_game_anlegen_modusid", false);
        let startpunkte = this.getCookieValue(cookies,"darthelfer_game_anlegen_startpunkte", true);
        let bestOfLegs = this.getCookieValue(cookies,"darthelfer_game_anlegen_bestoflegs", true);
        let firstToLegs = this.getCookieValue(cookies,"darthelfer_game_anlegen_firsttolegs", true);
        let sets = this.getCookieValue(cookies,"darthelfer_game_anlegen_sets", true);
        if( sets === null || sets === undefined) {
            sets = 1;
        }
        this.state = {modusid: modusid, startpunkte: startpunkte, sets: sets, bestOfLegs: bestOfLegs, firstToLegs: firstToLegs,
            selectedDoppelpaarung:undefined, selectedGegner:undefined, showGameType: true};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();

        this.changeModusId = this.changeModusId.bind(this);
        this.changeStartpunkte = this.changeStartpunkte.bind(this);
        this.changeBestOfLegs = this.changeBestOfLegs.bind(this);
        this.changeFirstToLegs = this.changeFirstToLegs.bind(this);
        this.setCookie = this.setCookie.bind(this);
    }

    getCookieValue(cookies, name, toIntValue) {
        let value = cookies.get(name);
        let gefuellt = value !== null && value !== undefined && value !== "";
        if( toIntValue ) {
            return gefuellt ? parseInt(value) : undefined;
        }
        return gefuellt ? value : undefined;
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showError(message) {
        console.log("Error");
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.doppelpartner + "/all",
            json => {
                this.setState({doppelpaarungen: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    changeModusId(modusid) {
        this.setState({modusid: modusid})
        this.setCookie("darthelfer_game_anlegen_modusid", modusid);
    }

    changeStartpunkte(startpunkte) {
        this.setState({startpunkte: startpunkte})
        this.setCookie("darthelfer_game_anlegen_startpunkte", startpunkte);
    }

    changeBestOfLegs(bestOfLegs) {
        this.setState({bestOfLegs: bestOfLegs})
        this.setCookie("darthelfer_game_anlegen_bestoflegs", bestOfLegs);
    }

    changeFirstToLegs(firstToLegs) {
        this.setState({firstToLegs: firstToLegs})
        this.setCookie("darthelfer_game_anlegen_firsttolegs", firstToLegs);
    }

    changeSets(sets) {
        this.setState({sets: sets})
        this.setCookie("darthelfer_game_anlegen_sets", sets);
    }

    setCookie(name, value) {
        const { cookies } = this.props;
        var date = new Date();
        date.setDate(date.getDate() + 365);
        let options = {expires: date, path: '/'};
        cookies.set(name, value != null && value !== undefined ? value : "", options);
    }

    save() {
        this.messages.clear();

        let startpunkte = this.state.startpunkte;
        let sets = this.state.sets;
        let firstToLegs = this.state.firstToLegs;
        let bestOfLegs = this.state.bestOfLegs;
        let modusid = this.state.modusid;


        let gegner = this.state.gegner;
        let anwurf = this.state.anwurf;
        let gast = gegner === "gast" ? true : false;
        let herausforderung = gegner === "herausforderung" ? true : false;

        let spielerId;
        let gegenerId;
        let doppelMatch = false;
        if( this.state.typ === "doppelgame" ) {
            spielerId = this.state.selectedDoppelpaarung.id;
            gegenerId = this.state.selectedGegner.id;
            doppelMatch = true;
        } else {
            if( this.props.gegnerId !== null && this.props.gegnerId !== undefined) {
                gegenerId = this.props.gegnerId;
            } else {
                gegenerId = this.state.user !== undefined ? this.state.user.id : null;
            }
        }

        this.setState({starting:true});
        let data = {id: this.state.id, gameType: this.state.gametype, spielerId: spielerId,
            gegnerId: gegenerId, gast: gast , sets: sets, firstToLegs: firstToLegs,
            bestOfLegs: bestOfLegs, modusid:modusid, startpunkte:startpunkte, herausforderung: herausforderung, anwurf: anwurf, doppelMatch: doppelMatch,
            zufallszahlen: this.state.zufallszahlen, zufallszahlenjedesleg: this.state.zufallszahlenjedesleg, cutthroat: this.state.cutthroat};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.fungame,
            data,
            json => {
                this.setState({funGameId: json.id, gameId: json.gameid, playerId: json.playerid, starting:false});
                this.showInfo("Spiel wurde gespeichert.");
            },
            responseNotOk => {
                console.log("notok:", responseNotOk);
                this.setState({starting:false});
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                console.log("Error:", error);
                this.setState({starting:false});
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    getDescription(description) {
        return <div className="grid" style={{textAlign:"left"}}>
            <div className="col" style={{fontSize:12}}>
                {description}
            </div>
        </div>
    }

    getAreaTypAuswahl() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left", cursor:"pointer", marginBottom:20}} onClick={() => {
                    let url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=dh_" + this.props.userId+ "&username=test";
                    window.open(url, '_blank', 'location=yes,height=400,width=400,scrollbars=true,status=true')}
                }>
                    <div className="col"><img alt="" style={{
                        marginRight: 20,
                        marginLeft: 0,
                        width: 30,
                        marginTop: 10
                    }} src="/images/webcam.png"/>
                        Webcam-Test
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.typ === 'einzelgame'} offLabel="Einzel-Game" onLabel="Einzel-Game" onChange={(e) => this.setState({typ: 'einzelgame'})}/>
                    </div>
                </div>
                {this.getDescription("Ganz normales Game gegen einen Gast, eine Herausforderung oder einem Onlinespieler.")}
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}} checked={this.state.typ === 'doppelgame'} offLabel="Doppel-Game" onLabel="Doppel-Game" onChange={(e) => this.setState({typ: 'doppelgame'})}/>
                    </div>
                </div>
                {this.getDescription("Du wählst in der Folge deinen Doppelpartner (anhand vom Funnamen - Anlage über Profil) und dann den gegnerischen Doppelpartner.")}
            </div>
        </div>
    }

    getAreaEinzelgame() {
        if( this.props.gegnerUsername !== null && this.props.gegnerUsername !== undefined ) {
            return <div className="col-12 md:col-12 lg:col-3">
                <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                    Spiel gegen <br/><br/>
                    <span style={{fontWeight:"bold"}}>{this.props.gegnerUsername}</span>
                </div>
            </div>
        }
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.gegner === 'gast'} offLabel="Gast" onLabel="Gast" onChange={(e) => this.setState({gegner: 'gast'})}/>
                    </div>
                </div>
                {this.getDescription("Du kannst mit einem Gast spielen. Dazu erhälst du einen 'Scorekey', diesen gibst du deinen Gastgegner. Er kann das Spiel dann über die Startseite von darthelfer.de starten. Spiele mit Gästen werden nicht in die Statistik eingerechnet.")}
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.gegner === 'herausforderung'} offLabel="Öffentliche Herausforderung" onLabel="Öffentliche Herausforderung" onChange={(e) => this.setState({gegner: 'herausforderung'})}/>
                    </div>
                </div>
                {this.getDescription("Dein Spiel wird unter Herausforderungen angezeigt, ein beliebiger Spieler kann diese Herausforderung annehmen und mit dir spielen.")}
                {this.getDescription("Sofern deine öffentliche Herausforderung nicht innerhalb von 15 Minuten angenommen wird, wird diese vom System automatisch gelöscht.")}
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.gegner === 'onlinegegner'} offLabel="Onlinegegner" onLabel="Onlinegegner" onChange={(e) => this.setState({gegner: 'onlinegegner'})}/>
                    </div>
                </div>
                {this.getDescription("Du kannst mit einem dir bekannten Benutzer spielen" +
                    ".")}
                { this.state.gegner === 'onlinegegner'  &&    <>
                    <div className="grid" style={{textAlign:"left", marginTop:20}}>
                        <div className="col">
                            Gegner:
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            <UserSearch callBackError={(message) => {
                                this.showError(message);
                                this.setState({user:undefined})
                            }}
                                        callBackClearMessages={() => this.messages.clear()}
                                        callBackInfo={this.showWarn}
                                        callBackUserFound={(user) => this.setState({user:user})}/></div>
                    </div>
                </>
                }
            </div>
        </div>
    }

    getAreaDoppelgame() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        Deine Doppelpaarung (Funname + Name Doppelpartner):
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", width:"100%"}}>
                    <div className="col" style={{width:"100%"}}>
                        <Dropdown value={this.state.selectedDoppelpaarung} options={this.state.doppelpaarungen} optionLabel="name"
                                  onChange={(e) => {
                                      this.setState({selectedDoppelpaarung: e.value})
                                  }}
                                  placeholder="Eingabe..." style={{width:"100%"}}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        Gegnerpaarung (der genaue Name):
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <DoppelpaarungSearch callBackInfo={(message) => this.showWarn(message)}
                                             callBackError={(message) => this.showError(message)}
                                             callBackClearMessages={() => this.messages.clear()}
                                             callBackUserFound={(gegner) => this.setState({selectedGegner: gegner})}/>
                    </div>
                </div>
            </div>
        </div>
    }

    getGameTypeArea() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.gametype === 'x01'} offLabel="X01" onLabel="X01" onChange={(e) => this.setState({gametype: 'x01'})}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.gametype === 'cricket'} offLabel="Cricket" onLabel="Cricket" onChange={(e) => this.setState({gametype: 'cricket'})}/>
                    </div>
                </div>
            </div>
        </div>
    }

    getAreaModus() {
        if (this.state.gametype === "x01") {
            return this.getAreaModusX01();
        }
        else if (this.state.gametype === "cricket") {
            return this.getAreaModusCricket();
        }
    }

    getAreaModusCricket() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left", marginBottom:10, cursor:"pointer"}}>
                    <div className="col">
                        <i className="pi pi-sign-out" style={{marginRight:10}}/>
                        <span onClick={() => this.setState({gametype:undefined})}>zurück zur Auswahl...</span>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", fontWeight:"bold", marginBottom:20}}>
                    <div className="col">
                        Deine Detailauswahl zum Cricket Spiel
                    </div>
                </div>
                <div className="p-grid" style={{textAlign:"left"}}>
                    <div className="p-col">
                        Sets:
                    </div>
                </div>
                <div className="p-grid" style={{textAlign:"left"}}>
                    <div className="p-col">
                        <GameSetsComponent sets={this.state.sets}
                                           onChange={(sets) => this.changeSets(sets)}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="col">
                        First to Legs:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameFirstToLegsComponent firstToLegs={this.state.firstToLegs}
                                                  onChange={(firstToLegs) => {
                                                      this.changeFirstToLegs(firstToLegs);
                                                      {firstToLegs !== undefined && this.changeBestOfLegs(undefined)};
                                                  }}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="col">
                        Best of Legs:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameBestOfLegsComponent bestOfLegs={this.state.bestOfLegs}
                                                  onChange={(bestOfLegs) => {
                                                      this.changeBestOfLegs(bestOfLegs);
                                                      {bestOfLegs !== undefined && this.changeFirstToLegs(undefined)};
                                                  }}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        Zufallszahlen
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginBottom:10}}>
                    <div className="col">
                        <SelectButton value={this.state.zufallszahlen}
                                      optionLabel="name"
                                      options={this.cricketzufallszahlen} onChange={(e) => this.setState({zufallszahlen: e.value})}></SelectButton>
                    </div>
                </div>
                {this.state.zufallszahlen && <>
                    <div className="grid" style={{textAlign:"left"}}>
                        <div className="col">
                            jedes Leg andere Zufallszahlen?
                        </div>
                    </div>
                    <div className="grid" style={{textAlign:"left", marginBottom:10}}>
                        <div className="col">
                            <SelectButton value={this.state.zufallszahlenjedesleg}
                                          optionLabel="name"
                                          options={this.cricketzufallszahlen} onChange={(e) => this.setState({zufallszahlenjedesleg: e.value})}></SelectButton>
                        </div>
                    </div>
                </>
                }
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        Cutthroat
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <SelectButton value={this.state.cutthroat}
                                      optionLabel="name"
                                      options={this.cricketcutthroat} onChange={(e) => this.setState({cutthroat: e.value})}></SelectButton>
                    </div>
                </div>
                {this.getDescription("Bei Cutthroat ja bekommt der Gegner Punkte sobald du die Zahl mehr als drei mal triffst und er diese noch nicht drei mal getroffen hat. Der Gegner kann das Spiel nicht beenden, solange er mehr Punkte wie du hast.")}
                {this.getDescription("Bei Cutthroat nein bekommt du Punkte sobald du eine Zahl mehr als drei mal triffst und der Gegner die Zahl noch nicht drei mal getroffen hat. Dein Gegner kann das Spiel nicht beenden, wenn er weniger Punkte wie du hat.")}

            </div>
        </div>
    }
    getAreaModusX01() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left", marginBottom:10, cursor:"pointer"}}>
                    <div className="col">
                        <i className="pi pi-sign-out" style={{marginRight:10}}/>
                        <span onClick={() => this.setState({gametype:undefined})}>zurück zur Auswahl...</span>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", fontWeight:"bold", marginBottom:20}}>
                    <div className="col">
                        Deine Detailauswahl zum X01 Spiel
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        Startpunkte:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameStartpunkteComponent startpunkte={this.state.startpunkte}
                                                  onChange={(startpunkte) => this.changeStartpunkte(startpunkte)}/>
                    </div>
                </div>
                <div className="p-grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="p-col">
                        Sets:
                    </div>
                </div>
                <div className="p-grid" style={{textAlign:"left"}}>
                    <div className="p-col">
                        <GameSetsComponent sets={this.state.sets}
                                                  onChange={(sets) => this.changeSets(sets)}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="col">
                        First to Legs:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameFirstToLegsComponent firstToLegs={this.state.firstToLegs}
                                                  onChange={(firstToLegs) => {
                                                      this.changeFirstToLegs(firstToLegs);
                                                      {firstToLegs !== undefined && this.changeBestOfLegs(undefined)};
                                                  }}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="col">
                        Best of Legs:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameBestOfLegsComponent bestOfLegs={this.state.bestOfLegs}
                                                  onChange={(bestOfLegs) => {
                                                      this.changeBestOfLegs(bestOfLegs);
                                                      {bestOfLegs !== undefined && this.changeFirstToLegs(undefined)};
                                                  }}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:10}}>
                    <div className="col">
                        Modus:
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <GameModusComponent modusId={this.state.modusid}
                                            onChange={(modusid) => this.changeModusId(modusid)}/>
                    </div>
                </div>
            </div>
        </div>
    }

    getAreaRechts() {
        return <div className="col-12 md:col-12 lg:col-3">
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff"}}>
                <div className="grid" style={{textAlign:"left"}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.anwurf === 'systementscheidet'} offLabel="System entscheidet wer beginnt" onLabel="System entscheidet wer beginnt" onChange={(e) => this.setState({anwurf: 'systementscheidet'})}/>
                    </div>
                </div>
                {this.getDescription("Das System wählt zufällig den Beginner des Spiels aus")}
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.anwurf === 'ausbullen'} offLabel="Ausbullen" onLabel="Ausbullen" onChange={(e) => this.setState({anwurf: 'ausbullen'})}/>
                    </div>
                </div>
                {this.getDescription("Das Spiel beginnt mit ausbullen")}
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.anwurf === 'ichbeginne'} offLabel="ich beginne" onLabel="ich beginne" onChange={(e) => this.setState({anwurf: 'ichbeginne'})}/>
                    </div>
                </div>
                <div className="grid" style={{textAlign:"left", marginTop:20}}>
                    <div className="col">
                        <ToggleButton style={{width:"100%"}}  checked={this.state.anwurf === 'gegnerbeginnt'} offLabel="Gegner beginnt" onLabel="Gegner beginnt" onChange={(e) => this.setState({anwurf: 'gegnerbeginnt'})}/>
                    </div>
                </div>
            </div>
        </div>
    }

    getHinweis() {
        return <div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
            <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #b3ccff", textAlign:"left"}}>
                <h4>Hinweis:</h4>
                - du bekommst deine angelegten Spiele in deinem Profil oder auf dem Dashboard angezeigt.
                <br/>
                - angelegte und nicht beendete Games werden nach einigen Tagen automatisch aufgeräumt und damit gelöscht.
            </div>
        </div>
    }

    isSpeicherDisabled() {
        let speichernDisabled = false;
        if( this.state.starting ) {
            speichernDisabled = true;
        } else if( this.state.typ !== "einzelgame" && this.state.typ !== "doppelgame" ) {
            speichernDisabled = true;
        } else if( this.state.typ === "einzelgame") {
            let gegnerAusgewaehlt = this.state.gegner !== undefined || this.props.gegnerId;
            speichernDisabled = !gegnerAusgewaehlt || this.state.anwurf === undefined;
            if( this.state.gametype === undefined) {
                speichernDisabled = true;
            }
            if( this.state.gametype === "x01") {
                if( this.state.startpunkte === undefined || this.state.modusid === undefined) {
                    speichernDisabled = true;
                }
            }
            if( this.state.gametype === "cricket") {
                if( this.state.zufallszahlen === undefined || this.state.cutthroat === undefined) {
                    speichernDisabled = true;
                }
            }
            if( this.state.gegner === "onlinegegner" && this.state.user === undefined) {
                speichernDisabled = true;
            }
            if( this.state.firstToLegs === undefined && this.state.bestOfLegs === undefined) {
                speichernDisabled = true;
            }
        } else if( this.state.typ === "doppelgame") {
            speichernDisabled = this.state.anwurf === undefined || this.state.startpunkte === undefined || this.state.modusid === undefined || this.state.selectedDoppelpaarung === undefined || this.state.selectedGegner === undefined;
        }
        return speichernDisabled;
    }

    render() {
        let speichernDisabled = this.isSpeicherDisabled();

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Spiel erstellen
                </div>
            </div>
            <div style={{padding:10}}>
                <div className="grid" style={{margin:0}}>
                    {this.getAreaTypAuswahl()}
                    {this.state.typ === "einzelgame" && this.getAreaEinzelgame()}
                    {this.state.typ === "doppelgame" && this.getAreaDoppelgame()}
                    {this.state.gametype === undefined && this.getGameTypeArea()}
                    {!this.state.gametype !== undefined && this.getAreaModus()}
                    {this.getAreaRechts()}
                </div>
                {!this.state.funGameId &&
                    <div className="grid" style={{padding:20, paddingTop:10, margin:0}}>
                        <Button label="Spiel starten" style={{width:300, height:60}} disabled={speichernDisabled} onClick={() => this.save()}/>
                    </div>
                }
                {this.state.funGameId &&
                <div className="grid" style={{padding: 20, paddingTop: 10, margin: 0, color:"green"}}>
                    Dein Spiel wurde erfolgreich angelegt...
                </div>
                }
                {this.state.gameId &&
                <div className="grid" style={{padding: 20, paddingTop: 10, margin: 0, color:"green"}}>
                    <NavLink
                        to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + this.state.gameId + "&playerid=" + this.state.playerId}
                        style={{textDecoration: "none"}}>
                        <img src="/images/dart_select.png" alt="Spiel starten"
                             style={{cursor: "pointer", width: 60, marginRight: 5}}/>
                    </NavLink>                </div>
                }
                {this.getHinweis()}
            </div>
        </div>
    }
}

export default GameDetail;
