import React from 'react'
import TournamentKratzerGames from "../../tournament/TournamentKratzerGames";

export default function PublicTournamentKratzerGamesTab({tournament, aktuelleRunde, gewinnerPlayerId, tournamentPlayers, tournamentKratzerGames, tournamentKratzerGamePlayers, restpunkteDtos, onCallbackSpielerName, onCallbackReload}) {
    return <div style={{backgroundColor: "#022836", padding:20}}>
        <TournamentKratzerGames tournament={tournament} readOnly={true} aktuelleRunde={aktuelleRunde} gewinnerPlayerId={gewinnerPlayerId}
                                tournamentPlayers={tournamentPlayers}
                                tournamentKratzerGames={tournamentKratzerGames} tournamentKratzerGamePlayers={tournamentKratzerGamePlayers}
                                restpunkteDtos={restpunkteDtos}
        onCallbackReload={onCallbackReload}/>
    </div>
}
