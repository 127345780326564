import DHDialog from "../../general/DHDialog";

export default function LigaSpieltagBoardauswahlHilfeDialog({onClose}) {

    const getArea = () => {
        return <div style={{padding: 20}}>
            Hier wird beschrieben, wie du Boards für die Ligaspieltage vergeben kannst.<br/><br/>
            <hr/>
            <strong>Möglichkeit 1:</strong><br/><br/>
            Du möchtest Spiele bewusst auf ein Board vergeben. (Stichwort: Heim- bzw. Gastboard)<br/><br/>
            Öffne im Ligaspieltag die Boardauswahl über den Knopf 'Boardauswahl anzeigen' und wähle im Auswahlfeld 'Boardauswahl' das entsprechende Board.<br/>
            Danach klicke bei allen Spielen auf dem du mit diesem Board spielen möchtest die Dartscheibe. Wiederhole diesen Vorgang für alle Boards und alle Spiele.<br/><br/>
            Du kannst das Board ändern indem du das 'rote X' Symbol klickst und damit die bestehende Board-Zuordnung löscht.<br/>
            Dann kannst du das neue Board auswählen und vergeben.
            <br/><br/>
            <hr/>
            <strong>Möglichkeit 2:</strong><br/><br/>
            Du möchtest bestimmte Boards für den Ligaspieltag nutzen, dir ist aber egal welches Spiel auf welchem Board gespielt wird..<br/><br/>
            Wechsle auf den 'Reiter' -> Details und wähle 'Rund ums Board'. Wähle auf dem geöffneten Dialog 'Boardvergabe' und suche deinen Ligaspieltag in der entsprechenden Auswahl.<br/><br/>
            Hier kannst du die entsprechenden Boards auswählen und mit dem + Symbol hinzufügen und danach speichern.<br/><br/>
            Die Spiele werden nun frei auf den gewählten Boards vergeben.
            <hr/>
            <strong>Hinweis:</strong><br/><br/>
            Du kannst Möglichkeit 1 und 2 kombinieren indem du über Möglichkeit 1 für bestimmte Spiele ein festes Board vergibst und bei anderen das Board leer lässt.<br/>
            Die Spiele für die du über Möglichkeit 1 kein Board vergeben hast, werden Boards aus Möglichkeit 2 zugewiesen.
            <hr/>
            <strong>Hinweis Board löschen:</strong><br/><br/>
            Sobald Darthelfer ein Board vergeben hat, kannst du diese Zuordnung natürlich wieder löschen.<br/>
            Bitte gehe unter Details auf Rund ums Board und schau dir die Boardbelegung an. Da kannst du mit dem Löschsymbol die Boardzuordnung zu einem Spiel wieder löschen.
        </div>
    }
    return <DHDialog onClose={onClose} title={"Hilfe - Boardauswahl"} buttons={[]} comp={getArea()}/>
}