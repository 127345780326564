import {styled} from "@mui/material/styles";


export default function DHDesktopDiv ({comp}) {

    const DhDiv = styled('div')(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.up("md")]: {
            display: 'block',
        },
    }));

    return <DhDiv>{comp}</DhDiv>
}

