import {connect} from 'react-redux'
import AktionenMitteilungen from "../../../components/views/liga/AktionenMitteilungen";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id, notifications: state.notifications.notifications};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AktionenMitteilungen)