export const NEW_CHAT_INFOS_OVER_WEBSOCKET = 'NEW_CHAT_INFOS_OVER_WEBSOCKET';

export const newChatInfosOverWebsocket = (chatInfos) => ({
    type: NEW_CHAT_INFOS_OVER_WEBSOCKET,
    value: chatInfos,
});

export function chatInfosOverWebsocket(chatInfos) {
    return function (dispatch) {
        dispatch(newChatInfosOverWebsocket(chatInfos));
    };
}