import React, {useEffect, useRef, useState} from 'react'
import ParticipantTable from "./ParticipantTable";


export default function PublicTournamentLigaspieltagParticipantTab({tournamentId, participants}) {

    let [heimPlayers, setHeimPlayers] = useState([]);
    let [gastPlayers, setGastPlayers] = useState([]);

    useEffect(() => {
        let heim = participants.filter(player =>
            player.heimmannschaft && (player.idShortSinglePlayer1 === null || player.idShortSinglePlayer1 === undefined))
        let gast = participants.filter(player =>
            !player.heimmannschaft && (player.idShortSinglePlayer1 === null || player.idShortSinglePlayer1 === undefined))
        setHeimPlayers(heim);
        setGastPlayers(gast);
    }, [participants]);

    return <div className="darthelfer-background-blue">
        <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>
                Anzahl der Teilnehmer Heimverein: {heimPlayers.length}
            </div>
        </div>
        <ParticipantTable tournamentId={tournamentId} participants={heimPlayers}
                          isBezahlung={false} isParticipantsMailConfirmation={false}
                          supportsWebPush={false} pushSpielerIds={[]}
                          updateSubscriptions={undefined}/>

        <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>
                Anzahl der Teilnehmer Gastverein: {gastPlayers.length}
            </div>
        </div>
        <ParticipantTable tournamentId={tournamentId} participants={gastPlayers}
                          isBezahlung={false} isParticipantsMailConfirmation={false}
                          supportsWebPush={false} pushSpielerIds={[]}
                          updateSubscriptions={undefined}/>
    </div>
}

