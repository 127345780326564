import React from 'react'
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

class AktionenChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showDialog: false};
    }

    render() {
        let component;
        if (this.props.headtoheadsUngelesen) {
            component = <span style={{whiteSpace: "nowrap", color: "red", fontWeight:"bold", textDecoration: "none"}}> !!! Chats !!!</span>
        } else {
            component = <span style={{whiteSpace: "nowrap", textDecoration: "none"}}>Chats</span>
        }

        return <NavLink
            to={NAVIGATION_ITEM.HEADTOHEADCHAT.route}
            style={{textDecoration: "none"}}>
            <div style={{textDecoration: "none", color: "black", textAlign:"center"}}><img alt="" style={{marginRight: 20, marginLeft: 20,width: 30,}} src="/images/chat.png"/>
                <br/>
                {component}
            </div>
        </NavLink>
    }
}

AktionenChat.propTypes = {
    userId: PropTypes.string.isRequired,
    headtoheadsUngelesen: PropTypes.bool.isRequired,
};

export default AktionenChat;