import React from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";

const INITAL_STATE = {
};

class DoppelpaarungSearch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITAL_STATE};
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            this.searchUser();
        }
    }

    searchUser() {
        this.props.callBackClearMessages();
        let name = this.state.name;
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.doppelpartner + "/byName",
            {name:name},
            json => {
                if( json === null || json === undefined || json === "" ) {
                    this.props.callBackInfo("Keine Doppelpaarung zum Namen '" + name + "' gefunden!");
                } else {
                    this.props.callBackUserFound(json);
                    this.setState({paarungGefunden: true});
                }
            },
            responseNotOk => {
                this.props.callBackError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.props.callBackError("Fehler: " + error.message);
            }
        );
    }

    resetSearch() {
        this.setState({paarungGefunden:false, name: ""});
        this.props.callBackUserFound(undefined);
    }

    changeName(value) {
        this.setState({name:value, paarungGefunden:false, user: undefined});
    }

    render() {
        return <div>
            <div className="grid">
                <div className="col">
                    <InputText style={{width:"100%"}} value={this.state.username}
                               onKeyDown={(e) => this.handelKeyDown(e)}
                               onChange={(e) => this.changeName(e.target.value)}
                    disabled={this.state.paarungGefunden}/></div>
                {this.state.paarungGefunden && <>
                    <div className="col-fixed" style={{width: 40}}>
                        <img src="images/found.png" alt="User wurde gefunden" style={{width: 30}}/>
                    </div>
                    <div className="col-fixed" style={{width:40}}>
                        <Button icon="pi pi-times" onClick={() => this.resetSearch()}
                                style={{marginLeft: 5}}/>
                    </div>
                    </>
                }
                {!this.state.paarungGefunden &&
                    <div className="col-fixed" style={{width: this.props.shortSearchButton ? 35: 120}}>
                        <Button label={this.props.shortSearchButton ? "" : "Suchen"} icon="pi pi-search" onClick={() => this.searchUser()} />
                    </div>
                }
            </div>
        </div>
    }
}

DoppelpaarungSearch.propTypes = {
    callBackInfo: PropTypes.func.isRequired,
    callBackError: PropTypes.func.isRequired,
    callBackClearMessages: PropTypes.func.isRequired,
    callBackUserFound: PropTypes.func.isRequired,
    shortSearchButton: PropTypes.bool,
};

export default DoppelpaarungSearch
