import React from 'react'

import {PDFDownloadLink} from '@react-pdf/renderer';
import QRCodeDocument from "../../pdf/qrcode/QRCodeDocument";
import QRGenerator from "../../pdf/qrcode/QRGenerator";
import ConfigUtil from "../../../util/ConfigUtil";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";


export default function TurnierQRCode({tournamentId, eventOperatorId, onlineTournament, tournamentSystem, bezeichnung}) {

    const getEventoperatorLink = () => {
        return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + eventOperatorId;
    }

    const getTournamentLink = () => {
        if( !onlineTournament) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + tournamentId;
        }
        if( tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.TURNIERDETAIL.route + "?turnierid=" + tournamentId;
        } else if( tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route + "?turnierid=" + tournamentId;
        } else if( tournamentSystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route + "?turnierid=" + tournamentId;
        } else if( tournamentSystem === TOURNAMENTSYSTEMS.LIGA.id) {
            return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + tournamentId;
        } else {
            return "";
        }
    }

    const dataGesamt = {
        qrcodes: [{
            id: "link_tournament",
            value: getTournamentLink(),
            bezeichnung: bezeichnung,
            beschreibung: "Direkt zum Turnier / zur Liga"
        },
        {
            id: "link_liveview",
            value: ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route + "?tournamentid=" + tournamentId,
            bezeichnung: bezeichnung,
            beschreibung: "Live-View zum Turnier / zur Liga"
        }]
    };
    const dataEventoperator = {
        qrcodes: [{
            id: "link_eventoperator",
            value: getEventoperatorLink(),
            bezeichnung: "",
            beschreibung: "Veranstalterbereich"
        }]
    };

    const dataTournament = {
        qrcodes: [{
            id: "link_tournament",
            value: getTournamentLink(),
            bezeichnung: bezeichnung,
            beschreibung: "Direkt zum Turnier / zur Liga"
        }]
    };
    const dataLiveView = {
        qrcodes: [{
            id: "link_liveview",
            value: ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT_LIVE_VIEW.route + "?tournamentid=" + tournamentId,
            bezeichnung: bezeichnung,
            beschreibung: "Live-View zum Turnier / zur Liga"
        }]
    };

    return <>
        <div>In unserem Forum findest du die Darthelfer-Logos die du gerne für Hinweise auf dein Turnier/Liga benutzen kannst.</div>
        <div><a href="https://support.darthelfer.de/viewtopic.php?t=102" target="_blank" rel="noopener noreferrer">Klicke hier...</a></div>
        <div className="grid" style={{padding: 20}}>
            {eventOperatorId !== null && eventOperatorId !== undefined &&
                <div className="col-12 md:col-12 lg:col-4">
                    <h3>QR Code - Veranstalterbereich</h3>
                    <div>(Rechtsklick auf das Bild zum Download)</div>
                    <QRGenerator data={{id: dataEventoperator.qrcodes[0].id, value: dataEventoperator.qrcodes[0].value}}/>
                    <PDFDownloadLink
                        document={<QRCodeDocument data={dataEventoperator}/>}
                        fileName="qrcode_tournament.pdf">
                        {({loading}) => (loading ? "Loading..." :
                            <div><img src="/images/pdf.png" style={{width: 30, marginRight: 10}} alt="PDF"/>Download PDF
                            </div>)}
                    </PDFDownloadLink>
                    <div style={{marginTop: 20}}>Link: {dataEventoperator.qrcodes[0].value} <a
                        href={dataEventoperator.qrcodes[0].value} target="_blank" rel="noopener noreferrer">(...Klick...)</a></div>
                </div>
            }
            <div className="col-12 md:col-12 lg:col-4">
                <h3>QR Code direkt zum Turnier / zur Liga</h3>
                <div>(Rechtsklick auf das Bild zum Download)</div>
                <QRGenerator data={{id: dataTournament.qrcodes[0].id, value: dataTournament.qrcodes[0].value}}/>
                <PDFDownloadLink
                    document={<QRCodeDocument data={dataTournament}/>}
                    fileName="qrcode_tournament.pdf">
                    {({loading}) => (loading ? "Loading..." :
                        <div><img src="/images/pdf.png" style={{width: 30, marginRight: 10}} alt="PDF"/>Download PDF
                        </div>)}
                </PDFDownloadLink>
                <div style={{marginTop: 20}}>Turnierlink: {dataTournament.qrcodes[0].value} <a href={dataTournament.qrcodes[0].value} target="_blank" rel="noopener noreferrer">(...Klick...)</a></div>
            </div>
            <div className="col-12 md:col-12 lg:col-4">
                <h3>QR Code direkt zum Live-View Turnier / zur Liga</h3>
                <div>(Rechtsklick auf das Bild zum Download)</div>
                <QRGenerator data={{id: dataLiveView.qrcodes[0].id, value: dataLiveView.qrcodes[0].value}}/>
                <PDFDownloadLink
                    document={<QRCodeDocument data={dataLiveView}/>}
                    fileName="qrcode_liveview.pdf">
                    {({loading}) => (loading ? "Loading..." :
                        <div><img src="/images/pdf.png" style={{width: 30, marginRight: 10}} alt="PDF"/>Download PDF
                        </div>)}
                </PDFDownloadLink>
                <div style={{marginTop: 20}}>Link Live-View: {dataLiveView.qrcodes[0].value} <a href={dataLiveView.qrcodes[0].value} target="_blank" rel="noopener noreferrer">(...Klick...)</a></div>
            </div>
        </div>
        <div>
            <PDFDownloadLink
                document={<QRCodeDocument data={dataGesamt}/>}
                fileName="qrcode_tournament.pdf">
                {({loading}) => (loading ? "Loading..." :
                    <div><img src="/images/pdf.png" style={{width: 30, marginRight: 10}} alt="PDF"/>Download Turnier und
                        Live-View PDF
                    </div>)}
            </PDFDownloadLink>
        </div>
    </>

}

