import {connect} from 'react-redux'
import PublicTournaments from "../../../../components/views/public/tournaments/PublicTournaments";

const mapStateToProps = function (state, ownProps) {
    return {isLoggedIn: state.user.isLoggedIn,};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicTournaments)
