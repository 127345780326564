import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import ScoringShortView from "../../../components/views/scoring/ScoringShortView";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const gameId = urlParams.get('gameid');
    const playerId = urlParams.get('playerid');
    return {gameid: gameId, playerid: playerId};
};

const mapDispatchToProps = dispatch => ({
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ScoringShortView))
