import React from 'react'
import {Button} from 'primereact/button';
import TableErgebnis from '../../../general/trainingsergebnisTable';
import TableTabelle from '../../../general/trainingsTable';
import HighscoreAndHighfinishArea from '../../../general/highscoreAndHighfinishArea';
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";

import PublicKeyComponent from "./PublicKeyComponent";
import {Sidebar} from "primereact/sidebar";

import TrainingGameEditComponent from "../../training/TrainingGameEditComponent";
import DateUtil from "../../../../util/DateUtil";
import ConfirmDialog from "../../../general/ConfirmDialog";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {NavLink} from "react-router-dom";
import {GameFirstToLegsComponent} from "../../../general/GameFirstToLegsComponent";

class TrainingPublicScore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publicKey: "",
            showConfirmDialogSessionAbschliessen: false,
            showSpielEintragen: false,
            counter:0
        };
        this.onMessageReceivedReload = this.onMessageReceivedReload.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.clearErrorMessage = this.clearErrorMessage.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.setPublicKey = this.setPublicKey.bind(this);
        this.showConfirmAbschliessenDialog = this.showConfirmAbschliessenDialog.bind(this);
    }

    showConfirmAbschliessenDialog() {
        this.setState({...this.state, showConfirmDialogSessionAbschliessen: true})
    }

    setPublicKey(publicKey) {
        this.setState({publicKey: publicKey});
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }


    clearErrorMessage() {
        this.messages.clear();
    }

    showWarn(message) {
        this.messages.show({
            severity: 'warn',
            sticky: 'true',
            summary: 'Achtung!!!',
            detail: message
        });
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    onMessageReceivedReload() {
        console.log("reload")
        window.location.reload();
    }

    onMessageReceived(payload) {
        console.log("Websocket - message received:");
        if (payload === null || payload === undefined || payload.body === undefined || payload.body === "") {
            console.log("Leere Nachricht erhalten", payload);
            this.setState({trainingssession: undefined, spieler: undefined, trainingsergebnisse: undefined})
        } else {
            let payloadBody = payload.body;
            const json = JSON.parse(payloadBody);
            let spieler = json.playerDtos;
            let trainingsergebnisse = json.trainingsergebnisseDtos;
            let trainingssession = json.trainingSessionDto;
            let ranglisteEintraege = json.ranglisteDtos;
            let payments = json.trainingSessionPlayerPaidDtos;
            this.setState({
                trainingssession: trainingssession,
                spieler: spieler,
                trainingsergebnisse: trainingsergebnisse,
                ranglisteEintraege: ranglisteEintraege,
                payments: payments,
            })
        }
    }

    changeLegs(legs) {
        this.messages.clear();
        let id = this.state.trainingssession.id;
        let url = ConfigUtil.getConfig().resourceUrls.public + "/changelegs/" + id + "/" + this.state.publicKey + "?legs=" + legs;
        FetchUtil.fetchPost(url,
            {},
            () => {
                this.showInfo("Legs wurden geändert.");
                this.setState({showSettings: false});
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern der Legs: " + responseNotOk.message);
                this.setState({showSettings: false});
            },
            error => {
                this.showError("Fehler beim Ändern der Legs: " + error.message);
                this.setState({showSettings: false});
            })
    }


    changeTrainingssessionToAbgeschlossen() {
        this.messages.clear();
        let id = this.state.trainingssession.id;
        let url = ConfigUtil.getConfig().resourceUrls.public + "/settrainingssessionabgeschlossen/" + id + "/" + this.state.publicKey;
        FetchUtil.fetchPost(url,
            {},
            result => {

                if (result === undefined || result === null || result === "") {
                    this.showInfo("Trainigssession wurde abgeschlossen.");
                } else {
                    this.showWarn(result)
                }
                this.setState({
                    ...this.state,
                    trainingssession: undefined,
                    spieler: undefined,
                    trainingsergebnisse: undefined,
                    showConfirmDialogSessionAbschliessen: false
                });
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status: " + responseNotOk.message);
                this.setState({...this.state, showConfirmDialogSessionAbschliessen: false});
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
                this.setState({...this.state, showConfirmDialogSessionAbschliessen: false});
            })
    }


    render() {
        let formattedDateTimeTrainingssession = "";
        if (this.state.trainingssession !== undefined) {
            formattedDateTimeTrainingssession = DateUtil.localDateTimeToString(this.state.trainingssession.timestampAsString);
        }

        return <div className="col-12 md:col-12 lg:col-12" style={{padding:0}} >
            <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
            </div>
            <div className={"grid p-justify-between"} style={{
                padding: "20px",
                marginRight: 0,
                marginLeft: 0,
                backgroundColor: "#f2f2f2",
                color: "black",
                borderBottom: "1px solid #888888",
                width: "100%",
            }}>
                <div className="col" style={{width: "50px", fontSize: "30px", borderRight: "1px solid"}}>
                    <NavLink to={"/"} style={{textDecoration:"none"}}>
                        <img
                            alt="Logo"
                            src={"images/darthelfer.svg"}
                            style={{width: "50px", verticalAlign:"middle"}}/><span style={{textDecoration:"none", color:"black"}}>darthelfer.de</span>
                    </NavLink>
                </div>
                <PublicKeyComponent publicKey={this.state.publicKey} setPublicKey={this.setPublicKey}
                                    onMessageReceived={this.onMessageReceived}
                                    onMessageReceivedReload={this.onMessageReceivedReload}
                                    clearErrorMessage={this.clearErrorMessage} showError={this.showError}
                                    showInfo={this.showInfo}/>
                <div className="col-fixed" style={{marginLeft: "5px", borderRight: "1px solid"}}>
                    <Button icon="pi pi-cog"
                            style={{marginLeft: "5px", width: "30", height: "70px"}}
                            disabled={!this.state.trainingssession || this.state.trainingssession.abgeschlossen}
                            onClick={() => this.setState({showSettings:true})}/>
                </div>
                <div className="col-fixed" style={{marginLeft: "5px", borderRight: "1px solid"}}>
                    <Button label="Trainingssession abschliessen"
                            style={{marginLeft: "5px", width: "300px", height: "70px"}}
                            disabled={!this.state.trainingssession || this.state.trainingssession.abgeschlossen}
                            onClick={() => this.showConfirmAbschliessenDialog()}/>
                </div>
                <div className="col-fixed" style={{marginLeft: "5px"}}>
                    <Button label="Eintragen" style={{marginLeft: "5px", width: "300px", height: "70px"}}
                            type="button"
                            disabled={!this.state.trainingssession || this.state.trainingssession.abgeschlossen}
                            onClick={(e) => this.setState({...this.state, showSpielEintragen: true})}/>
                </div>
            </div>
            <div className={"grid"} style={{
                marginTop: 5,
                marginBottom: 10,
                marginLeft:0,
                marginRight:0,
                padding: 20,
                backgroundColor: "white",
                color: "black", verticalAlign: "center", textAlign: "center"
            }}>
                {this.state.trainingssession !== undefined && this.state.trainingssession !== null ?
                    <div className="grid">
                        <div className="col" style={{fontSize: "16px", fontWeight: "bold"}}>
                            Trainingsession: {formattedDateTimeTrainingssession}
                        </div>
                    </div>
                    : <div/>
                }
                {this.state.spieler !== undefined && this.state.spieler !== null && this.state.spieler.length !== 0 ?
                    <div style={{width:"100%"}}>
                        <TableErgebnis spieler={this.state.spieler} trainingsergebnisse={this.state.trainingsergebnisse} payments={this.state.payments}/>
                        <TableTabelle rangliste={this.state.ranglisteEintraege} sort={"default"} callbackSort={() => ""}/>
                        <HighscoreAndHighfinishArea rangliste={this.state.ranglisteEintraege} />
                    </div>
                    :
                    <div style={{verticalAlign: "center", textAlign: "center", width: "100%"}}>
                        {this.state.trainingssession !== undefined && this.state.trainingssession !== null ?
                            <div className={"grid"}
                                 style={{verticalAlign: "center", textAlign: "center", width: "100%"}}>
                                <div className="col" style={{width: "100%"}}>
                                    Aktuell sind keine Ergebnisse zur Trainingsession vorhanden!!!
                                </div>
                            </div>
                            : <div/>
                        }
                        <div className={"grid"} style={{verticalAlign: "center", textAlign: "center", width: "100%"}}>
                            <div className="col" style={{width: "100%"}}>
                                <img alt="Logo"
                                     src={"images/darthelfer.svg"}
                                     style={{width: "300px"}}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {this.state.trainingssession !== undefined ?
                <Sidebar visible={this.state.showSpielEintragen} style={{width: "100%", height: "650px"}}
                         onHide={(e) => this.setState({showSpielEintragen: false, counter: this.state.counter+1})}
                         position="top" showCloseIcon={false}>
                    <TrainingGameEditComponent trainingssession={this.state.trainingssession}
                                               callBackOnError={this.showError} callBackOnInfo={this.showInfo}
                                               clearMessages={this.clearErrorMessage}
                                               publicKey={this.state.publicKey}
                                               counter={this.state.counter}/>
                    <Button type="button" onClick={() => this.setState({showSpielEintragen: false, counter: this.state.counter+1})}
                            label="Diesen Bereich schliessen..."
                            style={{width: "400px", marginLeft: "5px", marginTop: "5px", marginBottom: "20px"}}/>
                </Sidebar> : <div/>
            }
            {this.state.showSettings && <Sidebar visible={true} style={{width: "100%", height: "650px"}}
                                                 onHide={(e) => this.setState({showSettings: false, counter: this.state.counter+1})}
                                                 position="top" showCloseIcon={false}>
                <div style={{marginBottom:20}}>Bei Änderung wird automatisch gespeichert!</div>
                <div><span style={{marginRight:10}}>First to legs:</span>
                    <GameFirstToLegsComponent firstToLegs={this.state.trainingssession.firstToLegs}
                    onChange={(firstToLegs) => this.changeLegs(firstToLegs)}/></div>
                <br/>
                <br/>
                <Button type="button" onClick={() => this.setState({showSettings: false, counter: this.state.counter+1})}
                        label="Diesen Bereich schliessen..."
                        style={{width: "400px", marginLeft: "5px", marginTop: "5px", marginBottom: "20px"}}/>
            </Sidebar>
            }
            <ConfirmDialog visible={this.state.showConfirmDialogSessionAbschliessen}
                           callBackOnNein={() => this.setState({
                               showConfirmDialogSessionAbschliessen: false,
                               selectedTrainingssession: undefined
                           })}
                           callBackOnJa={() => this.changeTrainingssessionToAbgeschlossen()}
                           header="Session wirklich abschliessen"
                           textOben="Sobald eine Session abgeschlossen wird, kann diese Aktion nicht mehr rückgängig gemacht werden."
                           textUnten="Möchtest du die Session wirklich abschliessen?"/>
        </div>
    }

}

export default TrainingPublicScore;
