import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Panel} from "primereact/panel";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ConfirmDialog from "../../general/ConfirmDialog";
import {InputText} from "primereact/inputtext";
import DHDialog from "../../general/DHDialog";

export default function TournamentAddPlayerToKOPhaseDialog({tournamentId, feld, gruppe, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showMessage, setShowMessage] = useState(false);
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(undefined);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/players/" + tournamentId,
            json => {
                setPlayers(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const save = () => {
        setErrorMessage(undefined);
        let data = {feld: feld, playerId: selectedPlayer.id, gruppe: gruppe};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/player/kophase/add/" + tournamentId,
            data,
            json => {
                onClose(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setShowMessage(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setShowMessage(false);
            }
        );
    }

    const getPlayers = () => {
        if( filter === "") {
            return players;
        }
        return players.filter(player => player.name.includes(filter.trim()));
    }

    const getPlayerRow = (player) => {
        return <div key={"key_player_" + player.id} style={{marginBottom:10}}>
            <Button icon="pi pi-plus"
                                 onClick={() => {
                                     setShowMessage(true);
                                     setSelectedPlayer(player);
                                 }}
                                 style={{height: 30, width: 30, marginLeft: 10, marginRight: 10}}/>
            {player.name}
        </div>
    }

    const canSave = () => {
        for (const player of players) {
            let gruppeVorhanden = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id).length > 0;
            if (gruppeVorhanden) {
                let gruppe = groupEinteilung.filter(tmpGroup => tmpGroup.playerId === player.id)[0].gruppe;
                let problem = gruppe > anzahlGruppen;
                if (problem) {
                    return false
                }
            }
        }
        return true;
    }

    const dialogFooter = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose(false)}
                    className="p-button-secondary"/>
        </div>
    );

    return <DHDialog onClose={() => onClose(false)} title="Spieler in KO Phase einfügen" show={true} buttons={dialogFooter}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Panel header="Spielerübersicht">
            <div>Filter:</div>
            <div><InputText value={filter} onChange={(e) => setFilter(e.target.value)}/></div>
            <hr/>
            {getPlayers().map(player => getPlayerRow(player))}
        </Panel>
        {showMessage && <ConfirmDialog callBackOnJa={() => save()} callBackOnNein={() => setShowMessage(false)}
                                       header="Frage..." visible={true} textOben={"Möchtest du den gewählten Teilnehmer wirklich in den Knoten einfügen?"}/>}
    </>}/>
}
