import React from 'react'
import PropTypes from "prop-types";

import {Panel} from 'primereact/panel';
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";


import Table from "./settingsAdminTable";
import {Fieldset} from "primereact/fieldset";


class SettingsAdmins extends React.Component {

    constructor(props) {
        super(props);

        this.state = {spieler: []};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.changeSpielerAdminRole = this.changeSpielerAdminRole.bind(this);
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.user + "/all",
            json => {
                this.setState({spieler: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Spielerdaten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Spielerdaten: " + error.message);
            })
    }

    changeSpielerAdminRole(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/changeadminrole",
            {id},
            () => {
                this.showInfo("Adminrecht wurde erfolgreich geändert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Adminrechts:" + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Ändern des Adminrechts:" + error.message);
            })
    }


    render() {
        return <div style={{textAlign:"left"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>- Admins können Trainingssession abschliessen. Sofern eine Session nicht innerhalb von 2 Tagen nach
                    Start abgeschlossen wird, erhalten alle Admins eine Email mit der Bitte die Session abzuschliessen.
                </div>
                <div>- Admins können weitere Admins ernennen.</div>
                <div>- Admins können Traininssessions verwalten, die vom System automatisch angelegt werden.</div>
                <div>*** Es sollte normal nicht mehr als 1-2 Admins geben.</div>
                <div>*** Es gibt noch einen Superadmin, der wird hier im System nicht angezeigt. Dieser hat die Rechte
                    eines Admins und noch ein bisschen mehr.
                </div>
            </Fieldset>
            <Panel header="Admins bestimmen" style={{textAlign: "left"}}>
                <Table spieler={this.state.spieler} changeSpielerAdminRole={this.changeSpielerAdminRole}/>
            </Panel>
        </div>
    }
}

SettingsAdmins.propTypes = {
    id: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default SettingsAdmins;