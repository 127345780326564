import ConfigUtil from "./ConfigUtil";
import FetchUtil from "./FetchUtil";

class WebPushUtil {

    static async check() {
        if (!('serviceWorker' in navigator)) {
            console.log("Service Worker isn't supported");
            return false;
        }
        if (!('PushManager' in window)) {
            console.log("Push isn't supported");
            return false;
        }
        return navigator.serviceWorker.getRegistration()
            .then((registration) => {
                return registration != undefined;
        });
    }

    static async requestPermission() {
        if (!("Notification" in window)) {
            console.error("Notification not supported");
            return false;
        } else if (Notification.permission === "granted") {
            return true;
        } else if (Notification.permission !== "denied") {
            return await Notification.requestPermission().then(permission => {
                return permission === "granted";
            });
        }
        return false;
    }

    static hasPermission() {
        if (!("Notification" in window)) {
            console.error("Notification not supported");
            return false;
        } else {
            return Notification.permission === "granted";
        }
    }

    static async subscribe(key) {
        let reg;
        return navigator.serviceWorker.getRegistration()
            .then((registration) => {
                reg = registration;
                return registration.pushManager.getSubscription();
            })
            .then((subscription) => {
                if (subscription != null) {
                    console.log("Subscription found");
                    return subscription;
                } else {
                    console.log("Creating subscription");
                    const urlKey = key.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: urlKey,
                    };
                    return reg.pushManager.subscribe(subscribeOptions);
                }
            }, (error) => {
                console.log(error);
            });
    }

    static async hasSubscription() {
        const subscription = await this.getSubscription();
        return subscription != null;
    }

    static async getSubscription() {
        const sub = await navigator.serviceWorker.getRegistration()
            .then((registration) => {
                if (registration != null && registration.pushManager != null) {
                    return registration.pushManager.getSubscription();
                }
                return null;
            }, (error) => {
                console.log(error);
            });
        return sub;
    }

    static async loadSecret(responseNotOk, error) {
        let url = ConfigUtil.getConfig().resourceUrls.webpush + "/secret";
        return await FetchUtil.fetchGet(url,
            json => {
                return json;
            },
            responseNotOk,
            error);
    }

    static async subscriptionsLaden(tournamentId, ok = () => {}, responseNotOk = () => {}, error = () => {}) {
        const subscription = await this.getSubscription();
        if (subscription != null) {
            let url = ConfigUtil.getConfig().resourceUrls.webpush + "/subscriptions/" + tournamentId + "?endpoint=" + subscription.endpoint;
            await FetchUtil.fetchGet(url, ok, responseNotOk, error);
        } else {
            ok({spielerIds: []});
        }
    }

    static async pushAktivieren(tournamentId, spielerId, ok = () => {}, responseNotOk = () => {}, error = () => {}) {
        const permission = await this.requestPermission();
        if (permission) {
            const hasSubscription = await this.hasSubscription();
            let subscription;
            if (!hasSubscription) {
                const secret = await this.loadSecret(responseNotOk, error);
                subscription = await this.subscribe(secret.secret);
            } else {
                subscription = await this.getSubscription();
            }
            if (subscription != null) {
                let url = ConfigUtil.getConfig().resourceUrls.webpush + "/subscribe";
                let dto = {
                    subscription: JSON.stringify(subscription),
                    tournamentId: tournamentId,
                    spielerId: spielerId
                };
                await FetchUtil.fetchPost(url, dto, ok, responseNotOk, error);
            }
        }
    }

    static async pushDeaktivieren(tournamentId, spielerId, ok = () => {}, responseNotOk = () => {}, error = () => {}) {
        const subscription = await this.getSubscription();
        if (subscription != null) {
            let url = ConfigUtil.getConfig().resourceUrls.webpush + "/unsubscribe";
            let dto = {
                subscription: JSON.stringify(subscription),
                tournamentId: tournamentId,
                spielerId: spielerId
            };
            await FetchUtil.fetchPost(url, dto, ok, responseNotOk, error);
        }
    }

}

export  default WebPushUtil;
