import {getTournamentsystem, TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ConfigUtil from "../../../util/ConfigUtil";

export function openEventOperator(tournament) {
    openEventOperatorById(tournament.eventOperatorId);
}

export function openEventOperatorById(eventOperatorId) {
    let link = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + eventOperatorId;
    window.open(link, "_self")
}

export function openTournament(tournament, isLoggedIn, onShowLoginDialog) {
    let tournamentsystem = getTournamentsystem(tournament.turniersystem);
    let isTurnierMitGruppenphase = tournamentsystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO || tournamentsystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO;
    let isLiga = tournamentsystem === TOURNAMENTSYSTEMS.LIGA;
    let isWeekendLeague = tournamentsystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE;
    let isKODKO = tournamentsystem === TOURNAMENTSYSTEMS.DOPPELTERKO || tournamentsystem === TOURNAMENTSYSTEMS.EINFACHERKO;

    if (tournament.online && !isLoggedIn) {
        onShowLoginDialog(true);
        return;
    } else if (tournament.online) {
        let link = "";
        if (isLiga) {
            link = NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + tournament.id;
        } else if (isWeekendLeague) {
            link = NAVIGATION_ITEM.WEEKENDLEAGUEDETAIL.route + "?turnierid=" + tournament.id;
        } else if (isTurnierMitGruppenphase) {
            link = NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route + "?turnierid=" + tournament.id
        } else if (isKODKO) {
            link = NAVIGATION_ITEM.TURNIERDETAIL.route + "?turnierid=" + tournament.id
        }
        link = ConfigUtil.getBasicClientUrl() + link;
        window.open(link, "_self")
    } else {
        let link = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + tournament.id;
        window.open(link, "_self")
    }
}
