import React from "react";
import {NAVIGATION_ITEM} from "../../constants/navigationItems";
import {NavLink} from "react-router-dom";

class HeaderSmall extends React.Component {

    render() {
        return <div className={"grid p-dir-row"} style={{
            padding: "20px",
            marginRight:0,
            marginLeft:0,
            backgroundColor: "#f2f2f2",
            color: "black",
            borderBottom: "1px solid #888888",
            width:"100%",
        }}>
            <img alt="Logo" src={"images/darthelfer.svg"} style={{width: "60px"}}/>
            <div style={{fontSize: "30px", marginLeft: "20px", marginRight: "20px", textAlign: "left", float:"left", marginTop:"15px"}}>
                Deine persönliche Dartplattform!
            </div>
            <NavLink to={NAVIGATION_ITEM.DASHBOARD.route} style={{textDecoration: "none"}}>
                <div className="ligashortoverview" style={{height:"100%", width:300, borderLeft:"10px solid #f2f2f2"}}>
                    <div className="grid">
                        <div className="col-fixed" style={{width:50}}><img src="images/kachel/uebersicht.png" style={{height:40}} alt="Zur Übersicht."/></div>
                        <div className="col-fixed" style={{fontSize:16, width:130, marginTop:10, marginLeft:20}}>zur Übersicht</div>
                    </div>
                </div>
            </NavLink>
        </div>
    }
}

export default HeaderSmall;
