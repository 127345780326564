import React from "react";
import {Drawer} from "@mui/material";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

export default function DHDefaultDrawer({title, show, onClose, children}) {
    return <Drawer PaperProps={{sx: {maxWidth: 600}}} open={show} onClose={() => onClose()}>
        <Button icon={PrimeIcons.TIMES_CIRCLE}
                style={{position:"fixed", top:5, left:5}}
                className="p-button-danger" onClick={() => onClose()}/>
        <div style={{
            backgroundColor: "black", color: "white", textAlign: "center",
            fontWeight: "bold", fontSize: 20, padding: 10
        }}>
            {title}
        </div>
        {children}
    </Drawer>
}