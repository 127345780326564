import React from "react";
import {Dropdown} from "primereact/dropdown";
import DHSelectionComponent from "./DHSeclectionCompontent";

const VALUES = [
    {name: 'Auswahl löschen', code: undefined},
    {name: '2xDP | 4xEZ | 4xEZ | 4xEZ | 4xEZ', code: '2xDP|4xEZ|4xEZ|4xEZ|4xEZ'},
    {name: '4xEZ | 2xDP', code: '4xEZ|2xDP'},
    {name: '4xEZ | 2xDP | 4xEZ', code: '4xEZ|2xDP|4xEZ'},
    {name: '4xEZ | 2xDP | 4xEZ | 2xDP', code: '4xEZ|2xDP|4xEZ|2xDP'},
    {name: '4xEZ | 2xDP | 4xEZ | 2xDP | 4xEZ', code: '4xEZ|2xDP|4xEZ|2xDP|4xEZ'},
    {name: '4xEZ | 1xDP | 4xEZ | 1xDP', code: '4xEZ|1xDP|4xEZ|1xDP'},
    {name: '4xEZ | 2xDP | 4xEZ | 1xDP', code: '4xEZ|2xDP|4xEZ|1xDP'},
    {name: '4xEZ | 4xEZ | 2xDP', code: '4xEZ|4xEZ|2xDP'},
    {name: '4xEZ | 4xEZ | 2xDP | 2xDP', code: '4xEZ|4xEZ|2xDP|2xDP'},
    {name: '4xEZ | 4xEZ | 2xDP | 4xEZ | 4xEZ | 2xDP', code: '4xEZ|4xEZ|2xDP|4xEZ|4xEZ|2xDP'},
    {name: '4xEZ | 4xEZ | 2xDP | 4xEZ | 4xEZ', code: '4xEZ|4xEZ|2xDP|4xEZ|4xEZ'},
    {name: '4xEZ | 4xEZ | 4xEZ | 4xEZ', code: '4xEZ|4xEZ|4xEZ|4xEZ'},
    {name: '4xEZ | 4xEZ | 4xEZ | 4xEZ | 2xDP', code: '4xEZ|4xEZ|4xEZ|4xEZ|2xDP'},
    {name: '6xEZ | 3xDP | 6xEZ', code: '6xEZ|3xDP|6xEZ'},
    {name: '6xEZ | 3xDP | 3xEZ', code: '6xEZ|3xDP|3xEZ'},
    {name: '8xEZ | 4xDP', code: '8xEZ|4xDP'},
    {name: '8xEZ | 2xDP', code: '8xEZ|2xDP'},
    {name: '8xEZ | 2xDP | 8xEZ', code: '8xEZ|2xDP|8xEZ'},
    {name: '8xEZ | 2xDP | 8xEZ | 2xDP', code: '8xEZ|2xDP|8xEZ|2xDP'},
    {name: '8xEZ | 1xDP | 8xEZ | 1xDP', code: '8xEZ|1xDP|8xEZ|1xDP'},
    {name: '12xEZ | 2xDP', code: '12xEZ|2xDP'},
    {name: '12xEZ | 3xDP', code: '12xEZ|3xDP'},
    {name: '16xEZ', code: '16xEZ'},
];


export function LigaSpieltagModusComponent({id, disabled, onChange}) {
    return <DHSelectionComponent value={id} onChange={onChange} disabled={disabled} values={VALUES} title="Modus Ligaspieltag"/>
}
