import {connect} from 'react-redux'
import {withCookies} from "react-cookie";
import {logout} from "../../../actions/user";
import {setCurrentEventOperator} from "../../../actions/eventoperator";
import VeranstalterDashboard from "../../../components/views/veranstalter/VeranstalterDashboard";

const mapStateToProps = (state, ownProps) => ({
    userId: state.user.id,
    currentEventOperator: state.eventoperator.currentEventOperator,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout()),
    setCurrentEventOperator: (eventOperator) => dispatch(setCurrentEventOperator(eventOperator))
});

export default withCookies(connect(
    mapStateToProps,
    mapDispatchToProps
)(VeranstalterDashboard))
