import React from 'react';
import {Button} from "primereact/button";

import "./spieltagTable.css";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

function getRows(props) {
    let tableRows = [];
    if( props.spieltage !== undefined) {
        for (let i = 0; i < props.spieltage.length; i++) {
            let spieltag = props.spieltage[i];
            tableRows.push(<tr key={"row_spieltag_" + spieltag.id}>
                <td>{spieltag.dateString}</td>
                <td>{spieltag.beschreibung}</td>
                <td>{spieltag.teambezeichnung}</td>
                {spieltag.firstToLegs ?
                    <td>{spieltag.firstToLegs}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieltag.modusbezeichnung ?
                    <td>{spieltag.modusbezeichnung}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieltag.startpunkte ?
                    <td>{spieltag.startpunkte}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieltag.abgeschlossen ?
                    <td><i className="pi pi-star"/></td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieltag.abgeschlossen ?
                    <td>{spieltag.gewonnenSpieleHeim + ":" + spieltag.gewonnenSpieleGegner + " (" + spieltag.punkteHeim + ":" + spieltag.punkteGegner + ")"}</td>
                    :
                    <td></td>
                }
                <td>
                    <NavLink to={NAVIGATION_ITEM.GAMEDAY_DETAIL.route + "?spieltagid=" + spieltag.id} style={{textDecoration: "none"}}>
                        <Button icon="pi pi-ellipsis-h" tooltip="Spieltag Ergebnisse"
                                style={{marginRight: "20px", marginTop:"5px"}}/>
                    </NavLink>
                    {
                        !spieltag.abgeschlossen ?
                            <Button icon="pi pi-pencil" tooltip="Spieltag ändern"
                                    style={{marginRight: "5px", marginTop:"5px"}}
                                    onClick={() => props.editSpieltag(spieltag)}/>
                            :
                            ""
                    }
                    {
                        !spieltag.abgeschlossen ?
                            <Button icon="pi pi-star"
                                    onClick={() => props.showConfirmAbschliessenDialog(spieltag.id)}
                                    style={{marginRight: "5px", marginTop:"5px"}}
                                    tooltip="Spieltag abschliessen, keine Änderung mehr möglich!!!"
                                    className="p-button-danger"/>
                            :
                            ""
                    }
                    {
                        spieltag.abgeschlossen && props.isAdmin ?
                            <Button icon="pi pi-star"
                                    onClick={() => props.changeSpieltagToNichtAbgeschlossen(spieltag.id)}
                                    style={{marginRight:"5px", marginTop:"5px"}}
                                    tooltip="Spieltag aktivieren"
                                    className="p-button-success"/>
                            :
                            ""
                    }
                    {
                        !spieltag.abgeschlossen && props.isAdmin ?
                            <Button icon="pi pi-trash"
                                    onClick={() => props.deleteSpieltag(spieltag.id)}
                                    style={{marginRight:"5px", marginTop:"5px"}}
                                    tooltip="Spieltag löschen"/>
                            :
                            ""
                    }
                </td>

            </tr>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {
    let tableRows = getRows(props);
    return (<table className="spieltagetabelle darthelfertabelle">
            <thead>
                <tr>
                    <th style={{width:100}}>Datum</th>
                    <th>Beschreibung</th>
                    <th style={{width:180}}>Mannschaft</th>
                    <th style={{width:100}}>'first to legs'</th>
                    <th style={{width:120}}>Modus</th>
                    <th style={{width:120}}>Startpunkte</th>
                    <th style={{width: 50}}>Abgeschossen</th>
                    <th style={{width: 90}}>Ergebnis</th>
                    <th style={{width: 180}}></th>
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </table>
    );
}