import React, {useEffect, useState} from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHSelectionComponent from "./DHSeclectionCompontent";

export function SelectGamedaySaisonComponent(props) {

    const [teamid, setTeamid] = useState([]);
    const [saisons, setSaisons] = useState([]);
    const [saisonLoaded, setSaisonLoaded] = useState(false);

    const callBackLoaded = saisons => {
        setSaisons(saisons);
        if( saisons.length === 1) {
            props.callbackChange(saisons[0].id);
        }
    };

    if( props.teamid !== teamid) {
        setTeamid(props.teamid);
        if( saisonLoaded === true) {
            setSaisonLoaded(false);
        }
    }

    useEffect(() => {
        if( !saisonLoaded && props.teamid !== undefined && props.teamid !== "") {
            loadGamedaySaisons(props, callBackLoaded);
            setSaisonLoaded(true);
        } else {

        }
    });

    const getValues = () => {
        let returnValue = [];
        returnValue.push({name: 'Auswahl löschen', code: undefined});
        for (const s of saisons) {
            returnValue.push({name: s.bezeichnung, code: s.id });
        }
        return returnValue;
    }

    return <DHSelectionComponent value={props.saisonid} onChange={(id) => props.callbackChange(id)} values={getValues()} title=""/>
}

function loadGamedaySaisons(props, callbackOnLoaded) {
    FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getgamedaysaisonen?teamid=" + props.teamid,
        json => {
            callbackOnLoaded(json);
        },
        responseNotOk => {
            props.callbackOnError("Fehler beim Laden der Mannschaften: " + responseNotOk.message);
        },
        error => {
            props.callbackOnError("Fehler beim Laden der Mannschaften: " + error.message);
        })
}
