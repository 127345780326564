import {NEW_LEAGUEOPERATORS,} from "../actions/leagueoperator";

function leagueoperator(state = {
    leagueoperators: [],
}, action) {
    switch (action.type) {
        case NEW_LEAGUEOPERATORS:
            return {...state, leagueoperators: action.value};
        default:
            return state
    }
}

export default leagueoperator;