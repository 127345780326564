import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {USER_ROLES} from "../../../constants/userRoles";
import ConfigUtil from "../../../util/ConfigUtil";
import NewsArea from "../../general/NewsArea";

export default function Aktionen({isOnlineDashboard, userId, userRoles, logout}) {

    const [showNews, setShowNews] = useState(false);

    const getDialogLink = (images, text, onClick) => {
        return <div style={{textDecoration: "none", color: "black", cursor: "pointer"}} onClick={() => onClick(true)}><img alt="" style={{
            marginRight: 20,
            marginLeft: 20,
            width: 30,
            marginTop: 10
        }} src={images}/>
            <span style={{whiteSpace: "nowrap"}}>{text}</span>
        </div>
    }


    const getWebcamLink = (images, text) => {
        return <div style={{textDecoration: "none", color: "black", cursor: "pointer"}} onClick={() => {
            let url = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=dh_" + userId + "&username=test&testhinweis=true";
            window.open(url, '_blank', 'location=yes,height=400,width=400,scrollbars=true,status=true')
        }
        }><img alt="" style={{
            marginRight: 20,
            marginLeft: 20,
            width: 30,
            marginTop: 10
        }} src={images}/>
            <span style={{whiteSpace: "nowrap"}}>{text}</span>
        </div>
    }

    const getLineWithInternLink = (images, text, link) => {
        return <NavLink to={link} style={{textDecoration: "none"}}>
            <div style={{textDecoration: "none", color: "black"}}><img alt="" style={{
                marginRight: 20,
                marginLeft: 20,
                width: 30,
                marginTop: 10
            }} src={images}/>
                <span style={{whiteSpace: "nowrap"}}>{text}</span>
            </div>
        </NavLink>
    }

    const getLineWithExternenLink = (images, text, link) => {
        return <a href={link} target={"_blank"} style={{textDecoration: "none"}}>
            <div style={{textDecoration: "none", color: "black"}}><img alt="" style={{
                marginRight: 20,
                marginLeft: 20,
                width: 30,
                marginTop: 10
            }} src={images}/>
                <span style={{whiteSpace: "nowrap"}}>{text}</span>
            </div>
        </a>
    }

    const isAdmin = () => {
        if (userRoles !== undefined && userRoles !== null) {
            return userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }
        return false;
    }

    const isSuperAdmin = () => {
        if (userRoles !== undefined && userRoles !== null) {
            return userRoles.some(userRole => userRole === USER_ROLES.SUPERADMIN.text);
        }
        return false;
    }

    return <div className="col-12 md:col-12 lg:col-2" style={{padding: 10}}>
        <div className="ligashortoverview"
             style={{height: "100%", borderLeft: "10px solid black"}}>
            <h3>Aktionen</h3>
            <div style={{marginLeft: 0}}>
                {!isOnlineDashboard && getLineWithInternLink("images/profil_color.png", "Profil", NAVIGATION_ITEM.PROFIL.route)}
                {getDialogLink("images/kachel/news.png", "News", setShowNews)}
                {isOnlineDashboard && getWebcamLink("images/webcam.png", "Webcam-Test")}
                {isOnlineDashboard && getLineWithInternLink("images/paypal.png", "Bezahlen", NAVIGATION_ITEM.BEZAHLEN.route)}
                {isOnlineDashboard && getLineWithInternLink("images/automat.png", "Automat", NAVIGATION_ITEM.AUTOMAT.route)}
                {getLineWithInternLink("images/search.png", "Benutzersuche", NAVIGATION_ITEM.BENUTZERSUCHE.route)}
                {isOnlineDashboard && getLineWithInternLink("images/rangliste.png", "Rangliste", NAVIGATION_ITEM.RANGLISTE.route)}
                {getLineWithExternenLink("images/hilfe_blue.png", "Support / Hilfe", "https://support.darthelfer.de")}
                {getLineWithInternLink("images/agb.jpg", "AGB", NAVIGATION_ITEM.AGB.route)}
                {getLineWithInternLink("images/kachel/impressum_blue.png", "Impressum", NAVIGATION_ITEM.IMPRESSUM.route)}
                {getLineWithInternLink("images/kachel/datenschutz_blue.png", "Datenschutz", NAVIGATION_ITEM.DATENSCHUTZ.route)}
                {isOnlineDashboard && isAdmin() && getLineWithInternLink("images/kachel/tree_blue.png", "Ligen/Turniere", NAVIGATION_ITEM.TURNIER.route)}
                {isSuperAdmin() && getLineWithInternLink("images/kachel/tshirt_blue.png", "Personen", NAVIGATION_ITEM.PLAYER_OVERVIEW.route)}
                {isSuperAdmin() && getLineWithInternLink("images/kachel/gear_blue.png", "Administration", NAVIGATION_ITEM.ADMINISTRATION.route)}
                <div style={{cursor: "pointer"}} onClick={() => logout()}><img alt="" style={{
                    marginRight: 20,
                    marginLeft: 20,
                    width: 30,
                    marginTop: 10
                }} src="images/kachel/logout_blue.png"/>Abmelden
                </div>
            </div>
        </div>
        {showNews && <NewsArea proVisible={showNews} onClose={() => setShowNews(false)}/>}
    </div>
}

