import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import ProgressButton from "./ProgressButton";
import {PrimeIcons} from "primereact/api";
import DHDialog from "./DHDialog";

class ConfirmProgressDialog extends React.Component {

    render() {
        if( !this.props.visible ) {
            return "";
        }

        const footer = (
            <div>
                {(this.props.showJaButton === null || this.props.showJaButton === undefined || this.props.showJaButton) &&
                    <ProgressButton text="Ja" icon={PrimeIcons.CHECK} disabled={false} working={this.props.working} onClick={() => this.props.callBackOnJa()} />
                }
                <Button label="Nein" icon="pi pi-times" onClick={() => this.props.callBackOnNein()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog onClose={() => this.props.callBackOnNein()} title={this.props.header} buttons={[]} comp={<>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop: "20px"}}>
                {this.props.textUnten}
            </div>
            <div style={{marginTop:20}}>
                {footer}
            </div>
        </>}/>
    }
}


ConfirmProgressDialog.propTypes = {
    header: PropTypes.string.isRequired,
    working: PropTypes.bool.isRequired,
    textOben: PropTypes.string,
    textUnten: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnJa: PropTypes.func.isRequired,
    showJaButton: PropTypes.bool,
};

export default ConfirmProgressDialog;
