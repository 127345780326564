import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import HeadToHeadChat from "../../../components/views/liga/HeadToHeadChat";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const username = urlParams.get('username');
    return {userId: state.user.id, username: username};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeadToHeadChat))