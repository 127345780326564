import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {Tabelle171er, Tabelle180er, TabelleBestDarts, TabelleHighfinishes, TabelleHighscores, TabelleLowDarts, TabelleWorstDarts} from "./ligaComponents";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import LigaZuschauenArea from "./LigaZuschauenArea";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import TurnierQRCode from "./TurnierQRCode";
import PublicTournamentStatistikTab from "../public/tournaments/PublicTournamentStatistikTab";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import RefreshCounterComp from "../../RefreshCounterComp";
import PlayerUtil from "../../../util/PlayerUtil";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";
import {ToggleButton} from "primereact/togglebutton";
import TournamentX01Area from "../tournament/TournamentX01Area";
import ProgressButton from "../../general/ProgressButton";
import TournamentAnyGameTable from "../public/tournaments/TournamentAnyGameTable";
import RundUmsBoardDialog from "../veranstalter/board/RundUmsBoardDialog";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import {ProgressSpinner} from "primereact/progressspinner";

export default function AnyGamesDetail({userId, tournamentId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [zeigeBeschreibungVoll, setZeigeBeschreibungVoll] = useState(false)
    const [showAktionen, setShowAktionen] = useState(false)
    const [tournament, setTourament] = useState(undefined)
    const [ranglisteWrapper, setRanglisteWrapper] = useState(undefined)
    const [games, setGames] = useState([])
    const [usernames, setUsernames] = useState([])
    const [boardGames, setBoardGames] = useState([])

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showRundUmsBoardDialog, setShowRundUmsBoardDialog] = useState(false);

    const [saveGameWorking, setSaveGameWorking] = useState(false);
    const [showNewGame, setShowNewGame] = useState(false);
    const [selectedPlayer1, setSelectedPlayer1] = useState(undefined);
    const [selectedPlayer2, setSelectedPlayer2] = useState(undefined);

    const [sets, setSets] = useState(undefined)
    const [firstToLegs, setFirstToLegs] = useState(undefined)
    const [bestToLegs, setBestToLegs] = useState(undefined)
    const [modusId, setModusId] = useState(undefined)
    const [startpunkte, setStartpunkte] = useState(undefined)

    useEffect( () => {
        ladeDaten();
    } ,[]);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/anygamesturnier/" + tournamentId;
        FetchUtil.fetchGet(url,
            json => {
                setTourament(json.tournament);
                setRanglisteWrapper(json.ranglisteWrapper);
                setGames(json.games);
                setUsernames(json.usernameDtos);
                setBoardGames(json.boardGames);

                let modus = json.tournament.tournamentModusEntities.find(element => element.modusTyp === TOURNAMENTMODUS.ANY_GAMES.id);
                setStartpunkte(modus.startpunkte);
                setModusId(modus.modusid);
                setSets(modus.sets);
                setFirstToLegs(modus.firstToLegs);
                setBestToLegs(modus.bestOfLegs);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden des Turniers: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden des Turniers: " + error.message);
            });
    }

    const deleteGame = (gameId) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/anygames/delete/" + tournamentId + "/" + gameId,
            {},
            () => {
                setInfoMessage("Game wurde gelöscht");
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            });
    }

    const saveGame = () => {
        setErrorMessage(undefined);
        let data = {player1Id: selectedPlayer1, player2Id: selectedPlayer2,
            modusId: modusId,
            sets: sets,
            firstToLegs: firstToLegs,
            bestToLegs: bestToLegs,
            startpunkte: startpunkte};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/anygames/save/" + tournamentId,
            data,
            () => {
                setInfoMessage("Game wurde gespeichert");
                setSelectedPlayer1(undefined);
                setSelectedPlayer2(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
        });
    }

    const isAktuellerUserAdministrator = () => {
        if( tournament.erstellerUserId === userId) {
            return true;
        }
        for (const admin of tournament.administratoren) {
            if (admin.userId === userId) {
                return true;
            }
        }
        return false;
    }


    const getModusRow = (key, bezeichner, modusElement) => {
        let wert = "";
        if( bezeichner !== null && bezeichner !== undefined) {
            wert += "Modus: " + bezeichner;
        }
        wert += " Gametype: " + modusElement.gameType + " - ";
        if( modusElement.startpunkte !== null) {
            wert += " Startpunkte: " + modusElement.startpunkte;
        }
        if( modusElement.modusid !== null) {
            wert += " Modus: " + getModusNameById(modusElement.modusid);
        }
        if( modusElement.firstToLegs !== null) {
            wert += " ft " + modusElement.firstToLegs + " legs";
        }
        if( modusElement.bestOfLegs !== null) {
            wert += " best of " + modusElement.bestOfLegs + " legs";
        }

        return <div key={key}>{wert} {modusElement.sets > 1 ? " - sets: " + modusElement.sets : ""}</div>
    }


    const getHightlightsArea = () => {
        if( !ranglisteWrapper ) {
            return "";
        }
        return <div className="grid">
            <Tabelle180er rangliste180er={ranglisteWrapper.rangliste180er} getSpielerName={getSpielerName}/>
            <Tabelle171er rangliste171er={ranglisteWrapper.rangliste171er} getSpielerName={getSpielerName}/>
            <TabelleHighfinishes ranglisteHighfinishes={ranglisteWrapper.ranglisteHighfinishes} getSpielerName={getSpielerName}/>
            <TabelleHighscores ranglisteHighscores={ranglisteWrapper.ranglisteHighscores} getSpielerName={getSpielerName}/>
            <TabelleLowDarts lowDarts={ranglisteWrapper.lowDarts}  getSpielerName={getSpielerName} />
            <TabelleBestDarts bestDarts={ranglisteWrapper.bestDarts}  getSpielerName={getSpielerName} />
            <TabelleWorstDarts worstDarts={ranglisteWrapper.worstDarts}  getSpielerName={getSpielerName} />
        </div>;
    }

    const getSpielerName = (id) => {
        let players = tournament.tournamentPlayerEntities;
        return PlayerUtil.getSpielerName(players, id);
    }

    const getStatistikArea = () => {
        if( !ranglisteWrapper ) {
            return "";
        }
        return <div style={{backgroundColor: "#022836", color:"white", textAlign:"left", padding:5}}>
            <PublicTournamentStatistikTab tournamentId={tournament.id} participants={tournament.tournamentPlayerEntities}
                                          gesamtStatistik={ranglisteWrapper.statistikDto}
                                          playerStatistiks={ranglisteWrapper.playerStatistiks}/>
        </div>
    }

    const getZuschauenArea = () => {
        return <LigaZuschauenArea tournamentId={tournament.id}/>
    }

    const getAdministratoren = () => {
        if (tournament === null || tournament === undefined || tournament.administratoren === null || tournament.administratoren === undefined) {
            return "";
        }
        let administratoren = "";
        for (const administrator of tournament.administratoren) {
            if( administratoren !== "") {
                administratoren += " - ";
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    const getPlayerSelectionArray = () => {
        let auswahl = [];
        auswahl.push({name: 'Auswahl löschen', code: undefined});
        for (const player of tournament.tournamentPlayerEntities) {
            auswahl.push({name: PlayerUtil.getSpielerName(tournament.tournamentPlayerEntities, player.id), code: player.id})
        }
        return auswahl;
    }

    const getUsernamesByUserId = (userId) => {
        for (const username of usernames) {
            if (username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    const getGamesArea = () => {
        return <>
            <div style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                <ToggleButton onLabel="Neues Game anlegen"
                              offLabel="Neues Game anlegen - Ausgeblendet"
                              style={{width: 350, marginRight: 5}}
                              disabled={tournament.tournamentstatus !== "gestartet"}
                              checked={showNewGame}
                              onChange={(e) => setShowNewGame(!showNewGame)}/>
            </div>
            {getGamesNewArea()}
            <div style={{marginTop: 30}}>
                <TournamentAnyGameTable tournamentId={tournament.id}  eventOperatorId={tournament.eventOperatorId}
                                        games={games} players={tournament.tournamentPlayerEntities} boardGames={boardGames}
                                        isAdmin={isAktuellerUserAdministrator()} onRefresh={() => ladeDaten()} onDeleteGame={(gameId) => deleteGame(gameId)}/>
            </div>
        </>
    }

    const canSave = () => {
        if( !selectedPlayer1 && !selectedPlayer2 ) {
            return false;
        }
        return sets && (firstToLegs || bestToLegs) && modusId && startpunkte
    }

    const getGamesNewArea = () => {
        if( !showNewGame) {
            return "";
        }
        let playerSelectionArray = getPlayerSelectionArray();
        return <div style={{padding: 20, textAlign: "left"}}>
            <div style={{fontWeight: "bold"}}>
                Neues Game anlegen
            </div>
            <div style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                <span style={{marginRight: 10}}>Spieler 1:</span><DHSelectionComponent value={selectedPlayer1} onChange={(value) => setSelectedPlayer1(value)} values={playerSelectionArray}
                                                                                       title="Auswahl - Spieler 1 "/>
            </div>
            <div style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                <span style={{marginRight: 10}}>Spieler 2:</span><DHSelectionComponent value={selectedPlayer2} onChange={(value) => setSelectedPlayer2(value)} values={playerSelectionArray}
                                                                                       title="Auswahl - Spieler 2 "/>
            </div>
            <div style={{fontWeight: "bold", marginTop:30}}>
                Modus:
            </div>
            <div style={{marginTop: 10, alignItems: "center"}}>
                <TournamentX01Area showSets={true} showBestOfLegs={true} showPflichtfelder={true}
                                   sets={sets}
                                   firstToLegs={firstToLegs}
                                   bestOfLegs={bestToLegs}
                                   modusId={modusId} startpunkte={startpunkte}
                                   onSetsChanged={(sets) => setSets(sets)}
                                   onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                                   onBestOfLegsChanged={(bestOfLegs) => setBestToLegs(bestOfLegs)}
                                   onModusIdChanged={(modusId) => setModusId(modusId)}
                                   onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
            </div>
            <div style={{marginTop: 10, display: "flex", alignItems: "center"}}>
                <ProgressButton text="Game speichern" working={saveGameWorking} icon={PrimeIcons.SAVE} disabled={!canSave()}
                                onClick={() => saveGame()}/>
            </div>
            <hr/>
        </div>
    }

    const getTeilnehmerArea = () => {
        return <LigaTeilnehmerArea liga={tournament}
                                   players={tournament.tournamentPlayerEntities}
                                   showEditButton={true}
                                   readonly={!isAktuellerUserAdministrator()}
                                   getUsernamesByUserId={(userId) => getUsernamesByUserId(userId)}
                                   callBackRefresh={() => ladeDaten()}
                                   isVerschiebenErlaubt={false}
                                   showUsernames={true}
                                   isAdmin={isAktuellerUserAdministrator()}/>
    }

    const getBeschreibungArea = (fixedColumWidth) => {
        if (tournament.beschreibung === null || tournament.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if (zeigeBeschreibungVoll || tournament.beschreibung.length <= 50) {
            beschreibung = tournament.beschreibung;
        } else {
            beschreibung = tournament.beschreibung.substring(0, 50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor: "pointer"}}
                 onClick={() => setZeigeBeschreibungVoll(!zeigeBeschreibungVoll)}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }

    const getTurnierDetailArea = () => {
        let modusArray = [];
        let modus = tournament.tournamentModusEntities.find(element => element.modusTyp === TOURNAMENTMODUS.ANY_GAMES.id);
        modusArray.push(getModusRow("key_modus", "Modus", modus));

        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">{tournament.bezeichnung}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{tournament.beginnMitUhrzeitString}</div>
                    </div>
                    {getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{tournament.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierleitung:</div>
                        <div className="col">{getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierstatus:</div>
                        <div className="col">{tournament.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {modusArray}
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                {isAktuellerUserAdministrator() &&
                    <Button label="Bearbeiten" icon="pi pi-pencil"
                            onClick={() => setShowEditDialog(true)}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                {isAktuellerUserAdministrator() &&
                    <Button label="Aktionen" icon={PrimeIcons.BOLT}
                            onClick={() => setShowAktionen(true)}
                            style={{height: 40, width: 150, marginTop: 5, marginRight: 5}}/>
                }
                {tournament.tournamentstatus !== "abgeschlossen" && !tournament.online && isAktuellerUserAdministrator() &&
                    <Button label="Board/Schreiber verwalten" icon="pi pi-book" onClick={() => setShowRundUmsBoardDialog(true)}
                            style={{height: 40, marginTop:5, marginRight: 5}}/>
                }
                <RefreshCounterComp text="Auto-Refresh" onRefresh={() => ladeDaten()}/>
            </div>

        </div>
    }

    const isTurnierRunning = () => {
        return tournament.tournamentstatus !== "neu";
    }

    const isTurnierAbgeschlossen = () => {
        return tournament.tournamentstatus === "abgeschlossen";
    }

    if (!tournament) {
        return <div>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <ProgressSpinner style={{width:100, height:100}}/>
        </div>
    }

    return <div
        style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
            <div className="col" style={{marginTop: 10, padding: 0}}>
                Turnier - Details
            </div>
        </div>
        <div style={{paddingRight: 20, paddingLeft: 20}}>
            {getTurnierDetailArea()}
        </div>

        <TabView style={{width: "100%", paddingTop: 10, paddingLeft: 10, paddingRight: 10, border: "none"}}>
            <TabPanel header="Teilnehmer">
                {getTeilnehmerArea()}
            </TabPanel>
            <TabPanel header="Games">
                {getGamesArea()}
            </TabPanel>
            <TabPanel header="Highlights" disabled={!isTurnierRunning() || tournament.gameType !== "x01"}>
                {getHightlightsArea()}
            </TabPanel>
            <TabPanel header="Statistik" disabled={!isTurnierRunning() || tournament.gameType !== "x01"}>
                {getStatistikArea()}
            </TabPanel>
            <TabPanel header="Zuschauen" disabled={!isTurnierRunning() || isTurnierAbgeschlossen()}>
                {getZuschauenArea()}
            </TabPanel>
            {tournament.eventOperatorId !== null && tournament.eventOperatorId !== undefined &&
                <TabPanel header="Links">
                    <TurnierQRCode eventOperatorId={tournament.eventOperatorId} tournamentId={tournament.id}
                                   tournamentSystem={tournament.tournamentstatus} onlineTournament={tournament.online} bezeichnung={tournament.bezeichnung}/>
                </TabPanel>
            }
        </TabView>
        {showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => setErrorMessage(message)}
                                                onInfo={(message) => setInfoMessage(message)}
                                                onClearMessages={() => setErrorMessage(undefined)}
                                                callBackRefresh={ladeDaten}
                                                callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                proTournament={tournament}
                                                onClose={() => setShowAktionen(false)}
                                                callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
        />}
        {showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={tournament.eventOperatorId} onClose={() => setShowRundUmsBoardDialog(false)}/>}
        {showEditDialog && <TurnierVerwaltenDialog tournamentId={tournamentId}
                                                              callBackRefresh={ladeDaten}
                                                              callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                              callBackOnClose={() => setShowEditDialog(false)}/>
        }

    </div>

}

