import React, {useEffect, useState} from "react";
import {Button} from 'primereact/button';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import UserSearch from "../liga/UserSearch";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

function TurnierAdminsDialog({callBackOnClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [user, setUser] = useState(undefined);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    function ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/leagueoperator/admins",
            json => {
                setAdmins(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            });
    }

    function save() {
        setErrorMessage(undefined);
        let data = {};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/leagueoperator/save/" + user.id,
            data,
            json => {
                setInfoMessage("Daten wurden gespeichert!");
                setUser(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            });
    }

    function loeschen(userId) {
        setErrorMessage(undefined);
        let data = {};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/leagueoperator/delete/" + userId,
            data,
            json => {
                setInfoMessage("Admin wurde gelöscht!");
                setUser(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen: " + error.message);
            });
    }

    function getAdminRows() {
        let rows = [];
        for (const admin of admins) {
            rows.push(<div className="grid" key={"row_admin" + admin.userId} style={{marginBottom: 20}}>
                <div className="col-fixed" style={{width: 50}}>
                    <Button icon="pi pi-trash"
                            onClick={() => loeschen(admin.userId)}/>
                </div>
                <div className="col">
                    {admin.name}
                </div>
            </div>);
        }
        return rows;
    }

    return <DHDialog onClose={() => callBackOnClose()} title="Admins verwalten" show={true} buttons={<div>
        <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackOnClose()}
                className="p-button-secondary"/>
    </div>}
                     comp={<>

                         <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                             onClearErrorMessage={() => setErrorMessage(undefined)}/>
                         <div>
                             Die angelegten Admins werden dir bei der Turnier- und Ligaanlage zur Auswahl angezeigt.
                         </div>
                         <div style={{marginTop: 20}}>
                             Username:
                         </div>
                         <div>
                             <UserSearch
                                 callBackError={(message) => {
                                     setErrorMessage(message);
                                     setUser(undefined);
                                 }}
                                 callBackClearMessages={() => setErrorMessage(undefined)}
                                 callBackInfo={(message) => setInfoMessage(message)}
                                 callBackUserFound={(user) => setUser(user)}
                                 shortSearchButton={true}/>
                         </div>
                         <div style={{marginTop: 10}}>
                             <Button label="Speichern"
                                     type="button" onClick={() => save()} disabled={user === undefined}/>
                         </div>
                         <hr/>
                         {getAdminRows()}
                     </>}/>
}

TurnierAdminsDialog.propTypes = {
    callBackOnClose: PropTypes.func.isRequired,
};

export default TurnierAdminsDialog;
