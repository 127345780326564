import React, {useState} from 'react'
import UserSearch from "./UserSearch";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink, Redirect} from "react-router-dom";
import {Button} from "primereact/button";
import ConfirmDialog from "../../general/ConfirmDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function Freunde({userId, friends}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [user, setUser] = useState(undefined);

    const [showOfflineFriends, setShowOfflineFriends] = useState(false);
    const [selectedFreundschaftId, setSelectedFreundschaftId] = useState(undefined);
    const [showEntfernenDialog, setShowEntfernenDialog] = useState(false);
    const [showBestaetigenDialog, setShowBestaetigenDialog] = useState(false);

    const freundschaftBestaetigen = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.freundschaft + "/anfragebestaetigen?freundschaftId=" + selectedFreundschaftId;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Du hast die Freundschaft bestätigt");
                setShowBestaetigenDialog(false);
                setSelectedFreundschaftId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Bestätigen der Freundschaften: " + responseNotOk.message);
                setShowBestaetigenDialog(false);
                setSelectedFreundschaftId(undefined);
            },
            error => {
                setErrorMessage("Fehler beim Bestätigen der Freundschaften: " + error.message);
                setShowBestaetigenDialog(false);
                setSelectedFreundschaftId(undefined);
            });
    }

    const freundschaftEntfernen = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.freundschaft + "/entfernen?freundschaftId=" + selectedFreundschaftId;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setShowEntfernenDialog(false);
                setSelectedFreundschaftId(undefined);
                setInfoMessage("Du hast die Freundschaft/Anfrage gelöscht.");
            },
            responseNotOk => {
                setShowEntfernenDialog(false);
                setSelectedFreundschaftId(undefined);
                setErrorMessage("Fehler beim Entfernen der Freundschaften: " + responseNotOk.message);
            },
            error => {
                setShowEntfernenDialog(false);
                setSelectedFreundschaftId(undefined);
                setErrorMessage("Fehler beim Entfernen der Freundschaften: " + error.message);
            });
    }

    const getFreunschaft = (userId, freundschaftId, name, onlineStatus, isBestaetigen) => {
        return <>
            <div style={{display: "flex", alignItems: "center"}}>
                <NavLink to={NAVIGATION_ITEM.PROFILFRIEND.route + "?profilId=" + userId} style={{textDecoration: "none"}}>
                        <span style={{textDecoration: "none", color: "black", cursor: "pointer"}}>
                        <img src={onlineStatus ? "/images/dot_green.png" : "/images/dot_red.png"} style={{width: 15, height: 15, marginRight: 5}} alt="Onlinestatus"/> {name}</span>
                </NavLink>
                {isBestaetigen &&
                    <Button icon="pi pi-check" style={{marginLeft:5}} onClick={() => {
                        setShowBestaetigenDialog(true);
                        setSelectedFreundschaftId(freundschaftId);
                    }}
                    />
                }
                <Button icon="pi pi-trash" style={{marginLeft: 5}} onClick={() => {
                    setShowEntfernenDialog(true);
                    setSelectedFreundschaftId(freundschaftId);}}/>
            </div>
            <hr style={{borderTop: "1px dashed gray"}}/>
        </>

    }

    const getFreundschaftenZurBestaetigung = () => {
        let rows = [];
        for (const freundschaft of friends) {
            if (freundschaft.userId2 === userId && freundschaft.anfrage) {
                rows.push(freundschaft);
            }
        }
        return rows;
    }

    const getFreundschaftenWarteAufBestaetigung = () => {
        let rows = [];
        for (const freundschaft of friends) {
            if (freundschaft.userId1 === userId && freundschaft.anfrage) {
                rows.push(freundschaft);
            }
        }
        return rows;
    }

    const getRowsFreunde = () => {
        let rows = [];
        for (const freundschaft of friends) {
            if (freundschaft.anfrage) {
                continue;
            }
            if (!showOfflineFriends && !freundschaft.online) {
                continue;
            }
            rows.push(getRow(freundschaft, false));
        }
        return rows;
    }

    const getRowsZurBestaetigung = () => {
        let rows = [];
        let freundschaften = getFreundschaftenZurBestaetigung();
        for (const freundschaft of freundschaften) {
            rows.push(getRow(freundschaft, true));
        }
        return rows;
    }

    const getRowsWarteAufBestaetigung = () => {
        let rows = [];
        let freundschaften = getFreundschaftenWarteAufBestaetigung();
        for (const freundschaft of freundschaften) {
            rows.push(getRow(freundschaft, false));
        }
        return rows;
    }

    const getRow = (freundschaft, isBestaetigen) => {
        let userIdFreund = freundschaft.userId1 !== userId ? freundschaft.userId1 : freundschaft.userId2;
        let username = freundschaft.name + " (" + freundschaft.username + ")"
        return <div style={{marginBottom: 5}} key={"key_onlinefriend_" + userIdFreund}>
            {getFreunschaft(userIdFreund, freundschaft.id, username, freundschaft.online, isBestaetigen)}
        </div>
    }

    const getAnzahlOfflineUser = () => {
        return friends.filter(f => !f.online).length;
    }

    if (user) {
        return <Redirect to={NAVIGATION_ITEM.PROFILFRIEND.route + "?profilId=" + user.id}/>;
    }


    return <div className="col-12 md:col-12 lg:col-3" style={{padding: 10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #00ff55"}}>
            <div style={{marginTop: 20, marginBottom: 10}}>
                Benutzer suchen:
            </div>
            <UserSearch
                callBackError={(message) => {
                    setErrorMessage(message);
                    setUser(undefined);
                }}
                callBackClearMessages={() => setErrorMessage(undefined)}
                callBackInfo={(message) => setInfoMessage(message)}
                callBackUserFound={(user) => setUser(user)}
                shortSearchButton={true}/>
            <hr/>
            <h3>Freunde</h3>
            <div style={{textAlign: "left", marginBottom: 20, cursor: "pointer"}} onClick={() => setShowOfflineFriends(!showOfflineFriends)}>
                <img src="/images/pfeil_rechts_rot_transparent.png" style={{height: 10, marginRight: 5}}/>
                {showOfflineFriends ? "Offline-User (" + getAnzahlOfflineUser() + ") nicht anzeigen" : "Offline-User (" + getAnzahlOfflineUser() + ") ebenfalls anzeigen..."}
            </div>
            <div>
                {getRowsFreunde().length > 0 ? getRowsFreunde() : "keine Freunde online"}
            </div>
            {getFreundschaftenZurBestaetigung().length > 0 && <>
                <h4>Anfragen zur Bestätigung</h4>
                <div>
                    {getRowsZurBestaetigung()}
                </div>
            </>
            }
            {getFreundschaftenWarteAufBestaetigung().length > 0 && <>
                <h4>Du wartest auf Bestätigung</h4>
                <div>
                    {getRowsWarteAufBestaetigung()}
                </div>
            </>
            }
        </div>
        {showEntfernenDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() =>  {
                               setShowEntfernenDialog(false);
                               setSelectedFreundschaftId(undefined);
                           }}
                           callBackOnJa={() => freundschaftEntfernen()}
                           header="Freundschaft/Anfrage entfernen"
                           textOben=""
                           textUnten="Möchtest du die Freundschaft/Anfrage wirklich löschen?"/>
        }
        {showBestaetigenDialog &&
            <ConfirmDialog visible={true}
                           callBackOnNein={() =>  {
                               setShowBestaetigenDialog(false);
                               setSelectedFreundschaftId(undefined);
                           }}
                           callBackOnJa={() => freundschaftBestaetigen()}
                           header="Freundschaft/Anfrage bestätigen"
                           textOben=""
                           textUnten="Möchtest du die Freundschaft/Anfrage wirklich bestätigen?"/>
        }
    </div>
}
