import React from "react";
import PropTypes from "prop-types";
import FetchUtil from "../../util/FetchUtil";
import DateUtil from "../../util/DateUtil";
import ConfigUtil from "../../util/ConfigUtil";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {ScrollPanel} from 'primereact/scrollpanel';

class TrainingsSessionSelectComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {visible: false};

        this.uebermittleGewaehlteTrainingssession = this.uebermittleGewaehlteTrainingssession.bind(this);
        this.selectTrainingssession = this.selectTrainingssession.bind(this);
    }

    componentDidMount() {
        this.ladeTrainingssesion();
    }

    selectTrainingssession(selectSession) {
        // hier wird die Session noch mal in der Liste gesucht,
        // da nur in der Liste die Session davor u. danach vorhanden ist
        // und evtl. später zum Blättern benötigt wird.
        for (let i = 0; i < this.state.trainingssessions.length; i++) {
            let session = this.state.trainingssessions[i];
            if (session.id === selectSession.id) {
                this.props.callBackSelectFunc(session);
                break;
            }
        }
    }

    ladeTrainingssesion() {
        if( this.props.selectLast === undefined || this.props.selectLast === false) {
            // die aktuelle Trainingssession wird nur geholt, sobald nicht die letzte selektiert werden soll.
            FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.training + "/aktuellesession",
                json => {
                    this.props.callBackSelectFunc(json);
                },
                responseNotOk => {
                    this.props.callBackOnErrorFunc("Fehler beim Laden der Trainigssession: " + responseNotOk.message)
                },
                error => {
                    this.props.callBackOnErrorFunc("Fehler beim Laden der Trainigssession: " + error.message)
                });
        }

        let url;
        if (this.props.loadAll !== undefined && this.props.loadAll) {
            url = ConfigUtil.getConfig().resourceUrls.training + "/all";
        } else {
            url = ConfigUtil.getConfig().resourceUrls.training + "/allenichtabgeschlossen";
        }
        FetchUtil.fetchGet(url,
            json => {
                if( this.props.selectLast !== undefined  && this.props.selectLast === true && json.length > 0) {
                    this.props.callBackSelectFunc(json[0]);
                }
                this.setState({...this.state, trainingssessions: json});
            },
            responseNotOk => {
                this.props.callBackOnErrorFunc("Fehler beim Laden der Trainigssession zur Auswahl: " + responseNotOk.message)
            },
            error => {
                this.props.callBackOnErrorFunc("Fehler beim Laden der Trainigssession zur Auswahl: " + error.message)
            });

    }

    render() {

        let sidebarstyle;
        if (this.props.loadAll !== undefined && this.props.loadAll) {
            sidebarstyle = {width: "100%"};
        } else {
            sidebarstyle = {width: "300px"};
        }

        if (this.props.trainingssession === undefined || this.props.trainingssession.id === undefined) {
            let trainingssessionButtons = this.getTrainingssessionButtons(this.props.callBackSelectFunc);
            return <div>
                <Button type="button" label="Auswählen" onClick={() => this.setState({visible: true})}/>
                <Sidebar visible={this.state.visible} style={sidebarstyle}
                         onHide={(e) => this.setState({visible: false})}
                         position="left" showCloseIcon={false}>
                    <ScrollPanel style={{width: '100%', height: '100%'}} className="custom">
                        <div className="grid"
                             style={{padding: "0px", marginTop: "30px"}}>{trainingssessionButtons}</div>
                        <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                            <Button key="session_abbrechen" label="Abbrechen / Schliessen" className="p-button-danger"
                                    style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                    onClick={() => this.setState({...this.state, visible: false})}/>
                        </div>
                    </ScrollPanel>
                </Sidebar>
            </div>
        }

        let beforeTrainingsession = this.props.trainingssession.beforeSession;
        let afterTrainingsession = this.props.trainingssession.afterSession;
        return <div>
            <div className="grid" style={{textAlign: "left", verticalAlign: "center"}}>
                <div className="col-fixed" style={{width: "100%"}}>
                    <Button icon="pi pi-times" onClick={(e) => this.props.callBackDeleteFunc()}
                            style={{marginRight: "10px"}}/>
                    {DateUtil.localDateTimeToString(this.props.trainingssession.timestampAsString)}
                    {this.props.zeigeVorwaertsRueckwaerts ?
                        <Button icon="pi pi-chevron-left"
                                disabled={!beforeTrainingsession}
                                tooltip="Session davor anzeigen"
                                onClick={(e) => this.selectTrainingssession(beforeTrainingsession)} style={{
                            marginLeft: "10px",
                            marginRight: "5px"
                        }}/>
                        : <div/>
                    }
                    {this.props.zeigeVorwaertsRueckwaerts ?
                        <Button icon="pi pi-chevron-right"
                                disabled={!afterTrainingsession}
                                tooltip="Session danach anzeigen"
                                onClick={(e) => this.selectTrainingssession(afterTrainingsession)}
                                style={{marginRight: "10px"}}/>
                        : <div/>
                    }
                    {this.props.trainingssession.vonStatistikAusschliessen && <span>Statistik 'nein'</span>}
                </div>
            </div>
            <div>{this.props.trainingssession.beschreibung}</div>
        </div>
    }

    uebermittleGewaehlteTrainingssession(trainingssession) {
        this.props.callBackSelectFunc(trainingssession);
        this.setState({visible: false});
    }

    getTrainingssessionButtons(callBackFunc) {
        let buttons = [];
        if (this.state.trainingssessions === undefined) {
            return buttons;
        }

        for (let i = 0; i < this.state.trainingssessions.length; i++) {
            let session = this.state.trainingssessions[i];
            let label = DateUtil.localDateTimeToString(session.timestampAsString);
            let button = <Button label={label}
                                 key={session.id}
                                 onClick={(e) => this.uebermittleGewaehlteTrainingssession(session)}
                                 className="p-button-secondary"
                                 style={{height: "40px", marginRight: "10px", marginBottom: "10px"}}/>;

            buttons.push(<div key={"button_" + session.id}>{button}</div>);
        }
        return buttons;
    }
}

TrainingsSessionSelectComponent.propTypes = {
    trainingssession: PropTypes.object,
    callBackSelectFunc: PropTypes.func.isRequired,
    callBackDeleteFunc: PropTypes.func.isRequired,
    callBackOnErrorFunc: PropTypes.func.isRequired,
    loadAll: PropTypes.bool,
    selectLast: PropTypes.bool,
    zeigeVorwaertsRueckwaerts: PropTypes.bool.isRequired,
};

export default TrainingsSessionSelectComponent;
