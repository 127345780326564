import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';

import {Button} from "primereact/button";

import ConfirmDialog from "../../general/ConfirmDialog";
import {Sidebar} from "primereact/sidebar";
import Trainingsergebnisse from "../../general/Trainingsergebnisse";

import Table from "./trainingTable";

import {ScrollPanel} from 'primereact/scrollpanel';

import TrainingSessionEditDialog from "./TrainingSessionEditDialog";

import {USER_ROLES} from "../../../constants/userRoles";

class TrainingOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trainingssessions: [],
            showConfirmDialogSessionAbschliessen: false,
            showErgebnisse: false,
            showEditDialog: false,
        };

        this.changeTrainingssessionToAbgeschlossen = this.changeTrainingssessionToAbgeschlossen.bind(this);
        this.changeTrainingssessionToNichtAbgeschlossen = this.changeTrainingssessionToNichtAbgeschlossen.bind(this);
        this.showConfirmAbschliessenDialog = this.showConfirmAbschliessenDialog.bind(this);
        this.showErgebnisseSidebar = this.showErgebnisseSidebar.bind(this);
        this.showToast = this.showToast.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.editTrainingssession = this.editTrainingssession.bind(this);
        this.callBackRefresh = this.callBackRefresh.bind(this);
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showWarn(message) {
        this.messages.show({
            severity: 'warn',
            sticky: 'true',
            summary: 'Achtung!!!',
            detail: message
        });
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    showConfirmAbschliessenDialog(id) {
        this.setState({...this.state, showConfirmDialogSessionAbschliessen: true, selectedTrainingssession: id})
    }

    showErgebnisseSidebar(id) {
        this.setState({...this.state, showErgebnisse: true, selectedTrainingssession: id})
    }

    changeTrainingssessionToAbgeschlossen() {
        this.messages.clear();
        let id = this.state.selectedTrainingssession;
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.training + "/setabgeschlossen",
            {id},
            result => {

                if (result === undefined || result === null || result === "") {
                    this.showToast("Trainigssession wurde abgeschlossen.");
                } else {
                    this.showWarn(result)
                }
                this.loadData();
                this.setState({
                    ...this.state,
                    showConfirmDialogSessionAbschliessen: false,
                    selectedTrainingssession: undefined
                })
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status: " + responseNotOk.message);
                this.setState({
                    ...this.state,
                    showConfirmDialogSessionAbschliessen: false,
                    selectedTrainingssession: undefined
                })
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
                this.setState({
                    ...this.state,
                    showConfirmDialogSessionAbschliessen: false,
                    selectedTrainingssession: undefined
                })
            })

    }


    changeTrainingssessionToNichtAbgeschlossen(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.training + "/setnichtabgeschlossen",
            {id},
            result => {
                this.showToast("Trainigssession wurde aktiviert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Status auf aktiv: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Ändern des Status: " + error.message);
            })
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.training + "/all",
            json => {
                this.setState({trainingssessions: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Trainingssessions: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Trainingssessions: " + error.message);
            })
    }

    neuenTrainingstagAnlegen() {
        this.setState({
            showEditDialog: true,
            selectedTrainingssession: undefined
        });
    }

    editTrainingssession(trainingssession) {
        this.setState({showEditDialog:true, selectedTrainingssession:trainingssession});
    }

    callBackRefresh() {
        this.loadData();
    }

    render() {
        let isAdmin = false;
        if( this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding:0, margin:0, backgroundColor:"#c1f1ec"}}>
                <div className="col" style={{marginTop:10, padding:0}}>
                    Trainingstage
                </div>
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <div className="col" style={{textAlign:"left"}}>
                    <Button label="Neuen Trainingstag anlegen" icon="pi pi-pencil" tooltip="Neuen Trainingstag anlegen" onClick={() => this.neuenTrainingstagAnlegen()}/>
                </div>
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <Table isAdmin={isAdmin}
                       trainingssessions={this.state.trainingssessions}
                       showErgebnisseSidebar={this.showErgebnisseSidebar}
                       showConfirmAbschliessenDialog={this.showConfirmAbschliessenDialog}
                       changeTrainingssessionToNichtAbgeschlossen={this.changeTrainingssessionToNichtAbgeschlossen}
                       editTrainingssession={this.editTrainingssession}/>
            </div>
            <ConfirmDialog visible={this.state.showConfirmDialogSessionAbschliessen}
                           callBackOnNein={() => this.setState({
                               showConfirmDialogSessionAbschliessen: false,
                               selectedTrainingssession: undefined
                           })}
                           callBackOnJa={() => this.changeTrainingssessionToAbgeschlossen()}
                           header="Session wirklich abschliessen"
                           textOben="Sobald eine Session abgeschlossen wird, kann diese Aktion nicht mehr rückgängig gemacht werden."
                           textUnten="Möchtest du die Session wirklich abschliessen?"/>
            {this.state.showErgebnisse &&
                <Sidebar visible={this.state.showErgebnisse} style={{width: "100%"}}
                         onHide={(e) => this.setState({showErgebnisse: false})}
                         position="left" showCloseIcon={false}>
                    <ScrollPanel style={{width: '100%', height: '100%'}}>
                        <Trainingsergebnisse trainingssessionid={this.state.selectedTrainingssession} counter={0}/>
                        <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                            <Button label="Abbrechen / Schliessen" className="p-button-danger"
                                    style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                    onClick={() => this.setState({...this.state, showErgebnisse: false})}/>
                        </div>
                    </ScrollPanel>
                </Sidebar>
            }
            {this.state.showEditDialog &&
            <TrainingSessionEditDialog visible={this.state.showEditDialog}
                              trainingssession={this.state.selectedTrainingssession}
                              callBackRefresh={this.callBackRefresh}
                                       callBackEditDialogClose={() => this.setState({showEditDialog: false, selectedTrainingssession: undefined})}/>
            }
        </div>
    }
}

TrainingOverview.propTypes = {};

export default TrainingOverview