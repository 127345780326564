import React from 'react'
import AktionenContainer from "../../../containers/views/liga/AktionenContainer";
import PropTypes from "prop-types";
import MeineSpieleOverviewContainer from "../../../containers/views/liga/MeineSpieleOverviewContainer";
import GamesContainer from "../../../containers/views/liga/GamesContainer";
import FreundeContainer from "../../../containers/views/liga/FreundeContainer";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../../util/ConfigUtil";
import LigaShortOverviewContainer from "../../../containers/views/liga/LigaShortOverviewContainer";
import TurnierShortOverviewContainer from "../../../containers/views/liga/TurnierShortOverviewContainer";
import FetchUtil from "../../../util/FetchUtil";
import SchnellAktionenContainer from "../../../containers/views/liga/SchnellAktionenContainer";
import StartseiteLinks from "./StartseiteLinks";

let stompClient = undefined;

class LigaDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.websocketConnect = this.websocketConnect.bind(this);
        this.websocketDisConnect = this.websocketDisConnect.bind(this);
    }

    componentDidMount() {
        this.websocketConnect();
        if( this.props.leagueoperators === null || this.props.leagueoperators === undefined || this.props.leagueoperators.length === 0) {
            this.ladeLeagueOperators();
        }
    }

    componentWillUnmount() {
        this.websocketDisConnect();
    }

    ladeLeagueOperators() {
        let url = ConfigUtil.getConfig().resourceUrls.leagueoperator + "/all";
        FetchUtil.fetchGet(url,
            json => {
                this.props.newLeagueOperators(json);
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der LeagueOperators: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der LeagueOperators: " + error.message);
            });
    }

    websocketDisConnect() {
        if( stompClient !== null && stompClient !== undefined) {
            stompClient.disconnect(() => {});
        }
    }

    websocketConnect() {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                const destinationLogin = "/topic/public/user/login/" + this.props.userId;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destinationLogin);
                stompClient.subscribe(destinationLogin, () => console.log("subscribe wurde aufgerufen, wird aber nicht benoetigt."));
                const destinationGames = "/topic/public/fungames/games/" + this.props.userId;
                console.log("subscribe to " + destinationGames);
                stompClient.subscribe(destinationGames, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.gamesOverWebsocket(body.funGames);
                });
                const destinationHerausforderungen = "/topic/public/fungames/herausforderungen";
                console.log("subscribe to " + destinationHerausforderungen);
                stompClient.subscribe(destinationHerausforderungen, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.herausforderungenOverWebsocket(body.funGames);
                });
                const destinationNotifications = "/topic/public/user/notifications/" + this.props.userId;
                console.log("subscribe to " + destinationNotifications);
                stompClient.subscribe(destinationNotifications, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.notificationsOverWebsocket(body.notifications);
                });
                const destinationFriends = "/topic/public/user/friends/" + this.props.userId;
                console.log("subscribe to " + destinationFriends);
                stompClient.subscribe(destinationFriends, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.onlinefriendsOverWebsocket(body.freundschaftOnlineDtos);
                });
                const destinationLogout = "/topic/public/user/logout/" + this.props.userId;
                console.log("subscribe to " + destinationLogout);
                stompClient.subscribe(destinationLogout, () => {
                    this.props.logout();
                });
                const destinationStatistik = "/topic/public/user/statistik/" + this.props.userId;
                console.log("subscribe to " + destinationStatistik);
                stompClient.subscribe(destinationStatistik, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.statistikOverWebsocket(body);
                });
                const destinationAdminNotifications = "/topic/public/admin/notifications/" + this.props.userId;
                console.log("subscribe to " + destinationAdminNotifications);
                stompClient.subscribe(destinationAdminNotifications, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.newAdminNotificationsOverWebsocket(body);
                });
                const destinationLigaInfos = "/topic/public/liga/infos/" + this.props.userId;
                console.log("subscribe to " + destinationLigaInfos);
                stompClient.subscribe(destinationLigaInfos, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.newLigaInfosOverWebsocket(body);
                });
                const destinationTurnierInfos = "/topic/public/tournament/infos/" + this.props.userId;
                console.log("subscribe to " + destinationTurnierInfos);
                stompClient.subscribe(destinationTurnierInfos, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.newTurnierInfosOverWebsocket(body);
                });
                const destinationHeadToHeadChatUngelesen = "/topic/public/headtoheadchatungelesen/" + this.props.userId;
                console.log("subscribe to " + destinationHeadToHeadChatUngelesen);
                stompClient.subscribe(destinationHeadToHeadChatUngelesen, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.props.newChatInfosOverWebsocket(body);
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    render() {
        return <div>
            <div style={{padding:10}}>
                <div className="grid">
                    <SchnellAktionenContainer/>
                    <FreundeContainer/>
                    <MeineSpieleOverviewContainer/>
                    <GamesContainer/>
                    <StartseiteLinks cols={3} isOnlineDashboard={true}/>
                    <LigaShortOverviewContainer/>
                    <TurnierShortOverviewContainer/>
                    <AktionenContainer isOnlineDashboard={true}/>
                </div>
            </div>
        </div>
    }
}

LigaDashboard.propTypes = {
    userId: PropTypes.string.isRequired,
    userEventOperatorAdmin: PropTypes.bool.isRequired,
    gamesOverWebsocket: PropTypes.func.isRequired,
    herausforderungenOverWebsocket: PropTypes.func.isRequired,
    notificationsOverWebsocket: PropTypes.func.isRequired,
    onlinefriendsOverWebsocket: PropTypes.func.isRequired,
    statistikOverWebsocket: PropTypes.func.isRequired,
    newAdminNotificationsOverWebsocket: PropTypes.func.isRequired,
    newTurnierInfosOverWebsocket: PropTypes.func.isRequired,
    newLigaInfosOverWebsocket: PropTypes.func.isRequired,
    newLeagueOperators: PropTypes.func.isRequired,
    newChatInfosOverWebsocket: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};

export default LigaDashboard
