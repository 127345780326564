import React from 'react'
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import RanglisteEditView from "./RanglisteEditView";
import {USER_ROLES} from "../../../constants/userRoles";
import {Dropdown} from "primereact/dropdown";
import {TabelleRangliste, TabelleWeekendLeague} from "./ligaComponents";
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {Fieldset} from "primereact/fieldset";
import VerwalteRanglisteMarkerDialog from "./VerwalteRanglisteMarkerDialog";
import RanglistePunkteDialog from "./RanglistePunkteDialog";

const INITAL_STATE_RANGLISTE = {
    selectedRangliste: undefined,
    rangliste: undefined,
    turniere: undefined,
    turniereZumUser: undefined,
    tournamentRanglistePointsEntities: undefined,
};
const INITAL_STATE_WEEKENDLEAGE = {
    selectedWeekendLeague: undefined,
    weekendleaguerangliste: undefined,
    usernameDtos: undefined,
};
class RanglisteView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showRanglisteMarkerDialog: false, showRanglistePunkteDialog:false, showHinweis: true, selectedLigabetreiber: undefined, ...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE};

        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.changeLigabetreiber = this.changeLigabetreiber.bind(this);
        this.changeRangliste = this.changeRangliste.bind(this);
        this.ladeRangliste = this.ladeRangliste.bind(this);
        this.getWeekendLeageuSpielerName = this.getWeekendLeageuSpielerName.bind(this);
    }

    changeLigabetreiber(ligabetreiber) {
        if( ligabetreiber !== null && ligabetreiber !== undefined) {
            this.setState({selectedLigabetreiber: ligabetreiber, ...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE});
        } else {
            this.setState({selectedLigabetreiber: undefined, ...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE});
        }
        this.ladeRanglisten(ligabetreiber);
    }

    changeRangliste(rangliste) {
        if( rangliste !== null && rangliste !== undefined) {
            this.setState({...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE, selectedRangliste: rangliste});
            this.ladeRangliste(rangliste.id);
        } else {
            this.setState({selectedRangliste: undefined, ...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE});
        }
    }


    changeWeekendLeague(rangliste) {
        if( rangliste !== null && rangliste !== undefined) {
            this.setState({...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE, selectedWeekendLeague: rangliste});
            this.ladeWeekendLeague(rangliste.id);
        } else {
            this.setState({selectedWeekendLeague: undefined, ...INITAL_STATE_RANGLISTE, ...INITAL_STATE_WEEKENDLEAGE});
        }
    }

    ladeRangliste(ranglisteId) {
        if( ranglisteId === undefined || ranglisteId === null) {
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.rangliste + "/rangliste/leagueoperator/" + ranglisteId,
            json => {
                this.setState({rangliste: json.ranglisteByOperatorDtos,
                    turniere: json.tournamentDtos,
                    turniereZumUser: json.turniereZumUser,
                    tournamentRanglistePointsEntities: json.tournamentRanglistePointsEntities});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    ladeWeekendLeague(zeitraumId) {
        if( zeitraumId === undefined || zeitraumId === null) {
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.rangliste + "/weekendleague/" + zeitraumId,
            json => {
                this.setState({weekendleaguerangliste: json.tabelleDtos, usernameDtos: json.usernameDtos,
                anzahlGames: json.anzahlGames, anzahlLegs: json.anzahlLegs});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    ladeRanglisten(ligabetreiber) {
        if( ligabetreiber === undefined) {
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.rangliste + "/all/leagueoperator?leagueOperatorId=" + ligabetreiber.id,
            json => {
                this.setState({ranglisten: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            summary: 'Hinweis...',
            detail: message
        });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
        let isAdmin = false;
        if( this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text || userRole === USER_ROLES.SUPERADMIN.text);
        }

        let LIGABETREIBER_AUSWAHL = [];
        for (const leagueoperator of this.props.leagueoperators) {
            LIGABETREIBER_AUSWAHL.push(leagueoperator);
        }
        let RANGLISTEN_AUSWAHL = [];
        let WEEKENDLEAGUE_AUSWAHL = [];
        if( this.state.ranglisten !== null && this.state.ranglisten !== undefined) {
            for (const rangliste of this.state.ranglisten) {
                if( rangliste.zeitraumTyp === "rangliste_turniere") {
                    RANGLISTEN_AUSWAHL.push(rangliste);
                }
                if( rangliste.zeitraumTyp === "rangliste_weekendleague") {
                    WEEKENDLEAGUE_AUSWAHL.push(rangliste);
                }
            }
        }

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#B50339", color: "white"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Rangliste
                </div>
            </div>
            <div style={{padding:10, textAlign:"left", alignContent:"left"}}>
                <div className="grid">
                    <div className="col" style={{textAlign:"left"}}>
                        <Dropdown value={this.state.selectedLigabetreiber} options={LIGABETREIBER_AUSWAHL} optionLabel="name"
                                  onChange={(e) => this.changeLigabetreiber(e.value)}
                                  placeholder="Ligabetreiber auswählen..."
                                  style={{width:300}}/>
                        <br/>
                        <br/>
                        <span style={{fontWeight:"bold"}}>Bitte Rangliste oder Weekend League, zur Anzeige der entsprechenden Rangliste, wählen:</span>
                        <br/>
                        <Dropdown value={this.state.selectedRangliste} options={RANGLISTEN_AUSWAHL} optionLabel="bezeichnungFull"
                                  onChange={(e) => this.changeRangliste(e.value)}
                                  placeholder="Rangliste auswählen..."
                                  disabled={this.state.selectedLigabetreiber === null || this.state.selectedLigabetreiber === undefined}
                                  style={{width:300, marginTop:5}}/>
                        <br/>oder<br/>
                        <Dropdown value={this.state.selectedWeekendLeague} options={WEEKENDLEAGUE_AUSWAHL} optionLabel="bezeichnungFull"
                                  onChange={(e) => this.changeWeekendLeague(e.value)}
                                  placeholder="Weekend League auswählen..."
                                  disabled={this.state.selectedLigabetreiber === null || this.state.selectedLigabetreiber === undefined}
                                  style={{width:300, marginTop:5}}/>

                    </div>
                </div>
                {(this.state.rangliste  !== null && this.state.rangliste !== undefined) &&
                    <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                        <TabPanel header="Rangliste">
                            <Fieldset legend="Hinweis Punkte" toggleable collapsed={this.state.showHinweis} onToggle={(e) => this.setState({showHinweis: e.value})}>
                                {this.getHinweisArea()}
                            </Fieldset>
                            <TabelleRangliste players={this.state.rangliste}/>
                        </TabPanel>
                        <TabPanel header="Turniere">
                            {this.getTurnierArea(this.state.turniere)}
                        </TabPanel>
                        <TabPanel header="Deine Turniere">
                            {this.getTurnierArea(this.state.turniereZumUser)}
                        </TabPanel>
                    </TabView>
                }
                {(this.state.weekendleaguerangliste  !== null && this.state.weekendleaguerangliste !== undefined) &&
                    <TabelleWeekendLeague rangliste={this.state.weekendleaguerangliste} getSpielerName={this.getWeekendLeageuSpielerName} anzahlGames={this.state.anzahlGames} anzahlLegs={this.state.anzahlLegs}/>
                }
            </div>
            {isAdmin && <>
                <NavLink to={NAVIGATION_ITEM.RANGLISTEEDIT.route} style={{textDecoration: "none"}}>
                    <Button label="Verwalte Ranglisten" style={{height: "50px", marginRight:5}}/>
                </NavLink>
                <Button label="Verwalte Ranglistenmarker" style={{height: "50px", marginRight:5}} onClick={() => this.setState({showRanglisteMarkerDialog:true})}/>
                <Button label="Verwalte Ranglistenpunkte" style={{height: "50px", marginRight:5}} onClick={() => this.setState({showRanglistePunkteDialog:true})}/>
            </>
            }
            {this.state.showRanglisteMarkerDialog && <VerwalteRanglisteMarkerDialog onClose={() => this.setState({showRanglisteMarkerDialog:false})}/>}
            {this.state.showRanglistePunkteDialog && <RanglistePunkteDialog eventOperatorId={undefined} onClose={() => this.setState({showRanglistePunkteDialog:false})}/>}
        </div>
    }

    getWeekendLeageuSpielerName(userId) {
        for (const usernameObject of this.state.usernameDtos) {
            if (usernameObject.userId === userId) {
                return usernameObject.name + " - " + usernameObject.username;
            }
        }
        return "";
    }

    getTurnierArea(turniere) {
        if( turniere.length === 0) {
            return "Keine Turniere vorhanden";
        }

        let rows = [];
        for (const turnier of turniere) {
            let link = "";
            if( turnier.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
                link = NAVIGATION_ITEM.LIGADETAIL.route + "?ligaid=" + turnier.id
            } else if(turnier.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || turnier.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                link = NAVIGATION_ITEM.TURNIERDETAIL.route + "?turnierid=" + turnier.id
            } else if(turnier.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || turnier.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id) {
                link = NAVIGATION_ITEM.TURNIERGRUPPENDETAIL.route + "?turnierid=" + turnier.id
            }
            rows.push(<NavLink to={link} style={{textDecoration: "none"}} key={"key_navlink_turnier_" + turnier.id}>
                            <div key={"key_turnier_" + turnier.id} style={{marginTop:10, marginBottom:10}}>{turnier.bezeichnung} - Beginn: {turnier.beginnMitUhrzeitString}</div>
                        </NavLink>);
            rows.push(<hr key={"key_hr_" + turnier.id}/>);
        }

        return rows;
    }

    getHinweisDetailPlatzArea(beschriftung, wert) {
        if( wert !== null && wert > 0) {
            return beschriftung + " " + wert + " P. - ";
        }
        return "";
    }
    getHinweisDetailArea(typ, beschriftung) {
        const points = this.state.tournamentRanglistePointsEntities.find(entity => entity.typ === typ);
        if( points === null || points === undefined) {
            return "";
        }
        let wert = "";
        wert += this.getHinweisDetailPlatzArea("Platz 1:", points.platz1);
        wert += this.getHinweisDetailPlatzArea("Platz 2:", points.platz2);
        wert += this.getHinweisDetailPlatzArea("Platz 3:", points.platz3);
        wert += this.getHinweisDetailPlatzArea("Platz 4:", points.platz4);
        wert += this.getHinweisDetailPlatzArea("Platz 5:", points.platz5);
        wert += this.getHinweisDetailPlatzArea("Platz 6:", points.platz6);
        wert += this.getHinweisDetailPlatzArea("Platz 7:", points.platz7);
        wert += this.getHinweisDetailPlatzArea("Platz 8:", points.platz8);
        wert += this.getHinweisDetailPlatzArea("Platz 9:", points.platz9);
        wert += this.getHinweisDetailPlatzArea("Platz 10:", points.platz10);
        wert += this.getHinweisDetailPlatzArea("Platz 11:", points.platz11);
        wert += this.getHinweisDetailPlatzArea("Platz 12:", points.platz12);

        return <div style={{marginBottom:5}}><span style={{width:100}}>{beschriftung}</span> {wert}</div>
    }

    getHinweisArea() {
        if( this.state.tournamentRanglistePointsEntities === undefined ||
            this.state.tournamentRanglistePointsEntities === null ||
            this.state.tournamentRanglistePointsEntities.length === 0) {
            return <div>Keine Punkte zum Liga- oder Turnierbetreiber konfiguriert.</div>
        }
        return <div>
            {this.getHinweisDetailArea("ko4", "KO max. 4 Spieler:")}
            {this.getHinweisDetailArea("ko8", "KO max. 8 Spieler:")}
            {this.getHinweisDetailArea("ko16", "KO max. 16 Spieler:")}
            {this.getHinweisDetailArea("ko32", "KO max. 32 Spieler:")}
            {this.getHinweisDetailArea("ko64", "KO max. 64 Spieler:")}
            {this.getHinweisDetailArea("dko4", "DKO max. 4 Spieler:")}
            {this.getHinweisDetailArea("dko8", "DKO max. 8 Spieler:")}
            {this.getHinweisDetailArea("dko16", "DKO max. 16 Spieler:")}
            {this.getHinweisDetailArea("dko32", "DKO max. 32 Spieler:")}
            {this.getHinweisDetailArea("dko64", "DKO max. 64 Spieler:")}
            {this.getHinweisDetailArea("liga", "Liga:")}
            {this.getHinweisDetailArea("group", "Gruppenturnier:")}
            <div style={{marginTop:10}}>Hinweis für Gruppenturniere: Bei Gruppenturnieren addieren sich die Punkte aus dem Gruppenturnier und einem anschliessenden KO/DKO. Die evtl. konfigurierten Punkte für die Teilnahme werden in diesem Fall nur aus dem Gruppenturnier vergeben.</div>
        </div>
    }
}

RanglisteEditView.propTypes = {
    userId: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired,
    leagueoperators: PropTypes.array.isRequired,
};
export default RanglisteView;
