import React from "react";
import {Document, Image, Page, StyleSheet, View, Text} from "@react-pdf/renderer";
import logo from "../darthelfer_quer.png";

const styles = StyleSheet.create({
    page: {
        display: "block"
    },
    view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%"
    },
    logo: {
        padding: "30px",
        width: "100%",
    },
    link: {
        padding: "10px",
    },
    token: {
        padding: "10px",
    },
    boardbezeichnung: {
        paddingBottom:"5px",
    }
});

function BoardDocumentPage({ link, token, boards }) {
    return (
        <Page key={`page_1`} size="A5" style={styles.page}>
            <View style={styles.view}>
                <Image src={logo} style={styles.logo}/>
            </View>
            <View style={styles.view}>
                <Text style={styles.link}>URL: {link}</Text>
            </View>
            <View style={styles.view}>
                <Text style={styles.token}>Token: {token}</Text>
            </View>
            {boards !== null && boards !== undefined &&
                boards.map(board => <View style={styles.view}>
                    <Text style={styles.boardbezeichnung}>Board: {board.bezeichnung} / Token: {board.token}</Text>
                </View>
            )}
        </Page>
    );
}

function BoardDocument({ link, token, boards }) {
    return (
        <Document>
            <BoardDocumentPage link={link} token={token} boards={boards} />
        </Document>
    );
}

export default BoardDocument;
