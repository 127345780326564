import * as React from 'react';
import {useEffect, useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import DHDialog from "../../../general/DHDialog";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";

export default function EventOperatorRanglisteTable({rangliste, ranglisteInfos, tournaments, playersWelcheNichtBeruecksichtigtWurden}) {
    const CELL_PADDING_VALUE = 8;

    const DEFAULT_TOURNAMENT_SIZE = 4;

    let [selectedParticipant, setSelectedParticipant] = useState(undefined);

    let [tournamentSize, setTournamentSize] = useState(4);

    useEffect(() => {
        if( tournaments === null || tournaments === undefined || tournaments.length === 0 ) {
            setTournamentSize(0);
        } else if( tournaments.length <= DEFAULT_TOURNAMENT_SIZE) {
            setTournamentSize(tournaments.length);
        } else {
            setTournamentSize(DEFAULT_TOURNAMENT_SIZE);
        }
        console.log("tournaments", tournaments.length);
    }, [tournaments])

    const getTournamentsIntern = () => {
        let counter = 0;
        let t = [];
        if( tournaments === null || tournaments === undefined) {
            return t;
        }
        for (const tournament of tournaments) {
            if( counter >= tournamentSize) {
                break;
            }
            t.push(tournament);
            counter++;
        }
        return t;

    }

    const getTournamentHeaderColumns = (tournaments) => {
        if( tournaments === null || tournaments === undefined || tournaments.length === 0 )  {
            return "";
        }
        let cols = [];
        for (const tournament of getTournamentsIntern()) {
            cols.push(<TableCell
                key={tournament.tournamentId}
                style={{backgroundColor: "#ac1c27", color:"white", textAlign:"right", fontSize:14, width:50, writingMode:"vertical-rl", padding:CELL_PADDING_VALUE}}>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + tournament.tournamentId} style={{textDecoration: "none", color:"white"}}>
                    {tournament.bezeichnungShort}
                </NavLink>
            </TableCell>)
        }
        return cols;
    }

    const getAnzahlTeilnahmen = (idShort, tournaments) => {
        let teilnahmen = 0;
        for (const tournament of tournaments) {
            if( tournament.tournamentPlayerEntities.filter(player => player.idShort === idShort).length > 0 ) {
                   teilnahmen += 1;
            }
        }
        return teilnahmen;
    }

    const getTournamentValueColumns = (id, idShort, tournaments, playersWelcheNichtBeruecksichtigtWurden, style) => {
        if( getTournamentsIntern() === null || getTournamentsIntern() === undefined || getTournamentsIntern().length === 0 )  {
            return "";
        }
        let cols = [];
        for (const tournament of getTournamentsIntern()) {
            let filterPlayers = tournament.tournamentPlayerEntities.filter(player => player.idShort === idShort);
            let value;
            let player;
            if( filterPlayers.length > 0 ) {
                player = filterPlayers[0];
                value = " " + player.ranglistePunkteGesamt + " ";
            } else {
                value = "-";
            }
            let aktive = true;
            if( player !== undefined && playersWelcheNichtBeruecksichtigtWurden.length > 0 ) {
                aktive = !playersWelcheNichtBeruecksichtigtWurden.includes(player.id);
            }
            let spanStyle = "";
            if(!aktive) {
                spanStyle = {...style, border: "1px solid black", borderStyle:"dashed", padding:5};
            }
            cols.push(<TableCell key={"tournament_" + tournament.tournamentId + "_player_" + idShort}
                                 style={{...style, textAlign:"center"}}>
                <span style={{...spanStyle}}>{value}</span>
            </TableCell>);
        }
        return cols;
    }

    const getTableRows = (rangliste, ranglisteInfo, tournaments, playersWelcheNichtBeruecksichtigtWurden) => {
        let returnRows = [];
        let background = false;
        let lastValue = undefined;
        let platz = 1;

        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18, padding:CELL_PADDING_VALUE};


        for(let i = 0; i < rangliste.length; i++) {
            let participant = rangliste[i];
            let wert = participant.ranglistePunkte;
            if( lastValue === undefined) {
                lastValue = wert;
            } else if (lastValue !== wert ) {
                platz = i + 1;
                lastValue = wert;
            }
            let style;
            if( selectedParticipant !== undefined && selectedParticipant.id === participant.id) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }

            let cols = [];
            cols.push(<TableCell style={style}>{platz}.</TableCell>);
            cols.push(<TableCell style={style}>{participant.name}</TableCell>);
            cols.push(<TableCell style={{...style, textAlign:"center"}}>{participant.punkteDurchschnitt}</TableCell>);
            cols.push(<TableCell style={{...style, textAlign:"center"}}>{participant.ranglistePunkte !== null && participant.ranglistePunkte !== undefined ? participant.ranglistePunkte : "-"}</TableCell>);
            if(ranglisteInfos !== undefined && ranglisteInfos.einmalpunkte > 0 && ranglisteInfos.einmalpunkteFaktor > 0) {
                cols.push(<TableCell style={{...style, textAlign:"center", fontSize:12}}>
                    {ranglisteInfos.einmalpunkte} * {ranglisteInfos.einmalpunkteFaktor} = {ranglisteInfos.einmalpunkte*ranglisteInfos.einmalpunkteFaktor}
                </TableCell>);
            }
            cols.push(<TableCell style={{...style, textAlign:"center"}}>{getAnzahlTeilnahmen(participant.idShort, tournaments)}</TableCell>);
            let tmpCols = getTournamentValueColumns(participant.id, participant.idShort, tournaments, playersWelcheNichtBeruecksichtigtWurden, style)
            for (const tmpCol of tmpCols) {
                cols.push(tmpCol);
            }

            returnRows.push(<TableRow key={participant.id} onMouseOver={() => setSelectedParticipant(participant)}
                                      onClick={() => setSelectedParticipant(participant)}>
                {cols}
            </TableRow>)
            background = !background;
        }
        return returnRows;
    }

    const getAuswahlArea = () => {
        if( tournaments === null || tournaments === undefined) {
            return "";
        }
        let auswahl = [];
        for(let i = 1; i <= tournaments.length; i++) {
            auswahl.push({name: "" + i, code: i});
        }
        return <><span style={{marginRight:5}}>Anzeige {tournamentSize} von {tournaments.length} Events</span>
            <DHSelectionComponent value={undefined} onChange={(value) => setTournamentSize(value)} values={auswahl} title="Anzahl Turniere"/>
        </>
    }

    if( rangliste === null || rangliste === undefined || rangliste.length === 0) {
        return <div style={{color:"white", fontSize:16, padding:20}}>Keine Daten vorhanden.</div>
    }

    return <>
        <div style={{color:"white", width:"100%", paddingBottom:10, paddingRight:5, display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center", justifyContent:"right"}}>
            {getAuswahlArea()}
        </div>
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow style={{height:120}}>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80, padding:CELL_PADDING_VALUE}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80, textAlign:"center", padding:CELL_PADDING_VALUE}}>&#8709;</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:120, textAlign:"center", padding:CELL_PADDING_VALUE}}>Punkte</TableCell>
                        {ranglisteInfos !== undefined && ranglisteInfos.einmalpunkte > 0 && ranglisteInfos.einmalpunkteFaktor > 0 &&
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80, textAlign:"center", padding:CELL_PADDING_VALUE}}>Einmalp.</TableCell>
                        }
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80, textAlign:"center", padding:CELL_PADDING_VALUE}}>Anzahl</TableCell>
                        {getTournamentHeaderColumns(tournaments)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(rangliste, ranglisteInfos, tournaments, playersWelcheNichtBeruecksichtigtWurden)}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
