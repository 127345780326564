import {connect} from 'react-redux'
import App from "../../components/app/App";
import withRouter from "../../components/RouterComponentWrapper";
import {loginByToken} from "../../actions/user";

const mapStateToProps = function (state, ownProps) {
    return {
        isLoggedIn: state.user.isLoggedIn,
        userName: state.user.name,
        userId: state.user.id,
        location: ownProps.router.location,
    }
};

const mapDispatchToProps = dispatch => ({
    loginByToken: (showErrorOnFailure) => dispatch(loginByToken(showErrorOnFailure)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))