import PlusMinusComponent from "../../general/PlusMinusComponent";
import React, {useEffect, useState} from "react";
import ScoreWertComponent from "../../general/ScoreWertComponent";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {LinearProgress} from "@mui/material";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function LigaSpieltagEDartDetailStatistikGame({tournamentId, gameId, playerId, spielerName, gegnerName, gameNr, onMinimize}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [anzahlT20, setAnzahlT20] = useState(0);
    const [anzahlT19, setAnzahlT19] = useState(0);
    const [anzahlT18, setAnzahlT18] = useState(0);
    const [anzahlT17, setAnzahlT17] = useState(0);
    const [anzahlT16, setAnzahlT16] = useState(0);
    const [schwarzer, setSchwarzer] = useState(0);
    const [_171er, set_171er] = useState(0);
    const [_180er, set_180er] = useState(0);
    const [bull, setBull] = useState(0);
    const [highscores, setHighscores] = useState([]);
    const [highfinishes, setHighfinishes] = useState([]);

    const [anzahlPlayer1, setAnzahlPlayer1] = useState(0);
    const [anzahlPlayer2, setAnzahlPlayer2] = useState(0);

    const [complete, setComplete] = useState(false);

    const [changeTimeStamp, setChangeTimeStamp] = useState(undefined);
    const [working, setWorking] = useState(false);

    useEffect(() => {
        if( !gameId ) {
            return;
        }
        ladeGameDaten();
    },[gameId]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (changeTimeStamp === undefined) {
                return;
            }

            if (!(Date.now() - changeTimeStamp > 1000)) {
                return;
            }

            save();
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [changeTimeStamp]);

    const save = () => {
        setWorking(true);
        setChangeTimeStamp(undefined);
        let data = {triple20: anzahlT20, triple19: anzahlT19, triple18: anzahlT18, triple17: anzahlT17, triple16: anzahlT16,
            schwarzer: schwarzer, _171er: _171er, _180er: _180er, bull: bull, highfinishes: highfinishes, highscores: highscores,
        complete: complete, anzahlSpieler1: anzahlPlayer1, anzahlSpieler2: anzahlPlayer2};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/statistik/" + tournamentId + "/" +  gameId + "/" + playerId,
            data,
            () => {
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setChangeTimeStamp(undefined);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setChangeTimeStamp(undefined);
                setWorking(false);
            });
    }

    const ladeGameDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/statistik/game/"+ gameId,
            json => {
                if( json ) {
                    setAnzahlT20(json.triple20);
                    setAnzahlT19(json.triple19);
                    setAnzahlT18(json.triple18);
                    setAnzahlT17(json.triple17);
                    setAnzahlT16(json.triple16);
                    setSchwarzer(json.schwarzer);
                    set_171er(json._171er);
                    set_180er(json._180er);
                    setBull(json.bull);
                    setHighscores(json.highscores);
                    setHighfinishes(json.highfinishes);
                    setComplete(json.complete);
                    setAnzahlPlayer1(json.anzahlSpieler1 ? json.anzahlSpieler1 : 0);
                    setAnzahlPlayer2(json.anzahlSpieler2 ? json.anzahlSpieler2 : 0)
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }
    const removeArrayValue = (value, values, changeMethode) => {
        let newValues = values.filter(tmp => tmp !== value);
        changeMethode(newValues);
        setChangeTimeStamp(Date.now());
    }

    const addArrayValue = (value, values, changeMethode) => {
        let tmp = values;
        tmp.push(value);
        changeMethode(tmp);
        setChangeTimeStamp(Date.now());
    }

    const getValueComponentArea = (bezeichnung, value, onValueChange, left) => {
        return <div style={{marginBottom: 10, marginRight: left ? 30 : 0}}>
            <div style={{marginBottom: 2}}>{bezeichnung}</div>
            <PlusMinusComponent value={value} callBackMinus={() => {
                onValueChange(value - 1);
                setChangeTimeStamp(Date.now());
            }}
            callBackPlus={() => {
                onValueChange(value + 1);
                setChangeTimeStamp(Date.now());
            }}
            readonly={false}/>
        </div>
    }

    return <div style={{width: 300, borderRight: "1px solid gray", padding: 10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{
            marginBottom: 1, backgroundColor: "#022836", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis",
            height: 30, display: "flex", alignItems:"center",
        }}>
            <div style={{width:250, display:"flex"}}>
                {working && <LinearProgress color="inherit"/>}
                {!working && <div style={{display: "flex", alignItems:"center",}}>
                    <div style={{width:50}}>
                        <i className="pi pi-check" style={{color: "white"}}/>
                    </div>
                    <div style={{fontSize: 14}}>
                        Spiel: {gameNr}
                    </div>
                </div>}
            </div>
            <div>
                <i className="pi pi-window-minimize" onClick={() => onMinimize()} />
            </div>
        </div>
        <div style={{
            marginBottom: 1, backgroundColor: "#ac1c27", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis", display:"flex",
        }}>
            <div style={{width:30, minWidth:30, borderRight: "1px solid white", marginRight:5}}>{anzahlPlayer1}</div>{spielerName}
        </div>
        <div style={{
            marginBottom: 20, backgroundColor: "#ac1c27", color: "white", fontSize: 20, padding: 5,
            whiteSpace: "nowrap", overflow: "hidden",
            textOverflow: "ellipsis", display:"flex",
        }}>
            <div style={{width:30, minWidth:30, borderRight: "1px solid white", marginRight:5}}>{anzahlPlayer2}</div>{gegnerName}
        </div>
        <div style={{marginBottom:20}}>
            <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
                <div style={{marginRight: 30, marginBottom: 10}}>
                    <div style={{marginBottom: 5}}>Anzahl Spieler 1</div>
                    <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
                        <Button icon="pi pi-minus"
                                onClick={() => {
                                    setAnzahlPlayer1(anzahlPlayer1 - 1);
                                }}
                                style={{height: "40px", width: "40px", marginRight: "10px"}}
                                disabled={anzahlPlayer1 === 0}
                        />
                        <Button icon="pi pi-plus"
                                onClick={() => {
                                    setAnzahlPlayer1(anzahlPlayer1 + 1);
                                }}
                                style={{height: "40px", width: "40px", marginLeft: "10px", marginRight: "10px"}}/>
                    </div>
                </div>
                <div style={{marginBottom: 10}}>
                    <div style={{marginBottom: 5}}>Anzahl Spieler 2</div>
                    <div style={{display: "flex", alignContent: "center", alignItems: "center"}}>
                        <Button icon="pi pi-minus"
                                onClick={() => {
                                    setAnzahlPlayer2(anzahlPlayer2 - 1);
                                }}
                                style={{height: "40px", width: "40px", marginRight: "10px"}}
                                disabled={anzahlPlayer2 === 0}
                        />
                        <Button icon="pi pi-plus"
                                onClick={() => {
                                    setAnzahlPlayer2(anzahlPlayer2 + 1);
                                }}
                                style={{height: "40px", width: "40px", marginLeft: "10px", marginRight: "10px"}}/>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <Button label="Spiel abschließen" style={{width:"100%"}} onClick={() => {
                        setComplete(true);
                        setChangeTimeStamp(Date.now());
                    }} />
                </div>
                <div style={{fontSize:10, marginTop:5}}>
                    Das Ergebnis wird erst nach 'Spiel abschließen' gespeichert.
                </div>
            </div>
        </div>
        <hr/>
        <div style={{display: "flex"}}>
            {getValueComponentArea("Triple 20", anzahlT20, setAnzahlT20, true)}
            {getValueComponentArea("Triple 19", anzahlT19, setAnzahlT19, false)}
        </div>
        <div style={{display: "flex"}}>
            {getValueComponentArea("Triple 18", anzahlT18, setAnzahlT18, true)}
            {getValueComponentArea("Triple 17", anzahlT17, setAnzahlT17, false)}
        </div>
        <div style={{display: "flex"}}>
            {getValueComponentArea("Triple 16", anzahlT16, setAnzahlT16, true)}
            {getValueComponentArea("Schwarzer", schwarzer, setSchwarzer, false)}
        </div>
        <div style={{display: "flex"}}>
            {getValueComponentArea("171", _171er, set_171er, true)}
            {getValueComponentArea("180", _180er, set_180er, false)}
        </div>
        {getValueComponentArea("Single Bull / Bull", bull, setBull, true)}
        <div style={{paddingBottom: 50}}>
            <ScoreWertComponent beschreibung="Highscores:" onOk={(value) => addArrayValue(value, highscores, setHighscores)}
                                onRemove={(value) => removeArrayValue(value, highscores, setHighscores)}
                                readonly={false}
                                values={highscores}/>
        </div>
        <div style={{marginBottom: 20}}>
            <ScoreWertComponent beschreibung="Highfinishes:"
                                readonly={false}
                                values={highfinishes} onOk={(value) => addArrayValue(value, highfinishes, setHighfinishes)}
                                onRemove={(value) => removeArrayValue(value, highfinishes, setHighfinishes)}/>
        </div>
    </div>
}