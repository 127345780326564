import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Button} from "primereact/button";

function TurnierAdminTabPanel({onError, admins, changeAdmins}) {

    let [adminsZurAuswahl, setAdminsZurAuswahl] = useState([]);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/leagueoperator/admins",
            json => {
                setAdminsZurAuswahl(json);
            },
            responseNotOk => {
                onError("Fehler beim Laden der Admins: " + responseNotOk.message);
            },
            error => {
                onError("Fehler beim Laden der Admins: " + error.message);
            }
        );
    }, []);

    function getRows() {
        let rows = [];
        for (const admin of adminsZurAuswahl) {
            let image;
            let userIstAdmin = admins.includes(admin.userId);
            if (userIstAdmin) {
                image = <img src="/images/check2.png"
                             style={{width: 30, marginRight: 10, marginTop: 7}}
                             alt="Turnieradmin"/>;
            } else {
                image = ""
            }
            rows.push(<div className="grid" key={"row_admin" + admin.userId} style={{marginBottom: 20}}>
                <div className="col-fixed" style={{width: 100}}>
                    {image}
                    {!userIstAdmin && <Button icon="pi pi-plus-circle"
                                              onClick={() => {
                                                  let newAdmins = [...admins];
                                                  newAdmins.push(admin.userId);
                                                  changeAdmins(newAdmins);
                                              }}/>}
                    {userIstAdmin && <Button icon="pi pi-trash"
                                             onClick={() => {
                                                 let newAdmins = [];
                                                 for (const tmpAdmin of admins) {
                                                     if( tmpAdmin !== admin.userId) {
                                                         newAdmins.push(tmpAdmin);
                                                     }
                                                 }
                                                 changeAdmins(newAdmins);
                                             }}/>}
                </div>
                <div className="col">
                    {admin.name}
                </div>
            </div>);
        }
        return rows;
    }

    return <div>
        <div style={{marginTop: 20, marginBottom: 10}}>Auswahl der Personen die als Admins der Liga / des Turniers sind:</div>
        <div style={{marginTop: 20, marginBottom: 10}}>!!! Der aktuelle Benutzer wird immer als Admin hinzugefügt. Sofern er nicht als Admin zur Vorbelegung vorhanden ist, wird dies nur hier nicht angezeigt.</div>
        <div style={{marginTop: 20, marginBottom: 10}}>Sofern du den aktuellen Benutzer nicht im Turnier haben möchtest, musst du diesen im Turnierdialog entfernen.</div>
        {getRows()}
    </div>

}

TurnierAdminTabPanel.propTypes = {
    admins: PropTypes.array.isRequired,
    changeAdmins: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default TurnierAdminTabPanel;
