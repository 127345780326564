import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import GameStatistik from "../../../components/views/liga/GameStatistik";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const userId = urlParams.get('userId');
    const doppelpartnerId = urlParams.get('doppelpartnerId');
    return {userId: userId, doppelpartnerId: doppelpartnerId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GameStatistik))