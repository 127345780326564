import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import {GameBestOfLegsComponent, GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {GameModusComponent, getModusNameById} from "../../general/GameModusComponent";
import {Panel} from "primereact/panel";

export default function GamingAreaModusArea(props) {

    let [modusListe, setModusListe] = useState([]);
    let [id, setId] = useState(undefined);
    let [modusid, setModusid] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);
    let [bestOfLegs, setBestOfLegs] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);

    useEffect(() => {
        ladeDaten();
    }, []);

    const clearDaten = () => {
        setId(undefined);
        setStartpunkte(undefined);
        setModusid(undefined);
        setFirstToLegs(undefined);
        setBestOfLegs(undefined);
    }

    const selectDaten = (modus) => {
        setId(modus.id);
        setStartpunkte(modus.startpunkte);
        setModusid(modus.modusid);
        setFirstToLegs(modus.firstToLegs !== null ? modus.firstToLegs : undefined);
        setBestOfLegs(modus.bestOfLegs !== null ? modus.bestOfLegs : undefined);
    }

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.gamingarea + "/modus";
        FetchUtil.fetchGet(url,
            json => {
                setModusListe(json);
            },
            responseNotOk => {
                props.showError("Fehler beim Laden der Modus-Daten: " + responseNotOk.message);
            },
            error => {
                props.showError("Fehler beim Laden der Modus-Daten: " + error.message);
            }
        );
    }

    const save = () => {
        let url = ConfigUtil.getConfig().resourceUrls.gamingarea + "/savemodus";
        let data = {
            id: id,
            modusid: modusid,
            startpunkte: startpunkte,
            firstToLegs: firstToLegs,
            bestOfLegs: bestOfLegs
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                ladeDaten();
                clearDaten();
            },
            responseNotOk => {
                props.showError("Fehler beim Speichern der Modus-Daten: " + responseNotOk.message);
            },
            error => {
                props.showError("Fehler beim Speichern der Modus-Daten: " + error.message);
            }
        );
    }

    const changeAktiv = (id) => {
        let url = ConfigUtil.getConfig().resourceUrls.gamingarea + "/changemodusaktiv/" + id;
        FetchUtil.fetchPost(url,
            {},
            () => {
                ladeDaten();
                clearDaten();
            },
            responseNotOk => {
                props.showError("Fehler beim Speichern der Change-Modus-Daten: " + responseNotOk.message);
            },
            error => {
                props.showError("Fehler beim Speichern der Change-Modus-Daten: " + error.message);
            }
        );
    }

    const loescheModus = (id) => {
        let url = ConfigUtil.getConfig().resourceUrls.gamingarea + "/loeschemodus/" + id;
        FetchUtil.fetchDelete(url,
            {},
            () => {
                ladeDaten();
                clearDaten();
            },
            responseNotOk => {
                props.showError("Fehler beim Speichern der Loesche-Modus-Daten: " + responseNotOk.message);
            },
            error => {
                props.showError("Fehler beim Speichern der Loesche-Modus-Daten: " + error.message);
            }
        );
    }

    const getRows = () => {
        let rows = [];
        if (modusListe === null || modusListe === undefined || modusListe.length === 0) {
            rows.push(<div key="key_keine_spielmodus_vorhanden" style={{textAlign: "left", marginTop: 20}}>Keine
                gespeicherten Spielmodus vorhanden. Solange keine Spielmodus gepflegt werden, kann kein Gegner ein Spiel
                von dir annehmen.</div>);
        }
        for (const modus of modusListe) {
            rows.push(<div className="grid" style={{marginTop: 15}}>
                <div style={{float: "left", textAlign: "left", marginLeft: 10, marginRight: 5}}><Button
                    icon="pi pi-pencil"
                    onClick={() => selectDaten(modus)}
                    tooltip={"Ändern Modus"}/>
                </div>
                <div style={{float: "left", textAlign: "left", marginRight: 10}}><Button icon="pi pi-trash"
                                                                                         onClick={() => loescheModus(modus.id)}
                                                                                         tooltip={"Lösche Modus"}/>
                </div>
                {modus.aktiv &&
                <div style={{float: "left", textAlign: "left", marginRight: 5}}><img src="/images/check.png" alt="Aktiv"
                                                                                     style={{
                                                                                         height: 20,
                                                                                         marginLeft: 10,
                                                                                         marginRight: 10,
                                                                                         cursor: "pointer"
                                                                                     }}
                                                                                     onClick={() => changeAktiv(modus.id)}/>
                </div>}
                {!modus.aktiv &&
                <div style={{float: "left", textAlign: "left", marginRight: 5}}><img src="/images/cancel.png"
                                                                                     alt="inaktiv" style={{
                    height: 20,
                    marginLeft: 10,
                    marginRight: 10,
                    cursor: "pointer"
                }} onClick={() => changeAktiv(modus.id)}/></div>}
                <div style={{float: "left", textAlign: "left", marginRight: 20}}>Startpunkte: {modus.startpunkte}</div>
                <div style={{
                    float: "left",
                    textAlign: "left",
                    marginRight: 20
                }}>Modus: {getModusNameById(modus.modusid)}</div>
                {modus.firstToLegs !== null && modus.firstToLegs !== undefined &&
                <div style={{float: "left", textAlign: "left", marginRight: 20}}>First to
                    legs: {modus.firstToLegs}</div>
                }
                {modus.bestOfLegs !== null && modus.bestOfLegs !== undefined &&
                <div style={{float: "left", textAlign: "left", marginRight: 20}}>Best of legs: {modus.bestOfLegs}</div>
                }
                <br/>
            </div>);
        }
        return rows;
    }


    return <div className="grid" style={{padding: 0, margin: 0}}>
        <div className="col-12 md:col-12 lg:col-12" style={{padding: 20}}>
            <Panel header="Modus-Erklärung">
                <div style={{textAlign: "left", fontSize: 14, marginTop: 10, marginBottom: 10}}>Du musst mindestens
                    einen erfassten, aktiven Modus haben, damit ein Gegner ein Spiel gegen dich wählen kann. Alternativ
                    kannst du von einem anderen Spieler ein Spiel wählen.
                </div>
                <div style={{textAlign: "left", fontSize: 12, marginTop: 10}}>Deaktiviere oder Aktiviere welchen Modus
                    du aktuell bereit bist zu spielen.
                </div>
                <div style={{textAlign: "left", fontSize: 12, marginTop: 10}}>Der grüne <img src="/images/check.png"
                                                                                             alt="Aktiv" style={{
                    height: 20,
                    marginLeft: 10,
                    marginRight: 10
                }}/> (aktiv) oder der rote <img src="/images/cancel.png" alt="inaktiv"
                                                style={{height: 20, marginLeft: 10, marginRight: 10}}/> (nicht aktiv)
                    zeigt dir, welcher Modus
                    gerade aktiv ist.
                </div>
                <div
                    style={{textAlign: "left", fontSize: 12, marginTop: 10}}>Klicke auf <img
                    src="/images/check.png" alt="Aktiv"
                    style={{height: 20, marginLeft: 10, marginRight: 10}}/> oder <img
                    src="/images/cancel.png" alt="inaktiv" style={{height: 20, marginLeft: 10, marginRight: 10}}/> um
                    den
                    Modus aktuell aktiv oder inaktiv zu schalten.
                </div>
                <div
                    style={{textAlign: "left", marginTop: 20, marginBottom: 20, fontWeight: "bold"}}>Folgende
                    Spielmodus sind vorhanden:
                </div>
            </Panel>
            <div style={{padding: 20}}>
                <div className="grid" style={{textAlign: "left", padding: 0}}>
                    <div className="col">
                        <div style={{float: "left", marginRight: 20}}>
                            <span style={{marginRight: 10}}>Startpunkte:</span>
                            <GameStartpunkteComponent startpunkte={startpunkte}
                                                      onChange={(startpunkte) => setStartpunkte(startpunkte)}/>
                        </div>
                        <div style={{float: "left", marginRight: 20}}>
                            <span style={{marginRight: 10}}>Modus:</span>
                            <GameModusComponent modusId={modusid}
                                                onChange={(modusid) => setModusid(modusid)}/>
                        </div>
                    </div>
                </div>
                <div className="grid" style={{textAlign: "left", padding: 0}}>
                    <div className="col">
                        <div style={{float: "left", marginRight: 20}}>
                            <span style={{marginRight: 10}}>First to Legs:</span>
                            <GameFirstToLegsComponent firstToLegs={firstToLegs}
                                                      onChange={(firstToLegs) => {
                                                          setFirstToLegs(firstToLegs);
                                                          {firstToLegs !== undefined && setBestOfLegs(undefined)};
                                                      }}/>
                        </div>
                        <div style={{float: "left", marginRight: 20}}>
                            <span style={{marginRight: 10}}>Best of Legs:</span>
                            <GameBestOfLegsComponent bestOfLegs={bestOfLegs}
                                                      onChange={(bestOfLegs) => {
                                                          setBestOfLegs(bestOfLegs);
                                                          {bestOfLegs !== undefined && setFirstToLegs(undefined)};
                                                      }}/>
                        </div>
                    </div>
                </div>
                <div className="grid" style={{textAlign: "left", padding: 0, marginTop: 10}}>
                    <Button label="Speichern" disabled={startpunkte === undefined || modusid === undefined || (firstToLegs === undefined && bestOfLegs === undefined)} onClick={() => save()}/>
                </div>
                {getRows()}
            </div>
        </div>
    </div>
}

GamingAreaModusArea.propTypes = {
    userId: PropTypes.string.isRequired,
    showError: PropTypes.func.isRequired,
};