import React, {useEffect, useState} from 'react'
import {RadioButton} from "primereact/radiobutton";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TextField} from "@mui/material/";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import PaypalComponent from "../../paypal/PaypalComponent";
import {ProgressSpinner} from "primereact/progressspinner";
import DHDialog from "../../general/DHDialog";

export default function VeranstalterBezahlungDialog({eventOperatorId, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [zeitraum, setZeitraum] = useState("kostenlos");
    const [name, setName] = useState("");
    const [strasse, setStrasse] = useState("");
    const [pLZ, setPLZ] = useState("");
    const [ort, setOrt] = useState("");
    const [gutscheinCode, setGutscheinCode] = useState("");
    const [bezahlInfos, setBezahlInfos] = useState(undefined);
    const [priceDefault, setPriceDefault] = useState(undefined);
    const [price, setPrice] = useState(undefined);
    const [refreshOnClose, setRefreshOnClose] = useState(false);


    useEffect(() => {
        ladePrice();
    }, []);

    useEffect(() => {
        ladeDaten();
    }, [eventOperatorId]);

    const ladeDaten = () => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/bezahlinfos/" + eventOperatorId;
        FetchUtil.fetchGet(url,
            json => {
                setBezahlInfos(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Daten: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Laden der Daten: " + error.message)
            });
    }

    const ladePrice = () => {
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/proaccount/price";
        FetchUtil.fetchGet(url,
            json => {
                setPrice(json);
                setPriceDefault(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Preis-Daten: " + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler beim Laden der Preis-Daten: " + error.message)
            });
    }


    const testphaseEinloesen = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/testphase/" + eventOperatorId + "/" + gutscheinCode;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Testphase wurde gespeichert.");
                ladeDaten();
                setPrice(priceDefault);
                setGutscheinCode("");
                setRefreshOnClose(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Einlösen der Testphase: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Einlösen der Testphase: " + error.message)
            });
    }

    const gutscheinEinloesen = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/gutschein/" + gutscheinCode;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setPrice(json);
                setInfoMessage("Der Gutscheincode wurde akzeptiert. Preis wurde angepasst.")
                setRefreshOnClose(true);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Einlösen eines Gutscheins: " + responseNotOk.message);
                setPrice(priceDefault);
            },
            error => {
                setErrorMessage("Fehler beim Einlösen eines Gutscheins: " + error.message)
                setPrice(priceDefault);
            });
    }

    const getTestphaseArea = () => {
        return <Card style={{textAlign: "left", marginTop: 20, marginBottom: 20, width: "100%"}}>
            <div>
                {!price.rechnungPerHand && <>
                        <div style={{marginBottom:10}}>Du hast einen Gutscheincode eingelöst, der dich für eine Testphase in Darthelfer berechtigt.</div>
                        <div style={{marginBottom:10}}>Hinweis: Pro Veranstalter darf nur eine Testphase genutzt werden!</div>
                    </>
                }
                <div style={{marginBottom:10}}><Button label={price.rechnungPerHand ? "Gutschein einlösen" : "Testphase jetzt beginnen"} onClick={() => testphaseEinloesen()}/></div>
            </div>
        </Card>
    }

    const getPayArea = () => {
        if( bezahlInfos === null || bezahlInfos === undefined) {
            return "";
        }
        if( !bezahlInfos.kannWiederProVeranstalterBuchen) {
            return <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                <div>
                    Die aktuelle Veranstalter Pro-Zeit läuft noch. Du kannst erst 30 Tage vor Ablauf der Frist (ab dem {bezahlInfos.abWiederProVeranstalterBuchen}) wieder buchen.
                </div>
            </Card>
        }

        return <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="col" style={{fontWeight: "bold"}}>
                        Weitere Veranstalter-Pro-Zeit buchen
                    </div>
                </div>
                {!bezahlInfos.kannWiederProVeranstalterBuchen && <>
                    <div className="grid" style={{textAlign: "left"}}>
                        <div className="col">
                            Aktuell kannst du keine Bezahlung vornehmen. Vier Wochen vor Ablauf deiner Pro-Zeit ist die
                            Buchung wieder möglich.
                        </div>
                    </div>
                </>}
                {getRechnungsadresse()}
                {getPayAreaProTimeSelection()}
                {getPaypalButton()}
        </Card>
    }

    const getRechnungsadresse = () => {
        return <div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">
                    <TextField id="standard-name" label="Name Verein oder Veranstaltername oder dein persönlicher Name" variant="standard"
                               value={name}
                               onChange={(e) => setName(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">
                    <TextField id="standard-name" label="Straße" variant="standard" value={strasse}
                               onChange={(e) => setStrasse(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10, marginBottom:20}}>
                <div className="col-fixed" style={{width: 200}}>
                    <TextField id="standard-name" label="PLZ" variant="standard" value={pLZ}
                               onChange={(e) => setPLZ(e.target.value)} style={{width: "100%"}}/>
                </div>
                <div className="col">
                    <TextField id="standard-name" label="Ort" variant="standard" value={ort}
                               onChange={(e) => setOrt(e.target.value)} style={{width: "100%"}}/>
                </div>
            </div>
        </div>
    }

    const getPayAreaProTimeSelection = () => {
        if( !bezahlInfos.kannWiederProVeranstalterBuchen) {
            return <div>Kann nicht buchen...</div>
        }
        return <div>
            <div className="grid" style={{textAlign: "left"}}>
                <div className="col">
                    <RadioButton inputId="zeitraum1" name="zeitraum" value="kostenlos"
                                 onChange={(e) => setZeitraum(e.value)} checked={zeitraum === 'kostenlos'}/>
                    <label htmlFor="chb-systementscheidetwerbeginnt" className="p-checkbox-label"
                           style={{marginLeft: 10}}>Kostenlos</label>
                </div>
            </div>
            <div className="grid" style={{textAlign: "left", marginTop: 10, marginBottom:20}}>
                <div className="col">
                    <RadioButton inputId="zeitraum2" name="zeitraum" value="proaccount"
                                 onChange={(e) => setZeitraum(e.value)} checked={zeitraum === 'proaccount'}/>
                    <label htmlFor="chb-ausbullen" className="p-checkbox-label" style={{marginLeft: 10}}>1 Jahr
                        Veranstalter Pro Account / {price.brutto.replaceAll(".", ",")},- Euro</label>
                </div>
            </div>
        </div>;
    }

    const getPaypalButton = () => {
        if (zeitraum === "kostenlos") {
            return "";
        }
        if( name === "" || strasse === "" || pLZ === "" || ort === "") {
            return <div style={{color:"red"}}>Name, Strasse, PLZ und Ort muss zur Rechnungserstellung gefüllt sein. Erst dann ist eine Bezahlung möglich.</div>
        }
        return <PaypalComponent option={ "event-operator" } price={ price.brutto }
                                                data={{
                                                    eventOperatorId: eventOperatorId,
                                                    name: name,
                                                    strasse: strasse,
                                                    plz: pLZ,
                                                    ort: ort,
                                                    price: price,
                                                }}
                                                onSuccess={
                                                    () => {
                                                        setInfoMessage("Zeitraum wurde gebucht!");
                                                        ladeDaten();
                                                        setRefreshOnClose(true);
                                                    }
                                                }
                                                onResponseNotOK={
                                                    (message) => setErrorMessage("Fehler: " + message)
                                                }
                                                onError={
                                                    (message) => setErrorMessage("Fehler: " + message)
                                                }
        ></PaypalComponent>
    }

    const getProAccountZeitraeume = () => {
        if( bezahlInfos === null || bezahlInfos === undefined) {
            return <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                <ProgressSpinner style={{width:100, height:100}}/>
            </Card>
        }
        if(bezahlInfos.zeitraeume === null || bezahlInfos.zeitraeume === undefined || bezahlInfos.zeitraeume.length === 0) {
            return  <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                Keine Informationen über Veranstalter Pro-Account Zeiträume vorhanden.
            </Card>
        }

        let rows =[];
        const proEnde = bezahlInfos.zeitraeume[0];
        for (const zeitraum of bezahlInfos.zeitraeume) {
            rows.push(<div key={zeitraum.id}>
                <div>Zeitraum: {zeitraum.zeitraumvonString} - {zeitraum.zeitraumbisString}<br/>
                    Kaufzeitpunkt: {zeitraum.timestampString},
                    {zeitraum.hatRechnung && <>
                        Rechnung: <a href={ConfigUtil.getConfig().resourceUrls.receipt + "/eventoperator/" + zeitraum.id}>herunterladen</a>
                    </>}
                    <br/>
                    Notiz: {zeitraum.notiz}</div>
                <hr/></div>);
        }

        return <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
                    <div style={{fontWeight:"bold", color:"green", marginBottom:20}}>
                        Dein aktueller Veranstalter Pro-Zeitraum endet am <span>{proEnde.zeitraumbisString}</span>
                    </div>
                    <h4>Informationen über deine Veranstalter Pro-Account Zeiträume.</h4>
                    {rows}
        </Card>
    }

    if( price === undefined || price === null) {
        return <div style={{fontSize: 18}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
                <div>
                    Wir laden Daten...
                </div>
        </div>
    }

    const closeDialog = () => {
        if(refreshOnClose) {
            onRefresh();
        }
        onClose();
    }

    if( eventOperatorId === null || eventOperatorId === undefined) {
        return <div style={{fontSize: 18}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <div>
                Um eine Bezahlung vornehmen zu können, muss der Veranstalter inital gespeichert werden.<br/>Der aktuelle Jahrespreis für Darthelfer ist {price.brutto.replaceAll(".", ",")},- Euro.
            </div>
        </div>
    }

    return <DHDialog onClose={() => closeDialog()} title="Bezahlung" show={true} buttons={[]} showCloseButton={true}
                     comp={<div style={{fontSize: 18}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>
            <div style={{fontWeight:"bold", marginBottom:5}}>Vergleich Versionen - kostenlos vs. Bezahlversion</div>
            <div style={{marginBottom:20}}><a href="https://support.darthelfer.de/viewtopic.php?t=101" target="_blank" rel="noopener noreferrer">https://support.darthelfer.de/viewtopic.php?t=101</a></div>
        </div>
        <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
            <div style={{marginBottom:20, fontWeight:"bold"}}>Du bist hier im Bereich der Bezahlung für Veranstalter. Bezahle hier nur, sofern du Events über Darthelfer veranstalten möchtest.</div>
            Wir bieten hier die Bezahlung über PayPal an.
            <br/>
            Sofern du lieber per Rechnung bezahlen möchtest, sende uns eine Email an: <span style={{fontWeight: "bold"}}>support(a)darthelfer.de</span>
            <br/>
            Folgende Info bitte der Mail beifügen: <span style={{fontWeight: "bold"}}>Name, Vorname (evtl. Vereinsname), Straße inkl. Hausnummer, PLZ und Ort.</span>
            <br/>
            WICHTIG: <span style={{fontWeight: "bold"}}>Bitte den Namen des Veranstalters in die Mail einfügen, diesem wird der Pro-Account dann zugefügt.</span>
            <br/>
            Solltest du einen Gutscheincode haben, sende diesen in der Mail bitte mit.
        </Card>
        <Card style={{textAlign:"left", marginTop:20, marginBottom:20, width:"100%"}}>
            <div>
                <TextField id="standard-gutschein" label="Gutscheincode" variant="standard" value={gutscheinCode}
                           onChange={(e) => setGutscheinCode(e.target.value)} style={{width: 200}}/>
                <Button label="Gutscheincode einlösen" onClick={() => gutscheinEinloesen()} disabled={gutscheinCode === "" || gutscheinCode.length < 10}/>
            </div>
        </Card>
        {price.testPhase && getTestphaseArea()}
        {getProAccountZeitraeume()}
        {!price.testPhase && getPayArea()}
    </div>}/>
}
