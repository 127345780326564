import React, {useState, useEffect} from 'react'
import {ProgressSpinner} from "primereact/progressspinner";
import {useLocation} from "react-router-dom";

export default function ScorerRedirect() {

    const urlParams = new URLSearchParams(useLocation().search);
    const type = urlParams.get('type');

    const [timerCounter, setTimerCounter] = useState(10);

    useEffect(() => {
        setTimeout(() => {
            if( timerCounter < 0) {
                return;
            }
            setTimerCounter(timerCounter - 1);
        }, 1000);
    }, [timerCounter]);

    if( timerCounter <= 0 ) {
        let url;
        if( type === "test") {
            url = "https://client.darthelfer.de";
        } else {
            url = "https://scorer.darthelfer.de";
        }
        window.open(url, "_self");
    }
    return <div style={{backgroundColor: "#022836", height:"100%", minHeight:"100vh", color: "#ac1c27", textAlign: "center", fontSize:"3.0vw", padding:20}}>
        <div>
            <img alt="Logo" src={"images/darthelfer_quer_weiss.png"} style={{height: 100}}/>
        </div>
        <div>Aktualisierung.</div>
        <div>Wartezeit ca. {timerCounter} Sekunden!</div>
        <ProgressSpinner style={{width: 100, height: 100}} />
    </div>
}
