import React from 'react'
import {Card} from "primereact/card";

export default function VeranstalterTipps() {

    return <div style={{padding:20}}>
        <Card style={{fontSize:18}}>
            <div style={{fontWeight:"bold", marginBottom:5}}>Youtube-Kanal (Hilfevideos)</div>
            <div style={{marginBottom:20}}><a href="https://www.youtube.com/@darthelfer" target="_blank" rel="noopener noreferrer">https://www.youtube.com/@darthelfer</a></div>
            <div style={{fontWeight:"bold", marginBottom:5}}>Hilfe zum Anlegen von Veranstaltern / Boards</div>
            <div style={{marginBottom:20}}><a href="https://support.darthelfer.de/viewtopic.php?t=100" target="_blank" rel="noopener noreferrer">https://support.darthelfer.de/viewtopic.php?t=100</a></div>
            <div style={{fontWeight:"bold", marginBottom:5}}>Vergleich Versionen - kostenlos vs. Bezahlversion</div>
            <div style={{marginBottom:20}}><a href="https://support.darthelfer.de/viewtopic.php?t=101" target="_blank" rel="noopener noreferrer">https://support.darthelfer.de/viewtopic.php?t=101</a></div>
            <div style={{fontWeight:"bold", marginBottom:5}}>Allgemeine Hilfen im Supportforum</div>
            <div style={{marginBottom:20}}><a href="https://support.darthelfer.de" target="_blank" rel="noopener noreferrer">https://support.darthelfer.de</a></div>
            <div style={{fontWeight:"bold", marginBottom:5}}>Neuigkeiten/Infos in sozialen Netzwerken</div>
            <div style={{marginBottom:20}}><a href="https://www.facebook.com/darthelfer" target="_blank" rel="noopener noreferrer">https://www.facebook.com/darthelfer</a></div>
        </Card>

    </div>
}
