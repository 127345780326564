import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import PublicScoringView from "../../../components/views/public/training/PublicScoringView";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const monitorKey = urlParams.get('monitorkey');
    const mandant = urlParams.get('mandant');
    return {monitorkey: monitorKey, mandant: mandant};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicScoringView))
