import React, {useState} from 'react'
import {Button} from "primereact/button";
import ConfigUtil from "../../../util/ConfigUtil";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import QRCodeSidebar from "../../general/QRCodeSidebar";

export default function ScoringVideoSettingsComponent({gameid, displayName, videoHeight, videoWidth, showVideo, videoAnzeigeart,
                                                          showVideoMenu, soundIch, soundGegner, changeShowVideoMenu,
                                                          changeShowVideo, changeAnzeigeart, changeSoundIch, changeSoundGegner,
                                                            changeVideoHeight, changeVideoWidth, isMobileDevice,showChat, onShowChat, onShowSettings, showSoundIch, showSoundGegner, supportVideoOption3, supportVideoChangeByOption2}) {

    let soundIchImage =  soundIch ? "/images/megaphone_color.png" : "/images/megaphone.png";
    let soundGegnerImage =  soundGegner ? "/images/ear_color.png" : "/images/ear.png";

    let [showQRCode, setShowQRCode] = useState(false);

    const videoURL = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=" + gameid + "&username=" + displayName;

    const showVideoImNeuenFenster = () => {
        changeShowVideo(false);
        window.open(videoURL, '_blank', 'location=yes,height=400,width=400,scrollbars=true,status=true')
    }

    let anzeigeimage;
    let widthDisabled = true;
    if( videoAnzeigeart === 1)  {
        anzeigeimage = "/images/one.png";
        widthDisabled = false;
    }
    else if( videoAnzeigeart === 2)  {
        anzeigeimage = "/images/two.png";
        widthDisabled = !supportVideoChangeByOption2;
    }
    else if( videoAnzeigeart === 3)  {
        anzeigeimage = "/images/three.png";
    }
    const disableButtons = videoAnzeigeart === 3;

    if( isMobileDevice) {
        let background = showVideoMenu ? {backgroundColor: "#e6f2ff"} : {};
        return <div className="grid" style={{position:"fixed", top:0, right:0, width:"100%", zIndex: 600, margin:0, padding:0, textAlign:"right", ...background}}>
            <div className="col" style={{padding: 0, margin: 0, textAlign: "right", float: "left", marginTop: 5, marginRight: 5}}>
                {showVideoMenu && <>
                    <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                        <Button className="p-button-danger"
                                icon="pi pi-power-off" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 10}}
                                tooltip="Ausgang"/>
                    </NavLink>
                    <Button className={showChat ? "" : "p-button-secondary"} icon="pi pi-book"
                            style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 10}} onClick={() => {
                        onShowChat(!showChat);
                    }} tooltip=""/>
                    <Button icon="pi pi-plus" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 1}}
                            onClick={() => changeVideoWidth(videoWidth + 5)} tooltip="Bild vergrößern"/>
                    <Button icon="pi pi-minus" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 5}}
                            onClick={() => changeVideoWidth(videoWidth - 5)} tooltip="Bild verkleinern"/>
                    <Button icon="pi pi-plus" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 1}}
                            onClick={() => changeVideoHeight(videoHeight + 5)} tooltip="Bild vergrößern"/>
                    <Button icon="pi pi-minus" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 10}}
                            onClick={() => changeVideoHeight(videoHeight - 5)} tooltip="Bild verkleinern"/>
                    <Button className={showVideo ? "" : "p-button-secondary"} icon="pi pi-desktop" style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 10}} onClick={() => {
                        changeShowVideo(!showVideo)
                    }} tooltip="Webcam anzeigen"/>
                    <Button style={{height: 25, width: 25, margin: 0, padding: 0, marginRight: 10}} icon="pi pi-th-large" tooltip="Einstellungen"
                            onClick={() => onShowSettings(true)}/>
                </>
                }
                <Button icon={showVideoMenu ? "pi pi-chevron-right" : "pi pi-chevron-left"} style={{height: 30, width: 30, margin: 0, padding: 0}}
                        onClick={() => changeShowVideoMenu(!showVideoMenu)}/>
            </div>
        </div>
    }
    if (!showVideo) {
        return <div className="grid align-content-end vertical-container" style={{position:"absolute", bottom:0, right:30, height:"100%", width:20, zIndex:600, margin:0, padding:0}}>
            <div className="col" style={{padding:0, margin:0, textAlign:"right"}}>
                {showSoundIch && <img src={soundIchImage} alt="Meinen Sound abspielen" style={{width:35, marginTop:10, cursor: "pointer"}} onClick={() => changeSoundIch(!soundIch)} />}
                {showSoundGegner && <img src={soundGegnerImage} alt="Gegner Sound abspielen" style={{width:35, marginTop:10, cursor: "pointer"}} onClick={() => changeSoundGegner(!soundGegner)} />}
                <Button icon="pi pi-clone" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop:10}} onClick={() => changeShowVideo(true)} tooltip=""/>
            </div>
        </div>
    }

    return <div className="grid align-content-end vertical-container" style={{position:"absolute", bottom: 0, right:30, height:"100%", width:20, zIndex:600, margin:0, padding:0}}>
        <div className="col" style={{padding:0, margin:0, textAlign:"right"}}>
            <Button className={showVideoMenu ? "" : "p-button-secondary"} icon={showVideoMenu ? "pi pi-chevron-down" : "pi pi-chevron-up"}
                    style={{height:35, width:35, margin:0, padding:0}} onClick={() => {changeShowVideoMenu(!showVideoMenu)}} tooltip=""/>
            {showVideoMenu && <>
                <img src="/images/qrcode.png" alt="QR-Code Video extern" style={{height: 35, width: 35, marginTop: 10, cursor:"pointer"}} onClick={() => setShowQRCode(true)}/>
                {showSoundIch && <img src={soundIchImage} alt="Meinen Sound abspielen" style={{width: 35, marginTop: 10, cursor: "pointer"}} onClick={() => changeSoundIch(!soundIch)}/>}
                {showSoundGegner && <img src={soundGegnerImage} alt="Gegner Sound abspielen" style={{width: 35, marginTop: 10, cursor: "pointer"}} onClick={() => changeSoundGegner(!soundGegner)}/>}
                <Button icon="pi pi-clone" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 20}} disabled={disableButtons} onClick={() => showVideoImNeuenFenster()} tooltip=""/>
                <img src={anzeigeimage} alt="Anzeigeart" style={{width: 35, marginTop: 10, cursor: "pointer"}}
                     onClick={() => {
                         let newAnzeigeart;
                         if (videoAnzeigeart === 1) {
                             newAnzeigeart = 2;
                         } else if (videoAnzeigeart === 2) {
                             if (supportVideoOption3) {
                                 newAnzeigeart = 3;
                             } else {
                                 newAnzeigeart = 1;
                             }
                         } else {
                             newAnzeigeart = 1;
                         }
                         changeAnzeigeart(newAnzeigeart);
                     }}/>
                <Button className={showVideo ? "" : "p-button-secondary"} icon="pi pi-desktop" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 10}} onClick={() => {
                    changeShowVideo(!showVideo)
                }} tooltip=""/>
                <Button icon="pi pi-plus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 10}} disabled={disableButtons || widthDisabled}
                        onClick={() => changeVideoWidth(videoWidth + 5)} tooltip=""/>
                <Button icon="pi pi-minus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 1}} disabled={disableButtons || widthDisabled}
                        onClick={() => changeVideoWidth(videoWidth - 5)} tooltip=""/>
                <Button icon="pi pi-plus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 10}} disabled={disableButtons}
                        onClick={() => changeVideoHeight(videoHeight + 5)} tooltip=""/>
                <Button icon="pi pi-minus" style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 1}} disabled={disableButtons}
                        onClick={() => changeVideoHeight(videoHeight - 5)} tooltip=""/>
            </>
            }
        </div>
        {showQRCode &&
            <QRCodeSidebar link={videoURL} beschreibung={"Scannen diesen QR Code um das Meeting mit deinem Gegner z.B. auf einem Handy oder Tablet zu starten."} kritisch={false} onClose={() => setShowQRCode(false)}/>
        }
    </div>
}


