import React from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";

const STARTPUNKTE_ZUR_AUSWAHL = [
    {name: 'Auswahl löschen', code: undefined},
    {name: "101", code: 101},
    {name: "170", code: 170},
    {name: "301", code: 301},
    {name: "501", code: 501},
    {name: "601", code: 601},
    {name: "701", code: 701},
    {name: "901", code: 901},
    {name: "1001", code: 1001},
];

export function GameStartpunkteComponent({startpunkte, onChange}) {
    return <DHSelectionComponent value={startpunkte} onChange={onChange} values={STARTPUNKTE_ZUR_AUSWAHL} title="Startpunkte"/>
}
