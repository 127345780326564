import React, {useEffect, useState} from 'react'
import ConfigUtil from "../../util/ConfigUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ChatComponent from "./ChatComponent";

let stompClient = undefined;

export default function Chat(props) {

    let [messages, setMessages] = useState([]);

    useEffect(() => {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/chat/" + props.id;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, (payload) => {
                    const body = JSON.parse(payload.body);
                    setMessages(body.messages);
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });

        return () => {
            if( stompClient !== null && stompClient !== undefined) {
                console.log("Websocket - Chat - disconnect:");
                stompClient.disconnect(() => {});
            }
        };
    }, []);

    return <ChatComponent messages={messages} type={props.type} id={props.id} userId={props.userId}/>
}
