import React from 'react'
import PropTypes from "prop-types";
import PlusMinusComponent from "../../general/PlusMinusComponent";
import ScoreWertComponent from "../../general/ScoreWertComponent";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import ConfirmDialog from "../../general/ConfirmDialog";

class SpieltagGameComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...this.props.game, showDeleteDialog:false};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);

        this.setGameActiveIn = this.setGameActiveIn.bind(this);
        this.setGameAbgeschlossen = this.setGameAbgeschlossen.bind(this);
        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.addHighscore = this.addHighscore.bind(this);
        this.removeHighscore = this.removeHighscore.bind(this);
        this.addHighfinish = this.addHighfinish.bind(this);
        this.removeHighfinish = this.removeHighfinish.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.mytimer);
    }

    componentDidMount() {
        this.mytimer = setInterval(() => this.save(), 1000);
    }

    setGameActiveIn(gameid) {
        this.messages.clear();
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game/" + gameid + "/inactive",
            {},
            () => {
                this.showInfo("Spiel wurde inaktiv geschaltet.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Inaktiv schalten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Inaktiv schalten: " + error.message);
            }
        );
    }

    setGameAbgeschlossen(gameid) {
        this.messages.clear();
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game/" + gameid + "/abgeschlossen",
            {},
            () => {
                this.showInfo("Spiel wurde abgeschlossen geschaltet.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Abschliessen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Abschliessen: " + error.message);
            }
        );
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    delete() {
        this.messages.clear();
        this.setState({changeTimeStamp: undefined, isSaving: true});
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game/" + this.props.game.id,
            {},
            id => {
                this.setState({isSaving: false});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Löschen: " + error.message);
            }
        );
    }

    save() {
        let stateTimestamp = this.state.changeTimeStamp;
        if (stateTimestamp === undefined || this.props.readonly) {
            return;
        }

        if (!(Date.now() - stateTimestamp > 1000)) {
            return;
        }

        this.messages.clear();
        this.setState({changeTimeStamp: undefined, isSaving: true});
        let data = {id: this.props.game.id, ...this.state};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday + "/" + this.props.spieltagid + "/game",
            data,
            id => {
                this.setState({isSaving: false});
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );f
    }

    handleMinus(field, value) {
        this.setState({[field]: this.state[field] - 1, changeTimeStamp: Date.now()});
    }

    handlePlus(field) {
        this.setState({[field]: this.state[field] + 1, changeTimeStamp: Date.now()});
    }

    removeHighfinish(value) {
        let newHighfinishes = this.entferneErstenGefundenWertAusArray(this.state.highfinishes, value);
        this.setState({...this.state, highfinishes: newHighfinishes, changeTimeStamp: Date.now()});
    }

    addHighfinish(value) {
        let highfinishes = this.state.highfinishes;
        highfinishes.push(value);
        this.setState({...this.state, highfinishes: highfinishes, changeTimeStamp: Date.now()});
    }

    removeHighscore(value) {
        let newHighscores = this.entferneErstenGefundenWertAusArray(this.state.highscores, value);
        this.setState({...this.state, highscores: newHighscores, changeTimeStamp: Date.now()});
    }

    addHighscore(value) {
        let highscores = this.state.highscores;
        highscores.push(value);
        this.setState({...this.state, highscores: highscores, changeTimeStamp: Date.now()});
    }

    entferneErstenGefundenWertAusArray(myarray, value) {
        let newMyArray = [];
        let found = false;
        for (var i = 0; i < myarray.length; i++) {
            if (myarray[i] !== value || found === true) {
                newMyArray.push(myarray[i]);
            } else {
                // hat sonst wenn z.b. 111 zwei mal drinnen war, beide gelöscht.
                found = true;
            }
        }
        return newMyArray;
    }

    getScoreButtons(callBackFunc, currentLegValue) {
        let buttons = [];
        if (this.props.game.firstToLegs === undefined) {
            return buttons;
        }
        let classNameSelected = "p-button-success";
        let className = "p-button-secondary";
        for (let i = 0; i <= this.props.game.firstToLegs; i++) {
            let currentClassName = className;
            if (i === currentLegValue) {
                currentClassName = classNameSelected;
            }
            buttons.push(<Button key={i} label={"" + i}
                                 onClick={(e) => callBackFunc(i)}
                                 className={currentClassName}
                                 disabled={this.props.readonly}
                                 style={{height: "60px", width: "60px", marginRight: "10px", marginBottom: "10px"}}/>);
        }
        return buttons;
    }

    getStateIcon() {
        return <div style={{height: 40, width: 40, marginLeft: 20}}>
            {this.state.changeTimeStamp !== undefined &&
            <i className="pi pi-pencil"/>
            }
            {this.state.changeTimeStamp === undefined && !this.state.isSaving &&
            <i className="pi pi-check"/>
            }
            {this.state.isSaving &&
            <i className="pi pi-spin pi-spinner"/>
            }
        </div>

    }

    render() {
        let legsGewaehlt = this.state.anzahlSpieler1 === undefined || this.state.anzahlSpieler2 === undefined;
        let legsEingabeGleich = this.state.anzahlSpieler1 === this.state.anzahlSpieler2;
        let keineEingabeHatMaxLegsWert = true;
        if (this.props.game !== undefined) {
            keineEingabeHatMaxLegsWert = this.state.anzahlSpieler1 !== this.props.game.firstToLegs &&
                this.state.anzahlSpieler2 !== this.props.game.firstToLegs;
        }
        let abschliessenDisabled = this.state.changeTimeStamp !== undefined || legsGewaehlt || legsEingabeGleich || keineEingabeHatMaxLegsWert;
        let inaktivSchaltenDisabled = !this.props.readonly && this.state.changeTimeStamp !== undefined;

        return <div style={{padding: 10}} key={this.props.game.id}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <hr/>
            {this.props.readonly &&
            <div className="grid align-center vertical-container"
                 style={{textAlign: "left", fontSize: 12, color: "red", margin: 0}}>
                <div className="col">
                    Readonly!!! Änderungen werden nicht gespeichert!!!
                </div>
            </div>
            }
            <div className="grid align-center vertical-container"
                 style={{textAlign: "left", fontSize: 20, margin: 0, marginBottom:20}}>
                <div className="col">
                    <Button label="Spiel inaktiv schalten"
                            disabled={inaktivSchaltenDisabled}
                            onClick={() => this.setGameActiveIn(this.props.game.id)}
                            style={{width: 200, height: 30, marginRight: 20}}/>
                    <Button label="Spiel abschließen"
                            disabled={abschliessenDisabled}
                            onClick={() => this.setGameAbgeschlossen(this.props.game.id)}
                            style={{width: 200, height: 30, marginRight: 150}}/>
                    <Button label="Spiel löschen"
                            disabled={this.props.readonly}
                            onClick={() => this.setState({showDeleteDialog:true})}
                            style={{width: 200, height: 30}}/>
                </div>
            </div>
            <div className="grid align-center vertical-container"
                 style={{textAlign: "left", marginBottom: 20, fontSize: 20}}>
                {this.getStateIcon()}
                <div className="col-fixed align-center vertical-container">
                    {this.props.game.spieler1Name}
                </div>
                <div className="col-fixed align-center vertical-container">
                    <div style={{float: "left"}}>{this.getScoreButtons((score) => this.setState({
                        anzahlSpieler1: score,
                        changeTimeStamp: Date.now()
                    }), this.state.anzahlSpieler1)}</div>
                </div>
                <div className="col-fixed align-center vertical-container">
                    <div style={{float: "left", marginLeft: 20, marginRight: 20}}>:</div>
                </div>
                <div className="col-fixed align-center vertical-container">
                    <div style={{float: "left"}}>{this.getScoreButtons((score) => this.setState({
                        anzahlSpieler2: score,
                        changeTimeStamp: Date.now()
                    }), this.state.anzahlSpieler2)}</div>
                </div>
                <div className="col-fixed align-center vertical-container">
                    <div
                        style={{float: "left"}}>{this.props.game.spieler2Name !== undefined && this.props.game.spieler2Name !== null && this.props.game.spieler2Name !== "" ? this.props.game.spieler2Name : "'Gegner'"}</div>
                </div>
            </div>
            <div className="grid align-center vertical-container" style={{textAlign: "left", marginBottom: 10}}>
                <div className="col-fixed" style={{width: 100, fontWeight: "bold"}}>Triple</div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="20:"
                                                                                      value={this.state._20}
                                                                                      callBackMinus={() => this.handleMinus("_20")}
                                                                                      callBackPlus={() => this.handlePlus("_20")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="19:"
                                                                                      value={this.state._19}
                                                                                      callBackMinus={() => this.handleMinus("_19")}
                                                                                      callBackPlus={() => this.handlePlus("_19")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="18:"
                                                                                      value={this.state._18}
                                                                                      callBackMinus={() => this.handleMinus("_18")}
                                                                                      callBackPlus={() => this.handlePlus("_18")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="17:"
                                                                                      value={this.state._17}
                                                                                      callBackMinus={() => this.handleMinus("_17")}
                                                                                      callBackPlus={() => this.handlePlus("_17")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="16:"
                                                                                      value={this.state._16}
                                                                                      callBackMinus={() => this.handleMinus("_16")}
                                                                                      callBackPlus={() => this.handlePlus("_16")}
                                                                                      readonly={this.props.readonly}/>
                </div>
            </div>
            <div className="grid align-center vertical-container" style={{textAlign: "left"}}>
                <div className="col-fixed" style={{width: 100, fontWeight: "bold"}}>Sonstiges</div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="180:"
                                                                                      value={this.state._180}
                                                                                      callBackMinus={() => this.handleMinus("_180")}
                                                                                      callBackPlus={() => this.handlePlus("_180")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 180}}><PlusMinusComponent beschreibung="171:"
                                                                                      value={this.state._171}
                                                                                      callBackMinus={() => this.handleMinus("_171")}
                                                                                      callBackPlus={() => this.handlePlus("_171")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 300}}><PlusMinusComponent beschreibung="Single Bull + Bull:"
                                                                                      value={this.state.bull}
                                                                                      callBackMinus={() => this.handleMinus("bull")}
                                                                                      callBackPlus={() => this.handlePlus("bull")}
                                                                                      readonly={this.props.readonly}/>
                </div>
                <div className="col-fixed" style={{width: 200}}><PlusMinusComponent beschreibung="Neger:"
                                                                                      value={this.state.neger}
                                                                                      callBackMinus={() => this.handleMinus("neger")}
                                                                                      callBackPlus={() => this.handlePlus("neger")}
                                                                                      readonly={this.props.readonly}/>
                </div>
            </div>
            <div className="grid align-center vertical-container" style={{textAlign: "left"}}>
                <div className="col" style={{float: "left", marginLeft: 80}}>
                    <ScoreWertComponent beschreibung="Highscores:"
                                        readonly={this.props.readonly}
                                        values={this.state.highscores} callBackOk={this.addHighscore}
                                        removeValue={this.removeHighscore}/>
                    <ScoreWertComponent beschreibung="Highfinishes:"
                                        readonly={this.props.readonly}
                                        values={this.state.highfinishes} callBackOk={this.addHighfinish}
                                        removeValue={this.removeHighfinish}/>
                </div>
            </div>
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteDialog: false
                           })}
                           callBackOnJa={this.delete}
                           header="Spiel löschen"
                           textOben=""
                           textUnten="Möchtest du das Spiel wirklich löschen?"/>

        </div>
    }
}

SpieltagGameComponent.propTypes = {
    game: PropTypes.object.isRequired,
    spieltagid: PropTypes.string.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
};

export default SpieltagGameComponent;