import React from 'react'

class UnderConstruction extends React.Component {

    render() {
        return <div style={{backgroundColor: "white", color: "black", textAlign: "left", padding: "20px", marginTop:"20px"}}>
            Diese Seite ist noch nicht fertig...
        </div>
    }
}

export default UnderConstruction;