import React from 'react';
import PropTypes, {instanceOf} from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";

import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {
    InputAreaSmall,
    PlayerHeaderArea,
    PlayerSingleArea,
    PlayerSingleAreaHalberBildschirm,
    PlayerSingleAreaSmall,
    ScoreArea,
    ScoreHeader,
    StatistikArea,
    WurfArea
} from "../../../score/ScoreComponents";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import AnzahlWuerfeDialog from "../../training/AnzahlWuerfeDialog";
import AufnahmeShortcutDialog from "../../training/AufnahmeShortcutDialog";
import {getModusNameById} from "../../../general/GameModusComponent";
import {ScoringRatingArea} from "../../training/ScoringRatingArea";
import {Cookies} from "react-cookie";
import DoppelQuestionDialog from "../../training/DoppelQuestionDialog";
import {Sidebar} from "primereact/sidebar";
import {RadioButton} from "primereact/radiobutton";
import ScoringChatComponent from "../ScoringChatComponent";
import ScoringVideoSettingsComponent from "../ScoringVideoSettingsComponent";
import ScoringVideoComponent from "../ScoringVideoComponent";
import AusbullenComponent from "../AusbullenComponent";
import SoundComponent from "../../../general/SoundComponent";
import AutodartsConnection from "../../../autodarts/AutodartsConnection";

let stompClient = undefined;

const zIndexVollbildHeader = 600;
const zIndexMessages = 600;
const zIndexWebviewHinweisButton = 600;
const zIndexAutomat = 550;

class ScoringX01View extends React.Component {

    constructor(props) {
        super(props);

        const { cookies } = props;
        let videoAnzeigeart = 1;
        let videoWidth = 40;
        let videoHeight = 30;
        let soundIch = false;
        let soundGegner = false;
        let anzeigeeinstellung = "system";
        let darthelfer_scoring_anzeigeart = cookies.get('darthelfer_scoring_anzeigeart');
        if( darthelfer_scoring_anzeigeart != null && darthelfer_scoring_anzeigeart !== undefined && darthelfer_scoring_anzeigeart !== "") {
            videoAnzeigeart = parseInt(cookies.get('darthelfer_scoring_anzeigeart'));
        }
        let darthelfer_scoring_width = cookies.get('darthelfer_scoring_width');
        if( darthelfer_scoring_width != null && darthelfer_scoring_width !== undefined && darthelfer_scoring_width !== "") {
            videoWidth = parseInt(cookies.get('darthelfer_scoring_width'));
        }
        let darthelfer_scoring_height = cookies.get('darthelfer_scoring_height');
        if( darthelfer_scoring_height != null && darthelfer_scoring_height !== undefined && darthelfer_scoring_height !== "") {
            videoHeight = parseInt(cookies.get('darthelfer_scoring_height'));
        }
        let darthelfer_scoring_sound_ich = cookies.get('darthelfer_scoring_sound_ich');
        if( darthelfer_scoring_sound_ich != null && darthelfer_scoring_sound_ich !== undefined && darthelfer_scoring_sound_ich !== "") {
            soundIch = cookies.get('darthelfer_scoring_sound_ich') === "true" ? true : false;
        }
        let darthelfer_scoring_sound_gegner = cookies.get('darthelfer_scoring_sound_gegner');
        if( darthelfer_scoring_sound_gegner != null && darthelfer_scoring_sound_gegner !== undefined && darthelfer_scoring_sound_gegner !== "") {
            soundGegner = cookies.get('darthelfer_scoring_sound_gegner') === "true" ? true : false;
        }
        let darthelfer_scoring_anzeigeeinstellung = cookies.get('darthelfer_scoring_anzeigeeinstellung');
        if( darthelfer_scoring_anzeigeeinstellung != null && darthelfer_scoring_anzeigeeinstellung !== undefined && darthelfer_scoring_anzeigeeinstellung !== "") {
            anzeigeeinstellung = cookies.get('darthelfer_scoring_anzeigeeinstellung');
        }

        this.state = {
            gameid: props.gameid,
            aufnahme: "",
            saving: false,
            showWebcamHinweis: true,
            videoMobilWidth:100,
            videoMobilHeight:50,
            showAufnahmeShortcutsDialog: false,
            showAnzahlWuerfe : false,
            showScoringDoppelQuestion: false,
            showAnzahlWuerfeMitDoppelQuestion: false,
            videoAnzeigeart: videoAnzeigeart,
            videoWidth:videoWidth,
            videoHeight:videoHeight,
            showVideoMenu: true,
            showVideo: true,
            bullabgeschickt: false,
            videoOff: false,
            soundIch: soundIch,
            soundGegner:soundGegner,
            soundValue : undefined,
            chatMessages:[],
            automatenwerte: undefined,
            autodartsValue: 0,
            showAllgemeineEinstellungen: false,
            anzeigeeinstellung: anzeigeeinstellung,
        };

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onGameError = this.onGameError.bind(this);
        this.onSoundIchReceived = this.onSoundIchReceived.bind(this);
        this.onSoundGegnerReceived = this.onSoundGegnerReceived.bind(this);
        this.onChatMessageReceived = this.onChatMessageReceived.bind(this);
        this.onAutomatReceived = this.onAutomatReceived.bind(this);
        this.websocketConnect = this.websocketConnect.bind(this);
        this.sendAnzahlWuerfe = this.sendAnzahlWuerfe.bind(this);
        this.merkeAufnahme = this.merkeAufnahme.bind(this);
        this.sendAufnahme = this.sendAufnahme.bind(this);
        this.sendAutomatAufnahme = this.sendAutomatAufnahme.bind(this);
        this.saveAufnahme = this.saveAufnahme.bind(this);
        this.changeScore = this.changeScore.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.state.gameid);
    }

    componentWillUnmount() {
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
    }

    isMobileDevice() {
        if( this.state.anzeigeeinstellung === "mobil") {
            return true;
        } else if( this.state.anzeigeeinstellung === "desktop") {
            return false;
        } else {
            let windowWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
            if( windowWidth < 1200) {
                return true;
            }
            return false;
        }
    }

    websocketConnect(gameid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/game/x01/" + gameid;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
                const destinationGameError = "/topic/public/game/error/" + this.props.playerid;
                console.log("subscribe to " + destinationGameError);
                stompClient.subscribe(destinationGameError, this.onGameError);
                const destinationSoundIch = "/topic/public/game/soundich/" + this.props.playerid;
                console.log("subscribe to " + destinationSoundIch);
                stompClient.subscribe(destinationSoundIch, this.onSoundIchReceived);
                const destinationSoundGegner = "/topic/public/game/soundgegner/" + this.props.playerid;
                console.log("subscribe to " + destinationSoundGegner);
                stompClient.subscribe(destinationSoundGegner, this.onSoundGegnerReceived);
                const destinationChat = "/topic/public/chat/" + gameid;
                console.log("subscribe to " + destinationChat);
                stompClient.subscribe(destinationChat, this.onChatMessageReceived);
                const destinationAutomat = "/topic/public/game/automat/" + this.props.playerid;
                console.log("subscribe to " + destinationAutomat);
                stompClient.subscribe(destinationAutomat, this.onAutomatReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onAutomatReceived(payload) {
        const body = JSON.parse(payload.body);
        this.setState( {automatenwerte: body});
    }

    onChatMessageReceived(payload) {
        const body = JSON.parse(payload.body);
        if( body.messages.length > 0 ) {
            this.setState({chatMessages: body.messages });
        }
    }

    onSoundIchReceived(payload) {
        if( !this.state.soundIch) {
            return;
        }
        const json = JSON.parse(payload.body);
        this.setState({soundValue: parseInt(json)});
    }

    onSoundGegnerReceived(payload) {
        if( !this.state.soundGegner) {
            return;
        }
        const json = JSON.parse(payload.body);
        this.setState({soundValue: parseInt(json)});
    }

    onGameError(payload) {
        this.showError(payload.body);
        this.setState({bullabgeschickt: false, saving: false});
    }


    onMessageReceived(payload) {
        const json = JSON.parse(payload.body);
        let daten = {...json, saving: false, aufnahme: "", automatenwerte: undefined};
        if( daten.erneutBullen ) {
            daten = {...daten, bullabgeschickt:false}
        }
        this.setState(daten);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    handelKeyDown(event) {
        if (event.keyCode === 13) {
            // enter
            this.sendAufnahme();
            return;
        }

        const wertFuerF9 = this.getToGo(this.props.playerid, false)- this.state.aufnahme;
        switch (event.key) {
            case "F1": this.sendAufnahme(0); event.preventDefault(); break;
            case "F2": this.sendAufnahme(26); event.preventDefault(); break;
            case "F3": this.sendAufnahme(41); event.preventDefault(); break;
            case "F4": this.sendAufnahme(45); event.preventDefault(); break;
            case "F5": this.sendAufnahme(60); event.preventDefault(); break;
            case "F6": this.sendAufnahme(81); event.preventDefault(); break;
            case "F7": this.sendAufnahme(85); event.preventDefault(); break;
            case "F8": this.sendAufnahme(100); event.preventDefault(); break;
            case "F9": this.sendAufnahme(wertFuerF9); event.preventDefault(); break;
            case "F10": this.sendAufnahme(this.getToGo(this.props.playerid, false), 1); event.preventDefault(); break;
            case "F11": this.sendAufnahme(this.getToGo(this.props.playerid, false), 2); event.preventDefault(); break;
            case "F12": this.sendAufnahme(this.getToGo(this.props.playerid, false), 3); event.preventDefault(); break;
            default: break;
        }
        event.stopPropagation();
    }

    merkeAufnahme(aufnahme) {
        this.setState({aufnahme: aufnahme});
    }

    sendAnzahlWuerfe(anz, anzahlAmDoppelVorbei) {
        this.sendAufnahme(this.state.aufnahme, anz, anzahlAmDoppelVorbei);
    }

    sendAufnahme(aufnahme, anzahlWuerfe, anzahlWuerfeAmDoppelVorbei) {
        let tmpAufnahme;
        if( aufnahme !== undefined && aufnahme !== null ) {
            tmpAufnahme = aufnahme;
        } else {
            tmpAufnahme = this.state.aufnahme;
        }
        this.saveAufnahme(tmpAufnahme, anzahlWuerfe, anzahlWuerfeAmDoppelVorbei);
    }

    sendAutomatAufnahme() {
        if( this.state.automatenwerte === undefined || this.state.automatenwerte === null ) {
            return;
        }

        let anzahlWuerfe = this.state.automatenwerte.length;
        let tmpAufnahme = 0;
        let wert1 = this.state.automatenwerte[0];
        if(wert1 !== undefined && wert1 !== null && wert1 !== "") {
            tmpAufnahme += Number.parseInt(wert1);
        }
        let wert2 = this.state.automatenwerte[1];
        if(wert2 !== undefined && wert2 !== null && wert2 !== "") {
            tmpAufnahme += Number.parseInt(wert2);
        }
        let wert3 = this.state.automatenwerte[2];
        if(wert3 !== undefined && wert3 !== null && wert3 !== "") {
            tmpAufnahme += Number.parseInt(wert3);
        }
        this.saveAufnahme(tmpAufnahme, anzahlWuerfe, undefined);
    }

    sendBullen(bull1, bull2, bull3) {
        this.messages.clear();

        let wertGesamt = bull1 + ";" + bull2 + ";" + bull3;
        if( wertGesamt === null || wertGesamt === undefined) {
            // es gab Calls in das Backend, wo der Wert aus welchem Grund auch immer "null" war. Sicherheitsabfrage.
            this.showError("Fehler beim Bullen, bitte aktualisiere den Browser und gib dein Ergebnis erneut ein.");
            return;
        }
        this.setState({bullAbgeschickt: true});
        let url = "/app/bullen/" + this.state.gameid + "/" + this.props.playerid + "/" + wertGesamt;
        let data = JSON.stringify({wert: wertGesamt});
        stompClient.send(url, {}, data);
    }

    sendUndoLegEnd() {
        this.messages.clear();
        let url = "/app/undolegend/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, {});
    }

    saveAufnahme(aufnahme, anzahlWuerfe, anzahlWuerfeAmDoppelVorbei) {
        this.messages.clear();
        if (this.state.saving) {
            console.log("Ich speichere gerade, weiterer Speicherversuch nicht erlaubt.");
            return;
        }
        const aufnahmeKorrekt = aufnahme !== "" && parseInt(aufnahme) <= 180;
        if (!aufnahmeKorrekt) {
            return;
        }

        const playerScoreDto = this.getPlayerScoreDto(this.props.playerid, false);
        const showScoringQuestion = playerScoreDto.showScoringQuestion;
        const showScoringDoppelQuestion = playerScoreDto.showScoringDoppelQuestion;
        const togo = this.getToGo(this.props.playerid, false);

        let relevanterDoubleModus = this.state.modusId === "double_out" || this.state.modusId === "double_in_double_out";
        let showDoppelDialog = showScoringDoppelQuestion && ((togo - parseInt(aufnahme) <= 50) || (togo <= 180 && parseInt(aufnahme) <= 0) );
        if( relevanterDoubleModus && showDoppelDialog && anzahlWuerfeAmDoppelVorbei === undefined) {
            let showAnzahlWuerfeDialog = togo === parseInt(aufnahme) && anzahlWuerfe === undefined;
            this.setState({aufnahme: aufnahme, showScoringDoppelQuestion: true, showAnzahlWuerfeMitDoppelQuestion: showAnzahlWuerfeDialog, showAnzahlWuerfeDoppelQuestion: anzahlWuerfe });
            return;
        }
        if( !showDoppelDialog && showScoringQuestion && togo === parseInt(aufnahme) && anzahlWuerfe === undefined) {
            this.setState({showAnzahlWuerfe: true});
            return;
        }

        this.setState({saving: true});

        let url = "/app/score/x01/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, JSON.stringify({aufnahme: aufnahme, anzahlWuerfe: anzahlWuerfe, anzahlDoppelwuerfe: anzahlWuerfeAmDoppelVorbei}));
    }

    changeScore(playerid, scoreid, aufnahme) {
        let url = "/app/changescore/x01/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, JSON.stringify({id: scoreid, aufnahme: aufnahme}));
    }

    getAutomatArea() {

        let style = {};
        if( this.state.anzeigeart === 1 || this.state.anzeigeart === 3) {
            style = { top: 65, left:20};
        } else if( this.state.anzeigeart === 2 ) {
            style = { top: 65, right:20};
        }

        let wert1 = this.state.automatenwerte[0];
        let wert2 = this.state.automatenwerte[1];
        let wert3 = this.state.automatenwerte[2];
        return <div style={{position: "absolute", width:120, height:220, zIndex: zIndexAutomat,
            border:"2px solid black", alignContent:"center",
            alignItems:"center", textAlign:"center",
            backgroundColor:"#e6f2ff", ...style}}>
            <span style={{fontWeight:"bold"}}>Automat</span>
            <br/>
            <InputText value={wert1}
                       onChange={(e) => this.changeAutomataufnahme(e, 0)}
                       style={{fontSize: 20, width: 60, marginTop:5}}/>
            <br/>
            <InputText value={wert2}
                       onChange={(e) => this.changeAutomataufnahme(e, 1)}
                       style={{fontSize: 20, width: 60, marginTop:5}}/>
            <br/>
            <InputText value={wert3}
                       onChange={(e) => this.changeAutomataufnahme(e, 2)}
                       style={{fontSize: 20, width: 60, marginTop:5}}/>
            <br/>
            <Button label="Absenden" onClick={() => this.sendAutomatAufnahme()}
                    style={{marginTop:10}}/>
        </div>
    }

    allgemeineEinstellungArea() {
        let soundIchImage =  this.state.soundIch ? "/images/megaphone_color.png" : "/images/megaphone.png";
        let soundGegnerImage =  this.state.soundGegner ? "/images/ear_color.png" : "/images/ear.png";

        return <Sidebar visible={this.state.showAllgemeineEinstellungen} fullScreen onHide={() => this.setState({ showAllgemeineEinstellungen: false })}>
            <Button label="Bereich schliessen" onClick={() => this.setState({showAllgemeineEinstellungen: false})} style={{margin: 10}}/>
            <h3>Aktueller Jitsi-Server</h3>
            <div>https://jitsi.rptu.de/</div>
            <h3>Anzeigeeinstellung</h3>
            <div style={{marginTop:10, fontSize:12}}>
                Das System versucht die beste Einstellung für dich zu finden. Du kannst die Einstellung aber auch selbst wählen. In diesem Fall kann es natürlich zu Problemen führen, auf einem mobilen Gerät wird die Desktop-Ansicht nie richtig funktionieren und wurde dafür auch nicht optimiert.
            </div>
            <div style={{marginTop:10}}>
                <RadioButton inputId="anzeigeeinstellung" name="anzeigeeinstellung" value="system" onChange={(e) => this.changeAnzeigeeinstellung(e.value)} checked={this.state.anzeigeeinstellung === 'system'} style={{marginRight:10}}/>
                Das System entscheidet ob Mobil oder Desktop Version angezeigt wird.
            </div>
            <div style={{marginTop:10}}>
                <RadioButton inputId="anzeigeeinstellung" name="anzeigeeinstellung" value="mobil" onChange={(e) => this.changeAnzeigeeinstellung(e.value)} checked={this.state.anzeigeeinstellung === 'mobil'} style={{marginRight:10}}/>
                Immer Mobile-Ansicht anzeigen.
            </div>
            <div style={{marginTop:10}}>
                <RadioButton inputId="anzeigeeinstellung" name="anzeigeeinstellung" value="desktop" onChange={(e) => this.changeAnzeigeeinstellung(e.value)} checked={this.state.anzeigeeinstellung === 'desktop'} style={{marginRight:10}}/>
                Immer Desktop-Ansicht anzeigen.


            </div>
            <hr/>
            <h3>Einstellungen zur Callerausgabe</h3>
            <div style={{marginTop:10}}>
                <img src={soundIchImage} alt="Meinen Sound abspielen" style={{width:25, marginRight:5, cursor: "pointer"}} onClick={() => {
                    this.setState({soundIch: !this.state.soundIch});
                    this.setCookieValue("darthelfer_scoring_sound_ich", !this.state.soundIch);
                }} />
                <span onClick={() => {
                    this.setState({soundIch: !this.state.soundIch});
                    this.setCookieValue("darthelfer_scoring_sound_ich", !this.state.soundIch);
                }} style={{cursor: "pointer"}}>Meine Würfe ansagen</span>
            </div>
            <div style={{marginTop:10}}>
                <img src={soundGegnerImage} alt="Gegner Sound abspielen" style={{width:25, marginRight:5, cursor: "pointer", }} onClick={() => {
                    this.setState({soundGegner: !this.state.soundGegner});
                    this.setCookieValue("darthelfer_scoring_sound_gegner", !this.state.soundGegner);
                }} />
                <span onClick={() => {
                    this.setState({soundGegner: !this.state.soundGegner});
                    this.setCookieValue("darthelfer_scoring_sound_gegner", !this.state.soundGegner);
                }} style={{cursor: "pointer"}}>Würfe vom Gegner ansagen</span>
            </div>
        </Sidebar>
    }

    renderPC(anzScores, spielBeendet) {
        const aufnahmeKorrekt = this.state.aufnahme !== "" && parseInt(this.state.aufnahme) <= 180;

        let gameDetailAktuellerPlayer = this.getGameDetails(this.props.playerid, false);
        let gameDetailGegner = this.getGameDetails(this.props.playerid, true);
        let legAveragePlayer = "";
        let legAverageGegner = "";
        if( gameDetailAktuellerPlayer !== undefined ) {
            legAveragePlayer = gameDetailAktuellerPlayer.legAverage;
        }
        if( gameDetailGegner !== undefined ) {
            legAverageGegner = gameDetailGegner.legAverage;
        }

        let mussAusgebulltWerden = this.mussAusgebulltWerden();
        return <div
            style={{backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <Toast ref={(el) => this.toast = el} style={{zIndex: zIndexMessages}}/>
            <Messages ref={(el) => this.messages = el} style={{position:"fixed", top:60, left:0, width:"100%", paddingLeft:20, paddingRight:20, zIndex: zIndexMessages}} onClick={() => this.messages.clear()}/>
            {this.state.soundValue !== undefined && <SoundComponent loop={false}
                                                                    file={this.state.soundValue + ".wav"}
                                                                    onEnded={() => this.setState({soundValue: undefined})}/>
            }
            {this.allgemeineEinstellungArea()}
            <ScoringVideoComponent gameid={this.props.gameid}
                                   displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                   videoAnzeigeart={this.state.videoAnzeigeart}
                                   showVideo={this.state.showVideo}
                                   showVideoMenu={this.state.showVideoMenu}
                                   isMobileDevice={false}
                                   videoHeight={this.state.videoHeight}
                                   videoWidth={this.state.videoAnzeigeart === 2 ? "47" : this.state.videoWidth}/>
            <ScoringVideoSettingsComponent gameid={this.props.gameid}
                                           displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                           videoHeight={this.state.videoHeight}
                                           videoWidth={this.state.videoWidth}
                                           showVideoMenu={this.state.showVideoMenu}
                                           showVideo={this.state.showVideo}
                                           soundGegner={this.state.soundGegner}
                                           soundIch={this.state.soundIch}
                                           videoAnzeigeart={this.state.videoAnzeigeart}
                                           supportVideoOption3={true}
                                           supportVideoChangeByOption2={false}
                                           isMobileDevice={false}
                                           showSoundIch={true}
                                           showSoundGegner={true}
                                           changeSoundIch={(value) => {
                                               this.setState({soundIch: value});
                                               this.setCookieValue("darthelfer_scoring_sound_ich", value);
                                           }}
                                           changeSoundGegner={(value) => {
                                               this.setState({soundGegner: value});
                                               this.setCookieValue("darthelfer_scoring_sound_gegner", value);
                                           }}
                                           changeShowVideo={(value) => this.setState({showVideo: value})}
                                           changeShowVideoMenu={() => this.setState({showVideoMenu: !this.state.showVideoMenu})}
                                           changeVideoHeight={(value) => {
                                               if( value > 10 && value < 100  ) {
                                                   this.setState({videoHeight: value});
                                                   this.setCookieValue("darthelfer_scoring_height", value);
                                               }
                                           }}
                                           changeVideoWidth={(value) => {
                                               if( value > 10 && value < 100  ) {
                                                   this.setState({videoWidth: value});
                                                   this.setCookieValue("darthelfer_scoring_width", value);
                                               }
                                           }}
                                           changeAnzeigeart={(value) => {
                                               this.setState({videoAnzeigeart: value});
                                               this.setCookieValue("darthelfer_scoring_anzeigeart", value);
                                           }}
            />
            <ScoringChatComponent
                showChat={this.props.showChat} onShowChat={this.props.changeShowChat}
                isMobileDevice={false} gameid={this.props.gameid} chatMessages={this.state.chatMessages}
                userid={this.getPlayerScoreDto(this.props.playerid, false).userid} onError={this.showError}/>
            {this.state.automatenwerte !== undefined && this.getAutomatArea()}
            {mussAusgebulltWerden && <AusbullenComponent gameid={this.props.gameid}
                                                                player={this.getPlayerScoreDto(this.props.playerid, false)}
                                                                gegner={this.getPlayerScoreDto(this.props.playerid, true)}
                                                                callBackOnError={(message) => this.showError(message)}
                                                                callBackClearErrorMessages={() => this.messages.clear()}
                                                                callBackSendBullen={(bull1, bull2, bull3) => this.sendBullen(bull1, bull2, bull3)}
                                                                erneutBullen={this.state.erneutBullen}
                                                                bullAbgeschickt={this.state.bullAbgeschickt}/>
            }
            <div className="grid" style={{
                height: 60, margin: 0, marginBottom: 0, padding: 0, width: "100%", backgroundColor: "#e6f2ff",
                position: "fixed", top: 0, left: 0,
                zIndex: zIndexVollbildHeader,
            }}>
                <div className="col" style={{zIndex: zIndexVollbildHeader}}>
                    {this.state.videoAnzeigeart === 3 && <>
                        <PlayerHeaderArea player={this.getPlayerScoreDto(this.props.playerid, false)}
                                          isSetModus={this.isSetModus()}
                                          label="Ich "
                                          togo={this.getToGoCurrentPlayer()}
                                          isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid === this.props.playerid}/>
                        <PlayerHeaderArea player={this.getPlayerScoreDto(this.props.playerid, true)}
                                          isSetModus={this.isSetModus()}
                                          label="Gegner "
                                          togo={this.getToGo(this.props.playerid, true)}
                                          isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid !== this.props.playerid}/>
                    </>
                    }
                    {this.state.saving && <div>Speichere...<i className="pi pi-spin pi-spinner" style={{fontSize: 50, marginLeft: 30}}/></div>}
                    {!spielBeendet && this.state.darfPlayerLegZuruecksetzen &&
                        <Button label="Zurücksetzen" icon="pi pi-trash" onClick={() => this.sendUndoLegEnd()}
                                style={{marginLeft: 10}}/>
                    }
                    {!spielBeendet && this.isPlayerActive(this.props.playerid) && !this.state.saving &&
                        <>
                            <span style={{marginLeft: 20, marginRight: 10}}>Aufnahme:</span>
                            <InputText value={this.state.aufnahme}
                                       ref={(el) => this.aufnahmeInput = el}
                                       autoFocus={true}
                                       onChange={(e) => this.changeAufnahme(e)}
                                       style={{fontSize: 20, width: 60}}
                                       onKeyDown={(e) => this.handelKeyDown(e)}/>
                            <Button label="Absenden" disabled={!aufnahmeKorrekt} onClick={() => this.sendAufnahme()}
                                    style={{marginLeft: 20}}/>
                            <Button icon="pi pi-info-circle" onClick={() => this.setState({showAufnahmeShortcutsDialog: true})}
                                    style={{marginLeft: 10}}/>
                        </>
                    }
                </div>
                <div className="col" style={{fontSize: 20, textAlign: "right"}}>{this.getModusBeschreibung()}</div>
                {this.props.autodartsBoardId !== null && this.props.autodartsBoardId !== undefined &&
                    <div className="col-fixed" style={{width: 250, fontSize: 20, textAlign: "right", marginRight: 5}}>
                        <AutodartsConnection gameId={this.props.gameid}
                                             playerId={this.props.playerid}
                                             boardId={this.props.autodartsBoardId}
                                             modusId={this.state.modusId}
                                             toGo={this.getToGo(this.props.playerid, false)}
                                             active={this.isPlayerActive(this.props.playerid)}
                                             stompClient={stompClient}
                                             onValueChange={(value) => {
                                                 if( !this.mussAusgebulltWerden()) {
                                                     this.setState({autodartsValue: value});
                                                 }
                                                 }}
                                             onErrorClear={() => this.messages.clear()}
                        onError={(message) => this.showError(message)}/>
                    </div>
                }
                <div className="col-fixed" style={{width: 40, fontSize: 20, textAlign: "right", marginRight: 5}}>
                    <Button style={{height: 40, width: 40, marginTop: 5, padding: 0, marginRight: 10}} icon="pi pi-th-large" tooltip="Einstellungen"
                            onClick={() => this.setState({showAllgemeineEinstellungen: true})}/>
                </div>
                <div className="col-fixed" style={{width: 40, fontSize: 20, textAlign: "right", marginRight: 30}}>
                    <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                        <Button className="p-button-danger" icon="pi pi-power-off" style={{height: 40, width: 40, marginTop: 5, padding: 0, marginRight: 10}}
                                tooltip="Ausgang"/>
                    </NavLink>
                </div>
            </div>
            {this.state.videoAnzeigeart === 1 && <>
                <div>
                    <div className="grid" style={{
                        height: 300,
                        width: "100%",
                        margin: 0,
                        marginTop: 50,
                        padding: 0,
                    }}>
                        <PlayerSingleArea player={this.getPlayerScoreDto(this.props.playerid, false)}
                                          isSetModus={this.isSetModus()}
                                          togo={this.getToGoCurrentPlayer()}
                                          showCheckout={true}
                                          modusId={this.state.modusId}
                                          isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid === this.props.playerid}/>
                        <PlayerSingleArea player={this.getPlayerScoreDto(this.props.playerid, true)}
                                          isSetModus={this.isSetModus()}
                                          togo={this.getToGo(this.props.playerid, true)}
                                          showCheckout={false}
                                          modusId={this.state.modusId}
                                          isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid !== this.props.playerid}/>
                    </div>
                </div>
                {!spielBeendet ?
                    this.getScoreArea(anzScores)
                    :
                    this.gameOverArea()
                }
            </>
            }
            {this.state.videoAnzeigeart === 2  && <>
                <div style={{width: "50%", marginTop: 50}}>
                    <PlayerSingleAreaHalberBildschirm player={this.getPlayerScoreDto(this.props.playerid, false)}
                                                      isSetModus={this.isSetModus()}
                                                      togo={this.getToGoCurrentPlayer()}
                                                      showCheckout={true}
                                                      modusId={this.state.modusId}
                                                      legAverage={legAveragePlayer}
                                      isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid === this.props.playerid}/>
                    <PlayerSingleAreaHalberBildschirm player={this.getPlayerScoreDto(this.props.playerid, true)}
                                                      isSetModus={this.isSetModus()}
                                                        togo={this.getToGo(this.props.playerid, true)}
                                                      showCheckout={false}
                                                      modusId={this.state.modusId}
                                                      legAverage={legAverageGegner}
                                      isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid !== this.props.playerid}/>
                </div>
                {!spielBeendet ?
                    this.getScoreArea(anzScores)
                    :
                    this.gameOverArea()
                }
            </>
            }
            <AufnahmeShortcutDialog visible={this.state.showAufnahmeShortcutsDialog} callBackOnClose={() => this.setState({showAufnahmeShortcutsDialog: false})}/>
            <AnzahlWuerfeDialog visible={this.state.showAnzahlWuerfe} sendAnzahlWuerfe={this.sendAnzahlWuerfe} callBackOnClose={() => this.setState({showAnzahlWuerfe: false})}/>
            <DoppelQuestionDialog visible={this.state.showScoringDoppelQuestion}
                                  showAnzahlWuerfe={this.state.showAnzahlWuerfeMitDoppelQuestion}
                                  anzahlWuerfe={this.state.showAnzahlWuerfeDoppelQuestion}
                                  sendAnzahlWuerfe={this.sendAnzahlWuerfe} callBackOnClose={() => this.setState({showScoringDoppelQuestion: false})}/>
        </div>
    }

    renderMobilDevice(anzScores, spielBeendet) {
        let mussAusgebulltWerden = this.mussAusgebulltWerden(this.props.playerid);
        let gameDetailAktuellerPlayer = this.getGameDetails(this.props.playerid, false);
        let gameDetailGegner = this.getGameDetails(this.props.playerid, true);
        let legAveragePlayer = "";
        let legAverageGegner = "";
        if( gameDetailAktuellerPlayer !== undefined ) {
            legAveragePlayer = gameDetailAktuellerPlayer.legAverage;
        }
        if( gameDetailGegner !== undefined ) {
            legAverageGegner = gameDetailGegner.legAverage;
        }

        return <div style={{width:"100%", height:"100%", backgroundColor: "#e6f2ff", padding:0, margin:0}}>
            <Toast ref={(el) => this.toast = el} style={{zIndex: zIndexMessages}}/>
            <Messages ref={(el) => this.messages = el} style={{width:"100%", padding:0, zIndex: zIndexMessages}} onClick={() => this.messages.clear()}/>
            <div style={{padding:5,fontSize:12}}>{this.getModusBeschreibung()}</div>
            {this.state.soundValue !== undefined && <SoundComponent loop={false}
                                                                    file={this.state.soundValue + ".wav"}
                                                                    onEnded={() => this.setState({soundValue: undefined})}/>
            }
            {this.allgemeineEinstellungArea()}
            <ScoringVideoComponent gameid={this.props.gameid}
                                   displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                   videoAnzeigeart={this.state.videoAnzeigeart}
                                   showVideo={this.state.showVideo}
                                   showVideoMenu={this.state.showVideoMenu}
                                   isMobileDevice={true}
                                   videoHeight={this.state.videoMobilHeight} videoWidth={this.state.videoMobilWidth}/>
            <ScoringVideoSettingsComponent gameid={this.props.gameid}
                                           displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                           videoHeight={this.state.videoMobilHeight}
                                           videoWidth={this.state.videoMobilWidth}
                                           showVideoMenu={this.state.showVideoMenu}
                                           showVideo={this.state.showVideo}
                                           soundGegner={this.state.soundGegner}
                                           soundIch={this.state.soundIch}
                                           videoAnzeigeart={this.state.videoAnzeigeart}
                                           isMobileDevice={true}
                                           showSoundIch={false}
                                           showSoundGegner={false}
                                           showChat={this.props.showChat}
                                           onShowChat={this.props.changeShowChat}
                                           onShowSettings={(value) => this.setState({showAllgemeineEinstellungen: value})}
                                           changeSoundIch={(value) => ""}
                                           changeSoundGegner={(value) => ""}
                                           changeShowVideo={(value) => this.setState({showVideo: value})}
                                           changeShowVideoMenu={() => this.setState({showVideoMenu: !this.state.showVideoMenu})}
                                           changeVideoHeight={(value) => {
                                               if( value > 10 && value < 90  ) {
                                                   this.setState({videoMobilHeight: value});
                                                   this.setCookieValue("darthelfer_scoring_height", value);
                                               }
                                           }}
                                           changeVideoWidth={(value) => {
                                               if( value > 10 && value <= 100  ) {
                                                   this.setState({videoMobilWidth: value});
                                               }
                                           }}
                                           changeAnzeigeart={(value) => ""}
            />
            <ScoringChatComponent
                showChat={this.props.showChat} onShowChat={this.props.changeShowChat}
                isMobileDevice={true} gameid={this.props.gameid} chatMessages={this.state.chatMessages}
                userid={this.getPlayerScoreDto(this.props.playerid, false).userid} onError={this.showError}/>
            {mussAusgebulltWerden && <AusbullenComponent gameid={this.props.gameid}
                                                         player={this.getPlayerScoreDto(this.props.playerid, false)}
                                                         gegner={this.getPlayerScoreDto(this.props.playerid, true)}
                                                         callBackOnError={(message) => this.showError(message)}
                                                         callBackClearErrorMessages={() => this.messages.clear()}
                                                         callBackSendBullen={(bull1, bull2, bull3) => this.sendBullen(bull1, bull2, bull3)}
                                                         erneutBullen={this.state.erneutBullen}
                                                         bullAbgeschickt={this.state.bullAbgeschickt}/>
            }
            { this.state.showWebcamHinweis &&
            <Button label="Hinweis: Meldungen im Video nicht sichtbar, Monitor drehen!!!" onClick={(e) => this.setWebcamMessageUnsichtbar(e)} className="p-button-danger" style={{position:"fixed", bottom:0, left:0, width:"100%", zIndex:zIndexWebviewHinweisButton}} />
            }
            <div className="grid" style={{padding:10,margin:0}}>
                { this.isPlayerActive(this.props.playerid) && <>
                    <Button icon="pi pi-times" style={{width: 40, height: "100%"}} onClick={() => this.setState({aufnahme:""})} disabled={this.state.aufnahme === ""}/>
                    <Button icon="pi pi-angle-left" style={{width: 40, height: "100%"}} onClick={() => this.setState({aufnahme: this.state.aufnahme.substring(0, this.state.aufnahme.length -1)})} disabled={this.state.aufnahme === ""}/>
                    <span style={{marginLeft:30, marginTop:0, fontSize:28, fontWeight:"bold", width:50}}>{this.state.aufnahme}</span>
                </>
                }
                { !spielBeendet && this.state.darfPlayerLegZuruecksetzen &&
                    <Button label="Zurücksetzen" icon="pi pi-trash" onClick={() => this.sendUndoLegEnd()}
                            style={{marginLeft: 10}}/>
                }
            </div>
            { this.isPlayerActive(this.props.playerid) && !spielBeendet &&
            <InputAreaSmall merkeAufnahme={this.merkeAufnahme} sendAufnahme={this.sendAufnahme} aufnahme={this.state.aufnahme}/>
            }
            <div className="grid" style={{width: "100%", padding:0,margin:0}}>
                <PlayerSingleAreaSmall player={this.getPlayerScoreDto(this.props.playerid, false)}
                                       legAverage={legAveragePlayer}
                                       showCheckout={true}
                                       modusId={this.state.modusId}
                                       togo={this.getToGoCurrentPlayer()}
                                       isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid === this.props.playerid}/>
                <PlayerSingleAreaSmall player={this.getPlayerScoreDto(this.props.playerid, true)}
                                       legAverage={legAverageGegner}
                                       showCheckout={false}
                                       modusId={this.state.modusId}
                                       togo={this.getToGo(this.props.playerid, true)}
                                       isLegBeginner={!mussAusgebulltWerden && this.state.beginnerLegPlayerid !== this.props.playerid}/>
            </div>
            {!spielBeendet ?
                this.getScoreArea(anzScores)
                :
                this.gameOverAreaSmall()
            }
            <AnzahlWuerfeDialog visible={this.state.showAnzahlWuerfe} sendAnzahlWuerfe={this.sendAnzahlWuerfe} callBackOnClose={() => this.setState({showAnzahlWuerfe:false})}/>
        </div>;
    }

    render() {
        if (this.state.players === undefined) {
            return <div>Dein Spiel ist noch nicht geladen...</div>;
        }

        let existiertEinGewinner = this.existiertEinGewinner();
        let existiertUnentschieden = this.existiertUnentschieden();
        const anzScoresPlayer1 = existiertEinGewinner || existiertUnentschieden ? 0 : this.getGameDetails(this.props.playerid, false).scoreDtos.length;
        const anzScoresPlayer2 = existiertEinGewinner || existiertUnentschieden ? 0 : this.getGameDetails(this.props.playerid, true).scoreDtos.length;
        const anzScores = anzScoresPlayer1 > anzScoresPlayer2 ? anzScoresPlayer1 : anzScoresPlayer2;

        if( this.isMobileDevice()) {
            return this.renderMobilDevice(anzScores, existiertEinGewinner || existiertUnentschieden);
        } else {
            return this.renderPC(anzScores, existiertEinGewinner || existiertUnentschieden);
        }
    }

    setWebcamMessageUnsichtbar(e) {
        this.setState({showWebcamHinweis:false});
        e.stopPropagation();
    }

    getScoreArea(anzScores) {
        let legAveragePlayer = this.getGameDetails(this.props.playerid, false).legAverage;
        let legAverageGegner = this.getGameDetails(this.props.playerid, true).legAverage;
        let mobileDevice = this.isMobileDevice();
        let showStatistik = this.state.videoAnzeigeart === 1 && !this.isMobileDevice();
        let width = this.state.videoAnzeigeart === 1 ? "100%" : "50%";
        let style = mobileDevice ? {} : {borderLeft: "2px solid black", borderRight: "2px solid black"};
        return <div className="grid" style={{backgroundColor: "#e6f2ff", padding:0, margin:0, height:"100%", width:width}}>
            {showStatistik && <StatistikArea orientation="left" player={this.getPlayerScoreDto(this.props.playerid, false)} legAverage={legAveragePlayer}/>}
            <div className="col" style={style}>
                <ScoreHeader fontSize={mobileDevice ? 14 : 20}/>
                <div className="grid"
                     style={{backgroundColor: "#e6f2ff"}}>
                    <div className="col">
                        <ScoreArea key={"score_area_" + this.props.playerid}
                                   scores={this.getGameDetails(this.props.playerid, false).scoreDtos}
                                   playerid={this.props.playerid}
                                   changeAufnahmeAllowed={true} changeScore={this.changeScore} anzScores={anzScores}
                                   mobileDevice={mobileDevice}/>
                    </div>
                    <div className="col-fixed" style={{width: 70, margin: 0, padding: 0}}>
                        <WurfArea anzScores={anzScores}  mobileDevice={mobileDevice}/>
                    </div>
                    <div className="col">
                        <ScoreArea key={"score_area_gegner"} scores={this.getGameDetails(this.props.playerid, true).scoreDtos}
                                        changeAufnahmeAllowed={false} anzScores={anzScores} mobileDevice={mobileDevice}/>
                    </div>
                </div>
            </div>
            {(!mobileDevice && this.state.videoAnzeigeart === 1) && <StatistikArea orientation="right" player={this.getPlayerScoreDto(this.props.playerid, true)}  legAverage={legAverageGegner}/>}
        </div>;
    }

    getGameDetails(playerid, opposite) {
        let playerScoreDto = this.getPlayerScoreDto(playerid, opposite);
        for (const gameDetailResultDto of playerScoreDto.gameDetailResultDtos) {
            if (!gameDetailResultDto.abgeschlossen) {
                return gameDetailResultDto;
            }
        }
        return undefined;
    }

    getPlayerScoreDtoByPlayers(players, playerid, opposite) {
        for (const player of players) {
            if (opposite && player.playerid !== playerid) {
                return player;
            }
            if (!opposite && player.playerid === playerid) {
                return player;
            }
        }
        return undefined;
    }

    getPlayerScoreDto(playerid, opposite) {
        return this.getPlayerScoreDtoByPlayers(this.state.players, playerid, opposite);
    }

    existiertUnentschieden() {
        return this.state.unentschieden !== null && this.state.unentschieden !== undefined && this.state.unentschieden;
    }

    existiertEinGewinner() {
        return this.state.gewonnenPlayerid !== null && this.state.gewonnenPlayerid !== undefined;
    }

    getToGo(playerid, opposite) {
        if( this.existiertEinGewinner() || this.existiertUnentschieden()) {
            return 0;
        }
        const gameDetail = this.getGameDetails(playerid, opposite);
        return gameDetail.scoreDtos[gameDetail.scoreDtos.length - 1].togo;
    }

    isPlayerActive(playerid) {
        return this.getPlayerScoreDto(playerid, false).active;
    }

    mussAusgebulltWerden() {
        let player1idAktiv = this.getPlayerScoreDto(this.props.playerid, false).active;
        let player2idAktiv = this.getPlayerScoreDto(this.props.playerid, true).active;
        return !player1idAktiv && !player2idAktiv;
    }

    changeAufnahme(e) {
        if (!isNaN(e.target.value) && e.target.value.length <= 3) {
            this.setState({aufnahme: e.target.value})
        }
    }

    changeAutomataufnahme(e, counter) {
        if (!isNaN(e.target.value) && e.target.value.length <= 3) {
            let automatenwerte = this.state.automatenwerte;
            automatenwerte[counter] = e.target.value;
            this.setState({automatenwerte: automatenwerte})
        }
    }

    setCookieValue(name, value) {
        const { cookies } = this.props;
        var date = new Date();
        date.setDate(date.getDate() + 365);
        let options = {expires: date, path: '/'};
        cookies.set(name, value, options);
    }

    gameOverAreaSmall() {
        let label = "";
        if ( this.existiertEinGewinner()) {
            label = "Spieler "+ this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername +" hat gewonnen!";
        } else if( this.existiertUnentschieden()) {
            label = "Das Spiel endet unentschieden!";
        }

        let folgeGameId = this.state.folgeGameId;
        let aktullerUser = this.getPlayerScoreDto(this.props.playerid, false);
        let gegner = this.getPlayerScoreDto(this.props.playerid, true);
        let showRematch = gegner.userid !== "GAST" && aktullerUser.userid !== "GAST"&& !this.state.doppelMatch;
        let showRating = !this.state.doppelMatch;

        return <div className="grid" style={{height:"100%", width:"100%", fontSize:15, color:"#77b300", fontWeight:"bold", marginTop:20, padding:20}}>
            <div className="col">
                {label}
                <br/>
                {folgeGameId !== undefined && folgeGameId !== null && <>
                    <br/>
                    Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                    <br/>
                    <br/>
                    <img src="/images/dart_select.png" alt="Spiel starten" style={{cursor:"pointer", width: 80, marginLeft:30}} onClick={() => this.starteFolgeGame()}/>
                    <br/>
                </>
                }
                <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                {showRating && <ScoringRatingArea gameId={this.state.gameid} playerId={this.props.playerid}/>}
                {showRematch &&
                <div style={{marginTop:20}}>
                    <NavLink to={NAVIGATION_ITEM.GAME_OVERVIEW.route + "?gegnerId=" + gegner.userid + "&username=" + gegner.username} style={{textDecoration: "none"}}>
                        <Button label="Rematch starten" icon="pi pi-globe" tooltip="Rematch starten"
                                onClick={() => ""} style={{margin: 5}}/>
                    </NavLink>
                </div>
                }
            </div>

        </div>;
    }

    gameOverArea() {
        let label = "";
        if ( this.existiertEinGewinner()) {
            label = "Spieler "+ this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername +" hat gewonnen!";
        } else if( this.existiertUnentschieden()) {
            label = "Das Spiel endet unentschieden!";
        }

        let folgeGameId = this.state.folgeGameId;
        let aktullerUser = this.getPlayerScoreDto(this.props.playerid, false);
        let gegner = this.getPlayerScoreDto(this.props.playerid, true);
        let showRematch = gegner.userid !== "GAST" && aktullerUser.userid !== "GAST" && !this.state.doppelMatch;
        let showRating = !this.state.doppelMatch;

        return <div className="grid" style={{height:"100%", width:"100%", padding:0, margin:0}}>
            {this.state.videoAnzeigeart === 1 && <StatistikArea player={this.getPlayerScoreDto(this.props.playerid, false)}/>}
            <div className="col" style={{color:"#77b300", fontSize:25}}>
                {label}
                <br/>
                {folgeGameId !== undefined && folgeGameId !== null && <>
                    <br/>
                    Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                    <br/>
                    <br/>
                    <img src="/images/dart_select.png" alt="Spiel starten" style={{cursor:"pointer", width: 80, marginLeft:30}} onClick={() => this.starteFolgeGame()}/>
                    <br/>
                    </>
                }
                <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                {showRating && <ScoringRatingArea gameId={this.state.gameid} playerId={this.props.playerid}/>}
                {showRematch &&
                    <div style={{marginTop:20}}>
                        <NavLink to={NAVIGATION_ITEM.GAME_OVERVIEW.route + "?gegnerId=" + gegner.userid + "&username=" + gegner.username} style={{textDecoration: "none"}}>
                            <Button label="Rematch starten" icon="pi pi-globe" tooltip="Rematch starten"
                                    onClick={() => ""} style={{margin: 5}}/>
                        </NavLink>
                    </div>
                }
            </div>
            {this.state.videoAnzeigeart === 1 && <StatistikArea player={this.getPlayerScoreDto(this.props.playerid, true)}/>}
        </div>;
    }

    starteFolgeGame() {
        this.setState({gameId: this.state.folgeGameId, bullabgeschickt:false});
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
        this.websocketConnect(this.state.folgeGameId);
    }

    changeAnzeigeeinstellung(value) {
        this.setState({anzeigeeinstellung: value});
        this.setCookieValue('darthelfer_scoring_anzeigeeinstellung', value);
    }

    isSetModus() {
        return this.state.sets && this.state.sets > 1;
    }

    getModusBeschreibung() {
        let meetingKey = "";
        if( this.state.meetingKey !== null && this.state.meetingKey !== undefined) {
            meetingKey = "Key: " + this.state.meetingKey + " - ";
        }

        let modus = getModusNameById(this.state.modusId);
        let setBeschreibung = "";
        if( this.isSetModus()) {
            setBeschreibung = " - ft sets: " + this.state.sets;
        }
        if( this.state.firstToLegs) {
            return meetingKey + "Modus:" + modus + setBeschreibung + " 'first to legs':" + this.state.firstToLegs;
        }
        return meetingKey + "Modus:" + modus + setBeschreibung + " 'best of legs':" + this.state.bestOfLegs;
    }

    getToGoCurrentPlayer() {
        if( this.getToGo(this.props.playerid, false) - this.state.autodartsValue < 0 ) {
            return this.getToGo(this.props.playerid, false);
        }
        return this.getToGo(this.props.playerid, false) - this.state.autodartsValue;
    }
}

ScoringX01View.propTypes = {
    gameid: PropTypes.string.isRequired,
    playerid: PropTypes.string.isRequired,
    autodartsBoardId: PropTypes.string,
    showChat: PropTypes.bool.isRequired,
    changeShowChat: PropTypes.func.isRequired,
    cookies: instanceOf(Cookies).isRequired,
};

export default ScoringX01View;
