import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ConfirmKennwortDialog from "../../../general/ConfirmKennwortDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";


export default function PublicTournamentRegistrationOptionIdShort({tournamentId, isParticipantsMailConfirmation, passwordRequired, onRefresh}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [idShort, setIdShort] = useState("");

    const [message, setMessage] = useState("");
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [showTeilnehmenButton, setShowTeilnehmenButton] = useState(true);

    const [showKennwortDialog, setShowKennwortDialog] = useState(false);
    const [kennwort, setKennwort] = useState("");

    const teilnehmen = () => {
        setErrorMessage(undefined);
        setShowInfoMessage(false);
        let message = validationMessage();
        if (message !== "") {
            return;
        }
        if (passwordRequired && kennwort === "") {
            setShowKennwortDialog(true);
            return
        }

        let data = {
            idShort: idShort,
            kennwort: kennwort,
            tournamentId: tournamentId
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/tournamentteilnehmenIdShort", data, message => {
            if (message === "") {
                setInfoMessage("Teilnahme war erfolgreich!");
                setShowInfoMessage(true);
                setShowKennwortDialog(false);
                setKennwort("");
                setShowTeilnehmenButton(false);
                onRefresh();
            } else {
                setErrorMessage(message);
                setShowKennwortDialog(false);
                setShowInfoMessage("");
                setKennwort("");
            }
        }, responseNotOk => {
            setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            setShowKennwortDialog(false);
            setKennwort("");
        }, error => {
            setErrorMessage("Fehler beim Speichern: " + error.message);
            setShowKennwortDialog(false);
            setKennwort("");
        });
    }

    const validationMessage = () => {
        let message = "";
        if (idShort === "") {
            message = "SpielerID";
        }
        if (message !== "") {
            message = "Pflichtfelder füllen: " + message;
        }
        setMessage(message);
        return message;
    }

    return <div style={{marginBottom: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <h3>Daten eingeben und anmelden</h3>
        <div style={{marginTop:10}}>Pflichtfelder sind mit * gekennzeichnet.</div>
        <div className="grid" style={{textAlign: "center"}}>
            <div className="col-12 md:col-12 lg:col-12">
                <div style={{marginTop: 30, display: "flex"}}>
                    <span className="p-float-label" style={{marginRight: 10}}>
                        <InputText id="idShortText" value={idShort}
                                   onChange={(e) => setIdShort(e.target.value)} style={{width:250}}/>
                        <label htmlFor="idShortText" style={{ fontWeight: "bold"}}>SpielerID:</label>
                    </span>
                </div>
            </div>
        </div>
        {message !== "" && <div style={{marginTop: 30, display: "flex", color: "red"}}>
            {message}
        </div>}
        {showInfoMessage && isParticipantsMailConfirmation !== null && isParticipantsMailConfirmation && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
            Du erhälst umgehend eine Email. Bitte bestätige den Bestätigungslink in der Email.
            <br/><br/>
            ACHTUNG! Prüfe bitte deinen SPAM-Ordner.
            <br/><br/>
            Die Mail ist 24 Stunden gültig. Sofern du die Teilnahme innerhalb dieser Zeit nicht bestätigst, wirst du
            vom System automatisch wieder abgemeldet.
        </div>}
        {showInfoMessage && (isParticipantsMailConfirmation === null || !isParticipantsMailConfirmation) && <div style={{marginTop: 30, color: "green", fontWeight: "bold", fontSize: 18}}>
            Deine Anmeldung war erfolgreich.
        </div>}
        {showTeilnehmenButton && <div style={{marginTop: 30, display: "flex"}}>
            <Button label="Anmelden" icon="pi pi-plus" onClick={() => teilnehmen()}
                    style={{height: 40, width: 350}}/>
        </div>}
        {showKennwortDialog && <ConfirmKennwortDialog visible={true}
                                                      header="Turnierkennwort eingeben."
                                                      textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                                      changeKennwort={(kennwort) => setKennwort(kennwort)}
                                                      callBackOnAbbrechen={() => setShowKennwortDialog(false)}
                                                      callBackOnAbsenden={() => teilnehmen()}/>}

    </div>
}

