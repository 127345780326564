import {connect} from 'react-redux'
import Administration from "../../../components/views/liga/Administration";

const mapStateToProps = (state, ownProps) => ({
    userId: state.user.id,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Administration)