class MandantType {
    constructor(bezeichnung) {
        this.bezeichnung = bezeichnung;
    }
}

export const MANDANTTYPES = {
    DEFAULT: new MandantType("default"),
    TEAM: new MandantType("team"),
};


export function getMandantType(bezeichnung) {
    if (MANDANTTYPES.DEFAULT.bezeichnung === bezeichnung) {
        return MANDANTTYPES.DEFAULT;
    } else if (MANDANTTYPES.TEAM.bezeichnung === bezeichnung) {
        return MANDANTTYPES.TEAM;
    }
    return undefined;
}