import * as React from 'react';
import {useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

export default function PlatzierungTable({platzierungen}) {
    let [selectedIdShort, setSelectedIdShort] = useState(undefined);

    if( platzierungen.length === 0) {
        return <div style={{padding:20, color:"white", textAlign:"left"}}>Keine Daten vorhanden.</div>
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18};
        for(let i = 0; i < platzierungen.length; i++) {
            let platzierung = platzierungen[i];
            let style;
            if( selectedIdShort !== undefined && selectedIdShort === platzierung.idShort) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }
            returnRows.push(  <TableRow key={platzierung.idShort} onMouseOver={() => setSelectedIdShort(platzierung.idShort)}
                                        onClick={() => setSelectedIdShort(platzierung.idShort)}>
                <TableCell style={style}>{platzierung.platz}.</TableCell>
                <TableCell style={style}>{platzierung.name}</TableCell>
            </TableRow>)
            background = !background;
        }
        return returnRows;
    }

    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:100}}>Platz</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20}}>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
