import React from 'react'

class Impressum extends React.Component {

    render() {
        return <div style={{backgroundColor: "white", color: "black", textAlign:"center"}}>
            {this.getImpressum()}
        </div>
    }

    getImpressum() {
        return <div>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#e0e0eb", marginBottom:20}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Impressum
                </div>
            </div>
            <p
                className="adsimple-321126352"> Informationspflicht
                laut § 5
                TMG.
            </p>
            <p className="adsimple-321126352">next level software (Roland Benning & Heiko Förster, Software Gbr)<br/>Nikolaus-Heilmann-Straße
                20, <br/>97447 Frankenwinheim, <br/>Deutschland</p>
            <p className="adsimple-321126352">
            </p>
            <p className="adsimple-321126352">
                <strong>E-Mail:</strong> <a href="mailto:darthelfer@nl-soft.com">darthelfer@nl-soft.com</a>
            </p>
            <p className="adsimple-321126352">
                <strong>Berufsbezeichnung:</strong> Softwarelösungen, Webdesigner, Beratung</p>
            <p className="adsimple-321126352">
                <strong>Vertreten durch die Gesellschafter</strong>
                <br/>Roland Benning und Heiko Förster</p>
            <p>Quelle: Erstellt mit dem <a title="Impressum Generator Deutschland"
                                           href="https://www.adsimple.de/impressum-generator/" 
                                           >Impressum Generator</a> von AdSimple in Kooperation mit <a
                href="https://www.slashtechnik.de"   title="">slashtechnik.de</a>
            </p>
            <h2 className="adsimple-321126352">EU-Streitschlichtung</h2>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir
                Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br/>
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
                Kommission unter <a className="adsimple-321126352"
                                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
                                     rel="noopener">http://ec.europa.eu/odr?tid=321126352</a> zu richten.
                Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2 className="adsimple-321126352">Haftung für Inhalte dieser Website</h2>
            <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
                Informationen bereitzustellen. Laut Telemediengesetz <a className="adsimple-321126352"
                                                                        href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321126352"
                                                                        rel="noopener" >(TMG) §7
                    (1)</a> sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen,
                nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller
                Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als
                Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder
                gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                Tätigkeit hinweisen.</p>
            <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen
                nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im
                Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt. </p>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu
                kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im
                Impressum.</p>
            <h2 className="adsimple-321126352">Haftung für Links auf dieser Website</h2>
            <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind.
                Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten
                hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort
                entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie
                finden die Kontaktdaten im Impressum.</p>
            <h2 className="adsimple-321126352">Urheberrechtshinweis</h2>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der
                Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten,
                vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls
                notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
                verfolgen.</p>
            <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu
                kontaktieren.</p>
            <h2 className="adsimple-321126352">Bildernachweis</h2>
            <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
            <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
            <ul className="adsimple-321126352">
                <li className="adsimple-321126352">Fotograf Mustermann</li>
            </ul>
            <p>Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland"
                                           href="https://www.adsimple.de/datenschutz-generator/" 
                                           >Datenschutz Generator</a> von AdSimple in Kooperation mit <a
                href="https://www.hashtagbeauty.de"   title="">hashtagbeauty.de</a>
            </p>
        </div>;
    }
}

export default Impressum;