import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function DHDatePicker({value, changeValue, disabled}) {

    const [myValue, setMyValue] = useState(null);

    useEffect(() => {
        if( value === undefined) {
            return;
        }
        setMyValue(dayjs(value));
    },[value]);

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
        <MobileDatePicker value={myValue}
                              slotProps={{ actionBar: {
                                      actions: ['accept', 'clear', 'cancel']
                                  },
                                  textField: { size: 'small', placeholder:'' } }}
                              disabled={disabled}
                              onChange={(e) => {
                                  if( e === null) {
                                    changeValue(undefined);
                                  } else {
                                      let formattedValue = e.format('YYYY-MM-DD');
                                      formattedValue += "T00:00:00.000Z"
                                      console.log(formattedValue);
                                      changeValue(formattedValue);
                                  }
                              }} ampm={false}/>
    </LocalizationProvider>
}