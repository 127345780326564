import {NEW_STATISTIK_OVER_WEBSOCKET,} from "../actions/statistik";

function statistik(state = {
    dreissigtagestatistik: [],
}, action) {
    switch (action.type) {
        case NEW_STATISTIK_OVER_WEBSOCKET:
            return {...state, dreissigtagestatistik: action.value};
        default:
            return state
    }
}

export default statistik;