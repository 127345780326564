import React, {useState} from 'react'
import Turniergruppen from "../../tournament/Turniergruppen";
import PublicTournamentGroupRefreshComp from "./PublicTournamentGroupRefreshComp";
import Turnierbaum from "../../tournament/Turnierbaum";
import {Button} from "primereact/button";
import SchweizerSystemRunden from "../../tournament/SchweizerSystemRunden";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";


export default function PublicTournamentGroupsTab({tournament, gruppenTabellen, bestplatzierteDtos, boardGames, liveGames, gruppeModelWrapperListe}) {

    const [tableView, setTableView] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState(1);

    const isGruppenphaseSetModus = () => {
        let modusGruppenphase = tournament.tournamentModusEntities.find(element => element.modusTyp === "gruppenturnier");
        return modusGruppenphase.sets > 1;
    }

    const isSchweizerSystem = () => {
        return tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id;
    }

    if( isSchweizerSystem() ){
        return <SchweizerSystemRunden tournament={tournament}
                                      gruppenTabellen={gruppenTabellen}
                                      userId={undefined}
                                      boardGames={boardGames}
                                      liveGames={liveGames}
                                      isSetModus={isGruppenphaseSetModus()}
                                      onRefresh={() => ""}
                                      isReadOnly={true}/>
    }

    if( tournament.tournamentAuslosungEntities.length > 0 ) {
        let gruppenButtons = [];
        for(let gruppe = 1; gruppe <= tournament.anzahlGruppen; gruppe++) {
            gruppenButtons.push(<Button className={ selectedGroup === gruppe ? "" : "p-button-secondary"} label={gruppe} style={{width:100, marginRight:5, marginTop:5}} onClick={() => setSelectedGroup(gruppe)}/> );
        }
        let modelWrapper = gruppeModelWrapperListe.filter(wrapper => wrapper.gruppe === selectedGroup)[0];
        return <>
            <div style={{display:"flex", flexWrap:"wrap", backgroundColor:"#022836", padding:5}}>{gruppenButtons}</div>
            <Turnierbaum userId={undefined}
                         turnier={tournament}
                         tournamentGroup={selectedGroup}
                         userGames={[]}
                         setzliste={[]}
                         model={modelWrapper.model}
                         readonly={true}
                         boardGames={boardGames}
                         liveGames={liveGames}
                         tournamentBeschreibungen={modelWrapper.tournamentBeschreibungen}
                         loadData={() => ""}
            />

        </>
    }

    return <div style={{color:"white", padding:5, fontSize:16, backgroundColor: "#022836", minHeight: "100vh", height: "100%"}}>
        {!tableView && <PublicTournamentGroupRefreshComp tournament={tournament} boardGames={boardGames} liveGames={liveGames} gruppenTabellen={gruppenTabellen} onChangeView={() => setTableView(true)}/> }
        {tableView && <Turniergruppen showChangeView={true} onChangeView={() => setTableView(false)} turnier={tournament}
                                        isSetModus={isGruppenphaseSetModus()}
                                        gruppenTabellen={gruppenTabellen}
                                        bestplatzierteDtos={bestplatzierteDtos}
                                        tournamentsystem={""}
                                        userId={undefined}
                                        admin={false}
                                        boardGames={boardGames}
                                        liveGames={liveGames}
                                        refresh={() => ""}/>
        }
    </div>
}

