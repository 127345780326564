import React from 'react'

import TurnierKachel from "./TurnierKachel";
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import TurnierModelInitialXY from "./TurnierModelInitialXY";
import TurnierModelDoppelKoInitialXY from "./TurnierModelDoppelKoInitialXY";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";

import Linien from './turnierLinien';
import TurnierBoardAnzeige from "./TurnierBoardAnzeige";
import {isVerliererseiteByFeld, isVerliererseiteByModel} from "./TurnierbaumHelper";
import ChangeModusOrBoardKODialog from "./ChangeModusOrBoardKODialog";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import tournament from "../../../reducers/tournament";

class Turnierbaum extends React.Component {

    constructor() {
        super();

        this.state = {farbmodus: "light", selectedPlayerId: undefined};

        this.getModel = this.getModel.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.callBackFarbmodus = this.callBackFarbmodus.bind(this);
        this.showError = this.showError.bind(this);
    }

    showInfo(message) {
        this.toast.clear();
        this.toast.show({severity: 'success', summary: 'Erfolgreich...', detail: message});
    }

    showWarn(message) {
        this.messages.clear();
        this.messages.show({severity: 'warn', sticky: 'true', summary: 'Achtung!!!', detail: message});
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({severity: 'error', sticky: 'true', summary: 'Fehler...', detail: message});
    }

    callBackFarbmodus(modus) {
        this.setState({farbmodus: modus});
    }

    getFeldXYWerte(werte, feld) {
        for (let y = 0; y < werte.length; y++) {
            let wert = werte[y];
            if (wert.feld === feld) {
                return wert;
            }
        }
        return undefined;
    }

    getUeberschriftArea(prefix, bezeichnung, x, y, ) {
        let falscherGruppeFolgeModusId = this.props.turnier.gruppeFolgeModusId === "dko";
        return <div key={"div_ueberschrift_" + prefix} style={{
            cursor: !falscherGruppeFolgeModusId && !this.props.readonly ? "pointer" : "",
            position: "absolute",
            fontSize: 16,
            width:200,
            border:"1px solid gray",
            backgroundColor: "#ac1c27",
            color:"white",
            top: y,
            left: x}}
             onClick={() => !falscherGruppeFolgeModusId && !this.props.readonly && this.setState({selectedModelPrefix: prefix, selectedModelPrefixBezeichnung: bezeichnung})}
        >{bezeichnung}{!falscherGruppeFolgeModusId && !this.props.readonly && <Button icon={PrimeIcons.PENCIL} style={{marginLeft:5, height:22, width:22}}/> }</div>
    }

    getUeberschriften() {
        let ueberschriften = [];
        let initialXYWerte = this.getInitialXYWerte();

        let x = initialXYWerte.x;
        for(let counter = 1; counter <= 100; counter++) {
            let beschreibung = this.props.tournamentBeschreibungen.filter(besch => besch.modelFeldPrefix === (counter + "-") )[0];
            if( beschreibung === undefined) {
                break;
            }
            ueberschriften.push(this.getUeberschriftArea(beschreibung.modelFeldPrefix, beschreibung.bezeichnung, x, 20));
            x = x + 220;
        }

        x = initialXYWerte.x - 220;
        for(let counter = 100; counter <= 1000; counter++) {
            let beschreibung = this.props.tournamentBeschreibungen.filter(besch => besch.modelFeldPrefix === (counter + "-") )[0];
            if( beschreibung === undefined) {
                break;
            }
            ueberschriften.push(this.getUeberschriftArea(beschreibung.modelFeldPrefix, beschreibung.bezeichnung, x, 20));
            x = x - 220;
        }

        return ueberschriften;
    }

    ermittleFeldVonFelder(feld, felder) {
        for (const feldTmp of felder) {
            if (feldTmp.feld === feld) {
                return feldTmp;
            }
        }
        return undefined;
    }

    ermittleXYWertFuerKache(model, naechstesYAufEbene1, initialXYWerte, felder) {
        let xywert;
        if (model.startknoten) {
            let x = initialXYWerte.x;
            xywert = {x: x, y: naechstesYAufEbene1};
        } else {
            let tmpModelTyp = isVerliererseiteByModel(model) ? "VERLIERER" : "GEWINNER";
            let parent1Typ = isVerliererseiteByFeld(model.parent1) ? "VERLIERER" : "GEWINNER";
            let parent2Typ = undefined;
            if (model.parent2) {
                parent2Typ = isVerliererseiteByFeld(model.parent2) ? "VERLIERER" : "GEWINNER";
            }
            let wertParent1 = this.ermittleFeldVonFelder(model.parent1, felder);
            let newX = wertParent1.x;
            if( tmpModelTyp === "GEWINNER") {
                newX = newX + 220;
            } else {
                newX = newX - 220;
            }
            if (parent1Typ === parent2Typ) {
                let wertParent2 = this.ermittleFeldVonFelder(model.parent2, felder);
                let unterschied = (wertParent2.y - wertParent1.y) / 2;
                xywert = {x: newX, y: wertParent1.y + unterschied};
            } else {
                xywert = {x: newX, y: wertParent1.y};
            }
        }
        return xywert;
    }

    getGameZumScorenZumFeld(feld) {
        if( this.props.userGames === undefined || this.props.userGames === null || this.props.userGames.length === 0 ||
            this.props.turnier.games === undefined || this.props.turnier.games === null || this.props.turnier.games.length === 0) {
            return undefined;
        }
        let userGame = this.props.userGames.find(game => game.tournamentGameId === feld);
        if( userGame !== undefined ) {
            return this.props.turnier.games.find(game => game.turniergameid === userGame.tournamentGameId);
        } else {
            return undefined;
        }
    }

    getEbenenen() {
        let felder = [];

        if (this.props.turnier === undefined) {
            return "";
        }
        let objekte = [];
        let model = this.getModel();

        let initialXYWerte = this.getInitialXYWerte();
        let naechstesYAufEbene1 = initialXYWerte.y;

        let auslosungEntities = undefined
        let ergebnisEntities = undefined
        if( this.props.turnier.tournamentAuslosungEntities !== undefined) {
            auslosungEntities = this.props.turnier.tournamentAuslosungEntities.filter(tmp => tmp.gruppe === this.props.tournamentGroup);
        }
        if( this.props.turnier.tournamentErgebnisEntities !== undefined) {
            ergebnisEntities = this.props.turnier.tournamentErgebnisEntities.filter(tmp => tmp.gruppe === this.props.tournamentGroup);
        }

        let feldXYWerte = [];
        for (let i = 0; i < model.length; i++) {
            let tmpModel = model[i];
            if( tmpModel.final2 || tmpModel.feld === "spielumplatzdrei" ) {
                continue;
            }
            let xywert = this.ermittleXYWertFuerKache(tmpModel, naechstesYAufEbene1, initialXYWerte, felder);
            if (tmpModel.startknoten) {
                naechstesYAufEbene1 = naechstesYAufEbene1 + initialXYWerte.interval;
            }

            let xwert = xywert.x;
            let ywert = xywert.y;

            // wenn das Model keinen child hat, dann muss der ywert vom parent genommen werden
            // da es sich um das Siegerendkästchen handelt und dieses soll genau daneben angezeigt werden.
            if (tmpModel.endknoten) {
                let parent1FeldXYWert = this.getFeldXYWerte(feldXYWerte, tmpModel.parent1);
                ywert = parent1FeldXYWert.y;
            }

            feldXYWerte.push({feld: tmpModel.feld, x: xwert, y: ywert});
            if (tmpModel.ueberschrift !== undefined) {
                objekte.push(<div key={"key_ueberschrift_" + tmpModel.feld} style={{
                    position: "absolute",
                    fontSize: "14px",
                    top: ywert - 32,
                    left: xwert,
                    width: 200,
                    textAlign: tmpModel.ueberschriftAlign,
                    color: "gray",
                }}>{tmpModel.ueberschrift}</div>);
            }
            let gameZumScorenZumFeld = this.getGameZumScorenZumFeld(tmpModel.feld);
            let boardGame = this.getBoardGame(tmpModel.feld, ergebnisEntities);
            objekte.push(<div key={"key_turnierkachel_" + this.props.turnier.id + "_" + tmpModel.feld}>
                        <TurnierKachel model={tmpModel}
                                        models={model}
                                        boardGame={boardGame}
                                        liveGames={this.props.liveGames}
                                        onPlayerSelect={(playerId) => this.setState({selectedPlayerId: playerId})}
                                        selectedPlayerId={this.state.selectedPlayerId}
                                        game={gameZumScorenZumFeld}
                                        tournament={this.props.turnier}
                                        tournamentGroup={this.props.tournamentGroup}
                                        userId={this.props.userId}
                                        readonly={this.props.readonly}
                                        tournamentstatus={this.props.turnier.tournamentstatus}
                                        spielerliste={this.props.turnier.tournamentPlayerEntities !== undefined ? this.props.turnier.tournamentPlayerEntities : undefined}
                                        auslosung={auslosungEntities}
                                        ergebnisse={ergebnisEntities}
                                        callBackLoadData={this.props.loadData}
                                        x={xwert} y={ywert-10}/>
                            {boardGame !== undefined &&
                                <TurnierBoardAnzeige boardGameName={boardGame.boardBezeichnung} xValue={xwert + 100} yValue={ywert-25} textAlign="right"/>
                            }
                            {boardGame !== undefined && boardGame.schreiberId !== undefined &&
                                <TurnierBoardAnzeige boardGameName={boardGame.schreiberName} xValue={xwert} yValue={ywert-25} textAlign="left"/>
                            }
            </div>);

            if( this.props.turnier.spielUmPlatz3 && tmpModel.finale) {
                // spiel um Platz 3 muss noch geschrieben werden.
                let modelZumSpielUmPlatz3 = model.filter(t => t.feld === "spielumplatzdrei")[0];
                let gameZumScorenZumFeld = this.getGameZumScorenZumFeld(modelZumSpielUmPlatz3.feld);
                let boardGameZumSpielUmPlatz3 = this.getBoardGame(modelZumSpielUmPlatz3.feld, ergebnisEntities);

                let beschreibung = this.props.tournamentBeschreibungen.filter(besch => besch.modelFeldPrefix === "spielumplatzdrei" )[0];

                objekte.push(this.getUeberschriftArea(beschreibung.modelFeldPrefix, beschreibung.bezeichnung, xwert + 50, ywert + 100));

                objekte.push(<div key={"key_turnierkachel_" + tmpModel.feld}>
                    <TurnierKachel model={modelZumSpielUmPlatz3}
                                   models={model}
                                   boardGame={boardGameZumSpielUmPlatz3}
                                   liveGames={this.props.liveGames}
                                   onPlayerSelect={(playerId) => this.setState({selectedPlayerId: playerId})}
                                   selectedPlayerId={this.state.selectedPlayerId}
                                   game={gameZumScorenZumFeld}
                                   tournament={this.props.turnier}
                                   tournamentGroup={this.props.tournamentGroup}
                                   userId={this.props.userId}
                                   readonly={this.props.readonly}
                                   tournamentstatus={this.props.turnier.tournamentstatus}
                                   spielerliste={this.props.turnier.tournamentPlayerEntities !== undefined ? this.props.turnier.tournamentPlayerEntities : undefined}
                                   auslosung={this.props.turnier.tournamentAuslosungEntities !== undefined ? this.props.turnier.tournamentAuslosungEntities : undefined}
                                   ergebnisse={this.props.turnier.tournamentErgebnisEntities !== undefined ? this.props.turnier.tournamentErgebnisEntities : undefined}
                                   callBackLoadData={this.props.loadData}
                                   x={xwert + 50} y={ywert + 130}/>
                    {boardGameZumSpielUmPlatz3 !== undefined &&
                        <TurnierBoardAnzeige boardGameName={boardGameZumSpielUmPlatz3.boardBezeichnung} xValue={xwert + 150} yValue={ywert+190} textAlign="right"/>
                    }
                    {boardGameZumSpielUmPlatz3 !== undefined && boardGameZumSpielUmPlatz3.schreiberId !== undefined &&
                        <TurnierBoardAnzeige boardGameName={boardGameZumSpielUmPlatz3.schreiberName} xValue={xwert + 50} yValue={ywert+190} textAlign="left"/>
                    }
                </div>);
            }

            felder.push({feld: tmpModel.feld, x: xwert, y: ywert, model: tmpModel});
        }

        for (const feld of felder) {
            objekte = objekte.concat(<div key={"key_linien_" + feld.feld}><Linien feld={feld} felder={felder}/></div>);
        }
        return objekte;
    }

    getBoardGame(feld, ergebnisEntities) {
        if( this.props.boardGames === null || this.props.boardGames === undefined) {
            return undefined;
        }
        let ergebnisseZumFeld = ergebnisEntities.filter(ergebnis => ergebnis.feld === feld);
        if( ergebnisseZumFeld.length === 0) {
            return undefined;
        }
        let ergebnisId = ergebnisseZumFeld[0].id;
        for (const boardGame of this.props.boardGames) {
            if( boardGame.tournamentId === this.props.turnier.id && boardGame.tournamentGameId === ergebnisId) {
                return boardGame;
            }
        }
        return undefined;
    }

    getTreeHeight() {
        if( this.getModel() === null) {
            return 500;
        }
        if( this.getModel().length >= 1024) {
            return 42000;
        } else if( this.getModel().length >= 512) {
            return 21100;
        } else if( this.getModel().length >= 256) {
            return 11200;
        } else if( this.getModel().length >= 128) {
            return 5600;
        } else if( this.getModel().length >= 64) {
            return 2800;
        } else if( this.getModel().length >= 32) {
            return 1400;
        } else if( this.getModel().length >= 16) {
            return 700;
        } else if( this.getModel().length >= 8) {
            return 400;
        } else if( this.getModel().length >= 4) {
            return 300;
        } else {
            return 10500;
        }
    }


    render() {
        if( this.props.turnier === null || this.props.turnier === undefined || this.props.turnier.tournamentPlayerEntities === null) {
            return "Keine Daten vorhanden";
        }
        let initialXYWerte = this.getInitialXYWerte();
        let maxXValue = initialXYWerte !== undefined ? initialXYWerte.maxX + 220 : 5000;
        let treeHeight = this.getTreeHeight();

        // dark mode = backgroundColor: "#222" light backgroundColor: "#eee"
        return <div key={"turnierbaum_obersteebene"} style={{width:"100%", minWidth:"100%"}}>
            <div key={"turnierbaum_2_ebene"} style={{
                position: "relative",
                top: "0",
                left: "0",
                textAlign: "center",
                align: "center",
                color: "white",
                padding: "0px",
                width: maxXValue + 10000,
                height: treeHeight,
                backgroundColor: "#022836",
            }}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                {this.state.selectedModelPrefix !== undefined && <ChangeModusOrBoardKODialog tournamentId={this.props.turnier.id}
                                                                                             eventOperatorId={this.props.turnier.eventOperatorId}
                                                                                             prefix={this.state.selectedModelPrefix}
                                                                                             bezeichnung={this.state.selectedModelPrefixBezeichnung}
                                                                                             onClose={() => this.setState({selectedModelPrefix: undefined, selectedModelPrefixBezeichnung: undefined})} />
                }
                {this.getEbenenen()}
                {this.getUeberschriften()}
            </div>
        </div>
    }

    getModel() {
        return this.props.model;
    }

    getInitialXYWerte() {
        if (this.props.turnier === undefined) {
            return undefined;
        }
        if (this.props.turnier.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id || this.props.turnier.gruppeFolgeModusId === "dko") {
            let anzahl = this.props.model.filter(tmp => tmp.parent1 === null && tmp.parent2 === null && tmp.child !== null).length;
            let players = anzahl * 2;
            if (players <= 4) {
                return TurnierModelDoppelKoInitialXY.startwerte4();
            } else if (players <= 8) {
                return TurnierModelDoppelKoInitialXY.startwerte8();
            } else if (players <= 16) {
                return TurnierModelDoppelKoInitialXY.startwerte16();
            } else if (players <= 32) {
                return TurnierModelDoppelKoInitialXY.startwerte32();
            } else if (players <= 64) {
                return TurnierModelDoppelKoInitialXY.startwerte64();
            } else if (players <= 128) {
                return TurnierModelDoppelKoInitialXY.startwerte128();
            } else if (players <= 256) {
                return TurnierModelDoppelKoInitialXY.startwerte256();
            }
        } else if (this.props.turnier.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
            return TurnierModelInitialXY.startwerte();
        }
    }
}

Turnierbaum.propTypes = {
    userId: PropTypes.string.isRequired,
    readonly: PropTypes.bool.isRequired,
    model: PropTypes.array.isRequired,
    turnier: PropTypes.object.isRequired,
    tournamentGroup: PropTypes.number.isRequired,
    loadData: PropTypes.func.isRequired,
    boardGames: PropTypes.array,
    liveGames: PropTypes.array,
    userGames: PropTypes.array.isRequired,
    games: PropTypes.array,
    tournamentBeschreibungen: PropTypes.array,
};

export default Turnierbaum
