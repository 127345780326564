import React from 'react'
import PropTypes from "prop-types";

import {Panel} from 'primereact/panel';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

const INITIAL_STATE = {
    id: "",
    currentPassword: "",
    password: "",
    password2: ""
};


class SettingsUserdaten extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE, id: props.id};

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    save() {
        this.messages.clear();
        if( this.state.password !== this.state.password2) {
            this.showError("Die beiden neuen Passwörter stimmen nicht überein.");
            return;
        }
        let data = {currentPassword: this.state.currentPassword, newPassword: this.state.password};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.user,
            data,
            json => {
                this.showInfo("Speichern der neuen Zugangsdaten war erfolgreich!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        return <div>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <Panel header="Kennwort ändern" style={{textAlign: "left"}}>
                <div className="grid" style={{marginTop: "20px"}}>
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Aktuelles Passwort:</div>
                    <div className="col"><Password name="currentPassword" value={this.state.currentPassword}
                                                      onChange={(e) => this.handleChange(e)} feedback={false}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort:</div>
                    <div className="col"><Password name="password" value={this.state.password}
                                                     onChange={(e) => this.handleChange(e)}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort bestätigen:</div>
                    <div className="col"><Password name="password2" value={this.state.password2}
                                                     onChange={(e) => this.handleChange(e)}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                    <div className="col"><Button label="Speichern" onClick={() => this.save()}/></div>
                </div>
            </Panel>
        </div>
    }
}

SettingsUserdaten.propTypes = {
    id : PropTypes.string.isRequired
};

export default SettingsUserdaten;