import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

const BOARD_WIDTH = 100;
const BOARD_HEIGHT = 100;
const DART_WIDTH = 2;
const DART_HEIGHT = 2;
const DEFAULT_RIM = 11.25;

export default function BoardComponent({ throwDataPlayer, throwDataGegner, onClose }) {

    let [showGegner, setShowGegner] = useState(true);

    let [svgDom, setSvgDom] = useState();
    let [actives, setActives] = useState([]);

    useEffect(() => {
        let throwData;
        if( showGegner) {
            throwData = throwDataGegner;
        } else {
            throwData = throwDataPlayer;
        }
        resetDarts();
        for (let i = 0; i < throwData.length; i++) {
            renderDart(throwData[i]);
        }
    }, [ showGegner, throwDataPlayer, throwDataGegner,  svgDom ]);

    function loadSvg() {
        const board = document.getElementById("board");
        if (board) {
            console.log("Loaded board");
            const tmp = board.contentDocument;
            if (tmp) {
                console.log("Access board content");
                setSvgDom(tmp);
            }
        }
    }

    function calcX(
        x,
        dartWidth = DART_WIDTH,
        boardWidth = BOARD_WIDTH,
        rim = DEFAULT_RIM,
    ) {
        const center = boardWidth / 2;
        const scaled = x * (center - rim);
        const pin = scaled + center - (dartWidth / 2);
        return pin;
    }

    function calcY(
        y,
        dartHeight = DART_HEIGHT,
        boardHeight = BOARD_HEIGHT,
        rim = DEFAULT_RIM,
    ) {
        const center = boardHeight / 2;
        const scaled = y * (center - rim) * -1;
        const pin = scaled + center - (dartHeight / 2);
        console.log("Y: " + y + " -> " + center + ", " + scaled + ", " + pin);
        return pin;
    }

    function resetDarts() {
        console.log("Reset darts: " + actives);
        for (let i = 1; i <= 3; i++) {
            const dart = document.getElementById("dart-" + i);
            if( dart ) {
                dart.style.display = "none";
                dart.title = "none";
            }
        }
        for (let name of actives) {
            const segment = svgDom?.getElementById(name);
            if (segment) {
                segment.classList.remove("active");
            }
        }
        setActives([]);
    }

    function renderDart(data) {
        if (data) {
            const dartNumber  = data.throwNumber || 1;
            const dart = document.getElementById("dart-" + dartNumber);
            if (dart) {
                const name = data.throw.segment.bed + "-" + data.throw.segment.name;
                console.log(
                    dartNumber + ": -> " + data.throw.segment.name + " | " + name,
                );
                dart.style.display = "inline";
                dart.style.left = calcX(data.throw.coords.x) + "%";
                dart.style.top = calcY(data.throw.coords.y) + "%";
                dart.title = data.throw.segment.name;
                const segment = svgDom?.getElementById(name);
                if (segment) {
                    segment.classList.add("active");
                    actives.push(name);
                    setActives(actives)
                }
            }
        }
    }

    return <Dialog baseZIndex={2000} header="Board" visible={true} modal={false}  resizable={true} style={{ width: 500, height: 550 }} onHide={() => onClose()}>
        <div className="page">
            <Button label="Zeige meine Würfe"
                    className={!showGegner ? "p-button-primary" : "p-button-secondary"}
                    onClick={() => setShowGegner(false)} style={{position:"absolute", bottom:5, left:5, zIndex:10000}}/>
            <Button label="Zeige Gegner"
                    className={showGegner ? "p-button-primary" : "p-button-secondary"}
                    onClick={() => setShowGegner(true)} style={{position:"absolute", bottom:5, right:20, zIndex:10000}}/>
            <div className="board">
                <object
                    id="board"
                    data="/images/dart-board.svg"
                  type="image/svg+xml"
                  onLoad={() => loadSvg()}
                >Hier sollte eine Dartscheibe erscheinen
                </object>
                <div id="dart-1" className="dart dart-1"></div>
                <div id="dart-2" className="dart dart-2"></div>
                <div id="dart-3" className="dart dart-3"></div>
            </div>
        </div>
    </Dialog>
}
