import React from "react";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DateUtil from "../../util/DateUtil";
import {Sidebar} from 'primereact/sidebar';
import {Button} from "primereact/button";
import PropTypes from "prop-types";


class TrainingAuswahlSaison extends React.Component {

    constructor(props) {
        super(props);
        this.state = {visible: false, selectedSaison: undefined};

        this.abbrechen = this.abbrechen.bind(this);
        this.auswaehlen = this.auswaehlen.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.visible !== prevState.visible) {
            //this.ladeDaten(this.state.von, this.state.bis);
        }
    }

    componentDidMount() {
        this.ladeDaten(this.state.von, this.state.bis)
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getsaisonen",
            json => {
                for(let i = 0; i < json.length; i++) {
                    let saison = json[i];
                    if( saison.active) {
                        this.auswaehlen(saison);
                    }
                }

                this.setState({...this.state, saisonen: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Saisonen: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Saisonen: " + error.message);
            })
    }

    abbrechen() {
        this.props.callbackOnDelete();
        this.setState({visible:false});
    }

    auswaehlen(saison) {
        this.props.callbackOnSelect(saison);
        this.setState({visible:false, selectedSaison: saison});
    }

    saisonLoeschen(saison) {
        this.props.callbackOnDelete();
        this.setState({visible:false, selectedSaison: undefined});
    }


    render() {
        let buttons = [];
        if( this.state.saisonen !== undefined) {
            for(let i = 0 ; i < this.state.saisonen.length; i++) {
                let saison = this.state.saisonen[i];
                let von = DateUtil.localDateTimeToDateString(saison.vondatum);
                let bis = DateUtil.localDateTimeToDateString(saison.bisdatum);
                let bezeichnung = von + " - " + bis;
                buttons.push(<Button key={saison.id} label={bezeichnung}
                        style={{marginRight: "5px", marginBottom:"5px", height: "50px", width: "240px"}}
                        onClick={() => this.auswaehlen(saison)}
                                     className="p-button-secondary"/>);
            }
        }

        let bezeichnung = "";
        if (this.state.selectedSaison !== undefined) {
            let von = DateUtil.localDateTimeToDateString(this.state.selectedSaison.vondatum);
            let bis = DateUtil.localDateTimeToDateString(this.state.selectedSaison.bisdatum);
            bezeichnung = von + "-" + bis;
        }


        return <div className="grid" style={{marginTop: '20px', marginBottom: "20px", textAlign: "left", wordWrap:"break-word"}}>
            <div className="col">
                <Button label="Saison auswählen" style={{marginRight: "5px"}}
                        onClick={(e) => this.setState({visible: true})}/>
                {this.state.selectedSaison !== undefined ?
                    <div style={{marginTop:"5px"}}>
                        <Button icon="pi pi-times"
                                onClick={() => this.saisonLoeschen()} style={{marginRight: "5px"}}/>
                        {bezeichnung}
                    </div>
                    : <div/>
                }
            </div>
            <Sidebar visible={this.state.visible} style={{width: "350px"}}
                     onHide={(e) => this.setState({visible: false})}
                     position="left" showCloseIcon={false}>
                <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                    {buttons}
                    <Button label="Abbrechen / Schliessen" className="p-button-danger"
                            style={{marginLeft: "5px", marginTop:"20px", height: "50px", width: "240px"}}
                            onClick={() => this.abbrechen()}/>
                </div>
            </Sidebar>
        </div>
    }
}


TrainingAuswahlSaison.propTypes = {
    visible: PropTypes.bool.isRequired,
    callbackOnSelect: PropTypes.func.isRequired,
    callbackOnDelete: PropTypes.func.isRequired,
};

export default TrainingAuswahlSaison;
