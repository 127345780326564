import {connect} from 'react-redux'
import GamingArea from "../../../components/views/liga/GamingArea";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamingArea)