import React, {useRef} from 'react'
import StatisticTable from "./StatisticTable";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import StatistikDialog from "./StatistikDialog";
import StatistikGamesDialog from "./StatistikGamesDialog";
import {PrimeIcons} from "primereact/api";

export default function PublicTournamentStatistikTab({tournamentId, participants, gesamtStatistik, playerStatistiks}) {
    const filterParticipantsOP = useRef(null);

    const [filterText, setFilterText] = React.useState("");
    const [selectedStatistik, setSelectedStatistik] = React.useState(undefined);
    const [selectedStatistikName, setSelectedStatistikName] = React.useState(undefined);
    const [showGamePlayerId, setShowGamePlayerId] = React.useState(undefined);

    const filterParticipants = () => {
        if (filterText === "") {
            return participants;
        }
        let returnList = [];
        for (const participant of participants) {
            if (participant.name.toUpperCase().includes(filterText.toUpperCase())) {
                returnList.push(participant);
            }
        }
        return returnList;
    }

    const getGesamtStatistikArea = () => {
        if( gesamtStatistik === null || gesamtStatistik === undefined) {
            return "";
        }
        return <div style={{paddingLeft: 20, paddingBottom: 10, color: "white",cursor: "pointer"}} onClick={() => {
            setSelectedStatistik(gesamtStatistik);
            setSelectedStatistikName("Turnier/Liga");
        }}>
            Avg: {gesamtStatistik.average} / F-9 Avg.: {gesamtStatistik.first9Average}
            <i className={PrimeIcons.CHART_LINE} style={{fontSize: 20, marginLeft: 10}}/>
        </div>
    }

    return <div>
        <div style={{display: "flex", marginTop: 20, marginLeft: 15, marginBottom: 10}}>
            <OverlayPanel ref={filterParticipantsOP}
                          showCloseIcon={false} dismissable={true}
                          style={{height: 100, width: 200}}>
                <div style={{marginTop: 20}}>
                        <span className="p-float-label">
                            <InputText id="filterText" value={filterText}
                                       onChange={(e) => setFilterText(e.target.value)}/>
                            <label htmlFor="filterText">Filter</label>
                        </span>
                </div>
            </OverlayPanel>
            <div style={{color: "white", marginRight: 10, fontSize: 20}}>Anzahl der
                Teilnehmer: {participants.length}</div>
            <i className="pi pi-filter" style={{color: "white", width: 40, height: 30, fontSize: 30}}
               onClick={(e) => filterParticipantsOP.current.toggle(e)}/>
            {filterText !== "" &&
                <div style={{
                    color: "white",
                    marginRight: 10,
                    fontSize: 20
                }}>Gefiltert: {filterParticipants().length}</div>
            }
        </div>
        {getGesamtStatistikArea()}
        <StatisticTable participants={filterParticipants()} tournamentId={tournamentId} playerStatistiks={playerStatistiks} onShowStatistik={(statistik, name) => {
            setSelectedStatistik(statistik);
            setSelectedStatistikName(name);
        }}
                        onShowGames={(playerId) => setShowGamePlayerId(playerId)}
        />
        {selectedStatistik !== undefined && <StatistikDialog statistik={selectedStatistik} showPlayerGesamtStatistik={true} name={selectedStatistikName}  onClose={() => setSelectedStatistik(undefined)}/>}
        {showGamePlayerId !== undefined && <StatistikGamesDialog tournamentId={tournamentId} playerId={showGamePlayerId} onClose={() => setShowGamePlayerId(undefined)}/>}
    </div>
}
