import * as React from 'react';
import {useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DateUtil from "../../../../util/DateUtil";
import PlayerUtil from "../../../../util/PlayerUtil";
import StatistikGameDialog from "./StatistikGameDialog";
import BoardSelectionDialog from "../../veranstalter/BoardSelectionDialog";
import ConfirmDialog from "../../../general/ConfirmDialog";

const CELL_PADDING_VALUE = 8;

export default function TournamentAnyGameTable({tournamentId, eventOperatorId, games, players, boardGames, isAdmin, onRefresh, onDeleteGame}) {

    const [showDeleteDialog , setShowDeleteDialog ] = useState(false);
    const [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    const [selectedGameId, setSelectedGameId] = useState(undefined);
    const [selectedGameBoardVergabe, setSelectedGameBoardVergabe] = useState(undefined);

    const getBoardGame = (gameId) => {
        if( !boardGames) {
            return undefined;
        }
        for (const boardGame of boardGames) {
            if( boardGame.tournamentGameId === gameId) {
                return boardGame;
            }
        }
        return undefined;
    }

    const getTableRows = (games) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        games.forEach(game => {

            let boardGame = getBoardGame(game.id);
            let ergebnisVorhanden = game.anzahlPlayer1 !== null;
            let ergebnis =  ergebnisVorhanden ? game.anzahlPlayer1 + ":" + game.anzahlPlayer2 : "-";
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={game.id}>
                <TableCell style={style}>{DateUtil.localDateTimeToDateTimeString(game.timestamp)}</TableCell>
                <TableCell style={style}>{PlayerUtil.getSpielerName(players, game.player1Id)}</TableCell>
                {ergebnisVorhanden && <TableCell style={{...style, textAlign:"center"}}><span style={{cursor: "pointer"}} onClick={() => {
                    setSelectedGameId(game.id);
                    setShowGameDetailDialog(true);
                }}>{ergebnis}</span></TableCell>}
                {!ergebnisVorhanden && <TableCell style={{...style,textAlign:"center"}}>
                    {boardGame !== undefined ?
                        <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20, marginTop:10}}>{boardGame.boardBezeichnung}</div>
                            :
                        <div style={{textAlign:"center", fontSize:20}}>-</div>
                    }
                </TableCell>}
                <TableCell style={style}>{PlayerUtil.getSpielerName(players, game.player2Id)}</TableCell>
                {isAdmin &&
                    <TableCell style={style}>
                        {!ergebnisVorhanden &&
                            <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben"
                                 style={{cursor: "pointer", width: 30, marginRight: 10}}
                                 onClick={() => {
                                     setSelectedGameBoardVergabe(game);
                                 }}/>
                        }
                        <img src="/images/cancel.png" alt="Spiel löschen" title="Spiel löschen"
                             style={{cursor: "pointer", width: 30}}
                             onClick={() => {
                                 setShowDeleteDialog(true);
                                 setSelectedGameId(game.id);
                             }}/>
                    </TableCell>
                }
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    if (games.length === 0) {
        return <div style={{color: "white", fontSize: 20, padding: 20}}>Aktuell keine Games vorhanden.</div>
    }
    return <>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20, padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width: 100, padding:CELL_PADDING_VALUE}}>Datum</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Spieler 1</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, textAlign:"center", width: 50, padding:CELL_PADDING_VALUE}}>Ergebnis</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Spieler 2</TableCell>
                        {isAdmin && <TableCell style={{backgroundColor: "#ac1c27", color:"white", width: 100, fontSize:20, padding:CELL_PADDING_VALUE}}>#</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(games)}
                </TableBody>
            </Table>
        </TableContainer>
        {showGameDetailDialog &&
            <StatistikGameDialog tournamentId={tournamentId} tournamentGameId={selectedGameId} onClose={() => {
                setShowGameDetailDialog(false);
                setSelectedGameId(undefined);
            }}/>
        }
        {selectedGameBoardVergabe !== undefined &&
            <BoardSelectionDialog onClose={() => {
                setSelectedGameBoardVergabe(undefined);
            }}
                                  eventOperatorId={eventOperatorId}
                                  spieler1Name={PlayerUtil.getSpielerName(players, selectedGameBoardVergabe.player1Id)}
                                  spieler2Name={PlayerUtil.getSpielerName(players, selectedGameBoardVergabe.player2Id)}
                                  showSchreiberArea={false}
                                  schreiberName={() => ""}
                                  players={players}
                                  callBackLoadData={() => onRefresh()}
                                  tournamentId={tournamentId}
                                  tournamentGameId={selectedGameBoardVergabe.id}/>
        }
        {showDeleteDialog && <ConfirmDialog callBackOnNein={() => setShowDeleteDialog(false)}
                                            callBackOnJa={() => {
                                                onDeleteGame(selectedGameId);
                                                setSelectedGameId(undefined);
                                                setShowDeleteDialog(false);
                                            }} visible={true} header={"Löschen?"} textOben={"Möchtest du das Game wirklich löschen?"} />
        }
    </>
}
