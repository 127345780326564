import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import Turnierbaum from "../../../components/views/tournament/Turnierbaum";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const tournamentId = urlParams.get('id');
    const userId= state.user.id;
    return {tournamentid: tournamentId, userId: userId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Turnierbaum))