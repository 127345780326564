import {Button} from "primereact/button";
import React, {useState} from "react";
import PlusMinusComponent from "../../general/PlusMinusComponent";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import TournamentAddPlayerToKOPhaseDialog from "./TournamentAddPlayerToKOPhaseDialog";
import DHDialog from "../../general/DHDialog";


export default function KODKOErgebnisEintragenDialog({tournamentId, tournamentGameId, gruppe, feld, spieler1Id, spieler2Id, spielername1, spielername2,
                                                      readonly, ergebnisse, models, model, onErgebnisLoeschen, finaleMitSatzausgleich, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [anzahlSpieler1, setAnzahlSpieler1] = useState(0);
    let [anzahlSpieler2, setAnzahlSpieler2] = useState(0);

    let [anzahlSpieler1Finale2, setAnzahlSpieler1Finale2] = useState(0);
    let [anzahlSpieler2Finale2, setAnzahlSpieler2Finale2] = useState(0);

    let [showAddPlayerDialog, setShowAddPlayerDialog] = useState(false);

    const ergebnisEintragen = (tmpGameId, tmpAnzahlSpieler1, tmpAnzahlSpieler2) => {
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changeergbnis";
        let data = {
            tournamentid: tournamentId,
            tournamentGameId: tmpGameId,
            player1Id: spieler1Id,
            player2Id: spieler2Id,
            anzahlPlayer1: tmpAnzahlSpieler1,
            anzahlPlayer2: tmpAnzahlSpieler2,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                setInfoMessage("Ergebnis wurde erfolgreich gespeichert.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern des Ergebnisses: " + error.message);
            });
    }

    const ergebnisLoeschen = (tmpFeld) => {
        let ergebnis = getErgebnisByFeld(tmpFeld);
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/deleteresult";
        let data = {tournamentid: tournamentId, tournamentGameId: ergebnis.id};
        FetchUtil.fetchPost(url,
            data,
            () => {
                setInfoMessage("Ergebnis wurde erfolgreich gelöscht.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Löschen des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Löschen des Ergebnisses: " + error.message);
            });
    }

    const getErgebnisByFeld = (tmpFeld) => {
        if (ergebnisse !== undefined && ergebnisse !== null) {
            for (const ergebnis of ergebnisse) {
                if (ergebnis.feld === tmpFeld) {
                    return ergebnis;
                }
            }
        }
        return undefined;
    }

    const getErgebnis = () => {
        return getErgebnisByFeld(feld);
    }

    const isSpieler2IstSieger = () => {
        let ergebnis = getErgebnis();
        return ergebnis !== undefined && ergebnis.anzahlSpieler2 > ergebnis.anzahlSpieler1;
    }

    const getSpielerAuswahlArea = () => {
        let disableErgebnisEintragen = (anzahlSpieler1 === 0 && anzahlSpieler2 === 0) || anzahlSpieler1 === anzahlSpieler2;
        let disableErgebnisEintragenFinale2 = (anzahlSpieler1Finale2 === 0 && anzahlSpieler2Finale2 === 0) || anzahlSpieler1Finale2 === anzahlSpieler2Finale2;

        return <div style={{textAlign: "left"}}>
            <div>Schnelleingabe - Das Ergebnis wird 1:0 für den gewählten Spieler gewertet.</div>
            <div className="grid" style={{marginTop:10}}>
                <div className="col">
                    {isFinaleUndFinaleMitSatzausgleich() &&
                        <div style={{marginBottom: 10, fontWeight: "bold"}}>Finale - 1</div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: "50px"}}>
                            <Button icon="pi pi-check-circle"
                                    tooltip="Gewinner festlegen"
                                    onClick={() => ergebnisEintragen( tournamentGameId,1, 0) }/>
                        </div>
                        <div className="col">{spielername1}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: "50px"}}>
                            <Button icon="pi pi-check-circle"
                                    tooltip="Gewinner festlegen"
                                    onClick={() => ergebnisEintragen( tournamentGameId,0, 1)}/>
                        </div>
                        <div className="col">{spielername2}</div>
                    </div>
                </div>
                {isFinaleUndFinaleMitSatzausgleich() && isSpieler2IstSieger() &&
                    <div className="col">
                        <div style={{marginBottom:10, fontWeight:"bold"}}>Finale - 2</div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: "50px"}}>
                                <Button icon="pi pi-check-circle"
                                        tooltip="Gewinner festlegen"
                                        onClick={() => ergebnisEintragen( getErgebnisByFeld("finale2").id,1, 0) }/>
                            </div>
                            <div className="col">{spielername1}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: "50px"}}>
                                <Button icon="pi pi-check-circle"
                                        tooltip="Gewinner festlegen"
                                        onClick={() => ergebnisEintragen( getErgebnisByFeld("finale2").id,0, 1)}/>
                            </div>
                            <div className="col">{spielername2}</div>
                        </div>
                    </div>
                }
            </div>
            <hr/>
            <div>Genaue Ergebniseingabe</div>
            <div className="grid" style={{marginTop:10}}>
                <div className="col">
                    {isFinaleUndFinaleMitSatzausgleich() &&
                        <div style={{marginBottom: 10, fontWeight: "bold"}}>Finale - 1</div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: "200px"}}>{spielername1} Anzahl:
                        </div>
                        <div className="col">
                            <PlusMinusComponent value={anzahlSpieler1}
                                                readonly={false}
                                                callBackMinus={() => setAnzahlSpieler1(anzahlSpieler1 - 1)}
                                                callBackPlus={() => setAnzahlSpieler1(anzahlSpieler1 + 1 )}/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: "200px"}}>{spielername2} Anzahl:
                        </div>
                        <div className="col">
                            <PlusMinusComponent value={anzahlSpieler2}
                                                readonly={false}
                                                callBackMinus={() => setAnzahlSpieler2(anzahlSpieler2 - 1)}
                                                callBackPlus={() => setAnzahlSpieler2(anzahlSpieler2 + 1)}/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            <Button label="Ergebnis eintragen"
                                    tooltip="Ergebnis eintragen"
                                    disabled={disableErgebnisEintragen}
                                    onClick={() => ergebnisEintragen( tournamentGameId,anzahlSpieler1, anzahlSpieler2)}/>
                        </div>
                    </div>
                </div>
                {isFinaleUndFinaleMitSatzausgleich() && isSpieler2IstSieger() &&
                <div className="col">
                    <div style={{marginBottom: 10, fontWeight: "bold"}}>Finale - 2</div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: "200px"}}>{spielername1} Anzahl:
                        </div>
                        <div className="col">
                            <PlusMinusComponent value={anzahlSpieler1Finale2}
                                                readonly={false}
                                                callBackMinus={() => setAnzahlSpieler1Finale2(anzahlSpieler1Finale2 - 1)}
                                                callBackPlus={() => setAnzahlSpieler1Finale2(anzahlSpieler1Finale2 + 1 )}/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: "200px"}}>{spielername2} Anzahl:
                        </div>
                        <div className="col">
                            <PlusMinusComponent value={anzahlSpieler2Finale2}
                                                readonly={false}
                                                callBackMinus={() => setAnzahlSpieler2Finale2(anzahlSpieler2Finale2 - 1)}
                                                callBackPlus={() => setAnzahlSpieler2Finale2(anzahlSpieler2Finale2 + 1)}/>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            <Button label="Ergebnis eintragen"
                                    tooltip="Ergebnis eintragen"
                                    disabled={disableErgebnisEintragenFinale2}
                                    onClick={() => ergebnisEintragen( getErgebnisByFeld("finale2").id, anzahlSpieler1Finale2, anzahlSpieler2Finale2)}/>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>;
    }

    const gibtEsErgbnisseDieAenderungVerhindern = () => {

        let fields = [];
        for (const tmpModel of models) {
            if (tmpModel.parent1 === feld || tmpModel.parent2 === feld) {
                fields.push(tmpModel.feld);
            }
        }
        if (ergebnisse !== undefined && ergebnisse !== null) {
            for (const ergebnis of ergebnisse) {
                fields.includes(ergebnis.feld);
                if (fields.includes(ergebnis.feld) && ergebnis.anzahlSpieler1 !== undefined && ergebnis.anzahlSpieler1 !== null) {
                    return true;
                }
            }
        }
        return false;
    }

    const kannGeaendertWerden = () => {
        let aenderungMoeglich = gibtEsErgbnisseDieAenderungVerhindern();
        let tmpModel = models.filter(tmp => tmp.feld === feld)[0];
        return !aenderungMoeglich && !tmpModel.endknoten;
    }

    const spielerIdsVorhanden = () => {
        return !(spieler1Id === null || spieler1Id === undefined || spieler2Id === null || spieler2Id === undefined);
    }

    if( readonly ) {
        return <DHDialog onClose={() => onClose()} title="Ergebnis eintragen" show={true} buttons={[]}
                         comp={<>
            <div>
                <Button label="Dialog schliessen"
                        tooltip="Dialog schliessen"
                        onClick={() => onClose()}/>
            </div>
            <div>Keine Änderung möglich.</div>
        </>}/>
    }

    const isFinaleUndFinaleMitSatzausgleich = () => {
        return model.tournamentModus === "finale" && finaleMitSatzausgleich;
    }

    const isAulosungUndHatErgebnisFreilos = () => {
        return feld.startsWith("1-") && (spieler1Id === "freilos" || spieler2Id === "freilos");
    }

    return <DHDialog onClose={() => onClose()} title="Ergebnis eintragen" show={true} buttons={[]}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div>
            <Button label="Dialog schliessen"
                    tooltip="Dialog schliessen"
                    onClick={() => onClose()}/>
        </div>
        {isAulosungUndHatErgebnisFreilos() && <>
            <hr/>
            <div style={{color:"red", fontWeight:"bold"}}>ACHTUNG!</div>
            <div>Im Turnierbaum ist an dieser Stelle ein Freilos vorhanden. Du kannst an dieser Stelle einen Spieler, der noch nicht im Baum vorhanden ist, einfügen.
                Diese Aktion ist nur auf der Auslosungsebene möglich.
                Dafür darf in diesem Bereich kein Ergebnis vorhanden sein (auch nicht in Folgespielen).
                Lösche im Fall der Fälle dieses Ergebnis und evtl. abhängige Ergebnisse und füge danach einen Spieler hinzu.</div>
            <div style={{marginTop:10}}>
                <Button label="Neuen Spieler einfügen"
                        disabled={getErgebnisByFeld(feld).anzahlSpieler1 !== null && getErgebnisByFeld(feld).anzahlSpieler1 !== undefined }
                        onClick={() => setShowAddPlayerDialog(true)}/>
            </div>
        </>
        }
        <hr/>
        {!spielerIdsVorhanden() &&
            <div>Keine Änderung möglich, beide Spieler müssen gesetzt sein.</div>
        }
        {spielerIdsVorhanden() && kannGeaendertWerden() &&
            <>
                <div className="grid" style={{marginTop:10}}>
                    <div className="col">
                        {isFinaleUndFinaleMitSatzausgleich() &&
                            <div style={{marginBottom: 10, fontWeight: "bold"}}>Finale - 1</div>
                        }
                        Hier kannst du ein eventuell vorhandenes Ergebnis löschen.
                        <br/>
                        <Button label="Ergebnis löschen" tooltip="Ergebnis löschen" onClick={() => ergebnisLoeschen(feld)}/>
                    </div>
                    {isFinaleUndFinaleMitSatzausgleich() && isSpieler2IstSieger() &&
                        <div className="col">
                            <div style={{marginBottom: 10, fontWeight: "bold"}}>Finale - 2</div>
                            Hier kannst du ein eventuell vorhandenes Ergebnis löschen.
                            <br/>
                            <Button label="Ergebnis löschen" tooltip="Ergebnis löschen"
                                    onClick={() => ergebnisLoeschen("finale2")}/>
                        </div>
                    }
                </div>
                <hr/>
            </>
        }
        {isFinaleUndFinaleMitSatzausgleich() && <div>Finale</div>}
        {spielerIdsVorhanden() && !kannGeaendertWerden() &&
            <div style={{color: "gray"}}>Das Spiel kann nicht geändert werden, da abhänige Spiele bereits ein Ergebnis haben. </div>
        }
        {spielerIdsVorhanden() && kannGeaendertWerden() &&
            getSpielerAuswahlArea()
        }
        {showAddPlayerDialog && <TournamentAddPlayerToKOPhaseDialog
            tournamentId={tournamentId} feld={feld} gruppe={gruppe}
            onClose={(refresh) => {
                setShowAddPlayerDialog(false);
                if( refresh) {
                    onRefresh();
                }
            }}
            /> }
    </>}/>
}
