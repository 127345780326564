import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {Drawer} from "@mui/material";


export default function DHSelectionComponent({title, values, value, onChange, disabled}) {
    const[selectedValue, setSelectedValue] = useState(undefined);
    const[show, setShow] = useState(false);

    useEffect( () => {
        setSelectedValue(getValueByValue(value));
    }, [value, values])

    const getValueByValue = (value) => {
        if (value === undefined || value === null) {
            return undefined;
        }
        for (const tmp of values) {
            if (tmp.code === value) {
                return tmp;
            }
        }
        return undefined;
    }

    const getRows = () => {
        let rows = [];
        let color = true;
        for (const tmp of values) {
            rows.push(<div key={"key_" + tmp.code}
                           onClick={() => {
                               onChange(tmp.code);
                               setShow(false);
                           }
                           }
                           style={{textAlign:"center", border:"1px solid #ddd",
                               fontSize:20, padding:10, cursor:"pointer",
                               backgroundColor: color ? "#f2f2f2" : "white"}}
            >
                {tmp.name}
            </div>);
            color = !color;
        }
        return rows;
    }

    return <>
        <div style={{display:"flex"}}>
            <Button icon={PrimeIcons.PENCIL} onClick={() => setShow(true)} disabled={disabled}/>
            {selectedValue !== undefined && <div style={{marginLeft:10, marginTop:7}}>{selectedValue.name}</div>}
        </div>
        <Drawer PaperProps={{sx: {width: 300}}} open={show} onClose={() => setShow(false)}>
            <Button icon={PrimeIcons.TIMES_CIRCLE}
                    style={{position:"fixed", top:5, left:5}}
                    className="p-button-danger" onClick={() => setShow(false)}/>
            <div style={{backgroundColor:"black", color:"white", textAlign:"center",
                fontWeight:"bold", fontSize:20, padding:10}}>
                {title}
            </div>
            {getRows()}
        </Drawer>
    </>
}