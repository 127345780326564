import React, {useEffect, useState} from 'react'
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import QRGenerator from "../../pdf/qrcode/QRGenerator";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";

export default function LigaspieltagQRCodeSidebar({tournamentId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [qrcode, setQrcode] = useState(undefined);

    useEffect(() => {
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        ladeQRCode();
    }, [tournamentId]);

    const ladeQRCode = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/load/qrcode/" + tournamentId,
            (json) => {
                setQrcode(json.qrcode);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    const qrCodeGenerieren = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.ligaspieltag + "/generate/qrcode/" + tournamentId,
            {},
            (json) => {
                setQrcode(json.qrcode);
                setInfoMessage("QR Code wurde (neu) generiert");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    const getLink = () => {
        if( qrcode === null || qrcode === undefined) {
            return undefined;
        }
        let link = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLI_LIGASPIELTAGGASTEINGABE.route + "?tournamentid=" +  tournamentId + "&qrcode=" + qrcode;
        console.log(link);
        return link;
    }


    return <Sidebar visible={true} style={{width: 380}}
                    onHide={(e) => onClose()}
                    position="left" showCloseIcon={true}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{padding: 10, marginBottom: 10}}>
            <Button label="Bereich schliessen" onClick={() => onClose()}/>
        </div>
        <div style={{padding: 10}}>
            <Button label="QR Code (neu) generieren" onClick={() => qrCodeGenerieren()} style={{width: "100%"}}/>
        </div>
        {getLink() !== undefined &&
            <div style={{width: "100%"}}>
                <QRGenerator data={{id: 1, value: getLink()}}/>
            </div>
        }
        <div style={{padding: 10, fontSize: 16}}>
            Hinweis: Sobald du einen QR Code neu generierst, verliert der alte QR Code seine Gültigkeit. Über den alten QR Code können dann keine Daten mehr eingepflegt werden.
        </div>
    </Sidebar>
}
