import React, {useEffect, useState} from "react";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Link} from "@mui/material";
import {ToggleButton} from "primereact/togglebutton";
import DateUtil from "../../../util/DateUtil";
import {InputText} from "primereact/inputtext";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import DHDialog from "../../general/DHDialog";
import VeranstalterBezahlungDialog from "../veranstalter/VeranstalterBezahlungDialog";

export default function AdminVeranstalter() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [veranstalter, setVeranstalter] = useState([]);
    const [filterProAccountLaueftAus, setFilterProAccountLaueftAus] = useState(false);
    const [filterNuVerbindungAus, setFilterNuVerbindungAus] = useState(false);
    const [filterName, setFilterName] = useState("");

    const [selectedEventOperatorId, setSelectedEventOperatorId] = useState(undefined);
    const [showBezahlung, setShowBezahlung] = useState(false);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.admin + "/eventoperators",
            json => {
                setVeranstalter(json)
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }, []);

    const rows = () => {
        let tmpRows = [];
        let liste = veranstalter.filter(v => v.name.toUpperCase().includes(filterName.toUpperCase()));
        for (const tmpVeranstalter of liste) {
            if( filterProAccountLaueftAus && !tmpVeranstalter.proAccountLaeuftAus) {
                continue;
            }
            if( filterNuVerbindungAus && !tmpVeranstalter.nuClubUUID) {
                continue;
            }
            let admins = [];
            for (const admin of tmpVeranstalter.admins) {
                admins.push(<div>{admin.vorname} {admin.name} - {admin.email} - {admin.handynummer}</div>)
            }
            tmpRows.push(<div key={"id" + tmpVeranstalter.id}>
                <hr/>
                <div>Name: {tmpVeranstalter.name}</div>
                <div>Pro-Account Endedatum: {tmpVeranstalter.proAccountEndeDatum}</div>
                <div style={{color: tmpVeranstalter.proAccountLaeuftAus ? "red" : "black"}}>Pro-Account läuft aus: {tmpVeranstalter.proAccountLaeuftAus ? "ja" : "nein"}</div>
                <div>Nu-Verbindung: {tmpVeranstalter.nuClubUUID ? "ja" : "nein"}</div>
                <div>Id: {tmpVeranstalter.id}</div>
                <div>Anlagedatum: {DateUtil.localDateTimeToDateTimeString(tmpVeranstalter.anlagedatum)}</div>
                <div>Änderungsdatum: {DateUtil.localDateTimeToDateTimeString(tmpVeranstalter.aenderungsdatum)}</div>
                <div>Admins:</div>
                {admins}
                <div style={{marginTop: 10}}>
                    <Link href={ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + tmpVeranstalter.id}
                          target="_blank" rel="noopener">Öffentlicher Bereich...</Link>
                </div>
                <div style={{marginTop: 10, color:"blue"}} onClick={() => {
                    setSelectedEventOperatorId(tmpVeranstalter.id);
                    setShowBezahlung(true);
                }} >
                    Bezahlinfos...
                </div>

            </div>);
        }
        return tmpRows;
    }

    return <div style={{textAlign: "left"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div style={{marginBottom: 10}}>
            <div style={{display: "flex"}}>
                Anzeige nur läuft ab:
                <ToggleButton
                    style={{marginLeft: 10}}
                    onLabel="Ja" offLabel="Nein"
                    checked={filterProAccountLaueftAus}
                    onChange={(e) => setFilterProAccountLaueftAus(!filterProAccountLaueftAus)}/>
            </div>
        </div>
        <div style={{marginBottom: 10}}>
            <div style={{display: "flex"}}>
                Anzeige nur mit nu-Verbindung:
                <ToggleButton
                    style={{marginLeft: 10}}
                    onLabel="Ja" offLabel="Nein"
                    checked={filterNuVerbindungAus}
                    onChange={(e) => setFilterNuVerbindungAus(!filterNuVerbindungAus)}/>
            </div>
        </div>
        <div style={{marginBottom: 10}}>
            <div style={{display: "flex"}}>
                Name:
                <InputText value={filterName} style={{width: 300, marginLeft:10}}
                           onChange={(e) => setFilterName(e.target.value)}/>
            </div>
        </div>
        <div style={{marginBottom: 10}}>
            Anzahl Veranstalter: {rows().length}
        </div>
        {rows()}
        {showBezahlung && <DHDialog title={"Bezahlungen"} onClose={() => {
            setShowBezahlung(false);
            setSelectedEventOperatorId(undefined);
        }} buttons={[]} comp={<VeranstalterBezahlung eventOperatorId={selectedEventOperatorId}/> }/>}
    </div>
}