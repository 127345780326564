import {Drawer} from "@mui/material";
import React from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";

export default function DHDialog({comp, title, buttons, onClose, showCloseButton}) {

    return <Drawer PaperProps={{sx: {width: "100%"}}} open={true} onClose={() => onClose()}>
        {title !== undefined && <>
            <Button icon={PrimeIcons.TIMES_CIRCLE}
                    style={{position:"fixed", top:5, left:5}}
                    className="p-button-danger" onClick={() => onClose()}/>
            <div style={{
                backgroundColor: "black", color: "white", textAlign: "center",
                fontWeight: "bold", fontSize: 20, padding: 10
            }}>
                {title}
            </div>
        </>}
        <div style={{padding: 10, marginBottom: 50}}>
            {comp}
        </div>
        <div style={{position: "fixed", bottom: 0, right: 0, paddingBottom: 10, paddingRight:20, display: "flex"}}>
            {buttons}
            {showCloseButton &&
                <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
            }
        </div>
    </Drawer>
}