import {connect} from 'react-redux'
import TrainingOnlineGame from "../../../components/views/training/TrainingOnlineGame";

const mapStateToProps = state => ({
    userid: state.user.id
});
const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingOnlineGame)