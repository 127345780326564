import {connect} from 'react-redux'
import withRouter from "../../components/RouterComponentWrapper";
import TwoPlayerScoringView from "../../components/score/TwoPlayerScoringView";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const gameId = urlParams.get('gameid');
    return {gameid: gameId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoPlayerScoringView))