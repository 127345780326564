import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DateUtil from "../../../util/DateUtil";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 400,
    },
});

function getRows(props) {

    let showUebersicht = props.uebersicht !== undefined ? props.uebersicht : false;

    let tableRows = [];
    if( props.spielergames !== undefined) {
        for (let i = 0; i < props.spielergames.length; i++) {
            let spielergames = props.spielergames[i];
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let gameDaten;

            let rowId;
            let dynmischerBereich = [];
            if( showUebersicht ) {
                rowId = spielergames.playerDto.id;
                gameDaten = spielergames.gesamt;
                let spielername = gameDaten.spieler1Name;
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}} onClick={() => props.onSelect(spielergames.allGames)}>{spielername}</TableCell>);
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.gewonnen + gameDaten.verloren}</TableCell>);
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.spieleGewonnenInProzent}</TableCell>);
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.gewonnen}</TableCell>);
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.verloren}</TableCell>);
            } else {
                rowId = spielergames.id;
                gameDaten = spielergames;
                let formattedDateTime = DateUtil.localDateTimeToDateString(gameDaten.gamdayTimestamp);
                let spielerdetail = "Spieltagbeschreibung: '" + gameDaten.gamedayBezeichnung + "' am " + formattedDateTime + " / " +gameDaten.spieler1Name + " : " + (gameDaten.spieler2Name !== "" ? gameDaten.spieler2Name : "'kein Name vorhanden'")
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{spielerdetail}</TableCell>);
                dynmischerBereich.push(<TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.anzahlSpieler1 > gameDaten.anzahlSpieler2 ? "X" : ""}</TableCell>);
            }
            tableRows.push(<TableRow key={"tabellenzeile_" + rowId } style={currentStyle}>
                {dynmischerBereich}
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.legsGewonnenInProzent}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.anzahlSpieler1}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.anzahlSpieler2}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.tripleJeLeg}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._16}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._17}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._18}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._19}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._20}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._171}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten._180}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.neger}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.highscores}</TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{gameDaten.highfinishes}</TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {
    const classes = useStyles();

    if( props.spielergames === undefined) {
        return <div>Keine Daten vorhanden....</div>
    }

    let showUebersicht = props.uebersicht !== undefined ? props.uebersicht : false;

    let tableRows = getRows(props);

    let dynmischerBereich = [];
    if( showUebersicht ) {
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Spieler</TableCell>);
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Spiele gesamt</TableCell>);
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Spiele gewonnen in %</TableCell>);
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Spiele gewonnen</TableCell>);
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Spiele verloren</TableCell>);
    } else {
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Spiel</TableCell>);
        dynmischerBereich.push(<TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>gewonnen</TableCell>);
    }

    return (
        <Paper className={classes.root}>
            {!showUebersicht ? <h1>Details zum Spieler</h1> : ""}
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {dynmischerBereich}
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Legs gewonnen in %</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:90}}>Legs gewonnen</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:80}}>Legs verloren</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>triple je leg</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>t16</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>t17</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>t18</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>t19</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>t20</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>171</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>180</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:30}}>Neger</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:100}}>Highscores</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4", width:100}}>Highfinishes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key="bodykey">
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}