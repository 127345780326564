import React from "react";
import PropTypes from "prop-types";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import TournamentX01Area from "../TournamentX01Area";
import {Button} from "primereact/button";

export default function KODKOX01VerwaltenArea({
                                   tournamentSystem,
                                   gewinnerseiteSets,
                                   gewinnerseiteFirstToLegs,
                                   gewinnerseiteModusId,
                                   gewinnerseiteStartpunkte,
                                   verliererseiteSets,
                                   verliererseiteFirstToLegs,
                                   verliererseiteModusId,
                                   verliererseiteStartpunkte,
                                   setGewinnerseiteFirstToLegs,
                                   setGewinnerseiteModusId,
                                   setGewinnerseiteStartpunkte,
                                   setGewinnerseiteSets,
                                   setVerliererseiteSets,
                                   setVerliererseiteFirstToLegs,
                                   setVerliererseiteModusId,
                                   setVerliererseiteStartpunkte
                               }) {

    function uebernehmeNachVerliererseite() {
        setVerliererseiteSets(gewinnerseiteSets);
        setVerliererseiteFirstToLegs(gewinnerseiteFirstToLegs);
        setVerliererseiteStartpunkte(gewinnerseiteStartpunkte);
        setVerliererseiteModusId(gewinnerseiteModusId);
    }

    return <>
        {tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id &&
            <div className="grid" style={{marginTop: 20}}>
                <div className="col-fixed" style={{width: 200, fontWeight: "bold"}}>Gewinnerseite:
                    (pflicht)
                </div>
            </div>
        }
        <TournamentX01Area showSets={true} showBestOfLegs={false} showPflichtfelder={true}
                           sets={gewinnerseiteSets} firstToLegs={gewinnerseiteFirstToLegs}
                           modusId={gewinnerseiteModusId} startpunkte={gewinnerseiteStartpunkte}
                           onSetsChanged={(sets) => setGewinnerseiteSets(sets)}
                           onFirstToLegsChanged={(firstToLegs) => setGewinnerseiteFirstToLegs(firstToLegs)}
                           onModusIdChanged={(modusId) => setGewinnerseiteModusId(modusId)}
                           onStartpunkteChanged={(startpunkte) => setGewinnerseiteStartpunkte(startpunkte)}/>
        {tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id && <>
            <div className="grid" style={{marginTop: 20}}>
                <div className="col" style={{width: 200, fontWeight: "bold"}}>Verliererseite:
                    (pflicht)
                </div>
            </div>
            <div className="grid">
                <div className="col">
                    <Button label="Daten von Gewinnerseite übernehmen"
                            onClick={uebernehmeNachVerliererseite}/>
                </div>
            </div>
            <TournamentX01Area showSets={true} showBestOfLegs={false} sets={verliererseiteSets}
                               showPflichtfelder={true} firstToLegs={verliererseiteFirstToLegs}
                               modusId={verliererseiteModusId} startpunkte={verliererseiteStartpunkte}
                               onSetsChanged={(sets) => setVerliererseiteSets(sets)}
                               onFirstToLegsChanged={(firstToLegs) => setVerliererseiteFirstToLegs(firstToLegs)}
                               onModusIdChanged={(modusId) => setVerliererseiteModusId(modusId)}
                               onStartpunkteChanged={(startpunkte) => setVerliererseiteStartpunkte(startpunkte)}/>
        </>
        }
    </>

}
