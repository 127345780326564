import React from 'react';
import PlayerUtil from "../../util/PlayerUtil";

function getHighScoreArea(props) {
    let zeilen = [];
    for (let i = 0; i < props.rangliste.length; i++) {
        let row = props.rangliste[i];
        if (row.highscoresBlank !== null && row.highscoresBlank !== undefined && row.highscoresBlank.length > 0) {
            let spielername = PlayerUtil.anzeigeNameZumSpieler(row.playerDto);
            zeilen.push(<div key={i + "ueberschift_highscore"}
                             style={{textAlign: "left"}}>{spielername + ": " + row.highscoresBlank}</div>);
        }
    }
    if (zeilen.length > 0) {
        let ueberschrift = <div key="ueberschift_highscore"
                                style={{textAlign: "left", fontWeight: 'bold', marginTop: "20px"}}>Highscores</div>;
        return [ueberschrift].concat(zeilen);
    }
    return <div/>;
}

function getHighfinishesArea(props) {
    let zeilen = [];
    for (let i = 0; i < props.rangliste.length; i++) {
        let row = props.rangliste[i];
        if (row.highfinishesBlank !== null && row.highfinishesBlank !== undefined && row.highfinishesBlank.length > 0) {
            let spielername = PlayerUtil.anzeigeNameZumSpieler(row.playerDto);
            zeilen.push(<div key={i + "ueberschift_highfinishes"}
                             style={{textAlign: "left"}}>{spielername + ": " + row.highfinishesBlank}</div>);
        }
    }
    if (zeilen.length > 0) {
        let ueberschrift = <div key="ueberschift_highfinsihes"
                                style={{textAlign: "left", fontWeight: 'bold', marginTop: "20px"}}>Highfinishes</div>;
        return [ueberschrift].concat(zeilen);
    }
    return <div/>;
}

export default function area(props) {
    if( props.rangliste === undefined || props.rangliste === null ) {
        return "";
    }
    return (
        <div>
            {getHighfinishesArea(props)}
            {getHighScoreArea(props)}
        </div>
    );
}