import React from 'react'
import {Messages} from 'primereact/messages';

import {Toast} from "primereact/toast";
import {Panel} from 'primereact/panel';
import TrainingsSessionSelectComponent from "../../general/TrainingsSessionSelectComponent";
import Trainingsergebnisse from "../../general/Trainingsergebnisse";
import Trainingtabelle from "../../general/Trainingtabelle";

class StatisticsTrainingsessions extends React.Component {

    constructor() {
        super();
        this.state = {trainigssession: undefined, counter:0};

        this.merkeTrainingssession = this.merkeTrainingssession.bind(this);
        this.showToast = this.showToast.bind(this);
        this.callBackOnError = this.callBackOnError.bind(this);
    }

    merkeTrainingssession(trainingssession) {
        this.setState({trainingssession: trainingssession, counter: this.state.counter +1 });
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    callBackOnError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
       return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black", padding: "20px"}}>
           <Toast ref={(el) => this.toast = el}/>
           <Messages ref={(el) => this.messages = el}/>
           <Panel header="Trainingsessions">

               <div className="grid" style={{marginBottom: "40px", textAlign:"left"}}>
                   <div className="col-fixed" style={{width: '150px'}}>Trainingssession:</div>
                   <div className="col">
                       <TrainingsSessionSelectComponent
                           trainingssession={this.state.trainingssession}
                           callBackSelectFunc={this.merkeTrainingssession}
                           callBackDeleteFunc={() => this.setState({...this.state, trainingssession: undefined})}
                           callBackOnErrorFunc={this.callBackOnError}
                            loadAll={true} zeigeVorwaertsRueckwaerts={true}
                       selectLast={true}/>
                   </div>
               </div>
               {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                   <Trainingsergebnisse trainingssessionid={this.state.trainingssession.id}
                                        counter={this.state.counter}/> : <div/>
               }
               {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                   <Trainingtabelle trainingssessionid={this.state.trainingssession.id} loadGesamt={false} ueberschrift="Tabelle zur Trainingssession"/> : <div/>
               }
           </Panel>
        </div>
    }

}

StatisticsTrainingsessions.propTypes = {};

export default StatisticsTrainingsessions