import React from 'react';
import {Sidebar} from "primereact/sidebar";
import Table from "./statisticGamedaysTable";
import {Button} from "primereact/button";


export default function SimpleOverlay(props) {
    return <Sidebar visible={true} style={{width:"100%", height:"100%"}} onHide={() => props.onClose()} showCloseIcon={false} >
        <Button onClick={() => props.onClose()} label="Bereich schliessen"/>
        <Table spielergames={props.games} uebersicht={false} />
    </Sidebar>
}
