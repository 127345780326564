import {connect} from 'react-redux'
import withRouter from "../../../../components/RouterComponentWrapper";
import PublicTournament from "../../../../components/views/public/tournaments/PublicTournament";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";

const mapStateToProps = function (state, ownProps) {
    let tournamentId = ownProps.router.location.pathname.substring(NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route.length + 1);
    return {userId: state.user.id, isLoggedIn: state.user.isLoggedIn, tournamentId: tournamentId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicTournament))
