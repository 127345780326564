import React from 'react'
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import AktionenMitteilungenContainer from "../../../containers/views/liga/AktionenMitteilungenContainer";
import AktionenChatContainer from "../../../containers/views/liga/AktionenChatContainer";
import PropTypes from "prop-types";

class SchnellAktionen extends React.Component {

    getLineWithInternLink(images, text, link) {
        return <NavLink to={link} style={{textDecoration: "none"}}>
            <div style={{textDecoration: "none", color: "black", textAlign:"center"}}>
                <img alt="" style={{
                    width: 30,
                    }} src={images}/>
                <br/>
                <span style={{whiteSpace: "nowrap"}}>{text}</span>
            </div>
        </NavLink>
    }

    render() {
        return <div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
            <div className="ligashortoverview"
                 style={{height: "100%", borderLeft: "10px solid black"}}>
                <div className="grid" style={{marginLeft: 0}}>
                    <div className="col">{this.getLineWithInternLink("images/statistik.png", "Games/Statistik", NAVIGATION_ITEM.USERSTATSTIK.route)}</div>
                    <div className="col">{this.getLineWithInternLink("images/profil_color.png", "Profil", NAVIGATION_ITEM.PROFIL.route)}</div>
                    <div className="col">{this.getLineWithInternLink("images/kalender_color.png", "Kalender", NAVIGATION_ITEM.KALENDER.route)}</div>
                    <div className="col"><AktionenChatContainer/></div>
                    <div className="col"><AktionenMitteilungenContainer/></div>
                </div>
            </div>
        </div>
    }
}

SchnellAktionen.propTypes = {
    userEventOperatorAdmin: PropTypes.bool.isRequired,
};

export default SchnellAktionen;
