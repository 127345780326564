import React, {useEffect, useState} from 'react'
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {getFromLocalStorageWithDefault, KEY_LAST_EVENTS, saveToLocalStorage} from "../util/localStorageZugriff";
import {PrimeIcons} from "primereact/api";
import DHDefaultDrawer from "./general/DHDefaultDrawer";

export default function LastEventComp({currentId, onLoad}) {

    const [showDetails, setShowDetails] = useState(false);
    const [lastEvents, setLastEvents] = useState([]);

    useEffect(() => {
        loadFromLocalStorage();
    }, [currentId]);

    const loadFromLocalStorage = () => {
        setLastEvents(getFromLocalStorageWithDefault(KEY_LAST_EVENTS, []));
    }

    const getDetailsArea  = () => {
        if( !showDetails || lastEvents.length === 0) {
            return "";
        }
        let rows = [];

        let styleWithBackground = {color: "#022836", background:"#eaf1f5", padding:10, margin:0};
        let styleWithoutBackground = {color: "#022836", background:"white", padding:10, margin:0};

        let background = false;
        for (const lastEvent of lastEvents) {
            let style = background ? styleWithBackground :  styleWithoutBackground;
            if( onLoad === undefined) {
                rows.push(<div key={"key_last_event" + lastEvent.id + "_type_" + lastEvent.type } style={{marginTop: 10, cursor: "pointer", color:"black", ...style}}>
                    <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + lastEvent.id} style={{textDecoration: "none"}}>
                        <span style={{color: "black"}}>{lastEvent.name}</span>
                    </NavLink>
                </div>);
            } else {
                rows.push(<div key={"key_last_event" + lastEvent.id + "_type_" + lastEvent.type }
                               style={{marginTop: 10, cursor: "pointer", color:"black", ...style}} onClick={() => {
                                   onLoad(lastEvent.id);
                                   setShowDetails(false);
                               }}>
                    <span style={{color: "black"}}>{lastEvent.name}</span>
                </div>);
            }
            background = !background;
        }
        return <DHDefaultDrawer show={showDetails} title={"Auswahl"} onClose={() => setShowDetails(false)} buttons={[]} children={<>
            <div style={{padding:10}}>
                {rows}
                <div style={{marginTop: 30, marginBottom: 30, cursor: "pointer"}} onClick={() => deleteHistory()}>
                    <hr/>
                    <i className={PrimeIcons.TRASH} style={{marginRight: 5}}/>
                    Verlauf leeren
                </div>
            </div>
        </>}/>
    }

    const deleteHistory = () => {
        saveToLocalStorage(KEY_LAST_EVENTS, []);
        loadFromLocalStorage();
    }

    const ermittleErstesEvent = () => {
        for (const lastEvent of lastEvents) {
            if( lastEvent.id !== currentId) {
                return lastEvent;
            }
        }
        return undefined;
    }

    if( ermittleErstesEvent() === null || ermittleErstesEvent() === undefined) {
        return "";
    }

    return <div style={{display:"flex",
        whiteSpace: "nowrap", overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign:"left", justifyContent:"left", verticalAlign:"middle", alignItems:"left", padding:5, marginBottom:10, width: "100%", color:"whitesmoke",
        backgroundColor:"#0a435d"}}>
        {ermittleErstesEvent() !== undefined && <div>
            <span style={{marginLeft:10, marginRight: 10, fontSize: 15, cursor: "pointer"}} onClick={() => setShowDetails(!showDetails)}>zuletzt besucht:</span>
            <i className={showDetails ? PrimeIcons.ANGLE_UP : PrimeIcons.ANGLE_DOWN}
               style={{marginRight: 10, fontSize: 15, cursor: "pointer"}} onClick={() => setShowDetails(!showDetails)}/>
            {onLoad === undefined ?
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + ermittleErstesEvent().id} style={{textDecoration: "none"}}>
                    <span style={{color: "white", cursor: "pointer"}}>{ermittleErstesEvent().name}</span>
                </NavLink>
                :
                <span style={{color: "white", cursor: "pointer"}} onClick={() => onLoad(ermittleErstesEvent().id)}>
                    {ermittleErstesEvent().name}
                </span>
            }
        </div>
        }
        {getDetailsArea()}
    </div>
}
