import React from "react";
import QRCode from "qrcode.react";

function QRGenerator({data}) {
    return (
        <div>
            <QRCode
                id={data.id}
                value={data.value}
                size={255}
                bgColor="#FFF"
                fgColor="#000"
                includeMargin
                level={"H"}
            />
        </div>
    );
}

export default QRGenerator;
