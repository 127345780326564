import React from 'react'
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {NavLink} from "react-router-dom";


class Links extends React.Component {

    render() {
        return <div className="col-12 md:col-12 lg:col-12 footer" style={{padding: 10}}>
            <div className="kachelStartseite" style={{height:"100%"}}>
                <div className="grid" style={{textAlign: "center", marginBottom: "-0.5em"}}>
                    <div className="col-12 md:col-6 lg:col-4"><NavLink to={NAVIGATION_ITEM.AGB.route} style={{textDecoration: "none"}}>AGB</NavLink></div>
                    <div className="col-12 md:col-6 lg:col-4"><NavLink to={NAVIGATION_ITEM.IMPRESSUM.route} style={{textDecoration: "none"}}>Impressum</NavLink></div>
                    <div className="col-12 md:col-6 lg:col-4"><NavLink to={NAVIGATION_ITEM.DATENSCHUTZ.route} style={{textDecoration: "none"}}>Datenschutzerklärung</NavLink></div>
                </div>
            </div>
        </div>
    }
}

Links.propTypes = {};

export default Links;