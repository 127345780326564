import React from 'react'
import {PrimeIcons} from "primereact/api";
import ConfigUtil from "../../../../util/ConfigUtil";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {NavLink} from "react-router-dom";


export default function PublicTournamentBreadCumb({
                                                      isMobil,
                                                      isLiveView,
                                                      eventOperatorBezeichnung,
                                                      eventOperatorId,
                                                      tournamentBezeichnung,
                                                      tournamentId
                                                  }) {

    function ErzeugeArea({icon, label, url}) {
        if (isMobil || (!isMobil && label === undefined)) {
            return <i className={icon} style={{'fontSize': '1.5em', marginLeft: 10, cursor: url !== undefined ? "pointer" : "default"}} onClick={() => url !== undefined && window.open(url, "_self")}></i>
        } else {
            return <>
                <i className={icon} style={{'fontSize': '1.5em', marginLeft: 10, cursor: url !== undefined ? "pointer" : "default"}} onClick={() => url !== undefined && window.open(url, "_self")}></i>
                <span style={{fontSize: "1.5em", marginLeft: 5, cursor: url !== undefined ? "pointer" : "default"}}  onClick={() => url !== undefined && window.open(url, "_self")}>{label}</span>
            </>
        }
    }

    const erzeugeZwischenraum = () => {
        return <ErzeugeArea icon={"pi pi-chevron-right"}/>
    }

    const getEventoperatorLink = () => {
        return ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_EVENTOPERATOR.route + "/" + eventOperatorId;
    }

    return <div style={{
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        padding: 5,
        paddingTop: 10,
        paddingBottom: 5
    }}>
        {!isMobil &&
            <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                <img alt="Logo" src={"images/darthelfer_quer.png"} style={{height: 25, marginLeft: 20}}/>
            </NavLink>
        }
        {<ErzeugeArea icon={"pi pi-home"} url={ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.HOME.route}/>}
        {erzeugeZwischenraum()}
        {<ErzeugeArea icon={PrimeIcons.FOLDER} label="Events"  url={ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route}/>}
        {eventOperatorId !== undefined && <>
            {erzeugeZwischenraum()}
            <ErzeugeArea icon={PrimeIcons.ID_CARD} label={eventOperatorBezeichnung} url={getEventoperatorLink()}/>
        </>
        }
        {tournamentId !== undefined && tournamentId !== null && <>
            {erzeugeZwischenraum()}
            <ErzeugeArea icon={PrimeIcons.FOLDER_OPEN} label={tournamentBezeichnung} url={ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + tournamentId}/>
        </>
        }
        {isLiveView && <>
            {erzeugeZwischenraum()}
            <ErzeugeArea icon={PrimeIcons.EYE} label="Live"/>
        </>
        }
    </div>
}

