import {SET_CURRENT_EVENT_OPERATOR} from "../actions/eventoperator";

function chat(state = {
    currentEventOperator: undefined,
}, action) {
    switch (action.type) {
        case SET_CURRENT_EVENT_OPERATOR:
            return {...state, currentEventOperator: action.eventOperator};
        default:
            return state;
    }
}

export default chat;
