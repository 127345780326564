import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import EventOperatorTable from "./EventOperatorTable";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import ProgressButton from "../../../general/ProgressButton";
import {PrimeIcons} from "primereact/api";

export default function PublicEventoperatorSearchTab() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [eventOperators, setEventOperators] = useState([]);
    let [text, setText] = useState("");
    let [working, setWorking] = useState(false);

    const search = () => {
        setWorking(true);
        setErrorMessage(undefined);
        let data = {text: text};
        let url = ConfigUtil.getConfig().resourceUrls.public + "/eventoperators";
        FetchUtil.fetchPost(url,
            data,
            json => {
                if(json.eventOperators.length === 0) {
                    setInfoMessage("Keine Veranstalter gefunden.");
                }
                setEventOperators(json.eventOperators);
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error);
                setWorking(false);
            }
        );

    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{fontSize:16}}>
            Suche deinen Veranstalter mit mindestens 5 Zeichen, danach kannst du in seinen Bereich navigieren.
        </div>
        <div style={{marginTop:10, marginBottom:20, display: "flex"}}>
            <InputText style={{marginRight:5}} onChange={(e) => setText(e.target.value)} value={text}/>
            <ProgressButton text="Suchen"
                            icon={PrimeIcons.SEARCH}
                            working={working}
                            disabled={text.length < 5} onClick={() => search()}/>
        </div>
        <EventOperatorTable eventOperators={eventOperators} />
    </>
}
