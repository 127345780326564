import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DateUtil from "../../util/DateUtil";


export default function ChatComponent(props) {

    let [message, setMessage] = useState("");

    const save = () => {
        if( message === "") {
            return;
        }

        let data = {type: props.type, typeId: props.id, message, userId: props.userId};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.chat,
            data,
            json => {
                setMessage("");
            },
            responseNotOk => {
                console.log("Fehler beim Speichern: " + responseNotOk.message);
                props.callBackOnError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                console.log("Fehler beim Speichern: " + error.message);
                props.callBackOnError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const keyDown = (e) => {
        if (e.keyCode === 13) {
            // enter
            save();
        }
    }

    const getRows = () => {
        if( props.messages === null || props.messages === undefined) {
            return [];
        }
        let rows = [];
        for (const tmp of props.messages) {
            let d = new Date(tmp.time);
            let date = DateUtil.localDateTimeToString(d);
            let name = tmp.userId === props.userId ? date + " / " + tmp.userName: tmp.userName + " / " + date;
            rows.push(<div style={{marginBottom:10}}>
                <div style={{padding:5, backgroundColor:"#ac1c27", color:"white", textAlign: tmp.userId === props.userId ? 'right' : 'left'}}>{name}</div>
                <div style={{textAlign: tmp.userId === props.userId ? 'right' : 'left'}}>{tmp.message}</div>
            </div>)
        }
        return rows;
    }

    return <div>
        <div className="grid" style={{margin:0, padding:0}}>
            <div className="col" style={{width: "80%", marginRight: 0, paddingRight: 0}}>
                <InputText value={message} style={{width: "100%"}}
                           onChange={(e) => setMessage(e.target.value)}
                           onKeyDown={(e) => keyDown(e)} maxLength="500"/>
                ({message.length}/500)
            </div>
            <div className="col-fixed" style={{width: 40, marginRight: 5, marginLeft: 0, paddingLeft: 0}}>
                <Button icon="pi pi-check"
                        tooltip="Speichern"
                        onClick={() => save()}/>
            </div>
        </div>
        <div style={{padding:10, marginTop:20}}>
            {getRows()}
        </div>
    </div>
}
