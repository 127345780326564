import React from 'react'

import Turniergame from "./Turniergame";
import PlayerUtil from "../../../util/PlayerUtil";

export default function SchweizerSystemRunde({tournament, userId, isReadOnly, isSetModus, selectedPlayerId, boardGames, liveGames, groupGames, gruppe, runde, onRefresh, onPlayerSelect}) {

    const getRows = () => {
        let rows = [];
        for (const game of groupGames) {
            rows.push(<Turniergame tournament={tournament} tournamentGroup={runde} userId={userId}
                                   gruppennummer={runde}
                                   game={game}
                                   readOnly={isReadOnly}
                                   callBackRefresh={onRefresh}
                                   callbackGetSpielerName={(playerId) => PlayerUtil.getSpielerName(tournament.tournamentPlayerEntities, playerId)}
                                   selectedPlayerId={selectedPlayerId}
                                   boardGames={boardGames}
                                   liveGames={liveGames}
                                   onPlayerSelect={(playerId) => onPlayerSelect(playerId)}/>);
        }
        return rows;
    }

    return <div style={{minWidth: 400, backgroundColor: "white", color: "black", textAlign: "left", marginTop: 10}}>
        <div style={{backgroundColor: "#26a69a", color: "#f2f2f2", fontSize: 16, fontWeight: "bold", textAlign: "center", padding: 10}}>
            <div>Gruppe {gruppe} / Runde {runde} - Games</div>
        </div>
        <div style={{minWidth: 400, marginTop: 10}}>
            {getRows()}
        </div>

    </div>
}

