import React from "react";
import PropTypes from "prop-types";
import ConfigUtil from "../../../util/ConfigUtil";

function LigaUrkunden({tournamentId, rangliste, tabelleUrkundeDtos, callbackGetSpielername,rangliste171er, rangliste180er, ranglisteHighscores, ranglisteHighfinishes, ranglisteBestDarts}) {

    const getLink = (playerId, type, anzahl) => {
        return ConfigUtil.getConfig().resourceUrls.urkunden + "/" + tournamentId + "/" + playerId + "/" + type + "?anzahl=" + anzahl;
    }

    const getBestDartsRows = () => {
        let rows =[];
        if( ranglisteBestDarts.length === 0) {
            rows.push(<div>Keine Einträge vorhanden!!!</div>);
            return rows;
        }
        let anzahldarts = undefined;
        for (const row of ranglisteBestDarts) {
            if( anzahldarts === undefined) {
                anzahldarts = row.anzahldarts;
            }
            if( row.anzahldarts > anzahldarts) {
                continue;
            }

            rows.push(<div>
                <a href={getLink(row.id, "bestdarts", anzahldarts)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>
                {callbackGetSpielername(row.id)}</div>)
        }
        return rows;
    }

    const getHighscoresRows = () => {
        let rows =[];
        if( ranglisteHighscores.length === 0) {
            rows.push(<div>Keine Einträge vorhanden!!!</div>);
            return rows;
        }
        for (const row of ranglisteHighscores) {
            rows.push(<div>
                <a href={getLink(row.id, "highscores", row.anzahl)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>
                {callbackGetSpielername(row.id)}</div>)
        }
        return rows;
    }

    const getHighfinishesRows = () => {
        let rows =[];
        if( ranglisteHighfinishes.length === 0) {
            rows.push(<div>Keine Einträge vorhanden!!!</div>);
            return rows;
        }
        for (const row of ranglisteHighfinishes) {
            rows.push(<div>
                <a href={getLink(row.id, "highfinishes", row.anzahl)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>
                {callbackGetSpielername(row.id)}</div>)
        }
        return rows;
    }

    const get171erRows = () => {
        let rows =[];
        if( rangliste171er.length === 0) {
            rows.push(<div>Keine Einträge vorhanden!!!</div>);
            return rows;
        }
        for (const row of rangliste171er) {
            rows.push(<div>
                <a href={getLink(row.id, "171er", row.anzahl)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>
                {callbackGetSpielername(row.id)}</div>)
        }
        return rows;
    }

    const get180erRows = () => {
        let rows =[];
        if( rangliste180er.length === 0) {
            rows.push(<div>Keine Einträge vorhanden!!!</div>);
            return rows;
        }
        for (const row of rangliste180er) {
            rows.push(<div>
                <a href={getLink(row.id, "180er", row.anzahl)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>
                {callbackGetSpielername(row.id)}
            </div>)
        }
        return rows;
    }

    const getUrkundeFuerPlatz = (platz) => {
        let plaetze = tabelleUrkundeDtos.filter(value => value.platz === platz);
        let rows = [];
        for (const platzDto of plaetze) {
            rows.push(<div>
                <a href={getLink(platzDto.playerid, "platz", platzDto.platz)}>
                    <img src="/images/pdf.png" style={{width: 30, marginRight: 20}} alt="PDF"/>
                </a>{callbackGetSpielername(platzDto.playerid)}
            </div>);
        }
        return rows;
    }

    const getPlatz1 = () => {
        return <>
            <h3>Platz 1</h3>
            {tabelleUrkundeDtos !== undefined && <div>
                {getUrkundeFuerPlatz(1)}
            </div>
            }
            {rangliste !== undefined && <div>
                <a href={getLink(rangliste[0].playerid, "platz", 1)}>
                    <img src="/images/pdf.png" style={{width: 30, marginRight: 20}} alt="PDF"/>
                </a>{callbackGetSpielername(rangliste[0].playerid)}
            </div>
            }
        </>
    }

    const getPlatz2 = () => {
        return <>
            <h3>Platz 2</h3>
            {tabelleUrkundeDtos !== undefined && <div>
                {getUrkundeFuerPlatz(2)}
            </div>
            }
            {rangliste !== undefined && <div>
                <a href={getLink(rangliste[1].playerid, "platz", 2)}>
                    <img src="/images/pdf.png" style={{width: 30, marginRight: 20}} alt="PDF"/>
                </a>{callbackGetSpielername(rangliste[1].playerid)}
            </div>
            }
        </>
    }

    const getPlatz3 = () => {
        return <>
            <h3>Platz 3</h3>
            {tabelleUrkundeDtos !== undefined && <div>
                {getUrkundeFuerPlatz(3)}
            </div>
            }
            {rangliste !== undefined && <div>
                <a href={getLink(rangliste[2].playerid, "platz", 3)}>
                    <img src="/images/pdf.png" style={{width:30, marginRight:20}} alt="PDF"/>
                </a>{callbackGetSpielername(rangliste[2].playerid)}
            </div>
            }
        </>
    }

    return <div style={{textAlign:"left", padding:20}}>
        {getPlatz1()}
        {getPlatz2()}
        {getPlatz3()}
        <h3>180er</h3>
        {get180erRows()}
        <h3>171er</h3>
        {get171erRows()}
        <h3>Best Dart</h3>
        {getBestDartsRows()}
        <h3>Highfinishes</h3>
        {getHighfinishesRows()}
        <h3>Highscores</h3>
        {getHighscoresRows()}
    </div>
}


LigaUrkunden.propTypes = {
    tournamentId: PropTypes.string.isRequired,
    rangliste: PropTypes.array,
    tabelleUrkundeDtos: PropTypes.array,
    rangliste171er: PropTypes.array.isRequired,
    rangliste180er: PropTypes.array.isRequired,
    ranglisteHighfinishes: PropTypes.array.isRequired,
    ranglisteHighscores: PropTypes.array.isRequired,
    ranglisteBestDarts: PropTypes.array.isRequired,
    callbackGetSpielername: PropTypes.func.isRequired,
};

export default LigaUrkunden;
