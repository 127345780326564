import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import {PickList} from 'primereact/picklist';
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function TurnierSetzlisteKoDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [target, setTarget] = useState([]);
    const [source, setSource] = useState([]);

    useEffect(() => {
        if( !tournament) {
            return;
        }
        init();

    }, [tournament]);

    const init = () => {
        if (tournament !== undefined) {
            let tmpSource = [];
            let tmpTarget = [];
            if( tournament.tournamentPlayerEntities !== undefined && tournament.tournamentPlayerEntities !== null ) {
                for (const player of tournament.tournamentPlayerEntities) {
                    let isInSetzliste = false;
                    if( tournament.tournamentSetzlisteEntities !== undefined && tournament.tournamentSetzlisteEntities !== null ) {
                        for (const setzlisteeintrag of tournament.tournamentSetzlisteEntities) {
                            if( setzlisteeintrag.playerid === player.id ) {
                                isInSetzliste = true;
                            }
                        }
                    }
                    if( !isInSetzliste) {
                        tmpSource.push(player);
                    }
                }
            }
            if( tournament.tournamentSetzlisteEntities !== undefined && tournament.tournamentSetzlisteEntities !== null ) {
                for (const setzlisteeintrag of tournament.tournamentSetzlisteEntities) {
                    let tmpPlayer;
                    for (const player of tournament.tournamentPlayerEntities) {
                        if( player.id === setzlisteeintrag.playerid) {
                            tmpPlayer = player;
                        }
                    }
                    tmpTarget.push(tmpPlayer);
                }
            }

            setSource(tmpSource);
            setTarget(tmpTarget)
        }
    }

    const save = () => {
        let setzliste = [];
        for (const spieler of target) {
            setzliste.push({id: undefined, playerid: spieler.id});
        }
        let id = tournament.id;
        let data = {tournamentId: id, tournametSetzlisteEinzelnDtoList: setzliste};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/savesetzliste",
            data,
            () => {
                setInfoMessage("Speichern war erfolgreich!")
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const itemTemplate = (item) => {
        if( item === null ) {
            return "";
        }
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <span className="product-category">{item.name}</span>
                </div>
            </div>
        );
    }

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }

    const getSpieler = () => {
        return source;
    }

    const getSetzliste = () => {
        return target;
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" onClick={() => save()}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()}
                    className="p-button-secondary"/>
        </div>
    )

    return <DHDialog title="Setzliste bearbeiten" buttons={dialogFooter}
            onClose={() => onClose()} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweise..." style={{marginBottom:20}} toggleable={true}>
            <div>- Hier kannst du die Setzliste der Turnierteilnehmer festlegen.</div>
            <div>- Sofern keine Spieler gesetzt werden sollen, nimmst du natürlich keine Einstellung vor.</div>
        </Fieldset>
        <PickList source={getSpieler()} target={getSetzliste()} itemTemplate={itemTemplate}
                  sourceHeader="Alle Spieler" targetHeader="Setzliste"
                  sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                  onChange={onChange}
                  showSourceControls={false}></PickList>
    </>}/>
}