import React from 'react'
import PropTypes from "prop-types";
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import BoardSelectionDialog from "../veranstalter/BoardSelectionDialog";
import StatistikGameDialog from "../public/tournaments/StatistikGameDialog";

class Turniergame extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            showBoardSelectionDialog: false,
            showGameDetailDialog: false,
            anzahlSpieler1: this.props.game.anzahlSpieler1 != null ? this.props.game.anzahlSpieler1 : "",
            anzahlSpieler2: this.props.game.anzahlSpieler2 != null ? this.props.game.anzahlSpieler2 : "",
        };

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.openEdit = this.openEdit.bind(this);
    }

    handelKeyDown(e) {
        if (e.keyCode === 13) {
            // enter
            this.save();
        }
    }

    openEdit(aenderungsmodus) {
        if( this.props.readOnly) {
            return;
        }
        if( !aenderungsmodus) {
            return;
        }

        if( this.props.game.spielOhneErgebnis) {
            return;
        }
        this.setState({edit: true});
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    changeInput(e, name) {
        if( !isNaN(e.target.value)) {
            this.setState({[name]: e.target.value});
        }
    }

    save() {
        this.messages.clear();
        if( this.state.anzahlSpieler1 === "" || this.state.anzahlSpieler2 === "" ) {
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changegroupgame";
        let data = {
            tournamentid: this.props.tournament.id,
            tournamentGameId: this.props.game.id,
            feld: undefined,
            player1Id: this.props.game.spieler1,
            player2Id: this.props.game.spieler2,
            anzahlPlayer1: this.state.anzahlSpieler1,
            anzahlPlayer2: this.state.anzahlSpieler2,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                this.showInfo("Ergebnis wurde erfolgreich gespeichert.");
                this.setState({edit:false});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern des Ergebnisses: " + error.message);
            });
    }

    delete() {
        this.messages.clear();
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/changegroupgame";
        let data = {
            tournamentid: this.props.tournament.id,
            tournamentGameId: this.props.game.id,
            feld: undefined,
            player1Id: this.props.game.spieler1,
            player2Id: this.props.game.spieler2,
            anzahlPlayer1: undefined,
            anzahlPlayer2: undefined,
        };
        FetchUtil.fetchPost(url,
            data,
            () => {
                this.showInfo("Ergebnis wurde erfolgreich gelöscht.");
                this.setState({edit:false, anzahlSpieler1:"", anzahlSpieler2:""});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen des Ergebnisses: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Löschen des Ergebnisses: " + error.message);
            });
    }

    getGameId(turniergameid) {
        if( turniergameid !== undefined && turniergameid !== null && this.props.tournament.games !== null && this.props.tournament.games !== undefined) {
            for (const game of this.props.tournament.games) {
                if( game.turniergameid === turniergameid) {
                    return game.id;
                }
            }
        }
        return undefined;
    }

    isAktuellerUserImGame() {
        return this.props.game.spieler1 === this.props.userId || this.props.game.spieler2 === this.props.userId;
    }

    getBoardGame() {
        if( this.props.boardGames === null || this.props.boardGames === undefined) {
            return undefined;
        }
        for (const boardGame of this.props.boardGames) {
            if( boardGame.tournamentGameId === this.props.game.id) {
                return boardGame;
            }
        }
        return undefined;
    }

    getLiveGame(boardGame) {
        if( this.props.liveGames === null || this.props.liveGames === undefined || boardGame === null || boardGame === undefined) {
            return undefined;
        }
        let gameId = boardGame.gameId;
        let tmpGames = this.props.liveGames.find(game => game.gameId === gameId);
        return tmpGames
    }

    getLiveGameErgebnis(liveGame) {
        if( liveGame.startssoon) {
            return <img src="/images/dot_red.png" style={{width:20}}/>
        }
        if( liveGame.inGame) {
            return <img src="/images/dot_green.png" style={{width:20}}/>
        }
        if( liveGame.sets > 1 ) {
            return <span style={{opacity:0.5}}>{liveGame.spieler1Sets}:{liveGame.spieler2Sets}</span>
        } else {
            return <span style={{opacity:0.5}}>{liveGame.spieler1Legs}:{liveGame.spieler2Legs}</span>
        }
    }

    render() {
        let spieler1 = this.props.callbackGetSpielerName(this.props.game.spieler1);
        let spieler2 = !this.props.game.spielOhneErgebnis ? this.props.callbackGetSpielerName(this.props.game.spieler2) : "-";
        let spieler1Id = this.props.game.spieler1;
        let spieler2Id = this.props.game.spieler2;
        let schreiberName = this.props.game.schreiberName;
        let beideSpielerVorhanden = this.props.game.spieler1 !== null && this.props.game.spieler2 !== null;
        let anzahlSpieler1 = this.props.game.anzahlSpieler1 != null ? this.props.game.anzahlSpieler1 : "";
        let anzahlSpieler2 = this.props.game.anzahlSpieler2 != null ? this.props.game.anzahlSpieler2 : "";
        let ergebnis = anzahlSpieler1 !== "" && anzahlSpieler2 !== "" ? anzahlSpieler1 + ":" + anzahlSpieler2 : "";
        let aenderungsmodus = this.props.tournament.folgeTurnierId === null || this.props.tournament.folgeTurnierId === undefined;
        let ergebnisVorhanden = anzahlSpieler1 !== null && anzahlSpieler1 !== undefined && anzahlSpieler1 !== "";

        let player1Style = {backgroundColor: ergebnisVorhanden && Number(anzahlSpieler1) > Number(anzahlSpieler2) ? "#6b9d25" : ""};
        let player2Style = {backgroundColor: ergebnisVorhanden && Number(anzahlSpieler2) > Number(anzahlSpieler1) ? "#6b9d25" : ""};
        if( this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler1Id ) {
            player1Style = {...player1Style, backgroundColor: "#FD8943"};
        }
        if( this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === spieler2Id ) {
            player2Style = {...player2Style, backgroundColor: "#FD8943"};
        }
        let schreiberStyle = this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === this.props.game.schreiberId ? {backgroundColor: "#FD8943"} : {};
        let boardGame = this.getBoardGame();
        let liveGame = this.getLiveGame(boardGame);
        return <div>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            {this.state.showGameDetailDialog &&
                <StatistikGameDialog tournamentId={this.props.tournament.id} tournamentGameId={this.props.game.id} onClose={() => {
                    this.setState({showGameDetailDialog: false});
                }}/>
            }
            <div style={aenderungsmodus ? {padding: 5, cursor:"pointer"}:{padding: 5}}>
                {boardGame !== undefined && <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20, marginTop:10}}>Wird gespielt an: {boardGame.boardBezeichnung}</div>}
                {boardGame !== undefined && boardGame.schreiberId !== null && boardGame.schreiberId !== undefined && <div style={{textAlign:"center", backgroundColor:"#ac1c27", color:"white", fontSize:20}}>Schreiber: {boardGame.schreiberName}</div>}
                <div className="grid">
                    <div className="col-fixed" style={{width: 20}} onClick={() => this.openEdit(aenderungsmodus)}>{this.props.game.spielrunde}</div>
                    <div className="col" style={player1Style} onMouseOver={() => this.props.onPlayerSelect(spieler1Id)} onMouseOut={() => this.props.onPlayerSelect(undefined)} onClick={() => this.openEdit(aenderungsmodus)}>{spieler1}</div>
                    <div className="col" style={player2Style} onMouseOver={() => this.props.onPlayerSelect(spieler2Id)} onMouseOut={() => this.props.onPlayerSelect(undefined)} onClick={() => this.openEdit(aenderungsmodus)}>{spieler2}</div>
                    <div className="col-fixed" style={{width: 40}} onClick={() => {
                        if( ergebnisVorhanden && beideSpielerVorhanden && this.props.tournament.scoringWithDarthelfer) {
                            this.setState({showGameDetailDialog: true})
                        }
                    }}>
                        {ergebnis === "" && this.getGameId(this.props.game.id) !== undefined && this.isAktuellerUserImGame() &&
                            <NavLink to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + this.getGameId(this.props.game.id) + "&playerid=" + this.props.userId} style={{textDecoration: "none"}}>
                                <img src="/images/dart_select.png" style={{cursor:"pointer", width: 25}} alt=""/>
                            </NavLink>
                        }
                        {ergebnis !== "" && ergebnis}
                        {(aenderungsmodus && !this.props.readOnly && !this.props.tournament.online && ergebnis === "" && beideSpielerVorhanden) &&
                            <img src="/images/kneipe.png" alt="Board vergeben" title="Board vergeben" style={{width: 20}}
                                 onClick={() => this.setState({showBoardSelectionDialog: true}) }/>
                        }
                        {!ergebnisVorhanden && liveGame !== undefined && this.getLiveGameErgebnis(liveGame)}
                    </div>
                </div>
                {(boardGame === null || boardGame === undefined) && this.props.game.schreiberName !== null && this.props.game.schreiberName !== undefined && this.props.game.schreiberName !== "" && <>
                    <div style={schreiberStyle}>Schreiber: {this.props.game.schreiberName}</div>
                    <hr/>
                </>
                }
            </div>
            {this.state.showBoardSelectionDialog && <BoardSelectionDialog onClose={() => this.setState({showBoardSelectionDialog: false})}
                                                                          eventOperatorId={this.props.tournament.eventOperatorId}
                                                                          spieler1Name={spieler1} spieler2Name={spieler2}
                                                                          schreiberName={schreiberName}
                                                                          showSchreiberArea={true}
                                                                          callBackLoadData={this.props.callBackRefresh}
                                                                          players={this.props.tournament.tournamentPlayerEntities}
                                                                          tournamentId={this.props.tournament.id} tournamentGameId={this.props.game.id}/>}
            {this.state.edit &&
            <div className="grid" style={{padding: 0}}>
                <div className="col-fixed" style={{width:100, marginLeft: 50}}>
                    <InputText style={{width: 30}} value={this.state.anzahlSpieler1}
                               onChange={(e) => this.changeInput(e,"anzahlSpieler1")}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>  :
                    <InputText style={{width: 30}} value={this.state.anzahlSpieler2}
                               onChange={(e) => this.changeInput(e,"anzahlSpieler2")}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>
                </div>
                <div className="col-fixed" style={{width: 40}}>
                    <Button icon="pi pi-check" disabled={this.state.anzahlSpieler1 === "" || this.state.anzahlSpieler2 === ""} tooltip="Spiel speichern" style={{marginLeft:5, marginRight:5}} onClick={()=> this.save()}/>
                </div>
                <div className="col-fixed" style={{width: 40}}>
                    <Button icon="pi pi-times-circle" onClick={() => this.delete()} tooltip="Spielergebnis entfernen" style={{marginLeft:5, marginRight:5}}/>
                </div>
                <div className="col-fixed" style={{width: 40}}>
                    <Button label="Schliessen" onClick={() => this.setState({edit:false})} tooltip="Bereich schliessen" style={{marginLeft:5, marginRight:5}}/>
                </div>
            </div>
            }
        </div>;
    }
}

Turniergame.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentGroup: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    gruppennummer: PropTypes.number.isRequired,
    game: PropTypes.object.isRequired,
    callbackGetSpielerName: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    onPlayerSelect: PropTypes.func.isRequired,
    selectedPlayerId: PropTypes.string.isRequired,
    boardGames: PropTypes.array,
    liveGames: PropTypes.array,
};

export default Turniergame
