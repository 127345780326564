import React, {useRef, useState} from 'react'
import {Button} from "primereact/button";
import TournamentPrioArea from "./TournamentPrioArea";
import BoardBelegungArea from "./BoardBelegungArea";
import BoardAufrufArea from "./BoardAufrufArea";
import BoardVergabeArea from "./BoardVergabeArea";
import {Menu} from "primereact/menu";
import {PrimeIcons} from "primereact/api";
import TeilnehmerPrioArea from "./TeilnehmerPrioArea";
import TurnierSchreiberArea from "../../liga/TurnierSchreiberArea";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHMobileDiv from "../../../general/DHMobileDiv";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import BoardTeilenArea from "./BoardTeilenArea";
import DHDialog from "../../../general/DHDialog";


export default function RundUmsBoardDialog({eventOperatorId, onClose}) {
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [refreshCounter, setRefreshCounter] = useState(0);

    const menu = useRef(null);

    const [tabValue, setTabValue] = useState("boardbelegung");

    const boardvergabeAnstossen = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/boardvergabeanstossen/" + eventOperatorId,
            {},
            json => {
                setInfoMessage("Boardvergabe wurde angestoßen!");
                setRefreshCounter(refreshCounter+1);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Anstossen der Boardvergabe: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Anstossen der Boardvergabe: " + error.message);
            })
    }


    const erzeugeTab = (value, key, width) => {
        return <div style={{fontSize: 16, width:width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Boardbelegung" ,"boardbelegung", 180));
        returnValue.push(erzeugeTab("Boardvergabe" ,"boardvergabe", 180));
        returnValue.push(erzeugeTab("Boardaufruf" ,"boardauruf", 180));
        returnValue.push(erzeugeTab("Schreiber" ,"schreiber", 100));
        returnValue.push(erzeugeTab("Turnierpriorität" ,"tournamentprio", 200));
        returnValue.push(erzeugeTab("Teilnehmerpriorität" ,"teilnehmerprio", 220));
        returnValue.push(erzeugeTab("Teilen" ,"teilen", 100));
        return returnValue;
    }

    const getMenuItems = () => {
        let returnValue = [];
        returnValue.push({label: 'Boardbelegung', icon: PrimeIcons.FOLDER, command: () => setTabValue("boardbelegung"), style:{borderLeft: tabValue === "boardbelegung" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Boardvergabe', icon: PrimeIcons.FOLDER, command: () => setTabValue("boardvergabe"), style:{borderLeft: tabValue === "boardvergabe" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Boardaufruf', icon: PrimeIcons.FOLDER, command: () => setTabValue("boardauruf"), style:{borderLeft: tabValue === "boardauruf" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Schreiber', icon: PrimeIcons.FOLDER, command: () => setTabValue("schreiber"), style:{borderLeft: tabValue === "schreiber" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Turnierpriorität', icon: PrimeIcons.FOLDER, command: () => setTabValue("tournamentprio"), style:{borderLeft: tabValue === "tournamentprio" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Teilnehmerpriorität', icon: PrimeIcons.FOLDER, command: () => setTabValue("teilnehmerprio"), style:{borderLeft: tabValue === "teilnehmerprio" ? "10px solid #ac1c27" : "none"}});
        returnValue.push({label: 'Teilen', icon: PrimeIcons.FOLDER, command: () => setTabValue("teilen"), style:{borderLeft: tabValue === "teilen" ? "10px solid #ac1c27" : "none"}});
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "boardauruf" ) {
            return <BoardAufrufArea eventOperatorId={eventOperatorId}/>;
        } else if( tabValue === "tournamentprio" ) {
            return <TournamentPrioArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "boardbelegung" ) {
            return <BoardBelegungArea eventOperatorId={eventOperatorId} refreshCounter={refreshCounter}/>;
        } else if( tabValue === "boardvergabe" ) {
            return <BoardVergabeArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "teilnehmerprio" ) {
            return <TeilnehmerPrioArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "schreiber" ) {
            return <TurnierSchreiberArea eventOperatorId={eventOperatorId} />;
        } else if( tabValue === "teilen" ) {
             return <BoardTeilenArea eventOperatorId={eventOperatorId} />;
        }
    }

    return <DHDialog title="Rund ums Board" showCloseButton={true} onClose={() => onClose()} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <DHMobileDiv comp={
            <div>
                <i className="pi pi-bars" style={{margin:10, fontSize:50}} onClick={(e) => menu.current.toggle(e)} />
                <Menu model={getMenuItems()} popup ref={menu} style={{fontSize:30, width:300}}/>
            </div>
        }/>
        <div style={{marginBottom:20}}>
            <Button onClick={() => boardvergabeAnstossen()} style={{width:"100%"}}>Es zwickt im Turnierverlauf? Stosse hier die automatische Boardvergabe erneut an!</Button>
        </div>
        <DHDesktopDiv comp={
            <div style={{marginTop:10, marginBottom:20}}>
                <div style={{display:"flex"}}>
                    {getTabs()}
                </div>
            </div>
        }/>
        {getTabpanel()}
    </>}/>
}
