import React, {useState} from 'react'
import {LigaSpieltagComponent} from "../../liga/ligaComponents";


export default function PublicLigaSpieltageTab({tournament, boardGames}) {

    let [selectedPlayerId, setSelectedPlayerId] = useState(undefined);

    const getHoechsterSieltag = (spieltag) => {
        let hoechsterSpieltag = 0
        let gruppenEintraege = tournament.tournamentGroupGameEntities;
        if( gruppenEintraege === null || gruppenEintraege === undefined) {
            return hoechsterSpieltag;
        }
        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde > hoechsterSpieltag) {
                hoechsterSpieltag = eintrag.spielrunde;
            }
        }
        return hoechsterSpieltag;
    }


    const getGamesBySpieltag = (spieltag) => {
        let liga = tournament
        let gruppenEintraege = liga.tournamentGroupGameEntities;
        let games = []
        if( gruppenEintraege === null || gruppenEintraege === undefined) {
            return games;
        }

        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde === spieltag) {
                games.push(eintrag);
            }
        }
        return games;

    }

    const getSpielerName = (id) => {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < tournament.tournamentPlayerEntities.length; i++) {
            let spieler = tournament.tournamentPlayerEntities[i];
            // es kann die userid oder playerid oder eine doppelpartnerId drinnen sein.
            if (spieler.userId === id || spieler.id === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        return "Freilos";
    }

    const getArea = () => {
        let hoechsterSpieltag = getHoechsterSieltag();
        let cols = []
        for(let i = 1 ; i <= hoechsterSpieltag ;i++) {
            let games = getGamesBySpieltag(i);
            if( games.length > 0 ) {
                cols.push(<LigaSpieltagComponent
                    readonly={true}
                    showGames={true}
                    selectedPlayerId={selectedPlayerId}
                    onPlayerSelect={(playerId) => setSelectedPlayerId(playerId)}
                    boardGames={boardGames}
                    spieltag={i} games={games}
                    liga={tournament}
                    refresh={() => ""}
                    userId={undefined}
                    currentUserPlayerId={""}
                    getSpielerName={getSpielerName}
                    callbackHasPlayersMeetingkeyAllowed={() => ""}
                    callbackIsSpielerInaktiv={() => ""} key={"ligadetail_spieltag_" + i}/>);
            }
        }
        return  <div className="grid">
                {cols}
        </div>;
    }

    return <div style={{backgroundColor:"#022836", padding:5, paddingTop:20}}>
        {getArea()}
    </div>
}

