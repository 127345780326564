import React, {useEffect, useState} from 'react'
import RanglistePlatzLine from "./RanglistePlatzLine";
import {Button} from "primereact/button";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import PropTypes from "prop-types";

function RanglisteTypView({eventOperatorId, clearMessage, showError, showInfo, typ, anzahlPersonen, plaetzeAuswahl}) {
    const [punkteFuerTeilnahme, setPunkteFuerTeilnahme] = useState(0);
    const [punkteGewonnenesSpiel, setPunkteGewonnenesSpiel] = useState(0);
    const [punkteVerlorenesSpiel, setPunkteVerlorenesSpiel] = useState(0);

    const [plaetze, setPlaetze] = useState([]);

    useEffect( () => {
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.rangliste + "/getpoints/" + typ;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.rangliste + "/getpoints/eventoperator/" + eventOperatorId + "/" + typ;
        }
        FetchUtil.fetchPost(url,
            {},
            json => {
                setPunkteFuerTeilnahme(json.punkteFuerTeilnahme);
                setPunkteGewonnenesSpiel(json.punkteGewonnenesSpiel);
                setPunkteVerlorenesSpiel(json.punkteVerlorenesSpiel);
                setPlaetze(json.plaetze !== undefined && json.plaetze !== null ? json.plaetze : []);
            },
            responseNotOk => {
                showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Laden der Daten " + error.message);
            }
        );

    } ,[]);

    const save = () => {
        clearMessage();
        // evtl wurde der wert eines feldes nicht geändert und beim laden war es auch noch nicht vorhanden. aus diesem grund über alle felder drüber gehen und im zweifelsfall mit 0 einfügen.
        let newPlaetze = plaetze;
        for (const platzAuswahl of plaetzeAuswahl) {
            let arrayGefunden = plaetze.filter(tmp => tmp.platz === platzAuswahl);
            if( arrayGefunden.length === 0 ) {
                // nix gefunden, aus dem grund einfügen.
                newPlaetze.push({platz: platzAuswahl, value: 0});
            }
        }
        let data = {typ: typ, "punkteFuerTeilnahme": punkteFuerTeilnahme ,"punkteGewonnenesSpiel": punkteGewonnenesSpiel , "punkteVerlorenesSpiel": punkteVerlorenesSpiel ,
            plaetze: newPlaetze};
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.rangliste + "/savepoints";
        } else {
            url = ConfigUtil.getConfig().resourceUrls.rangliste + "/savepoints/eventoperator/" + eventOperatorId;
        }

        FetchUtil.fetchPost(url,
            data,
            json => {
                showInfo("Es wurde erfolgreich gespeichert.");
            },
            responseNotOk => {
                showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    const getPlatzValue = (platz) => {
        for (const tmpPlatz of plaetze) {
            if( tmpPlatz.platz === platz ) {
                return tmpPlatz.value;
            }
        }
        return "";
    }

    const setPlatzValue = (platz, value) => {
        let tmpPlatze = plaetze.filter(tmp => tmp.platz !== platz);
        tmpPlatze.push({platz: platz, value: value !== null ? value : 0});
        setPlaetze(tmpPlatze);
    }

    const getArea = () => {
        let rows = [];
        for (const tmpPlatzAuswahl of plaetzeAuswahl) {
            rows.push(<RanglistePlatzLine beschriftung={tmpPlatzAuswahl + "."} value={getPlatzValue(tmpPlatzAuswahl)} onChangeValue={(value) => setPlatzValue(tmpPlatzAuswahl, value)}/>)
        }
        return rows;
    }

    return <div style={{textAlign:"left", padding:10}}>
        <div style={{marginBottom:20}}>Einstellungen für {typ} Turnieren mit einem {anzahlPersonen} Personen Spielplan (max. {anzahlPersonen} Personen im Turnier)</div>
        <div style={{marginBottom:5}}>Punkte für Teilnahme</div>
        <RanglistePlatzLine beschriftung="" value={punkteFuerTeilnahme} onChangeValue={setPunkteFuerTeilnahme}/>
        <br/>
        <div style={{marginBottom:5}}>Punkte je gewonnenes Spiel</div>
        <RanglistePlatzLine beschriftung="" value={punkteGewonnenesSpiel} onChangeValue={setPunkteGewonnenesSpiel}/>
        <br/>
        <div style={{marginBottom:5}}>Punkte je verlorenes Spiel</div>
        <RanglistePlatzLine beschriftung="" value={punkteVerlorenesSpiel} onChangeValue={setPunkteVerlorenesSpiel}/>
        <br/><br/>
        {getArea()}
        <div style={{marginTop:20}}><Button label="Speichern" onClick={() => save()}/></div>
    </div>
}

RanglisteTypView.propTypes = {
    eventOperatorId : PropTypes.string,
    plaetzeAuswahl : PropTypes.array.isRequired,
    typ : PropTypes.string.isRequired,
    anzahlPersonen : PropTypes.number.isRequired,
    clearMessage : PropTypes.func.isRequired,
    showError : PropTypes.func.isRequired,
    showInfo : PropTypes.func.isRequired,
}

export default RanglisteTypView;
