import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import LigaZuschauenArea from "./LigaZuschauenArea";
import Chat from "../../general/Chat";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {Panel} from "primereact/panel";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import ConfirmDialog from "../../general/ConfirmDialog";
import GamingAreaModusArea from "./GamingAreaModusArea";
import {getModusNameById} from "../../general/GameModusComponent";
import SoundComponent from "../../general/SoundComponent";

let stompClient = undefined;

class GamingArea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {onlineUsers: [], playSound: false, showGameDeleteDialog: false};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);

        this.websocketConnect = this.websocketConnect.bind(this);
        this.websocketDisConnect = this.websocketDisConnect.bind(this);
    }

    componentDidMount() {
        this.websocketConnect();
    }

    componentWillUnmount() {
        this.websocketDisConnect();
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    websocketDisConnect() {
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.disconnect(() => {
            });
        }
    }

    websocketConnect(turnierid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {
        }; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                // ***********
                // Reihenfolge der subscribes beibehalten
                // ***********
                const destinationOnlineUser = "/topic/public/gamingareaonlineusers";
                console.log("subscribe to " + destinationOnlineUser);
                stompClient.subscribe(destinationOnlineUser, (payload) => {
                    const body = JSON.parse(payload.body);
                    this.setState({onlineUsers: body});
                });
                const destinationGamingArea = "/topic/public/gamingarea/" + this.props.userId;
                console.log("subscribe to " + destinationGamingArea);
                stompClient.subscribe(destinationGamingArea, (payload) => {
                    const body = JSON.parse(payload.body);
                    let state = {gamesInviter: body.gamesInviter, gamesInvited: body.gamesInvited, games: body.games};
                    this.setState(state);
                });
                const destinationPing = "/topic/public/gamingareaping/" + this.props.userId;
                console.log("subscribe to " + destinationPing);
                stompClient.subscribe(destinationPing, () => {
                    this.setState({playSound: true})
                });
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    einladen(userId, modusId) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.gamingarea + "/einladen/" + userId + "/" + modusId,
            {},
            json => {
                this.showInfo("Spieler wurde eingeladen.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    einladungLoeschen(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.gamingarea + "/einladungloeschen/" + id,
            {},
            json => {
                this.showInfo("Einladung wurde gelöscht.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    einladungAnnehmen(id) {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.gamingarea + "/einladungannehmen/" + id,
            {},
            json => {
                this.showInfo("Einladung wurde angenommen.");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    gameLoeschen() {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.gamingarea + "/loeschegame/" + this.state.selectedDeleteGameId,
            {},
            () => {
                this.showInfo("Game wurde gelöscht.");
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId: undefined});
            },
            responseNotOk => {
                this.showError("Fehler beim Löschen: " + responseNotOk.message);
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId: undefined});
            },
            error => {
                this.showError("Fehler beim Löschen: " + error.message);
                this.setState({showGameDeleteDialog: false, selectedDeleteGameId: undefined});
            }
        );
    }

    getUsernamesByUserId(userId) {
        let user = this.state.onlineUsers.find(user => user.userId === userId);
        if( user !== null && user !== undefined) {
            return user.name;
        }
        return "kein Name vorhanden";
    }

    getUserAvgByUserId(userId) {
        let user = this.state.onlineUsers.find(user => user.userId === userId);
        if( user !== null && user !== undefined) {
            return user.average;
        }
        return "-";
    }

    isAkutellerUserOnline() {
        for (const onlineUser of this.state.onlineUsers) {
            if (onlineUser.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    getGamingArea() {
        let rowsUsersOnline = [];
        let rowsGamesInviter = [];
        let rowsGamesInvited = [];
        if (this.state.onlineUsers !== undefined && this.state.onlineUsers !== null) {
            for (const onlineUser of this.state.onlineUsers) {
                if (onlineUser.userId === this.props.userId) {
                    continue;
                }
                let name = onlineUser.name;
                let average = onlineUser.average;
                let namePlusAverge = name + " (Avg.: " + average + ")";
                let modusRows = [];
                if( onlineUser.modusListe === null || onlineUser.modusListe === undefined || onlineUser.modusListe.length === 0 ) {
                    modusRows.push(<div key={"key_empty_modusliste_" + onlineUser.userId}>Keine Moduseinträge vorhanden, daher kein Gaming möglich!</div>)
                } else {
                    for (const modus of onlineUser.modusListe) {
                        modusRows.push(<div key={"key_modus_" + modus.id}>
                            <hr style={{borderStyle: "dashed", size: 1}}/>
                            <div className="grid align-center vertical-container" style={{marginTop:10}}>
                                <div style={{float: "left", textAlign: "left", marginRight: 20}}>
                                    <Button icon="pi pi-arrow-circle-right"
                                            onClick={() => this.einladen(onlineUser.userId, modus.id)}
                                            style={{marginRight: 10, marginTop: 5}}
                                            tooltip={"Spieler einladen"}/>
                                </div>
                                <div style={{float: "left", textAlign: "left", marginRight: 20}}>
                                    Startpunkte: {modus.startpunkte}</div>
                                <div style={{float: "left", textAlign: "left",marginRight: 20}}>
                                    Modus: {getModusNameById(modus.modusid)}</div>
                                {modus.firstToLegs !== null && modus.firstToLegs !== undefined &&
                                <div style={{float: "left", textAlign: "left", marginRight: 20}}>First to
                                    legs: {modus.firstToLegs}</div>
                                }
                                {modus.bestOfLegs !== null && modus.bestOfLegs !== undefined &&
                                <div style={{float: "left", textAlign: "left", marginRight: 20}}>Best of legs: {modus.bestOfLegs}</div>
                                }
                            </div>
                            <br/>
                        </div>)
                    }
                }

                rowsUsersOnline.push(<div key={"key_online_user_" + onlineUser.userId} style={{padding:20}}>
                    <div className="grid">
                        <div className="col" style={{fontWeight:"bolder"}}>{namePlusAverge}</div>
                    </div>
                    {modusRows}
                    <hr/>
                </div>);
            }
        }
        if (this.state.gamesInvited !== undefined && this.state.gamesInvited !== null) {
            for (const game of this.state.gamesInvited) {
                let name = this.getUsernamesByUserId(game.userIdInviter)
                let average = this.getUserAvgByUserId(game.userIdInviter)
                let namePlusAverge = name + " (Avg.: " + average + ")";
                rowsGamesInvited.push(<div key={"key_invited_" + game.id}>
                    <div className="grid">
                        <div className="col">
                            <Button icon="pi pi-trash"
                                    onClick={() => this.einladungLoeschen(game.id)}
                                    style={{marginRight: 10, marginTop: 5}}
                                    tooltip={"Einladung ablehnen"}/>
                            <Button icon="pi pi-eye"
                                    onClick={() => this.einladungAnnehmen(game.id)}
                                    style={{marginRight: 10, marginTop: 5}}
                                    tooltip={"Einladung annehmen"}/>
                            {namePlusAverge}
                        </div>
                    </div>
                    <div className="grid" style={{paddingTop:20, paddingLeft:10, paddingRight:20}}>
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>
                            Startpunkte: {game.startpunkte}</div>
                        <div style={{float: "left", textAlign: "left",marginRight: 20}}>
                            Modus: {getModusNameById(game.modusid)}</div>
                        {game.firstToLegs !== null && game.firstToLegs !== undefined &&
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>First to
                            legs: {game.firstToLegs}</div>
                        }
                        {game.bestOfLegs !== null && game.bestOfLegs !== undefined &&
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>Best of
                            legs: {game.bestOfLegs}</div>
                        }
                    </div>
                    <hr style={{borderStyle: "dashed", size: 1}}/>
                </div>);
            }
        }
        if (this.state.gamesInviter !== undefined && this.state.gamesInviter !== null) {
            for (const game of this.state.gamesInviter) {
                let name = this.getUsernamesByUserId(game.userIdInvited)
                let average = this.getUserAvgByUserId(game.userIdInvited)
                let namePlusAverge = name + " (Avg.: " + average + ")";
                rowsGamesInviter.push(<div key={"key_inviter_" + game.id}>
                    <div className="grid">
                        <div className="col">
                            <Button icon="pi pi-trash"
                                    onClick={() => this.einladungLoeschen(game.id)}
                                    style={{marginRight: 10, marginTop: 5}}
                                    tooltip={"Einladung löschen"}/>
                            {namePlusAverge}
                        </div>
                    </div>
                    <div className="grid" style={{paddingTop:20, paddingLeft:10, paddingRight:20}}>
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>
                            Startpunkte: {game.startpunkte}</div>
                        <div style={{float: "left", textAlign: "left",marginRight: 20}}>
                            Modus: {getModusNameById(game.modusid)}</div>
                        {game.firstToLegs !== null && game.firstToLegs !== undefined &&
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>First to
                            legs: {game.firstToLegs}</div>
                        }
                        {game.bestOfLegs !== null && game.bestOfLegs !== undefined &&
                        <div style={{float: "left", textAlign: "left", marginRight: 20}}>Best of
                            legs: {game.bestOfLegs}</div>
                        }
                    </div>
                    <hr style={{borderStyle: "dashed", size: 1}}/>
                </div>);
            }
        }

        if (rowsUsersOnline.length === 0) {
            rowsUsersOnline.push(<div key="header_keine_teilnehmer_spielbereit" className="grid">
                <div className="col">--- keine Teilnehmer spielbereit ---</div>
            </div>);
        }
        if (rowsGamesInvited.length === 0) {
            rowsGamesInvited.push(<div key="header_keine_einladungen_vorhanden" className="grid">
                <div className="col">--- Keine Einladungen vorhanden ---</div>
            </div>);
        }
        if (rowsGamesInviter.length === 0) {
            rowsGamesInviter.push(<div key="header_keine_einladungen_vergeben" className="grid">
                <div className="col">--- Du hast keine Einladungen vergeben ---</div>
            </div>);
        }
        return <div style={{textAlign: "left",}}>
            <Panel header="Erklärung">
                <div style={{marginBottom: 10}}><i className="pi pi-eye" style={{marginRight: 10}}/> Spielanfrage
                    annehmen.
                </div>
                <div style={{marginBottom: 10}}><i className="pi pi-trash" style={{marginRight: 10}}/>Spielanfrage
                    ablehnen oder Spieleinladung löschen.
                </div>
                <div style={{marginBottom: 10}}><i className="pi pi-arrow-circle-right" style={{marginRight: 10}}/>Spieleinladung
                    absenden.
                </div>
                <div style={{marginBottom: 10}}><img src="/images/dart_select.png" alt="Spiel starten"
                                                     style={{width: 20, marginRight: 10}}/>Spiel ist angelegt und kann
                    gestartet werden.
                </div>
            </Panel>
            <div className="grid">
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Spieler die gerade spielbereit sind:</h4>
                    {rowsUsersOnline}
                </div>
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Spieler die dich eingeladen haben:</h4>
                    {rowsGamesInvited}
                </div>
                <div className="col-12 md:col-96 lg:col-4">
                    <h4>Spieler die du eingeladen hast:</h4>
                    {rowsGamesInviter}
                </div>
            </div>
        </div>;
    }

    getGamesArea() {
        if (this.state.games === null || this.state.games === undefined || this.state.games.length === 0) {
            return "";
        }
        let cols = [];
        for (const game of this.state.games) {
            let gegenerName = this.props.userId === game.userId1 ? game.userId2Name : game.userId1Name;

            cols.push(<div className="col" key={"key_mygame_" + game.id} style={{textAlign: "left", marginLeft: 20}}>
                <div style={{marginBottom: 5}}>
                    <NavLink
                        to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + game.gameId + "&playerid=" + this.props.userId}
                        style={{textDecoration: "none"}}>
                        <img src="/images/dart_select.png" alt="Spiel starten"
                             style={{cursor: "pointer", width: 30, marginRight: 5}}/>
                    </NavLink>
                    {gegenerName}
                    <Button icon="pi pi-trash"
                            onClick={() => this.setState({showGameDeleteDialog: true, selectedDeleteGameId: game.id})}
                            tooltip="Löschen" style={{marginLeft: 5}}/>
                </div>
            </div>)
        }

        return <div style={{marginTop: 20, padding: 20}}>
            <div className="grid">
                <div className="col" style={{textAlign: "left"}}>Deine aktuell anstehende Spiele, bitte gehe umgehend
                    in dein aktuelles Spiel:
                </div>
            </div>
            {cols.length === 0 && <div style={{textAlign: "left"}}>Aktuell sind keine Spiele für dich vorhanden</div>}
            {cols.length !== 0 && <div className="grid" style={{marginTop: 10}}>{cols}</div>}
        </div>
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ConfirmDialog visible={this.state.showGameDeleteDialog}
                           callBackOnNein={() => this.setState({showGameDeleteDialog: false})}
                           callBackOnJa={() => this.gameLoeschen()}
                           header="Spiel wirklich löschen"
                           textOben="Sobald du das Spiel löscht, wird dieses komplett gelöscht und ist nicht mehr aufrufbar!!! Dies sollte nur bei technischen Problemen oder ähnlichem gemacht werden."
                           textUnten="Möchtest du das Spiel wirklich löschen?"/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Gaming-Area
                </div>
            </div>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            {this.isAkutellerUserOnline() && <div style={{
                textAlign: "left",
                fontSize: 20,
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                color: "green"
            }}>Du wirst anderen als Online-User und damit spielbereit angezeigt!</div>}
            <div style={{
                textAlign: "left",
                fontSize: 16,
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                color: "black"
            }}>Bitte pflege unter 'Deine Modus' deine aktuellen Spielmodus-Angebote!!!</div>
            {this.getGamesArea()}
            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border: "none"}}>
                <TabPanel header="Gaming">
                    {this.getGamingArea()}
                </TabPanel>
                <TabPanel header="Deine Modus">
                    <GamingAreaModusArea userId={this.props.userId} showError={(message) => this.showError(message)}/>
                </TabPanel>
                <TabPanel header="Zuschauen">
                    {this.getZuschauenArea()}
                </TabPanel>
                <TabPanel header="Chat">
                    {this.getChat()}
                </TabPanel>
            </TabView>
            {this.state.playSound && <SoundComponent loop={false}
                                                    file={"dingdong.mp3"}
                                                    onEnded={() => this.setState({playSound: false})}/>
            }
        </div>
    }

    getZuschauenArea() {
        return <LigaZuschauenArea tournamentId={"gamingarea"}/>
    }

    getChat() {
        return <Chat type="gamingarea" loadData={true} id={"gamingarea"} userId={this.props.userId}
                     callBackOnError={(message) => this.showError(message)}/>
    }
}

GamingArea.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default GamingArea
