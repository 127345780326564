import React, {useEffect, useState} from 'react';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {withCookies} from 'react-cookie';
import ScoringShortCricketView from "./cricket/ScoringShortCricketView";

export default withCookies(ScoringShortView);

function ScoringShortView({gameid, playerid, cookies}) {

    let [gameType, setGameType] = useState(undefined);
    let [showChat, setShowChat] = useState(false);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.game + "/gametype/" + gameid,
            json => {
                setGameType(json);
            },
            responseNotOk => {},
            error => {});
    }, []);

    if (gameType === undefined) {
        return <div>Laden...</div>;
    }

    if( gameType === "cricket" ) {
        return <ScoringShortCricketView gameid={gameid} playerid={playerid} showChat={showChat}
                                   changeShowChat={(show) => setShowChat(show)}
                                   cookies={cookies}/>;
    } else {
        return <div>GameType wird nicht unterstützt.</div>
    }
}
