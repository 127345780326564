import {connect} from 'react-redux'
import Settings from "../../../components/views/settings/Settings";

const mapStateToProps = state => ({
    id: state.user.id,
    userRoles: state.user.userRoles
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)