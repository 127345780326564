import {connect} from 'react-redux'
import Start from "../../../components/views/start/Start";
import {logout} from "../../../actions/user";

const mapStateToProps = (state, ownProps) => ({
    isLoggedIn: state.user.isLoggedIn,
    userId: state.user.id,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Start)