import React from "react";
import DHSelectionComponent from "./DHSeclectionCompontent";

const LEGS_ZUR_AUSWAHL = [
    {name: 'Auswahl löschen', code: undefined},
    {name: '1', code: 1},
    {name: '2', code: 2},
    {name: '3', code: 3},
    {name: '4', code: 4},
    {name: '5', code: 5},
    {name: '6', code: 6},
    {name: '7', code: 7},
    {name: '8', code: 8},
    {name: '9', code: 9},
    {name: '10', code: 10},
    {name: '11', code: 11},
    {name: '12', code: 12},
    {name: '13', code: 13},
    {name: '14', code: 14},
    {name: '15', code: 15},
    {name: '16', code: 16},
    {name: '17', code: 17},
    {name: '18', code: 18},
    {name: '19', code: 19},
    {name: '20', code: 20},
    {name: '21', code: 21},
    {name: '22', code: 22},
    {name: '23', code: 23},
    {name: '24', code: 24},
    {name: '25', code: 25},
    {name: '26', code: 26},
    {name: '27', code: 27},
    {name: '28', code: 28},
    {name: '29', code: 29},
    {name: '30', code: 30},
];

export function GameFirstToLegsComponent({firstToLegs, onChange}) {
    return <DHSelectionComponent value={firstToLegs} onChange={onChange} values={LEGS_ZUR_AUSWAHL} title="first to x legs"/>
}

export function GameBestOfLegsComponent({bestOfLegs, onChange}) {
    return <DHSelectionComponent value={bestOfLegs} onChange={onChange} values={LEGS_ZUR_AUSWAHL} title="best of x legs"/>
}