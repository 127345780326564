import React from 'react'

export default function PublicTournamentRegistrationOptionVorOrt() {
    return <div style={{marginBottom:20}}>
        <h3>Gehe zum Turnier und registriere dich direkt bei der Turnierleitung... viel Spaß!!! :-)</h3>
        <div style={{marginBottom:5}}>Sofern du einen Zugang zu Darthelfer hast, nehme deine <span style={{fontWeight:"bold"}}>SpielerID</span> mit, diese findest du in deinem Profil.</div>
        <div style={{marginBottom:5}}>Du hast schon mal an einem Turnier teilgenommen, in deiner Bestätigungsmail findest du deine <span style={{fontWeight:"bold"}}>SpielerID</span>.</div>
        <div>Beides führt dazu, dass deine Anmeldung schneller geht und deine Spielerdaten miteinander verknüpft sind. <span style={{fontWeight:"bold"}}>(!!!WICHTIG!!! für Ranglisten)</span></div>
    </div>
}
