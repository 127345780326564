import {connect} from 'react-redux'
import RanglisteEditView from "../../../components/views/liga/RanglisteEditView";

const mapStateToProps = (state, ownProps) => ({
    userRoles: state.user.userRoles,
    userId: state.user.id,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RanglisteEditView)