import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {ShortStatistikArea} from "./ligaComponents";
import {Gesamtstatistik} from "./../statistics/StatisticGameComponents";
import {Card} from "primereact/card";
import PropTypes from "prop-types";

class DoppelStatisticArea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {gesamtStatistik: {}, statistikLetzte30Tage: {}};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.statistics + "/doppelstatistik?doppelpartnerid=" + this.props.doppelpartnerid,
            json => {
                this.setState({...json});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#ff99c2"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Statistik
                </div>
            </div>
            <div className="grid" style={{textAlign: "left", padding: 20, margin: 0}}>
                <div className="col" style={{marginRight: 10}}>
                    <Card>
                        <ShortStatistikArea statistik={this.state.gesamtStatistik} label="Gesamt"/>
                        <span style={{marginLeft: 20, cursor: "pointer"}} onClick={() => this.setState({
                            selectedStatistik: this.state.gesamtStatistik,
                            selectedLabel: "Gesamtstatistik"
                        })}>
                                Details anzeigen....</span>
                    </Card>
                </div>
                <div className="col" style={{marginRight: 10}}>
                    <Card>
                        <ShortStatistikArea statistik={this.state.heute} label="Heute"/>
                        <span style={{marginLeft: 20, cursor: "pointer"}} onClick={() => this.setState({
                            selectedStatistik: this.state.heute,
                            selectedLabel: "Statistik heute"
                        })}>
                        Details anzeigen....</span>
                    </Card>
                </div>
                <div className="col" style={{marginRight: 10}}>
                    <Card>
                        <ShortStatistikArea statistik={this.state.dreissigTage} label="Letzte 30 Tage"/>
                        <span style={{marginLeft: 20, cursor: "pointer"}} onClick={() => this.setState({
                            selectedStatistik: this.state.dreissigTage,
                            selectedLabel: "Statistik letzte 30 Tage"
                        })}>
                        Details anzeigen....</span>
                    </Card>
                </div>
                <div className="col" style={{marginRight: 10}}>
                    <Card>
                        <ShortStatistikArea statistik={this.state.diesesJahr} label="Dieses Jahr"/>
                        <span style={{marginLeft: 20, cursor: "pointer"}} onClick={() => this.setState({
                            selectedStatistik: this.state.diesesJahr,
                            selectedLabel: "Statistik dieses Jahr"
                        })}>
                        Details anzeigen....</span>
                    </Card>
                </div>
                <div className="col" style={{marginRight: 10}}>
                    <Card>
                        <ShortStatistikArea statistik={this.state.letztesJahr} label="Letztes Jahr"/>
                        <span style={{marginLeft: 20, cursor: "pointer"}} onClick={() => this.setState({
                            selectedStatistik: this.state.letztesJahr,
                            selectedLabel: "Statistik letztes Jahr"
                        })}>
                        Details anzeigen....</span>
                    </Card>
                </div>
            </div>
            {this.state.selectedStatistik !== null && this.state.selectedStatistik !== undefined &&
            <div style={{textAlign: "left", padding: 20, margin: 0}}>
                <Gesamtstatistik data={this.state.selectedStatistik} ueberschrift={this.state.selectedLabel}/>
            </div>
            }

        </div>
    }

    y
}

DoppelStatisticArea.propTypes = {
    doppelpartnerid: PropTypes.string.isRequired,
};

export default DoppelStatisticArea