import React from 'react'
import {
    Tabelle171er,
    Tabelle180er,
    TabelleBestDarts,
    TabelleHighfinishes,
    TabelleHighscores, TabelleLowDarts, TabelleWorstDarts
} from "../../liga/ligaComponents";


export default function PublicTournamentHighlightsTab({tournament}) {

    const getSpielerName = (id) => {
        if (id === undefined || tournament === null || tournament === undefined) {
            return "";
        }

        if( tournament.turnierGruppe !== null && tournament.turnierGruppe !== undefined) {
            for (let i = 0; i < tournament.turnierGruppe.tournamentPlayerEntities.length; i++) {
                let spieler = tournament.turnierGruppe.tournamentPlayerEntities[i];
                if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                    if (spieler.name.length > 25) {
                        return spieler.name.substring(0, 25) + "...";
                    }
                    return spieler.name;
                }
            }
        }
        if( tournament.turnierKO !== null && tournament.turnierKO !== undefined) {
            for (let i = 0; i < tournament.turnierKO.tournamentPlayerEntities.length; i++) {
                let spieler = tournament.turnierKO.tournamentPlayerEntities[i];
                if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                    if (spieler.name.length > 25) {
                        return spieler.name.substring(0, 25) + "...";
                    }
                    return spieler.name;
                }
            }
        }
        if( tournament.turnierMain !== null && tournament.turnierMain !== undefined) {
            for (let i = 0; i < tournament.turnierMain.tournamentPlayerEntities.length; i++) {
                let spieler = tournament.turnierMain.tournamentPlayerEntities[i];
                if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                    if (spieler.name.length > 25) {
                        return spieler.name.substring(0, 25) + "...";
                    }
                    return spieler.name;
                }
            }
        }

        return "Freilos";
    }

    return <div className="grid" style={{backgroundColor: "#022836", minHeight: "100vh", height: "100%", padding:0, margin:0}}>
        <Tabelle180er color="white" rangliste180er={tournament.ranglisteWrapper.rangliste180er} getSpielerName={getSpielerName}/>
        <Tabelle171er color="white" rangliste171er={tournament.ranglisteWrapper.rangliste171er} getSpielerName={getSpielerName}/>
        <TabelleHighfinishes color="white" ranglisteHighfinishes={tournament.ranglisteWrapper.ranglisteHighfinishes} getSpielerName={getSpielerName}/>
        <TabelleHighscores color="white" ranglisteHighscores={tournament.ranglisteWrapper.ranglisteHighscores} getSpielerName={getSpielerName}/>
        <TabelleLowDarts color="white" lowDarts={tournament.ranglisteWrapper.lowDarts}  getSpielerName={getSpielerName} />
        <TabelleBestDarts color="white" bestDarts={tournament.ranglisteWrapper.bestDarts}  getSpielerName={getSpielerName} />
        <TabelleWorstDarts color="white" worstDarts={tournament.ranglisteWrapper.worstDarts}  getSpielerName={getSpielerName} />
    </div>
}

