import React, {useEffect, useState} from 'react'
import {PrimeIcons} from "primereact/api";
import {Button} from "primereact/button";
import PublicLigaspielttagMannschaftTab from "./PublicLigaspielttagMannschaftTab";
import PublicLigaspielttagGamesTab from "./PublicLigaspielttagGamesTab";
import {getFromLocalStorageWithDefault} from "../../../../util/localStorageZugriff";
import DHDialog from "../../../general/DHDialog";

export default function PublicLigaspielttagGastmannschaftInput({aktuellerBereich, onRefresh, onClose}) {

    const gastInputLigaspieltag = getFromLocalStorageWithDefault("dh_ligaspieltag_gast_input", undefined)

    const [tabValue, setTabValue] = useState("tournament_games");
    const [refreshCounter, setRefreshCounter] = useState(1);

    useEffect(() => {
        setRefreshCounter(refreshCounter+1);
    }, [aktuellerBereich]);

    const erzeugeTab = (value, key, icon, width) => {
        return <div key={key} style={{color: "white", fontSize: 20, width:width, marginLeft:5, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>

            <i className={icon}
               style={{fontSize: 20, marginLeft: 5, marginRight: 5, color: "white", cursor: "pointer"}}/>
            {value.toUpperCase()}
        </div>
    }

    const getTabs = () => {
        let returnValue = [];
        returnValue.push(erzeugeTab("Games" ,"tournament_games", PrimeIcons.TH_LARGE, 150));
        returnValue.push(erzeugeTab("Mannschaft", "tournament_mannschaft", PrimeIcons.USERS, 180));
        return returnValue;
    }

    const getTabpanel = () => {
        if( tabValue === "tournament_games" ) {
            return <PublicLigaspielttagGamesTab tournamentid={gastInputLigaspieltag.tournamentid} qrcode={gastInputLigaspieltag.qrcode} counter={refreshCounter} />
        } else if( tabValue === "tournament_mannschaft" ) {
            return <PublicLigaspielttagMannschaftTab tournamentid={gastInputLigaspieltag.tournamentid}
                                                     qrcode={gastInputLigaspieltag.qrcode}
            onRefresh={onRefresh}/>
        }
        return "Kein Tab gewählt...";
    }

    const getDataArea = () => {
        return <div style={{padding: 0, backgroundColor: "#022836", height: "100%", color: "white", minWidth:"100%", width: "100%"}}>
            <div style={{marginBottom: 10, padding: 10}}>
                <Button label="Aktualisieren" onClick={() => {
                    setRefreshCounter(refreshCounter + 1);
                    onRefresh();
                }}
                        style={{width: "100%", maxWidth: 350}}/>
            </div>
            <div style={{display: "flex"}}>
                {getTabs()}
            </div>
            <div style={{padding: 10, marginTop: 20}}>
                {getTabpanel()}
            </div>
        </div>

    }

    return <DHDialog onClose={() => onClose()} title={"Eingabe - Gastmannschaft"} show={true} comp={getDataArea()} buttons={[]}/>
}
