import React from 'react'

class InteresseAnDarthelfer extends React.Component {

    render() {
        return <div className="col-12 md:col-12 lg:col-12" style={{padding:10}}>
            <div className="kachelStartseite" style={{height:"100%"}}>
                <h3>Was kostet Darthelfer?</h3>
                <div style={{marginBottom:10}}><span style={{fontWeight:"bold"}}>Veranstalter</span> haben die Möglichkeit kostenlos Ihre Veranstaltungen zu verwalten. Mit der kostenlosen Version ist die Eingabe der Ergebnisse von Hand notwendig. Für den automatischen Turnierverlauf und die Ergebnissübernahme mit dem darthelfer-scorer fällt eine Gebühr an. Registriere dich und schau dir die Preise einfach unter dem Punkt Veranstalter an.</div>
                <div style={{marginBottom:10}}><span style={{fontWeight:"bold"}}>Ligabetreiber:</span> Onlineligen kosten eine geringe Gebühr. Diese Gebühr kannst du deinen Teilnehmern direkt über DH, per PayPal, zahlen lassen oder du erhälst eine Rechnung von uns und musst die Gebühr selbst bezahlen. Dafür erhalten deine Teilnehmer für die Ligadauer einen Pro-Account und können an allen Onlineturnieren teilnehmen.</div>
                <div style={{marginBottom:10}}><span style={{fontWeight:"bold"}}>Gamer/Benutzer:</span> Die Registrierung und das Gaming ist mit DH grundsätzlich kostenlos. Du kannst dich somit registrieren und dir DH in aller Ruhe anschauen. Sofern du an Ligen oder Turnieren teilnehmen möchtest musst du die Ligagebühr bezahlten. Es gibt kostenlose und kostenpflichtige Turnier. Für die Teilnahme an kostenpflichtigen Turnieren musst du einen Pro-Account haben. Für einen Monat Pro-Account zahlst du 4,- Euro, für ein Jahr 29,- Euro.</div>
            </div>
        </div>
    }
}

export default InteresseAnDarthelfer;
