import React, {useEffect, useState} from "react";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

function AdminLigabetreiber() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [betreiber, setBetreiber] = useState([]);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.admin + "/betreiber",
            json => {
                setBetreiber(json)
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }, []);

    const rows = () => {
        let tmpRows = [];
        for (const tmpBetreiber of betreiber) {
            let tournaments = "";
            for (const tournament of tmpBetreiber.leagueOperatorEntity.tournaments) {
                tournaments += tournament + " / ";
            }
            let users = [];
            for (const user of tmpBetreiber.users) {
                users.push(<div key={"key_user_" + user.id}>
                    <br/>
                    {user.username}
                    <br/>
                    {user.vorname} {user.nachname}
                    <br/>
                    {user.strasse} - {user.plz} {user.ort}
                    <br/>
                    Email: {user.email}
                </div>);
            }
            let leagueOperatorEntity = tmpBetreiber.leagueOperatorEntity;
            tmpRows.push(<div key={"id" + leagueOperatorEntity.id}>
                <hr/>
                <div>Aktiv: {leagueOperatorEntity.active ? "JA" : "NEIN"}</div>
                <div>Name: {leagueOperatorEntity.name}</div>
                <div>Kurzname: {leagueOperatorEntity.shortname}</div>
                <div>ID: {leagueOperatorEntity.id}</div>
                <div>Homepage: {leagueOperatorEntity.homepage}</div>
                <div>Socialmedia: {leagueOperatorEntity.socialmedia}</div>
                <div>proDaysForUsersByLeagueStart: {leagueOperatorEntity.proDaysForUsersByLeagueStart}</div>
                <div>paymentTyp: {leagueOperatorEntity.paymentTyp}</div>
                <div>Erlaubte Turniertypen: {tournaments}</div>
                <div style={{marginTop:20, marginBottom:10}}>Admins:</div>
                {users}
            </div>)
        }
        return tmpRows;
    }

    return <div style={{textAlign:"left"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {rows()}
    </div>
}

export default AdminLigabetreiber;
