import React from 'react'
import PropTypes from "prop-types";
import LiveView from "../public/liveview/LiveView";

function LigaZuschauenArea({tournamentId}) {
    return <div>
        <LiveView tournamentid={tournamentId} showEmbedded={true}/>
    </div>
}

LigaZuschauenArea.propTypes = {
    tournamentId: PropTypes.string.isRequired,
};

export default LigaZuschauenArea;
