import {NEW_NOTIFICATIONS_OVER_WEBSOCKET,NEW_ADMIN_NOTIFICATIONS_OVER_WEBSOCKET} from "../actions/notifications";

function notifications(state = {
    notifications: [],
    adminnotifications: [],
}, action) {
    switch (action.type) {
        case NEW_NOTIFICATIONS_OVER_WEBSOCKET:
            return {...state, notifications: action.value};
        case NEW_ADMIN_NOTIFICATIONS_OVER_WEBSOCKET:
            return {...state, adminnotifications: action.value};
        default:
            return state
    }
}

export default notifications;