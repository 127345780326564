import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import TournamentX01Area from "./TournamentX01Area";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHDialog from "../../general/DHDialog";
import {InputNumber} from "primereact/inputnumber";
import {PrimeIcons} from "primereact/api";


export default function ChangeModusGroupDialog({tournamentId, bezeichnung, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [sets, setSets] = useState(undefined);
    let [firstToLegs, setFirstToLegs] = useState(undefined);
    let [bestOfLegs, setBestOfLegs] = useState(undefined);
    let [modusId, setModusId] = useState(undefined);
    let [startpunkte, setStartpunkte] = useState(undefined);
    let [group, setGroup] = useState(1);

    let [modusListe, setModusListe] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    useEffect(() => {
        modusVorbelegen(modusListe);
    }, [group]);

    const ladeDaten = () => {
        setErrorMessage(undefined)
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/loadtournamentgroupmodus/" + tournamentId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setModusListe(json);
                modusVorbelegen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const modusVorbelegen = (tmpModusListe) => {
        console.log(modusListe);
        for (const element of tmpModusListe) {
            console.log(element);
            if( element.tournamentGroup === group) {
                console.log("treffer", element);
                setSets(element.sets);
                setFirstToLegs(element.firttolegs);
                setBestOfLegs(element.bestoflegs);
                setModusId(element.modusId);
                setStartpunkte(element.startpunkte);
                setInfoMessage(undefined);
                return;
            }
        }
        setInfoMessage("Keine Daten zur Vorbelegung vorhanden.");
        setSets(undefined);
        setFirstToLegs(undefined);
        setBestOfLegs(undefined);
        setModusId(undefined);
        setStartpunkte(undefined);
    }

    const save = (close) =>  {
        setErrorMessage(undefined)
        let data = {group: group, sets: sets, firstToLegs: firstToLegs, bestOfLegs: bestOfLegs, modusId: modusId, startpunkte: startpunkte};
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/savetournamentgroupmodus/" + tournamentId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                if( close ) {
                    onClose();
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const enableSaveButton = () => {
        return (group !== undefined && group !== null && group !== "") && sets &&  (firstToLegs || bestOfLegs) &&  modusId  && startpunkte;
    }

    return <DHDialog onClose={onClose} title="Modus verändern" comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: 20}}>Modusänderung für Event '{bezeichnung}'</div>
        <div style={{marginBottom: 20, color: "red"}}>
            ACHTUNG! Sobald ein Spiel aus einer Gruppe bereits am Scorer aufgerufen wurde und du danach den Modus hier änderst,
            musst du im Scorer das Spiel verlassen und das Spiel erneut vom Server holen. Dabei wird der Modus neu geholt und das Spiel im richtigen Modus gespielt.
        </div>
        <div style={{marginBottom: 20, color: "red"}}>
            Sobald du für eine Gruppe versehentlich einen Modus eingestellt und gespeichert hast, kann dieser nicht mehr gelöscht werden. Stelle dann einfach den Standardmodus für diese Gruppe hier ein.
        </div>
        <div style={{marginBottom: 20}}>
            Gruppe: <InputNumber value={group} onValueChange={(e) => setGroup(e.value)} min={0}/>
            <Button icon={PrimeIcons.SEARCH} />
        </div>
        <TournamentX01Area showSets={true} showBestOfLegs={true} sets={sets}
                           showPflichtfelder={true} firstToLegs={firstToLegs}
                           bestOfLegs={bestOfLegs}
                           modusId={modusId} startpunkte={startpunkte}
                           onSetsChanged={(sets) => setSets(sets)}
                           onFirstToLegsChanged={(firstToLegs) => setFirstToLegs(firstToLegs)}
                           onBestOfLegsChanged={(bestOfLegs) => setBestOfLegs(bestOfLegs)}
                           onModusIdChanged={(modusId) => setModusId(modusId)}
                           onStartpunkteChanged={(startpunkte) => setStartpunkte(startpunkte)}/>
        <div style={{marginTop: 20, textAlign: "right"}}>
            <Button label="Speichern und Schließen"
                    disabled={!enableSaveButton()}
                    style={{marginRight: 5}}
                    onClick={() => save(true)}/>
            <Button label="Speichern"
                    disabled={!enableSaveButton()}
                    style={{marginRight: 5}}
                    onClick={() => save(false)}/>
            <Button label="Schliessen"
                    onClick={() => onClose()}/>
        </div>
    </>
    }/>
}
