import * as React from 'react';
import {useState} from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DateUtil from "../../../util/DateUtil";
import StatistikGameDialog from "./tournaments/StatistikGameDialog";

const CELL_PADDING_VALUE = 8;

export default function UserStatistikGamesTable({games}) {

    const [showGameDetailDialog, setShowGameDetailDialog] = useState(false);
    const [selectedGame, setSelectedGame] = useState(undefined);

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        games.forEach(game => {
            let ergebnis =  "";
            if( game.sets > 1) {
                ergebnis = game.playerGewonnenSets + ":" + game.playerVerloreneSets;
            } else {
                ergebnis = game.playerGewonnenLegs + ":" + game.playerVerloreneLegs;
            }
            let ergebnisVorhanden = ergebnis !== "";

            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={game.id}>
                <TableCell style={style}>{DateUtil.localDateTimeToDateTimeString(game.timestamp)}</TableCell>
                <TableCell style={style}>{game.spielerName}</TableCell>
                {ergebnisVorhanden && <TableCell style={{...style, textAlign:"center"}}><span style={{cursor: "pointer"}} onClick={() => {
                    setSelectedGame(game);
                    setShowGameDetailDialog(true);
                }}>{ergebnis}</span></TableCell>}
                <TableCell style={style}>{game.gegnerName}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    if (games.length === 0) {
        return <div style={{color: "white", fontSize: 20, padding: 20}}>Aktuell keine Games vorhanden.</div>
    }
    return <>
        <div style={{marginBottom:10}}>Klick auf das Ergebnis zeigt dir Details an.</div>
        <TableContainer style={{maxHeight: "100%", paddingBottom: 0}}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20, padding:0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width: 100, padding:CELL_PADDING_VALUE}}>Datum</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Spieler 1</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, textAlign:"center", width: 50, padding:CELL_PADDING_VALUE}}>Ergebnis</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Spieler 2</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(games)}
                </TableBody>
            </Table>
        </TableContainer>
        {showGameDetailDialog &&
            <StatistikGameDialog gameId={selectedGame.gameId} onClose={() => {
                setShowGameDetailDialog(false);
                setSelectedGame(undefined);
            }}/>
        }
    </>
}
