import React, {useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import TournamentPlayerEventOperatorSelectDialog from "./TournamentPlayerEventOperatorSelectDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

export default function EventOperatorZusammenlegenSpielerIdDialog({tournamentId, eventOperatorId, callBackOnClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [showSearchSpieler1Dialog, setShowSearchSpieler1Dialog] = useState(false);
    let [showSearchSpieler2Dialog, setShowSearchSpieler2Dialog] = useState(false);
    let [spieler1Names, setSpieler1Names] = useState([]);
    let [spieler2Names, setSpieler2Names] = useState([]);
    let [spieler1Id, setSpieler1Id] = useState("");
    let [spieler2Id, setSpieler2Id] = useState("");
    let [newSpielerName, setNewSpielerName] = useState("");

    const save = () => {
        let data = {tournamentId: tournamentId, idShortAlt: spieler1Id, idShortNeu: spieler2Id, nameNeu: newSpielerName };
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/player/zusammenlegen/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Zusammenlegung war erfolgreich.");
                setSpieler1Names([]);
                setSpieler2Names([]);
                setSpieler1Id("");
                setSpieler2Id("");
                setNewSpielerName("");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const getNewNamesArea = (names) => {
        let returnArea = [];
        for (const name of names) {
            returnArea.push(<span key={"key_" + name} style={{fontWeight:"bold", cursor:"pointer"}} onClick={() => setNewSpielerName(name)}>{name}, </span>);
        }
        return returnArea;
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" disabled={spieler1Id === "" || spieler2Id === "" || newSpielerName.trim() === ""} onClick={() => save()}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackOnClose()}
                    className="p-button-secondary"/>
        </div>
    );

    return <DHDialog onClose={() => callBackOnClose()} title="SpielerId ändern" show={true} buttons={dialogFooter}
                     comp={<>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <h4>Zusammenlegen von SpielerIds ist eine gefährliche Angelegenheit. Bitte lege SpielerIds nur zusammen, wenn du SICHER BIST, dass es sich um genau eine Person/Doppelpaarung handelt.</h4>
            <h4>Sofern du zwei SpielerIds zusammenlegen möchtest, ist immer die SpielerId welche evtl. ein 'U' beinhaltet als neue SpielerId zu nutzen. Dabei handelt es sich um einen Spieler mit Login und dieser Spieler erhält seine Statistiken in seinem Profil zur Anzeige.</h4>
            <h4>Die SpielerId welche danach übrig bleibt, ist die SpielerId von Spieler 2.</h4>
            <div className="grid" style={{marginTop: 20}}>
                <div className="col">
                    <Button label="Spieler 1 - SpielerId auswählen" icon="pi pi-search-plus"
                            onClick={() => setShowSearchSpieler1Dialog(true)}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">Alte SpielerId: <span style={{fontWeight:"bold"}}>{spieler1Id}</span></div>
            </div>
            <div className="grid" style={{marginTop: 5}}>
                <div className="col">Vorhandene Namen zur alten SpielerId (klick): <span style={{fontWeight:"bold"}}>{getNewNamesArea(spieler1Names)}</span></div>
            </div>
            <div className="grid" style={{marginTop: 20}}>
                <div className="col">
                    <Button label="Spieler 2 - SpielerId auswählen" icon="pi pi-search-plus"
                            onClick={() => setShowSearchSpieler2Dialog(true)}/>
                </div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">Neue SpielerId: <span style={{fontWeight:"bold"}}>{spieler2Id}</span></div>
            </div>
            <div className="grid" style={{marginTop: 5}}>
                <div className="col">Vorhandene Namen zur neuen SpielerId (klick): <span style={{fontWeight:"bold"}}>{getNewNamesArea(spieler2Names)}</span></div>
            </div>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col">Neuer Spielername: <InputText style={{width: 330}} value={newSpielerName}
                                                                   onChange={(e) => setNewSpielerName(e.target.value)}/></div>
            </div>
            {(showSearchSpieler1Dialog || showSearchSpieler2Dialog) && <TournamentPlayerEventOperatorSelectDialog
                eventOperatorId={eventOperatorId}
                callBackOnClose={() => {
                    setShowSearchSpieler1Dialog(false);
                    setShowSearchSpieler2Dialog(false)
                }}
                callBackOnSelect={(spielerId, nameArray) => {
                    let name = "";
                    if( nameArray.length === 1) {
                        name = nameArray[0];
                    } else {
                        name = "";
                    }
                    if( showSearchSpieler1Dialog ) {
                        setSpieler1Id(spielerId);
                        setSpieler1Names(nameArray);
                    } else {
                        setSpieler2Id(spielerId);
                        setSpieler2Names(nameArray);
                    }
                    setShowSearchSpieler1Dialog(false);
                    setShowSearchSpieler2Dialog(false);
                }}/>}
        </>}/>
}
