import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import UserSearch from "../liga/UserSearch";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {InputText} from "primereact/inputtext";
import DHDialog from "../../general/DHDialog";

function TurnierChangeInactivePlayerDialog({tournamentId, isOnline, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [spielerId, setSpielerId] = useState("");
    const [user, setUser] = useState(undefined);
    const [selectedPlayerId, setSelectedPlayerId] = useState(undefined);
    const [players, setPlayers] = useState([]);

    useEffect( () => {
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/players/inactive/" + tournamentId;
        FetchUtil.fetchPost(url,
            {},
            (json) => {
                setPlayers(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Daten: " + error.message);
            })

    } ,[]);

    const save = () => {
        setErrorMessage(undefined);
        let userId = user !== undefined ? user.id : null;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/player/change/" + tournamentId + "/" + selectedPlayerId + "/" + userId + "/" + spielerId;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Es wurde erfolgreich gespeichert.");
                setUser(undefined);
                setSelectedPlayerId(undefined);
                setPlayers([]);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            })
    }

    const getRowsInactivePlayers = () => {
        let rows = [];
        for (const player of players) {
            rows.push(<div key={"player_key_" + player.id} onClick={() => setSelectedPlayerId(player.id)}>{selectedPlayerId ===  player.id && <img src="/images/check.png" style={{width: "30px", marginLeft: "20px"}} alt="ausgewählt"/>}{player.name}</div>)
        }
        return rows;
    }

    const saveDisabled = () => {
        if( isOnline) {
            return selectedPlayerId === undefined || user === undefined;
        } else {
            return selectedPlayerId === undefined || spielerId === "";
        }
    }

    return <DHDialog onClose={() => onClose()} title="Tausche inaktiven Teilnehmer im Turnier" show={true} buttons={[]}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{textAlign:"left"}}>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}} toggleable={true}>
                <div>Über diesen Dialog kannst du einen inaktiven Teilnehmer durch einen neuen Teilnehmer ersetzen.</div>
            </Fieldset>
            {isOnline &&
                <div className="grid">
                    <div className="col-fixed" style={{width: 400}}>
                        Username neuer Teilnehmer:
                    </div>
                    <div className="col-fixed" style={{width: 400}}>
                        <UserSearch callBackError={(message) => {
                            setErrorMessage(message);
                            setUser(undefined);
                        }}
                                    callBackClearMessages={() => setErrorMessage(undefined)}
                                    callBackInfo={(message) => setInfoMessage(message)}
                                    callBackUserFound={(user) => setUser(user)}/>
                    </div>
                </div>
            }
            {!isOnline &&
                <div className="grid">
                    <div className="col-fixed" style={{width: 400}}>
                        SpielerId vom Spieler der eingefügt werden soll (kopiere diese aus der Teilnehmerliste:
                    </div>
                    <div className="col-fixed" style={{width: 400}}>
                        <InputText value={spielerId} style={{width: "100%"}}
                                   placeholder="SpielerId"
                                   onChange={(e) => setSpielerId(e.target.value)}/>
                    </div>
                </div>
            }
            <h3>Liste der inaktiven Teilnehmer im Turnier/ in der Liga:<br/>(bitte gewünschten Spieler auswählen)</h3>
            {players.length === 0 && <div>Keine inaktiven Teilnehmer vorhanden.</div>}
            {getRowsInactivePlayers()}
            <div style={{marginTop:20}}>
                <Button label="Speichern"
                        type="button" onClick={() => save()} style={{marginRight:5}} disabled={saveDisabled()}/>
                <Button label="Schliessen"
                        type="button" onClick={() => onClose()}/>

            </div>
        </div>
    </>}/>
}

TurnierChangeInactivePlayerDialog.propTypes = {
    tournamentId : PropTypes.string.isRequired,
    onClose : PropTypes.func.isRequired,
    onRefresh : PropTypes.func.isRequired,
}

export default TurnierChangeInactivePlayerDialog;
