import React from 'react'
import PropTypes from "prop-types";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";

import Turniergruppe from "./Turniergruppe";
import {ToggleButton} from 'primereact/togglebutton';
import {Button} from 'primereact/button';
import TurnierGruppenphaseAbschliessenDialog from "./TurnierGruppenphaseAbschliessenDialog";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {OverlayPanel} from "primereact/overlaypanel";
import {Checkbox} from "primereact/checkbox";
import TurnierGruppeAddPlayerDialog from "./TurnierGruppeAddPlayerDialog";
import TurnierLuckyLoserDialog from "./TurnierLuckyLoserDialog";
import RanglisteProfileGruppenZuordnungDialog from "../rangliste/RanglisteProfileGruppenZuordnungDialog";
import BestplatzierteManuellDialog from "../BestplatzierteManuellDialog";

class Turniergruppen extends React.Component {

    constructor() {
        super();

        this.state = {
            zeigeTabellen: true,
            minimizedGroups: [],
            showAbschliessenDialog: false,
            showLuckyLoserDialog: false,
            zeigeNurMeineGruppe: false,
            meineGruppe: false,
            blendeGespielteSpieleAus: false,
            showTeilnehmerInGruppeSchiebenDialog: false,
            showRanglisteGruppenZuordnungDialog: false,
            showBestplatzierteDialog: false,
            selectedPlayerId: false,
        };

        this.minimizeGroup = this.minimizeGroup.bind(this);
        this.isGroupMinizmized = this.isGroupMinizmized.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    componentDidMount() {
        if( this.props.turnier.tournamentGroupEntities !== null && this.props.turnier.tournamentGroupEntities !== undefined) {
            let playerId = undefined;
            if( this.props.turnier.doppelturnier) {
                let doppelpartnerId = undefined;
                for (const doppelDto of this.props.turnier.doppelpartnerDtos) {
                    if( doppelDto.userId1 === this.props.userId || doppelDto.userId2 === this.props.userId) {
                        doppelpartnerId = doppelDto.id;
                    }
                }

                if( doppelpartnerId !== undefined) {
                    for (const player of this.props.turnier.tournamentPlayerEntities) {
                        if( player.doppelpartnerId === doppelpartnerId) {
                            playerId = player.id;
                            break;
                        }
                    }
                }
            } else {
                for (const player of this.props.turnier.tournamentPlayerEntities) {
                    if( player.userId === this.props.userId) {
                        playerId = player.id;
                        break;
                    }
                }
            }
            if( playerId !== undefined) {
                for (const groupEntity of this.props.turnier.tournamentGroupEntities) {
                    if( groupEntity.spieler === playerId) {
                        this.setState({meineGruppe: groupEntity.gruppe});
                    }
                }
            }
        }
    }

    minimizeGroup(gruppennummer) {
        let newMinimizedGroups = this.state.minimizedGroups;
        newMinimizedGroups.push(gruppennummer);
        this.setState({minimizedGroups: newMinimizedGroups});
    }

    maximizeGroup(gruppennummer) {
        let newMinimizedGroups = [];
        for (const group of this.state.minimizedGroups) {
            if (group !== gruppennummer) {
                newMinimizedGroups.push(group);
            }
        }
        this.setState({minimizedGroups: newMinimizedGroups});
    }

    isGroupMinizmized(gruppennummer) {
        if( this.state.zeigeNurMeineGruppe && gruppennummer !== this.state.meineGruppe ) {
            return true;
        }

        for (const group of this.state.minimizedGroups) {
            if (group === gruppennummer) {
                return true;
            }
        }
        return false;
    }

    getTabelle(gruppe) {
        if( this.props.gruppenTabellen === null || this.props.gruppenTabellen === undefined ) {
            return undefined;
        }
        for (const gruppenTabelle of this.props.gruppenTabellen) {
            if( gruppenTabelle.gruppe === gruppe) {
                return gruppenTabelle;
            }
        }
        return undefined;
    }

    getGruppen(istGruppenphaseAbgeschlossen) {
        if (this.props.turnier === undefined) {
            return "";
        }

        let gruppen = [];
        for (let i = 0; i < this.props.turnier.anzahlGruppen; i++) {
            let gruppennummer = i + 1;
            if( this.isGroupMinizmized(gruppennummer) ) {
                continue;
            }
            let tabelle = this.getTabelle(gruppennummer);
            gruppen.push(<div style={{width:"100%", maxWidth:400, paddingRight:5}} key={"key_group_" + gruppennummer}><Turniergruppe tournament={this.props.turnier}
                                        gruppenTabelle={tabelle}
                                                             bestplatzierteDtos={this.props.bestplatzierteDtos}
                                        isSetModus={this.props.isSetModus}
                                        userId={this.props.userId}
                                        gruppennummer={gruppennummer}
                                        zeigeTabelle={this.state.zeigeTabellen} minimizeGroup={this.minimizeGroup}
                                        readOnly={!this.props.admin}
                                        showGroup={true}
                                        boardGames={istGruppenphaseAbgeschlossen ? [] : this.props.boardGames}
                                        liveGames={this.props.liveGames}
                                        blendeGespielteSpieleAus={this.state.blendeGespielteSpieleAus}
                                        selectedPlayerId={this.state.selectedPlayerId}
                                        onPlayerSelect={(playerId) => this.setState({selectedPlayerId: playerId})}
                                        callBackRefresh={() => {
                                            this.props.refresh();
                                        }}/>
            </div>);
        }
        return gruppen;
    }

    getMinimierteGruppen() {
        if (this.props.turnier === undefined) {
            return "";
        }

        let gruppen = [];
        for (let i = 0; i < this.props.turnier.anzahlGruppen; i++) {
            let gruppennummer = i + 1;
            if (this.isGroupMinizmized(gruppennummer)) {
                gruppen.push(<Button label={"Gruppe " + gruppennummer} icon="pi pi-external-link" iconPos="right"
                                     style={{marginRight: 5, marginTop:5}} onClick={() => this.maximizeGroup(gruppennummer)}/>);
            }
        }
        if (gruppen.length > 0) {
            gruppen.push(<Button label={"Zeige alle Gruppen"} icon="pi pi-external-link" iconPos="right"
                                 style={{marginRight: 5, marginTop:5}} onClick={() => this.setState({minimizedGroups: []})}/>);
        }
        return gruppen;
    }

    render() {
        if( this.props.turnier === undefined || this.props.turnier === null) {
            return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
                Laden Daten...
            </div>
        }
        let istGruppenphaseAbgeschlossen = this.props.turnier !== undefined && this.props.turnier.folgeTurnierId !== null && this.props.turnier.folgeTurnierId !== undefined;
        let laeuftLuckyLoserTurnier = this.props.turnier !== undefined && this.props.turnier.luckyLoserTurnierId !== null && this.props.turnier.luckyLoserTurnierId !== undefined;
        let isRoundRobin = this.props.turnier.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id;
        let gruppen = this.getGruppen(istGruppenphaseAbgeschlossen);
        let minimierteGruppen = this.getMinimierteGruppen();
        let abschliessenKlickbar = !istGruppenphaseAbgeschlossen;
        let luckyLoserKlickbar = istGruppenphaseAbgeschlossen && !laeuftLuckyLoserTurnier && this.props.turnier.tournamentstatus !== "abgeschlossen";

        return <div style={{textAlign: "center", align: "center", color:"gray"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid" style={{padding:0, margin:0}}>
                <div className="col" style={{textAlign:"left", fontSize:12}}>
                    Tabellensortierung: Punkte - Gewonnne Spiele - Leg.Differenz - Anzahl Legs gewonnen - direkter Spielervergleich
                </div>
            </div>
            {istGruppenphaseAbgeschlossen &&
            <div className="grid"
                 style={{marginTop: 20, padding: 20, marginLeft: 0, marginRight: 0}}>
                    <span style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "green"
                    }}>Die Gruppenphase ist beendet!!!</span>
            </div>
            }
            <div className="grid" style={{padding: 5, paddingTop:10, marginLeft: 0, marginRight: 0}}>
                {this.props.admin && !isRoundRobin &&
                    <Button label="Gruppenphase abschliessen" disabled={!abschliessenKlickbar}
                            onClick={(e) => this.setState({showAbschliessenDialog: true})} style={{marginRight: 5}}/>
                }
                {this.props.admin && !isRoundRobin &&
                    <Button label="Lucky Loser Turnier starten" disabled={!luckyLoserKlickbar}
                            onClick={(e) => this.setState({showLuckyLoserDialog: true})} style={{marginRight: 5}}/>
                }
                {this.props.showChangeView &&
                    <Button icon="pi pi-list"
                            onClick={() => this.props.onChangeView()}
                            style={{marginRight: 5}}/> }
                <ToggleButton onLabel="Zeige Tabellen"
                              offLabel="Tabellen werden nicht angezeigt"
                              style={{width: 200, marginRight: 5}}
                              checked={this.state.zeigeTabellen}
                              onChange={(e) => this.setState({zeigeTabellen: e.value})}/>
                {this.props.userId !== undefined &&
                    <ToggleButton onLabel="Zeige nur meine Gruppe"
                                  offLabel="Zeige alle Gruppen"
                                  style={{width: 300, marginRight: 5}}
                                  checked={this.state.zeigeNurMeineGruppe}
                                  onChange={(e) => this.setState({zeigeNurMeineGruppe: e.value})}/>
                }
                {this.props.admin && this.props.turnier.tournamentstatus === "gestartet" && !istGruppenphaseAbgeschlossen &&
                    <Button label="Teilnehmer in Gruppe schieben"
                            icon="pi pi-user-plus"
                            style={{marginRight: 5}}
                            type="button" onClick={() => this.setState({showTeilnehmerInGruppeSchiebenDialog:true})}/>
                }
                {this.props.admin && this.props.turnier.bestplatzierteNaechsteKommenWeiter &&
                    <Button label="Bestplatzierte verwalten"
                            style={{marginRight: 5}}
                            type="button" onClick={() => this.setState({showBestplatzierteDialog:true})}/>
                }
                {this.props.admin && this.props.turnier.ranglisteId !== null && this.props.turnier.ranglisteId !== undefined && isRoundRobin &&
                    <Button label="Ranglistenprofil pro Gruppe"
                            type="button" onClick={() => this.setState({showRanglisteGruppenZuordnungDialog:true})}/>
                }
                <OverlayPanel ref={(el) => this.filterOP = el} showCloseIcon>
                    <div style={{marginBottom: 5}}>
                        <Checkbox inputId="blendeGespielteSpieleAus" style={{marginRight:10}}
                                  onChange={e => this.setState({blendeGespielteSpieleAus: !this.state.blendeGespielteSpieleAus})}
                                  checked={this.state.blendeGespielteSpieleAus}/>
                        <label htmlFor="blendeGespielteSpieleAus" className="p-checkbox-label">Blende bereits gespielte Spiele aus.</label>
                    </div>
                </OverlayPanel>
                <i className="pi pi-filter" style={{color: "white", width: 40, height: 30, fontSize: 25}}
                   onClick={(e) => this.filterOP.show(e)}/>
            </div>
            {(!this.state.zeigeNurMeineGruppe && minimierteGruppen.length > 0 ) &&
                <div className="grid" style={{padding: 20, marginLeft: 0, marginRight: 0}}>
                    {minimierteGruppen}
                </div>
            }
            <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
                {gruppen}
            </div>
            {this.state.showTeilnehmerInGruppeSchiebenDialog &&
                <TurnierGruppeAddPlayerDialog tournamentId={this.props.turnier.id}
                                              players={this.props.turnier.tournamentPlayerEntities} anzahlGruppen={this.props.turnier.anzahlGruppen}
                                              onRefresh={this.props.refresh} onClose={() => this.setState({showTeilnehmerInGruppeSchiebenDialog: false})}/>
            }
            {this.state.showAbschliessenDialog &&
                <TurnierGruppenphaseAbschliessenDialog
                    tournamentid={this.props.turnier.id}
                    oldTournamentSystem={this.props.turnier.turniersystem}
                    propAnzahlSpielerDieWeiterkommen={this.props.turnier.anzahlSpielerProGruppeFuerKOPhase}
                    gameType={this.props.turnier.gameType}
                    callBackCloseDialog={() => this.setState({showAbschliessenDialog: false})}
                    callBackRefresh={this.props.refresh}/>
            }
            {this.state.showLuckyLoserDialog &&
                <TurnierLuckyLoserDialog
                    tournamentid={this.props.turnier.id}
                    gameType={this.props.turnier.gameType}
                    anzahlSpielerProGruppeFuerKOPhase={this.props.turnier.anzahlSpielerProGruppeFuerKOPhase}
                    callBackCloseDialog={() => this.setState({showLuckyLoserDialog: false})}
                    callBackRefresh={this.props.refresh}/>
            }
            {this.state.showRanglisteGruppenZuordnungDialog &&
                <RanglisteProfileGruppenZuordnungDialog
                    eventOperatorId={this.props.turnier.eventOperatorId}
                    tournamentId={this.props.turnier.id}
                    ranglisteProfileId={this.props.turnier.ranglisteProfileId}
                    anzahlGruppen={this.props.turnier.anzahlGruppen}
                    onError={(message) => this.showError(message)}
                    onClose={() => this.setState({showRanglisteGruppenZuordnungDialog: false})}/> }
            {this.state.showBestplatzierteDialog &&
            <BestplatzierteManuellDialog tournament={this.props.turnier} gruppenTabellen={this.props.gruppenTabellen} bestplatzierteDtos={this.props.bestplatzierteDtos}
                                         disabled={this.props.turnier.folgeTurnierId }
                                         onRefresh={this.props.refresh()}
                                         onClose={() => this.setState({showBestplatzierteDialog: false})} /> }
        </div>
    }
}

Turniergruppen.propTypes = {
    turnier: PropTypes.object.isRequired,
    gruppenTabellen: PropTypes.array.isRequired,
    bestplatzierteDtos: PropTypes.array.isRequired,
    tournamentsystem: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    isSetModus: PropTypes.bool.isRequired,
    showChangeView: PropTypes.bool,
    onChangeView: PropTypes.bool,
    boardGames: PropTypes.array,
    liveGames: PropTypes.array,
};

export default Turniergruppen
