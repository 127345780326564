import React from 'react'
import PropTypes from "prop-types";
import NotificationsDialog from "./NotificationsDialog";

class AktionenMitteilungen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showDialog:false};
    }

    ungeleseneMitteilungenVorhanden() {
        if( this.props.notifications === null || this.props.notifications === undefined) {
            return false;
        }
        for (const notification of this.props.notifications) {
            if( !notification.gelesen ) {
                return true;
            }
        }
        return false;
    }

    render() {
        let benachrichtungenVorhanden = this.ungeleseneMitteilungenVorhanden();

        let component;
        if ( benachrichtungenVorhanden) {
            component = <span style={{whiteSpace: "nowrap", color: "red", fontWeight:"bold"}}> !!! Mitteilungen !!!</span>
        } else {
            component = <span style={{whiteSpace: "nowrap"}}>Mitteilungen</span>
        }

        return <>
            <div style={{textDecoration: "none", color: "black", cursor:"pointer", textAlign:"center"}} onClick={() => this.setState({showDialog:true})}>
                <img alt="" style={{marginRight: 20, marginLeft: 20,width: 30,}} src={benachrichtungenVorhanden ? "/images/notification_color.png" : "/images/notification_black.png"}/>
                <br/>
                {component}
            </div>
            { this.state.showDialog &&
                <NotificationsDialog visible={true}
                                 notifications={this.props.notifications} callBackCloseDialog={() => this.setState({showDialog:false})}/>
            }
        </>
    }
}

AktionenMitteilungen.propTypes = {
    userId: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired,
};

export default AktionenMitteilungen;