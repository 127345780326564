import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHDialog from "../../general/DHDialog";

function StatisticGameCricket({callBackDialogClose, visible, gameId, gameType}) {

    const [game, setGame] = useState(undefined);

    useEffect(() => {
        ladeDaten(gameId);
    }, [gameId, visible]);

    function ladeDaten(gameId) {
        if (gameId === null || gameId === undefined) {
            return;
        }
        if (!visible) {
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.game + "/gameausarchiv/cricket/" + gameId;
        FetchUtil.fetchGet(url,
            json => {
                setGame(json);
            },
            responseNotOk => {
            console.log("Fehler beim Laden der Gamedaten: " + responseNotOk.message);
            },
            error => {
                console.log("Fehler beim Laden der Gamedaten: " + error.message);
            });
    }

    function getAnzahlSets() {
        let legs = game.gameDetailsPlayer1;
        let groesstesSet = 1;
        for (const leg of legs) {
            if (groesstesSet < leg.setNumber) {
                groesstesSet = leg.setNumber;
            }
        }
        return groesstesSet;
    }

    function getAnzahlLegs(setNumber) {
        return game.gameDetailsPlayer1.filter(leg => leg.setNumber === setNumber).length;
    }

    function erzeugeZahlenZumLeg(leg, legInfo) {
        let buttons = [];
        let zahlen = leg.zahlen.split(";");
        for (const zahlTmp of zahlen) {
            let anzahl = legInfo.wuerfe[zahlTmp];
            buttons.push(getZahlBereich(zahlTmp, anzahl));
        }
        return buttons;
    }

    function getZahlBereich(zahl, anzahlPlayer) {
        let url;
        if (anzahlPlayer === undefined || anzahlPlayer === 0) {
            url = "/images/treffer_0.png";
        } else if (anzahlPlayer === 1) {
            url = "/images/treffer_1.png";
        } else if (anzahlPlayer === 2) {
            url = "/images/treffer_2.png";
        } else {
            url = "/images/treffer_3.png";
        }
        return <div
            className="flex align-items-center justify-content-center h-4rem" style={{marginLeft: 5, marginRight: 5}}>
            {zahl} <img src={url} alt="Treffer" style={{margin: 2, height: 70}}/>
        </div>
    }

    if (game === undefined || visible === false) {
        return "";
    }

    const dialogFooter = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => callBackDialogClose()}
                    className="p-button-secondary"/>
        </div>
    );

    let gewinnerVorhanden = game.gewonnenPlayerid !== null && game.gewonnenPlayerid !== undefined;
    let unentschiedenVorhanden = game.unentschieden !== null && game.unentschieden !== undefined;
    if (!gewinnerVorhanden && !unentschiedenVorhanden) {
        return <DHDialog onClose={() => callBackDialogClose()} title="Spielstatistik" show={visible} buttons={dialogFooter}
                         comp={<>
            Dieses Spiel wurde nicht über darthelfer.de gespielt oder ist noch nicht archiviert, aus diesem Grund liegt
            keine Statistik vor.
        </>}/>
    }

    let player1Name = game.player1Name;
    let player2Name = game.player2Name;
    let legs = [];

    let isSetModus = getAnzahlSets() > 1;

    for (let setNumber = 1; setNumber <= getAnzahlSets(); setNumber++) {
        for (let leg = 1; leg <= getAnzahlLegs(setNumber); leg++) {
            let legSetBez = "";
            if (isSetModus) {
                legSetBez = "Set " + setNumber + " Leg Nummer " + leg;
            } else {
                legSetBez = "Leg Nummer" + leg;
            }
            let gameDetailsPlayer1 = game.gameDetailsPlayer1.find(detail => detail.setNumber === setNumber && detail.leg === leg);
            let gameDetailsPlayer2 = game.gameDetailsPlayer2.find(detail => detail.setNumber === setNumber && detail.leg === leg);
            let legInfoPlayer1 = game.legInfos.find(info => info.gamedetailid === gameDetailsPlayer1.id);
            let legInfoPlayer2 = game.legInfos.find(info => info.gamedetailid === gameDetailsPlayer2.id);
            legs.push(<div key={"ueberschrift_set_" + setNumber + "_leg" + leg}
                           style={{marginTop: 20, marginBottom: 10, fontSize: 20, fontWeight: "bold"}}>
                {legSetBez} <span
                style={{fontSize: 14}}>(oben {player1Name}, unten {player2Name})</span>
            </div>);
            legs.push(
                <div className="grid" style={{fontSize: 20, marginTop:20}}>
                    <div className="col-fixed" style={{width:200, fontSize:20}}>Punkte: {game.cutthroad ? legInfoPlayer1.punkte : legInfoPlayer2.punkte}</div>
                    {erzeugeZahlenZumLeg(gameDetailsPlayer1, legInfoPlayer1)}
                </div>);
            legs.push(
                <div className="grid" style={{fontSize: 20, marginTop:20}}>
                    <div className="col-fixed" style={{width:200}}>Punkte: {game.cutthroad ? legInfoPlayer2.punkte : legInfoPlayer1.punkte}</div>
                    {erzeugeZahlenZumLeg(gameDetailsPlayer2, legInfoPlayer2)}
                </div>);
        }
    }

    let ergebnis = "";
    if (isSetModus) {
        // Set Modus
        ergebnis = game.statistikPlayer1.gewonneneSets + ":" + game.statistikPlayer2.gewonneneSets;
    } else {
        ergebnis = game.statistikPlayer1.gewonneneLegs + ":" + game.statistikPlayer2.gewonneneLegs;
    }
    return <DHDialog onClose={() => callBackDialogClose()} title="Spielstatistik" show={visible} buttons={dialogFooter}
                     comp={<>
        <h1>{player1Name} vs. {player2Name} {ergebnis}</h1>
        <h3>Cutthroat: {game.cutthroat ? "ja" : "nein"}</h3>
        {legs}
        <div style={{fontSize: 10, marginTop: 20}}>GameId: {game.gameid}</div>
    </>}/>
}

StatisticGameCricket.propTypes = {
    callBackDialogClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    gameId: PropTypes.string.isRequired,
};

export default StatisticGameCricket
