import InfoDialog from "./InfoDialog";
import React from "react";

export default function InfoDialogEventNichtVeroeffentlichen({onClose}) {
    return <InfoDialog title="Event nicht veröffentlichen..."
                textArray={[
                    "Sobald du das Event nicht veröffentlichst, wird dieses in der öffentlichen Turnier-/Ligaübersicht nicht angzeigt.",
                    "Damit kannst du das Event auch nicht direkt im öffentlichen Bereich aufrufen und niemand kann das Event beobachten.",
                    "Weiter wird der Live-View zum Beobachten der Spiele nicht aktiv sein.",
                    "Sofern du eines dieser Features nutzen möchtest, musst du das Event veröffentlichen!",
                    "Tipp: du kannst das Event veröffentlichen und nur die öffentliche Anmeldung deaktivieren.",
                ]}
                visible={true} onClose={() => onClose()}/>
}
