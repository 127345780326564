import {connect} from 'react-redux'
import PlayerOverview from "../../../components/views/player/PlayerOverview";

const mapStateToProps = (state, ownProps) => ({
    mandantType: state.user.mandantType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerOverview)