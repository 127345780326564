import React, {useState} from 'react'
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import KODKOX01VerwaltenArea from "./verwalten/KODKOX01VerwaltenArea";
import KODKOCricketVerwaltenArea from "./verwalten/KODKOCricketVerwaltenArea";
import {ToggleButton} from "primereact/togglebutton";
import {RadioButton} from "primereact/radiobutton";
import {Dropdown} from "primereact/dropdown";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import ProgressButton from "../../general/ProgressButton";

function TurnierLuckyLoserDialog({gameType, tournamentid, anzahlSpielerProGruppeFuerKOPhase, callBackRefresh, callBackCloseDialog}) {

    const [auslosungLaeuft, setAuslosungLaeuft] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [finaleVerliererseiteMussZweiSpieleGewinnen, setFinaleVerliererseiteMussZweiSpieleGewinnen] = useState(false);
    let [spielUmPlatz3, setSpielUmPlatz3] = useState(false);

    let [gewinnerseiteSets, setGewinnerseiteSets] = useState(undefined);
    let [gewinnerseiteFirstToLegs, setGewinnerseiteFirstToLegs] = useState(undefined);
    let [gewinnerseiteModusId, setGewinnerseiteModusId] = useState(undefined);
    let [gewinnerseiteStartpunkte, setGewinnerseiteStartpunkte] = useState(undefined);
    let [gewinnerseiteZufallszahlen, setGewinnerseiteZufallszahlen] = useState(undefined);
    let [gewinnerseiteZufallszahlenJedesLeg, setGewinnerseiteZufallszahlenJedesLeg] = useState(undefined);
    let [gewinnerseiteCutthroat, setGewinnerseiteCutthroat] = useState(undefined);

    let [verliererseiteSets, setVerliererseiteSets] = useState(undefined);
    let [verliererseiteFirstToLegs, setVerliererseiteFirstToLegs] = useState(undefined);
    let [verliererseiteModusId, setVerliererseiteModusId] = useState(undefined);
    let [verliererseiteStartpunkte, setVerliererseiteStartpunkte] = useState(undefined);
    let [verliererseiteZufallszahlen, setVerliererseiteZufallszahlen] = useState(undefined);
    let [verliererseiteZufallszahlenJedesLeg, setVerliererseiteZufallszahlenJedesLeg] = useState(undefined);
    let [verliererseiteCutthroat, setVerliererseiteCutthroat] = useState(undefined);

    let [achtelfinaleSets, setAchtelfinaleSets] = useState(undefined);
    let [achtelfinaleFirstToLegs, setAchtelfinaleFirstToLegs] = useState(undefined);
    let [achtelfinaleModusId, setAchtelfinaleModusId] = useState(undefined);
    let [achtelfinaleStartpunkte, setAchtelfinaleStartpunkte] = useState(undefined);
    let [achtelfinaleZufallszahlen, setAchtelfinaleZufallszahlen] = useState(undefined);
    let [achtelfinaleZufallszahlenJedesLeg, setAchtelfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [achtelfinaleCutthroat, setAchtelfinaleCutthroat] = useState(undefined);

    let [viertelfinaleSets, setViertelfinaleSets] = useState(undefined);
    let [viertelfinaleFirstToLegs, setViertelfinaleFirstToLegs] = useState(undefined);
    let [viertelfinaleModusId, setViertelfinaleModusId] = useState(undefined);
    let [viertelfinaleStartpunkte, setViertelfinaleStartpunkte] = useState(undefined);
    let [viertelfinaleZufallszahlen, setViertelfinaleZufallszahlen] = useState(undefined);
    let [viertelfinaleZufallszahlenJedesLeg, setViertelfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [viertelfinaleCutthroat, setViertelfinaleCutthroat] = useState(undefined);

    let [halbfinaleSets, setHalbfinaleSets] = useState(undefined);
    let [halbfinaleFirstToLegs, setHalbfinaleFirstToLegs] = useState(undefined);
    let [halbfinaleModusId, setHalbfinaleModusId] = useState(undefined);
    let [halbfinaleStartpunkte, setHalbfinaleStartpunkte] = useState(undefined);
    let [halbfinaleZufallszahlen, setHalbfinaleZufallszahlen] = useState(undefined);
    let [halbfinaleZufallszahlenJedesLeg, setHalbfinaleZufallszahlenJedesLeg] = useState(undefined);
    let [halbfinaleCutthroat, setHalbfinaleCutthroat] = useState(undefined);

    let [spielUmPlatz3Sets, setSpielUmPlatz3Sets] = useState(undefined);
    let [spielUmPlatz3FirstToLegs, setSpielUmPlatz3FirstToLegs] = useState(undefined);
    let [spielUmPlatz3ModusId, setSpielUmPlatz3ModusId] = useState(undefined);
    let [spielUmPlatz3Startpunkte, setSpielUmPlatz3Startpunkte] = useState(undefined);
    let [spielUmPlatz3Zufallszahlen, setSpielUmPlatz3Zufallszahlen] = useState(undefined);
    let [spielUmPlatz3ZufallszahlenJedesLeg, setSpielUmPlatz3ZufallszahlenJedesLeg] = useState(undefined);
    let [spielUmPlatz3Cutthroat, setSpielUmPlatz3Cutthroat] = useState(undefined);

    let [finaleSets, setFinaleSets] = useState(undefined);
    let [finaleFirstToLegs, setFinaleFirstToLegs] = useState(undefined);
    let [finaleModusId, setFinaleModusId] = useState(undefined);
    let [finaleStartpunkte, setFinaleStartpunkte] = useState(undefined);
    let [finaleZufallszahlen, setFinaleZufallszahlen] = useState(undefined);
    let [finaleZufallszahlenJedesLeg, setFinaleZufallszahlenJedesLeg] = useState(undefined);
    let [finaleCutthroat, setFinaleCutthroat] = useState(undefined);

    let [showAchtelfinale, setShowAchtelfinale] = useState(false);
    let [showViertelfinale, setShowViertelfinale] = useState(false);
    let [showHalbfinale, setShowHalbfinale] = useState(false);
    let [showSpielUmPlatz3, setShowSpielUmPlatz3] = useState(false);
    let [showFinale, setShowFinale] = useState(false);

    let [tournamentSystem, setTournamentSystem] = useState(undefined);

    let [werKommtWeiter, setWerKommtWeiter] = useState(undefined);
    let [selectedPlatzierung, setSelectedPlatzierung] = useState(undefined);
    let [platzierungen, setPlatzierungen] = useState([]);

    function turnierAnlegen() {
        setErrorMessage(undefined);
        setAuslosungLaeuft(true);
        let isDKOModus = tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id;
        let modusArray = [];
        modusArray.push({
            modusTyp: TOURNAMENTMODUS.GEWINNERSEITE.id,
            gameType: gameType,
            modusid: gewinnerseiteModusId,
            sets: gewinnerseiteSets,
            firstToLegs: gewinnerseiteFirstToLegs,
            bestOfLegs: undefined,
            startpunkte: gewinnerseiteStartpunkte,
            zufallszahlen: gewinnerseiteZufallszahlen,
            zufallszahlenjedesleg: gewinnerseiteZufallszahlenJedesLeg,
            cutthroat: gewinnerseiteCutthroat
        });
        if (isDKOModus && (verliererseiteModusId !== undefined || verliererseiteSets !== undefined || verliererseiteFirstToLegs !== undefined || verliererseiteStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.VERLIERERSEITE.id,
                gameType: gameType,
                modusid: verliererseiteModusId,
                sets: verliererseiteSets,
                firstToLegs: verliererseiteFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: verliererseiteStartpunkte,
                zufallszahlen: verliererseiteZufallszahlen,
                zufallszahlenjedesleg: verliererseiteZufallszahlenJedesLeg,
                cutthroat: verliererseiteCutthroat
            });
        }
        if (showAchtelfinale && (achtelfinaleModusId !== undefined || achtelfinaleSets !== undefined || achtelfinaleFirstToLegs !== undefined || achtelfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.ACHTELFINALE.id,
                gameType: gameType,
                modusid: achtelfinaleModusId,
                sets: achtelfinaleSets,
                firstToLegs: achtelfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: achtelfinaleStartpunkte,
                zufallszahlen: achtelfinaleZufallszahlen,
                zufallszahlenjedesleg: achtelfinaleZufallszahlenJedesLeg,
                cutthroat: achtelfinaleCutthroat
            });
        }
        if (showViertelfinale && (viertelfinaleModusId !== undefined || viertelfinaleSets !== undefined || viertelfinaleFirstToLegs !== undefined || viertelfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.VIERTELFINALE.id,
                gameType: gameType,
                modusid: viertelfinaleModusId,
                sets: viertelfinaleSets,
                firstToLegs: viertelfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: viertelfinaleStartpunkte,
                zufallszahlen: viertelfinaleZufallszahlen,
                zufallszahlenjedesleg: viertelfinaleZufallszahlenJedesLeg,
                cutthroat: viertelfinaleCutthroat
            });
        }
        if (showHalbfinale && (halbfinaleModusId !== undefined || halbfinaleSets !== undefined || halbfinaleFirstToLegs !== undefined || halbfinaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.HALBFINALE.id,
                gameType: gameType,
                modusid: halbfinaleModusId,
                sets: halbfinaleSets,
                firstToLegs: halbfinaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: halbfinaleStartpunkte,
                zufallszahlen: halbfinaleZufallszahlen,
                zufallszahlenjedesleg: halbfinaleZufallszahlenJedesLeg,
                cutthroat: halbfinaleCutthroat
            });
        }
        if (showSpielUmPlatz3 && (spielUmPlatz3ModusId !== undefined || spielUmPlatz3Sets !== undefined || spielUmPlatz3FirstToLegs !== undefined || spielUmPlatz3Startpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.SPIELUMPLATZDREI.id,
                gameType: gameType,
                modusid: spielUmPlatz3ModusId,
                sets: spielUmPlatz3Sets,
                firstToLegs: spielUmPlatz3FirstToLegs,
                bestOfLegs: undefined,
                startpunkte: spielUmPlatz3Startpunkte,
                zufallszahlen: spielUmPlatz3Zufallszahlen,
                zufallszahlenjedesleg: spielUmPlatz3ZufallszahlenJedesLeg,
                cutthroat: spielUmPlatz3Cutthroat
            });
        }
        if (showFinale && (finaleModusId !== undefined || finaleSets !== undefined || finaleFirstToLegs !== undefined || finaleStartpunkte)) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.FINALE.id,
                gameType: gameType,
                modusid: finaleModusId,
                sets: finaleSets,
                firstToLegs: finaleFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: finaleStartpunkte,
                zufallszahlen: finaleZufallszahlen,
                zufallszahlenjedesleg: finaleZufallszahlenJedesLeg,
                cutthroat: finaleCutthroat
            });
        }

        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/createLuckyLoser";
        let data = {
            turnierid: tournamentid,
            tournamentModusEntities: modusArray,
            finaleVerliererseiteMussZweiSpieleGewinnen: finaleVerliererseiteMussZweiSpieleGewinnen,
            spielUmPlatz3: spielUmPlatz3,
            tournamentSystem: tournamentSystem,
            werKommtWeiter: werKommtWeiter,
            platzierungen: platzierungen,
        };
        FetchUtil.fetchPost(url,
            data,
            json => {
                setInfoMessage("Lucky Loser Turnier wurde erfolgreich angelegt.");
                callBackRefresh();
                callBackCloseDialog();
                setAuslosungLaeuft(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setAuslosungLaeuft(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setAuslosungLaeuft(false);
            });
    }

    function showSaveButton() {
        if( werKommtWeiter === undefined) {
            return false;
        }
        if( werKommtWeiter === "platzierung" && platzierungen.length === 0) {
            return false;
        }
        if (gameType === "x01") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteModusId && verliererseiteStartpunkte;
                return gewinnerFelder && verliererFelder;
            } else {
                return false;
            }
        } else if (gameType === "cricket") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteZufallszahlen !== undefined && verliererseiteCutthroat !== undefined;
                return gewinnerFelder && verliererFelder;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function getX01Area() {
        return <KODKOX01VerwaltenArea
            tournamentSystem={tournamentSystem}
            showAchtelfinale={showAchtelfinale} showViertelfinale={showViertelfinale} showHalbfinale={showHalbfinale} showSpielUmPlatz3={showSpielUmPlatz3} showFinale={showFinale}
            achtelfinaleModusId={achtelfinaleModusId} halbfinaleStartpunkte={halbfinaleStartpunkte}
            halbfinaleFirstToLegs={halbfinaleFirstToLegs} finaleStartpunkte={finaleStartpunkte} verliererseiteModusId={verliererseiteModusId}
            finaleFirstToLegs={finaleFirstToLegs} gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs} achtelfinaleSets={achtelfinaleSets}
            gewinnerseiteStartpunkte={gewinnerseiteStartpunkte} achtelfinaleFirstToLegs={achtelfinaleFirstToLegs} gewinnerseiteModusId={gewinnerseiteModusId}
            achtelfinaleStartpunkte={achtelfinaleStartpunkte} verliererseiteSets={verliererseiteSets} halbfinaleModusId={halbfinaleModusId}
            halbfinaleSets={halbfinaleSets} verliererseiteFirstToLegs={verliererseiteFirstToLegs} verliererseiteStartpunkte={verliererseiteStartpunkte}
            spielUmPlatz3Sets={spielUmPlatz3Sets} spielUmPlatz3FirstToLegs={spielUmPlatz3FirstToLegs} spielUmPlatz3ModusId={spielUmPlatz3ModusId} spielUmPlatz3Startpunkte={spielUmPlatz3Startpunkte}
            viertelfinaleSets={viertelfinaleSets} viertelfinaleFirstToLegs={viertelfinaleFirstToLegs} gewinnerseiteSets={gewinnerseiteSets}
            finaleModusId={finaleModusId} finaleSets={finaleSets} viertelfinaleModusId={viertelfinaleModusId}
            viertelfinaleStartpunkte={viertelfinaleStartpunkte}
            setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteModusId={setGewinnerseiteModusId} setGewinnerseiteStartpunkte={setGewinnerseiteStartpunkte}
            setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteModusId={setVerliererseiteModusId} setVerliererseiteStartpunkte={setVerliererseiteStartpunkte}
            setAchtelfinaleSets={setAchtelfinaleSets} setAchtelfinaleFirstToLegs={setAchtelfinaleFirstToLegs} setAchtelfinaleModusId={setAchtelfinaleModusId} setAchtelfinaleStartpunkte={setAchtelfinaleStartpunkte}
            setViertelfinaleSets={setViertelfinaleSets} setViertelfinaleFirstToLegs={setViertelfinaleFirstToLegs} setViertelfinaleModusId={setViertelfinaleModusId} setViertelfinaleStartpunkte={setViertelfinaleStartpunkte}
            setHalbfinaleSets={setHalbfinaleSets} setHalbfinaleFirstToLegs={setHalbfinaleFirstToLegs} setHalbfinaleModusId={setHalbfinaleModusId} setHalbfinaleStartpunkte={setHalbfinaleStartpunkte}
            setSpielUmPlatz3Sets={setSpielUmPlatz3Sets} setSpielUmPlatz3FirstToLegs={setSpielUmPlatz3FirstToLegs} setSpielUmPlatz3ModusId={setSpielUmPlatz3ModusId} setSpielUmPlatz3Startpunkte={setSpielUmPlatz3Startpunkte}
            setFinaleSets={setFinaleSets} setFinaleFirstToLegs={setFinaleFirstToLegs} setFinaleModusId={setFinaleModusId} setFinaleStartpunkte={setFinaleStartpunkte}
            setShowAchtelfinale={setShowAchtelfinale} setShowViertelfinale={setShowViertelfinale} setShowHalbfinale={setShowHalbfinale} setShowSpielUmPlatz3={setShowSpielUmPlatz3} setShowFinale={setShowFinale}
        />
    }

    function getCricketArea() {
        return <KODKOCricketVerwaltenArea
            tournamentSystem={tournamentSystem}
            showAchtelfinale={showAchtelfinale} showViertelfinale={showViertelfinale} showHalbfinale={showHalbfinale} showFinale={showFinale}
            gewinnerseiteSets={gewinnerseiteSets} gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs} gewinnerseiteZufallszahlen={gewinnerseiteZufallszahlen} gewinnerseiteZufallszahlenJedesLeg={gewinnerseiteZufallszahlenJedesLeg} gewinnerseiteCutthroat={gewinnerseiteCutthroat}
            verliererseiteSets={verliererseiteSets} verliererseiteFirstToLegs={verliererseiteFirstToLegs} verliererseiteZufallszahlen={verliererseiteZufallszahlen} verliererseiteZufallszahlenJedesLeg={verliererseiteZufallszahlenJedesLeg} verliererseiteCutthroat={verliererseiteCutthroat}
            achtelfinaleSets={achtelfinaleSets} achtelfinaleFirstToLegs={achtelfinaleFirstToLegs} achtelfinaleZufallszahlen={achtelfinaleZufallszahlen} achtelfinaleZufallszahlenJedesLeg={achtelfinaleZufallszahlenJedesLeg} achtelfinaleCutthroat={achtelfinaleCutthroat}
            viertelfinaleSets={viertelfinaleSets} viertelfinaleFirstToLegs={viertelfinaleFirstToLegs} viertelfinaleZufallszahlen={viertelfinaleZufallszahlen} viertelfinaleZufallszahlenJedesLeg={viertelfinaleZufallszahlenJedesLeg} viertelfinaleCutthroat={viertelfinaleCutthroat}
            halbfinaleSets={halbfinaleSets} halbfinaleFirstToLegs={halbfinaleFirstToLegs} halbfinaleZufallszahlen={halbfinaleZufallszahlen} halbfinaleZufallszahlenJedesLeg={halbfinaleZufallszahlenJedesLeg} halbfinaleCutthroat={halbfinaleCutthroat}
            finaleSets={finaleSets} finaleFirstToLegs={finaleFirstToLegs} finaleZufallszahlen={finaleZufallszahlen} finaleZufallszahlenJedesLeg={finaleZufallszahlenJedesLeg} finaleCutthroat={finaleCutthroat}
            setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteZufallszahlen={setGewinnerseiteZufallszahlen} setGewinnerseiteZufallszahlenJedesLeg={setGewinnerseiteZufallszahlenJedesLeg} setGewinnerseiteCutthroat={setGewinnerseiteCutthroat}
            setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteZufallszahlen={setVerliererseiteZufallszahlen} setVerliererseiteZufallszahlenJedesLeg={setVerliererseiteZufallszahlenJedesLeg} setVerliererseiteCutthroat={setVerliererseiteCutthroat}
            setAchtelfinaleSets={setAchtelfinaleSets} setAchtelfinaleFirstToLegs={setAchtelfinaleFirstToLegs} setAchtelfinaleZufallszahlen={setAchtelfinaleZufallszahlen} setAchtelfinaleZufallszahlenJedesLeg={setAchtelfinaleZufallszahlenJedesLeg} setAchtelfinaleCutthroat={setAchtelfinaleCutthroat}
            setViertelfinaleSets={setViertelfinaleSets} setViertelfinaleFirstToLegs={setViertelfinaleFirstToLegs} setViertelfinaleZufallszahlen={setViertelfinaleZufallszahlen} setViertelfinaleZufallszahlenJedesLeg={setViertelfinaleZufallszahlenJedesLeg} setViertelfinaleCutthroat={setViertelfinaleCutthroat}
            setHalbfinaleSets={setHalbfinaleSets} setHalbfinaleFirstToLegs={setHalbfinaleFirstToLegs} setHalbfinaleZufallszahlen={setHalbfinaleZufallszahlen} setHalbfinaleZufallszahlenJedesLeg={setHalbfinaleZufallszahlenJedesLeg} setHalbfinaleCutthroat={setHalbfinaleCutthroat}
            setFinaleSets={setFinaleSets} setFinaleFirstToLegs={setFinaleFirstToLegs} setFinaleZufallszahlen={setFinaleZufallszahlen} setFinaleZufallszahlenJedesLeg={setFinaleZufallszahlenJedesLeg} setFinaleCutthroat={setFinaleCutthroat}
            setShowAchtelfinale={setShowAchtelfinale} setShowViertelfinale={setShowViertelfinale} setShowHalbfinale={setShowHalbfinale} setShowFinale={setShowFinale}
        />
    }

    const dialogFooter = (
        <div>
            <ProgressButton text="Lucky Loser Turnier anlegen" icon="pi pi-save" disabled={auslosungLaeuft || !showSaveButton()} working={auslosungLaeuft} onClick={() => turnierAnlegen()}/>
            <Button label="Schliessen" icon="pi pi-times" style={{marginLeft:5}} onClick={() => callBackCloseDialog()}
                    className="p-button-secondary"/>
        </div>
    );

    const addPlatzeriung = () => {
        let tmpPlatzierungen = [...platzierungen];
        tmpPlatzierungen.push(selectedPlatzierung);
        setPlatzierungen(tmpPlatzierungen);
        setSelectedPlatzierung(undefined);
    }

    const getPlatzierungDropdown = () => {
        let values = [];
        // Gruppen können nur 20 Teilnehmer habe, zur Sicherheit 30
        for( let i = 1; i <= 30; i++) {
            if( i <= anzahlSpielerProGruppeFuerKOPhase ) {
                continue;
            }
            if( platzierungen.includes(i)) {
                continue;
            }
            values.push({value:i});
        }
        return <div key={"key_dropdown_platzierung"} style={{marginLeft:10}}>
            <Dropdown value={selectedPlatzierung} options={values} optionLabel="value"
                      onChange={(e) => {
                          setSelectedPlatzierung(e.value);
                      }} emptyMessage={"Auswahl"}
                      placeholder="Eingabe..." style={{width:120}}/>
        </div>
    }

    const getPlatzungAnzeige = () => {
        let tmpPlatzierungen = platzierungen.sort(function(a, b) {
            return a - b;
        });
        let value = "";
        for (const platzierungenElement of tmpPlatzierungen) {
            value += platzierungenElement + "; ";
        }
        return value;
    }

    return <DHDialog onClose={callBackCloseDialog} title="Lucky Loser Turnier anlegen" buttons={dialogFooter} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding: 20}}>
            <div style={{marginBottom: 20, fontWeight: "bold"}}>
                Hier kannst du ein Lucky Loser Turnier festlegen.<br/>
                Nach dem Starten können Modus usw. für das Lucky Loser Turnier nicht mehr verändert werden!!!
            </div>
            <div style={{marginBottom: 30}}>
                <div className="grid" style={{textAlign: "left"}}>
                    Auswahl Lucky Loser Turniermodus:
                </div>
                <div className="grid" style={{textAlign: "left", marginTop: 5}}>
                    <div className="col">
                        <RadioButton inputId="rbtournamentsystem1" name="rbtournamentsystem" value={TOURNAMENTSYSTEMS.EINFACHERKO.id} onChange={(e) => setTournamentSystem(e.value)}
                                     checked={tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id}/>
                        <label htmlFor="rbtournamentsystem1" className="p-checkbox-label" style={{marginLeft: 10}}>Einfaches-KO</label>
                    </div>
                </div>
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="col">
                        <RadioButton inputId="rbtournamentsystem2" name="rbtournamentsystem" value={TOURNAMENTSYSTEMS.DOPPELTERKO.id} onChange={(e) => setTournamentSystem(e.value)}
                                     checked={tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id}/>
                        <label htmlFor="rbtournamentsystem2" className="p-checkbox-label" style={{marginLeft: 10}}>Doppel-KO</label>
                    </div>
                </div>
            </div>
            {tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id &&
                <div className="grid" style={{marginBottom: 20}}>
                    <div className="col-fixed" style={{width: 210}}>Spiel um Platz 3:
                    </div>
                    <div className="col">
                        <ToggleButton
                            onLabel="Ja" offLabel="Nein"
                            checked={spielUmPlatz3}
                            onChange={(e) => setSpielUmPlatz3(!spielUmPlatz3)}/>
                    </div>
                </div>
            }
            {tournamentSystem !== undefined && tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id &&
                <div className="grid">
                    <div className="col-fixed" style={{width: 200}}>Final Satzausgleich:
                    </div>
                    <div className="col">
                        <ToggleButton
                            onLabel="Ja" offLabel="Nein"
                            checked={finaleVerliererseiteMussZweiSpieleGewinnen}
                            onChange={(e) => setFinaleVerliererseiteMussZweiSpieleGewinnen(!finaleVerliererseiteMussZweiSpieleGewinnen)}/>
                    </div>
                </div>
            }
            <div style={{marginBottom: 30}}>
                <div className="grid" style={{textAlign: "left"}}>
                    Wer soll ins Lucky Loser Turnier?
                </div>
                <div className="grid" style={{textAlign: "left", marginTop: 5}}>
                    <div className="col">
                        <RadioButton inputId="rbtwerkommtweiter1" name="rbtwerkommtweiter" value="alle" onChange={(e) => setWerKommtWeiter(e.value)} checked={werKommtWeiter === "alle"}/>
                        <label htmlFor="rbtwerkommtweiter1" className="p-checkbox-label" style={{marginLeft: 10}}>Alle die nicht weitergekommen sind</label>
                    </div>
                </div>
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="col">
                        <RadioButton inputId="rbtwerkommtweiter2" name="rbtwerkommtweiter" value="platzierung" onChange={(e) => setWerKommtWeiter(e.value)} checked={werKommtWeiter === "platzierung"}/>
                        <label htmlFor="rbtwerkommtweiter2" className="p-checkbox-label" style={{marginLeft: 10}}>Platzierungen auswählen</label>
                    </div>
                </div>
                {werKommtWeiter === "platzierung" && <>
                    <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center", marginTop: 20}}>
                        {getPlatzierungDropdown()}
                        <Button icon="pi pi-plus-circle" style={{height: 30, width: 30}} disabled={selectedPlatzierung === undefined} onClick={() => addPlatzeriung()}/>
                    </div>
                    <div style={{fontSize: 16, marginTop: 5}}>
                        {getPlatzungAnzeige()}
                    </div>
                </>
                }
            </div>

            {tournamentSystem !== undefined &&
                <div style={{marginBottom: 20}}>
                    Daten für KO bzw./oder DKO Phase festlegen:
                </div>
            }
            {tournamentSystem !== undefined && gameType === "x01" && getX01Area()}
            {tournamentSystem !== undefined && gameType === "cricket" && getCricketArea()}
        </div>
    </>}/>
}

TurnierLuckyLoserDialog.propTypes = {
    tournamentid: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    anzahlSpielerProGruppeFuerKOPhase: PropTypes.number.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
};

export default TurnierLuckyLoserDialog
