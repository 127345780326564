import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {Checkbox} from "primereact/checkbox";

function RanglisteMarkerComponent({eventOperatorId, selectedMarkers, onValueChange, onError}) {

    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker";
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/eventoperator/" + eventOperatorId;
        }
        FetchUtil.fetchGet(url,
            json => {
                let newMarkers = [];
                for (const jsonElement of json) {
                    newMarkers.push(jsonElement.marker);
                }
                setMarkers(newMarkers);
            },
            responseNotOk => {
                if( onError !== null && onError !== undefined) {
                    onError("Fehler: " + responseNotOk.message)
                }
            },
            error => {
                if( onError !== null && onError !== undefined) {
                    onError("Fehler: " + error.message)
                }
            }
        );
    }, []);


    const rows = () => {
        let rows = [];
        for (const marker of markers) {
            rows.push(<div className="grid" key={"key_marker_" + marker}>
                <div className="col">
                    <Checkbox inputId={"inputIdMarker" + marker} value={marker} onChange={() => {
                        if( selectedMarkers.includes(marker) ) {
                            let newSelectedMarkers = [];
                            for (const tmp of selectedMarkers) {
                                if( tmp !== marker) {
                                    newSelectedMarkers.push(tmp);
                                }
                            }
                            onValueChange(newSelectedMarkers);
                        } else {
                            let newSelectedMarkers = [];
                            for (const tmp of selectedMarkers) {
                                newSelectedMarkers.push(tmp);
                            }
                            newSelectedMarkers.push(marker);
                            onValueChange(newSelectedMarkers);
                        }
                    }} checked={selectedMarkers.includes(marker)}></Checkbox>
                    <label htmlFor={"inputIdMarkerTest" + marker} className="p-checkbox-label" style={{marginLeft:10}}>{marker}</label>
                </div>
            </div>)
        }
        return rows;
    }

    return <div>
        {rows()}
    </div>
}

RanglisteMarkerComponent.propTypes = {
    onValueChange: PropTypes.func.isRequired,
    selectedMarkers: PropTypes.array.isRequired,
};

export default RanglisteMarkerComponent;
