import * as React from 'react';
import {useEffect, useState} from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

export default function LiveViewTable({games, playerIdsChecked, onDetail}) {

    const CELL_PADDING_VALUE = 8;

    let [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth}
    );
    let [showAuswahl, setShowAuswahl] = useState(false);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if( games === null || games === undefined || games.length === 0 ) {
        return "";
    }

    const isBigView = () => {
        return dimensions.width > 800;
    }

    const schreiberVorhanden = () => {
        for (const game of games) {
            if( game.schreiberId !== undefined && game.schreiberId !== null ) {
                return true;
            }
        }
        return false;
    }

    const isEdart = (game) => {
        return game.dartType !== null && game.dartType !== undefined && game.dartType === "EDART";
    }

    const getTableRows = (games) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let counter = 1;
        games.forEach(game => {
            let isSetModus = game.sets > 1;
            let gewinnerSpielerId = "";
            let spieler1MehrSetsOderLegs = game.sets === 1 ? game.spieler1Legs > game.spieler2Legs : game.spieler1Sets > game.spieler2Sets
            let spieler2MehrSetsOderLegs = game.sets === 1 ? game.spieler2Legs > game.spieler1Legs : game.spieler2Sets > game.spieler1Sets
            let startssoon = game.startssoon !== undefined && game.startssoon;
            let inGame = game.inGame !== undefined && game.inGame;
            let abgeschlossen = game.abgeschlossen;
            if( abgeschlossen && isSetModus) {
                gewinnerSpielerId = game.spieler1Sets > game.spieler2Sets ? game.game.spieler1Id : game.spieler2Id;
            } else if( abgeschlossen) {
                gewinnerSpielerId = game.spieler1Legs > game.spieler2Legs ? game.spieler1Id : game.spieler2Id;
            }
            let checkedPlayer1 = playerIdsChecked.includes(game.spieler1Id);
            let checkedPlayer2 = playerIdsChecked.includes(game.spieler2Id);
            let ergebnis = isSetModus ? game.spieler1Sets + ":" + game.spieler2Sets :  game.spieler1Legs + ":" + game.spieler2Legs;
            let spieler1ToGo = isEdart(game) ? "-" : game.spieler1Togo;
            let spieler2ToGo = isEdart(game) ? "-" : game.spieler2Togo;
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow onClick={() => onDetail(game.boardId)}>
                {isBigView() &&
                    <TableCell style={{...style, textAlign:"center"}}>{game.boardName}</TableCell>
                }
                {isBigView() && schreiberVorhanden() &&
                    <TableCell style={{...style, textAlign:"left"}}>{game.schreiberName !== null && game.schreiberName !== undefined && game.schreiberName !== "" ? game.schreiberName : "-" }</TableCell>
                }
                <TableCell style={{...style, textAlign:"right", fontWeight: game.spieler1Id === gewinnerSpielerId ? "bold" : "unset", color: game.spieler1Id === gewinnerSpielerId ? "green" : ""}}>
                    {checkedPlayer1 && <span style={{color: "green", fontWeight: "bold", marginRight:10}}>Check</span>}
                    <img src={!abgeschlossen && game.spieler1Id === game.spielerIdActive ? "/images/pfeil_rechts_rot_transparent.png"  : "/images/transparent.png"} style={{width:14, height:20, marginRight:10}}/>
                    {game.spieler1Name}
                </TableCell>
                {!abgeschlossen && isBigView() &&
                    <TableCell style={{...style, textAlign:"center"}}>{spieler1ToGo}</TableCell>
                }
                {abgeschlossen && isBigView() &&
                    <TableCell style={{...style, textAlign:"center", color:"green"}}>{spieler1MehrSetsOderLegs ? "Sieger" : "-"}</TableCell>
                }
                {startssoon &&
                    <TableCell style={{...style, textAlign:"center", color:"green"}}>Startet</TableCell>
                }
                {inGame &&
                    <TableCell style={{...style, textAlign:"center", color:"green"}}>im Spiel</TableCell>
                }
                {!startssoon && !inGame &&
                    <TableCell style={{...style, textAlign:"center"}}>
                        <img src={!abgeschlossen && game.spieler1Id === game.spielerIdLegAnwurf ? "/images/punkt_rot.png"  : "/images/transparent.png"} style={{width:10, height:10, marginRight:5}}/>
                        {ergebnis}
                        <img src={!abgeschlossen && game.spieler2Id === game.spielerIdLegAnwurf ? "/images/punkt_rot.png" : "/images/transparent.png"} style={{width:10, height:10, marginLeft:5}}/>
                    </TableCell>
                }
                {!abgeschlossen && isBigView() &&
                    <TableCell style={{...style, textAlign:"center"}}>{spieler2ToGo}</TableCell>
                }
                {abgeschlossen && isBigView() &&
                    <TableCell style={{...style, textAlign:"center", color:"green"}}>{spieler2MehrSetsOderLegs ? "Sieger" : "-"}</TableCell>
                }
                <TableCell style={{...style, textAlign:"left", fontWeight: game.spieler2Id === gewinnerSpielerId ? "bold" : "unset", color: game.spieler2Id === gewinnerSpielerId ? "green" : ""}}>
                    {game.spieler2Name}
                    <img src={!abgeschlossen && game.spieler2Id === game.spielerIdActive ? "/images/pfeil_links_rot_transparent.png" : "/images/transparent.png"} style={{width:14, height:20, marginLeft:10}}/>
                    {checkedPlayer2 && <span style={{color: "green", fontWeight: "bold", marginLeft:10}}>Check</span>}
                </TableCell>
            </TableRow>)
            {!abgeschlossen && !isBigView() && !isEdart(game) &&
            returnRows.push(<TableRow key={game.id}>
                <TableCell colSpan={isBigView() ? 6 : 4}
                           style={{...style, textAlign: "center"}}>
                    {spieler1ToGo} - {spieler2ToGo}
                </TableCell>
            </TableRow>)
            }
            {!isBigView() &&
            returnRows.push(<TableRow key={game.id}>
                <TableCell colSpan={isBigView() ? 6 : 4}
                           style={{...style, textAlign: "center"}}>
                    {game.boardName}
                    {game.schreiberName !== null && game.schreiberName !== undefined && game.schreiberName !== "" ? <><br/>Schreiber: {game.schreiberName}</> : "" }
                    {abgeschlossen && <><br/><div style={{color:"green"}}>Sieger steht fest</div></>}
                </TableCell>
            </TableRow>)
            }
            background = !background;
            counter++;
        });
        return returnRows;
    }

    const getMobilePlayerArea = (game, spielerName, toGo, spielerId, sets, legs, hatMehrSetsOderLegs) => {
        let abgeschlossen = game.abgeschlossen;
        let startssoon = game.startssoon !== undefined && game.startssoon;
        let inGame = game.inGame !== undefined && game.inGame;
        let isSetModus = game.sets > 1;

        return <div className="grid" style={{padding:0, margin:0}}>
            <div className="col" style={{padding:0, margin:0}}>
                {spielerName}
                {!abgeschlossen && <img src={ spielerId === game.spielerIdLegAnwurf ? "/images/punkt_rot.png"  : "/images/transparent.png"} style={{width:10, height:10, marginLeft:5}}/>}
                {!abgeschlossen && <img src={spielerId === game.spielerIdActive ? "/images/pfeil_links_rot_transparent.png"  : "/images/transparent.png"} style={{width:14, height:20, marginLeft:10}}/>}
                {abgeschlossen && hatMehrSetsOderLegs &&
                    <span style={{marginLeft:5, color:"green"}}>Sieger</span>
                }
            </div>
            {startssoon && <>
                <div className="col-fixed" style={{width:100, padding:0, margin:0, textAlign:"center", color:"green"}}>
                    Startet
                </div>
            </>}
            {inGame && <>
                <div className="col-fixed" style={{width:100, padding:0, margin:0, textAlign:"center", color:"green"}}>
                    im Spiel
                </div>
            </>}
            {!startssoon && !inGame && <>
                <div className="col-fixed" style={{width:60, padding:0, margin:0, textAlign:"center"}}>
                    {!abgeschlossen && !isEdart(game) ? toGo : "-"}
                </div>
                <div className="col-fixed" style={{width:40, padding:0, margin:0, textAlign:"center"}}>
                    {isSetModus ? sets : legs}
                </div>
            </>
            }
        </div>
    }

    const getMobileRows = () => {
        let rows = [];
        rows.push(<div style={{borderBottom:"1px solid grey", textAlign:"left", fontSize:"2.0vw", padding:5, backgroundColor:"#ac1c27", color:"white"}}>
            <div className="grid" style={{padding:0, margin:0}}>
                <div className="col" style={{padding:0, margin:0}}>
                    Spielername
                </div>
                <div className="col-fixed" style={{width:60, padding:0, margin:0, textAlign:"center"}}>
                    ToGo
                </div>
                <div className="col-fixed" style={{width:40, padding:0, margin:0, textAlign:"center"}}>
                    Erg.
                </div>
            </div>
        </div>)
        let gerade = true;
        for (const game of games) {
            let style = gerade ? {color:"black", backgroundColor:"white"} : {color:"black", backgroundColor:"rgb(234,241,245)"};
            rows.push(<div style={{borderBottom:"1px solid grey", textAlign:"left", fontSize:"4.0vw", padding:5, ...style}}>
                {getMobilePlayerArea(game, game.spieler1Name, game.spieler1Togo, game.spieler1Id, game.spieler1Sets, game.spieler1Legs, game.sets === 1 ? game.spieler1Legs > game.spieler2Legs : game.spieler1Sets > game.spieler2Sets)}
                {getMobilePlayerArea(game, game.spieler2Name, game.spieler2Togo, game.spieler2Id, game.spieler2Sets, game.spieler2Legs, game.sets === 1 ? game.spieler2Legs > game.spieler1Legs : game.spieler2Sets > game.spieler1Sets)}
            </div>)
            gerade = !gerade;
        }

        return rows;
    }

    if( dimensions.width < 700) {
        return <div style={{padding:10}}>
            {getMobileRows()}
        </div>
    }

    return (
        <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}} aria-label="caption table">
            <TableHead>
                <TableRow>
                    {isBigView() &&
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"center", width:120, padding:CELL_PADDING_VALUE}}>Board</TableCell>
                    }
                    {isBigView() && schreiberVorhanden() &&
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"left", width:200, padding:CELL_PADDING_VALUE}}>Schreiber</TableCell>
                    }
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"right", padding:CELL_PADDING_VALUE}}>Spieler 1</TableCell>
                    {isBigView() &&
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"center", width:90, padding:CELL_PADDING_VALUE}}>ToGo</TableCell>
                    }
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, width:80, textAlign:"center", padding:CELL_PADDING_VALUE}}>Ergebnis</TableCell>
                    {isBigView() &&
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"center", width:90, padding:CELL_PADDING_VALUE}}>ToGo</TableCell>
                    }
                    <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, textAlign:"left", padding:CELL_PADDING_VALUE}}>Spieler 2</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {getTableRows(games)}
            </TableBody>
        </Table>
    );
}
