import React from 'react'
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {GameBestOfLegsComponent, GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {GameSetsComponent} from "../../general/GameSetsComponent";
import {SelectButton} from "primereact/selectbutton";

function TournamentCricketArea({
                                   showSets,
                                   showBestOfLegs,
                                   showPflichtfelder,
                                   sets,
                                   firstToLegs,
                                   bestOfLegs,
                                   zufallszahlen,
                                   zufallszahlenjedesleg,
                                   cutthroat,
                                   onSetsChanged,
                                   onFirstToLegsChanged,
                                   onBestOfLegsChanged,
                                   onZufallszahlenChanged,
                                   onZufallszahlenjedeslegChanged,
                                   onCutthroatChanged
                               }) {

    const cricketzufallszahlen = [
        {name: 'Ja', value: true},
        {name: 'Nein', value: false},
    ];
    const cricketcutthroat = [
        {name: 'Ja', value: true},
        {name: 'Nein', value: false},
    ];


    return <>
        {showSets &&
            <div className="grid">
                <div className="col-fixed" style={{width: "120px"}}>first to sets: {showPflichtfelder && "*"}</div>
                <div className="col-fixed" style={{width: "250px"}}>
                    <GameSetsComponent sets={sets}
                                       onChange={(sets) => onSetsChanged(sets)}/>
                </div>
            </div>
        }
        <div className="grid">
            <div className="col-fixed" style={{width: "120px"}}>first to
                legs: {bestOfLegs === undefined && showPflichtfelder && "*"}</div>
            <div className="col-fixed" style={{width: "250px"}}>
                <GameFirstToLegsComponent firstToLegs={firstToLegs}
                                          onChange={(firstToLegs) => {
                                              onFirstToLegsChanged(firstToLegs)
                                              {firstToLegs !== undefined && onBestOfLegsChanged !== undefined && onBestOfLegsChanged(undefined)};
                                          }}/>
            </div>
        </div>
        {showBestOfLegs &&
            <div className="grid">
                <div className="col-fixed" style={{width: "120px"}}>best of
                    legs: {firstToLegs === undefined && showPflichtfelder && "*"}</div>
                <div className="col-fixed" style={{width: "250px"}}>
                    <GameBestOfLegsComponent bestOfLegs={bestOfLegs}
                                             onChange={(bestOfLegs) => {
                                                 onBestOfLegsChanged(bestOfLegs);
                                                 {bestOfLegs !== undefined && onFirstToLegsChanged(undefined)};
                                             }}/>
                </div>
            </div>
        }
        <div className="grid" style={{textAlign: "left"}}>
            <div className="col">
                Zufallszahlen: {showPflichtfelder && "*"}
            </div>
        </div>
        <div className="grid" style={{textAlign: "left", marginBottom: 10}}>
            <div className="col">
                <SelectButton value={zufallszahlen}
                              optionLabel="name"
                              options={cricketzufallszahlen}
                              onChange={(e) => onZufallszahlenChanged(e.value)}></SelectButton>
            </div>
        </div>
        {zufallszahlen && <>
            <div className="grid" style={{textAlign: "left"}}>
                <div className="col">
                    jedes Leg andere Zufallszahlen?
                </div>
            </div>
            <div className="grid" style={{textAlign: "left", marginBottom: 10}}>
                <div className="col">
                    <SelectButton value={zufallszahlenjedesleg}
                                  optionLabel="name"
                                  options={cricketzufallszahlen}
                                  onChange={(e) => onZufallszahlenjedeslegChanged(e.value)}></SelectButton>
                </div>
            </div>
        </>
        }
        <div className="grid" style={{textAlign: "left"}}>
            <div className="col">
                Cutthroat: {showPflichtfelder && "*"}
            </div>
        </div>
        <div className="grid" style={{textAlign: "left"}}>
            <div className="col">
                <SelectButton value={cutthroat}
                              optionLabel="name"
                              options={cricketcutthroat}
                              onChange={(e) => onCutthroatChanged(e.value)}></SelectButton>
            </div>
        </div>
    </>
}

TournamentCricketArea.propTypes = {
    sets: PropTypes.string,
    firstToLegs: PropTypes.string.isRequired,
    bestOfLegs: PropTypes.string,
    zufallszahlen: PropTypes.bool.isRequired,
    zufallszahlenjedesleg: PropTypes.bool.isRequired,
    cutthroat: PropTypes.bool.isRequired,
    onSetsChanged: PropTypes.func,
    onFirstToLegsChanged: PropTypes.func.isRequired,
    onBestOfLegsChanged: PropTypes.func,
    onZufallszahlenChanged: PropTypes.func.isRequired,
    onZufallszahlenjedeslegChanged: PropTypes.func.isRequired,
    onCutthroatChanged: PropTypes.func.isRequired,
    showSets: PropTypes.bool.isRequired,
    showBestOfLegs: PropTypes.bool.isRequired,
    showPflichtfelder: PropTypes.bool.isRequired,
};

export default TournamentCricketArea
