import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import Turniergruppen from "../../../components/views/tournament/Turniergruppen";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const tournamentId = urlParams.get('id');
    return {tournamentid: tournamentId, userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Turniergruppen))