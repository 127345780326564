import {combineReducers} from 'redux'
import user from "./user";
import app from "./app";
import modal from "./modal";
import games from "./games";
import notifications from "./notifications";
import friends from "./friends";
import statistik from "./statistik";
import tournament from "./tournament";
import leagueoperator from "./leagueoperator";
import chat from "./chat";
import eventoperator from "./eventoperator";


export default combineReducers({
    user, app, modal, games, notifications, friends, statistik, tournament, leagueoperator, chat, eventoperator
})
