import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from "@mui/material/TableHead";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import DateUtil from "../../../util/DateUtil";
import NuPlayerMatchComponent from "../../nu/NuPlayerMatchComponent";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 350,
    },
});

function getKratzerPoints(props, id) {
    if( props.tournamentKratzerPoints === null || props.tournamentKratzerPoints === undefined) {
        return "";
    }
    return props.tournamentKratzerPoints.filter(point => point.id === id)[0];
}

function isLigaspieltag(props)  {
    return props.liga.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id;
}

function getName(props, row) {
    if( props.liga.doppelturnier) {
        let dtos = props.liga.doppelpartnerDtos;
        let doppelusernamen = "";
        let average = "";
        for (const dto of dtos) {
            if( dto.id === row.doppelpartnerId ) {
                doppelusernamen = dto.userName1 + " - " + dto.user1Username + " | " + dto.userName2 + " - " + dto.user2Username;
                average += " - (Avg.: " + dto.average + ")";
            }
        }
        let returnName = row.name;
        if( doppelusernamen !== null && doppelusernamen !== undefined && doppelusernamen !== "") {
            returnName = returnName + " ( " + doppelusernamen + " )" + average;
        }
        if( row.idShortSinglePlayer1 !== null || row.idShortSinglePlayer2 !== null ) {
            returnName =  returnName + " ( ";
            if( row.idShortSinglePlayer1 !== null) {
                returnName =  returnName + " SpielerId1: " + row.idShortSinglePlayer1;
            }
            if( row.idShortSinglePlayer2 !== null) {
                returnName =  returnName + " SpielerId2: " + row.idShortSinglePlayer2;
            }
            returnName =  returnName + " ) ";
        }
        let kratzerPoint = getKratzerPoints(props, row.tournamentKratzerPointsId);
        if( kratzerPoint !== null && kratzerPoint !== undefined && kratzerPoint !== "") {
            returnName = returnName + " (" + kratzerPoint.bezeichnung + " - Kratzer: " + kratzerPoint.punkte + ")";
        }
        let wartelistePos = getWartelistePos(props, row);
        if(  wartelistePos !== "") {
            returnName += "(" + wartelistePos + ")";
        }
        return returnName;

    } else {
        let nachname = row.nachname !== null && row.nachname !== undefined ? " , Name: " + row.nachname : "";
        let vorname = row.vorname !== null && row.vorname !== undefined ? " , Vorname: " + row.vorname : "";
        let returnName = row.name + nachname + vorname;
        let kratzerPoint = getKratzerPoints(props, row.tournamentKratzerPointsId);
        if( kratzerPoint !== null && kratzerPoint !== undefined && kratzerPoint !== "") {
            returnName = returnName + " (" + kratzerPoint.bezeichnung + " - Kratzer: " + kratzerPoint.punkte + ")";
        }
        if( isLigaspieltag(props) ) {
            if( row.heimmannschaft ) {
                returnName = returnName + " ( -Heim- )";
            } else {
                returnName = returnName + " ( -Gast- )";
            }
        }
        let wartelistePos = getWartelistePos(props, row);
        if(  wartelistePos !== "") {
            returnName += " (" + wartelistePos + ")";
        }
        return returnName;
    }
}

function getYellowCard(message) {
    if( message !== null && message !== undefined ) {
        return <>
            <br/>
            <img src="/images/card_yellow.png"
                 style={{width: 30}}
                 alt="Gelbe Karte"/>
            <br/><span style={{color:"white"}}>Begründung: {message}</span>
            <hr style={{color:"white"}}/>
        </>
    }
    return "";
}

function getAdminComment(message) {
    if( message !== null && message !== undefined ) {
        return <>
            <br/>
            <span style={{color:"white"}}>{message}</span>
            <hr style={{color:"white"}}/>
        </>
    }
    return "";
}

function getZuordnungen(player) {
    return <>
        {player.idShortDoppel !== null &&  player.idShortDoppel !== undefined && <>
            <br/>Zuordnung Doppel - SpielerId: {player.idShortDoppel}
        </>
        }
        {player.idShortSinglePlayer1 !== null &&  player.idShortSinglePlayer1 !== undefined && <>
            <br/>Zuordnung Einzel - SpielerId: {player.idShortSinglePlayer1}
        </>
        }
        {player.idShortSinglePlayer2 !== null &&  player.idShortSinglePlayer2 !== undefined && <>
            <br/>Zuordnung Einzel - SpielerId: {player.idShortSinglePlayer2}
        </>
        }
    </>
}
function getRedCard(message) {
    if( message !== null && message !== undefined ) {
        return <>
            <br/>
            <img src="/images/card_red.png"
                 style={{width: 30}}
                 alt="Rote Karte"/>
            <br/><span style={{color:"white"}}>Begründung: {message}</span>
            <hr style={{color:"white"}}/>
        </>
    }
    return "";
}

function getWartelistePos(props, player) {
    if( props.liga.playersSortedByTime === null || props.liga.playersSortedByTime === undefined || props.liga.playersSortedByTime.length === 0) {
        return "";
    }
    if( player.warteliste) {
        let counter = 1;
        for (const tmp of props.liga.playersSortedByTime) {
            if( !tmp.warteliste ) {
                continue;
            }
            if( tmp.id === player.id) {
                return "Wartelistepos.: " + counter;
            }
            counter++;
        }
    }
    return "";
}

function getRows(props, showButtons, onShowDetail, onShowExternPersonVerknuepfenDialog) {
    if( props.rows === null || props.rows === undefined) {
        return;
    }
    let tableRows = [];
    let isEventOperator = props.liga.eventOperatorId !== null && props.liga.eventOperatorId !== undefined;
    let isLigaSpieltag = props.liga.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id

    for (let i = 0; i < props.rows.length; i++) {
        let row = props.rows[i];
        let styleRow = i % 2 === 0 ? {backgroundColor: "#666666"} : {backgroundColor: "#404040"};

        let userIdVorhanden = row.userId !== null && row.userId !== undefined;

        let username = "";
        let usernameDarthelfer = "";
        let average = "";
        let average60Tage = "";
        let average90Tage = "";
        if( userIdVorhanden ) {
            username = props.getUsernamesByUserId(row.userId);
            usernameDarthelfer = "";
            average = "";
            if (username !== undefined) {
                usernameDarthelfer = username.username;
                average = username.average ? username.average : 0;
                average60Tage = username.average60Tage ? username.average60Tage : 0;
                average90Tage = username.average90Tage ? username.average90Tage : 0;
            }
        }
        tableRows.push(<TableRow key={"key_liga_teilnehmer_tabellenzeile_" + row.id} style={{...styleRow, color: "white"}}>
            <TableCell style={{color:"white"}}>
                {row.idShort}{props.showExternPersonId && row.externPersonId && <div style={{marginTop:10}}><br/>Extern PersonId: {row.externPersonId}</div>}
            </TableCell>
            {row.inaktiv && <TableCell style={{color:"white"}}>
                <i className="pi pi-ban" style={{fontSize:20, marginRight:25}} /><s>{getName(props, row)}</s>
                {getAdminComment(row.adminComment)}
                {getYellowCard(row.yellowCardMessage)}
                {getRedCard(row.redCardMessage)}
                {getZuordnungen(row)}
            </TableCell>
            }
            {props.liga.doppelturnier && !row.inaktiv && <TableCell style={{color:"white"}}>
                <span style={{color:"white"}}>{getName(props, row)}</span>
                {getAdminComment(row.adminComment)}
                {getYellowCard(row.yellowCardMessage)}
                {getRedCard(row.redCardMessage)}
                {getZuordnungen(row)}
            </TableCell>
            }
            {!props.liga.doppelturnier && !row.inaktiv && userIdVorhanden && <TableCell style={{color:"white"}}>
                <>
                    <NavLink to={NAVIGATION_ITEM.PROFILFRIEND.route + "?profilId=" + row.userId} style={{textDecoration: "none"}}>
                        <span style={{color:"white", cursor:"pointer"}}>{getName(props, row)}</span>
                    </NavLink>
                    {getAdminComment(row.adminComment)}
                    {getYellowCard(row.yellowCardMessage)}
                    {getRedCard(row.redCardMessage)}
                    {getZuordnungen(row)}
                </>
            </TableCell>
            }
            {!props.liga.doppelturnier && !row.inaktiv && !userIdVorhanden && <TableCell style={{color:"white"}}>
                    <span style={{color:"white"}}>{getName(props, row)}</span>
                    {getAdminComment(row.adminComment)}
                    {getYellowCard(row.yellowCardMessage)}
                    {getRedCard(row.redCardMessage)}
                    {getZuordnungen(row)}
            </TableCell>
            }
            {props.showUsernames && <TableCell align="center" style={{color:"white"}}>
                {userIdVorhanden && usernameDarthelfer + " - Avg. Tage 30/60/90 - : " + average + " / " + average60Tage + " / " + average90Tage}
            </TableCell>}
            {showButtons &&
            <TableCell align="center" style={{fontSize: "14px", color:"white"}}>
                <div className="grid">
                    {isEventOperator && !isLigaSpieltag &&
                        <img src={row.anwesend ? "/images/anwesend_color.png" : "/images/anwesend_black.png"}
                             style={{width: 35, height: 35, marginRight: 10, marginTop: 5, cursor:"pointer"}}
                             alt="Anwesend" onClick={() => props.onChangeAnwesend(row.id)}/>
                    }
                    {row.warteliste && <img src="/images/warteliste.png"
                                            style={{width: 40, height: 40, marginRight: 10, marginTop: 5}}
                                            alt="Warteliste"/>
                    }
                    {!row.inaktiv && <img src="/images/delete_color.png"
                                          style={{width: 40, height: 40, marginRight: 10, marginTop: 5, cursor: "pointer"}}
                                          alt="Teilnehmer entfernen"
                                          onClick={() => props.showDeleteConfirmDialog(row.id)}/>
                    }
                    {(props.liga.participantsMailConfirmation !== null && props.liga.participantsMailConfirmation !== undefined && props.liga.participantsMailConfirmation) &&
                        <div style={{width: 50, height: 50, cursor: "pointer"}}>
                            <img src={(row.confirmationExpirationTime === null || row.confirmationExpirationTime === undefined) ? "/images/player_green.png" : "/images/player_border.png"}
                                 style={{width: 30, marginLeft: 0, marginTop: 7}}
                                 alt="Teilnahme per Mail bestätigt"/>
                        </div>
                    }
                    {(!isEventOperator && props.liga.tournamentstatus === "neu" && props.isVerschiebenErlaubt) &&
                        <div style={{width: 50, height: 50, border: "1px solid black", cursor: "pointer"}}
                             onClick={() => props.showChangeLigaDialog(row.id, row.name)}>
                            <img src="/images/change.png"
                                 style={{width: 30, marginLeft: 0, marginTop: 7}}
                                 alt="Liga wechseln"/>
                        </div>
                    }
                    {!isLigaSpieltag &&
                        <div style={{width: 50, height: 50, cursor: "pointer"}}
                             onClick={() => props.bezahltMarkieren(row.id)}>
                            <img src={row.bezahlt ? "/images/euro_green.png" : "/images/euro_black.png"}
                                 style={{width: 30, marginLeft: 0, marginTop: 7}}
                                 alt="Bezahlung"/>
                        </div>
                    }
                    {!isEventOperator &&
                        <div style={{width: 50, height: 50, cursor: "pointer"}}
                             onClick={() => props.verwarnungErteilen(row.id, row.name)}>
                            <img src="/images/card_yellow_red.png"
                                 style={{width: 30, marginLeft: 0, marginTop: 7}}
                                 alt="Verwarnung"/>
                        </div>
                    }
                    {!isLigaSpieltag &&
                        <div style={{width: 50, height: 50, cursor: "pointer"}}
                             onClick={() => onShowDetail(row)}>
                            <img src="/images/info.png"
                                 style={{width: 30, marginLeft: 0, marginTop: 7}}
                                 alt="Info"/>
                        </div>
                    }
                    {isLigaSpieltag && props.showExternPersonId && isEventOperator && row.externPersonId && !userIdVorhanden &&
                        <div style={{width: 50, height: 50, cursor: "pointer"}}
                             onClick={() => onShowExternPersonVerknuepfenDialog(row)}>
                            <img src="/images/nu_darthelfer_player_match.png"
                                 style={{width: 40, marginLeft: 0, marginTop: 7}}
                                 alt="Info"/>
                        </div>
                    }
                </div>
            </TableCell>
            }
        </TableRow>);
    }
    return tableRows;
}


export default function SimpleTable(props) {
    const classes = useStyles();
    let [teilnehmerFuerDetails,setTeilnehmerFuerDetails] = useState(undefined);
    let [externPartnerVerknuepfen, setExternPartnerVerknuepfen] = useState(undefined);

    let showButtons = props.isAdmin && !props.readonly;
    let tableRows = getRows(props, showButtons, (player) => setTeilnehmerFuerDetails(player), (player) => setExternPartnerVerknuepfen(player));

    const DetailDialog = () => {
        return <DHDefaultDrawer onClose={() => setTeilnehmerFuerDetails(undefined)} show={true}
                                title="Details" children={<div style={{padding:20}}>
            <div style={{marginBottom:5}}>SpielerID: {teilnehmerFuerDetails.idShort}</div>
            <div style={{marginBottom:5}}>Spielername: {teilnehmerFuerDetails.name}</div>
            <div style={{marginBottom:5}}>Vorname: {teilnehmerFuerDetails.vorname}</div>
            <div style={{marginBottom:5}}>Nachname: {teilnehmerFuerDetails.nachname}</div>
            <div style={{marginBottom:5}}>Strasse: {teilnehmerFuerDetails.strasse}</div>
            <div style={{marginBottom:5}}>PLZ: {teilnehmerFuerDetails.plz}</div>
            <div style={{marginBottom:5}}>Ort: {teilnehmerFuerDetails.ort}</div>
            <div style={{marginBottom:5}}>Telefon: {teilnehmerFuerDetails.telefon}</div>
            <div style={{marginBottom:5}}>Email: {teilnehmerFuerDetails.email}</div>
            <div style={{marginBottom:5}}>Auf Warteliste: {teilnehmerFuerDetails.warteliste ? "ja" : "nein"}</div>
            <div style={{marginBottom:5}}>Anmeldezeit: {DateUtil.localDateTimeToString(teilnehmerFuerDetails.time)}</div>
            <div style={{marginBottom:5}}>Teilnahmebestätigung offen: {teilnehmerFuerDetails.confirmationExpirationTime !== null && teilnehmerFuerDetails.confirmationExpirationTime !== undefined ? "Ja" : "Nein"}</div>
            <div style={{marginBottom:5}}>Bestätigung möglich bis (sofern Bestätigung noch notwendig): {DateUtil.localDateTimeToString(teilnehmerFuerDetails.confirmationExpirationTime)}</div>
        </div>}/>
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{backgroundColor: "white", width:100}}/>
                        <TableCell align="center" style={{backgroundColor: "white"}}/>
                        {props.showUsernames && <TableCell align="center" style={{backgroundColor: "white"}}/>}
                        {showButtons &&
                            <TableCell align="center" style={{fontSize: "14px", backgroundColor:"black", color:"white"}}/>
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontSize: "14px", backgroundColor:"black", color:"white", width:100}}>SpielerID</TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"black", color:"white"}}>Spieler</TableCell>
                        {props.showUsernames && <TableCell align="center" style={{fontSize: "14px", backgroundColor:"black", color:"white"}}>Username</TableCell>}
                        {showButtons &&
                            <TableCell align="center" style={{fontSize: "14px", backgroundColor:"black", color:"white", width:280}}/>
                        }
                    </TableRow>
                </TableHead>
                <TableBody key="bodykey">
                    {tableRows}
                </TableBody>
            </Table>
            {teilnehmerFuerDetails !== undefined && <DetailDialog />}
            {externPartnerVerknuepfen !== undefined &&
                <NuPlayerMatchComponent eventOperatorId={props.liga.eventOperatorId} player={externPartnerVerknuepfen}  onClose={() => setExternPartnerVerknuepfen(undefined)} />
            }
        </Paper>
    );
}
