import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import PlatzierungTable from "./PlatzierungTable";
import ConfigUtil from "../../../../util/ConfigUtil";

export default function PublicTournamentPlatzierungTab({tournamentId}) {
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [platzierungen, setPlatzierungen] = useState([]);

    useEffect(() => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.public + "/tournament/platzierung/" + tournamentId,
            {},
            json => {
                setPlatzierungen(json);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }, [tournamentId]);

    return <div style={{marginTop:20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <PlatzierungTable platzierungen={platzierungen}/>
    </div>
}

