import {connect} from 'react-redux'
import withRouter from "../components/RouterComponentWrapper"
import RegistrationComplete from "../components/RegistrationComplete";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const startseiteUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    const userRegistrationId = urlParams.get('id');
    return {userregistrationid: userRegistrationId, startseiteUrl: startseiteUrl};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationComplete))