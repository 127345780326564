import React, {useEffect, useState} from 'react';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ScoringX01View from "./x01/ScoringX01View";
import {withCookies} from 'react-cookie';
import ScoringCricketView from "./cricket/ScoringCricketView";

export default withCookies(ScoringView);

function ScoringView({gameid, playerid, autodartsBoardId, cookies}) {

    let [gameType, setGameType] = useState(undefined);
    let [showChat, setShowChat] = useState(false);

    useEffect(() => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.game + "/gametype/" + gameid,
            json => {
                setGameType(json);
            },
            responseNotOk => {},
            error => {});
    }, []);

    if (gameType === undefined) {
        return <div>Laden...</div>;
    }

    if( gameType === "x01" ) {
        return <ScoringX01View gameid={gameid} playerid={playerid}
                               showChat={showChat}
                               autodartsBoardId={autodartsBoardId}
                               changeShowChat={(show) => setShowChat(show)}
                               cookies={cookies} />
    }
    else if( gameType === "cricket" ) {
        return <ScoringCricketView gameid={gameid} playerid={playerid} showChat={showChat}
                                   changeShowChat={(show) => setShowChat(show)}
                                   cookies={cookies}/>;
    }
}
