import React, {useState} from 'react'
import {Drawer} from "@mui/material";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {InputText} from "primereact/inputtext";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function PublicTournamentDeregistrationComp({tournmanetId, onClose}) {

    const [clickCount, setClickCount] = useState(0);
    const [email, setEmail] = useState("");
    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const sendMail = () => {
        setErrorMessage(undefined);
        setClickCount(clickCount+1);
        let url = ConfigUtil.getConfig().resourceUrls.public + "/deregistration/email";
        FetchUtil.fetchPost(url,
            {tournamentId: tournmanetId, email: email},
            json => {
                setInfoMessage("Wir versuchen dir eine Email zu schicken.");
                setClickCount(1000);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const isMailSendAllowed = () => {
        let laenge = email.length  > 5;
        let atVorhanden = email.includes("@");
        let dotVorhanden = email.includes(".");
        return laenge && atVorhanden && dotVorhanden;
    }

    return <Drawer PaperProps={{sx: {width: 380}}} open={true} onClose={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Button icon={PrimeIcons.TIMES_CIRCLE}
                style={{position: "fixed", top: 5, left: 5}}
                className="p-button-danger" onClick={() => onClose()}/>
        <div style={{
            backgroundColor: "black", color: "white", textAlign: "center",
            fontWeight: "bold", fontSize: 20, padding: 10
        }}>
            Abmelden
        </div>
        <div style={{padding: 20}}>
            <div style={{marginBottom: 10, fontWeight: "bold"}}>Möglichkeit 1:</div>
            <div>Du hast eine Anmeldemail erhalten, in dieser befindet sich ein Link zur Abmeldung. Bitte klicke diesen Link.</div>
            <div style={{marginTop: 30, marginBottom: 10, fontWeight: "bold"}}>Möglichkeit 2:</div>
            <div>Gebe hier deine Emailadresse ein mit der du dich angemeldet hast und klicke auf 'Mail senden'. <br/>
                Sofern wir deine Emailadresse in der Teilnehmerliste finden, senden wir an diese Adresse eine neue Email.<br/>
                In dieser Mail befindet sich ein Link für die Abmeldung.
            </div>
            <div style={{marginTop: 20}}>
                <InputText style={{width: "100%"}} value={email}
                           placeholder="Email"
                           onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div style={{marginTop: 5}}>
                <Button label="Mail senden" disabled={!isMailSendAllowed() || clickCount > 5} style={{width: "100%"}} onClick={() => sendMail()}/>
            </div>
            <div style={{marginTop: 20}}>
                <span style={{fontWeight: "bold"}}>Hinweis:</span><br/>
                Die Email wird umgehend verschickt und sollte innerhalb von maximal einer Minute bei dir sein.<br/>
                Bitte prüfe eventuell deinen SPAM-Ordner.<br/><br/>
                Sofern wir die Mailadresse nicht finden, erhälst du hier, zur Datensicherheit unserer Teilnehmer, keine Rückmeldung.
            </div>
        </div>
    </Drawer>
}
