import React from 'react';
import {Button} from "primereact/button";
import DateUtil from "../../../util/DateUtil";

import "./trainingTable.css";

function getRows(props) {
    let tableRows = [];
    if( props.trainingssessions !== undefined) {
        for (let i = 0; i < props.trainingssessions.length; i++) {
            let session = props.trainingssessions[i];
            let formattedDateTime = DateUtil.localDateTimeToString(session.timestampAsString);
            tableRows.push(<tr key={"traininsession_row_" + session.id}>
                    <td  style={{width:150}}>{formattedDateTime}</td>
                {session.beschreibung !== null && session.beschreibung !== undefined && session.beschreibung !== "" ?
                    <td>{session.beschreibung}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {session.vonStatistikAusschliessen ?
                    <td style={{width: 50}}><i className="pi pi-star"/></td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}} style={{width: 50}}/>
                }
                <td style={{width:100}}>{session.firstToLegs}</td>
                {session.abgeschlossen ?
                    <td style={{width: 50}}><i className="pi pi-star"/></td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}} style={{width: 50}}/>
                }
                <td style={{width:150}}>
                    <Button icon="pi pi-list"
                            onClick={() => props.showErgebnisseSidebar(session.id)}
                            style={{marginRight: "5px", marginTop:"5px"}}
                            tooltip="Zeige Ergebnisse zur Trainingssession"/>
                    {
                        !session.abgeschlossen ?
                            <Button icon="pi pi-pencil" tooltip="Trainigssession ändern"
                                    style={{marginRight: "5px", marginTop:"5px"}}
                                    onClick={() => props.editTrainingssession(session)}/>
                            :
                            ""
                    }
                    {
                        !session.abgeschlossen ?
                            <Button icon="pi pi-star"
                                    onClick={() => props.showConfirmAbschliessenDialog(session.id)}
                                    style={{marginRight: "5px", marginTop:"5px"}}
                                    tooltip="Trainingssession abschliessen, keine Änderung mehr möglich!!!"
                                    className="p-button-danger"/>
                            :
                            ""
                    }
                    {
                        session.abgeschlossen && props.isAdmin ?
                            <Button icon="pi pi-star"
                                    onClick={() => props.changeTrainingssessionToNichtAbgeschlossen(session.id)}
                                    style={{marginRight:"5px", marginTop:"5px"}}
                                    tooltip="Trainingssession aktivieren"
                                    className="p-button-success"/>
                            :
                            ""
                    }
                </td>
            </tr>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {
    let tableRows = getRows(props);
    return (<table className="trainingstabelle darthelfertabelle">
            <thead>
                <tr>
                    <th>Datum</th>
                    <th>Beschreibung</th>
                    <th>Statistik 'nein'</th>
                    <th>'first to legs'</th>
                    <th>Abgeschossen</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </table>
    );
}