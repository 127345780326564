import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import WeekendLeagueDetail from "../../../components/views/liga/WeekendLeagueDetail";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const turnierId = urlParams.get('turnierid');
    return {turnierid: turnierId, userId: state.user.id, leagueoperators: state.leagueoperator.leagueoperators};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(WeekendLeagueDetail))