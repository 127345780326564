import React from 'react'

import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import Kachel from '../../general/Kachel';
import KachelLogoutContainer from "../../../containers/general/KachelLogoutContainer";

class KachelOverview extends React.Component {

    render() {
        return <div style={{backgroundColor:"white", padding:20}}>
            <div className={"grid p-dir-row"} style={{marginTop: "10px", align:"center", textAlign:"center"}}>
                <Kachel text={"Trainingstage"} icon={"calculator"}
                        navlinkto={NAVIGATION_ITEM.TRAINING_OVERVIEW.route} color="#c1f1ec"/>
                <Kachel text={"Spieltage"} icon={"gameday"}
                        navlinkto={NAVIGATION_ITEM.GAMEDAY_OVERVIEW.route} color="#ecb3ff"/>
                <Kachel text={"Personen"} icon={"tshirt"}
                        navlinkto={NAVIGATION_ITEM.PLAYER_OVERVIEW.route} color="#ff99c2"/>
            </div>
            <div className={"grid p-dir-row"} style={{marginTop: "10px"}}>
                <Kachel text={"Trainingsspiel eintragen"} icon={"calculator"} fixicon={"right-arrow"}
                        navlinkto={NAVIGATION_ITEM.TRAINING_GAME_EDIT.route} color="#c1f1ec"/>
                <Kachel text={"Auswertungen / Statistik"} icon={"molecule"}
                        navlinkto={NAVIGATION_ITEM.STATISTICS.route} color="#ffffcc"/>
                <Kachel text={"Einstellungen"} icon={"gear"}
                        navlinkto={NAVIGATION_ITEM.SETTINGS.route} color="#e0e0eb"/>
            </div>
            <div className={"grid p-dir-row"} style={{marginTop: "10px"}}>
            </div>
            <div className={"grid p-dir-row"} style={{marginTop: "10px", marginBottom: "20px"}}>
                <KachelLogoutContainer color="#7070db"/>
            </div>
        </div>
    }
}

export default KachelOverview
