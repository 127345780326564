export const NEW_STATISTIK_OVER_WEBSOCKET = 'NEW_STATISTIK_OVER_WEBSOCKERT';

export const newStatistikOverWebsocket = (statistik) => ({
    type: NEW_STATISTIK_OVER_WEBSOCKET,
    value: statistik,
});

export function statistikOverWebsocket(statistik) {
    return function (dispatch) {
        dispatch(newStatistikOverWebsocket(statistik));
    };
}