import * as React from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useState} from "react";

export default function RanglisteTable({participants}) {
    let [selectedParticipant, setSelectedParticipant] = useState(undefined);

    if( participants.length === 0) {
        return ""
    }

    const getTableRows = (participants) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18};
        let lastValue = undefined;
        let platz = 1;
        participants.sort(function(player1, player2){return player2.ranglistePunkteGesamt - player1.ranglistePunkteGesamt});
        for(let i = 0; i < participants.length; i++) {
            let participant = participants[i];
            let wert = participant.ranglistePunkteGesamt;
            if( lastValue === undefined) {
                lastValue = wert;
            } else if (lastValue !== wert ) {
                platz = i + 1;
                lastValue = wert;
            }
            let style;
            if( selectedParticipant !== undefined && selectedParticipant.id === participant.id) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }
            returnRows.push(  <TableRow key={participant.id} onMouseOver={() => setSelectedParticipant(participant)}
                                        onClick={() => setSelectedParticipant(participant)}>
                <TableCell style={style}>{platz}.</TableCell>
                <TableCell style={style}>{participant.name}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.ranglistePunkteGesamt !== null && participant.ranglistePunkteGesamt !== undefined ? participant.ranglistePunkteGesamt : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.ranglistePunkte !== null && participant.ranglistePunkte !== undefined ? participant.ranglistePunkte : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.ranglisteBestleistung !== null && participant.ranglisteBestleistung !== undefined ? participant.ranglisteBestleistung : "-"}</TableCell>
                <TableCell style={{...style, textAlign:"center"}}>{participant.ranglisteAnwesenheit !== null && participant.ranglisteAnwesenheit !== undefined ? participant.ranglisteAnwesenheit : "-"}</TableCell>
            </TableRow>)
            background = !background;
        }
        return returnRows;
    }

    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:150, textAlign:"center"}}>Gesamt</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:150, textAlign:"center"}}>Rangliste</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:150, textAlign:"center"}}>Bestleistung</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:150, textAlign:"center"}}>Anwesenheit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(participants)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
