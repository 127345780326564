import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ConfirmDialog from "../../general/ConfirmDialog";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

function VerwalteRanglisteMarkerDialog({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [selectedMarker, setSelectedMarker] = useState(undefined);
    const [marker, setMarker] = useState("");
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        ladeMarker();
    }, []);

    const ladeMarker = () => {
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker";
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/eventoperator/" + eventOperatorId;
        }
        FetchUtil.fetchGet(url,
            json => {
                let newMarkers = [];
                for (const jsonElement of json) {
                    newMarkers.push(jsonElement.marker);
                }
                setMarkers(newMarkers);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    };

    const deleteMarker = () => {
        setErrorMessage(undefined);
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/delete/" + selectedMarker;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/delete/eventoperator/" + eventOperatorId + "/" + selectedMarker;
        }
        FetchUtil.fetchDelete(url,
            {},
            () => {
                setSelectedMarker(undefined);
                setMarker("");
                ladeMarker();
                setInfoMessage("Marker wurde gelöscht.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const saveMarker = () => {
        setErrorMessage(undefined);
        let url;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/save/" + marker;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ranglistemarker/save/eventoperator/" + eventOperatorId + "/" + marker;
        }
        FetchUtil.fetchPost(url,
            {},
            () => {
                setSelectedMarker(undefined);
                setMarker("");
                ladeMarker();
                setInfoMessage("Marker wurde gespeichert.");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const rows = () => {
        let rows = [];
        for (const marker of markers) {
            rows.push(<div className="grid" key={"key_marker_" + marker} style={{marginBottom:20}}>
                <div className="col">
                    <Button label="Marker löschen" style={{width:150}} type="button"
                            onClick={() => setSelectedMarker(marker)}/><span style={{fontSize:20, marginLeft:20}}>{marker}</span>
                </div>
            </div>)
        }
        return rows;
    }

    return <DHDialog onClose={() => onClose()} title="Verwalte Ranglistemarkers" show={true} buttons={[]}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis" toggleable={true} collapsed={true} style={{marginBottom:40}}>
            <div>
                Über diesen Dialog kannst du Ranglistenmarkers verwalten.<br/>
                Hintergrund ist, dass du zur gleichen Zeit verschiedene Ranglisten spielen lassen kannst.<br/>
                Sobald du Marker angelegt hast, kannst du Ranglisten mit diesen Markern anlegen.<br/>
                Gleichzeitig musst du dann diese Marker einem Ranglistenturnier zuweisen.<br/>
                Damit erfolgt eine Filterung der Ranglisten nach den angegebenen Markern.<br/>
                <br/>
                Achtung: Sobald du einen Marker löscht, bleiben diese an den zugordneten Turnieren und Ranglisten bestehen. Du müsstest diese Ranglisten u. evtl. Turniere dann anpassen.
            </div>
        </Fieldset>
        <div style={{marginBottom:20}}>
            <div style={{marginTop:20}}>
                <InputText type="text" placeholder="Bezeichnung des Markers" value={marker}
                           onChange={(e) => setMarker(e.target.value)}
                style={{width:"100%"}}/>
            </div>
            <div style={{marginTop:20}}>
                <Button onClick={() => saveMarker()} label="Speichern..."
                        disabled={marker === ""}/>
            </div>
            <hr/>
        </div>
        <div style={{marginBottom:10}}>
            <h3>Liste vorhandener Markers:</h3>
        </div>
        {rows()}
        <ConfirmDialog visible={selectedMarker !== undefined}
                       callBackOnNein={() => setSelectedMarker(undefined)}
                       callBackOnJa={() => deleteMarker()}
                       header="Marker löschen"
                       textUnten="Möchtest du den Marker wirklich löschen?"/>
    </>}/>
}


VerwalteRanglisteMarkerDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
};


export default VerwalteRanglisteMarkerDialog;
