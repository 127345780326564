import React, {useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export function AdministrationNuAnbindung() {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);


    let [eventOperatorId, setEventOperatorId] = useState("");
    let [tournamentId, setTournamentId] = useState("");


    const syncNuLigaspieltageZumTurnier = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/nu/tournament/ligaspieltage/sync/" + tournamentId,
            {},
            () => {
                setInfoMessage("Synchronisiert");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }


    const syncNuLigaspieltageZumEventOperator = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/nu/eventoperator/ligaspieltage/sync/" + eventOperatorId,
            {},
            () => {
                setInfoMessage("Synchronisiert");
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            })
    }

    return <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid red"}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <h3>nu-Ligaspieltage zum Veranstalter neu Synchronisieren</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="eventOperatorId" style={{width: 600}} value={eventOperatorId}
                       onChange={(e) => setEventOperatorId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <Button onClick={() => syncNuLigaspieltageZumEventOperator()} label="Sync..." disabled={eventOperatorId === ""}/>
        </div>
        <hr/>
        <h3>nu-Ligaspieltage zum Ligaspieltag neu Synchronisieren</h3>
        <div style={{width: "100%"}}>
            <InputText type="text" placeholder="tournamentId" style={{width: 600}} value={tournamentId}
                       onChange={(e) => setTournamentId(e.target.value)}/>
        </div>
        <div style={{marginTop: 20}}>
            <Button onClick={() => syncNuLigaspieltageZumTurnier()} label="Sync..." disabled={tournamentId === ""}/>
        </div>
        <hr/>
    </div>
}
