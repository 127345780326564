import * as React from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {openEventOperatorById} from "../../../../containers/views/public/PublicTournamentHelper";

export default function EventOperatorTable({eventOperators}) {
    const CELL_PADDING_VALUE = 8;

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {cursor:"pointer", color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {cursor: "pointer", color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        for(let i = 0; i < eventOperators.length; i++) {
            let style = background ? styleWithBackground : styleWithoutBackground;

            let eventOperator = eventOperators[i];
            returnRows.push(<TableRow key={eventOperator.id}>
                <TableCell style={style}
                           onClick={() => openEventOperatorById(eventOperator.id)}>
                           {eventOperator.name}
                </TableCell>
            </TableRow>)
            background = !background;
        }
        return returnRows;
    }

    if( eventOperators === null || eventOperators === undefined || eventOperators.length === 0) {
        return <div style={{color:"white", fontSize:16, padding:20}}>Keine Daten vorhanden.</div>
    }

    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow style={{height:40}}>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Veranstalter</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
