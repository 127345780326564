import React, {useState} from "react";
import {Button} from "primereact/button";
import DHDialog from "../general/DHDialog";

export default function AutodartsManuelScoreDialog({score, onScore, onClose}) {

    const [miss, setMiss] = useState(false);
    const [mode, setMode] = useState("single");
    const [value, setValue] = useState(undefined);

    const getModeButton = (label, value) => {
        return <Button label={label} onClick={() => {
            setMode(value);
            setMiss(false);
        }} style={{width:100, height:50, marginRight:5}} className={mode === value ? "p-button-primary" : "p-button-secondary"}/>
    }

    const getNumberButton = (label, tmpValue) => {
        return <Button label={label} onClick={() => {
            setValue(tmpValue);
            setMiss(false);
        }} style={{width:100, height:50, marginRight:5}} className={value === tmpValue ? "p-button-primary" : "p-button-secondary"}/>
    }

    const getMissArea = () => {
        return <div style={{display: "flex", marginTop: 20}}>
            <Button label="MISS" onClick={() => {
                setMiss(!miss);
                setMode(undefined);
                setValue(undefined);
            }
            } style={{width:100, height:50, marginRight:5}} className={miss ? "p-button-primary" : "p-button-secondary"}/>
        </div>
    }
    const getModeArea = () => {
        return <div style={{display: "flex", marginTop: 20}}>
            {getModeButton("Single", "single")}
            {getModeButton("Double", "double")}
            {getModeButton("Triple", "triple")}
            {getModeButton("Surround", "surround")}
        </div>
    }

    const getNumberArea = () => {
        return <>
            <div style={{display: "flex", marginTop: 20}}>
                {getNumberButton("1", 1)}
                {getNumberButton("2", 2)}
                {getNumberButton("3", 3)}
                {getNumberButton("4", 4)}
                {getNumberButton("5", 5)}
            </div>
            <div style={{display: "flex", marginTop: 5}}>
                {getNumberButton("6", 6)}
                {getNumberButton("7", 7)}
                {getNumberButton("8", 8)}
                {getNumberButton("9", 9)}
                {getNumberButton("10", 10)}
            </div>
            <div style={{display: "flex", marginTop: 5}}>
                {getNumberButton("11", 11)}
                {getNumberButton("12", 12)}
                {getNumberButton("13", 13)}
                {getNumberButton("14", 14)}
                {getNumberButton("15", 15)}
            </div>
            <div style={{display: "flex", marginTop: 5}}>
                {getNumberButton("16", 16)}
                {getNumberButton("17", 17)}
                {getNumberButton("18", 18)}
                {getNumberButton("19", 19)}
                {getNumberButton("20", 20)}
            </div>
            <div style={{display: "flex", marginTop: 5}}>
                {getNumberButton("25", 25)}
                {getNumberButton("Bull", 50)}
            </div>
        </>
    }

    const getCurrentValue = () => {
        if( miss ) {
            return {value: 0, number:0, name: "MISS", multiplier: 1, changed: true};
        }

        if( value === 25 ) {
            return {value: 25, number:25, name: "25", multiplier: 1, changed: true};
        } else if( value === 50 ) {
            return {value: 50, number:50, name: "Bull", multiplier: 1, changed: true};
        } else if( value !== undefined) {
            let anzeige = "M";
            let multiplier = 0;
            if( mode === "single") {
                multiplier = 1;
                anzeige = "S";
            } else if( mode === "double") {
                multiplier = 2;
                anzeige = "D";
            } else if( mode === "triple") {
                multiplier = 3;
                anzeige = "T";
            }
            return {value: value * multiplier, name: anzeige + value, number: value, multiplier: multiplier, changed: true};
        }

        return {value: undefined, name: ""};
    }

    const getCurrentValueArea = () => {
        return <div style={{height:50, fontSize:20}}>
            Aktueller Wert: {getCurrentValue().name}
        </div>
    }

    const isSaveActive = () => {
        if( miss === true) {
            return true;
        }
        return mode !== undefined && getCurrentValue().value !== undefined
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" style={{marginRight: 5}}  disabled={!isSaveActive()} onClick={() => onScore(getCurrentValue())}/>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
        </div>
    );

    return <DHDialog onClose={onClose} title="Wurf abändern / Wurf von Hand eingeben" buttons={dialogFooter} comp={<>
        <div style={{fontSize: 16, fontWeight: "bold", marginBottom: 30}}>Score {score + 1} soll geändert / eingegeben werden!</div>
        {getCurrentValueArea()}
        {getMissArea()}
        {getModeArea()}
        {getNumberArea()}
    </>}/>
}
