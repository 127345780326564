import React, {useEffect, useRef} from "react";

import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";


export default function ErrorInfoComponent({errorMessage, infoMessage, onClearInfoMessage, onClearErrorMessage}) {

    const messages = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        if( infoMessage !== undefined && infoMessage !== null && infoMessage !== "") {
            showInfo(infoMessage);
        }
    }, [infoMessage]);

    useEffect(() => {
        if( errorMessage !== undefined && errorMessage !== null && errorMessage !== "") {
            showError(errorMessage);
        } else {
            clearMessages();
        }
    }, [errorMessage]);

    const clearMessages = () => {
        messages.current.clear();
    }

    const showInfo = (message) => {
        toast.current.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        });
    }

    function showError(message) {
        messages.current.show({severity: 'error', sticky: true, summary: '', detail: message});
    }

    return <div style={{position:"fixed", top: 0, left:0, width:"100%", zIndex:1000}}>
            <Toast ref={toast} onHide={() => onClearInfoMessage()}/>
            <Messages ref={messages} onRemove={() => {
                if( onClearErrorMessage !== undefined) {
                    onClearErrorMessage();
                }
            }}></Messages>
        </div>
}
