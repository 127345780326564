import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import DHDialog from "./DHDialog";

class ConfirmDialog extends React.Component {

    render() {
        if( !this.props.visible ) {
            return "";
        }
        const footer = (
            <div>
                <Button label="Ja" icon="pi pi-check" onClick={() => this.props.callBackOnJa()} />
                <Button label="Nein" icon="pi pi-times" onClick={() => this.props.callBackOnNein()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog onClose={() => this.props.callBackOnNein()} title={this.props.header} buttons={[]} comp={<>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop: "20px"}}>
                {this.props.textUnten}
            </div>
            <div style={{marginTop: 20}}>
                {footer}
            </div>
        </>}/>
    }
}


ConfirmDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textOben: PropTypes.string,
    textUnten: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnNein: PropTypes.func.isRequired,
    callBackOnJa: PropTypes.func.isRequired,
};

export default ConfirmDialog;
