import React from 'react'

import {Panel} from 'primereact/panel';
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import SettingsSelectDayAndTimeComponent from "./SettingsSelectDayAndTimeComponent";
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {GameModusComponent} from "../../general/GameModusComponent";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import {GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";

class SettingsTrainingssessions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: undefined,
            trainingsessionDtos: [],
            firstToLegs: undefined,
            modusid: undefined,
            startpunkte: undefined,
        };

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.changeData = this.changeData.bind(this);
        this.isDayActive = this.isDayActive.bind(this);
        this.getTime = this.getTime.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings,
            json => {
                this.setState(json);
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Trainingsessionsettings: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Trainingsessionsettings: " + error.message);
            })
    }

    changeData(day, active, time) {
        let foundDay = false;
        let newTrainingsessionDtosArray = [];
        for (var i = 0; i < this.state.trainingsessionDtos.length; i++) {
            let tmpDay = this.state.trainingsessionDtos[i];
            if (tmpDay.day === day) {
                foundDay = true;
                newTrainingsessionDtosArray.push({day: day, active: active, time: time})
            } else {
                newTrainingsessionDtosArray.push(tmpDay);
            }
        }
        if (!foundDay) {
            newTrainingsessionDtosArray.push({day: day, active: active, time: time})
        }
        let newTrainingsessionDtos = {trainingsessionDtos: newTrainingsessionDtosArray}
        let newState = {...this.state, ...newTrainingsessionDtos};
        this.setState(newState);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    isDayActive(day) {
        for (var i = 0; i < this.state.trainingsessionDtos.length; i++) {
            let tmpDay = this.state.trainingsessionDtos[i];
            if (tmpDay.day === day) {
                return tmpDay.active;
            }
        }
        return false;
    }

    getTime(day) {
        for (var i = 0; i < this.state.trainingsessionDtos.length; i++) {
            let tmpDay = this.state.trainingsessionDtos[i];
            if (tmpDay.day === day) {
                return tmpDay.time;
            }
        }
        return "";
    }

    save() {
        this.messages.clear();

        let data = {
            id: this.state.id,
            firstToLegs: this.state.firstToLegs,
            modusid: this.state.modusid,
            startpunkte: this.state.startpunkte,
            trainingsessionDtos: [...this.state.trainingsessionDtos]};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings,
            data,
            json => {
                this.showInfo("Speichern der Einstellungen zu Trainingssessions war erfolgreich!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        return <div style={{textAlign: "left"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <Fieldset legend="Hinweise..." style={{marginBottom: 20}}>
                <div>Hier wird eingestellt, wann das System eine Trainingssession automatisch anlegen soll.</div>
                <div>Sofern z.B. Di. u. Do. immer Training ist, kann das System diese Sessions automatisch anlegen.
                </div>
                <div style={{marginBottom: "20px"}}>Das Anlegen erfolgt wenige Minuten vor dem Sessionstart, damit
                    können dann direkt Ergebnisse in dieser Traininssession erfasst werden.
                </div>
            </Fieldset>
            <Panel header="Einstellungen für automatische Anlage von Trainigssessions" style={{textAlign: "left"}}>
                <div className="grid" style={{marginTop:10, marginBottom:10}}>
                    <div className="col">
                        Trainingssession werden mit folgenden Standardwerten angelegt:
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 100}}>first to legs:</div>
                    <div className="col">
                        <GameFirstToLegsComponent firstToLegs={this.state.firstToLegs} onChange={(firstToLegs) => this.setState({firstToLegs: firstToLegs})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 100}}>Modus:</div>
                    <div className="col">
                        <GameModusComponent modusId={this.state.modusid}
                                            onChange={(modusid) => this.setState({modusid: modusid})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 100}}>Startpunkte:</div>
                    <div className="col">
                        <GameStartpunkteComponent startpunkte={this.state.startpunkte}
                                                  onChange={(startpunkte) => this.setState({startpunkte: startpunkte})}/>
                    </div>
                </div>
                <hr/>
                <SettingsSelectDayAndTimeComponent beschriftung="Montag" day="monday"
                                                   value={this.getTime("monday")}
                                                   active={this.isDayActive("monday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Dienstag" day="tuesday"
                                                   value={this.getTime("tuesday")}
                                                   active={this.isDayActive("tuesday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Mittwoch" day="wednesday"
                                                   value={this.getTime("wednesday")}
                                                   active={this.isDayActive("wednesday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Donnerstag" day="thursday"
                                                   value={this.getTime("thursday")}
                                                   active={this.isDayActive("thursday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Freitag" day="friday"
                                                   value={this.getTime("friday")}
                                                   active={this.isDayActive("friday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Samstag" day="saturday"
                                                   value={this.getTime("saturday")}
                                                   active={this.isDayActive("saturday")}
                                                   onChange={this.changeData}/>
                <SettingsSelectDayAndTimeComponent beschriftung="Sonntag" day="sunday"
                                                   value={this.getTime("sunday")}
                                                   active={this.isDayActive("sunday")}
                                                   onChange={this.changeData}/>
                <div className="grid" style={{marginTop: "25px"}}>
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}></div>
                    <div className="col"><Button label="Speichern" style={{width: "200px"}}
                                                   onClick={() => this.save()}/></div>
                </div>
            </Panel>
        </div>
    }
}

SettingsTrainingssessions.propTypes = {};

export default SettingsTrainingssessions;