import * as React from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useState} from "react";
import {Button} from "primereact/button";
import WebPushUtil from "../../../../util/WebPushUtil";

export default function ParticipantTable({tournamentId, participants, isBezahlung, isParticipantsMailConfirmation, supportsWebPush, pushSpielerIds, updateSubscriptions}) {
    const CELL_PADDING_VALUE = 8;

    let [selectedParticipant, setSelectedParticipant] = useState(undefined);

    if( participants.length === 0) {
        return ""
    }

    const getTableRows = (pushSpielerIds, participants, isBezahlung, isParticipantsMailConfirmation, supportsWebPush) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleSelected = {background: "#FD8943", color:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        let counter = 1;
        participants.forEach(participant => {
            let style;
            if( selectedParticipant !== undefined && selectedParticipant.id === participant.id) {
                style = styleSelected;
            } else {
                style = background ? styleWithBackground : styleWithoutBackground;
            }
            returnRows.push( <TableRow key={participant.id} onMouseOver={() => setSelectedParticipant(participant)}
                                       onClick={() => setSelectedParticipant(participant)}>
                <TableCell style={style}>{counter}.</TableCell>
                <TableCell style={style}>{participant.name}</TableCell>
                <TableCell style={{...style, width:100}}>
                    {!isParticipantsMailConfirmation && !isBezahlung && "-"}
                    {isParticipantsMailConfirmation && <img src={(participant.confirmationExpirationTime === null || participant.confirmationExpirationTime === undefined) ? "/images/player_green.png" : "/images/player_border.png"} alt="Anmeldung" style={{width:25}}/>}
                    {isBezahlung && <img src={participant.bezahlt ? "/images/euro_green.png" : "/images/euro_black.png"} alt="Bezahlung" style={{width:25}}/>}
                    {supportsWebPush && !pushSpielerIds.includes(participant.id) && <Button icon="pi pi-star" style={{marginRight:5, verticalAlign:"baseline"}} onClick={() => pushAktivieren(tournamentId, participant.id)}/>}
                    {supportsWebPush && pushSpielerIds.includes(participant.id) && <Button icon="pi pi-star-fill" style={{marginRight:5, verticalAlign:"baseline"}} onClick={() => pushDeaktivieren(tournamentId, participant.id)}/>}
                </TableCell>
            </TableRow>)
            background = !background;
            counter++;
        });
        return returnRows;
    }

    const pushAktivieren = (tournamentId, spielerId) => {
        WebPushUtil.pushAktivieren(tournamentId, spielerId)
        updateSubscriptions();
    }

    const pushDeaktivieren = (tournamentId, spielerId) => {
        WebPushUtil.pushDeaktivieren(tournamentId, spielerId)
        updateSubscriptions();
    }

    return (
        <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, width:80, padding:CELL_PADDING_VALUE}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE, width:100}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows(pushSpielerIds, participants, isBezahlung, isParticipantsMailConfirmation, supportsWebPush)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
