class TurnierModelDoppelKoInitialXY {
    static startwerte4() {
        return {x: 470, y: 80, interval:80, maxX: 1230};
    }

    static startwerte8() {
        return {x: 920, y: 80, interval:80, maxX: 1900};
    }

    static startwerte16() {
        return {x: 1360, y: 80, interval:80, maxX: 2560}
    }

    static startwerte32() {
        return {x: 1800, y:80, interval:80, maxX:3280};
    }

    static startwerte64() {
        return {x: 2300, y:80, interval:80, maxX:3680};
    }

    static startwerte128() {
        return {x: 2700, y:80, interval:80, maxX:3980};
    }

    static startwerte256() {
        return {x: 3100, y:80, interval:80, maxX:4280};
    }

    static ueberschriften4() {
        return [
            {x: 300, y: 30, text:"Verliererseite"},
            {x: 600, y: 30, text:"Auslosung"},
            {x: 850, y: 30, text:"Gewinnerseite"},
        ];
    }

    static ueberschriften8() {
        return [
            {x: 600, y: 30, text:"Verliererseite"},
            {x: 960, y: 30, text:"Auslosung"},
            {x: 1300, y: 30, text:"Gewinnerseite"},
        ];
    }

    static ueberschriften16() {
        return [
            {x: 1000, y: 30, text:"Verliererseite"},
            {x: 1400, y: 30, text:"Auslosung"},
            {x: 1780, y: 30, text:"Gewinnerseite"},
        ];
    }

    static ueberschriften32() {
        return [
            {x: 1400, y: 30, text:"Verliererseite"},
            {x: 1830, y: 30, text:"Auslosung"},
            {x: 2250, y: 30, text:"Gewinnerseite"},
        ];
    }

    static ueberschriften64() {
        return [
            {x: 1900, y: 30, text:"Verliererseite"},
            {x: 2320, y: 30, text:"Auslosung"},
            {x: 2750, y: 30, text:"Gewinnerseite"},
        ];
    }

    static ueberschriften128() {
        return [
            {x: 2100, y: 30, text:"Verliererseite"},
            {x: 2720, y: 30, text:"Auslosung"},
            {x: 3250, y: 30, text:"Gewinnerseite"},
        ];
    }
}

export default TurnierModelDoppelKoInitialXY;
