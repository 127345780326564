import React from 'react'
import {ShortStatistikArea} from "./ligaComponents";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";

class ProfilFriendStatistikArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.statistics + "/kurzstatistik/" + this.props.userId,
            json => {
                this.setState({statistikLetzte30Tage: json});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        if( this.state.statistikLetzte30Tage === null || this.state.statistikLetzte30Tage === undefined) {
            return "Daten werden geladen";
        }
        return <div className="col-12 md:col-12 lg:col-4" style={{padding:10}}>
            <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid #06c7e6"}}>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <h3>Kurzstatistik</h3>
                <ShortStatistikArea statistik={this.state.statistikLetzte30Tage} label="30 Tage" />
            </div>
        </div>
    }
}

ProfilFriendStatistikArea.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default ProfilFriendStatistikArea;
