import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PlayerUtil from "../../util/PlayerUtil";

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 400,
    },
});

function hatSpielerBezahlt(props, spieler) {
    if( props.payments === undefined || props.payments === null) {
        return false;
    }
    for (let i = 0; i < props.payments.length; i++) {
        let spielerId = props.payments[i];
        if( spielerId === spieler.id) {
            return true;
        }
    }
    return false;
}
function getErgebnisBySpieler(props, spieler1, spieler2) {
    let spieler1Id = spieler1.id;
    let spieler2Id = spieler2.id;
    for (let i = 0; i < props.trainingsergebnisse.length; i++) {
        let tmpErgebnis = props.trainingsergebnisse[i];
        let spieler1Vorhanden = tmpErgebnis.spieler1Id === spieler1.id || tmpErgebnis.spieler2Id === spieler1Id;
        let spieler2Vorhanden = tmpErgebnis.spieler1Id === spieler2.id || tmpErgebnis.spieler2Id === spieler2Id;
        if(  spieler1Vorhanden && spieler2Vorhanden) {
            if( tmpErgebnis.spieler1Id === spieler1.id) {
                return tmpErgebnis.spieler1Legs + ":" + tmpErgebnis.spieler2Legs;
            } else {
                return tmpErgebnis.spieler2Legs + ":" + tmpErgebnis.spieler1Legs;
            }
        }
    }
    return "";
}

function getColumnsBySpieler(props, spieler) {
    let style = {fontSize: "14px", width:"50px", minWidth:"50px", maxWidth:"50px", textAlign:"center", borderRight:"2px solid #f4f4f4"};
    let cols = [];
    for (let i = 0; i < props.spieler.length; i++) {
        let tmpSpieler = props.spieler[i];
        let columnKey = "column_" + spieler.id + "_column_" + tmpSpieler.id + "_" + Math.random();
        if( spieler.id === tmpSpieler.id) {
            cols.push(<TableCell key={columnKey} style={style}>-</TableCell>)
        } else {
            let ergebnis = getErgebnisBySpieler(props, spieler, tmpSpieler);
            cols.push(<TableCell key={columnKey} style={style}>{ergebnis}</TableCell>);
        }
    }
    return cols;
}

function getRows(props) {
    let tableRows = [];
    if( props.spieler !== undefined) {
        for (let i = 0; i < props.spieler.length; i++) {
            let spieler = props.spieler[i];
            let modulo = i % 2;
            let currentStyle = modulo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let spielername = PlayerUtil.anzeigeNameZumSpieler(spieler);

            let spielerColumns = getColumnsBySpieler(props, spieler);
            tableRows.push(<TableRow key={"tabellenzeile_" + spieler.id} style={currentStyle}>
                <TableCell style={{width:"50px", borderRight:"2px solid #f4f4f4"}}>
                    {"" + (i+1) + "."}
                </TableCell>
                <TableCell style={{borderRight:"2px solid #f4f4f4"}}>{spielername}</TableCell>
                {spielerColumns}
                <TableCell style={{width:"10px", borderRight:"2px solid #f4f4f4"}}>
                    {hatSpielerBezahlt(props, spieler) ? <img src="/images/check.png" style={{width: "20px"}} alt="bezahlt"/> : ""}
                </TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {
    const classes = useStyles();

    let tableRows = getRows(props);

    let columns = [];
    for(let i = 0; i < props.spieler.length; i++) {
        let spieler = props.spieler[i];
        let columnKey = "column_spieler_header_" + spieler.id;
        columns.push(<TableCell key={columnKey} style={{fontSize: "14px", width:"50px", minWidth:"50px", maxWidth:"50px", textAlign:"center", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>{(i+1) + "."}</TableCell>)
    }
    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: "14px", width:"50px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}></TableCell>
                        <TableCell style={{fontSize: "14px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>Name</TableCell>
                        {columns}
                        <TableCell style={{fontSize: "14px", width:"10px", backgroundColor:"#a6a6a6", borderRight:"2px solid #f4f4f4"}}>bez.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key="bodykey">
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}