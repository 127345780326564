import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import DHDialog from "./DHDialog";

class ConfirmKennwortDialog extends React.Component {

    render() {
        if( !this.props.visible ) {
            return "";
        }
        const footer = (
            <div>
                <Button label="Absenden" icon="pi pi-check" onClick={() => this.props.callBackOnAbsenden()} />
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.props.callBackOnAbbrechen()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog onClose={() => this.props.callBackOnAbbrechen()} title={this.props.header} buttons={[]} comp={<>
            <div>
                {this.props.textOben}
            </div>
            <div style={{marginTop: 20}}>
                Bitte Kennwort eingeben:
            </div>
            <div style={{marginTop: "20px"}}>
                <InputText value={this.props.kennwort} style={{width: "100%"}}
                           onChange={(e) => this.props.changeKennwort(e.target.value)}/>
            </div>
            <div style={{marginTop: 20}}>
                {footer}
            </div>
        </>}/>
    }
}


ConfirmKennwortDialog.propTypes = {
    header: PropTypes.string.isRequired,
    textOben: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    callBackOnAbbrechen: PropTypes.func.isRequired,
    callBackOnAbsenden: PropTypes.func.isRequired,
};

export default ConfirmKennwortDialog;
