import React from 'react';
import PropTypes, {instanceOf} from "prop-types";
import {Cookies} from "react-cookie";
import ScoringChatComponent from "../ScoringChatComponent";
import ScoringVideoComponent from "../ScoringVideoComponent";
import ScoringVideoSettingsComponent from "../ScoringVideoSettingsComponent";
import {Button} from "primereact/button";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Toast} from "primereact/toast";
import {Messages} from "primereact/messages";
import {OverlayPanel} from "primereact/overlaypanel";
import AusbullenComponent from "../AusbullenComponent";
import ScoringCricketChangeDialog from "./ScoringCricketChangeDialog";
import QRCodeSidebar from "../../../general/QRCodeSidebar";

let stompClient = undefined;

const zIndexMessages = 600;

class ScoringCricketView extends React.Component {

    constructor(props) {
        super(props);

        const {cookies} = props;
        let videoAnzeigeart = 1;
        let videoWidth = 40;
        let videoHeight = 30;
        let anzeigeeinstellung = "system";
        let darthelfer_scoring_anzeigeart = cookies.get('darthelfer_scoring_anzeigeart');
        if (darthelfer_scoring_anzeigeart != null && darthelfer_scoring_anzeigeart !== undefined && darthelfer_scoring_anzeigeart !== "") {
            videoAnzeigeart = parseInt(cookies.get('darthelfer_scoring_anzeigeart'));
        }
        let darthelfer_scoring_width = cookies.get('darthelfer_scoring_width');
        if (darthelfer_scoring_width != null && darthelfer_scoring_width !== undefined && darthelfer_scoring_width !== "") {
            videoWidth = parseInt(cookies.get('darthelfer_scoring_width'));
        }
        let darthelfer_scoring_height = cookies.get('darthelfer_scoring_height');
        if (darthelfer_scoring_height != null && darthelfer_scoring_height !== undefined && darthelfer_scoring_height !== "") {
            videoHeight = parseInt(cookies.get('darthelfer_scoring_height'));
        }
        let darthelfer_scoring_anzeigeeinstellung = cookies.get('darthelfer_scoring_anzeigeeinstellung');
        if (darthelfer_scoring_anzeigeeinstellung != null && darthelfer_scoring_anzeigeeinstellung !== undefined && darthelfer_scoring_anzeigeeinstellung !== "") {
            anzeigeeinstellung = cookies.get('darthelfer_scoring_anzeigeeinstellung');
        }

        this.state = {
            gameid: props.gameid,
            aufnahme: "",
            saving: false,
            showWebcamHinweis: true,
            videoMobilWidth: 100,
            videoMobilHeight: 50,
            videoAnzeigeart: videoAnzeigeart,
            videoWidth: videoWidth,
            videoHeight: videoHeight,
            showVideoMenu: true,
            showVideo: true,
            bullAbgeschickt: false,
            videoOff: false,
            chatMessages: [],
            anzeigeeinstellung: anzeigeeinstellung,
            wuerfe: [],
            showShortCuts: false,
            singleActive: true,
            doubleActive: true,
            tribleActive: true,
            showChangeDialog: false,
        };

        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onGameError = this.onGameError.bind(this);
        this.onChatMessageReceived = this.onChatMessageReceived.bind(this);
        this.handelKeyDown = this.handelKeyDown.bind(this);
        this.addShortCutWurf = this.addShortCutWurf.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.props.gameid);
        this.addEventListener();
    }

    componentWillUnmount() {
        this.removeEventListener();
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
    }

    addEventListener() {
        document.addEventListener("keydown", this.handelKeyDown);
    }

    removeEventListener() {
        document.removeEventListener("keydown", this.handelKeyDown);
    }


    addShortCutWurf(zahl) {
        if ( this.state.singleActive ){
            this.addWurf("S" + zahl);
        } else if( this.state.doubleActive) {
            this.addWurf("D" + zahl);
        } else if( this.state.tribleActive) {
            if( zahl === 25) {
                return;
            }
            this.addWurf("T" + zahl);
        } else {
            this.showError("Shortcut kann nicht verarbeitet werden.");
        }
    }

    handelKeyDown(event) {
        if (event.keyCode === 13) {
            // enter
            this.score();
            return;
        }
        switch (event.key) {
            case "0": this.addWurf("S0"); event.preventDefault(); break;
            case "1": this.addWurf("S0"); event.preventDefault(); break;
            case "2": this.addWurf("S0"); event.preventDefault(); break;
            case "3": this.addWurf("S0"); event.preventDefault(); break;
            case "4": this.addWurf("S0"); event.preventDefault(); break;
            case "5": this.addWurf("S0"); event.preventDefault(); break;
            case "6": this.addWurf("S0"); event.preventDefault(); break;
            case "7": this.addWurf("S0"); event.preventDefault(); break;
            case "Backspace": this.entferneLetztenWurf(); event.preventDefault(); break;
            case "F1": this.setState({singleActive:true, doubleActive:true, tribleActive: true}); event.preventDefault(); break;
            case "F2": this.setState({singleActive:true, doubleActive:false, tribleActive: false}); event.preventDefault(); break;
            case "F3": this.setState({singleActive:false, doubleActive:true, tribleActive: false}); event.preventDefault(); break;
            case "F4": this.setState({singleActive:false, doubleActive:false, tribleActive: true}); event.preventDefault(); break;
            case "F5": this.addWurf("S0"); event.preventDefault(); break;
            case "F6": this.addShortCutWurf(this.state.zahlen[0]); event.preventDefault(); break;
            case "F7": this.addShortCutWurf(this.state.zahlen[1]); event.preventDefault(); break;
            case "F8": this.addShortCutWurf(this.state.zahlen[2]); event.preventDefault(); break;
            case "F9": this.addShortCutWurf(this.state.zahlen[3]); event.preventDefault(); break;
            case "F10": this.addShortCutWurf(this.state.zahlen[4]); event.preventDefault(); break;
            case "F11": this.addShortCutWurf(this.state.zahlen[5]); event.preventDefault(); break;
            case "F12": this.addShortCutWurf(this.state.zahlen[6]); event.preventDefault(); break;
            default: break;
        }
        event.stopPropagation();
    }


    websocketConnect(gameid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/game/cricket/" + gameid;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
                const destinationGameError = "/topic/public/game/error/" + this.props.playerid;
                console.log("subscribe to " + destinationGameError);
                stompClient.subscribe(destinationGameError, this.onGameError);
                const destinationChat = "/topic/public/chat/" + gameid;
                console.log("subscribe to " + destinationChat);
                stompClient.subscribe(destinationChat, this.onChatMessageReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const json = JSON.parse(payload.body);
        let daten = {...json, saving: false, automatenwerte: undefined, wuerfe: []};
        if( daten.erneutBullen ) {
            daten = {...daten, bullAbgeschickt:false}
        }
        this.setState(daten);
    }

    onChatMessageReceived(payload) {
        const body = JSON.parse(payload.body);
        if( body.messages.length > 0 ) {
            this.setState({chatMessages: body.messages });
        }
    }

    onGameError(payload) {
        this.showError(payload.body);
        this.setState({bullAbgeschickt: false, saving: false});
    }

    showError(message) {
        this.messages.clear();
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    entferneLetztenWurf() {
        let wuerfe = this.state.wuerfe;
        let neueWuerfe = wuerfe.splice(0, wuerfe.length -1);
        this.setState({wuerfe: neueWuerfe});
    }

    score() {
        this.messages.clear();
        if (this.state.saving) {
            console.log("Ich speichere gerade, weiterer Speicherversuch nicht erlaubt.");
            return;
        }

        this.setState({saving: true});

        let url = "/app/score/cricket/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, JSON.stringify({zahlen: this.state.wuerfe}));
    }

    setCookieValue(name, value) {
        const {cookies} = this.props;
        var date = new Date();
        date.setDate(date.getDate() + 365);
        let options = {expires: date, path: '/'};
        cookies.set(name, value, options);
    }

    getModusBeschreibung() {
        let meetingKey = "";
        if (this.state.meetingKey !== null && this.state.meetingKey !== undefined) {
            meetingKey = "Key: " + this.state.meetingKey + " - ";
        }

        let setBeschreibung = "";
        if(this.state.cutthroat) {

        }
        if (this.isSetModus()) {
            setBeschreibung = " - ft sets: " + this.state.sets;
        }
        if (this.state.firstToLegs) {
            return meetingKey + "Cricket - cutthroat " + (this.state.cutthroat ? "ja " : "nein ") + setBeschreibung  + " - 'first to legs':" + this.state.firstToLegs;
        }
        return meetingKey + "Cricket - cutthroat " + (this.state.cutthroat ? "ja " : "nein ") + setBeschreibung + " - 'best of legs':" + this.state.bestOfLegs;
    }

    isSetModus() {
        return this.state.sets && this.state.sets > 1;
    }

    getPlayerScoreDtoByPlayers(players, playerid, opposite) {
        for (const player of players) {
            if (opposite && player.playerid !== playerid) {
                return player;
            }
            if (!opposite && player.playerid === playerid) {
                return player;
            }
        }
        return undefined;
    }

    getQRArea() {
        if( !this.state.showQRCode ) {
            return "";
        }
        let value = ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.SCORING_SHORT.route + "?gameid=" + this.props.gameid + "&playerid=" + this.props.playerid;
        return <QRCodeSidebar link={value} beschreibung={"QR Code für Scoring mit Smartphone"} kritisch={false} onClose={() => this.setState({showQRCode: false})}/>
    }

    getHilfe() {
        return <OverlayPanel ref={(el) => this.op = el} showCloseIcon>
            <h3>Allgemeine Eingabe</h3>
            <div style={{marginBottom:5}}>Deine eingegbenen Zahlen werden neben dem Absenden Knopf angzeigt. D20 steht zum Beispiel für Double 20 und T20 für Trible 20.</div>
            <div>Bei deiner Eingabe kannst du Fehlwürfe einfach weg lassen, dass System gibt für dich dann 0 als geworfen ein.</div>
            <div style={{marginBottom:5}}>Dies gilt ebenfalls für 3 Fehlwürfe, in diesem Fall kannst du einfach Absenden klicken. In diesem Fall werden 3 Fehlerwürfe für dich eingegeben.</div>
            <h3>Löschen von Eingaben</h3>
            <div style={{marginBottom:5}}>Sobald eine Eingabe vorhanden ist, erscheint ein roter Bereich über den du diese Eingaben wieder löschen kannst. Danach gibst du deine korrekten Eingaben einfach neu ein.</div>
            <h3>Info</h3>
            <div style={{marginBottom:5}}>Wenn du z.B. eine 20 bereits geworfen hast und nun T20 wirfst und der Gegner die 20 schon voll hat, wird dein Wurf auf D20 reduziert da du nur noch diese Wurf für die 20 benötigst.</div>
            <h3>Eingabe (Windows-Rechner) mit Shortcuts</h3>
            <div>F1: S/D/T aktiv - F2: S aktiv - F3: D aktiv - F4: T aktiv</div>
            <div>F5: 0 - F6-12: erste Zahl bis letzte Zahl z.B. 15,16,17,18,19,20,25</div>
            <div>Backspace (Rückgängig) Taste, entfernt den letzten Wurf</div>
            <div>Enter schickt deine Würfe ab.</div>
            <div>Beispiele: für Single 15 würdest du F1 und danach F6 klicken / tripe 20 = F4 danach F11 usw.</div>
            <div style={{marginBottom:5, cursor:"pointer"}} onClick={() => this.setState({showShortCuts: !this.state.showShortCuts})}>Klicke hier... du kannst die Shortcuts im Dialog angezeigt lassen bzw. wieder ausblenden.</div>
            <h3>Probleme mit den Shortcuts</h3>
            <div style={{marginBottom:5}}>Sofern die Shortcuts nicht funktionieren hat das Fenster den Fokus verloren. Bitte klicke einfach auf deinen Namen und versuche es erneut.</div>
        </OverlayPanel>
    }

    getPlayerScoreDto(playerid, opposite) {
        return this.getPlayerScoreDtoByPlayers(this.state.players, playerid, opposite);
    }

    getZahlAreaAnzeigeArt2(zahl, anzahlPlayer) {
        let url;
        if (anzahlPlayer === undefined || anzahlPlayer === 0) {
            url = "/images/treffer_0.png";
        } else if (anzahlPlayer === 1) {
            url = "/images/treffer_1.png";
        } else if (anzahlPlayer === 2) {
            url = "/images/treffer_2.png";
        } else {
            url = "/images/treffer_3.png";
        }
        return <div
            className="flex align-items-center justify-content-center h-8rem" style={{marginLeft: 5, marginRight: 5}}>
                {zahl} <img src={url} alt="Treffer" style={{margin: 2, height: 120}}/>
        </div>
    }

    getZahlRow(zahl, anzahlPlayer) {
        let url;
        if (anzahlPlayer === undefined || anzahlPlayer === 0) {
            url = "/images/treffer_0.png";
        } else if (anzahlPlayer === 1) {
            url = "/images/treffer_1.png";
        } else if (anzahlPlayer === 2) {
            url = "/images/treffer_2.png";
        } else {
            url = "/images/treffer_3.png";
        }
        return <div
            className="flex align-items-center justify-content-center h-8rem" style={{marginRight: 20}}>
            <div className="grid" style={{fontSize: 25}}>
                <div
                    className="border-1 h-8rem bg-blue-500 text-white font-bold flex align-items-center justify-content-center"
                    style={{width: 70}}>
                    {zahl}<br/>T:{anzahlPlayer}
                </div>
                <div
                    className="border-1 h-8rem bg-blue-500 text-white font-bold flex align-items-center justify-content-center"
                    style={{width: 180}}>
                    <img src={url} alt="Treffer" style={{margin: 2, height: 100}}/>
                </div>
            </div>
        </div>
    }

    getPlayerAreaAnzeigeart2(currentPlayer) {
        let player = this.getPlayerScoreDto(this.props.playerid, !currentPlayer);
        let setModus = this.isSetModus();
        let label = "";
        if( setModus) {
            label = "Sets: " + player.gewonneneSets + " - Legs: " + player.gewonneneLegs;
        } else {
            label = "Legs: " + player.gewonneneLegs;
        }

        let backgroundColorScore = player.active ? "#77b300" : "#0059b3";

        let isLegBeginner = !this.mussAusgebulltWerden() && this.state.beginnerLegPlayerid === player.playerid;

        return <div style={{padding: 20, backgroundColor: backgroundColorScore, color: "white", marginTop:5}}>
            <div className="grid" style={{fontSize: 20}}>
                {this.erzeugeZahlAreaAnzeigeart2(player)}
                <div
                    className="flex align-items-center justify-content-center h-8rem" style={{marginLeft: 5, marginRight: 5}}>
                    {player.username !== null ? player.username : player.playername} - Punkte: {player.punkte} / {label}
                    {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
                </div>
            </div>
        </div>
    }

    getPlayerArea(currentPlayer) {
        let player = this.getPlayerScoreDto(this.props.playerid, !currentPlayer);
        let setModus = this.isSetModus();
        let label = "";
        if( setModus) {
            label = "Sets: " + player.gewonneneSets + " - Legs: " + player.gewonneneLegs;
        } else {
            label = "Legs: " + player.gewonneneLegs;
        }

        let backgroundColorScore = player.active ? "#77b300" : "#0059b3";

        let isLegBeginner = !this.mussAusgebulltWerden() && this.state.beginnerLegPlayerid === player.playerid;
        return <div style={{padding: 20, backgroundColor: backgroundColorScore}}>
            <div className="grid">
                <div className="col"
                     style={{fontSize: 35, alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                    {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
                    {player.username !== null ? player.username : player.playername}
                    {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
                </div>
            </div>
            <div className="grid">
                <div className="col"
                     style={{fontSize: 25, alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                    Punkte: {player.punkte} / {label}
                </div>
            </div>
        </div>
    }

    erzeugeZahlenButtons() {
        let buttons = [];
        let fTaste = ["F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"];
        buttons.push(this.getButton(0, fTaste[0]));
        let counter = 1;
        for (const zahlTmp of this.state.zahlen) {
            buttons.push(this.getButton(zahlTmp, fTaste[counter]));
            counter++;
        }
        return buttons;
    }

    erzeugeZahlArea(currentPlayer) {
        let player = this.getPlayerScoreDto(this.props.playerid, !currentPlayer);
        let buttons = [];
        for (const zahlTmp of this.state.zahlen) {
            let anzahl = player.wuerfe[zahlTmp];
            buttons.push(this.getZahlRow(zahlTmp, anzahl));
        }
        return buttons;
    }

    erzeugeZahlAreaAnzeigeart2(player) {
        if( this.state.zahlen === null ) {
            return;
        }

        let buttons = [];
        for (const zahlTmp of this.state.zahlen) {
            let anzahl = player.wuerfe[zahlTmp];
            buttons.push(this.getZahlAreaAnzeigeArt2(zahlTmp, anzahl));
        }
        return buttons;
    }

    erzeugeEingabeAnzeigenArea() {
        let columns = [];
        for (const wurf of this.state.wuerfe) {
            columns.push(<div className="col-fixed" style={{width:80, margin:5}}>
                {wurf}
                </div>
            );
        }
        return <div className="grid" style={{color:"black", fontSize:20, marginTop:20, textAlign:"center", backgroundColor:"#afabab", margin:5}}>
            <div className="col-fixed" style={{width:140}}>
                Eingabe:
            </div>
            {columns}
            <div className="col" style={{backgroundColor:"#77b300", color:"white", cursor:"pointer", margin:5}}
                 onClick={() => this.score()}>
                Absenden
            </div>
            {this.state.wuerfe.length === 0 && <div className="col-fixed" style={{width: 50}}></div>}
            {this.state.wuerfe.length > 0 &&
                <div className="col-fixed" style={{
                    backgroundColor: "red",
                    color: "white",
                    cursor: "pointer",
                    width: 50, margin:5
                }} onClick={() => this.entferneLetztenWurf()}>
                    <i className="pi pi-step-backward" style={{'fontSize': '1em', cursor: "pointer"}}/>
                </div>
            }
        </div>
    }

    erzeugeEingabeAnzeigenAreaAnzeigeart2() {
        let labelWuerfe = "";
        for (const wurf of this.state.wuerfe) {
            if( labelWuerfe !== "") {
                labelWuerfe += " | ";
            }
            labelWuerfe += wurf;
        }
        return <div className="grid" style={{color:"black", fontSize:20, marginTop:20, textAlign:"left", margin:5, width:200}}>
            <div className="col" style={{width:200}}>
                <div className="grid" style={{height:40}}>
                    <div className="col">
                        {labelWuerfe}
                    </div>
                </div>
                <div className="grid">
                    <div className="col" style={{backgroundColor:"#77b300", color:"white", textAlign:"center", cursor:"pointer", margin:5}} onClick={() => this.score()}>
                        Absenden
                    </div>
                    {this.state.wuerfe.length === 0 && <div className="col-fixed" style={{width: 50}}></div>}
                    {this.state.wuerfe.length > 0 &&
                        <div className="col-fixed" style={{
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: 40, margin:5
                        }} onClick={() => this.entferneLetztenWurf()}>
                            <i className="pi pi-step-backward" style={{'fontSize': '1em', cursor: "pointer"}}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    }


    existiertEinGewinner() {
        return this.state.gewonnenPlayerid !== null && this.state.gewonnenPlayerid !== undefined;
    }

    existiertUnentschieden() {
        return this.state.unentschieden !== null && this.state.unentschieden !== undefined && this.state.unentschieden;
    }

    mussAusgebulltWerden() {
        let player1idAktiv = this.getPlayerScoreDto(this.props.playerid, false).active;
        let player2idAktiv = this.getPlayerScoreDto(this.props.playerid, true).active;
        return !player1idAktiv && !player2idAktiv;
    }

    sendBullen(bull1, bull2, bull3) {
        this.messages.clear();

        let wertGesamt = bull1 + ";" + bull2 + ";" + bull3;
        if( wertGesamt === null || wertGesamt === undefined) {
            // es gab Calls in das Backend, wo der Wert aus welchem Grund auch immer "null" war. Sicherheitsabfrage.
            this.showError("Fehler beim Bullen, bitte aktualisiere den Browser und gib dein Ergebnis erneut ein.");
            return;
        }
        this.setState({bullAbgeschickt: true});
        let url = "/app/bullen/" + this.state.gameid + "/" + this.props.playerid + "/" + wertGesamt;
        let data = JSON.stringify({wert: wertGesamt});
        stompClient.send(url, {}, data);
    }

    getAreaAnzeigeart1(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden) {
        return <>
            <div className="grid" style={{width: "100%", margin: 0, marginTop: 10, padding: 0}}>
                <div className="col-12 md:col-6 lg:col-6" style={{color: "white"}}>
                    {this.getPlayerArea(true)}
                    {!unentschiedenVorhanden && !gewinnerVorhanden &&
                        <div className="card" style={{padding: 10}}>
                            <div className="flex flex-row flex-wrap card-container blue-container">
                                {this.erzeugeZahlArea(true)}
                            </div>
                        </div>
                    }
                    {playerAktiv && !unentschiedenVorhanden && !gewinnerVorhanden && <>
                        <div className="card" style={{padding: 10, fontSize: 20, color: "black", backgroundColor:"#afabab",}}>
                            {this.erzeugeEingabeAnzeigenArea()}
                            <div className="flex flex-row flex-wrap card-container">
                                {this.erzeugeZahlenButtons()}
                            </div>
                        </div>
                    </>
                    }
                </div>
                <div className="col-12 md:col-6 lg:col-6" style={{color: "white"}}>
                    {this.getPlayerArea(false)}
                    {!unentschiedenVorhanden && !gewinnerVorhanden &&
                        <div className="card" style={{padding: 10}}>
                            <div className="flex flex-row flex-wrap card-container blue-container">
                                {this.erzeugeZahlArea(false)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    }

    getAreaAnzeigeart2(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden) {
        return <>
            {this.getPlayerAreaAnzeigeart2(true)}
            {this.getPlayerAreaAnzeigeart2(false)}
            {playerAktiv && !unentschiedenVorhanden && !gewinnerVorhanden && <div style={{backgroundColor:"#afabab"}}>
                    {this.erzeugeEingabeAnzeigenAreaAnzeigeart2()}
                    {this.erzeugeZahlenButtons()}
            </div>
            }
        </>
    }

    getShortCutArea() {
        let text = "F1: S D T aktiv / F2: S aktiv / F3: D aktiv / F4: T aktiv";
        return <div style={{fontSize:20, backgroundColor: "#1a67b6", top: 0, left:70, position: "fixed", color:"white", zIndex: 700, padding:10}}>
            <Button className="p-button-danger" icon="pi pi-times-circle" onClick={() => this.setState({showShortCuts: false})}
                    style={{height: 30, width: 30, padding: 0, marginRight:10}}/>
            {text}
        </div>;
    }

    starteFolgeGame() {
        this.setState({gameId: this.state.folgeGameId, bullabgeschickt:false});
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
        this.websocketConnect(this.state.folgeGameId);
    }

    sendUndoLegEnd() {
        this.messages.clear();
        let url = "/app/undolegend/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, {});
    }

    changeScore(playerid, scoreid, zahl) {
        let url = "/app/changescore/cricket/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, JSON.stringify({id: scoreid, zahl: zahl}));
    }

    render() {
        if( this.state.timestampAsString === undefined ) {
            return "Lade Game...";
        }
        let currentPlayer = this.getPlayerScoreDto(this.props.playerid, false);
        let playerAktiv = currentPlayer.active;
        let gewinnerVorhanden = this.existiertEinGewinner();
        let unentschiedenVorhanden = this.existiertUnentschieden();
        let spielBeendet = gewinnerVorhanden || unentschiedenVorhanden;

        return <div
            style={{backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <Toast ref={(el) => this.toast = el} style={{zIndex: zIndexMessages}}/>
            <Messages ref={(el) => this.messages = el} style={{position:"fixed", top:60, left:0, width:"100%", paddingLeft:20, paddingRight:20, zIndex: zIndexMessages}} onClick={() => this.messages.clear()}/>
            <div style={{position: "absolute", bottom: 50, left:0}}>
                <Button icon="pi pi-pencil"
                        style={{height: 35, width: 35, margin: 0, padding: 0, marginTop: 10}} onClick={() => this.setState({showChangeDialog:true})} tooltip="Score verändern"/>
            </div>
            { !spielBeendet && this.state.darfPlayerLegZuruecksetzen && <div style={{position: "absolute", top: 0, left:150}}>
                    <Button label="Zurücksetzen" icon="pi pi-trash" onClick={() => this.sendUndoLegEnd()}
                            style={{marginLeft: 10}}/>
                </div>
            }
            {this.state.showShortCuts && this.getShortCutArea()}
            {this.mussAusgebulltWerden() && <AusbullenComponent gameid={this.props.gameid}
                                                                player={this.getPlayerScoreDto(this.props.playerid, false)}
                                                                gegner={this.getPlayerScoreDto(this.props.playerid, true)}
                                                                callBackOnError={(message) => this.showError(message)}
                                                                callBackClearErrorMessages={() => this.messages.clear()}
                                                                callBackSendBullen={(bull1, bull2, bull3) => this.sendBullen(bull1, bull2, bull3)}
                                                                erneutBullen={this.state.erneutBullen}
                                                                bullAbgeschickt={this.state.bullAbgeschickt}/>
            }
            <div className="grid" style={{height: 60, margin: 0, marginBottom:5, padding: 0, width: "100%", backgroundColor: "#0059b3"}}>
                <div className="col-fixed" style={{width:50}}>
                    <i className="pi pi-question-circle" style={{marginLeft:10, 'fontSize': '3em', cursor: "pointer"}}
                       onClick={(e) => this.op.toggle(e)}/>
                </div>
                {this.props.playerid !== "GAST" &&
                    <div className="col-fixed" style={{width: 50}}>
                        <i className="pi pi-qrcode" style={{marginLeft: 10, 'fontSize': '3em', cursor: "pointer"}}
                           onClick={(e) => this.setState({showQRCode: true})}/>
                    </div>
                }
                <div className="col" style={{
                    fontSize: 20,
                    textAlign: "right",
                    color: "white",
                    marginTop: 10
                }}>{this.getModusBeschreibung()}</div>
                <div className="col-fixed" style={{width: 60, marginRight: 20}}>
                    <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                        <Button className="p-button-danger" icon="pi pi-power-off"
                                style={{height: 40, width: 40, marginTop: 5, padding: 0}}/>
                    </NavLink>
                </div>
            </div>
            {this.state.videoAnzeigeart === 1 && this.getAreaAnzeigeart1(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden)}
            {this.state.videoAnzeigeart === 2 && this.getAreaAnzeigeart2(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden)}
            {unentschiedenVorhanden && <div className="grid" style={{height:"100%", width:"100%", fontSize:15, color:"#77b300", fontWeight:"bold", marginTop:20, padding:20}}>
                <div className="col">
                    Das Spiel endet unentschieden!
                </div>
            </div>
            }
            {gewinnerVorhanden && <div className="grid" style={{height:"100%", width:"100%", fontSize:15, color:"#77b300", fontWeight:"bold", marginTop:20, padding:20}}>
                <div className="col">
                    Spieler {this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername} hat gewonnen!
                    {this.state.folgeGameId !== undefined && this.state.folgeGameId !== null && <>
                        <br/>
                        Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                        <br/>
                        <br/>
                        <img src="/images/dart_select.png" alt="Spiel starten" style={{cursor:"pointer", width: 80, marginLeft:30}} onClick={() => this.starteFolgeGame()}/>
                        <br/>
                    </>
                    }
                </div>
            </div>
            }
            {this.getQRArea()}
            {this.getHilfe()}
            <ScoringVideoComponent gameid={this.props.gameid}
                                   displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                   videoAnzeigeart={this.state.videoAnzeigeart}
                                   showVideo={this.state.showVideo}
                                   showVideoMenu={this.state.showVideoMenu}
                                   isMobileDevice={false}
                                   videoHeight={this.state.videoHeight} videoWidth={this.state.videoWidth}
            />

            <ScoringVideoSettingsComponent gameid={this.props.gameid}
                                           displayName={this.getPlayerScoreDto(this.props.playerid, false).username}
                                           videoHeight={this.state.videoHeight}
                                           videoWidth={this.state.videoWidth}
                                           showVideoMenu={this.state.showVideoMenu}
                                           showVideo={this.state.showVideo}
                                           soundGegner={false}
                                           soundIch={false}
                                           supportVideoOption3={false}
                                           supportVideoChangeByOption2={true}
                                           videoAnzeigeart={this.state.videoAnzeigeart}
                                           isMobileDevice={false}
                                           showSoundIch={false}
                                           showSoundGegner={false}
                                           changeSoundIch={(value) => ""}
                                           changeSoundGegner={(value) => ""}
                                           changeShowVideo={(value) => this.setState({showVideo: value})}
                                           changeShowVideoMenu={() => this.setState({showVideoMenu: !this.state.showVideoMenu})}
                                           changeVideoHeight={(value) => {
                                               if (value > 10 && value < 100) {
                                                   this.setState({videoHeight: value});
                                                   this.setCookieValue("darthelfer_scoring_height", value);
                                               }
                                           }}
                                           changeVideoWidth={(value) => {
                                               if (value > 10 && value < 100) {
                                                   this.setState({videoWidth: value});
                                                   this.setCookieValue("darthelfer_scoring_width", value);
                                               }
                                           }}
                                           changeAnzeigeart={(value) => {
                                               this.setState({videoAnzeigeart: value});
                                               this.setCookieValue("darthelfer_scoring_anzeigeart", value);
                                           }}
            />
            <ScoringChatComponent
                showChat={this.props.showChat} onShowChat={(value) => {
                    if( value) {
                        this.removeEventListener();
                    } else {
                        this.addEventListener();
                    }
                    this.props.changeShowChat(value)
            }}
                isMobileDevice={false} gameid={this.props.gameid} chatMessages={this.state.chatMessages}
                userid={"test"} onError={this.showError}/>
            {this.state.showChangeDialog && <ScoringCricketChangeDialog zahlen={this.state.zahlen}
                                                                        scores={this.getPlayerScoreDto(this.props.playerid, false).scores}
                                                                        onSave={(id, zahl) => this.changeScore(this.props.playerid, id, zahl)}
                                                                        callBackOnClose={() => this.setState({showChangeDialog: false})}/>}
        </div>
    }

    addWurf(zahl) {
        let wuerfe = this.state.wuerfe;
        if( wuerfe.length >= 3 ) {
            return;
        }
        wuerfe.push(zahl);
        this.setState({wuerfe: wuerfe});
    }

    getButton(zahl, fTaste) {
        if( zahl === 0 ) {
            return <div
                className="flex align-items-center justify-content-start" style={{width:240, marginLeft: 10, height:50}}>
                <div className="grid">
                    {this.state.showShortCuts &&  <div className="col-fixed" style={{width:40, height:45, backgroundColor:"white",color:"black", textAlign:"center"}}>
                        {fTaste}
                    </div>
                    }
                    <div className="col-fixed" style={{width:180, height:45, backgroundColor:"#D6BA1A", textAlign:"center", cursor:"pointer"}}
                         onClick={() => this.addWurf("S" + zahl)}>
                        {zahl}
                    </div>
                </div>
            </div>
        }
        return <div
            className="flex align-items-center justify-content-start" style={{width:240, marginLeft: 10, height:50}}>
            <div className="grid">
                {this.state.showShortCuts &&  <div className="col-fixed" style={{width:40, height:45, backgroundColor:"white",color:"black", textAlign:"center"}}>
                    {fTaste}
                </div>
                }
                {this.state.singleActive &&  <div className="col-fixed" style={{width:60, height:45, backgroundColor:"#D6BA1A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("S" + zahl)}>
                    {zahl}
                </div>
                }
                {this.state.doubleActive && <div className="col-fixed" style={{width:60, height:45, backgroundColor:"#D6931A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("D" + zahl)}>
                        D{zahl}
                </div>
                }
                {zahl !== 25 && this.state.tribleActive && <div className="col-fixed" style={{width:60, height:45, backgroundColor:"#D6561A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("T" + zahl)}>
                    T{zahl}
                </div>
                }
            </div>
        </div>
    }
}

ScoringCricketView.propTypes = {
    gameid: PropTypes.string.isRequired,
    playerid: PropTypes.string.isRequired,
    showChat: PropTypes.bool.isRequired,
    changeShowChat: PropTypes.func.isRequired,
    cookies: instanceOf(Cookies).isRequired,
};

export default ScoringCricketView;
