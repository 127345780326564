import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop:"20px",
        boxShadow: "0 2px 15px #888888",
    },
    table: {
        minWidth: 350,
    },
});

const backroundColorPunkte = "#E6946C";
const backroundColorSpiele = "#E0AF97";
const backroundColorLegs = "#E6946C";

function getRows(props) {
    let tableRows = [];
    for (let i = 0; i < props.rows.length; i++) {
        let row = props.rows[i];
        let styleRow = i % 2 === 0 ? {backgroundColor: "#666666"} : {backgroundColor: "#404040"};
        let spielername = props.callbackGetSpielername(row.playerid);
        let gesamtspiele = row.gewonnenespiele + row.verlorenespiele + row.unentschiedenspiele;
        let firstThreeArea ={};
        if( i === 3) {
            firstThreeArea = {borderTop:"2px solid red"};
        }
        tableRows.push(<TableRow key={"tabellenzeile_" + row.playerid} style={{...styleRow, color:"white", ...firstThreeArea}}>
            <TableCell style={{width:"50px", color:"white"}}>
                {"" + (i+1) + "."}
            </TableCell>
            <TableCell style={{color:"white"}}>{spielername}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorPunkte}}>{row.punkte}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorSpiele}}>{gesamtspiele}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorSpiele}}>{row.gewonnenespiele}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorSpiele}}>{row.unentschiedenspiele}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorSpiele}}>{row.verlorenespiele}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorLegs}}>{row.anzahlLegsGewonnen - row.anzahlLegsVerloren}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorLegs}}>{row.anzahlLegsGewonnen}</TableCell>
            <TableCell style={{color:"black", textAlign:"center", backgroundColor:backroundColorLegs}}>{row.anzahlLegsVerloren}</TableCell>
        </TableRow>);
    }

    return tableRows;
}

export default function SimpleTable(props) {
    const classes = useStyles();

    let tableRows = getRows(props);

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2} style={{backgroundColor: "white"}}/>
                        <TableCell align="center" style={{backgroundColor: backroundColorPunkte}}>Punkte</TableCell>
                        <TableCell align="center" colSpan={4} style={{backgroundColor: backroundColorSpiele}}>Spiele</TableCell>
                        <TableCell align="center" colSpan={3} style={{backgroundColor: backroundColorLegs}}>{props.isSetModus ? "Sets" : "Legs"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{width:30}}>Rang</TableCell>
                        <TableCell>Spielername</TableCell>
                        <TableCell style={{width:50, backgroundColor: backroundColorPunkte}}></TableCell>
                        <TableCell align="right" style={{width:60, backgroundColor: backroundColorSpiele}}>Gesamt</TableCell>
                        <TableCell align="right" style={{width:30, backgroundColor: backroundColorSpiele}}>S</TableCell>
                        <TableCell align="right" style={{width:30, backgroundColor: backroundColorSpiele}}>U</TableCell>
                        <TableCell align="right" style={{width:30, backgroundColor: backroundColorSpiele}}>V</TableCell>
                        <TableCell align="right" style={{width:50, backgroundColor: backroundColorLegs}}>Diff</TableCell>
                        <TableCell align="right" style={{width:30, backgroundColor: backroundColorLegs}}>G</TableCell>
                        <TableCell align="right" style={{width:30, backgroundColor: backroundColorLegs}}>V</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key="bodykey">
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}
