import {NEW_ONLINEFRIENDS_OVER_WEBSOCKET,} from "../actions/friends";

function friends(state = {
    onlineFriends: [],
}, action) {
    switch (action.type) {
        case NEW_ONLINEFRIENDS_OVER_WEBSOCKET:
            return {...state, onlineFriends: action.value};
        default:
            return state
    }
}

export default friends;