import React from 'react';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {USER_ROLES} from "../../../constants/userRoles";
import {Button} from "primereact/button";

import PlayerUtil from "../../../util/PlayerUtil";


function getRows(props) {
    let tableRows = [];
    if( props.spieler !== undefined) {
        for (let i = 0; i < props.spieler.length; i++) {
            let spieler = props.spieler[i];
            let modolo = i % 2;
            let currentStyle = modolo === 0 ? {} : {backgroundColor: "#f2f2f2"};

            let isAdmin = spieler.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text);

            let spielername = PlayerUtil.anzeigeNameZumSpieler(spieler);

            tableRows.push(<TableRow key={spieler.id} style={currentStyle}>
                <TableCell>
                    {spielername}
                </TableCell>
                <TableCell style={{width:"50px"}}>{spieler.active ? <i className="pi pi-star"/> : ""}</TableCell>
                <TableCell style={{width:"50px"}}>{isAdmin ? <i className="pi pi-star"/> : ""}</TableCell>
                <TableCell style={{width:"50px"}}>
                    <Button icon="pi pi-star"
                            onClick={() => props.changeSpielerAdminRole(spieler.id)}
                            style={{marginRight: "5px"}}
                            tooltip={!isAdmin ? "Zum Admin machen" : "Adminrecht entziehen"}
                            className={isAdmin ? "p-button-danger" : "p-button-success"}/>
                </TableCell>
            </TableRow>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {

    let tableRows = getRows(props);

    return (
        <Paper>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor: "#a6a6a6"}}>
                        <TableCell style={{color: "white", fontSize: "14px"}}>Name</TableCell>
                        <TableCell align="left" style={{color: "white", fontSize: "14px", width:"50px"}}>Aktiv</TableCell>
                        <TableCell align="left" style={{color: "white", fontSize: "14px"}}>Admin</TableCell>
                        <TableCell align="left" style={{color: "white", fontSize: "14px"}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows}
                </TableBody>
            </Table>
        </Paper>
    );
}