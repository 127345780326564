class DateUtil {
    static toDate(date, options) {
        if (options && options.replaceToday && isToday(date)) {
            return "Heute";
        } else if (options && options.replaceYesterday && isYesterday(date)) {
            return "Gestern";
        } else {
            let day = toDoubleDigit(date.getDate().toString());
            let month = toDoubleDigit((date.getMonth() + 1).toString()); // +1 da getMonth 0-11 liefert
            let year = date.getFullYear().toString();
            return day + "." + month + "." + year;
        }
    }

    static toTime(date) {
        let hours = toDoubleDigit(date.getHours().toString());
        let minutes = toDoubleDigit(date.getMinutes().toString());
        return hours + ":" + minutes + " Uhr";
    }

    static localDateTimeToString(localDateTime) {
        if( localDateTime === null || localDateTime === undefined) {
            return "";
        }
        let mydate = Date.parse(localDateTime);
        let newDate = new Date(mydate);
        let formattedDate = newDate.toLocaleTimeString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\./g, '.');
        return formattedDate.substring(0, formattedDate.length-3);
    }

    static localDateTimeToDateString(localDateTime) {
        let mydate = Date.parse(localDateTime);
        let newDate = new Date(mydate);
        let formattedDate = newDate.toLocaleTimeString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\./g, '.');
        return formattedDate.substring(0, formattedDate.length-10);
    }

    static localDateTimeToDateTimeString(localDateTime) {
        let mydate = Date.parse(localDateTime);
        let newDate = new Date(mydate);
        let formattedDate = newDate.toLocaleTimeString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/\./g, '.');
        return formattedDate.substring(0, 17);;
    }

    static toLocalDateTime(date) {
        if (!date) {
            return null;
        }
        const tzOffsetInMillis = date.getTimezoneOffset() * 60000;
        return new Date(date - tzOffsetInMillis).toISOString().slice(0, -1);
    }

    static subtractMinutes(objDate, intMinutes) {
        var numberOfMlSeconds = objDate.getTime();
        var addMlSeconds = (intMinutes) * 60000;
        var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
        return newDateObj;
    }

    static isDateBeforeDateWithMinutes(date1, date2, minutes) {
        let tmpDate1 = this.subtractMinutes(date1, minutes);
        if( date2 < tmpDate1) {
            return true;
        }
        return false;
    }
}

function isToday(date) {
    return isSameDay(new Date(), date);
}

function isYesterday(date) {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDay(yesterday, date);
}

function isSameDay(date1, date2) {
    return date1.toDateString() === date2.toDateString();
}

function toDoubleDigit(s) {
    if (s < 10) {
        return "0" + s;
    }
    return s;
}

export default DateUtil;
