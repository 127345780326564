import {Panel} from "primereact/panel";
import React from "react";

function erzeugeElementFuerEinzelnenWert(id, label, wert) {
    return <div key={id} style={{float:"left", width:170}}><div style={{width:130, float:"left"}}>{label}:</div><div>{wert}</div></div>
}

export function Gesamtstatistik(props) {

    if( props.data === undefined) {
        return "";
    }
    let statistik = props.data;
    let highfinishes = "";
    if( statistik.highfinishes.includes("x")) {
        highfinishes =statistik.highfinishes;
    } else{
        for (const highfinish of statistik.highfinishes) {
            highfinishes = highfinishes + highfinish + ", ";
        }
    }
    let einzelneWerte = [];
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div0", "0 geworfen", statistik.aufnahmen0));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div0-9", "0-9 geworfen", statistik.aufnahmen0Bis9));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div10", "10-19 geworfen", statistik.aufnahmen10Bis19));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div20", "20-29 geworfen", statistik.aufnahmen20Bis29));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div30", "30-39 geworfen", statistik.aufnahmen30Bis39));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div40", "40-49 geworfen", statistik.aufnahmen40Bis49));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div50", "50-59 geworfen", statistik.aufnahmen50Bis59));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div60", "60-69 geworfen", statistik.aufnahmen60Bis69));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div70", "70-79 geworfen", statistik.aufnahmen70Bis79));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div80", "80-89 geworfen", statistik.aufnahmen80Bis89));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div90", "90-99 geworfen", statistik.aufnahmen90Bis99));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div100", "100-109 geworfen", statistik.aufnahmen100Bis109));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div110", "110-119 geworfen", statistik.aufnahmen110Bis119));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div120", "120-129 geworfen", statistik.aufnahmen120Bis129));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div130", "130-139 geworfen", statistik.aufnahmen130Bis139));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div140", "140-149 geworfen", statistik.aufnahmen140Bis149));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div150", "150-159 geworfen", statistik.aufnahmen150Bis159));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div160", "160-169 geworfen", statistik.aufnahmen160Bis169));
    einzelneWerte.push(erzeugeElementFuerEinzelnenWert("div170", "170-180 geworfen", statistik.aufnahmen170Bis180));
    let zeigeSpieleZeile = statistik.gewonneneSpiele > 0 || statistik.verloreneSpiele > 0 || statistik.unentschiedenSpiele > 0;
    return <Panel header={props.ueberschrift} style={{marginBottom:20}}>
        <div className="grid">
            <div className="col-fixed" style={{width:300}}>
                Average: {statistik.average}
            </div>
            <div className="col-fixed" style={{width:300}}>
                First 9 Dart Average: {statistik.first9Average}
            </div>
        </div>
        {zeigeSpieleZeile &&
            <div className="grid">
                <div className="col-fixed" style={{width: 300}}>
                    Gewonnene Spiele: {statistik.gewonneneSpiele}
                </div>
                <div className="col-fixed" style={{width: 300}}>
                    Verlorene Spiele: {statistik.verloreneSpiele}
                </div>
                <div className="col-fixed" style={{width: 300}}>
                    Unentschieden Spiele: {statistik.unentschiedenSpiele}
                </div>
            </div>
        }
        {(props.isSetModus || props.isSetModus === undefined) &&
            <div className="grid">
                <div className="col-fixed" style={{width: 300}}>
                    Gewonnene Sets: {statistik.gewonneneSets}
                </div>
                <div className="col-fixed" style={{width: 300}}>
                    Verlorene Sets: {statistik.verloreneSets}
                </div>
            </div>
        }
        {(!props.isSetModus || props.isSetModus === undefined) &&
            <div className="grid">
                <div className="col-fixed" style={{width:300}}>
                    Gewonnene Legs: {statistik.gewonneneLegs}
                </div>
                <div className="col-fixed" style={{width:300}}>
                    Verlorene Legs: {statistik.verloreneLegs}
                </div>
            </div>
        }
        {statistik.anzahlDoppelwuerfe > 0 &&
            <div className="grid" style={{marginTop:10}}>
                <div className="col">
                    Doppelquote: {statistik.doppelquote}% - {statistik.anzahlDoppelgetroffen}/{statistik.anzahlDoppelwuerfe}
                </div>
            </div>
        }
        {statistik.highscores.includes("x") &&
            <div className="grid">
                <div className="col">
                    Highscores: {statistik.highscores}
                </div>
            </div>
        }
        <div className="grid">
            <div className="col">
                Highfinishes: {highfinishes}
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width:300}}>
                Ton: {statistik.ton}
            </div>
            <div className="col-fixed" style={{width:300}}>
                100+: {statistik._100Plus}
            </div>
            <div className="col-fixed" style={{width:300}}>
                140+: {statistik._140Plus}
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width:300}}>
                171er: {statistik._171er}
            </div>
            <div className="col-fixed" style={{width:300}}>
                180er: {statistik._180er}
            </div>
        </div>
        <div className="grid">
            <div className="col">
                {einzelneWerte}
            </div>
        </div>
    </Panel>
}
