import {connect} from 'react-redux'
import TurnierShortOverview from "../../../components/views/liga/TurnierShortOverview";

const mapStateToProps = function (state, ownProps) {
    return {turnierInfos: state.tournament.turnierInfos};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TurnierShortOverview)