import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {ToggleButton} from "primereact/togglebutton";
import ConfirmDialog from "../../general/ConfirmDialog";
import QRCodeSidebar from "../../general/QRCodeSidebar";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import RundUmsBoardDialog from "../veranstalter/board/RundUmsBoardDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

export default function ExterneLigaSpieltag({id, userId}) {

    const EVENTOPERATOR_ID = "15af673e-aa85-4132-aecb-3553a2bf533b"; // TODO

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const[gameBoardZuweisung, setGameBoardZuweisung] = useState([]);
    const[boards, setBoards] = useState([
        {id: "1", bezeichnung: "Board 1 - Tmp"},
        {id: "2", bezeichnung: "Board 2 - Tmp"}
    ]);
    const[selectedBoard, setSelectedBoard] = useState(undefined);

    const[meetingInfos, setMeetingInfos] = useState(undefined);

    const[showBoardauswahl, setShowBoardauswahl] = useState(false);
    const[showBoardauswahlSave, setShowBoardauswahlSave] = useState(false);
    const[showRefreshDialog, setShowRefreshDialog] = useState(false);
    const[showQRCodeKapitaen, setShowQRCodeKapitaen] = useState(false);
    const[showQRCodeAllgemein, setShowQRCodeAllgemein] = useState(false);
    const[showGasteingabeDurchHeim, setShowGasteingabeDurchHeim] = useState(false);
    const[showRundUmsBoardDialog, setShowRundUmsBoardDialog] = useState(false);

    useEffect(() => {
        ladeMeeting();
    }, [id]);

    const ladeMeeting = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.externeliga + "/meeting/" + id,
            json => {
                console.log(json.homeClubName);
                setMeetingInfos(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            })
    }

    const addBoard = (matchNr) => {
        let tmp = gameBoardZuweisung.filter(game => game.matchNr !== matchNr);
        tmp.push({matchNr: matchNr, boardId: selectedBoard.id});
        setGameBoardZuweisung([...tmp]);
    }

    const deleteBoard = (matchNr) => {
        let tmp = gameBoardZuweisung.filter(game => game.matchNr !== matchNr);
        setGameBoardZuweisung([...tmp]);
    }

    const getBoardZumMatch = (matchNr) => {
        let matchArray = gameBoardZuweisung.filter(game => game.matchNr === matchNr);
        if( matchArray.length === 1 ) {
            let tmp = matchArray[0];
            let boardArray = boards.filter(board => board.id === tmp.boardId);
            if( boardArray.length === 1) {
                return boardArray[0];
            }
        }
        return undefined;
    }

    const getEinzelRow = (matchNr, matchType) => {
        let board = getBoardZumMatch(matchNr);
        return <div style={{marginTop:5}}>
            <div>Spiel: {matchNr}</div>
            {board !== undefined && <div style={{display:"flex", textAlign:"center", justifyContent:"center", verticalAlign:"middle", alignItems:"center",
                        backgroundColor:"red", color:"white", width:250, height:30,
                        border: "2px solid white",
                        borderTopLeftRadius: 10,borderTopRightRadius: 10,}}>
                    {board.bezeichnung}
                </div>
            }
            <div>
                    <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                              onChange={(e) => ""} placeholder="Eingabe..."
                              style={{width:250}}/>
                    <span style={{marginLeft:5, marginRight:5}}>vs.</span>
                    <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                              onChange={(e) => ""} placeholder="Eingabe..."
                              style={{width:250}}/>
            </div>
            {selectedBoard !== undefined && <div>
                <div style={{textAlign:"left", marginTop:10}}>
                    <img src="/images/dart_select.png" alt="Board zuweisen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => addBoard(matchNr)}/>
                    <img src="/images/delete_color.png" alt="Board entfernen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => deleteBoard(matchNr)}/>
                </div>
                <hr/>
            </div>
            }
        </div>
    }

    const getDoppelRow = (matchNr) => {
        let board = getBoardZumMatch(matchNr);
        return <div style={{marginTop:5}}>
            <div>Spiel: {matchNr}</div>
            {board !== undefined && <div style={{display:"flex", textAlign:"center", justifyContent:"center", verticalAlign:"middle", alignItems:"center",
                backgroundColor:"red", color:"white", width:250, height:30,
                border: "2px solid white",
                borderTopLeftRadius: 10,borderTopRightRadius: 10,}}>
                {board.bezeichnung}
            </div>
            }
            <div>
                <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                          onChange={(e) => ""} placeholder="Eingabe..."
                          style={{width:200}}/>
                <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                          onChange={(e) => ""} placeholder="Eingabe..."
                          style={{width:200}}/>
                <span style={{marginLeft:5, marginRight:5}}>vs.</span>
                <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                          onChange={(e) => ""} placeholder="Eingabe..."
                          style={{width:200}}/>
                <Dropdown value={""} options={[{id: "1", name : "Heiko Förster"}]} optionLabel="name"
                          onChange={(e) => ""} placeholder="Eingabe..."
                          style={{width:200}}/>
            </div>
            {selectedBoard !== undefined && <div>
                <div style={{textAlign:"left", marginTop:10}}>
                    <img src="/images/dart_select.png" alt="Board zuweisen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => addBoard(matchNr)}/>
                    <img src="/images/delete_color.png" alt="Board entfernen" style={{cursor: "pointer", width: 50, margin: 5}} onClick={() => deleteBoard(matchNr)}/>
                </div>
                <hr/>
            </div>
            }
        </div>
    }

    const getBoardDropdownArea = () => {
        return <div key={"key_dropdown_ko"} style={{marginTop:10, textAlign:"left"}}>
            <div style={{marginBottom:5}}>Boardauswahl</div>
            <div>
                <Dropdown value={selectedBoard} options={boards} optionLabel="bezeichnung"
                          onChange={(e) => {
                              setSelectedBoard(e.value);
                          }} emptyMessage={"Keine Boards vorhanden"}
                          placeholder="Eingabe..." style={{width:200}}/>
            </div>
            <div style={{marginTop:5}}>Hinweis: Wähle ein Board aus und klicke bei den Spielen die auf diesem Board gespielt werden die Dartscheibe an.</div>
        </div>
    }

    const getHeaderRow = (bezeichnung) => {
        return <div style={{textAlign:"left", marginBottom:10, fontWeight:"bold"}}>{bezeichnung}</div>
    }

    if( meetingInfos === undefined) {
        return <>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            <div>Wir laden die Daten...</div>
        </>
    }

    return <div style={{padding:10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{fontWeight:"bold", marginBottom:5}}>
            Heimmannschaft
        </div>
        <div>
            Verein: {meetingInfos.homeClubName}
        </div>
        <div>
            Team: {meetingInfos.homeClubTeamName}
        </div>
        <div style={{fontWeight:"bold", marginTop:20, marginBottom:5}}>
            Gastmannschaft
        </div>
        <div>
            Verein: {meetingInfos.guestClubName}
        </div>
        <div>
            Team: {meetingInfos.guestClubTeamName}
        </div>
        <div style={{marginTop:30}}>
            <Button label="Ligaspieltag aktualisieren" style={{marginTop:5, marginRight:5}} onClick={() => setShowRefreshDialog(true)} />
            <Button label="Boardauswahl speichern" style={{marginTop:5, marginRight:5}} onClick={() => setShowBoardauswahlSave(true)} />
            <ToggleButton style={{marginTop:5, marginRight:5, width: 200}} checked={showBoardauswahl} offLabel="Boardauswahl anzeigen" onLabel="Boardauswahl ausblenden"
                          onChange={(e) => {
                              setSelectedBoard(undefined);
                              setShowBoardauswahl(!showBoardauswahl);
                          }} />
            <Button label="QR Code - Gastkapitän" style={{marginTop:5, marginRight:5}} onClick={() => setShowQRCodeKapitaen(true)} />
            <Button label="QR Code - allgemein" style={{marginTop:5, marginRight:5}} onClick={() => setShowQRCodeAllgemein(true)} />
            <Button label="Gasteingabe durch Heim" style={{marginTop:5, marginRight:5}} onClick={() => setShowGasteingabeDurchHeim(true)} />
            <Button label="Boards verwalten" style={{marginTop:5, marginRight:5}} onClick={() => setShowRundUmsBoardDialog(true)}/>

        </div>
        {showBoardauswahl &&
            <div style={{marginTop:30}}>
                {getBoardDropdownArea()}
            </div>
        }
        <div style={{marginTop:30}}>
            {getHeaderRow("Phase 1: Einzelspiel(e)")}
            {getEinzelRow(1, "SINGLE")}
            {getEinzelRow(2, "SINGLE")}
            {getEinzelRow(3, "SINGLE")}
            {getEinzelRow(4, "SINGLE")}
        </div>
        <div style={{marginTop:30}}>
            {getHeaderRow("Phase 2: Doppelspiel(e)")}
            {getDoppelRow(5, "DOUBLE")}
            {getDoppelRow(6, "DOUBLE")}
        </div>
        <div style={{marginTop:30}}>
            {getHeaderRow("Phase 3: Einzelspiel(e)")}
            {getEinzelRow(7, "SINGLE")}
            {getEinzelRow(8, "SINGLE")}
            {getEinzelRow(9, "SINGLE")}
            {getEinzelRow(10, "SINGLE")}
        </div>
        <div style={{marginTop:30}}>
            {getHeaderRow("Phase 4: Doppelspiel(e)")}
            {getDoppelRow(11, "DOUBLE")}
            {getDoppelRow(12, "DOUBLE")}
        </div>

        {showBoardauswahlSave && <ConfirmDialog visible={true} header="Boardauswahl speichern"
                                                textOben="Sobald du die aktuelle Boardauswahl speicherst, wird diese zu deinem Team abgespeichert.
                                                Bei weiteren Ligaspielen werden diese Boardeinstellungen als Vorbelegung genutzt."
                                                textUnten="Boardauswahl wirdklich speichern und evtl. bereits gespeicherte Einstellungen überschreiben?"
                                                callBackOnNein={() => setShowBoardauswahlSave(false)}
                                                callBackOnJa={() => ""}/>}
        {showRefreshDialog && <ConfirmDialog visible={true} header="Ligaspieltag aktualisieren"
                                                textOben="Sofern du den Ligaspieltag aktualisierst, werden die Daten vom Drittsystem neu geholt. Grund dafür könnte sein, dass nach dem Laden der Daten vom Drittsystem noch weitere Spieler im System eingepflegt wurden o.ä. Bitte aktualisiere die Daten nur, wenn du wirklich Spieler vermisst oder sonstige Probleme mit den angezeigten Daten vorliegen."
                                                textUnten="Möchtest du den Ligaspieltag wirklich neu vom Drittstem holen und damit die Daten in Darthelfer aktualisieren?"
                                                callBackOnNein={() => setShowRefreshDialog(false)}
                                                callBackOnJa={() => ""}/>}
        {showQRCodeKapitaen && <QRCodeSidebar onClose={() => setShowQRCodeKapitaen(false)}
                                              kritisch={true}
                                              beschreibung="Dieser QR Code ist auschliesslich für den Kapitän der Gastmannschaft, damit kann er seine Aufstellung eintragen. Bitte diesen QR Code aus Sicherheitsgründen ausschließlich dem Kapitän zeigen!"
                                              link="https://www.darthelfer.de/#/gehtnochnicht"/>}
        {showQRCodeAllgemein && <QRCodeSidebar onClose={() => setShowQRCodeAllgemein(false)}
                                               kritisch={false}
                                               beschreibung="Dieser QR Code ist für die Öffentlichkeit und kann ohne Probleme weitergegeben werden. Damit kann der Spieltag im öffentlichen Bereich beobachtet werden."
                                               link="https://www.darthelfer.de/#/oeffentlicherbereich"/>}
        {showGasteingabeDurchHeim && <ConfirmWithQuestionDialog visible={true} header="Gasteingabe durch Heimmannschaft"
                                             textOben="Das Konzept von Darthelfer sieht vor, dass die Gastmannschaft über einen QR Code auf eine Seite von Darthelfer geht und die Mannschaftsaufstellung selbst vornimmt. Verweigert die Gastmannschaft diese selbstständige digitale Eingabe oder ist technisch evtl. nicht in der Lage dazu, kann die Eingabe für die Gastmannschaft durch die Heimmannschaft durchgeführt werden. Sofern du die Eingabe der Gastmannschaft über die Heimmanschaft freischaltest, kann diese Aktion nicht mehr rückgängig gemacht werden."
                                             textUnten="Möchtest oder musst du die Aufstellung für die Gastmannschaft eingeben? Wurdest du von der Gastmannschaft dazu aufgefordert oder ist diese zumindest damit einverstanden?"
                                             callBackOnNein={() => setShowGasteingabeDurchHeim(false)}
                                             callBackOnJa={() => ""}/>}
        {showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={EVENTOPERATOR_ID} onClose={() => setShowRundUmsBoardDialog(false)}/>}
    </div>
}
