import React from "react";

class PlayerUtil {
    static anzeigeNameZumSpieler(spieler) {
        let vorname = spieler.firstname !== null && spieler.firstname !== undefined ? spieler.firstname + " " : "";
        if( spieler.nickname !== null && spieler.nickname !== undefined && spieler.nickname !== "") {
            return spieler.nickname + " - " + vorname + spieler.name;
        } else {
            return vorname + spieler.name;
        }
    }

    static getSpielerName(players, id) {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < players.length; i++) {
            let spieler = players[i];
            if (spieler.id === id || spieler.userId === id) {
                let spielername = spieler.name;
                if( spieler.inaktiv) {
                    return <s style={{marginRight:5}}>{spielername}</s>;
                } else {
                    return spielername;
                }
            }
        }
        return "FEHLER";
    }

    static isSpielerInaktiv(players, id) {
        if( id === null || id === undefined) {
            return false;
        }
        for (let i = 0; i < players.length; i++) {
            let spieler = players[i];
            if (spieler.id === id || spieler.userId === id) {
                return spieler.inaktiv;
            }
        }
        return false;
    }
}

export default PlayerUtil;