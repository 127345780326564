import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Sidebar} from "primereact/sidebar";
import {PrimeIcons} from "primereact/api";

export default function BoardAuswahlDialog({eventOperatorId, favorits, onSelection, onDelete, onClose}) {

    const [boards, setBoards] = useState([]);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        loadBoards();
    }, []);


    const loadBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/" + eventOperatorId,
            json => {
                setBoards(json.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            });
    }

    const isFavorit = (boardId) => {
        if( favorits === undefined) {
            return false;
        }
        return favorits.filter(fav => fav.boardId === boardId).length > 0;
    }

    const getBoardCols = () => {
        if (boards.length === 0) {
            return <div style={{marginTop: 30}}>Keine Boards vorhanden.</div>;
        }
        let rows = [];
        for (const board of boards) {
            rows.push(<div key={"key_board_" + board.id}  style={{width: 320, maxWidth:360, padding:5, border: "1px solid #DDD", margin:5}}>
                Bezeichnung: '{board.bezeichnung}'
                <div>
                    {isFavorit(board.id) &&
                    <Button icon="pi pi-trash" tooltip="Entfernen"
                            style={{marginRight: "5px", marginTop: "5px"}}
                            disabled={false}
                            onClick={() => {
                                onDelete(board.id, board.bezeichnung);
                            }}/>
                    }
                    {!isFavorit(board.id) &&
                        <Button icon={PrimeIcons.CHECK} label="Übernehmen"
                            style={{marginRight: "5px", marginTop: "5px", width:200}}
                            onClick={() => {
                                onSelection(board.id, board.bezeichnung);
                            }}/>
                    }
                </div>
        </div>);
        }
        return rows;
    }


    return <Sidebar visible={true} fullScreen onHide={() => onClose()}>
        <div style={{marginBottom: 20}}><Button label="Bereich schliessen" onClick={() => onClose()}/></div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage}
                            onClearInfoMessage={() => setInfoMessage(undefined)}
                            onClearErrorMessage={() => setErrorMessage(undefined)}/>
        <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
            {getBoardCols()}
        </div>
    </Sidebar>
}
