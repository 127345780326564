import React from "react";


export default function StatistikComponent({name, showPlayerGesamtStatistik, statistik}) {

    const header = (bezeichnung) => {
        return <div style={{padding:10, margionTop:20, width:"100%", borderBottom:"2px solid #ac1c27", textAlign:"center", color:"#ac1c27", fontSize:25, marginBottom:20, fontWeight:"bold"}}>{bezeichnung}</div>
    }

    function StatistikRow({bezeichnung, value}) {
        return <div className="grid" style={{fontSize:16}}>
            <div className="col" style={{textAlign:"right"}}>{bezeichnung}</div>
            <div className="col" style={{textAlign:"left"}}>{value !== null && value !== undefined ? value : "-"}</div>
        </div>
    }

    const getMap = (values) => {
        if( values === null || values === undefined) {
            return new Map();
        }
        const map = new Map();

        let sortedValues = values.sort();
        for (const tmp of sortedValues) {
            let count = 0;
            if( map.get(tmp) !== undefined) {
                count = map.get(tmp);
            }
            count++;
            map.set(tmp, count);
        }
        return map;
    }

    const getHighScores = () => {
        if( !statistik.highscores || statistik.highscores === "") {
            return "";
        }
        if( statistik.highscores.includes("x")) {
            return statistik.highscores;
        }
        let map = getMap(statistik.highscores);
        let scores = "";
        for (let [key, value] of map) {
            scores += value + "x" + key + ", ";
        }
        return scores;
    }


    const getHighFinishes = () => {
        if( !statistik.highfinishes || statistik.highfinishes === "") {
            return "";
        }
        if( statistik.highfinishes.includes("x")) {
            return statistik.highfinishes;
        }
        let map = getMap(statistik.highfinishes);
        let scores = "";
        for (let [key, value] of map) {
            scores += value + "x" + key + ", ";
        }
        return scores;
    }

    return <div>
        <div style={{padding:10, marginTop:20, marginBottom:20, width:"100%", backgroundColor:"#ac1c27", textAlign:"center", color:"white", fontSize:25}}>{name}</div>
        <StatistikRow bezeichnung={"Average"} value={statistik.average}/>
        <StatistikRow bezeichnung={"first 9 Average"} value={statistik.first9Average}/>
        <StatistikRow bezeichnung={"Würfe im Turnier"} value={statistik.anzahlWuerfeGesamt}/>
        <StatistikRow bezeichnung={"Geworfene Punkte"} value={statistik.anzahlGeworfenerPunkteGesamt}/>
        {statistik.highfinishes !== null && statistik.highfinishes !== undefined &&
            <StatistikRow bezeichnung={"Highfinishes"} value={getHighFinishes()}/>
        }
        {statistik.highscores !== null && statistik.highscores !== undefined &&
            <StatistikRow bezeichnung={"Highscores"} value={getHighScores()}/>
        }
        {statistik.anzahlDoppelwuerfe !== null && statistik.anzahlDoppelwuerfe !== undefined && statistik.anzahlDoppelwuerfe > 0 && <>
                <StatistikRow bezeichnung="Doppelquote" value={statistik.doppelquote + "%"}/>
                <StatistikRow bezeichnung="Doppel" value={statistik.anzahlDoppelgetroffen + "/" + statistik.anzahlDoppelwuerfe}/>
            </>
        }
        <div className="grid">
            {showPlayerGesamtStatistik &&
                <div className="col-12 md:col-12 lg:col-4">
                    {header("Spiele")}
                    <StatistikRow bezeichnung={"Gewonnen"} value={statistik.gewonneneSpiele}/>
                    <StatistikRow bezeichnung={"Verloren"} value={statistik.verloreneSpiele}/>
                    <StatistikRow bezeichnung={"Unentschieden"} value={statistik.unentschiedenSpiele}/>
                </div>
            }
            <div className={showPlayerGesamtStatistik ? "col-12 md:col-12 lg:col-4": "col-12 md:col-12 lg:col-6"}>
                {header("Highlights")}
                <StatistikRow bezeichnung={"Best Leg"} value={statistik.bestLeg}/>
                <StatistikRow bezeichnung={"Worst Leg"} value={statistik.worstLeg}/>
                <StatistikRow bezeichnung={"100+"} value={statistik._100Plus}/>
                <StatistikRow bezeichnung={"140+"} value={statistik._140Plus}/>
                <StatistikRow bezeichnung={"171"} value={statistik._171er}/>
                <StatistikRow bezeichnung={"180"} value={statistik._180er}/>
            </div>
            <div className={showPlayerGesamtStatistik ? "col-12 md:col-12 lg:col-4" : "col-12 md:col-12 lg:col-6"}>
                {header("Geworfen")}
                <StatistikRow bezeichnung={"0"} value={statistik.aufnahmen0}/>
                <StatistikRow bezeichnung={"0-9"} value={statistik.aufnahmen0Bis9}/>
                <StatistikRow bezeichnung={"10-19"} value={statistik.aufnahmen10Bis19}/>
                <StatistikRow bezeichnung={"20-29"} value={statistik.aufnahmen20Bis29}/>
                <StatistikRow bezeichnung={"30-39"} value={statistik.aufnahmen30Bis39}/>
                <StatistikRow bezeichnung={"40-49"} value={statistik.aufnahmen40Bis49}/>
                <StatistikRow bezeichnung={"50-59"} value={statistik.aufnahmen50Bis59}/>
                <StatistikRow bezeichnung={"60-69"} value={statistik.aufnahmen60Bis69}/>
                <StatistikRow bezeichnung={"70-79"} value={statistik.aufnahmen70Bis79}/>
                <StatistikRow bezeichnung={"80-89"} value={statistik.aufnahmen80Bis89}/>
                <StatistikRow bezeichnung={"90-99"} value={statistik.aufnahmen90Bis99}/>
                <StatistikRow bezeichnung={"100-109"} value={statistik.aufnahmen100Bis109}/>
                <StatistikRow bezeichnung={"110-119"} value={statistik.aufnahmen110Bis119}/>
                <StatistikRow bezeichnung={"120-129"} value={statistik.aufnahmen120Bis129}/>
                <StatistikRow bezeichnung={"130-139"} value={statistik.aufnahmen130Bis139}/>
                <StatistikRow bezeichnung={"140-149"} value={statistik.aufnahmen140Bis149}/>
                <StatistikRow bezeichnung={"150-159"} value={statistik.aufnahmen150Bis159}/>
                <StatistikRow bezeichnung={"160-169"} value={statistik.aufnahmen160Bis169}/>
                <StatistikRow bezeichnung={"170-180"} value={statistik.aufnahmen170Bis180}/>
            </div>
        </div>
    </div>
}
