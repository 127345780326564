import {connect} from 'react-redux'
import UserStatstik from "../../../components/views/liga/UserStatistik";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserStatstik)