import {connect} from 'react-redux'
import withRouter from "../../../components/RouterComponentWrapper";
import SpieltagDetail from "../../../components/views/spieltag/SpieltagDetail";

const mapStateToProps = function (state, ownProps) {
    const urlParams = new URLSearchParams(ownProps.router.location.search);
    const spieltagId = urlParams.get('spieltagid');
    return {userRoles: state.user.userRoles, spieltagid: spieltagId};
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SpieltagDetail))