import {connect} from 'react-redux'
import ChoosLeagueoperatorDialog from "../../../components/views/liga/ChoosLeagueoperatorDialog";

const mapStateToProps = (state, ownProps) => ({
    leagueoperators: state.leagueoperator.leagueoperators,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChoosLeagueoperatorDialog)