import React, {useEffect, useState} from "react";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";

export default function RanglisteComponent({ranglisteId, eventOperatorId, onChange, onError}) {

    let [ranglisten, setRanglisten] = useState([]);

    useEffect(() => {
        ladeRanglisten();
    }, []);

    const ladeRanglisten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglisten/" + eventOperatorId,
            json => {
                let newRanglisten = [{code: undefined, name: "Auswahl löschen"}];
                for (const jsonElement of json) {
                    newRanglisten.push({code: jsonElement.id, name: jsonElement.bezeichnung});
                }
                setRanglisten(newRanglisten);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message)
            },
            error => {
                onError("Fehler: " + error.message)
        });
    }

    return <DHSelectionComponent value={ranglisteId} onChange={onChange} values={ranglisten} title="Ranglisten"/>
}
