import React from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import CsvDownloader from "react-csv-downloader";

export default function ExportPlatzierungenButton({platzierungen}) {

    const getColumns = () => {
        let cols = [
            {
                id: 'cell1',
                displayName: 'Platz',
            },
            {
                id: 'cell2',
                displayName: 'SpielerId',
            },
            {
                id: 'cell3',
                displayName: 'Name',
            },
        ];
        return cols;
    }

    const replaceChars = (value) => {
        if( value === null || value === undefined) {
            return "";
        }
        return ""+ value.replace(";", ",");
    }

    const getData = () => {
        let rows = [];
        for (const platzierung of platzierungen) {
            let name = replaceChars(platzierung.name);
            rows.push({cell1: platzierung.platz, cell2: platzierung.idShort, cell3: name});
        }
        return rows;
    }

    return <CsvDownloader filename="platzierung.csv" datas={getData()} columns={getColumns()} separator=";">
        <Button hr icon={PrimeIcons.DOWNLOAD} label="Platzierungen exportieren (csv)"/>
    </CsvDownloader>
}
