import {connect} from 'react-redux'
import BenutzersucheView from "../../../components/views/liga/BenutzersucheView";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenutzersucheView)