import React, {useEffect, useState} from "react";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";

export default function RanglisteProfilesComponent({ranglisteProfileId, eventOperatorId, onChange, onError}) {

    let [selectedProfile, setSelectedProfile] = useState(undefined);
    let [profiles, setProfiles] = useState([]);

    useEffect(() => {
        ladeProfile();
    }, []);

    const ladeProfile = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.ranglisteNG + "/ranglistenprofiles/" + eventOperatorId,
            json => {
                let newProfiles = [{code: undefined, name: "Auswahl löschen"}]
                for (const jsonElement of json) {
                    newProfiles.push({code: jsonElement.id, name: jsonElement.bezeichnung});
                }
                setProfiles(newProfiles);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message)
            },
            error => {
                onError("Fehler: " + error.message)
        });
    }

    return <DHSelectionComponent value={ranglisteProfileId} onChange={onChange} values={profiles} title="Ranglistenprofile"/>
}
