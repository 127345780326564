import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import PropTypes from "prop-types";
import UserSearch from "./UserSearch";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Card} from "primereact/card";
import Chat from "../../general/Chat";
import DHDialog from "../../general/DHDialog";

class HeadToHeadChat extends React.Component {

    constructor(props) {
        super(props);
        let username = props.username !== null && props.username !== undefined ? props.username : "";
        this.state = {chats: [], currentHeadToHeadChatId: undefined, username: username};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.startChat = this.startChat.bind(this);
        this.setChatAktiv = this.setChatAktiv.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }


    componentDidMount() {
        this.ladeDaten();
        this.mytimer = setInterval(() => this.ladeDaten(), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.mytimer);
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    showWarn(message) {
        this.messages.show({
            severity: 'info',
            sticky: 'true',
            summary: '',
            detail: message
        });
    }

    ladeDaten() {
        let url = ConfigUtil.getConfig().resourceUrls.chat + "/chats";
        FetchUtil.fetchGet(url,
            json => {this.setState({chats: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden: " + error.message);
            });
    }

    startChat() {
        this.messages.clear();
        let data = {userId: this.state.user.id};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.chat + "/headtoheadchatanlegen",
            data,
            json => {
                this.setState({currentHeadToHeadChatId: json.id, chats: json.chatHeadToHeadResultDtos})
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    setChatAktiv(currentHeadToHeadChatId) {
        this.messages.clear();
        this.setState({currentHeadToHeadChatId:currentHeadToHeadChatId});
        let url = ConfigUtil.getConfig().resourceUrls.chat + "/chatmessagesgelesen/" + currentHeadToHeadChatId;
        FetchUtil.fetchPost(url,
                {},
                json => {this.setState({chats: json});},
                responseNotOk => {
                    this.showError("Fehler: " + responseNotOk.message);
                },
                error => {
                    this.showError("Fehler: " + error.message);
                }
        );
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#ffbb99"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Chat
                </div>
            </div>
            {this.chatMitUserStartenArea()}
            <div style={{textAlign:"left", padding:20}}>Tipp: Chats mit folgendem Symbol <img src="/images/unread.png" alt="Ungelesene Chats" style={{height:20, marginLeft:10, marginRight:10}}/> wurden von deinem Chatpartner noch nicht gelesen.</div>
            {this.getChats("Deine ungelesene Chats:", false)}
            {this.getChats("Deine bereits gelesenen Chats:", true)}
            {this.state.currentHeadToHeadChatId !== undefined &&
                <DHDialog onClose={() => this.setState({currentHeadToHeadChatId:undefined})}
                          title={this.getDialogHeader()} show={true} buttons={[]}
                          comp={<>
                              <Chat type="headtohead" loadData={true} id={this.state.currentHeadToHeadChatId} userId={this.props.userId}
                                    callBackOnError={(message) => this.showError(message)}/>
                          </>}/>
            }
        </div>
    }

    getChats(ueberschrift, nurGelesene) {
        let chats = [];
        for (const headtoheadchat of this.state.chats) {
            if ((!nurGelesene && !headtoheadchat.gelesen) || (nurGelesene && headtoheadchat.gelesen)) {
                chats.push(<div key={"key_headtoheadchat_" + headtoheadchat.id} style={{padding: 10, cursor:"pointer"}}
                                onClick={() => this.setChatAktiv(headtoheadchat.id)}>
                    <Card style={{height:60}}>{!headtoheadchat.chatPartnerGelesen && <img src="/images/unread.png" alt="Ungelesene Chats" style={{height:20, marginRight:10}}/>}{headtoheadchat.username}</Card></div>)
            }
        }
        return <div style={{textAlign: "left", padding: 20}}>
            <h4>{ueberschrift}</h4>
            <div className="grid" style={{textAlign: "left"}}>
                {chats}
            </div>
        </div>
    }

    getDialogHeader() {
        for (const chat of this.state.chats) {
            if( chat.id === this.state.currentHeadToHeadChatId) {
                return "Chat mit " + chat.username;
            }
        }
        return "";
    }

    chatMitUserStartenArea() {
        return <div className="grid" style={{padding: 20, margin: 0, textAlign: "left"}}>
            <Card style={{width: "100%"}}>
                <div className="grid">
                    <div className="col-fixed" style={{width: 100}}>
                        Username:
                    </div>
                    <div className="col-fixed" style={{width: 400}}>
                        <UserSearch username={this.state.username} callBackError={(message) => {
                            this.showError(message);
                            this.setState({user: undefined})
                        }}
                                    callBackClearMessages={() => this.messages.clear()}
                                    callBackInfo={this.showWarn}
                                    callBackUserFound={(user) => this.setState({user: user})}/>
                    </div>
                    <div className="col">
                        <Button label="Chat starten"
                                disabled={this.state.user === undefined}
                                type="button" onClick={() => this.startChat()}/>
                    </div>
                </div>
            </Card>
        </div>

    }
}

HeadToHeadChat.propTypes = {
    userId: PropTypes.string.isRequired,
    username: PropTypes.string,
};

export default HeadToHeadChat