import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";

function TeilnahmebedingungenDialog({visible, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [beschreibung, setBeschreibung] = useState("");
    let [link, setLink] = useState("");
    let [teilnahmebedingungen, setTeilnahmebedingungen] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmebedingungen",
            json => {
                setTeilnahmebedingungen(json);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            });
    }

    const footer = (
        <div>
            <Button label="Schliessen" icon="pi pi-times" onClick={() => onClose()} className="p-button-secondary"/>
        </div>
    );

    const loeschen = (id) => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmebedingung/" + id,
            {},
            () => {
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }

    const save = () => {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmebedingung/save",
            ({beschreibung: beschreibung, link: link}),
            () => {ladeDaten()},
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            })
    }


    const rows = () => {
        let rowArray = [];
        for (const tmp of teilnahmebedingungen) {
            rowArray.push(<div key={"key_teilnahmebedingungen_" + tmp.id} style={{marginBottom: 20}}>
                <hr/>
                <div><Button value={"Löschen"} icon="pi pi-trash"
                             style={{marginRight: "5px", marginTop: "5px"}}
                             tooltip="Löschen "
                             onClick={() => loeschen(tmp.id)}/>
                </div>
                <div>{tmp.beschreibung}</div>
                {tmp.link !== null && tmp.link !== undefined && tmp.link !== "" &&
                    <div><a href={tmp.link} target="_blank" rel="noopener noreferrer">Link zum Dokument...</a></div>
                }
            </div>)
        }
        return rowArray;
    }

    return <DHDialog onClose={() => onClose()} title="Teilnahmebedingungen" show={visible} buttons={footer}
                     comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginTop:10}}>
            <span className="p-float-label">
                <InputText id="beschreibung" value={beschreibung} onChange={(e) => setBeschreibung(e.target.value)} style={{width:"100%"}}/>
                <label htmlFor="beschreibung">Beschreibung</label>
            </span>
        </div>
        <div style={{marginTop:20}}>
            <span className="p-float-label">
                <InputText id="link" value={link} onChange={(e) => setLink(e.target.value)}  style={{width:"100%"}}/>
                <label htmlFor="link">Link mit http am Anfang</label>
            </span>
        </div>
        <div style={{marginTop:20}}>
            <Button label="Speichern" icon="pi pi-save" onClick={() => save()} disabled={beschreibung === ""}/>
        </div>
        {rows()}
    </>}/>
}

TeilnahmebedingungenDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TeilnahmebedingungenDialog;
