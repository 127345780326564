import {connect} from 'react-redux'
import {logout} from "../../../actions/user";
import Aktionen from "../../../components/views/liga/Aktionen";

const mapStateToProps = (state, ownProps) => ({
    userRoles: state.user.userRoles,
    userId: state.user.id,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch(logout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Aktionen)