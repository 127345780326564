import React from 'react'
import {Messages} from 'primereact/messages';

import {Toast} from "primereact/toast";
import {Panel} from 'primereact/panel';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";

import PropTypes from "prop-types";
import {SelectTeamComponent} from "../../general/SelectTeamComponent";
import {SelectGamedaySaisonComponent} from "../../general/SelectGamedaySaisonComponent";
import Table from "./statisticGamedaysTable";
import DetailSidebar from "./statisticGamesDetail";

class StatisticsGamedays extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
    }

    showToast(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }

    render() {
        return <div style={{textAlign: "left", align: "center", backgroundColor: "white", color: "black", padding: "20px"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            {this.getFilterArea()}
            {this.state.selectedGames !== undefined ? <DetailSidebar games={this.state.selectedGames} onClose={() => this.setState({selectedGames: undefined})}/> : ""}
            <Table spielergames={this.state.data} uebersicht={true} onSelect={(games) => {
                console.log("onselect", games)
                this.setState({selectedGames: games});
            }} />
        </div>
    }

    changeTeamid(teamid) {
        this.setState({teamid: teamid, saisonid: undefined, data: undefined});
        this.loadData(teamid, undefined);
    }

    changeSaisonid(saisonid) {
        this.setState({saisonid: saisonid});
        this.loadData(this.state.teamid, saisonid);
    }

    loadData(teamid, saisonid) {
        if( teamid === undefined || saisonid === undefined) {
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.statistics + "/spieltag/" + teamid + "/" + saisonid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({data: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Spieltagdaten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Spieltagdaten: " + error.message);
            });
    }

    getFilterArea() {
        return <Panel header="Filter" style={{marginBottom:20}}>
            <div style={{marginTop:10}}>
                <div style={{textAlign: "left"}}>
                    Team:
                </div>
                <div style={{textAlign: "left"}}>
                    <SelectTeamComponent teamid={this.state.teamid} onChange={(teamid) => this.changeTeamid(teamid)}/>
                </div>
                <div style={{textAlign: "left", marginTop:20}}>
                    Saison:
                </div>
                <div style={{textAlign: "left"}}>
                    <SelectGamedaySaisonComponent saisonid={this.state.saisonid} teamid={this.state.teamid} callbackChange={(saisonid) => this.changeSaisonid(saisonid)}/>
                </div>
            </div>
        </Panel>
    }
}

StatisticsGamedays.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default StatisticsGamedays