import {connect} from 'react-redux'
import Freunde from "../../../components/views/liga/Freunde";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id, friends: state.friends.onlineFriends};
};

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Freunde)