import React, {useEffect, useState} from 'react'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {ToggleButton} from "primereact/togglebutton";
import {InputNumber} from "primereact/inputnumber";
import RanglisteMarkerComponent from "../liga/RanglisteMarkerComponent";
import {InputTextarea} from "primereact/inputtextarea";
import {TabPanel, TabView} from "primereact/tabview";
import {SelectButton} from "primereact/selectbutton";
import {TOURNAMENTMODUS} from "../../../constants/tournamentModus";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TurnierSponsorenTabPanel from "./tabs/TurnierSponsorenTabPanel";
import TurnierAdminTabPanel from "./tabs/TurnierAdminTabPanel";
import LigaAktionenComponent from "./tabs/LigaAktionenComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import TournamentX01Area from "./TournamentX01Area";
import TournamentCricketArea from "./TournamentCricketArea";
import KODKOX01VerwaltenArea from "./verwalten/KODKOX01VerwaltenArea";
import KODKOCricketVerwaltenArea from "./verwalten/KODKOCricketVerwaltenArea";
import TurnierQRCode from "../liga/TurnierQRCode";
import TurnierKratzerTabPanel from "./tabs/TurnierKratzerTabPanel";
import BestleistungenDialog from "./BestleistungenDialog";
import RanglisteComponent from "../rangliste/RanglisteComponent";
import RanglisteProfilesComponent from "../rangliste/RanglisteProfilesComponent";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import InfoDialogEventNichtVeroeffentlichen from "../../general/InfoDialogEventNichtVeroeffentlichen";
import DHDialog from "../../general/DHDialog";
import DHDateTimePicker from "../../general/DHDateTimePicker";
import DHDefaultDrawer from "../../general/DHDefaultDrawer";

function TurnierVerwaltenDialog({tournamentId, propEventOperatorId, callBackOnClose, callBackRefresh, callBackOnDelete}) {

    const darttypes = [
        {name: 'Steeldarts', code: 'STEELDARTS'},
        {name: 'E-Dart', code: 'EDART'},
        {name: 'Gemischt', code: 'GEMISCHT'},
    ];

    const turniersystemsAuswahl = [
        {name: 'KO Turnier', value: TOURNAMENTSYSTEMS.EINFACHERKO.id},
        {name: 'DKO Turnier', value: TOURNAMENTSYSTEMS.DOPPELTERKO.id},
        {name: 'Gruppe -> KO Turnier', value: TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id},
        {name: 'Gruppe -> DKO Turnier', value: TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id},
        {name: 'Round Robin', value: TOURNAMENTSYSTEMS.ROUND_ROBIN.id},
        {name: 'Kratzer', value: TOURNAMENTSYSTEMS.KRATZER.id},
        {name: 'Schweizer System', value: TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id},
        {name: 'Any Games', value: TOURNAMENTSYSTEMS.ANY_GAMES.id},
    ];

    let [tournament, setTournament] = useState(undefined);

    let [admins, setAdmins] = useState([]);

    let [showBestleistungenDialog, setShowBestleistungenDialog] = useState(false);

    let [eventOperatorId, setEventOperatorId] = useState(propEventOperatorId);
    let [sponsoren, setSponsoren] = useState([]);

    let [tournamentSystem, setTournamentSystem] = useState(undefined);

    let [gameType, setGameType] = useState(eventOperatorId !== null && eventOperatorId !== undefined ? "x01" : undefined);
    let [id, setId] = useState(undefined);
    let [gruppeFolgeModusId, setGruppeFolgeModusId] = useState(undefined);
    let [turnierstatus, setTurnierstatus] = useState(undefined);
    let [bezeichnung, setBezeichnung] = useState("");
    let [bezeichnungShort, setBezeichnungShort] = useState("");
    let [kennwort, setKennwort] = useState("");
    let [maximaleAnzahlTeilnehmer, setMaximaleAnzahlTeilnehmer] = useState(undefined);
    let [minimaleAnzahlTeilnehmer, setMinimaleAnzahlTeilnehmer] = useState(undefined);
    let [beginn, setBeginn] = useState(undefined);
    let [anmeldeende, setAnmeldeende] = useState(undefined);
    let [anzeigenAb, setAnzeigenAb] = useState(undefined);
    let [doppelturnier, setDoppelturnier] = useState(false);
    let [dyp, setDyp] = useState(false);
    let [averageObergrenze, setAverageObergrenze] = useState(undefined);
    let [urkundendruck, setUrkundendruck] = useState(false);
    let [sollVomSystemGestartetWerden, setSollVomSystemGestartetWerden] = useState(false);
    let [bezahlung, setBezahlung] = useState(false);
    let [bezahlungbetrag, setBezahlungbetrag] = useState("");
    let [mailtext, setMailtext] = useState("");
    let [homepage, setHomepage] = useState("");
    let [socialmedia, setSocialmedia] = useState("");
    let [austragungsortBezeichnung, setAustragungsortBezeichnung] = useState("");
    let [austragungsortStrasse, setAustragungsortStrasse] = useState("");
    let [austragungsortPLZ, setAustragungsortPLZ] = useState("");
    let [austragungsortOrt, setAustragungsortOrt] = useState("");
    let [austragungsortEmail, setAustragungsortEmail] = useState("");
    let [austragungsortTelefon, setAustragungsortTelefon] = useState("");
    let [publishTournament, setPublishTournament] = useState(true);
    // sobald der EventOperator nicht gesetzt ist, handelt es sich um keinen Veranstalter somit um einen Onlineveranstalter. Dieser kann nur Online spielen.
    let [online, setOnline] = useState(eventOperatorId === null || eventOperatorId === undefined);
    let [scoringWithDarthelfer, setScoringWithDarthelfer] = useState(true);
    let [dartType, setDartType] = useState(darttypes[0]);
    let [registrationAllowed, setRegistrationAllowed] = useState(true);
    let [deleteUnconfirmedRegistrations, setDeleteUnconfirmedRegistrations] = useState(true);
    let [participantsMailConfirmation, setParticipantsMailConfirmation] = useState(true);
    let [rangliste, setRangliste] = useState(false);
    let [ranglistemarkers, setRanglistemarkers] = useState([]);
    let [beschreibung, setBeschreibung] = useState("");
    let [auslosungVonHand, setAuslosungVonHand] = useState(false);
    let [anzahlSpielerProGruppeFuerKOPhase, setAnzahlSpielerProGruppeFuerKOPhase] = useState(undefined);
    let [ranglisteId, setRanglisteId] = useState(undefined);
    let [ranglisteProfileId, setRanglisteProfileId] = useState(undefined);

    let [gewinnerseiteSets, setGewinnerseiteSets] = useState(undefined);
    let [gewinnerseiteBestToLegs, setGewinnerseiteBestToLegs] = useState(undefined);
    let [gewinnerseiteFirstToLegs, setGewinnerseiteFirstToLegs] = useState(undefined);
    let [gewinnerseiteModusId, setGewinnerseiteModusId] = useState(undefined);
    let [gewinnerseiteStartpunkte, setGewinnerseiteStartpunkte] = useState(undefined);
    let [gewinnerseiteZufallszahlen, setGewinnerseiteZufallszahlen] = useState(undefined);
    let [gewinnerseiteZufallszahlenJedesLeg, setGewinnerseiteZufallszahlenJedesLeg] = useState(undefined);
    let [gewinnerseiteCutthroat, setGewinnerseiteCutthroat] = useState(undefined);

    let [verliererseiteSets, setVerliererseiteSets] = useState(undefined);
    let [verliererseiteFirstToLegs, setVerliererseiteFirstToLegs] = useState(undefined);
    let [verliererseiteModusId, setVerliererseiteModusId] = useState(undefined);
    let [verliererseiteStartpunkte, setVerliererseiteStartpunkte] = useState(undefined);
    let [verliererseiteZufallszahlen, setVerliererseiteZufallszahlen] = useState(undefined);
    let [verliererseiteZufallszahlenJedesLeg, setVerliererseiteZufallszahlenJedesLeg] = useState(undefined);
    let [verliererseiteCutthroat, setVerliererseiteCutthroat] = useState(undefined);

    let [finaleVerliererseiteMussZweiSpieleGewinnen, setFinaleVerliererseiteMussZweiSpieleGewinnen] = useState(false);
    let [spielUmPlatz3, setSpielUmPlatz3] = useState(false);
    let [playerWarteliste, setPlayerWarteliste] = useState(false);
    let [bestplatzierteNaechsteKommenWeiter, setbestplatzierteNaechsteKommenWeiter] = useState(false);

    let [showPublishTournamentInfo, setShowPublishTournamentInfo] = useState(false);
    let [showPublishTournamentNotice, setShowPublishTournamentNotice] = useState(false);
    let [showOnlineNotice, setShowOnlineNotice] = useState(false);
    let [showScoringWithClientNotice, setShowScoringWithClientNotice] = useState(false);
    let [showDartTypeNotice, setShowDartTypeNotice] = useState(false);
    let [showRegistrationNotice, setShowRegistrationNotice] = useState(false);
    let [showAnmeldungUnbestaetigtLoeschenNotice, setShowAnmeldungUnbestaetigtLoeschenNotice] = useState(false);
    let [showTeilnahmePerMailBestaetigenNotice, setShowTeilnahmePerMailBestaetigenNotice] = useState(false);
    let [showBezahlungNotice, setShowBezahlungNotice] = useState(false);
    let [showBezahlungBetragNotice, setShowBezahlungBetragNotice] = useState(false);
    let [showMailNotice, setShowMailNotice] = useState(false);
    let [showStartzeitpunktNotice, setShowStartzeitpunktNotice] = useState(false);
    let [showMaxAverageNotice, setShowMaxAverageNotice] = useState(false);
    let [showKennwortNotice, setShowKennwortNotice] = useState(false);
    let [showRoundRobinGruppenNotice, setShowRoundRobinGruppenNotice] = useState(false);
    let [showBestplatzierteNaechteKommtWeiter, setShowBestplatzierteNaechteKommtWeiter] = useState(false);
    let [showDypNotice, setShowDypNotice] = useState(false);
    let [showUrkundenNotice, setShowUrkundenNotice] = useState(false);
    let [showRanglisteNotice, setShowRanglisteNotice] = useState(false);
    let [showStartTournamentNotice, setShowStartTournamentNotice] = useState(false);

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    function ladeTurnier(id) {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ladekodko/" + id,
            json => {
                let tournament = json.tournamentDto;
                setTournament(tournament);
                setId(tournament.id);
                setGruppeFolgeModusId(tournament.gruppeFolgeModusId);
                setEventOperatorId(tournament.eventOperatorId);
                setRanglisteId(tournament.ranglisteId);
                setRanglisteProfileId(tournament.ranglisteProfileId);
                setTurnierstatus(tournament.tournamentstatus);
                setBezahlung(tournament.bezahlung);
                setBezahlungbetrag(tournament.bezahlungBetrag);
                setMailtext(tournament.mailtext);
                setOnline(tournament.online);
                setAustragungsortBezeichnung(tournament.austragungsortBezeichnung);
                setAustragungsortStrasse(tournament.austragungsortStrasse);
                setAustragungsortPLZ(tournament.austragungsortPLZ);
                setAustragungsortOrt(tournament.austragungsortOrt);
                setAustragungsortTelefon(tournament.telefon);
                setAustragungsortEmail(tournament.email);
                setSocialmedia(tournament.socialmedia);
                setHomepage(tournament.homepage);
                setParticipantsMailConfirmation(tournament.participantsMailConfirmation);
                setRegistrationAllowed(tournament.registrationAllowed);
                setDeleteUnconfirmedRegistrations(tournament.deleteUnconfirmedRegistrations);
                setPublishTournament(tournament.publishTournament);
                setScoringWithDarthelfer(tournament.scoringWithDarthelfer);
                if( tournament.dartType === null || tournament.dartType === undefined) {
                    setDartType(darttypes[0]);
                } else {
                    let tmpDartType = darttypes[0];
                    for (const tmp of darttypes) {
                        if( tmp.code === tournament.dartType) {
                            tmpDartType = tmp;
                        }
                    }
                    setDartType(tmpDartType);
                }
                setGameType(tournament.gameType);
                setBezeichnung(tournament.bezeichnung);
                setBezeichnungShort(tournament.bezeichnungShort);
                setTournamentSystem(tournament.turniersystem);
                setAuslosungVonHand(tournament.auslosungVonHand);
                setAnzahlSpielerProGruppeFuerKOPhase(tournament.anzahlSpielerProGruppeFuerKOPhase);
                setbestplatzierteNaechsteKommenWeiter(tournament.bestplatzierteNaechsteKommenWeiter)
                if (tournament.beginn !== undefined && tournament.beginn !== null) {
                    setBeginn(tournament.beginn);
                }
                setBeschreibung(tournament.beschreibung);
                setKennwort(tournament.kennwort);
                setMinimaleAnzahlTeilnehmer(tournament.minAnzahlTeilnehmer);
                setMaximaleAnzahlTeilnehmer(tournament.maxAnzahlTeilnehmer);
                setAverageObergrenze(tournament.averageObergrenze);
                if (tournament.anmeldeende !== undefined && tournament.anmeldeende !== null) {
                    setAnmeldeende(tournament.anmeldeende);
                }
                if (tournament.anzeigenAb !== undefined && tournament.anzeigenAb !== null) {
                    setAnzeigenAb(tournament.anzeigenAb);
                }
                setSollVomSystemGestartetWerden(tournament.sollVomSystemGestartetWerden);
                setDoppelturnier(tournament.doppelturnier);
                setDyp(tournament.dyp);
                setUrkundendruck(tournament.urkunden);
                setFinaleVerliererseiteMussZweiSpieleGewinnen(tournament.finaleVerliererseiteMussZweiSpieleGewinnen);
                setSpielUmPlatz3(tournament.spielUmPlatz3);
                setPlayerWarteliste(tournament.playerWarteliste);
                setRangliste(tournament.rangliste);
                setRanglistemarkers(tournament.ranglistemarkers);
                let adminsNachDemLaden = [];
                for (const tmpAdmin of tournament.administratoren) {
                    adminsNachDemLaden.push(tmpAdmin.userId);
                }
                setAdmins(adminsNachDemLaden);
                let sponsorenNachDemLaden = [];
                for (const tmpSponsor of tournament.tournamentSponsorenEntities) {
                    sponsorenNachDemLaden.push(tmpSponsor.sponsorEntity.id)
                }
                setSponsoren(sponsorenNachDemLaden);
                let tournamentModusEntities = tournament.tournamentModusEntities;
                let modusKratzer = tournamentModusEntities.find(modusEntity => modusEntity.modusTyp === "kratzer");
                if (modusKratzer !== null && modusKratzer !== undefined) {
                    setGewinnerseiteSets(modusKratzer.sets);
                    setGewinnerseiteStartpunkte(modusKratzer.startpunkte);
                    setGewinnerseiteFirstToLegs(modusKratzer.firstToLegs);
                    setGewinnerseiteBestToLegs(modusKratzer.bestOfLegs);
                    setGewinnerseiteModusId(modusKratzer.modusid);
                }
                let modusAnyGames = tournamentModusEntities.find(modusEntity => modusEntity.modusTyp === "anygames");
                if (modusAnyGames !== null && modusAnyGames !== undefined) {
                    setGewinnerseiteSets(modusAnyGames.sets);
                    setGewinnerseiteStartpunkte(modusAnyGames.startpunkte);
                    setGewinnerseiteFirstToLegs(modusAnyGames.firstToLegs);
                    setGewinnerseiteBestToLegs(modusAnyGames.bestOfLegs);
                    setGewinnerseiteModusId(modusAnyGames.modusid);
                }
                let modusGruppenturnier = tournamentModusEntities.find(modusEntity => modusEntity.modusTyp === "gruppenturnier");
                if (modusGruppenturnier !== null && modusGruppenturnier !== undefined) {
                    setGewinnerseiteSets(modusGruppenturnier.sets);
                    setGewinnerseiteStartpunkte(modusGruppenturnier.startpunkte);
                    setGewinnerseiteFirstToLegs(modusGruppenturnier.firstToLegs);
                    setGewinnerseiteBestToLegs(modusGruppenturnier.bestOfLegs);
                    setGewinnerseiteModusId(modusGruppenturnier.modusid);
                    setGewinnerseiteZufallszahlen(modusGruppenturnier.zufallszahlen);
                    setGewinnerseiteZufallszahlenJedesLeg(modusGruppenturnier.zufallszahlenjedesleg);
                    setGewinnerseiteCutthroat(modusGruppenturnier.cutthroat);
                }
                let modusVerliererseite = tournamentModusEntities.find(modusEntity => modusEntity.modusTyp === "verliererseite");
                if (modusVerliererseite !== null && modusVerliererseite !== undefined) {
                    setVerliererseiteSets(modusVerliererseite.sets);
                    setVerliererseiteStartpunkte(modusVerliererseite.startpunkte);
                    setVerliererseiteFirstToLegs(modusVerliererseite.firstToLegs);
                    setVerliererseiteModusId(modusVerliererseite.modusid);
                    setVerliererseiteZufallszahlen(modusVerliererseite.zufallszahlen);
                    setVerliererseiteZufallszahlenJedesLeg(modusVerliererseite.zufallszahlenjedesleg);
                    setVerliererseiteCutthroat(modusVerliererseite.cutthroat);
                }
                let modusGewinnerseite = tournamentModusEntities.find(modusEntity => modusEntity.modusTyp === "gewinnerseite");
                if (modusGewinnerseite !== null && modusGewinnerseite !== undefined) {
                    setGewinnerseiteSets(modusGewinnerseite.sets);
                    setGewinnerseiteStartpunkte(modusGewinnerseite.startpunkte);
                    setGewinnerseiteFirstToLegs(modusGewinnerseite.firstToLegs);
                    setGewinnerseiteModusId(modusGewinnerseite.modusid);
                    setGewinnerseiteZufallszahlen(modusGewinnerseite.zufallszahlen);
                    setGewinnerseiteZufallszahlenJedesLeg(modusGewinnerseite.zufallszahlenjedesleg);
                    setGewinnerseiteCutthroat(modusGewinnerseite.cutthroat);
                }
                setShowPublishTournamentInfo(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    useEffect(() => {
        if (tournamentId === null || tournamentId === undefined) {
            return;
        }
        ladeTurnier(tournamentId);
    }, []);

    useEffect(() => {
        if(!publishTournament) {
            setShowPublishTournamentInfo(true);
        }
    }, [publishTournament]);

    function eventOperatorDatenVorbelegen() {
        setErrorMessage(undefined);

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + eventOperatorId,
            json => {
                setHomepage(json.homepage);
                setSocialmedia(json.socialmedia);
                setAustragungsortBezeichnung(json.austragungsortBezeichnung);
                setAustragungsortStrasse(json.austragungsortStrasse);
                setAustragungsortPLZ(json.austragungsortPLZ);
                setAustragungsortOrt(json.austragungsortOrt);
                setAustragungsortEmail(json.email);
                setAustragungsortTelefon(json.telefon);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Veranstalterdaten: " + error.message);
            }
        );
    }

    function save() {
        setErrorMessage(undefined);
        if( (rangliste || (ranglisteId !== null && ranglisteId !== undefined)) && (bezeichnungShort === null || bezeichnungShort === undefined || bezeichnungShort === "")) {
            setErrorMessage("Kurzbezeichnung muss bei Rangliste gefüllt sein.");
            return;
        }
        if( eventOperatorId !== undefined && eventOperatorId !== null && bezahlung && bezahlungbetrag === "") {
            setErrorMessage("Sofern du Bezahlung gewählt hast, musst du auch einen Bezahlbetrag eingeben.");
            return;
        }
        let isDKOModus = tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id;
        let modusArray = [];
        if (tournamentSystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.KRATZER.id,
                gameType: gameType,
                modusid: gewinnerseiteModusId,
                sets: gewinnerseiteSets,
                firstToLegs: gewinnerseiteFirstToLegs,
                bestOfLegs: gewinnerseiteBestToLegs,
                startpunkte: gewinnerseiteStartpunkte,
            });
        } else if (tournamentSystem === TOURNAMENTSYSTEMS.ANY_GAMES.id) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.ANY_GAMES.id,
                gameType: gameType,
                modusid: gewinnerseiteModusId,
                sets: gewinnerseiteSets,
                firstToLegs: gewinnerseiteFirstToLegs,
                bestOfLegs: gewinnerseiteBestToLegs,
                startpunkte: gewinnerseiteStartpunkte,
                zufallszahlen: gewinnerseiteZufallszahlen,
                zufallszahlenjedesleg: gewinnerseiteZufallszahlenJedesLeg,
                cutthroat: gewinnerseiteCutthroat
            });
        } else if (tournamentSystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.GRUPPENTURNIER.id,
                gameType: gameType,
                modusid: gewinnerseiteModusId,
                sets: gewinnerseiteSets,
                firstToLegs: gewinnerseiteFirstToLegs,
                bestOfLegs: gewinnerseiteBestToLegs,
                startpunkte: gewinnerseiteStartpunkte,
                zufallszahlen: gewinnerseiteZufallszahlen,
                zufallszahlenjedesleg: gewinnerseiteZufallszahlenJedesLeg,
                cutthroat: gewinnerseiteCutthroat
            });
        } else {
            modusArray.push({
                modusTyp: TOURNAMENTMODUS.GEWINNERSEITE.id,
                gameType: gameType,
                modusid: gewinnerseiteModusId,
                sets: gewinnerseiteSets,
                firstToLegs: gewinnerseiteFirstToLegs,
                bestOfLegs: undefined,
                startpunkte: gewinnerseiteStartpunkte,
                zufallszahlen: gewinnerseiteZufallszahlen,
                zufallszahlenjedesleg: gewinnerseiteZufallszahlenJedesLeg,
                cutthroat: gewinnerseiteCutthroat
            });
            if (isDKOModus && (verliererseiteModusId !== undefined || verliererseiteSets !== undefined || verliererseiteFirstToLegs !== undefined || verliererseiteStartpunkte)) {
                modusArray.push({
                    modusTyp: TOURNAMENTMODUS.VERLIERERSEITE.id,
                    gameType: gameType,
                    modusid: verliererseiteModusId,
                    sets: verliererseiteSets,
                    firstToLegs: verliererseiteFirstToLegs,
                    bestOfLegs: undefined,
                    startpunkte: verliererseiteStartpunkte,
                    zufallszahlen: verliererseiteZufallszahlen,
                    zufallszahlenjedesleg: verliererseiteZufallszahlenJedesLeg,
                    cutthroat: verliererseiteCutthroat
                });
            }
        }

        let adminsZumSpeichern = [];
        for (const admin of admins) {
            adminsZumSpeichern.push({userId: admin});
        }

        let myPublishTournament;
        if( eventOperatorId === null || eventOperatorId === undefined) {
            // kein eventOperator, daher immer veröffentlichen
            myPublishTournament = true;
        } else {
            myPublishTournament =  publishTournament;
        }

        let data = {
            id: id, gameType: gameType, eventOperatorId: eventOperatorId,
            participantsMailConfirmation: participantsMailConfirmation,
            bezahlung: bezahlung,
            bezahlungBetrag: bezahlungbetrag,
            socialmedia: socialmedia,
            homepage: homepage,
            austragungsortBezeichnung: austragungsortBezeichnung,
            austragungsortStrasse: austragungsortStrasse,
            austragungsortPLZ: austragungsortPLZ,
            austragungsortOrt: austragungsortOrt,
            email: austragungsortEmail,
            telefon: austragungsortTelefon,
            mailtext: mailtext,
            registrationAllowed: registrationAllowed,
            deleteUnconfirmedRegistrations: deleteUnconfirmedRegistrations,
            publishTournament: myPublishTournament,
            scoringWithDarthelfer: scoringWithDarthelfer,
            dartType: dartType.code,
            online: online, bezeichnung: bezeichnung, bezeichnungShort: bezeichnungShort, beschreibung: beschreibung,
            beginn: beginn, anmeldeende: anmeldeende, anzeigenAb: anzeigenAb,
            kennwort: kennwort,
            turniersystem: tournamentSystem,
            maxAnzahlTeilnehmer: maximaleAnzahlTeilnehmer,
            minAnzahlTeilnehmer: minimaleAnzahlTeilnehmer,
            sollVomSystemGestartetWerden: sollVomSystemGestartetWerden,
            doppelturnier: doppelturnier, dyp: dyp, urkunden: urkundendruck,
            rangliste: rangliste, ranglistemarkers: ranglistemarkers,
            ranglisteId: ranglisteId,
            ranglisteProfileId: ranglisteProfileId,
            averageObergrenze: averageObergrenze,
            finaleVerliererseiteMussZweiSpieleGewinnen: finaleVerliererseiteMussZweiSpieleGewinnen,
            spielUmPlatz3: spielUmPlatz3,
            playerWarteliste: playerWarteliste,
            administratoren: adminsZumSpeichern,
            sponsorenZumSpeichern: sponsoren,
            tournamentModusEntities: modusArray,
            auslosungVonHand: auslosungVonHand,
            anzahlSpielerProGruppeFuerKOPhase: anzahlSpielerProGruppeFuerKOPhase,
            bestplatzierteNaechsteKommenWeiter: bestplatzierteNaechsteKommenWeiter,
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/save",
            data,
            json => {
                ladeTurnier(json.id);
                setInfoMessage("Turnier wurde erfolgreich gespeichert.");
                callBackRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );
    }


    function showSaveButton() {
        if (!bezeichnung || !beginn || !tournamentSystem) {
            return false;
        }
        if( eventOperatorId !== null && eventOperatorId !== undefined && (publishTournament === undefined || publishTournament === null)) {
            return false;
        }
        if (gameType === "x01") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteModusId && gewinnerseiteStartpunkte;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteModusId && verliererseiteStartpunkte;
                return gewinnerFelder && verliererFelder;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id ||
                tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
                tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ||
                tournamentSystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ||
                tournamentSystem === TOURNAMENTSYSTEMS.ANY_GAMES.id ||
                tournamentSystem === TOURNAMENTSYSTEMS.KRATZER.id) {
                return (gewinnerseiteFirstToLegs || gewinnerseiteBestToLegs) && gewinnerseiteModusId && gewinnerseiteStartpunkte;
            } else {
                return false;
            }
        } else if (gameType === "cricket") {
            if (tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id) {
                return gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) {
                let gewinnerFelder = gewinnerseiteFirstToLegs && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
                let verliererFelder = verliererseiteFirstToLegs && verliererseiteZufallszahlen !== undefined && verliererseiteCutthroat !== undefined;
                return gewinnerFelder && verliererFelder;
            } else if (tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
                return (gewinnerseiteFirstToLegs || gewinnerseiteBestToLegs) && gewinnerseiteZufallszahlen !== undefined && gewinnerseiteCutthroat !== undefined;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    function istTurnierNeu() {
        return turnierstatus === undefined || turnierstatus === null || turnierstatus === "neu";
    }

    function istTurnierGestartet() {
        return turnierstatus === "gestartet";
    }

    function istTurnierAbgeschlossen() {
        return turnierstatus === "abgeschlossen";
    }

    const dialogFooter = (
        <div>
            <Button label="Speichern" icon="pi pi-save" style={{marginRight: 5}} disabled={!showSaveButton()} onClick={() => save()}/>
        </div>
    );

    function getX01Area() {
        if (tournamentSystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.ANY_GAMES.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ||
            tournamentSystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            return <>
                <TournamentX01Area showSets={true} showBestOfLegs={true} showPflichtfelder={true}
                                      sets={gewinnerseiteSets}
                                      firstToLegs={gewinnerseiteFirstToLegs}
                                      bestOfLegs={gewinnerseiteBestToLegs}
                                      modusId={gewinnerseiteModusId} startpunkte={gewinnerseiteStartpunkte}
                                      onSetsChanged={(sets) => setGewinnerseiteSets(sets)}
                                      onFirstToLegsChanged={(firstToLegs) => setGewinnerseiteFirstToLegs(firstToLegs)}
                                      onBestOfLegsChanged={(bestOfLegs) => setGewinnerseiteBestToLegs(bestOfLegs)}
                                      onModusIdChanged={(modusId) => setGewinnerseiteModusId(modusId)}
                                      onStartpunkteChanged={(startpunkte) => setGewinnerseiteStartpunkte(startpunkte)}/>
                {(eventOperatorId !== undefined && (tournamentSystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id)) && <>
                    <div className="grid" style={{marginTop:20}}>
                        <DHDefaultDrawer onClose={() => setShowRoundRobinGruppenNotice(false)} show={showRoundRobinGruppenNotice} title={"Hinweis"} children={
                            <div style={{padding: 20}}>
                                <div style={{marginBottom: 5}}>
                                    Wähle 'Ja' sobald du die Gruppen von Hand festlegen willst. Bei 'Nein' wird Darthelfer die Gruppen für dich automatisch erstellen und
                                    auslosen.
                                </div>
                            </div>
                        }/>
                        <div className="col-fixed" style={{width: 250}}>Gruppen von Hand festlegen:
                            <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                               onClick={(e) => setShowRoundRobinGruppenNotice(!showRoundRobinGruppenNotice)}/>
                        </div>
                        <div className="col-fixed" style={{width: "200px"}}>
                            <ToggleButton
                                className={ !istTurnierNeu() ? "p-disabled" : ""}
                                onLabel="Ja" offLabel="Nein"
                                checked={auslosungVonHand}
                                onChange={(e) => setAuslosungVonHand(!auslosungVonHand)}/>
                        </div>
                    </div>
                </>
                }
                {(eventOperatorId !== undefined && !istTurnierAbgeschlossen() &&
                    (tournamentSystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
                        tournamentSystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ||
                        tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id)) && <>
                    <div className="grid" style={{marginTop:20}}>
                        <div className="col-fixed" style={{width: 250}}>Anzahl der Spieler die pro Gruppe weiterkommen, bei Round Robin oder Schweizer System die in der Tabelle markiert werden:</div>
                        <div className="col-fixed" style={{width: "200px"}}>
                            <InputNumber value={anzahlSpielerProGruppeFuerKOPhase} onValueChange={(e) => setAnzahlSpielerProGruppeFuerKOPhase(e.value)} min={1}/>
                        </div>
                    </div>
                </>
                }
                {(!istTurnierAbgeschlossen() && (tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id)) && <>
                    <div className="grid" style={{marginTop:20}}>
                        <DHDefaultDrawer onClose={() => setShowBestplatzierteNaechteKommtWeiter(false)} show={showBestplatzierteNaechteKommtWeiter} title={"Hinweis"} children={
                            <div style={{padding: 20}}>
                                <div style={{marginBottom: 5}}>
                                    Sobald du durch die Eingabe *Anzahl der Spieler pro Gruppe die Weiterkommen* keinen vollständingen
                                    z.B. 16er, 32er usw. KO-Baum gefüllt hast, <br/>
                                    kannst du mit diesem Schalter die bestplatzierten Nächsten (z.B. die bestplatzierten Dritten) weiterkommen lassen.<br/><br/>
                                    Diese Auswahl ergibt nur Sinn, sobald durch deine Gruppenzahl und Teilnehmer die Weiterkommen nicht sowieso ein z.B. 16er, 32er usw. KO-Baum entsteht.<br/><br/>
                                    WICHTIG '1'! Sofern du die Gruppenphase als DKO spielst, wird diese Einstellung keine Auswirkung haben.<br/><br/>
                                    WICHTIG '2'!<br/>
                                    Beispiel: 5 Gruppen, 2 kommen weiter und die Bestplatzierten.<br/>
                                    In diesem Fall würden 5 x 2 weiterkommen = 10 plus die bestplatzierten Dritten, dass wären alle Dritten. Somit gesamt 15 Spieler die Weiterkommen.<br/>
                                    Bei dieser Einstellung würde <b>nicht</b> auf die weiteren Bestplatzierten auf Platz 4 geschaut um den KO-Baum zu füllen. Es würde somit 1 Freilos geben.<br/>
                                    Deine Einstellung in diesem Fall sollte sein, dass 3 weiterkommen und die Bestplatzierten. Damit würden 5 x 3 = 15 plus der bestplatzierte 4. weiterkommen. Gesamt
                                    somit 16 Spieler die weiterkommen.<br/><br/>
                                    WICHTIG '3'!<br/>
                                    Bei der Berechnung des Bestplatzierten wird ein Sieg des Spielers mit 3 Punkten gewertet, ein Unentschieden mit 1 Punkt. Diese Punkte werden durch die Anzahl der Spiele
                                    geteilt. Somit ist die Berechnung auch bei unterschiedlichen Gruppengrößen und damit unterschiedliche Spieleanzahl gerecht.<br/>
                                    Beispiel:<br/>
                                    Spieler 1: 1 Siege, 1 Unentschieden und 3 Verloren = 0,8 Faktor<br/>
                                    Spieler 2: 1 Siege, 1 Unentschieden und 2 Verloren = 1,0 Faktor<br/>
                                    Spieler 3: 0 Siege, 2 Unentschieden und 2 Verloren = 0,5 Faktor<br/>
                                    Die Spieler würden nun in der Reihenfolge des Faktors berücksichtigt. Zuerst Spieler 2, dann Spieler 1 und zum Schluss Spieler 3.
                                </div>
                            </div>
                        }/>
                        <div className="col-fixed" style={{width: 250}}>Bestplatzierte Nächste kommen weiter:
                            <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                               onClick={() => setShowBestplatzierteNaechteKommtWeiter(!showBestplatzierteNaechteKommtWeiter)}/>
                        </div>
                        <div className="col-fixed" style={{width: "200px"}}>
                            <ToggleButton
                                className={ istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                onLabel="Ja" offLabel="Nein"
                                checked={bestplatzierteNaechsteKommenWeiter}
                                onChange={() => setbestplatzierteNaechsteKommenWeiter(!bestplatzierteNaechsteKommenWeiter)}/>
                        </div>
                    </div>
                </>
                }
            </>
        }

        return <>
            <KODKOX01VerwaltenArea
                tournamentSystem={tournamentSystem}
                verliererseiteSets={verliererseiteSets}
                verliererseiteModusId={verliererseiteModusId}
                verliererseiteFirstToLegs={verliererseiteFirstToLegs}
                verliererseiteStartpunkte={verliererseiteStartpunkte}
                gewinnerseiteSets={gewinnerseiteSets}
                gewinnerseiteModusId={gewinnerseiteModusId}
                gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs}
                gewinnerseiteStartpunkte={gewinnerseiteStartpunkte}
                setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteModusId={setGewinnerseiteModusId} setGewinnerseiteStartpunkte={setGewinnerseiteStartpunkte}
                setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteModusId={setVerliererseiteModusId} setVerliererseiteStartpunkte={setVerliererseiteStartpunkte}
            />
            <div style={{color:"red", marginTop:20}}>Weitere Einstellungen, z.B. zu den einzelnen Spielrunden, kannst du nach der Auslosung in der Listenansicht hinter dem jeweiligen Bereich oder im Baum durch Klick auf die Überschrift vornehmen.</div>
        </>
    }

    function getCricketArea() {
        if (tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || tournamentSystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id) {
            return <TournamentCricketArea showSets={true} showBestOfLegs={true} showPflichtfelder={true}
                                          sets={gewinnerseiteSets} firstToLegs={gewinnerseiteFirstToLegs}
                                          bestOfLegs={gewinnerseiteBestToLegs}
                                          zufallszahlen={gewinnerseiteZufallszahlen}
                                          zufallszahlenjedesleg={gewinnerseiteZufallszahlenJedesLeg}
                                          cutthroat={gewinnerseiteCutthroat}
                                          onSetsChanged={(sets) => setGewinnerseiteSets(sets)}
                                          onFirstToLegsChanged={(firstToLegs) => setGewinnerseiteFirstToLegs(firstToLegs)}
                                          onBestOfLegsChanged={(bestOfLegs) => setGewinnerseiteBestToLegs(bestOfLegs)}
                                          onZufallszahlenChanged={(value) => setGewinnerseiteZufallszahlen(value)}
                                          onZufallszahlenjedeslegChanged={(value) => setGewinnerseiteZufallszahlenJedesLeg(value)}
                                          onCutthroatChanged={(value) => setGewinnerseiteCutthroat(value)}/>
        }
        return <KODKOCricketVerwaltenArea
            tournamentSystem={tournamentSystem}
            gewinnerseiteSets={gewinnerseiteSets} gewinnerseiteFirstToLegs={gewinnerseiteFirstToLegs} gewinnerseiteZufallszahlen={gewinnerseiteZufallszahlen} gewinnerseiteZufallszahlenJedesLeg={gewinnerseiteZufallszahlenJedesLeg} gewinnerseiteCutthroat={gewinnerseiteCutthroat}
            verliererseiteSets={verliererseiteSets} verliererseiteFirstToLegs={verliererseiteFirstToLegs} verliererseiteZufallszahlen={verliererseiteZufallszahlen} verliererseiteZufallszahlenJedesLeg={verliererseiteZufallszahlenJedesLeg} verliererseiteCutthroat={verliererseiteCutthroat}
            setGewinnerseiteSets={setGewinnerseiteSets} setGewinnerseiteFirstToLegs={setGewinnerseiteFirstToLegs} setGewinnerseiteZufallszahlen={setGewinnerseiteZufallszahlen} setGewinnerseiteZufallszahlenJedesLeg={setGewinnerseiteZufallszahlenJedesLeg} setGewinnerseiteCutthroat={setGewinnerseiteCutthroat}
            setVerliererseiteSets={setVerliererseiteSets} setVerliererseiteFirstToLegs={setVerliererseiteFirstToLegs} setVerliererseiteZufallszahlen={setVerliererseiteZufallszahlen} setVerliererseiteZufallszahlenJedesLeg={setVerliererseiteZufallszahlenJedesLeg} setVerliererseiteCutthroat={setVerliererseiteCutthroat}
        />
    }

    return <DHDialog onClose={callBackOnClose} title="Gruppe / KO / DKO Turnier / Kratzer / Schweizer anlegen/bearbeiten" buttons={dialogFooter} showCloseButton={true} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="grid">
            <div className="col-fixed" style={{width: 140, color: bezeichnung === "" ? "red" : ""}}>Bezeichnung: *</div>
            <div className="col"><InputText style={{minWidth:300, maxWidth: 500}} value={bezeichnung}
                                            onChange={(e) => setBezeichnung(e.target.value)}/></div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 140}}>Bezeichnung(kurz):</div>
            <div className="col"><InputText style={{width:200}} value={bezeichnungShort} maxLength={20}
                                            onChange={(e) => setBezeichnungShort(e.target.value)}/></div>
        </div>
        <div className="grid">
            <DHDefaultDrawer onClose={() => setShowStartzeitpunktNotice(false)} show={showStartzeitpunktNotice} title={"Hinweis"} children={
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 5}}>
                        Sofern du keine Uhrzeit angeben möchtest, wähle bitte 00:00 für die Uhrzeit aus. In diesem Fall werden wir dem Benutzer/Teilnehmer die Uhrzeit nicht anzeigen. Alle anderen
                        Uhrzeiten werden dem Benutzer/Teilnehmer zusätzlich zum Datum angezeigt.
                    </div>
                </div>
            }/>
            <div className="col-fixed" style={{width: 140, color: beginn === undefined || beginn === null ? "red" : ""}}>Startzeitpunkt: *
                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                   onClick={(e) => setShowStartzeitpunktNotice(!showStartzeitpunktNotice)}/>
            </div>
            <div className="col">
                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={beginn} changeValue={setBeginn}/>
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={{width: 140,  color: tournamentSystem === undefined || tournamentSystem === null ? "red" : ""}}>Turniermodus: *</div>
            <div className="col">
                <SelectButton disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={tournamentSystem}
                              optionLabel="name"
                              options={turniersystemsAuswahl}
                              onChange={(e) => {
                                  setTournamentSystem(e.value);
                              }}></SelectButton>
            </div>
        </div>
        <div className="grid">
            <DHDefaultDrawer onClose={() => setShowDartTypeNotice(false)} show={showDartTypeNotice} title={"Hinweis"} children={
                <div style={{padding: 20}}>
                    <div style={{marginBottom: 5}}>
                        Dart-Type wird im öffentlichen Bereich angezeigt.
                    </div>
                    <div style={{marginBottom: 5}}>
                        Bei Events von einem Veranstalter ist die richtige Auswahl wichtig, da der Scorer damit gesteuert wird ob E-Dart (Legeingabe) oder Scoring mit dem Scorer möglich ist.
                    </div>
                </div>
            }/>
            <div className="col-fixed" style={{width: 140}}>Dart-Typ:
                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                   onClick={(e) => setShowDartTypeNotice(!showDartTypeNotice)}/>
            </div>
            <div className="col">
                <SelectButton optionLabel="name" value={dartType} options={darttypes} onChange={(e) => setDartType(e.value)}
                              disabled={istTurnierGestartet() || istTurnierAbgeschlossen()}/>
            </div>
        </div>

        <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border: "none"}}>
            <TabPanel header="Modus">
                <div className="grid" style={{textAlign: "left"}}>
                    <div className="flex align-items-center justify-content-center h-4rem"
                         style={{marginLeft: 5, marginRight: 5}}>
                        <ToggleButton style={{width: 200}} checked={gameType === 'x01'} offLabel="X01" onLabel="X01"
                                      onChange={(e) => setGameType('x01')}
                                      className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <ToggleButton style={{width: 200}} checked={gameType === 'cricket'} offLabel="Cricket"
                                          onLabel="Cricket" onChange={(e) => setGameType('cricket')}
                                          className={ istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}/>
                        }
                    </div>
                </div>
                {gameType === "x01" && getX01Area()}
                {gameType === "cricket" && getCricketArea()}
            </TabPanel>
            <TabPanel header="Optionale Eingaben">
                <div className="grid">
                    <div className="col-fixed" style={{width: 200}}>Beschreibung:</div>
                </div>
                <div className="grid">
                    <div className="col" style={{width: "100%"}}>
                        <InputTextarea rows={5} cols={50} maxLength={10000} value={beschreibung}
                                       onChange={(e) => setBeschreibung(e.target.value)}/>
                    </div>
                </div>
                {eventOperatorId !== undefined && eventOperatorId !== null && <>
                    <hr/>
                    {(id === null || id === undefined) && <div>Bestleistungen können erst nach dem ersten Speichern eines Turniers festgelegt werden.</div>}
                    <div><Button label="Bestleistungen bearbeiten" disabled={id === null || id === undefined} onClick={() => setShowBestleistungenDialog(true)} /></div>
                </>
                }
                {eventOperatorId !== undefined && eventOperatorId !== null && TOURNAMENTSYSTEMS.ANY_GAMES.id !== tournamentSystem && <>
                    <div style={{marginTop:10}}>Rangliste:</div>
                    <div><RanglisteComponent eventOperatorId={eventOperatorId} onChange={(id) => setRanglisteId(id)} ranglisteId={ranglisteId} onError={(message) => setErrorMessage(message)}/> </div>
                    <div style={{marginTop:10}}>Ranglistenprofil:</div>
                    <div><RanglisteProfilesComponent eventOperatorId={eventOperatorId} onChange={(id) => setRanglisteProfileId(id)} ranglisteProfileId={ranglisteProfileId} onError={(message) => setErrorMessage(message)}/> </div>
                </>
                }
                <hr/>
                <div className="grid">
                    <div className={"col-12 md:col-12 lg:col-12"} style={{padding: 10}}>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowKennwortNotice(false)} show={showKennwortNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>Die Eingabe des Kennworts ist optional. Sobald ein
                                        Kennwort angegeben wird, muss der Benutzer bei
                                        der Teilnahme ein Kennwort eingeben.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 210}}>Kennwort für Turnierteilnahme:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowKennwortNotice(!showKennwortNotice)}/>
                            </div>
                            <div className="col">
                                <InputText disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={kennwort} style={{width: 200}}
                                           onChange={(e) => setKennwort(e.target.value)}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 210}}>Minimale Teilnehmerzahl:</div>
                            <div className="col">
                                <InputNumber value={minimaleAnzahlTeilnehmer} onValueChange={(e) => setMinimaleAnzahlTeilnehmer(e.value)} min={1}/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 210}}>Maximale Teilnehmerzahl:</div>
                            <div className="col">
                                <InputNumber value={maximaleAnzahlTeilnehmer} onValueChange={(e) => setMaximaleAnzahlTeilnehmer(e.value)} min={1}/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 210}}>Warteliste aktiv:</div>
                            <div className="col">
                                <ToggleButton
                                    className={istTurnierAbgeschlossen() || maximaleAnzahlTeilnehmer === null || maximaleAnzahlTeilnehmer === undefined ? "p-disabled" : ""}
                                    onLabel="Ja" offLabel="Nein"
                                    checked={playerWarteliste}
                                    onChange={(e) => setPlayerWarteliste(!playerWarteliste)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: 210}}>Anmeldeende:</div>
                            <div className="col-fixed" style={{width:300}}>
                                <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={anmeldeende} changeValue={setAnmeldeende}/>
                            </div>
                        </div>
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <div className="col-fixed" style={{width: 210}}>Anzeigen ab:</div>
                                <div className="col">
                                    <DHDateTimePicker disabled={istTurnierGestartet() || istTurnierAbgeschlossen()} value={anzeigenAb} changeValue={setAnzeigenAb}/>
                                </div>
                            </div>
                        }
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowMaxAverageNotice(false)} show={showMaxAverageNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Als Avg. wird die 60 Tage Average des Spielers genommen.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 210}}>Average Obergrenze:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowMaxAverageNotice(!showMaxAverageNotice)}/>
                                </div>
                                <div className="col">
                                    <InputNumber value={averageObergrenze}
                                                 style={{width: 200}}
                                                 disabled={istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                 onChange={(e) => setAverageObergrenze(e.value)}
                                                 mode="decimal" locale="de-DE" minFractionDigits={2}/>
                                </div>
                            </div>
                        }
                        {tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id &&
                            <div className="grid">
                                <div className="col-fixed" style={{width: 210}}>Spiel um Platz 3:
                                </div>
                                <div className="col">
                                    <ToggleButton
                                        className={istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                        onLabel="Ja" offLabel="Nein"
                                        checked={spielUmPlatz3}
                                        onChange={(e) => setSpielUmPlatz3(!spielUmPlatz3)}/>
                                </div>
                            </div>
                        }
                        {tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id &&
                            <div className="grid">
                                <div className="col-fixed" style={{width: 210}}>Final Satzausgleich:
                                </div>
                                <div className="col">
                                    <ToggleButton
                                        className={istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                        onLabel="Ja" offLabel="Nein"
                                        checked={finaleVerliererseiteMussZweiSpieleGewinnen}
                                        onChange={(e) => setFinaleVerliererseiteMussZweiSpieleGewinnen(!finaleVerliererseiteMussZweiSpieleGewinnen)}/>
                                </div>
                            </div>
                        }
                        {(tournamentSystem === TOURNAMENTSYSTEMS.EINFACHERKO.id || tournamentSystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowStartTournamentNotice(false)} show={showStartTournamentNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Turnier wird am Startzeitpunkt automatisch gestartet
                                        </div>
                                    </div>
                                }/>
                                {(eventOperatorId === null || eventOperatorId === undefined) && <>
                                    <div className="col-fixed" style={{width: 210}}>Turnier automatisch starten:
                                        <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                           onClick={(e) => setShowStartTournamentNotice(!showStartTournamentNotice)}/>
                                    </div>
                                    <div className="col-fixed" style={{width: "200px"}}>
                                        <ToggleButton
                                            className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                            onLabel="Ja" offLabel="Nein"
                                            checked={sollVomSystemGestartetWerden}
                                            onChange={(e) => setSollVomSystemGestartetWerden(!sollVomSystemGestartetWerden)}/>
                                    </div>
                                </>
                                }
                            </div>
                        }
                        <div className="grid">
                            <div className="col-fixed" style={{width: 210}}>Doppelturnier:</div>
                            <div className="col-fixed" style={{width: "200px"}}>
                                <ToggleButton
                                    className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                    onLabel="Ja" offLabel="Nein"
                                    checked={doppelturnier}
                                    onChange={() => setDoppelturnier(!doppelturnier)}/>
                            </div>
                        </div>
                        {eventOperatorId !== null && eventOperatorId !== undefined && doppelturnier &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowDypNotice(false)} show={showDypNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>DYP (Draw Your Partner) bezeichnet die Zuteilung von Spielpartnern per Los.
                                            Es melden sich Einzelspieler am Turnier an. Die Zuteilung der Doppelpartner erfolgt vom System.
                                            Beim Turnierstart muss eine gerade Anzahl an Spielern im System vorhanden sin.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 210}}>DYP:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={() => setShowDypNotice(!showDypNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 200}}>
                                    <ToggleButton
                                        className={istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                                    onLabel="Ja" offLabel="Nein"
                                                    checked={dyp}
                                                    onChange={() => setDyp(!dyp)}/>
                                    </div>
                                </div>
                        }
                        {(eventOperatorId === null || eventOperatorId === undefined) &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowUrkundenNotice(false)} show={showUrkundenNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Für abgeschlossene Ligen können Urkunden generiert
                                            werden, hierfür musst du einen 'Urkundenrohlung' mit dem Admin vereinbaren und
                                            los
                                            gehts.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 210}}>Urkundengenerierung:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowUrkundenNotice(!showUrkundenNotice)}/></div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <ToggleButton
                                        className={istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                        onLabel="Ja" offLabel="Nein"
                                        checked={urkundendruck}
                                        onChange={(e) => setUrkundendruck(!urkundendruck)}/>
                                </div>
                            </div>
                        }
                        {(eventOperatorId === undefined || eventOperatorId === null) && <>
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowRanglisteNotice(false)} show={showRanglisteNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>Sofern du das Turnier als Rangliste markierst, wird
                                            diese
                                            bei der Ranglistenberechnung berücktsichtigt.
                                        </div>
                                        <div style={{marginBottom: 5}}>Achtung!!! Du musst Ranglistenpunkte im System
                                            definieren. Bei Fragen melde dich in der Facebookgruppe https://www.facebook.com/groups/darthelfer oder wende dich an den Admin.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 210}}>Rangliste:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={() => setShowRanglisteNotice(!showRanglisteNotice)}/></div>
                                <div className="col-fixed" style={{width: "200px"}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                  checked={rangliste}
                                                  onChange={(e) => setRangliste(!rangliste)}/>
                                </div>
                            </div>
                            {rangliste &&
                                <div className="grid">
                                    <div className="col-fixed" style={{width: 210}}>Markers:</div>
                                    <div className="col-fixed" style={{width: "200px"}}>
                                        <RanglisteMarkerComponent eventOperatorId={eventOperatorId}
                                                                  onValueChange={(markers) => setRanglistemarkers(markers)}
                                                                  selectedMarkers={ranglistemarkers}/>
                                    </div>
                                </div>
                            }
                        </>
                        }
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Veranstalter" disabled={eventOperatorId === null || eventOperatorId === undefined}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-6">
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowPublishTournamentNotice(false)} show={showPublishTournamentNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    Datenschutz und Anonymität ist uns sehr wichtig. Auf der öffentlichen
                                    Turnierseite von Darthelfer kannst du dein Turnier anzeigen lassen. Bitte wähle dazu hier 'Ja'.
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Turnier veröffentlichen: *
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() =>  setShowPublishTournamentNotice(!showPublishTournamentNotice)}/>
                            </div>
                            <div className="col">
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={publishTournament}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setPublishTournament(!publishTournament)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowOnlineNotice(false)} show={showOnlineNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sofern du das Turnier als online markierst, wird dieses online über Darthelfer gespielt. In diesem Fall müssen sich DH Benutzer am Turnier anmelden und spielen
                                        dieses Turnier gegen einen Onlinegegner.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Offline ist für Turniere in deinem Vereinsheim oder Zuhausen. In diesem Fall kannst du die Ergebnisse von Hand in DH eintragen. Über den DH-Client kann gespielt
                                        werden, in diesem Fall werden die Ergebnisse automatisch eingetragen.
                                    </div>
                                    <div>
                                        Bitte beachte die Option Scoring mit Client. Sofern du über den darthelfer-scorer spielen willst, muss diese Option gesetzt werden. Wenn nicht, musst du das
                                        Ergebnis von Hand im Turnierbaum oder im Gruppenturnier eintragen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Online:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowOnlineNotice(!showOnlineNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={online}
                                              className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setOnline(!online)}/>
                            </div>
                        </div>
                        {!online &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowScoringWithClientNotice(false)} show={showScoringWithClientNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Sofern du dein Turnier mit Darthelfer scoren willst, wähle hier 'Ja'. Den
                                            darthelfer-scorer findest du unter https://scorer.darthelfer.de
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Hilfen dazu findest du unter support.darthelfer.de oder auf der Startseite von
                                            darthelfer.de
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Scoring mit Client:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={() => setShowScoringWithClientNotice(!showScoringWithClientNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                  checked={scoringWithDarthelfer}
                                                  className={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                                  onChange={(e) => setScoringWithDarthelfer(!scoringWithDarthelfer)}/>
                                </div>
                            </div>
                        }
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowRegistrationNotice(false)} show={showRegistrationNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn die Anmeldung zu deinem Turnier durch den Teilnehmer möglich ist.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', wenn du die Teilnehmer selbst eintragen möchtest. In diesem Fall ist eine Anmeldung über die darthelfer-Turnierübersicht nicht möglich. Nur du und
                                        deine Admins könnt Teilnehmer hinzufügen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Anmeldung möglich:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={(e) => setShowRegistrationNotice(!showRegistrationNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={registrationAllowed}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setRegistrationAllowed(!registrationAllowed)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowAnmeldungUnbestaetigtLoeschenNotice(false)} show={showAnmeldungUnbestaetigtLoeschenNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn du die Anmeldung vom System automatisch löschen lassen willst, sofern der Teilnehmer die Anmeldung in der Mail nicht bestätigt (Aktuell nach 24
                                        Std.). Die soll SPAM-Anmeldungen verhinden.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', Anmeldung wird nicht automatisch vom System gelöscht.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Unbestätigte Anmeldung löschen:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowAnmeldungUnbestaetigtLoeschenNotice(!showAnmeldungUnbestaetigtLoeschenNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={deleteUnconfirmedRegistrations}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={(e) => setDeleteUnconfirmedRegistrations(!deleteUnconfirmedRegistrations)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowTeilnahmePerMailBestaetigenNotice(false)} show={showTeilnahmePerMailBestaetigenNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Ja', wenn Teilnehmer Ihre Teilnahme bestätigen müssen. Die Teilnehmer erhalten nach der Anmeldung eine Mail und müssen innerhalb eines kurzen Zeitraums
                                        die Teilnahme bestätigen.
                                    </div>
                                    <div style={{marginBottom: 5}}>
                                        Wähle 'Nein', wenn die Teilnahme nicht per Mail bestätigt werden muss. In diesem Fall hast du das Risiko, dass ein Roboter oder eine Person die Anmeldung
                                        durchführt und du evtl. Teilnehmer gemeldet hast die nicht die Absicht haben mitzuspielen.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Teilnahme per Mail bestätigt:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowTeilnahmePerMailBestaetigenNotice(!setShowTeilnahmePerMailBestaetigenNotice())}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={participantsMailConfirmation}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={() => setParticipantsMailConfirmation(!participantsMailConfirmation)}/>
                            </div>
                        </div>
                        <div className="grid">
                            <DHDefaultDrawer onClose={() => setShowBezahlungNotice(false)} show={showBezahlungNotice} title={"Hinweis"} children={
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 5}}>
                                        Sofern für die Turnierteilnahme eine Gebühr notwendig ist, stelle hier auf 'Ja'. Im Mailtext kannst du dann deine Infos dafür angeben.
                                    </div>
                                </div>
                            }/>
                            <div className="col-fixed" style={{width: 220}}>Bezahlung:
                                <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                   onClick={() => setShowBezahlungNotice(!showBezahlungNotice)}/>
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <ToggleButton onLabel="Ja" offLabel="Nein"
                                              checked={bezahlung}
                                              className={ eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen() ? "p-disabled" : ""}
                                              onChange={() => setBezahlung(!bezahlung)}/>
                            </div>
                        </div>
                        {bezahlung &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowBezahlungBetragNotice(false)} show={showBezahlungBetragNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Bezahlbetrag. Dieser kann für die Turnierteilnahme eingerichtet werden. Wir fügen diese
                                            Info einer entsprechenden Bestätigungsmail hinzu.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Bezahlbetrag:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={() => setShowBezahlungBetragNotice(!showBezahlungBetragNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <div className="col" style={{width: "100%"}}>
                                        <InputText value={bezahlungbetrag} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                   onChange={(e) => setBezahlungbetrag(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {participantsMailConfirmation &&
                            <div className="grid">
                                <DHDefaultDrawer onClose={() => setShowMailNotice(false)} show={showMailNotice} title={"Hinweis"} children={
                                    <div style={{padding: 20}}>
                                        <div style={{marginBottom: 5}}>
                                            Der gesamte Text wird in der Bestätigungsmail eingefügt.
                                        </div>
                                        <div style={{marginBottom: 5}}>
                                            Füge in den Text eventuelle Bezahlinformationen wie Bankdaten oder PayPal Infos ein. Sofern der Betrag gefühlt ist, fügen wir dies Information automatisch
                                            hinzu.
                                        </div>
                                    </div>
                                }/>
                                <div className="col-fixed" style={{width: 220}}>Mailtext:
                                    <i className="pi pi-question-circle" style={{marginLeft: 5, cursor: "pointer"}}
                                       onClick={(e) => setShowMailNotice(!showMailNotice)}/>
                                </div>
                                <div className="col-fixed" style={{width: 220}}>
                                    <div className="col" style={{width: "100%"}}>
                                            <InputTextarea rows={5} cols={50} maxLength={5000} value={mailtext}
                                                           disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                                           onChange={(e) => setMailtext(e.target.value)}/>
                                        </div>
                                    </div>
                            </div>
                        }
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                        <Button label="Veranstalter- und Ausrichtungsortdaten aus Standard vorbelegen?" onClick={() => eventOperatorDatenVorbelegen()}/>
                        <h3>Veranstalterdaten</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Telefon:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortTelefon} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortTelefon(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Email:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortEmail} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortEmail(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Socialmedia-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={socialmedia} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setSocialmedia(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Homepage-Link:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={homepage} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setHomepage(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <h3>Daten zum Austragungsort</h3>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Bezeichnung<br/>(z.B Sportheim Hummelshausen):
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col">
                                    <InputText value={austragungsortBezeichnung} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortBezeichnung(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Strasse:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortStrasse} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortStrasse(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>PLZ:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortPLZ} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortPLZ(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="grid" style={{alignItems: "center", display: "flex"}}>
                            <div className="col-fixed" style={{width: 220}}>Ort:
                            </div>
                            <div className="col-fixed" style={{width: 220}}>
                                <div className="col" style={{width: "100%"}}>
                                    <InputText value={austragungsortOrt} disabled={eventOperatorId === null || eventOperatorId === undefined || istTurnierGestartet() || istTurnierAbgeschlossen()}
                                               onChange={(e) => setAustragungsortOrt(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            {eventOperatorId !== null && eventOperatorId !== undefined && tournamentSystem === TOURNAMENTSYSTEMS.KRATZER.id &&
                <TabPanel header="Kratzer">
                    <TurnierKratzerTabPanel onError={(message) => setErrorMessage(message)}
                                            tournamentId={id} />
                </TabPanel>
            }
            {(eventOperatorId === null || eventOperatorId === undefined) &&
                <TabPanel header="Admins">
                    <TurnierAdminTabPanel changeAdmins={(admins) => setAdmins(admins)}
                                          onError={(message) => setErrorMessage(message)} admins={admins}/>
                </TabPanel>
            }
            {(eventOperatorId === null || eventOperatorId === undefined) &&
                <TabPanel header="Sponsoren">
                    <TurnierSponsorenTabPanel onError={(message) => setErrorMessage(message)} sponsoren={sponsoren}
                                              changeSponsoren={(sponsoren) => setSponsoren(sponsoren)}/>
                </TabPanel>
            }
            <TabPanel header="Aktionen" disabled={id === undefined}>
                <LigaAktionenComponent proTournament={tournament} onInfo={(message) => setInfoMessage(message)}
                                       onClose={() => ""}
                                       callBackLoadNewTournament={(newId) => {
                                          ladeTurnier(newId);
                                          callBackRefresh();
                                      }}
                                       callBackRefresh={() => {
                                          ladeTurnier(id);
                                          callBackRefresh();
                                      }}
                                       callBackCloseDialog={() => {
                                          callBackOnClose();
                                      }}
                                       callBackOnDelete={() => {
                                          callBackOnDelete();
                                      }}
                                       onClearMessages={() => setErrorMessage(undefined)}
                                       onError={(message) => setErrorMessage(message)}/>
            </TabPanel>
            {id !== undefined &&
                <TabPanel header="QRCodes">
                    <TurnierQRCode tournamentId={id} eventOperatorId={eventOperatorId} onlineTournament={online} tournamentSystem={tournamentSystem} bezeichnung={bezeichnung}/>
                </TabPanel>
            }
        </TabView>
        {showPublishTournamentInfo && <InfoDialogEventNichtVeroeffentlichen onClose={() => setShowPublishTournamentInfo(false)}/>}
        {showBestleistungenDialog && <BestleistungenDialog onClose={() => setShowBestleistungenDialog(false)} tournamentId={id}/>}
        <div style={{marginTop: 20, fontSize: 10}}>TurnierId: {id}</div>
    </>}/>
}

TurnierVerwaltenDialog.propTypes = {
    callBackOnClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    tournamentId: PropTypes.string,
    propEventOperatorId: PropTypes.string,
};

export default TurnierVerwaltenDialog
