export function isEventOperatorAreaByCookieValue(cookies) {
    let value = cookies.get("dh_show_event_operator_area");
    let gefuellt = value !== null && value !== undefined && value !== "";
    return gefuellt && (value === true || value === "true");
 }

export function changeEventOperatorAreaByCookieValue(cookies) {
    if( isEventOperatorAreaByCookieValue(cookies)) {
        setCookieValue(cookies, "dh_show_event_operator_area", false);
    } else {
        setCookieValue(cookies, "dh_show_event_operator_area", true);
    }
}


export function setEventOperatorAreaByCookieValue(cookies) {
    setCookieValue(cookies, "dh_show_event_operator_area", true);
}

export function setCookieValue(cookies, name, value) {
    var date = new Date();
    date.setDate(date.getDate() + 365);
    let options = {expires: date, path: '/'};
    cookies.set(name, "" + value, options);
}
