import React from 'react'

export default function TurnierBoardAnzeige({feld, boardGameName, xValue, yValue, textAlign}) {

    const style = {
        position: "absolute",
        width: 100,
        height: 20,
        backgroundColor: "#ac1c27",
        color: "#eee",
        fontWeight: "bold",
        paddingLeft: 5,
        paddingRight: 0,
        paddingTop: 0,
        top: yValue + "px",
        left: xValue + "px",
        textAlign: textAlign,
        marginTop: 5,
        fontSize: 12,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    };

    return <div key={"turnierkachel_boardgame_" + feld} style={{...style, padding: 0, margin: 0}}>
        {boardGameName}
        </div>
}
