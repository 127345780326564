import * as React from 'react';
import {TableContainer} from "@mui/material/";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getTournamentsystem} from "../../../../constants/tournamentSystems";
import DHDesktopDiv from "../../../general/DHDesktopDiv";
import DHMobileDiv from "../../../general/DHMobileDiv";

const CELL_PADDING_VALUE = 8;
const CELL_PADDING_VALUE_MOBILE = 4;

export default function TournamentTable({tournaments, showOnline, showSteeldarts, showEDart, onOpen, onOpenEventOperator, showEventOperator}) {

    const getDartTypeBezeichnung = (dartType) => {
        if( dartType === "STEELDARTS") {
            return "Steeldarts";
        } else if( dartType === "EDART") {
            return "E-Dart";
        } else {
            return "";
        }
    }

    const getTableRowsDesktop = (tournaments, onOpen, onOpenEventoperator, showEventOperator) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};
        for (const tournament of tournaments) {
            if( tournament.online && !showOnline) {
                continue;
            }
            if( !tournament.online && tournament.dartType === "STEELDARTS" && !showSteeldarts) {
                continue;
            }
            if( !tournament.online && tournament.dartType === "EDART" && !showEDart) {
                continue;
            }

            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={tournament.id}>
                <TableCell style={style}>{tournament.beginnNurMitTagesangabe ? tournament.beginnString : tournament.beginnMitUhrzeitString}</TableCell>
                <TableCell style={{...style, cursor:"pointer"}} onClick={() => onOpen(tournament)}>
                    {tournament.bezeichnung}<br/>
                    <span style={{opacity:0.5, fontSize:14}}>
                        {tournament.online && "online"}
                        {!tournament.online && tournament.dartType && getDartTypeBezeichnung(tournament.dartType)}
                    </span>
                </TableCell>
                {showEventOperator &&
                    <TableCell
                        onClick={() => {
                            if( !tournament.online) {
                                onOpenEventoperator(tournament)}
                        }}
                        style={{...style, cursor: !tournament.online ? "pointer" : ""}}>{tournament.eventOperatorBezeichnung !== null && tournament.eventOperatorBezeichnung}{tournament.leagueOperatorBezeichnung !== null && tournament.leagueOperatorBezeichnung}</TableCell>
                }
                <TableCell style={style}>{getTournamentsystem(tournament.turniersystem).text}</TableCell>
            </TableRow>)
            background = !background;
        };
        return returnRows;
    }

    const getTableRowsMobile = (tournaments, onOpen, onOpenEventoperator, showEventOperator) => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 14, padding:CELL_PADDING_VALUE_MOBILE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 14, padding:CELL_PADDING_VALUE_MOBILE};
        for (const tournament of tournaments) {
            if( tournament.online && !showOnline) {
                continue;
            }
            if( !tournament.online && tournament.dartType === "STEELDARTS" && !showSteeldarts) {
                continue;
            }
            if( !tournament.online && tournament.dartType === "EDART" && !showEDart) {
                continue;
            }
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={tournament.id}>
                <TableCell style={style}>{tournament.beginnNurMitTagesangabe ? tournament.beginnString : tournament.beginnMitUhrzeitString}</TableCell>
                <TableCell style={{...style, cursor: "pointer"}} onClick={() => onOpen(tournament)}>
                    {tournament.bezeichnung}<br/>
                    <span style={{fontSize: 10}}>{getTournamentsystem(tournament.turniersystem).text}</span><br/>
                    <span style={{opacity: 0.5, fontSize: 10}}>
                        {tournament.online && "online"}
                        {!tournament.online && tournament.dartType && getDartTypeBezeichnung(tournament.dartType)}
                    </span>
                </TableCell>
                {showEventOperator &&
                    <TableCell
                        onClick={() => {
                            if (!tournament.online) {
                                onOpenEventoperator(tournament)
                            }
                        }}
                        style={{...style, cursor: !tournament.online ? "pointer" : ""}}>{tournament.eventOperatorBezeichnung !== null && tournament.eventOperatorBezeichnung}{tournament.leagueOperatorBezeichnung !== null && tournament.leagueOperatorBezeichnung}</TableCell>
                }
            </TableRow>)
            background = !background;
        };
        return returnRows;
    }

    if( tournaments.length === 0) {
        return <div style={{color:"white", fontSize:20, padding:20}}>Aktuell keine Events vorhanden.</div>
    }
    return <>
        <DHDesktopDiv comp={
            <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
                <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:20, padding:0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Datum</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Event</TableCell>
                            {showEventOperator &&
                                <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Veranstalter</TableCell>
                            }
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:20, padding:CELL_PADDING_VALUE}}>Typ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRowsDesktop(tournaments, onOpen, onOpenEventOperator, showEventOperator, CELL_PADDING_VALUE)}
                    </TableBody>
                </Table>
            </TableContainer>
        }/>
        <DHMobileDiv comp={
            <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
                <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:14}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE_MOBILE}}>Datum</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE_MOBILE}}>Event</TableCell>
                            {showEventOperator &&
                                <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:CELL_PADDING_VALUE}}>Veranstalter</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRowsMobile(tournaments, onOpen, onOpenEventOperator, showEventOperator, CELL_PADDING_VALUE)}
                    </TableBody>
                </Table>
            </TableContainer>
        }/>
    </>
}
