import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHDialog from "../../general/DHDialog";
import Description from "./Description";
import UserSearch from "../liga/UserSearch";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";

function AdminRow({admin, onDelete}) {
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    return <div className="grid" key={"key_admin_" + admin.id} style={{alignItems: "center", display: "flex"}}>
        <div className="col-fixed" style={{width: 50}}>
            <Button icon="pi pi-trash" tooltip="Löschen"
                    style={{marginRight: "5px", marginTop: "5px"}}
                    onClick={() => setShowDeleteMessage(true)}/>
        </div>
        <div className="col">
            {admin.username}
        </div>
        <div className="col">
            {admin.vorname}, {admin.name}
        </div>
        <div className="col">
            {admin.telefon}
        </div>
        <div className="col">
            {admin.email}
        </div>
        {showDeleteMessage &&
            <ConfirmWithQuestionDialog  header="Löschen" textOben="Möchtest du den Admin wirklich löschen?" visible={true}
                                        callBackOnNein={() => setShowDeleteMessage(false)} callBackOnJa={() => {
                onDelete(admin.userId);
                setShowDeleteMessage(false);
            }}/>
        }
    </div>
}

export default function VeranstalterAdminsDialog({eventOperatorId, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [admins, setAdmins] = useState([]);
    let [newAdminUser, setNewAdminUser] = useState(undefined);

    useEffect(() => {
        if (eventOperatorId === undefined) {
            return;
        }
        ladeVeranstalter();
    }, [eventOperatorId]);

    const ladeVeranstalter = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/byId/" + eventOperatorId,
            json => {
                setAdmins(json.admins);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const deleteAdmin = (userId) => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/deleteadmin/" + eventOperatorId + "/" + userId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde erfolgreich gelöscht");
                ladeVeranstalter();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );

    }

    const saveAdmin = () => {
        setErrorMessage(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.eventoperator + "/saveadmin/" + eventOperatorId + "/" + newAdminUser.id;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Admin wurde erfolgreich hinzugefügt");
                ladeVeranstalter();
                setNewAdminUser(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
            }
        );

    }

    return <DHDialog onClose={() => onClose()} title="Admins" show={true} buttons={[]} showCloseButton={true}
                     comp={<div style={{padding:20}}>
                         <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                             onClearErrorMessage={() => setErrorMessage(undefined)}/>
                         <div>
                             <Description
                                 message="Hier kannst du Administratoren verwalten. Gemeinsam könnt ihr dann an euren Veranstaltungen arbeiten und diese zusammen verwalten."/>
                             <Description message="Für neue Admins benötigst du den Usernamen der Person hier auf Darthelfer. Bitte
                    suche nach dieser Person und speichere diese dann als Admin."/>
                             <div style={{marginTop: 30, marginBottom: 20}}>
                                 Um weitere Admins speichern zu können, musst du den Veranstalter initial gespeichert haben.
                             </div>
                             <div style={{marginTop: 30, marginBottom: 20}}>
                                 <UserSearch
                                     callBackError={(message) => {
                                         setErrorMessage(message);
                                         setNewAdminUser(undefined);
                                     }}
                                     callBackClearMessages={() => setErrorMessage(undefined)}
                                     callBackUserFound={(user) => setNewAdminUser(user)}
                                     shortSearchButton={true}/>
                                 {newAdminUser !== undefined &&
                                     <Button label="Speichern" icon="pi pi-times" onClick={() => saveAdmin()}
                                             className="p-button-primary"/>}
                             </div>
                             <Description message="Hinweis: Du wirst beim initialen Speichern automatisch als Admin angelegt!"/>
                             {admins.map(admin => <AdminRow admin={admin} onDelete={(userId) => deleteAdmin(userId)}/>)}
                         </div>
                     </div>}/>
}
