import {connect} from 'react-redux'
import Kalender from "../../../components/views/liga/Kalender";

const mapStateToProps = function (state, ownProps) {
    return {userId: state.user.id};
};


const mapDispatchToProps = dispatch => ({
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Kalender)