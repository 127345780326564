import React from 'react';
import {Button} from "primereact/button";

import PlayerUtil from "../../../util/PlayerUtil";

import "./playerTable.css";

function getRows(props) {
    let tableRows = [];
    if( props.spieler !== undefined) {
        for (let i = 0; i < props.spieler.length; i++) {
            let spieler = props.spieler[i];

            let spielername = PlayerUtil.anzeigeNameZumSpieler(spieler);

            tableRows.push(<tr>
                <td style={{width:20}}><img src={spieler.onlineStatus ? "/images/dot_green.png" :"/images/dot_red.png"} style={{width:15, height:15}} alt="Onlinestatus"/></td>
                <td>{spielername}</td>
                {spieler.username !== null && spieler.username !== undefined && spieler.username !== "" ?
                    <td>{spieler.username}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieler.email !== null && spieler.email !== undefined && spieler.email !== "" ?
                    <td>{spieler.email}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieler.mobilenumber !== null && spieler.mobilenumber !== undefined && spieler.mobilenumber.email !== "" ?
                    <td>{spieler.mobilenumber}</td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}}/>
                }
                {spieler.active ?
                    <td><i className="pi pi-star"/></td>
                    :
                    <td dangerouslySetInnerHTML={{__html: "&nbsp;"}} style={{width: "50px"}}/>
                }
                {!props.readonly &&
                <td style={{width: "140px", textAlign: "center"}}>
                    <div>
                        <Button icon="pi pi-pencil" tooltip="Spieler ändern" style={{marginRight: "5px"}}
                                onClick={() => props.editSpieler(spieler)}/>
                        <Button icon="pi pi-star"
                                onClick={() => props.changeSpielerState(spieler.id)}
                                style={{marginRight: "5px", marginTop: "5px"}}
                                tooltip={spieler.active ? "Spieler inaktiv schalten" : "Spieler aktiv schalten"}
                                className={spieler.active ? "p-button-danger" : "p-button-success"}/>
                    </div>
                </td>
                }
            </tr>);
        }
    }

    return tableRows;
}
export default function SimpleTable(props) {

    let tableRows = getRows(props);
    return (<table className="darthelfer darthelfertabelle">
            <tr>
                <th></th>
                <th>Name, Vorname (Spitzname)</th>
                <th>Benutzername</th>
                <th>Email</th>
                <th>Mobilnr.</th>
                <th>Aktiv</th>
                {!props.readonly &&
                    <th></th>
                }
            </tr>
            {tableRows}
        </table>
    );
}